import { gql } from '@apollo/client';

const query = gql`
  mutation unAwsTag($request: AwsUntagRequest, $cloudId: Long, $region: String) {
  unAwsTag(awsUntagRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsUntagResponse {
        arn
        keys
        }
      }
    }
  }
`;
export default query;
