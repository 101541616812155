import { useCallback, useRef } from 'react';
import IPSetReferences from '../../components/IPSetReferences';
import RuleVariables from '../../components/RuleVariables';
import { ICreationDataPropsType } from '../type';

interface ISuricataCompatibleRuleProps {
  creationData: ICreationDataPropsType;
  updateCreationData: (data: ICreationDataPropsType) => void;
}

const SuricataCompatibleRule = ({ creationData, updateCreationData }: ISuricataCompatibleRuleProps) => {
  const { suricataRule } = creationData;
  const suricataRuleRef = useRef<HTMLTextAreaElement>(null);
  const handleChangeSuricataRule = useCallback(
    (value: string) => {
      updateCreationData({ ...creationData, suricataRule: value });
    },
    [creationData, updateCreationData],
  );
  const handleCopySuricataRule = () => {
    navigator.clipboard.writeText(suricataRuleRef.current?.value ?? '').then(() => {});
  };

  return (
    <>
      {<RuleVariables creationData={creationData} updateCreationData={updateCreationData} />}
      {<IPSetReferences creationData={creationData} updateCreationData={updateCreationData} />}
      <div className="rule-group-container">
        <div className="title-group">
          <p className="title">Suricata compatible rule string</p>
          <p className="sub-title">
            Suricata is an open source network IPs that include a standard rule- based language to traffic inspection.
          </p>
        </div>

        <div className="rule-group-details-container">
          <div className="rule-group-detail-content">
            <div className="config-rule-row">
              <div className="config-rule-column">
                <div className="config-rule-label">Suricata compatible rule string</div>
              </div>
            </div>
            <div className="config-rule-row" style={{ justifyContent: 'flex-start' }}>
              <div className="config-rule-column" style={{ flex: 'none', width: '50%' }}>
                <div className="config-rule-textarea">
                  <textarea
                    value={suricataRule}
                    ref={suricataRuleRef}
                    onChange={event => {
                      handleChangeSuricataRule(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div style={{ marginLeft: 6 }}>
                <button
                  className="button"
                  style={{ margin: 0 }}
                  onClick={() => {
                    handleCopySuricataRule();
                  }}
                >
                  Copy rules
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuricataCompatibleRule;
