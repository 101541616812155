import Button from './Button';
import _ from 'lodash';
import { DropdownListDataType } from 'components/Dropdown/types';
import './styles.scss'

type ButtonPropsType = {
  id?: string;
  label: string;
  type?: string;
  onClick: () => void;
  disabled?: boolean;
  dropdownList?: Array<DropdownListDataType>;
  dropdownValue?: DropdownListDataType;
  onDropdownValueChanged?: (value: DropdownListDataType) => void;
  loading?: boolean;
};

type ButtonGroupPropsType = {
  buttons: Array<ButtonPropsType>;
};

const ButtonGroup = (props: ButtonGroupPropsType) => {
  const { buttons } = props;

  return (
    <div className="button-group-common-container">
      {buttons.map(button => (
        <Button key={_.uniqueId('button')} {...button} />
      ))}
    </div>
  );
};

export default ButtonGroup;
