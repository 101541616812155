import '../index.scss';
import { useEffect, useState } from 'react';
import { FIREWALL_POLICY_CREATION, MOCK_AWS_KMS_KEY_DETAILS } from '../configs';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import { ConfigureAdvancedSettingsStepPropsType } from '../types';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import lazyGetAwsListKeys, { AwsListKeyVariablesType } from 'graphql/queries/getAwsListKeys';
import lazyGetAwsListAliases, { IAwsListAliasesVariables } from 'graphql/queries/getAwsListAliases';
import { DropdownListDataType } from 'components/Dropdown/types';
import Dropdown from 'components/molecules/Dropdown';
import { randomString } from 'utils/Common';
import Space from 'pages/v2/Organ/Management/components/Space';
import Button from 'pages/v2/Organ/Management/components/Button';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';

const ConfigureAdvancedSettingsStep = (props: ConfigureAdvancedSettingsStepPropsType) => {
  const {
    cloudId,
    region,
    onValueChanged,
    awsKmsKey,
    customizeEncryptionSettings,
    policyVariables,
    errorMessage,
    setErrorMessage,
  } = props;

  const { arn, description } = awsKmsKey || {};

  const [getAwsListKeys] = lazyGetAwsListKeys();
  const [getAwsListAliases] = lazyGetAwsListAliases();

  const [listKeys, setListKeys] = useState<DropdownListDataType[]>([]);

  const handleGetAwsListAliases = async (keyId: string) => {
    try {
      const variables: IAwsListAliasesVariables = {
        cloudId,
        region,
        request: {
          keyId,
        },
      };

      const response = await getAwsListAliases({ variables });
      return response.data?.getAwsListAliases?.data?.[0]?.aliases?.[0];
    } catch (error) {}
  };

  const handleGetAwsListKeys = async () => {
    try {
      const variables: AwsListKeyVariablesType = {
        cloudId,
        region,
        request: {
          limit: 1000,
        },
      };

      const response = await getAwsListKeys({ variables });
      const listKeys = response.data?.getAwsListKeys?.data?.[0]?.keys || [];

      if (!listKeys?.length) return;

      const arrVariables = listKeys.map(e => handleGetAwsListAliases(e.keyId));

      const result = await Promise.all(arrVariables);

      const options = listKeys.map((e, index) => ({
        name: e.keyId,
        value: e.keyId,
        arn: e.keyArn,
        description: result[index]?.aliasName,
        isVertical: true,
      }));

      setListKeys(options);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetAwsListKeys();
  }, []);

  return (
    <div>
      <div className="rule-group-container">
        <div className="title-group">
          <p className="title">{FIREWALL_POLICY_CREATION.CONFIGURE_ADVANCED_SETTINGS_TITLE}</p>
          <p className="sub-title">{FIREWALL_POLICY_CREATION.CONFIGURE_ADVANCED_SETTINGS_SUBTITLE}</p>
        </div>

        <div className="rule-group-details-container">
          <div
            className="rule-group-detail-title"
            style={{ gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            {FIREWALL_POLICY_CREATION.CUSTOMER_MANAGED_KEY_TITLE}
            <span className="rule-group-detail-sub-title">
              {FIREWALL_POLICY_CREATION.CUSTOMER_MANAGED_KEY_SUBTITLE}
            </span>
          </div>

          <div
            className="rule-group-detail-title"
            style={{ gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <span className="rule-group-detail-sub-title">
              {FIREWALL_POLICY_CREATION.CUSTOMER_MANAGED_KEY_DESCRIPTION}
            </span>

            <CheckboxAtom
              label={FIREWALL_POLICY_CREATION.CUSTOMIZE_ENCRYPTION_SETTINGS_CHECKBOX_LABEL}
              checked={customizeEncryptionSettings}
              onchange={() => onValueChanged('customizeEncryptionSettings', !customizeEncryptionSettings)}
            />

            {customizeEncryptionSettings ? (
              <div className="aws-kms-key-container">
                <Space size={10} />

                <div className="aws-kms-key-title">
                  <p>Choose an AWS KMS key</p>

                  <p>The key will be used for encryption instead of the default key.</p>
                </div>

                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: 10,
                  }}
                >
                  <div>
                    <Dropdown
                      id={randomString()}
                      placeholder={FIREWALL_POLICY_CREATION.CHOOSE_A_KEY_PLACEHOLDER}
                      data={listKeys}
                      value={awsKmsKey || { name: '', value: '' }}
                      handleClick={val => {
                        onValueChanged('awsKmsKey', val);
                        setErrorMessage?.();
                      }}
                    />

                    {!!errorMessage && (
                      <div className="error-container">
                        <img src={InputErrorIcon} width={16} height={16} />
                        <p className="error-message">{errorMessage}</p>
                      </div>
                    )}
                  </div>

                  <div style={{ marginTop: '6px' }}>
                    <Button
                      label={FIREWALL_POLICY_CREATION.CHOOSE_A_KEY_REMOVE_BUTTON}
                      disabled={!awsKmsKey?.value}
                      onClick={() => onValueChanged('awsKmsKey', null)}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          {customizeEncryptionSettings && awsKmsKey ? (
            <>
              <div
                className="rule-group-detail-title"
                style={{ gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
              >
                <p>{FIREWALL_POLICY_CREATION.KEY_DETAILS_TITLE}</p>

                <span className="rule-group-detail-sub-title">{FIREWALL_POLICY_CREATION.KEY_ARN_LABEL}</span>

                <span className="checkbox-labeled">{arn}</span>
              </div>
              <div
                className="rule-group-detail-title"
                style={{ gap: 8, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
              >
                <div style={{ gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1 }}>
                  <span className="rule-group-detail-sub-title">{FIREWALL_POLICY_CREATION.KEY_STATUS_LABEL}</span>

                  <span className="checkbox-labeled">{MOCK_AWS_KMS_KEY_DETAILS.KEY_STATUS}</span>
                </div>
                <div
                  style={{
                    gap: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flex: 1,
                    borderLeft: '1px solid #E0E0E0',
                    paddingLeft: 24,
                  }}
                >
                  <span className="rule-group-detail-sub-title">{FIREWALL_POLICY_CREATION.KEY_ALIASES_LABEL}</span>

                  <span className="checkbox-labeled">{description}</span>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>

      <div className="rule-group-container">
        <div className="rule-group-details-container">
          <div className="rule-group-detail-title" style={{ gap: 8, display: 'flex', flexDirection: 'column' }}>
            <p>{FIREWALL_POLICY_CREATION.POLICY_VARIABLES_TITLE}</p>

            <span className="rule-group-detail-sub-title">{FIREWALL_POLICY_CREATION.POLICY_VARIABLES_DESCRIPTION}</span>
          </div>

          <div className="rule-group-detail-title" style={{ gap: 8, display: 'flex', flexDirection: 'column' }}>
            <span className="checkbox-labeled">
              {FIREWALL_POLICY_CREATION.HOME_NET_VARIABLES_OVERRIDE_VALUES_TITLE}
            </span>

            <TextareaAtom
              placeholder={''}
              value={policyVariables}
              onChangeValue={value => onValueChanged('policyVariables', value)}
              disabled={false}
              noClear={true}
            />
            <span className="rule-group-detail-sub-title">
              {FIREWALL_POLICY_CREATION.HOME_NET_VARIABLES_OVERRIDE_VALUES_DESCRIPTION}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureAdvancedSettingsStep;
