import { gql } from '@apollo/client';

const query = gql`
  query getAwsResourceProfile($request: AwsGetResourceProfileRequest, $cloudId: Long, $region: String) {
    getAwsResourceProfile(awsGetResourceProfileRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetResourceProfileResponse {
          profileUpdatedAt
          sensitivityScore
          sensitivityScoreOverridden
          statistics {
            totalBytesClassified
            totalDetections
            totalDetectionsSuppressed
            totalItemsClassified
            totalItemsSensitive
            totalItemsSkipped
            totalItemsSkippedInvalidEncryption
            totalItemsSkippedInvalidKms
            totalItemsSkippedPermissionDenied
          }
        }
      }
    }
  }
`;
export default query;
