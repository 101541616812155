import lazyGetAwsDescribeLockedSnapshots, {
  IGetAwsDescribeLockedSnapshotsVariables,
} from 'graphql/queries/getAwsDescribeLockedSnapshots';
import { Fragment, useEffect, useState } from 'react';
import './SnapshotSetting.scss';

export type SnapshotSettingPropsType = {
  cloudId: number;
  region: string;
  snapshotId: string;
};

const SnapshotSetting = (props: SnapshotSettingPropsType) => {
  const { snapshotId, cloudId, region } = props;

  // API
  const [getAwsDescribeFastSnapshotRestores, { loading }] = lazyGetAwsDescribeLockedSnapshots();

  // State
  const [apiIsLoading, setApiIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  const getLockedSnapshot = async () => {
    try {
      const variables: IGetAwsDescribeLockedSnapshotsVariables = {
        cloudId,
        region,
        request: {
          snapshotIds: [snapshotId],
        },
      };

      const { data: describeFastSnapshotRestores } = await getAwsDescribeFastSnapshotRestores({ variables });
      if (!describeFastSnapshotRestores) return;

      setData(describeFastSnapshotRestores?.getAwsDescribeLockedSnapshots?.data?.[0]?.snapshots?.[0]);
    } catch (error) {}
  };

  useEffect(() => {
    if (!snapshotId) return;

    getLockedSnapshot();
  }, [snapshotId]);

  return (
    <Fragment>
      {loading ? (
        <div className="loading-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="snapshot-info">
            <p className="title">Snapshot Lock- New</p>
            <div className="content">
              <p className="title">Lock mode</p>
              <p className="value">{data ? `Lock ${data?.lockState}` : 'Not locked'}</p>
            </div>
          </div>

          <div className="shared-permission">
            <p className="title">Share permissions</p>
            <div className="content">
              <p className="title">Snapshot share permissions</p>
              <p className="value">Private</p>
              <p className="title">The snapshot is shared only with AWS accounts that you specified.</p>
            </div>
          </div>

          <div className="shared-account-info">
            <p className="title">Shared accounts</p>
            <p className="description">The snapshot is shared only with the following AWS accounts.</p>
            <div className="shared-container">
              <p className="row">AWS account ID</p>

              <div className="item">No item</div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default SnapshotSetting;
