import './index.scss';

interface ICheckboxAtomProps {
  id?: string;
  label?: string;
  description?: string;
  checked?: boolean;
  disabled?: boolean;
  onchange?: () => void;
}
const CheckboxAtom = ({ id, label, description, checked, disabled, onchange }: ICheckboxAtomProps) => {
  return (
    <div className='checkbox-atom-container'>
      <div className='checkbox-atom-group'>
        <label className="checkbox-atom">
          <input type="checkbox" {...(id && { id: id })} checked={checked} onChange={onchange} disabled={disabled} />

          <span />
        </label>

        {label ? <p className='checkbox-atom-label'>{label}</p> : null}
      </div>

      {description ? <p className='checkbox-atom-description'>{description}</p> : null}
    </div>
  );
};
export default CheckboxAtom;
