import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconEditRuleGroup from 'assets/svgs/v3/ico_policy_variables.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import RadioAtom from 'components/v2/atoms/RadioAtom';

type EditKeyRotationModalPropsType = {
  onSave?: (keyRotation: boolean, period: string) => void;
  keyRotation?: boolean;
  period?: string;
} & IBaseModalProps;

const EditKeyRotationModal = (props: EditKeyRotationModalPropsType) => {
  const { keyRotation: keyRotationProps, period: periodProps, onSave, ...baseModalProps } = props;
  const [keyRotation, setKeyRotation] = useState<boolean>(false);
  const [period, setPeriod] = useState<string>('90');

  useEffect(() => {
    if (baseModalProps.open) {
      setKeyRotation(!!keyRotationProps);
      setPeriod(period ?? '90');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditRuleGroup} />
          Edit automatic key rotation
        </>
      )}
      {...baseModalProps}
    >
      <div className="modal-content-container">
        <p className="title">Automatic Key rotation</p>
        <div className="input">
          <p>Key rotation</p>
          <RadioAtom
            label="Enable"
            value={keyRotation.toString()}
            checked="true"
            name="Enable"
            onChange={() => setKeyRotation(true)}
          />
          <RadioAtom
            label="Disable"
            value={keyRotation.toString()}
            checked="false"
            name="Disable"
            onChange={() => setKeyRotation(false)}
          />
        </div>
        {keyRotation && (
          <div className="input">
            <p>Rotation period (in days)</p>
            <p>Rotation period defines the number of days between each automatic rotation</p>
            <InputAtom placeholder={''} value={period} noClear={true} onChangeValue={val => setPeriod(val)} />

            <p>Enter a rotation period between 90 and 2560 days.</p>
          </div>
        )}

        <div className="button-group">
          <Button type={ButtonTypeEnum.GENERAL} onClick={() => baseModalProps?.onClose?.()} label="Cancel" />
          <Button type={ButtonTypeEnum.PRIMARY} onClick={() => onSave?.(keyRotation, period)} label="Save" />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditKeyRotationModal;
