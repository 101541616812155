import { useState, useMemo, useEffect, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconStateless from 'assets/svgs/v3/ico_stateless_actions.svg';
import IconStateful from 'assets/svgs/v3/ico_stateful_actions.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import ResetIcon from 'assets/svgs/v3/ico_reset.svg';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdLayout';
import TableCheckbox from 'components/v2/dataDisplay/TableCheckbox';
import TableRadio from 'components/v2/dataDisplay/TableRadio';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';

interface IEditPriorityProps extends IBaseModalProps {
  header: string;
  subHeader?: string;
  isStateless?: boolean;
  rows: RowType[];
  columns: ColumnType[];
  btnTitle?: string;
  note?: string;
  creationApiLoading: boolean;
  handleUpdateRule: Function;
  onClose: () => void;
}

const EditPriority = ({
  header,
  subHeader,
  isStateless = false,
  rows,
  columns,
  btnTitle,
  note,
  creationApiLoading,
  handleUpdateRule,
  onClose,
  ...baseModalProps
}: IEditPriorityProps) => {
  const [handleRows, setHandleRows] = useState<RowType[]>(rows);
  const [resourceSelected, setResourceSelected] = useState<string>('');

  const moveUp = useCallback(() => {
    const selectRowIndex = handleRows.findIndex(row => row.id === resourceSelected);
    if (resourceSelected && selectRowIndex > 0) {
      const updatedRows = [...handleRows];
      const temp = updatedRows[selectRowIndex];
      updatedRows[selectRowIndex] = { ...updatedRows[selectRowIndex - 1], priority: temp.priority };
      updatedRows[selectRowIndex - 1] = { ...temp, priority: updatedRows[selectRowIndex - 1].priority };
      setHandleRows(updatedRows);
    }
  }, [resourceSelected, handleRows]);

  const moveDown = useCallback(() => {
    const selectRowIndex = handleRows.findIndex(row => row.id === resourceSelected);
    if (resourceSelected && selectRowIndex < handleRows.length - 1) {
      const updatedRows = [...handleRows];
      const temp = updatedRows[selectRowIndex];
      updatedRows[selectRowIndex] = { ...updatedRows[selectRowIndex + 1], priority: temp.priority };
      updatedRows[selectRowIndex + 1] = { ...temp, priority: updatedRows[selectRowIndex + 1].priority };
      setHandleRows(updatedRows);
    }
  }, [resourceSelected, handleRows]);

  const onSavePolicy = async () => {
    const formatReq = handleRows.map(item => ({ priority: item.priority, resourceArn: item.arn, override: item.override }));
    const result = await handleUpdateRule(formatReq, true);
    if (result) {
      onClose();
    }
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={isStateless ? IconStateless : IconStateful} />
          {header}
        </>
      )}
      onClose={onClose}
      {...baseModalProps}
    >
      <div className="unmanaged-state-modal">
        <p className="note">{note}</p>
        <div className="unmanaged-state">
          <div className="row-1">
            <p className="text-title">{`${isStateless ? 'Stateless' : 'Stateful'}`} rule group</p>
            <div className="btn-group-edit">
              <button className="btn-up" onClick={moveUp}>
                {' '}
                Move Up
              </button>
              <button className="btn-down" onClick={moveDown}>
                Move Down
              </button>
            </div>
          </div>

          <TableRadio
            className="radio-atom"
            rows={handleRows}
            columns={columns}
            
            name="database"
            radioValue={resourceSelected}
            onRowSelected={setResourceSelected}
          />
        </div>

        <div className={'button-group'}>
          <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={onClose} />
          <Button
            label="Save"
            type={ButtonTypeEnum.PRIMARY}
            loading={creationApiLoading}
            onClick={onSavePolicy}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditPriority;
