import { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import CopyableText from 'components/v3/CopyableText';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import MgdTooltip from 'components/v2/MgdTooltip';
import { getStrValueOrNull, randomString } from 'utils/Common';
import './index.scss';

export type DetailTabPropsType = {
  title: string;
  isApiLoading?: boolean;
  description?: string;
  data: IMgdDetailKeyValueProps[];
  actions?: any;
};

const DetailTab = ({ title, description, data, isApiLoading, actions }: DetailTabPropsType) => {
  const detailInfoRef = useRef<HTMLDivElement>(null);

  const [detailInfoWidth, setDetailInfoWidth] = useState(0);

  useEffect((): any => {
    const observer = new ResizeObserver(entries => {
      setDetailInfoWidth(entries[0].contentRect.width);
    });

    if (detailInfoRef.current) {
      observer.observe(detailInfoRef.current);
      return () => detailInfoRef.current && observer.unobserve(detailInfoRef.current);
    }
    return () => detailInfoRef.current && observer.unobserve(detailInfoRef.current);
  }, [isApiLoading]);

  const renderLinkNode = useCallback((description: string, handleItemClick: any): ReactNode => {
    if (!description) {
      return <p>-</p>;
    }
    return (
      <p
        className="description link"
        onClick={e => {
          e.stopPropagation();
          handleItemClick?.(description);
        }}
      >
        {description}
      </p>
    );
  }, []);

  const renderValue = useCallback((detail: IMgdDetailKeyValueProps) => {
    if (!detail.description || detail.description === '-') {
      return <p>-</p>;
    }

    switch (detail.type) {
      case TextTypeEnum.COPY: {
        if (detail.description instanceof Array) {
          return (
            <Fragment>
              {detail.description.map((item, index) => {
                return <CopyableText text={item} key={`detail-tab-copy-${index}`} />;
              })}
            </Fragment>
          );
        }

        if (!detail.description || detail.description === '-') {
          return <p>-</p>;
        }

        return <CopyableText text={detail.description} />;
      }

      case TextTypeEnum.STATUS: {
        return (
          <div className="status">
            <p>{detail.description}</p>
          </div>
        );
      }

      case TextTypeEnum.LINK: {
        if (detail.description instanceof Array) {
          if (detail?.isTooltip) {
            return (
              <MgdTooltip id={randomString()} title={`${detail.description?.length} subnets`}>
                {detail.description.map((item: any, index: number) => {
                  return (
                    <Fragment key={`detail-description-tooltip-${index}`}>
                      {renderLinkNode(item, detail?.handleItemClick)}
                    </Fragment>
                  );
                })}
              </MgdTooltip>
            );
          }

          return (
            <Fragment>
              {detail.description.map((item, index) => {
                return (
                  <Fragment key={`detail-tab-link-${index}`}>{renderLinkNode(item, detail.handleItemClick)}</Fragment>
                );
              })}
            </Fragment>
          );
        }

        return renderLinkNode(detail.description, detail.handleItemClick);
      }

      case TextTypeEnum.COPY_LINK: {
        if (detail.description instanceof Array) {
          return detail.description.map((des, idx) => (
            <CopyableText
              key={`detail-tab-copy-${idx}`}
              text={des}
              onTextClicked={() => detail?.handleItemClick?.(des)}
            />
          ));
        }

        return (
          <CopyableText
            text={detail.description}
            onTextClicked={() => (detail?.handleItemClick ? detail.handleItemClick() : {})}
          />
        );
      }

      case TextTypeEnum.NORMAL:
      default:
        if (detail.description instanceof Array) {
          if (!detail?.description?.length) {
            return <p>-</p>;
          }

          return (
            <Fragment>
              {detail.description.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </Fragment>
          );
        }

        return <p>{getStrValueOrNull(detail.description)}</p>;
    }
  }, []);

  const renderContentNode = useCallback((): ReactNode => {
    // one column layout
    if (data.length === 1) {
      return (
        <div className="detail-info-content">
          {data?.map((detail: IMgdDetailKeyValueProps, index: number) => (
            <div className={`col-type-one`} key={`${index}`}>
              <div className="content">
                <div className="title">
                  <p>{detail.title}</p>
                </div>
                <div className="value">{renderValue(detail)}</div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    // two columns layout
    if (data.length === 2) {
      return (
        <div className="detail-info-content">
          {data?.map((detail: IMgdDetailKeyValueProps, index: number) => (
            <div className={`col-type-two ${(index + 1) % 2 === 0 ? 'last' : ''}`} key={`${index}`}>
              <div className="content">
                <div className="title">
                  <p>{detail.title}</p>
                </div>
                <div className="value">{renderValue(detail)}</div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    // three columns layout
    if (data.length === 3) {
      return (
        <div className="detail-info-content">
          {data?.map((detail: IMgdDetailKeyValueProps, index: number) => (
            <div className={`col-type-three ${index === data.length - 1 ? 'last' : ''}`} key={`${index}`}>
              <div className="content">
                <div className="title">
                  <p>{detail.title}</p>
                </div>
                <div className="value">{renderValue(detail)}</div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="detail-info-content" ref={detailInfoRef}>
        {data?.map((detail: IMgdDetailKeyValueProps, index: number) => (
          <div
            className={`col ${
              (index + 1) % Math.floor((detailInfoWidth > 1160 ? 1160 : detailInfoWidth) / 290) === 0 ? 'last' : ''
            }`}
            key={`${index}`}
          >
            <div className="content">
              <div className="title">
                <p>{detail.title}</p>
              </div>
              <div className="value">{renderValue(detail)}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }, [data, detailInfoRef, detailInfoWidth, renderValue]);

  if (isApiLoading) {
    return (
      <div className="detail-info">
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      </div>
    );
  }

  return (
    <div className="detail-info">
      <Fragment>
        <div className="detail-info-title">
          <div>
            <p>{title}</p>
            <p>{description}</p>
          </div>

          <div>{actions}</div>
        </div>
        {renderContentNode()}
      </Fragment>
    </div>
  );
};

export default DetailTab;
