export type MacieSearchBarFindingStatus = 'Current' | 'Archived' | 'All';

export type FilterCriteria = {
  name: string;
  display: string;
  type: FilterValueType;
  value: SearchValueGeneralType;
  availableOption?: MULTI_SELECT_TYPE_OPTION_TYPE[];
};

export enum FilterType {
  FINDING = 'FINDING',
  RESOURCE_COVERAGE = 'RESOURCE_COVERAGE',
  S3_BUCKET = 'S3_BUCKET',
  JOB = 'JOB',
}

export enum FilterValueType {
  VALUE_TYPE = 'VALUE_TYPE',
  MULTI_SELECT_TYPE = 'MULTI_SELECT_TYPE',
  FROM_TO_TYPE = 'FROM_TO_TYPE',
  DATE_TYPE = 'DATE_TYPE',
}

export type ValueType = string;
export type MultiSelectType = MULTI_SELECT_TYPE_OPTION_TYPE[];
export type FromToType = {
  gte?: number;
  lte?: number;
};
export type DateType = {
  gte?: {
    date?: Date;
    time?: string;
  };
  lte?: {
    date?: Date;
    time?: string;
  };
};

export type SearchValueGeneralType = ValueType | MultiSelectType | FromToType | DateType;

export type MULTI_SELECT_TYPE_OPTION_TYPE = {
  display: string;
  value: string;
};
