import { gql } from '@apollo/client';

const query = gql`
  mutation deleteAwsGroup($request: AwsDeleteGroupRequest, $cloudId: Long, $region: String) {
    deleteAwsGroup(awsDeleteGroupRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsDeleteGroupResponse {
          group {
            groupArn
            name
            description
          }
        }
      }
    }
  }
`;
export default query;
