import { useMemo, useCallback } from 'react';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import CloseIcon from 'assets/svgs/v3/ico_close.svg';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconSearch from 'assets/svgs/v3/ico_search.svg';
import IpAddress from '../../../../IpSet/IpAddress';
import {
  BODY_CONTENT_TO_INSPECT,
  CONTENT_TO_INSPECT,
  CONTENT_TYPE,
  HEADERS_MATCH_SCOPE,
  IP_ADDRESS_SELECTOR,
  JA3_FINGERPRINT,
  JSON_MATCH_SCOPE,
  JSON_REQUEST,
  MATCH_SCOPE,
} from '../../../../constant';
import HeaderFieldName from '../../../../IpSet/HeaderFieldName';
import PositionInsideHeader from '../../../../IpSet/PositionInsideHeader';
import MissingIpAddress from '../../../../IpSet/MissingIpAddress';
import IpSetOption from '../../../../components/IpSetOption';
import TextTransformation from './TextTransformation';
import MatchType from './MatchType';
import Keys from './TextareaContainer';
import OversizeHandling from './OversizeHandling';
import InputContainer from './InputContainer';
import RadioGroupContainer from '../../../../components/RadioGroupContainer';

import './index.scss';
import { COUNTRY_CODES_DATA } from '../../../../countryCodes';

const InspectNode = ({
  cloudId,
  selectedRegion,
  values = {},
  handleChangeValues,
  errors,
  ipSets = [],
  isHidePositionInsideHeader = true,
}: any) => {
  const {
    id,
    countryCode,
    ipAddress,
    headerFieldName,
    positionInsideHeader,
    missingIpAddress,
    ipSet,
    matchScope,
    matchKey,
    matchType,
    transformation,
    headerMatchScope,
    contentToInspect,
    keys,
    oversizeHandling,
    queryArgument,
    contentType,
    jsonMatchScope,
    jsonRequest,
    bodyContentToInspect,
    includedElements,
    stringToMatch,
    ja3Fingerprint,
  } = values;

  const handleRemove = useCallback(
    (value: string) => {
      const newRows = countryCode.filter((data: any) => {
        return data.value !== value;
      });
      handleChangeItem('countryCode', newRows);
    },
    [countryCode],
  );

  const handleChangeItem = (key: string, value: any) => {
    handleChangeValues(id, key, value);
  };

  const renderShowing = useMemo(() => {
    switch (values.inspect?.value) {
      case 'geoMatchStatement':
        return (
          <>
            <div className="inspect-container">
              <div className="country-list">
                <p className="rule-container-group-content-label">Country codes</p>

                <DropdownAtom
                  id="dropdown-rule-statement-country-code"
                  className="mw-50"
                  data={COUNTRY_CODES_DATA}
                  placeholder="Choose country codes"
                  value={{
                    name: '',
                    value: '',
                  }}
                  handleClick={val => {
                    if (!countryCode.includes(val)) {
                      const newState = countryCode.concat(val);

                      handleChangeItem('countryCode', newState);
                    }
                  }}
                  error={errors.countryCodes}
                />

                <div className="dropdown-list">
                  {countryCode?.length
                    ? countryCode.map((data: any, index: number) => (
                        <div key={index}>
                          <span>{data.name}</span>
                          <button
                            onClick={() => {
                              handleRemove(data.value.toString());
                            }}
                          >
                            <img src={CloseIcon} width={24} height={24} />
                          </button>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>

            <IpAddress ipAddressSelector={ipAddress} setIpAddressSelector={val => handleChangeItem('ipAddress', val)} />

            {ipAddress === IP_ADDRESS_SELECTOR[1].value ? (
              <>
                <HeaderFieldName
                  headerFieldName={headerFieldName}
                  setHeaderFieldName={val => handleChangeItem('headerFieldName', val)}
                />

                <PositionInsideHeader
                  isHideOption={isHidePositionInsideHeader}
                  positionInsideHeader={positionInsideHeader}
                  setPositionInsideHeader={val => handleChangeItem('positionInsideHeader', val)}
                />

                <MissingIpAddress
                  missingIpAddress={missingIpAddress}
                  setMissingIpAddress={val => handleChangeItem('missingIpAddress', val)}
                />
              </>
            ) : null}
          </>
        );
      case 'ipSetReferenceStatement':
        return (
          <>
            <IpSetOption
              cloudId={cloudId}
              selectedRegion={selectedRegion}
              ipSet={ipSet}
              setIpSet={val => handleChangeItem('ipSet', val)}
              ipSets={ipSets}
            />

            <IpAddress ipAddressSelector={ipAddress} setIpAddressSelector={val => handleChangeItem('ipAddress', val)} />

            {ipAddress === IP_ADDRESS_SELECTOR[1].value ? (
              <>
                <HeaderFieldName
                  headerFieldName={headerFieldName}
                  setHeaderFieldName={val => handleChangeItem('headerFieldName', val)}
                />

                <PositionInsideHeader
                  positionInsideHeader={positionInsideHeader}
                  setPositionInsideHeader={val => handleChangeItem('positionInsideHeader', val)}
                />

                <MissingIpAddress
                  missingIpAddress={missingIpAddress}
                  setMissingIpAddress={val => handleChangeItem('missingIpAddress', val)}
                />
              </>
            ) : null}
          </>
        );
      case 'labelMatchStatement':
        return (
          <>
            <RadioGroupContainer
              title="Labels"
              description="Labels are strings that rules add to the web request. You can evaluate labels that are added by rules that run
        before this one in the same web ACL."
              options={MATCH_SCOPE}
              checked={matchScope}
              onChange={val => handleChangeItem('matchScope', val)}
            />

            <div>
              <p className="rule-container-group-content-label">Match key</p>

              <p className="rule-container-group-content-description">
                {matchScope === MATCH_SCOPE[0].value
                  ? 'Enter the string containing the label name and optional prefix and namespaces. For example, namespace1:name or awswaf:managed:aws:managed-rule-set:namespace1name.'
                  : 'Enter the string with optional prefix and namespaces separated by a colon and an ending colon. For example, namespace1:namespace2: or awswaf:111122223333:rulegroup:testRules:namspace1:namespace2:'}
              </p>

              <InputAtom
                className="mw-50"
                placeholder="Enter label"
                hasPrefixIcon={true}
                srcPrefixIcon={IconSearch}
                value={matchKey}
                onChangeValue={val => handleChangeItem('matchKey', val)}
              />
            </div>
          </>
        );
      case 'SINGLE_HEADER':
        return (
          <>
            <div>
              <p className="rule-container-group-content-label">Header file name</p>

              <InputAtom
                className="w-50"
                noClear={true}
                value={headerFieldName}
                onChangeValue={val => handleChangeItem('headerFieldName', val)}
              />
            </div>

            <MatchType matchType={matchType} setMatchType={val => handleChangeItem('matchType', val)} />
            <InputContainer
              title="String to match"
              value={stringToMatch}
              onChange={val => handleChangeItem('stringToMatch', val)}
            />
            <TextTransformation
              transformation={transformation}
              setTransformation={val => handleChangeItem('transformation', val)}
            />
          </>
        );
      case 'ALL_HEADER':
        return (
          <>
            <RadioGroupContainer
              title="Headers match scope"
              description="The parts of the headers to inspect with the rule inspection criteria."
              options={HEADERS_MATCH_SCOPE}
              checked={headerMatchScope}
              onChange={val => handleChangeItem('headerMatchScope', val)}
            />

            <RadioGroupContainer
              title="Content to inspect"
              description="Filter the header keys to get a subset of headers to inspect."
              options={CONTENT_TO_INSPECT}
              checked={contentToInspect}
              onChange={val => handleChangeItem('contentToInspect', val)}
            />

            {contentToInspect !== CONTENT_TO_INSPECT[0].value ? (
              <Keys
                title={contentToInspect === CONTENT_TO_INSPECT[1].value ? 'Keys to include' : 'Keys to exclude'}
                description={
                  contentToInspect === CONTENT_TO_INSPECT[1].value
                    ? 'Specify the keys for the headers that you want to inspect.'
                    : 'Specify the keys for the headers that you want to exclude from the inspection.'
                }
                value={keys}
                onChange={val => handleChangeItem('keys', val)}
                placeholder="User-Agent
Referer"
                note="Enter one key per line. The key matches are case sensitive and must be exact."
              />
            ) : null}

            <MatchType matchType={matchType} setMatchType={val => handleChangeItem('matchType', val)} />
            <InputContainer
              title="String to match"
              value={stringToMatch}
              onChange={val => handleChangeItem('stringToMatch', val)}
            />
            <TextTransformation
              transformation={transformation}
              setTransformation={val => handleChangeItem('transformation', val)}
            />

            <OversizeHandling
              oversizeHandling={oversizeHandling}
              setOversizeHandling={val => handleChangeItem('oversizeHandling', val)}
            />
          </>
        );
      case 'COOKIES':
        return (
          <>
            <RadioGroupContainer
              title="Headers match scope"
              description="The parts of the headers to inspect with the rule inspection criteria."
              options={HEADERS_MATCH_SCOPE}
              checked={headerMatchScope}
              onChange={val => handleChangeItem('headerMatchScope', val)}
            />

            <RadioGroupContainer
              title="Content to inspect"
              description="Filter the header keys to get a subset of headers to inspect."
              options={CONTENT_TO_INSPECT}
              checked={contentToInspect}
              onChange={val => handleChangeItem('contentToInspect', val)}
            />

            {contentToInspect !== CONTENT_TO_INSPECT[0].value ? (
              <Keys
                title={contentToInspect === CONTENT_TO_INSPECT[1].value ? 'Keys to include' : 'Keys to exclude'}
                description={
                  contentToInspect === CONTENT_TO_INSPECT[1].value
                    ? 'Specify the keys for the headers that you want to inspect.'
                    : 'Specify the keys for the headers that you want to exclude from the inspection.'
                }
                value={keys}
                onChange={val => handleChangeItem('keys', val)}
                placeholder="session-id-time
session-id"
                note="Enter one key per line. The key matches are case sensitive and must be exact."
              />
            ) : null}

            <MatchType matchType={matchType} setMatchType={val => handleChangeItem('matchType', val)} />
            <InputContainer
              title="String to match"
              value={stringToMatch}
              onChange={val => handleChangeItem('stringToMatch', val)}
            />
            <TextTransformation
              transformation={transformation}
              setTransformation={val => handleChangeItem('transformation', val)}
            />

            <OversizeHandling
              oversizeHandling={oversizeHandling}
              setOversizeHandling={val => handleChangeItem('oversizeHandling', val)}
            />
          </>
        );
      case 'SINGLE_QUERY':
      case 'ALL_QUERY':
        return (
          <>
            <InputContainer
              title="Query argument"
              value={queryArgument}
              onChange={val => handleChangeItem('queryArgument', val)}
            />

            <MatchType matchType={matchType} setMatchType={val => handleChangeItem('matchType', val)} />
            <InputContainer
              title="String to match"
              value={stringToMatch}
              onChange={val => handleChangeItem('stringToMatch', val)}
            />
            <TextTransformation
              transformation={transformation}
              setTransformation={val => handleChangeItem('transformation', val)}
            />
          </>
        );
      case 'URI_PATH':
      case 'QUERY_STRING':
        return (
          <>
            <MatchType matchType={matchType} setMatchType={val => handleChangeItem('matchType', val)} />
            <InputContainer
              title="String to match"
              value={stringToMatch}
              onChange={val => handleChangeItem('stringToMatch', val)}
            />
            <TextTransformation
              transformation={transformation}
              setTransformation={val => handleChangeItem('transformation', val)}
            />
          </>
        );
      case 'BODY':
        return (
          <>
            <RadioGroupContainer
              title="Content type"
              options={CONTENT_TYPE}
              checked={contentType}
              onChange={val => handleChangeItem('contentType', val)}
            />

            {contentType === CONTENT_TYPE[1].value ? (
              <>
                <RadioGroupContainer
                  title="JSON match scope"
                  options={JSON_MATCH_SCOPE}
                  checked={jsonMatchScope}
                  onChange={val => handleChangeItem('jsonMatchScope', val)}
                />

                <RadioGroupContainer
                  title="How AWS WAF should handle the request if the JSON in the request body is invalid"
                  options={JSON_REQUEST}
                  checked={jsonRequest}
                  onChange={val => handleChangeItem('jsonRequest', val)}
                />

                <RadioGroupContainer
                  title="Content to inspect"
                  options={BODY_CONTENT_TO_INSPECT}
                  checked={bodyContentToInspect}
                  onChange={val => handleChangeItem('bodyContentToInspect', val)}
                />

                {bodyContentToInspect === BODY_CONTENT_TO_INSPECT[1].value ? (
                  <Keys
                    title="Included elements"
                    description="Specify the JSON keys whose value need to be examined."
                    value={includedElements}
                    onChange={val => handleChangeItem('includedElements', val)}
                    placeholder="/dogs/0/name
/cats/0/name"
                    note="Enter one element per line."
                  />
                ) : null}
              </>
            ) : null}

            <MatchType matchType={matchType} setMatchType={val => handleChangeItem('matchType', val)} />
            <InputContainer
              title="String to match"
              value={stringToMatch}
              onChange={val => handleChangeItem('stringToMatch', val)}
            />
            <TextTransformation
              transformation={transformation}
              setTransformation={val => handleChangeItem('transformation', val)}
            />
          </>
        );
      case 'HTTP_METHOD':
        return (
          <>
            <MatchType matchType={matchType} setMatchType={val => handleChangeItem('matchType', val)} />

            <InputContainer
              title="String to match"
              value={stringToMatch}
              onChange={val => handleChangeItem('queryArgument', val)}
            />

            <TextTransformation
              transformation={transformation}
              setTransformation={val => handleChangeItem('transformation', val)}
            />
          </>
        );
      case 'JA3_FIGERPRINT':
        return (
          <>
            <MatchType matchType={matchType} setMatchType={val => handleChangeItem('matchType', val)} />

            <InputContainer
              title="String to match"
              value={stringToMatch}
              onChange={val => handleChangeItem('queryArgument', val)}
            />

            <TextTransformation
              transformation={transformation}
              setTransformation={val => handleChangeItem('transformation', val)}
            />

            <RadioGroupContainer
              title="Fallback for missing JA3 fingerprint"
              description="Match status to apply if AWS WAF is unable to determine the JA3 fingerprint for the request."
              options={JA3_FINGERPRINT}
              checked={ja3Fingerprint}
              onChange={val => handleChangeItem('ja3Fingerprint', val)}
            />
          </>
        );
      case 'HEADER_ORDER':
        return (
          <>
            <MatchType matchType={matchType} setMatchType={val => handleChangeItem('matchType', val)} />

            <InputContainer
              title="String to match"
              value={stringToMatch}
              onChange={val => handleChangeItem('queryArgument', val)}
            />

            <TextTransformation
              transformation={transformation}
              setTransformation={val => handleChangeItem('transformation', val)}
            />

            <OversizeHandling
              oversizeHandling={oversizeHandling}
              setOversizeHandling={val => handleChangeItem('oversizeHandling', val)}
            />
          </>
        );
      default:
        return null;
    }
  }, [values, errors, isHidePositionInsideHeader]);

  return <>{renderShowing}</>;
};

export default InspectNode;
