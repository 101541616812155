import { useMutation } from '@apollo/client';
import { AwsDisAssociateSubnetsRequest, AwsDisAssociateSubnetsResponse } from 'graphql/types/AwsAssociateSubnet';
import { IGqlResponseNewData } from '../../types';
import query from './query';

export type AwsDisAssociateSubnetsVariablesType = {
  cloudId: number;
  region:string;
  request: AwsDisAssociateSubnetsRequest
};

export type AwsDisAssociateSubnetsResponseDataType = {
  disassociateAwsSubnets: IGqlResponseNewData<AwsDisAssociateSubnetsResponse>;
};

const disassociateAwsSubnetsMutation = () =>
  useMutation<AwsDisAssociateSubnetsResponseDataType, AwsDisAssociateSubnetsVariablesType>(query);

export default disassociateAwsSubnetsMutation;
