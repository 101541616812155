import { useLazyQuery } from '@apollo/client';
import { IGqlResponseData } from 'graphql/types';
import query from './query';
import { AwsGetResourcesRequestType } from 'graphql/types/AwsGetResources';

export interface IGetResourcesVariables {
  cloudId: number;
  region: string;
  request: AwsGetResourcesRequestType
}

export interface IGetResourcesResponseData {
  getAwsResources: IGqlResponseData<any>;
}

const lazyGetAwsResources = () => useLazyQuery<IGetResourcesResponseData, IGetResourcesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsResources;