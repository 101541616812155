import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import Table from 'components/v2/dataDisplay/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { useEffect, useMemo, useState } from 'react';
import Button from '../../components/Button';
import './index.scss';
import { dummyFindingRows } from '../dummy';
import { ResizableBox } from 'react-resizable';
import Resize from 'assets/svgs/v2/aside_resize_handle.svg';

const dropdownActions: DropdownListDataType[] = [
  { name: 'actions', value: '' },
  { name: 'archive', value: 'archive' },
  { name: 'unarchive', value: 'unarchive' },
];

const initialPagination: IMgdTablePaginationProps = {
  limit: 50,
  itemPerPage: 10,
  target: 'modifiedAt',
  direction: OrderDirection.DES,
  currentPage: 1,
};

const MgdListUpGuardDutyFinding = () => {
  // search input
  const [searchTxt, setSearchTxt] = useState<string>('');
  const [selectedAction, setSelectedAction] = useState<DropdownListDataType>(dropdownActions[0]);

  // table
  const [selectedRow, setSelectedRow] = useState<string>('');
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);

  //pagination
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>(initialPagination);

  const [asideWidth, setAsideWidth] = useState<number>(Number(localStorage.getItem('organNavWidth')) || 192);
  const [asideHeight, setAsideHeight] = useState<number>(0);

  const changeAsideWidth = (width: number) => {
    setAsideWidth(width);
    localStorage.setItem('organNavWidth', width.toString());
  };

  // handle select row item
  const handleSelectRow = (rowId: string) => {
    const selectedId = rowId === selectedRow ? '' : rowId;
    setSelectedRow(selectedId);
  };

  const columns: ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Security',
        field: 'security',
        sort: true,
        renderCell: (row: RowType) => <span>{row.security}</span>,
      },
      {
        label: 'Finding type',
        field: 'type',
        sort: true,
        renderCell: (row: RowType) => <span>{row.type}</span>,
      },
      {
        label: 'Resource',
        field: 'resource',
        sort: true,
        renderCell: (row: RowType) => <span>{row.resource}</span>,
      },
      {
        label: 'Last seen',
        field: 'lastSeen',
        sort: true,
        renderCell: (row: RowType) => <span>{row.lastSeen}</span>,
      },
      {
        label: 'Count',
        field: 'count',
        sort: true,
        renderCell: (row: RowType) => <span>{row.count}</span>,
      },
    ];
  }, []);

  useEffect(() => {
    const resizeHandler = () => {
      const element = document.getElementById('mgd-finding-summary-wrapper');
      console.log('🚀 ~ resizeHandler ~ element:', element?.clientWidth);
      console.log('🚀 ~ resizeHandler ~ element:', element?.clientHeight);

      if (element) {
        setAsideWidth(element.clientWidth);
        setAsideHeight(element.clientHeight);
      }
    };
    resizeHandler();

    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return (
    <>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Findings</p>
        </div>

        <div className="flex action a-center">
          <InputAtom value={searchTxt} onChangeValue={setSearchTxt} hasPrefixIcon={true} srcPrefixIcon={SearchIcon} />
          <DropdownAtom
            id="mgd-finding-actions"
            data={dropdownActions}
            placeholder="Select cloud provider"
            value={selectedAction}
            handleClick={() => {}}
          />
        </div>
      </div>

      <div className="data-grid-wrap">
        <Table
          rows={dummyFindingRows}
          columns={columns}
          reportCheckedList={() => {}}
          reportSelected={handleSelectRow}
          useSelected={false}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {},
          }}
          isLoading={false}
          horizontalScrollable={true}
        />
        <div className="mgd-finding-btn flex j-center">
          <Button label="More" type="PRIMARY" loading={false} onClick={() => {}} />
        </div>
      </div>
    </>
  );
};
// <>
{
  /* <div className="mgd-finding-search flex j-between a-center">
        <p className="mgd-finding-search-title">Finding</p>
        <div className="mgd-finding-search-input flex a-center j-between">
          <InputAtom value={searchTxt} onChangeValue={setSearchTxt} hasPrefixIcon={true} srcPrefixIcon={SearchIcon} />
          <DropdownAtom
            id="mgd-finding-actions"
            data={dropdownActions}
            placeholder="Select cloud provider"
            value={selectedAction}
            handleClick={() => {}}
          />
        </div>
      </div>
      <div className="mgd-finding-table data-grid-wrap">
        <Table
          rows={dummyFindingRows}
          columns={columns}
          reportCheckedList={() => {}}
          reportSelected={handleSelectRow}
          useSelected={false}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {},
          }}
          isLoading={false}
          horizontalScrollable={true}
        />
        <div className="mgd-finding-btn flex j-center">
          <Button label="More" type="PRIMARY" loading={false} onClick={() => {}} />
        </div>
      </div> */
}

export default MgdListUpGuardDutyFinding;
