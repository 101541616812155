import './index.scss';
import { useEffect, useState } from 'react';
import Details from '../Common/Components/Details';
import lazyGetAwsFirewallPolicy from 'graphql/queries/getAwsFirewallPolicy';
import NetworkFirewallRuleGroups from '../Common/Components/NetworkFirewallRuleGroups';
import { firewallPolicyTab } from '../configs';
import InspectionConfiguration from '../Common/Components/InspectionConfiguration';

const FirewallPolicySummary = ({ cloudId, region, data, setPageDetail }: any) => {
  const [tabSelected, setTabSelected] = useState(firewallPolicyTab[0]);
  const [firewallPolicy, setFirewallPolicy] = useState<any>(null);
  const [firewallPolicyResponse, setfirewallPolicyResponse] = useState<any>(null);

  const [getAwsFirewallPolicy, { loading }] = lazyGetAwsFirewallPolicy();

  const handleGetAwsFirewallPolicy = async () => {
    if (!data?.id) return;

    try {
      const variables = {
        cloudId,
        region,
        request: {
          firewallPolicyArn: data?.arn,
        },
      };

      const { data: firewallPolicy } = await getAwsFirewallPolicy({
        variables,
      });

      setFirewallPolicy(firewallPolicy?.getAwsFirewallPolicy?.data?.[0]?.firewallPolicy);
      setfirewallPolicyResponse(firewallPolicy?.getAwsFirewallPolicy?.data?.[0]?.firewallPolicyResponse);
    } catch (error) {}
  };

  useEffect(() => {
    setTabSelected(firewallPolicyTab[0]);
    handleGetAwsFirewallPolicy();
  }, [data?.id]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case firewallPolicyTab[0].id:
        return <NetworkFirewallRuleGroups cloudId={cloudId} region={region} data={data} firewallPolicy={firewallPolicy} isLoading={loading} />;

      case firewallPolicyTab[1].id:
        return <InspectionConfiguration cloudId={cloudId} region={region} data={data} firewallPolicy={firewallPolicy} />;

      case firewallPolicyTab[2].id:
        return <Details data={data} firewallPolicy={firewallPolicy} firewallPolicyResponse={firewallPolicyResponse} />;

      default:
        return null;
    }
  };

  return data?.id ? (
    <div className="box-summary">
      <div className="box-summary-id">{data?.name}</div>

      <div className="detail-tab firewall-sumary-tab flex a-center">
        {firewallPolicyTab.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>

      <div className="content-tab firewall-sumary-content-tab">{renderTab()}</div>
    </div>
  ) : (
    <div className="detail-data">
      <p className="empty-page">Select a item</p>
    </div>
  );
};

export default FirewallPolicySummary;
