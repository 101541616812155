import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsGetNamespaceRequestType, AwsGetNamespaceResponseType } from 'graphql/types/AwsGetNamespace';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsGetNamespaceVariable {
  cloudId: number;
  region: string;
  reqData: AwsGetNamespaceRequestType;
}

export interface IGetAwsNamespaceResponseData {
  getAwsNamespace: IGqlResponseNewData<AwsGetNamespaceResponseType>;
}

const lazyGetAwsNamespace = () =>
  useLazyQuery<IGetAwsNamespaceResponseData, IAwsGetNamespaceVariable>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsNamespace;
