import RadioAtom from 'components/v2/atoms/RadioAtom';
import { RadioDataPropsType } from '../steps/types';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import './index.scss';

export type RadioGroupSectionPropsType = {
  title: string;
  description?: string;
  numberOfColumn?: number;
  data: RadioDataPropsType[];
  valueSelected: string;
  onChangeValue: (value: string) => void;
  error?: boolean | string;
};

const RadioGroupSection = (props: RadioGroupSectionPropsType) => {
  const { title, description, numberOfColumn = 2, data, valueSelected, onChangeValue, error } = props;

  const [dataGroupByColumn, setDataGroupByColumn] = useState<Array<Array<RadioDataPropsType>>>([]);

  const getDataConverted = useCallback(() => {
    const parentItems: Array<Array<RadioDataPropsType>> = [];
    let childItems: Array<RadioDataPropsType> = [];
    data.map((item, index) => {
      childItems.push(item);
      if ((index + 1) % numberOfColumn === 0) {
        parentItems.push(childItems);
        childItems = [];
      }
    });
    setDataGroupByColumn(parentItems);
  }, [data, numberOfColumn]);

  const renderRadioNode = useCallback((): ReactNode => {
    return (
      <div className="aws-key-radio-group-option-container">
        {dataGroupByColumn.map((parentItem, pIdx) => {
          return (
            <div className="aws-key-radio-group-option" key={`radio-group-parent-${pIdx}`}>
              {parentItem.map(({ id, label, value, name, description }) => (
                <RadioAtom
                  key={id}
                  label={label}
                  value={value}
                  name={name}
                  description={description}
                  checked={valueSelected}
                  onChange={value => onChangeValue(value)}
                />
              ))}
            </div>
          );
        })}
        {!!error && (
          <div className="error-container">
            <img src={InputErrorIcon} width={16} height={16} />
            <p className="error-message">{error}</p>
          </div>
        )}
      </div>
    );
  }, [dataGroupByColumn, valueSelected, onChangeValue]);

  useEffect(() => {
    getDataConverted();
  }, [numberOfColumn, data]);

  return (
    <div className="aws-key-radio-group">
      <div className="aws-key-radio-group-header">
        <p className="aws-key-radio-group-header-title">{title}</p>
        <p className="aws-key-radio-group-header-description">{description}</p>
      </div>
      {renderRadioNode()}
    </div>
  );
};

export default RadioGroupSection;
