import { gql } from '@apollo/client';

const query = gql`
query getAwsListWorkgroups($reqData: AwsListWorkgroupsRequest, $cloudId: Long, $region: String) {
  getAwsListWorkgroups(listWorkgroupsRequest: $reqData, cloudId: $cloudId, region: $region) {
    result
    nextToken
    data {
      ... on AwsListWorkgroupsResponse {
        workgroups {
          baseCapacity
          configParameters {
            parameterKey
            parameterValue
          }
          creationDate
          crossAccountVpcs
          customDomainCertificateArn
          customDomainCertificateExpiryTime
          customDomainName
          endpoint {
            address
            port
            vpcEndpoints {
              networkInterfaces {
                availabilityZone
                ipv6Address
                networkInterfaceId
                privateIpAddress
                subnetId
              }
              vpcEndpointId
              vpcId
            }
          }
          enhancedVpcRouting
          ipAddressType
          maxCapacity
          namespaceName
          patchVersion
          port
          publiclyAccessible
          securityGroupIds
          status
          subnetIds
          workgroupArn
          workGroupId
          workGroupName
          workgroupVersion
        }
        nextToken
      }
    }
  }
}`;
export default query;