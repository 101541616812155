import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import DetailTabTargetGroup from '../Common/components/DetailTabTargetGroup';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import { DetailPropsType } from '../types';
import lazyGetAwsDescribeTargetHealth, {
  IGetAwsDescribeTargetHealthVariables,
} from 'graphql/queries/getAwsDescribeTargetHealth';
import { AwsTargetHealthDescription } from 'graphql/types/AwsDescribeTargetHealthResponse';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';

const Details = (props: DetailPropsType) => {
  const { targetGroup, cloudId, region, isSummaryHidden } = props;

  const navigate = useNavigate();

  const [getAwsDescribeTargetHealth, { loading: awsDescribeTargetHealthLoading }] = lazyGetAwsDescribeTargetHealth();

  const [targetHealthDescriptions, setTargetHealthDescriptions] = useState<AwsTargetHealthDescription[]>([]);

  const fetchGetAwsDescribeTargetHealth = useCallback(() => {
    const healthVariableRequest: IGetAwsDescribeTargetHealthVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        targetGroupArn: targetGroup?.targetGroupArn,
      },
    };
    getAwsDescribeTargetHealth({ variables: healthVariableRequest }).then(({ data: awsDescribeTargetHealthData }) => {
      setTargetHealthDescriptions(
        awsDescribeTargetHealthData?.getAwsDescribeTargetHealth?.data?.[0]?.targetHealthDescriptions || [],
      );
    });
  }, [cloudId, region, targetGroup]);

  const handleItemClicked = useCallback((link: string, screen: string, tabId: string, key: string, value: string) => {
    navigate(link, {
      replace: true,
      state: {
        managementType: screen || ManagementTypeEnum.NETWORK,
        tabId: tabId,
        key: key,
        value: value,
      },
    });
  }, []);

  const detailsTargetData = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      {
        title: 'Target type',
        description: targetGroup?.targetType,
        type: TextTypeEnum.COPY,
      },
      {
        title: 'Protocol:Port',
        description: `${targetGroup?.protocol}:${targetGroup?.port}`,
        type: TextTypeEnum.STATUS,
      },
      {
        title: 'Protocol version',
        description: targetGroup?.protocolVersion,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'VPC',
        description: targetGroup?.vpcId,
        type: TextTypeEnum.LINK,
        handleItemClick: () =>
          handleItemClicked(
            '/organ/1/manage/network',
            ManagementTypeEnum.NETWORK,
            'vpc',
            'vpc-id',
            targetGroup?.vpcId ?? '',
          ),
      },
      {
        title: 'IP address type',
        description: targetGroup?.ipAddressType,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Load balancer',
        description: targetGroup?.loadBalancers?.map(lb => lb.loadBalancerName),
        type: TextTypeEnum.LINK,
        handleItemClick: (value: string) =>
          handleItemClicked(
            '/organ/1/manage/instance',
            ManagementTypeEnum.INSTANCE,
            'loadBalancer',
            'tag:Name',
            value ?? '',
          ),
      },
    ];
  }, [targetGroup]);

  const detailsTargetHealthData = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      {
        title: 'Total targets',
        description: targetHealthDescriptions.length,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Health',
        description: targetHealthDescriptions.filter(target => target?.targetHealth?.state == 'healthy').length,
        type: TextTypeEnum.GREEN,
      },
      {
        title: 'Anomalous',
        description: targetHealthDescriptions.filter(target => target?.anomalyDetection?.result == 'anomalous').length,
        type: TextTypeEnum.RED,
      },
      {
        title: 'Unhealth',
        description: targetHealthDescriptions.filter(target => target?.targetHealth?.state == 'unhealthy').length,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Unused',
        description: targetHealthDescriptions.filter(target => target?.targetHealth?.state == 'unused').length,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Initial',
        description: targetHealthDescriptions.filter(target => target?.targetHealth?.state == ' initial').length,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Draining',
        description: targetHealthDescriptions.filter(target => target?.targetHealth?.state == 'draining').length,
        type: TextTypeEnum.NORMAL,
      },
    ];
  }, [targetHealthDescriptions]);

  useEffect(() => {
    fetchGetAwsDescribeTargetHealth();
  }, []);

  if (awsDescribeTargetHealthLoading) {
    return (
      <div className="detail-info">
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <DetailTab title="Details" description={targetGroup?.targetGroupArn} data={detailsTargetData} />

      <DetailTabTargetGroup styleClass={'border-top-none'} numberOfColumns={1} data={detailsTargetHealthData} />
    </Fragment>
  );
};

export default Details;
