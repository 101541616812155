import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import moment from 'moment';
import { DateType, FilterCriteria, FilterType, FilterValueType, FromToType, MultiSelectType } from './types';
import _ from 'lodash';

export const FINDING_STATUS: DropdownListDataType[] = [
  {
    name: 'All',
    value: 'all',
  },
  {
    name: 'Archived',
    value: 'archived',
  },
  {
    name: 'Current',
    value: 'current',
  },
];

export const FINDING_FILTERS_OPTIONS: FilterCriteria[] = [
  {
    name: 'accountId',
    display: 'Account ID',
    type: FilterValueType.VALUE_TYPE,
    value: '',
  },
  {
    name: 'id',
    display: 'Finding ID',
    type: FilterValueType.VALUE_TYPE,
    value: '',
  },
  {
    name: 'resourcesAffected.s3Bucket.name',
    display: 'S3 bucket name',
    type: FilterValueType.VALUE_TYPE,
    value: '',
  },
  {
    name: 'severity.description',
    display: 'Severity',
    type: FilterValueType.MULTI_SELECT_TYPE,
    value: '',
    availableOption: [
      {
        display: 'Low',
        value: 'Low',
      },
      {
        display: 'Medium',
        value: 'Medium',
      },
      {
        display: 'High',
        value: 'High',
      },
    ],
  },
  {
    name: 'type',
    display: 'Finding type',
    type: FilterValueType.MULTI_SELECT_TYPE,
    value: '',
    availableOption: [
      { display: 'SensitiveData:S3Object/Financial', value: 'SensitiveData:S3Object/Financial' },
      { display: 'SensitiveData:S3Object/Personal', value: 'SensitiveData:S3Object/Personal' },
      { display: 'SensitiveData:S3Object/Credentials', value: 'SensitiveData:S3Object/Credentials' },
      { display: 'SensitiveData:S3Object/CustomIdentifier', value: 'SensitiveData:S3Object/CustomIdentifier' },
      { display: 'SensitiveData:S3Object/Multiple', value: 'SensitiveData:S3Object/Multiple' },
      { display: 'Policy:IAMUser/S3BucketPublic', value: 'Policy:IAMUser/S3BucketPublic' },
      { display: 'Policy:IAMUser/S3BucketSharedExternally', value: 'Policy:IAMUser/S3BucketSharedExternally' },
      { display: 'Policy:IAMUser/S3BucketReplicatedExternally', value: 'Policy:IAMUser/S3BucketReplicatedExternally' },
      { display: 'Policy:IAMUser/S3BucketEncryptionDisabled', value: 'Policy:IAMUser/S3BucketEncryptionDisabled' },
      { display: 'Policy:IAMUser/S3BlockPublicAccessDisabled', value: 'Policy:IAMUser/S3BlockPublicAccessDisabled' },
      { display: 'Policy:IAMUser/S3BucketSharedWithCloudFront', value: 'Policy:IAMUser/S3BucketSharedWithCloudFront' },
    ],
  },
  {
    name: 'classificationDetails.jobId',
    display: 'Job ID',
    type: FilterValueType.VALUE_TYPE,
    value: '',
  },
];

export const RESOURCE_COVERAGE_FILTERS_OPTIONS: FilterCriteria[] = [
  {
    name: 'bucketName',
    display: 'Name',
    type: FilterValueType.VALUE_TYPE,
    value: '',
  },
  {
    name: 'accountId',
    display: 'Account',
    type: FilterValueType.VALUE_TYPE,
    value: '',
  },
];

export const S3_BUCKET_FILTERS_OPTIONS: FilterCriteria[] = [
  {
    name: 'accountId',
    display: 'Account ID',
    type: FilterValueType.VALUE_TYPE,
    value: '',
  },
  {
    name: 'bucketName',
    display: 'Bucket name',
    type: FilterValueType.VALUE_TYPE,
    value: '',
  },
  {
    name: 'automatedDiscoveryMonitoringStatus',
    display: 'Is monitored by automated discovery',
    type: FilterValueType.MULTI_SELECT_TYPE,
    value: '',
    availableOption: [
      {
        display: 'Monitored',
        value: 'MONITORED',
      },
      {
        display: 'Not monitored',
        value: 'NOT_MONITORED',
      },
    ],
  },
  {
    name: 'sensitivityScore',
    display: 'Sensitivity',
    type: FilterValueType.FROM_TO_TYPE,
    value: {
      gte: undefined,
      lte: undefined,
    },
  },
  {
    name: 'classifiableObjectCount',
    display: 'Classifiable object count',
    type: FilterValueType.FROM_TO_TYPE,
    value: {
      gte: undefined,
      lte: undefined,
    },
  },
  {
    name: 'sizeInBytes',
    display: 'Total storage size',
    type: FilterValueType.FROM_TO_TYPE,
    value: {
      gte: undefined,
      lte: undefined,
    },
  },
  {
    name: 'lastAutomatedDiscoveryTime',
    display: 'Latest automated discovery run',
    type: FilterValueType.DATE_TYPE,
    value: {
      lte: undefined,
      gte: undefined,
    },
  },
];

export const JOB_FILTERS_OPTIONS: FilterCriteria[] = [
  {
    name: 'createdAt',
    display: 'Created at',
    type: FilterValueType.DATE_TYPE,
    value: {
      lte: undefined,
      gte: undefined,
    },
  },
  {
    name: 'name',
    display: 'Job name',
    type: FilterValueType.VALUE_TYPE,
    value: '',
  },
  {
    name: 'jobType',
    display: 'Job type',
    type: FilterValueType.MULTI_SELECT_TYPE,
    availableOption: [
      {
        display: 'Monitored',
        value: 'MONITORED',
      },
      {
        display: 'Not monitored',
        value: 'NOT_MONITORED',
      },
    ],
    value: '',
  },
  {
    name: 'jobStatus',
    display: 'Status',
    type: FilterValueType.MULTI_SELECT_TYPE,
    availableOption: [
      {
        display: 'Running',
        value: 'RUNNING',
      },
      {
        display: 'Idle',
        value: 'IDLE',
      },
      {
        display: 'Cancelled',
        value: 'CANCELLED',
      },
      {
        display: 'Complete',
        value: 'COMPLTETE',
      },
      {
        display: 'Paused (By Macie)',
        value: 'PAUSED',
      },
      {
        display: 'Paused (By user)',
        value: 'USER_PAUSED',
      },
    ],
    value: '',
  },
];

export const filterTypeToFilterCriteriaSearchParamMap = {
  [FilterType.FINDING]: {
    [FilterValueType.VALUE_TYPE]: (filterCriteria: FilterCriteria) => ({
      [filterCriteria.name]: {
        eq: [filterCriteria.value],
      },
    }),
    [FilterValueType.MULTI_SELECT_TYPE]: (filterCriteria: FilterCriteria) => ({
      [filterCriteria.name]: {
        eq: (filterCriteria.value as MultiSelectType).map(each => each.value),
      },
    }),
    [FilterValueType.FROM_TO_TYPE]: (filterCriteria: FilterCriteria) => ({
      [filterCriteria.name]: {
        lte: (filterCriteria.value as FromToType).lte || null,
        gte: (filterCriteria.value as FromToType).gte || null,
      },
    }),
    [FilterValueType.DATE_TYPE]: (filterCriteria: FilterCriteria) => {
      const filterCriteriaValueAsDateType = filterCriteria.value as DateType;
      let fromValue = getDateTimeFromDateAndTimeString(
        filterCriteriaValueAsDateType.lte?.date,
        filterCriteriaValueAsDateType.lte?.time,
      );
      let toValue = getDateTimeFromDateAndTimeString(
        filterCriteriaValueAsDateType.lte?.date,
        filterCriteriaValueAsDateType.lte?.time,
      );
      return {
        [filterCriteria.name]: {
          lte: fromValue?.getTime(),
          gte: toValue?.getTime(),
        },
      };
    },
  },
  [FilterType.S3_BUCKET]: {
    [FilterValueType.VALUE_TYPE]: (filterCriteria: FilterCriteria) => ({
      [filterCriteria.name]: {
        eq: [filterCriteria.value],
      },
    }),
    [FilterValueType.MULTI_SELECT_TYPE]: (filterCriteria: FilterCriteria) => ({
      [filterCriteria.name]: {
        eq: (filterCriteria.value as MultiSelectType).map(each => each.value),
      },
    }),
    [FilterValueType.FROM_TO_TYPE]: (filterCriteria: FilterCriteria) => ({
      [filterCriteria.name]: {
        lte: (filterCriteria.value as FromToType).lte || null,
        gte: (filterCriteria.value as FromToType).gte || null,
      },
    }),
    [FilterValueType.DATE_TYPE]: (filterCriteria: FilterCriteria) => {
      const filterCriteriaValueAsDateType = filterCriteria.value as DateType;
      let fromValue = getDateTimeFromDateAndTimeString(
        filterCriteriaValueAsDateType.lte?.date,
        filterCriteriaValueAsDateType.lte?.time,
      );
      let toValue = getDateTimeFromDateAndTimeString(
        filterCriteriaValueAsDateType.gte?.date,
        filterCriteriaValueAsDateType.gte?.time,
      );
      return {
        [filterCriteria.name]: {
          lte: fromValue?.getTime(),
          gte: toValue?.getTime(),
        },
      };
    },
  },
  [FilterType.RESOURCE_COVERAGE]: {
    [FilterValueType.VALUE_TYPE]: (filterCriteria: FilterCriteria) => ({
      [filterCriteria.name]: {
        eq: [filterCriteria.value],
      },
    }),
    [FilterValueType.MULTI_SELECT_TYPE]: (filterCriteria: FilterCriteria) => ({
      [filterCriteria.name]: {
        eq: (filterCriteria.value as MultiSelectType).map(each => each.value),
      },
    }),
    [FilterValueType.FROM_TO_TYPE]: (filterCriteria: FilterCriteria) => ({
      [filterCriteria.name]: {
        lte: (filterCriteria.value as FromToType).lte || null,
        gte: (filterCriteria.value as FromToType).gte || null,
      },
    }),
    [FilterValueType.DATE_TYPE]: (filterCriteria: FilterCriteria) => {
      const filterCriteriaValueAsDateType = filterCriteria.value as DateType;
      let fromValue = getDateTimeFromDateAndTimeString(
        filterCriteriaValueAsDateType.lte?.date,
        filterCriteriaValueAsDateType.lte?.time,
      );
      let toValue = getDateTimeFromDateAndTimeString(
        filterCriteriaValueAsDateType.gte?.date,
        filterCriteriaValueAsDateType.gte?.time,
      );
      return {
        [filterCriteria.name]: {
          lte: fromValue?.getTime(),
          gte: toValue?.getTime(),
        },
      };
    },
  },
  [FilterType.JOB]: {
    [FilterValueType.VALUE_TYPE]: (filterCriteria: FilterCriteria) => [
      { comparator: 'CONTAINS', key: filterCriteria.name, values: [filterCriteria.value] },
    ],
    [FilterValueType.MULTI_SELECT_TYPE]: (filterCriteria: FilterCriteria) => [
      {
        comparator: 'EQ',
        key: filterCriteria.name,
        values: (filterCriteria.value as MultiSelectType).map(each => each.value),
      },
    ],
    [FilterValueType.FROM_TO_TYPE]: (filterCriteria: FilterCriteria) => {
      const returnValue = [];
      const filterCriteriaValueAsFromToType = filterCriteria.value as FromToType;
      if (filterCriteriaValueAsFromToType.lte) {
        returnValue.push({
          comparator: 'LTE',
          key: filterCriteria.name,
          values: filterCriteriaValueAsFromToType.lte,
        });
      }
      if (filterCriteriaValueAsFromToType.gte) {
        returnValue.push({
          comparator: 'GTE',
          key: filterCriteria.name,
          values: filterCriteriaValueAsFromToType.gte,
        });
      }
      return returnValue;
    },
    [FilterValueType.DATE_TYPE]: (filterCriteria: FilterCriteria) => {
      const returnValue = [];
      const filterCriteriaValueAsDateType = filterCriteria.value as DateType;
      let fromValue = getDateTimeFromDateAndTimeString(
        filterCriteriaValueAsDateType.lte?.date,
        filterCriteriaValueAsDateType.lte?.time,
      );
      let toValue = getDateTimeFromDateAndTimeString(
        filterCriteriaValueAsDateType.gte?.date,
        filterCriteriaValueAsDateType.gte?.time,
      );
      if (fromValue) {
        returnValue.push({
          comparator: 'LTE',
          key: filterCriteria.name,
          values: fromValue.toISOString(),
        });
      }
      if (toValue) {
        returnValue.push({
          comparator: 'GTE',
          key: filterCriteria.name,
          values: toValue.toISOString(),
        });
      }
      return returnValue;
    },
  },
};

export const getDateTimeFromDateAndTimeString = (date: Date | undefined, time: string | undefined) => {
  let dateTimeString = '';
  if (!date) {
    return null;
  }
  dateTimeString += moment(date).format('YYYY-MM-DD');

  const timeMoment = moment(time, 'HH:mm:ss');
  if (timeMoment.isValid()) {
    dateTimeString += timeMoment.format('HH:mm:ss');
  } else {
    dateTimeString += '00:00:00';
  }

  return moment(dateTimeString, 'YYYY-MM-DDHH:mm:ss').toDate();
};

export const generateSearchParam = (filterType: FilterType, filters: FilterCriteria[], findingStatus: DropdownListDataType) : any => {
  let searchParams: any = {};
    let searchData;

    switch (filterType) {
      case FilterType.FINDING:
      case FilterType.S3_BUCKET:
      case FilterType.RESOURCE_COVERAGE:
        searchData = filters.reduce((prev: any, cur) => {
          const searchParam = filterTypeToFilterCriteriaSearchParamMap[filterType][cur.type](cur);
          if (cur.type === FilterValueType.VALUE_TYPE || cur.type === FilterValueType.MULTI_SELECT_TYPE) {
            _.mergeWith(prev, searchParam, (objValue, srcValue) => {
              if (_.isArray(objValue)) {
                return objValue.concat(srcValue);
              }
            });
          }

          if (cur.type === FilterValueType.FROM_TO_TYPE || FilterValueType.DATE_TYPE) {
            if (!prev[cur.name]) {
              _.mergeWith(prev, searchParam, (objValue, srcValue) => {
                if (_.isArray(objValue)) {
                  return objValue.concat(srcValue);
                }
              });
            }
          }
          return prev;
        }, {});

        if (filterType === FilterType.FINDING) {
          searchParams = {
            findingCriteria: {
              criterion: searchData,
            },
          };
          if (findingStatus.value === 'archived') {
            searchParams.findingCriteria.archived = {
              eq: ['true'],
            };
          } else if (findingStatus.value === 'current') {
            searchParams.findingCriteria.archived = {
              eq: ['false'],
            };
          }
        } else {
          searchParams = {
            criteria: searchData,
          };
        }
        break;
      case FilterType.JOB:
        searchData = filters.reduce((prev: any[], cur) => {
          if (cur.type === FilterValueType.VALUE_TYPE || cur.type === FilterValueType.MULTI_SELECT_TYPE) {
            const searchParam = filterTypeToFilterCriteriaSearchParamMap[filterType][cur.type](cur)[0];
            const exist = prev.find(each => each.key === cur.name);
            if (exist) {
              _.mergeWith(exist, searchParam, (objValue, srcValue) => {
                if (_.isArray(objValue)) {
                  return objValue.concat(srcValue);
                }
              });
            }
          }

          if (cur.type === FilterValueType.FROM_TO_TYPE || FilterValueType.DATE_TYPE) {
            const searchParam = filterTypeToFilterCriteriaSearchParamMap[filterType][cur.type](cur);
            const exist = prev.find(each => each.key === cur.name);
            if (!exist) {
              prev.push(...searchParam);
            }
          }
          return prev;
        }, []);
        searchParams = {
          filterCriteria: {
            includes: searchData,
            excludes: [],
          },
        };
        break;
      default:
        break;
    }
    return searchParams;
}