import { OrderDirection } from '@Types/v2/Table';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import lazyGetAwsLoadBalancers from 'graphql/queries/getAwsLoadBalancers';
import { AwsLoadBalancer } from 'graphql/types/AwsLoadBalancer';
import { getFormatedDateWithTimezone } from 'utils/Common';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { FilterPropsType } from 'pages/v2/Organ/Management/type';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { LOAD_BALANCER_SEARCH_TYPES, LOAD_BALANCE_COLUMN_LIST } from '../../../configs';
import { LoadBalancerPropsType } from '../../types';

const keySearch: any = {
  'tag:Name': 'name',
  'dns-name': 'dnsName',
  'availability-zone': 'availabilityZones',
  'state-name': 'state',
  'vpc-id': 'vpcId',
};

const LoadBalancer = (props: LoadBalancerPropsType) => {
  const {
    tabInformation,
    cloudId,
    region,
    onNameClicked,
    onLoadBalancerSelected,
    currentRouteState,
    setCurrentRouteState,
  } = props;

  // API
  const [getAwsLoadBalancers, { loading: awsLoadBalancersLoading }] = lazyGetAwsLoadBalancers();

  // State
  const [tablePagination, setTablePagination] = useState({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [loadBalancers, setLoadBalancers] = useState<Array<AwsLoadBalancer>>([]);

  const currentFilter = currentRouteState && LOAD_BALANCER_SEARCH_TYPES.find(e => e.value === currentRouteState.key);
  const [searchType, setSearchType] = useState(currentFilter || LOAD_BALANCER_SEARCH_TYPES[0]);
  const [searchValue, setSearchValue] = useState<{ name: string; value: string }>({
    name: searchType.value as string,
    value: currentRouteState?.value || '',
  });
  const [scopeNFilterVariable, setScopeNFilterVariable] = useState<FilterPropsType | undefined>(searchValue);

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const getLoadBalancers = useCallback(() => {
    const variables = {
      cloudId: cloudId,
      region: region.value as string,
      request: {
        pageSize: 100,
      },
    };

    getAwsLoadBalancers({ variables }).then(res => {
      setLoadBalancers(res?.data?.getAwsLoadBalancers?.data?.[0]?.loadBalancers || []);
    });
  }, [getAwsLoadBalancers, cloudId]);

  // Init data
  useEffect(() => {
    getLoadBalancers();
  }, [getLoadBalancers]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const onHandleLoadBalancerNameClicked = useCallback(
    (loadBalancerId: string) => {
      onLoadBalancerSelected(loadBalancers.find(lb => lb.loadBalancerName === loadBalancerId)!);
      onNameClicked();
    },
    [loadBalancers],
  );

  const loadBalancerTableRows = useMemo(() => {
    return loadBalancers?.map(loadBalancer => {
      const { loadBalancerName, dnsName, state, vpcId, availabilityZones, type, createdTime } = loadBalancer;

      return {
        id: loadBalancerName,
        name: loadBalancerName ?? '-',
        dnsName: dnsName ?? '-',
        state: state?.code ?? '-',
        vpcId: vpcId ?? '-',
        availabilityZones: availabilityZones?.map(zone => zone?.zoneName),
        type: type,
        dateCreated: getFormatedDateWithTimezone(createdTime, 'MMM DD, YYYY HH:MM') ?? '-',
        onLoadBalancerNameClicked: onHandleLoadBalancerNameClicked,
      };
    });
  }, [loadBalancers]);

  const currentPageLoadBalancerTableRows = useMemo(() => {
    const firstItemIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const lastItemIndex = tablePagination.currentPage * tablePagination.itemPerPage - 1;

    const arr = loadBalancerTableRows?.filter((_, index) => index >= firstItemIndex && index <= lastItemIndex);

    let totalRows = arr;
    if (scopeNFilterVariable && scopeNFilterVariable?.value !== '' && scopeNFilterVariable?.name !== '') {
      totalRows = arr.filter((row: any) => {
        const key: any = keySearch[scopeNFilterVariable.name];

        return row[key]?.includes(scopeNFilterVariable.value);
      });
    }

    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(totalRows, tablePagination.target, tablePagination.direction).slice(startIndex, endIndex);
  }, [loadBalancerTableRows, tablePagination, scopeNFilterVariable]);

  console.log(currentPageLoadBalancerTableRows);

  const isLoading = useMemo(() => {
    return awsLoadBalancersLoading;
  }, [awsLoadBalancersLoading]);

  const handleSearch = useCallback(() => {
    updateTablePagination('currentPage', 1);

    if (searchValue?.value) {
      setScopeNFilterVariable(searchValue);
      return;
    }

    setScopeNFilterVariable(undefined);
  }, [searchValue]);

  return (
    <>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>{tabInformation.title}</p>
          <p>
            Elastic Load Balancing scales your load balancer capacity automatically in response to changes in incoming
            traffic.
          </p>
        </div>

        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={LOAD_BALANCER_SEARCH_TYPES}
            value={searchType}
            handleClick={e => {
              setSearchType(e);
              setSearchValue(prev => ({ name: e.value?.toString(), value: prev.value }));
            }}
          />
          <InputAtom
            type={'text'}
            value={searchValue?.value}
            defaultValue={''}
            onChangeValue={value => setSearchValue(prev => ({ name: prev.name, value }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={handleSearch}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearch();
              }
            }}
          />
        </div>
      </div>
      <div className="data-grid-wrap">
        {currentPageLoadBalancerTableRows.length === 0 && isLoading === false ? (
          <p className="empty-row">Empty</p>
        ) : (
          <Fragment>
            <Table
              rows={currentPageLoadBalancerTableRows}
              columns={LOAD_BALANCE_COLUMN_LIST}
              reportCheckedList={() => {}}
              reportSelected={id => {
                onLoadBalancerSelected(loadBalancers.find(item => item.loadBalancerName === id)!);
              }}
              sortOption={{
                target: tablePagination.target,
                direction: tablePagination.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  updateTablePagination('target', target);
                  updateTablePagination('direction', dir);
                },
              }}
              horizontalScrollable={true}
              isLoading={isLoading}
            />
            {loadBalancerTableRows?.length > 0 && !isLoading && (
              <div className="pagination-wrapper flex a-center">
                <p className="flex a-center">
                  Total <span>{loadBalancerTableRows.length}</span>
                </p>
                <TableManagePagination
                  ableFetchMore={false}
                  currentPage={tablePagination.currentPage}
                  updateCurrentPage={page => updateTablePagination('currentPage', page)}
                  totalPage={Math.ceil(loadBalancerTableRows.length / tablePagination.itemPerPage)}
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
    </>
  );
};

export default LoadBalancer;
