import { useMutation } from '@apollo/client';
import query from './query';

interface IAwsMatchAttributesInput {
  sources: string[];
  destinations: string[];
  sourcePorts?: { fromPort: number; toPort: number }[];
  destinationPorts?: { fromPort: number; toPort: number }[];
  protocols?: number[];
  tcpFlags?: { flags?: string[]; masks?: string[] }[];
}
interface IAwsRuleDefinitionInput {
  matchAttributes: IAwsMatchAttributesInput;
  actions: ('aws:pass' | 'aws:drop' | 'aws:forward_to_sfe')[];
}
export interface IAwsStatelessRuleInput {
  priority: number;
  ruleDefinition: IAwsRuleDefinitionInput;
}
interface IAwsStatelessRulesAndCustomActionsInput {
  statelessRules: IAwsStatelessRuleInput[];
  customActions: [];
}
export interface IAwsStatefulRuleInput {
  action: 'PASS' | 'DROP' | 'ALERT' | 'REJECT';
  header: {
    protocol: string;
    source: string;
    sourcePort: string;
    destination: string;
    destinationPort: string;
    direction: string;
  };
  ruleOptions: { keyword: string; settings: string[] }[];
}
export interface IAwsRulesSourceInput {
  rulesString?: string;
  rulesSourceList?: { targets: string[]; targetTypes: string[]; generatedRulesType: 'ALLOWLIST' | 'DENYLIST' };
  statefulRules?: IAwsStatefulRuleInput[];
  statelessRulesAndCustomActions?: IAwsStatelessRulesAndCustomActionsInput;
}
interface IAwsRuleVariablesInput {
  ipSets: Record<string, any>;
  portSets: Record<string, any>;
}
export interface IAwsRuleGroupFirewall {
  ruleVariables?: IAwsRuleVariablesInput;
  referenceSets?: { ipSetReferences?: Record<string, any> };
  rulesSource?: IAwsRulesSourceInput;
  statefulRuleOptions?: { ruleOrder: 'DEFAULT_ACTION_ORDER' | 'STRICT_ORDER' };
}
export interface IAwsTaglog {
  key: string;
  value: string;
}
interface IAwsEncryptionConfigurationLog {
  keyId?: string;
  type: 'CUSTOMER_KMS' | 'AWS_OWNED_KMS_KEY';
}
interface IAwsSourceMetadataFirewall {
  sourceArn: string;
  sourceUpdateToken: string;
}

export interface ICreateAwsRuleGroupFirewallVariables {
  cloudId: number;
  region: string;
  request: {
    ruleGroupName: string;
    ruleGroup: IAwsRuleGroupFirewall;
    rules?: string;
    type: string;
    description?: string;
    capacity: number;
    tags?: IAwsTaglog[];
    dryRun?: boolean;
    encryptionConfiguration: IAwsEncryptionConfigurationLog;
    sourceMetadata?: IAwsSourceMetadataFirewall;
    analyzeRuleGroup?: boolean;
  };
}

export interface ICreateAwsRuleGroupFirewallResponseData {
  updateToken: '';
  ruleGroupResponse: {
    ruleGroupArn: string;
    ruleGroupName: string;
    ruleGroupId: string;
    description: string;
    type: string;
    capacity: number;
    ruleGroupStatus: string;
    tags: {
      key: string;
      value: string;
    }[];
    consumedCapacity: number;
    numberOfAssociations: string;
    encryptionConfiguration: {
      keyId: string;
      type: string;
    };
    sourceMetadata: {
      keyId: string;
      type: string;
    };
    snsTopic: string;
    lastModifiedTime: string;
    analysisResults: {
      identifiedRuleIds: string;
      identifiedType: string;
      analysisDetail: string;
    };
  };
}

const createAwsRuleGroupFirewallMutation = () =>
  useMutation<ICreateAwsRuleGroupFirewallResponseData, ICreateAwsRuleGroupFirewallVariables>(query);
export default createAwsRuleGroupFirewallMutation;
