import { AwsGroupIdentifier } from "graphql/types/AwsListGroups";
import { AwsTLSInspectionConfigurationMetadata } from "graphql/types/AwsTLSInspectionConfigurationMetadata";
import { IMgdTabProps } from 'layouts/v3/MgdLayout';

export enum EditFirewallDetailModalTypeEnum {
  EditRuleGroup = 'EditRuleGroup',
  EditFireWallDetail = 'EditFireWallDetail',
}



export type AwsTLSInspectionConfigPropsType = {
  cloudId: number;
  region: string;
  tabInformation: IMgdTabProps;
  onTlsInpsectionSelected: (tlsInpsectionConfig: AwsTLSInspectionConfigurationMetadata) => void;
  onTlsInpsectionIdClicked: () => void;
  currentRouteState: any;
  setCurrentRouteState: (value: any) => void;
  onNavigateCreation: () => void;
}

export type AwsResourceGroupPropsType = {
  cloudId: number;
  region: string;
  tabInformation: IMgdTabProps;
  onResourceGroupSelected: (resourceGroup: AwsGroupIdentifier) => void;
  onResourceGroupIdClicked: () => void;
  currentRouteState: any;
  setCurrentRouteState: (value: any) => void;
  onNavigateCreation: () => void;
}

export type TagRowDataType = {
  index: number;
  key: string;
  value: string;
  flagDel?: boolean;
}

export type TagRowDataErrType = {
  index: number;
  key: string;
  errorKey?: string;
  errorValue?: string;
}