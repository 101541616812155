import { ErrorCode } from '@Types/error';
import IconEditFirewallDetail from 'assets/svgs/v3/ico_edit_firewall_detail.svg';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import Icon from 'components/v2/atoms/Icon';
import BaseModal from 'components/v2/modals/BaseModal';
import updateFirewallSubnetChangeProtectionMutation, {
  AwsUpdateFirewallSubnetChangeProtectionVariablesType,
} from 'graphql/mutations/updateAwsSubnetChangeProtection';
import updateFirewallDeleteProtectionMutation, {
  AwsUpdateFirewallDeleteProtectionVariablesType,
} from 'graphql/mutations/updateFirewallDeleteProtection';
import { useToast } from 'hooks/v2/useToast';
import { useState } from 'react';
import { IAwsFirewall, IBaseFirewallModalProps } from '../../../types';
import './index.scss';

interface IProtectionAgainstChangeProps extends IBaseFirewallModalProps {
  onChangeValue?: (index: number, value: string) => void;
}

const ProtectionAgainstChangeModal = ({
  header,
  cloudId,
  region,
  data,
  setData = () => { },
  onChangeValue,
  ...baseModalProps
}: IProtectionAgainstChangeProps) => {
  const {
    firewallArn = '',
    firewallName = '',
    deleteProtection = false,
    subnetChangeProtection = false,
  } = data?.firewall || {};

  const updateToken = data?.updateToken || '';

  const [newDeleteProtection, setNewDeleteProtection] = useState<boolean>(deleteProtection);
  const [newSubnetChangeProtection, setNewSubnetChangeProtection] = useState<boolean>(subnetChangeProtection);

  const [updateFirewallDeleteProtection] = updateFirewallDeleteProtectionMutation();
  const [updateFirewallSubnetChangeProtection] = updateFirewallSubnetChangeProtectionMutation();

  const handleUpdDeleteProtection = async () => {
    const deleteProtectionVariables: AwsUpdateFirewallDeleteProtectionVariablesType = {
      cloudId,
      region,
      request: {
        updateToken,
        deleteProtection: newDeleteProtection,
        firewallArn,
        firewallName,
      },
    };

    const response = await updateFirewallDeleteProtection({ variables: deleteProtectionVariables });
    return response.data?.updateAwsFirewallDeleteProtection.data[0];
  };

  const handleUpdSubnetChangeProtection = async (updateToken: string) => {
    const subnetChangeProtectionVariables: AwsUpdateFirewallSubnetChangeProtectionVariablesType = {
      cloudId,
      region,
      request: {
        updateToken,
        subnetChangeProtection: newSubnetChangeProtection,
        firewallArn,
        firewallName,
      },
    };

    const response = await updateFirewallSubnetChangeProtection({
      variables: subnetChangeProtectionVariables,
    });

    return response.data?.updateAwsSubnetChangeProtection.data[0];
  };

  const handleUpdateFirewallChangeProtection = async () => {
    if (newDeleteProtection === deleteProtection && newSubnetChangeProtection === subnetChangeProtection) {
      baseModalProps?.onClose?.();
      return;
    }

    let updToken = updateToken;

    try {
      let updatedDeleteProtectionVal = deleteProtection;
      let updatedSubnetChangeProtectionVal = subnetChangeProtection;

      if (newDeleteProtection !== deleteProtection) {
        const deleteProtectionRes = await handleUpdDeleteProtection();
        updToken = deleteProtectionRes?.updateToken || updateToken;
        updatedDeleteProtectionVal = deleteProtectionRes?.deleteProtection ?? deleteProtection;
      }

      if (newSubnetChangeProtection !== subnetChangeProtection) {
        const subnetChangeProtectionRes = await handleUpdSubnetChangeProtection(updToken);
        updToken = subnetChangeProtectionRes?.updateToken || updateToken;
        updatedSubnetChangeProtectionVal = subnetChangeProtectionRes?.subnetChangeProtection ?? subnetChangeProtection;
      }

      setData((prevState: IAwsFirewall) => ({
        ...prevState,
        updateToken: updToken,
        firewall: {
          ...prevState.firewall,
          subnetChangeProtection: updatedSubnetChangeProtectionVal,
          deleteProtection: updatedDeleteProtectionVal,
        },
      }));

      useToast(ErrorCode.SUCCESS, 'Update KMS encryption settings successfully.');

    } catch (err) {
      useToast(ErrorCode.UNKNOWN, 'Update KMS encryption settings failed.');
    } finally {
      baseModalProps?.onClose?.();
    }
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditFirewallDetail} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="protection-change-modal">
        <div className="protection-change">
          <div className="delete-protection">
            <p className="text-title">Delete protection</p>
            <p className="text-description">
              Protects the firewall from deletion. If enabled, Network firewall won’t delete the firewall if it’s in
              use.
            </p>
            <label className="labeled flex a-center">
              <CheckboxAtom
                id="checkbox-protection"
                checked={newDeleteProtection}
                onchange={() => setNewDeleteProtection(!newDeleteProtection)}
              />
              Enable
            </label>
          </div>

          <div className="delete-protection">
            <p className="text-title">Subnet change protection</p>
            <p className="text-description">
              Protects the firewall against changes to the subnet associations. If enabled, you can’t change in active
              firewall’s subnet associations
            </p>
            <label className="labeled flex a-center">
              <CheckboxAtom
                id="checkbox-protection"
                checked={newSubnetChangeProtection}
                onchange={() => setNewSubnetChangeProtection(!newSubnetChangeProtection)}
              />
              Enable
            </label>
          </div>
        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={handleUpdateFirewallChangeProtection}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default ProtectionAgainstChangeModal;
