import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsCreateKeyRequestResponseType, AwsCreateKeyRequestType } from 'graphql/types/AwsCreateKey';

export interface ICreateAwsKeyVariables {
  reqData: AwsCreateKeyRequestType;
  cloudId: number;
  region: string;
}

export interface ICreateAwsKeyResponseData {
  createAwsKey: IGqlResponseNewData<AwsCreateKeyRequestResponseType>;
}

const createAwsKeyMutation = () => useMutation<ICreateAwsKeyResponseData, ICreateAwsKeyVariables>(query);
export default createAwsKeyMutation;