import { PropsWithChildren, useEffect, useState } from 'react';
import './index.scss';

import { ResizableBox } from 'react-resizable';
import Handle from 'assets/svgs/v4/chatbot_handle.svg';

interface IChatBotAsideLnb extends PropsWithChildren {
  open: boolean;
}

const ChatBotAsideLnb = ({ children, open }:IChatBotAsideLnb) => {

  const [asideWidth, setAsideWidth] = useState<number>(Number(localStorage.getItem('chatBotNavWidth')) || 192);
  const [asideHeight, setAsideHeight] = useState<number>(0);

  const changeAsideWidth = (width: number) => {
    setAsideWidth(width);
    localStorage.setItem('chatBotNavWidth', width.toString());
  };

  useEffect(() => {
    const chatbotAsideElement = document.getElementById('chatbot-aside');
    if(open) {
      if(chatbotAsideElement) {
        chatbotAsideElement.classList.add('open');
        chatbotAsideElement.style.width = `${asideWidth}px`;
        setTimeout(() => {chatbotAsideElement.classList.remove('open')}, 300);
      }

      const resizeHandler = () => {
        const parentElement = document.getElementById('chatbot-aside');
        const element = document.getElementById('chatbot-aside')?.getElementsByClassName('react-resizable')[0];

        if (element) {
          setAsideWidth(element.clientWidth);
        }
        if (parentElement) {
          console.log(parentElement);
          setAsideHeight(parentElement.clientHeight);
        }
      };
      resizeHandler();

      window.addEventListener('chatbot-resize', resizeHandler);
      window.addEventListener('resize', resizeHandler);

      return () => {
        window.removeEventListener('chatbot-resize', resizeHandler);
        window.removeEventListener('resize', resizeHandler);
      }
    } else {
      if(chatbotAsideElement) {
        chatbotAsideElement.classList.add('close');
        chatbotAsideElement.style.width = `32px`;
        setTimeout(() => {chatbotAsideElement.classList.remove('close')}, 300);
      }
    }
  },[open]);
  
  return (
    <aside id="chatbot-aside" style={(open && process.env.REACT_APP_NEMO_CHATBOT_ENABLE !== 'false') ? {width: `${asideWidth}px`} : {width: '32px'}}>
      <ResizableBox
        draggableOpts={{ draggable: true }}
        width={asideWidth}
        height={asideHeight}
        minConstraints={[192, asideHeight]}
        maxConstraints={[400, asideHeight]}
        onResizeStart={e => {
          e.preventDefault();
        }}
        onResizeStop={e => {
          e.preventDefault();
        }}
        onResize={(_, { size }) => changeAsideWidth(size.width)}
        resizeHandles={["w"]}
        handle={<div className="chatbot-aside-handle flex a-center j-center" >
          <img src={Handle} width={5} height={35} />
        </div>
        }
      >
      <div>
        {children}
      </div>
      </ResizableBox>
    </aside>
  )
};

export default ChatBotAsideLnb;