import Table from 'components/v2/dataDisplay/Table';
import InputGroup from 'pages/v2/Organ/Management/components/InputGroup';
import SectionContainer from 'pages/v2/Organ/Management/components/SectionContainer';
import AddAnotherAwsAccount from 'pages/v2/Organ/Management/components/AddAnotherAwsAccount';
import { StepContainer } from 'pages/v2/Organ/Management/WAF/WebACL/CreateWebACL/components';
import { AccountRowType } from 'pages/v2/Organ/Management/components/types';
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CreationDataPropsType, DefineKeyUsagePermissionPropsType } from './types';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdTabLayout/types';
import lazyGetAwsListUsers, { IAwsListUsersVariables } from 'graphql/queries/getAwsListUsers';
import lazyGetAwsListRoles, { IGetListRolesVariables } from 'graphql/queries/getAwsListRoles';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';

const DefineKeyUsagePermission = (props: DefineKeyUsagePermissionPropsType) => {
  const { title, creationData, cloudId, region, updateCreationData } = props;

  // API
  const [getAwsListUsers, { loading: getListUserLoading }] = lazyGetAwsListUsers();
  const [getAwsListRoles, { loading: getListRoleLoading }] = lazyGetAwsListRoles();

  // State
  const [nextToken, setNextToken] = useState<string>('');
  const [listAccountArn, setListAccountArn] = useState<AccountRowType[]>(creationData?.otherAwsAccounts ?? []);
  const [listRowUser, setListRowUser] = useState<RowType[]>([]);
  const [listRowRole, setListRowRole] = useState<RowType[]>([]);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [tableTotal, setTableTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [tablePagination, setTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const apiLoading = useMemo((): boolean => {
    return getListUserLoading || getListRoleLoading;
  }, [getListUserLoading, getListRoleLoading]);

  const columns = useMemo((): ColumnType[] => {
    return [
      { label: 'Name', field: 'name', sort: true },
      { label: 'Path', field: 'path', sort: true },
      { label: 'Type', field: 'type', sort: true, width: 120 },
    ];
  }, []);

  const totalRowData = useMemo((): RowType[] => {
    const totalRows = [...listRowUser, ...listRowRole];
    setTableTotal({
      totalPage: Math.ceil(totalRows.length / tablePagination.itemPerPage),
      totalElement: totalRows.length,
    });
    return totalRows;
  }, [listRowUser, listRowRole, tablePagination]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(totalRowData, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [totalRowData, tableTotal, tablePagination, nextToken]);

  const userReqVariable = useMemo((): IAwsListUsersVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        maxItems: tablePagination.limit,
      },
    };
  }, [cloudId, region, tablePagination]);

  const fetchListUser = useCallback(
    async (nextToken?: string, isInitial?: boolean) => {
      const combinedVariable = variableCombineNextToken(userReqVariable, nextToken);

      const response = await getAwsListUsers({ variables: combinedVariable }).then(({ data: awsListUserResponse }) => {
        return awsListUserResponse;
      });

      const listUserResponse = response?.getAwsListUsers?.data?.[0];
      const listUser = listUserResponse?.users ?? [];

      if (listUser.length > 0) {
        const userRows: RowType[] = [];
        listUser.map(user => {
          userRows.push({
            ...user,
            name: user.userName,
            id: user.userId,
            type: 'User',
          });
        });

        let totalRows: RowType[] = [];
        if (isInitial) {
          totalRows = userRows;
        } else {
          totalRows = [...listRowUser, ...userRows];
        }
        setListRowUser(totalRows);

        const remainNumber = tablePagination.limit - listUser.length;
        if (remainNumber !== 0) {
          fetchListRole('', true, remainNumber);
        } else {
          setNextToken(listUserResponse?.marker ?? '');
        }
      }
    },
    [userReqVariable, listRowUser],
  );

  const roleReqVariable = useMemo((): IGetListRolesVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        maxItems: tablePagination.limit,
      },
    };
  }, [cloudId, region, tablePagination]);

  const getRoleReqVariable = useCallback(
    (remainNumber?: number): IGetListRolesVariables => {
      return {
        cloudId: cloudId,
        region: region,
        request: {
          maxItems: remainNumber ? remainNumber : tablePagination.limit,
        },
      };
    },
    [cloudId, region, tablePagination],
  );

  const fetchListRole = useCallback(
    async (nextToken?: string, isInitial?: boolean, remainNumber?: number) => {
      const roleReqVariable = getRoleReqVariable(remainNumber);
      const combinedVariable = variableCombineNextToken(roleReqVariable, nextToken);

      const response = await getAwsListRoles({ variables: combinedVariable }).then(({ data: awsListRoleResponse }) => {
        return awsListRoleResponse;
      });

      const listRoleResponse = response?.getAwsListRoles?.data?.[0];
      const listRole = listRoleResponse?.roles ?? [];
      if (listRole.length > 0) {
        const roleRows: RowType[] = [];
        listRole.map(role => {
          roleRows.push({
            ...role,
            id: role.roleId,
            name: role.roleName,
            type: 'Role',
          });
        });

        let totalRows: RowType[] = [];
        if (isInitial) {
          totalRows = roleRows;
        } else {
          totalRows = [...listRowRole, ...roleRows];
        }
        setNextToken(listRoleResponse?.marker ?? '');
        setListRowRole(totalRows);
      }
    },
    [roleReqVariable, listRowRole],
  );

  const initCheckedList = useCallback(() => {
    const currentChecked: string[] = [];
    if (creationData?.keyUsageSelected && creationData.keyUsageSelected.length > 0) {
      creationData.keyUsageSelected.map(rowData => {
        currentChecked.push(rowData.id?.toString() ?? '');
      });
    }
    setCheckedList(currentChecked);
  }, [creationData]);

  // const initListAccount = useCallback(() => {
  //   setListAccountArn(creationData?.otherAwsAccounts ?? []);
  // }, [creationData]);

  useEffect(() => {
    fetchListUser('', true);
    initCheckedList();
  }, []);

  // useEffect(() => {
  //   initListAccount();
  // }, [creationData.otherAwsAccounts]);

  const onCheckedTableChange = useCallback((list: string[]) => {
    setCheckedList(list);
    const listRowSelected: RowType[] = []
    if (list.length > 0) {
      list.map(item => {
        const rowData = totalRowData.find(row => row.id === item);
        if (rowData) {
          listRowSelected.push(rowData);
        }
      });
    }
    const updatedCreationData: CreationDataPropsType = { ...creationData, keyUsageSelected: listRowSelected };
    updateCreationData(updatedCreationData);
  }, [creationData, totalRowData]);

  const onAwsAccountChange = useCallback((currrentAccounts: AccountRowType[]) => {
    setListAccountArn(currrentAccounts);
    const updatedCreationData: CreationDataPropsType = { ...creationData, otherAwsAccounts: currrentAccounts };
    updateCreationData(updatedCreationData);
  }, [creationData]);

  const renderTableNode = useMemo((): ReactNode => {
    if (mainRowsCurrentPage.length === 0) {
      return (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      );
    }
    return (
      <Fragment>
        <Table
          columns={columns}
          rows={mainRowsCurrentPage}
          reportCheckedList={(list: string[]) => {
            onCheckedTableChange(list);
          }}
          sortOption={{
            target: tablePagination.target,
            direction: tablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              setTablePagination(prev => ({
                ...prev,
                target: target,
                direction: dir,
              }));
            },
          }}
          currentCheckList={checkedList}
          isLoading={apiLoading}
        />

        {apiLoading === false && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center text-paging">
              Total <span>{totalRowData.length}</span>
            </p>

            <TableManagePagination
              ableFetchMore={!!nextToken}
              currentPage={tablePagination.currentPage}
              updateCurrentPage={page =>
                setTablePagination(prev => ({
                  ...prev,
                  currentPage: page,
                }))
              }
              totalPage={tableTotal.totalPage}
            />
          </div>
        )}
      </Fragment>
    );
  }, [mainRowsCurrentPage, tablePagination, tableTotal, apiLoading, totalRowData, nextToken]);

  return (
    <StepContainer title={title}>
      <SectionContainer
        title="Key users"
        description="Select the IAM users and roles that can use the KMS key in cryptographic operations."
      >
        {renderTableNode}
      </SectionContainer>

      <SectionContainer title="Other AWS accounts">
        <InputGroup>
          <AddAnotherAwsAccount currentAccounts={listAccountArn} onChange={currentAccounts => onAwsAccountChange(currentAccounts)} />
        </InputGroup>
      </SectionContainer>
    </StepContainer>
  );
};

export default DefineKeyUsagePermission;
