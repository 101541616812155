import { AddTagPropsType, CreationDataPropsType } from "./types";
import { TagRowData } from "pages/v2/Organ/Management/components/types";
import { useCallback, useState } from "react";
import StepContainer from "pages/v2/Organ/Management/components/StepContainer";
import SectionContainer from "pages/v2/Organ/Management/components/SectionContainer";
import InputGroup from "pages/v2/Organ/Management/components/InputGroup";
import TagInput from "pages/v2/Organ/Management/components/TagInput";

const AddTag = (props: AddTagPropsType) => {
  const { title, creationData, updateCreationData } = props;

  const [tags, setTags] = useState<TagRowData[]>(creationData?.tags ?? []);

  const onTagsChange = useCallback((curentTags: TagRowData[]) => {
    setTags(curentTags);
    const updatedCreationData: CreationDataPropsType = { ...creationData, tags: curentTags };
    updateCreationData(updatedCreationData);
  }, [creationData]);

  return (
    <StepContainer
      title={title}
      description={'Choose the firewall policy to associate with this firewall.'}
    >
      <SectionContainer title="Tags">
        <InputGroup>
          <TagInput currentTags={tags} onChange={(currentTags) => onTagsChange(currentTags)} />
        </InputGroup>
      </SectionContainer>
    </StepContainer>
  );
}

export default AddTag;