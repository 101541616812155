import './index.scss';
import { useEffect, useState } from 'react';
import DetailItem, { IMgdDetailKeyValueProps } from '../../DetailItem';
import Icon from 'components/v2/atoms/Icon';
import CloseIcon from 'assets/svgs/v2/ico_close.svg';
import lazyGetAwsFindingStatistics, {
  IGetAwsFindingStatisticsVariables,
} from 'graphql/queries/getAwsFindingStatistics';
import lazyGetAwsDescribeBuckets, { IGetAwsDescribeBucketsVariables } from 'graphql/queries/getAwsDescribeBuckets';
import { AwsBucketMetadata, AwsDescribeBucketsResponse } from 'graphql/types/AwsBuckets';
import { TextTypeEnum } from 'layouts/v3/MgdLayout';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { FINDINGS_TAB, MACIE_TAB } from '../../../configs';
import { handleLinkClicked } from '../../../Function';
import { FilterCriteria, FilterType, FilterValueType } from '../../SearchBar/types';
type SeverityType = {
  high: number;
  medium: number;
  low: number;
};
const FindingsBucketSummary = ({ cloudId, region, data: dataItem, setData: setDataItem }: any) => {
  const [getAwsFindingStatistics] = lazyGetAwsFindingStatistics();
  const [getAwsDescribeBuckets] = lazyGetAwsDescribeBuckets();

  const [dataDetail, setDataDetail] = useState<AwsBucketMetadata | undefined>();
  const [findingsBySeverity, setFindingsBySeverity] = useState<SeverityType>({
    high: 0,
    medium: 0,
    low: 0,
  });
  const [findingsByType, setFindingsByType] = useState<any[]>([]);
  const [findingsByJob, setFindingsByJob] = useState<any[]>([]);
  const navigate = useNavigate();

  const handleGetData = async () => {
    const findingsVariable: IGetAwsDescribeBucketsVariables = {
      cloudId,
      region,
      reqData: {
        criteria: JSON.stringify({
          bucketName: {
            eq: [dataItem?.groupKey],
          },
        }),
      },
    };

    const { data: findings } = await getAwsDescribeBuckets({
      variables: findingsVariable,
    });
    setDataDetail(findings?.getAwsDescribeBuckets?.data?.[0]?.buckets?.[0]);
  };

  const fetchFindingStatistic = async (groupBy: string, s3BucketEq: string) => {
    if (!region) return;
    try {
      const variables: IGetAwsFindingStatisticsVariables = {
        cloudId,
        region,
        reqData: {
          groupBy,
          findingCriteria: {
            criterion: JSON.stringify({
              archived: { eq: [false] },
              'resourcesAffected.s3Bucket.name': { eq: [s3BucketEq] },
            }),
          },
        },
      };
      const { data: findingStatistics } = await getAwsFindingStatistics({ variables });

      return findingStatistics;
    } catch (error) {
      /* empty */
    }
  };

  const getParamForSearch = (items: FilterCriteria) => {
    const data = {
      navigate,
      link: '/organ/1/manage/macie',
      type: ManagementTypeEnum.MACIE,
      tabId: MACIE_TAB[0].id,
      key: FINDINGS_TAB[0].id.toString(),
      value: [
        {
          name: 'resourcesAffected.s3Bucket.name',
          display: 'S3 bucket name',
          type: FilterValueType.VALUE_TYPE,
          value: dataItem?.groupKey,
        },
        { ...items },
      ],
    };

    return data;
  };
 
  const findingStatisticSeverity = async () => {
    const result = await fetchFindingStatistic('severity.description', dataItem?.groupKey);
    const countsByGroup = result?.getAwsFindingStatistics?.data[0].countsByGroup;
    const severity: any = {};
    countsByGroup?.forEach(item => {
      const keyName = item?.groupKey?.toLowerCase();
      severity[keyName] = item.count;
    });
    setFindingsBySeverity({ ...findingsBySeverity, ...severity });
  };

  const findingStatisticType = async () => {
    const result = await fetchFindingStatistic('type', dataItem?.groupKey);
    const countsByGroup = result?.getAwsFindingStatistics?.data[0].countsByGroup;
    const dataFormat =
      countsByGroup?.map(item => ({
        title: item.groupKey,
        description: item.count,
        type: TextTypeEnum.LINK,
        handleItemClick: (value: string) => {
          handleLinkClicked(
            getParamForSearch({
              name: 'type',
              display: 'Finding type',
              type: FilterValueType.VALUE_TYPE,
              value: item.groupKey,
            }),
          );
        },
      })) || [];
    setFindingsByType([...dataFormat]);
  };

  const findingStatisticJob = async () => {
    const result = await fetchFindingStatistic('classificationDetails.jobId', dataItem?.groupKey);
    const countsByGroup = result?.getAwsFindingStatistics?.data[0].countsByGroup;
    const dataFormat =
      countsByGroup?.map(item => ({
        title: item.groupKey,
        description: item.count,
        type: TextTypeEnum.LINK,
        handleItemClick: (value: string) => {
          handleLinkClicked(
            getParamForSearch({
              name: 'classificationDetails.jobId',
              display: 'Job ID',
              type: FilterValueType.VALUE_TYPE,
              value: item.groupKey,
            }),
          );
        },
      })) || [];
    setFindingsByJob([...dataFormat]);
  };

  const getFormatedDateAt = (dateString: string) => {
    if (!dateString) return '-';
    //Coordinated Universal Time
    else return moment(dateString).utc().format('MMMM DD, YYYY[,] HH:mm:ss');
  };

  const calculateTimeDifference = (dateTime: string) => {
    if (!dateTime) return '';
    const inputTime = moment(dateTime).utc();
    const currentTime = moment();
    const difference = moment.duration(currentTime.diff(inputTime));
    if (difference.asMilliseconds() < 0) {
      return;
    }
    const days = difference.days();
    const hours = difference.hours();
    const minutes = difference.minutes();
    const months = difference.months();
    if (months > 0) {
      return `(${months} months ago)`;
    } else if (days > 0) {
      return `(${days} days ${!hours ? `${hours} hours` : ''} ago.)`;
    } else {
      if (hours > 0) {
        return `(${hours} hours ago)`;
      } else if (hours <= 0) {
        return `(${minutes} minutes ago)`;
      }
    }
  };

  useEffect(() => {
    if (!dataItem?.id) return;

    handleGetData();
    findingStatisticSeverity();
    findingStatisticType();
    findingStatisticJob();
  }, [dataItem?.id]);

  const findingsBySeverityDetail: IMgdDetailKeyValueProps[] = [
    {
      title: 'High',
      color: 'red',
      type: TextTypeEnum.LINK,
      description: findingsBySeverity.high,
      isChipTitle: true,
      handleItemClick: (value: string) => {
        handleLinkClicked(
          getParamForSearch({
            name: 'severity.description',
            display: 'severity',
            type: FilterValueType.VALUE_TYPE,
            value: 'High',
          }),
        );
      },
    },
    {
      title: 'Medium',
      color: 'yellow',
      isChipTitle: true,
      type: TextTypeEnum.LINK,
      description: findingsBySeverity.medium,
      handleItemClick: (value: string) => {
        handleLinkClicked(
          getParamForSearch({
            name: 'severity.description',
            display: 'severity',
            type: FilterValueType.VALUE_TYPE,
            value: 'Medium',
          }),
        );
      },
    },
    {
      title: 'Low',
      color: 'green',
      isChipTitle: true,
      type: TextTypeEnum.LINK,
      description: findingsBySeverity.low,
      handleItemClick: (value: string) => {
        handleLinkClicked(
          getParamForSearch({
            name: 'severity.description',
            display: 'severity',
            type: FilterValueType.VALUE_TYPE,
            value: 'Low',
          }),
        );
      },
    },
  ];

  const capitalizeFirstLetter = (val: string) => {
    return val
      .split(/[\s_]+/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const overview: IMgdDetailKeyValueProps[] = [
    {
      title: 'Account ID',
      description: dataDetail?.accountId,
    },
    {
      title: 'Region',
      description: dataDetail?.region,
    },
    {
      title: 'ARN',
      description: dataDetail?.bucketArn,
    },
    {
      title: 'Name',
      description: dataDetail?.bucketName,
    },
    {
      title: 'Created',
      description: dataDetail?.bucketCreatedAt,
    },
    {
      title: 'Shared access',
      description: capitalizeFirstLetter(dataDetail?.sharedAccess || ''),
    },
    {
      title: 'Versioning',
      description: dataDetail?.versioning ? 'Enabled' : 'Disabled',
    },
    {
      title: 'Last updated',
      description: `${getFormatedDateAt(dataDetail?.lastUpdated || '')} ${calculateTimeDifference(
        dataDetail?.lastUpdated || '',
      )}`,
    },
    {
      title: 'Latest automated discovery run',
      description: `${getFormatedDateAt(dataDetail?.lastAutomatedDiscoveryTime || '')} ${calculateTimeDifference(
        dataDetail?.lastAutomatedDiscoveryTime || '',
      )}`,
    },
  ];

  const convertToKb = (bytes?: number) => {
    if (!bytes) return 0;
    else return (bytes / 1024)?.toFixed(2);
  };

  const objectStatistics: IMgdDetailKeyValueProps[] = [
    {
      title: 'Macie automatically retrieves object metadata on a daily basis. Optionally refresh the data now.',
      onlyTitle: true,
      description: undefined,
    },
    {
      title: 'Total count',
      description: dataDetail?.objectCount,
    },
    {
      title: 'Total storage size',
      description: `${convertToKb(dataDetail?.sizeInBytes)} KB`,
    },
    {
      title: 'Total compressed size',
      description: `${convertToKb(dataDetail?.sizeInBytesCompressed)} KB`,
    },
  ];

  const classifiableObjects: IMgdDetailKeyValueProps[] = [
    {
      title: 'Total count',
      description: dataDetail?.classifiableObjectCount,
    },
    {
      title: 'Total storage size',
      description: `${convertToKb(dataDetail?.classifiableSizeInBytes)} KB`,
    },
  ];

  const unClassifiableObjects: IMgdDetailKeyValueProps[] = [
    {
      title: 'Total count',
      description: dataDetail?.unclassifiableObjectCount.total,
    },
    {
      title: 'Total storage size',
      description: `${convertToKb(dataDetail?.unclassifiableObjectSizeInBytes.total)} KB`,
    },
  ];

  const unClassifiableStorageClassObjects: IMgdDetailKeyValueProps[] = [
    {
      title: 'Total count',
      description: dataDetail?.unclassifiableObjectCount.storageClass,
    },
    {
      title: 'Total storage size',
      description: `${convertToKb(dataDetail?.unclassifiableObjectSizeInBytes.storageClass)} KB`,
    },
  ];

  const unClassifiableFileTypeClassObjects: IMgdDetailKeyValueProps[] = [
    {
      title: 'Total count',
      description: dataDetail?.unclassifiableObjectCount.fileType,
    },
    {
      title: 'Total storage size',
      description: `${convertToKb(dataDetail?.unclassifiableObjectSizeInBytes.fileType)} KB`,
    },
  ];

  const objectsByEncryptionType: IMgdDetailKeyValueProps[] = [
    {
      title: 'Customer provided',
      description: dataDetail?.objectCountByEncryptionType.customerManaged,
    },
    {
      title: 'AWS KSM managed',
      description: dataDetail?.objectCountByEncryptionType.kmsManaged,
    },
    {
      title: 'Amazon S3 managed',
      description: dataDetail?.objectCountByEncryptionType.s3Managed,
    },
    {
      title: 'No encryption',
      description: dataDetail?.objectCountByEncryptionType.unencrypted,
    },
    {
      title: 'Unknown',
      description: dataDetail?.objectCountByEncryptionType.unknown,
    },
  ];

  const serverSideEncryption: IMgdDetailKeyValueProps[] = [
    {
      title: 'Encryption required by bucket policy',
      description: dataDetail?.allowsUnencryptedObjectUploads === 'TRUE' ? 'No' : 'Yes',
    },
    {
      title: 'Default encryption',
      description: dataDetail?.serverSideEncryption.type,
    },
    {
      title: 'AWS KMS key',
      description: dataDetail?.serverSideEncryption.kmsMasterKeyId,
    },
  ];

  const sensitiveDataDiscovery: IMgdDetailKeyValueProps[] = [
    {
      title: 'Actively monitored by job',
      description: dataDetail?.jobDetails.isMonitoredByJob === 'TRUE' ? 'Yes' : 'No',
    },
    {
      title: 'Lastest job',
      type: TextTypeEnum.LINK,
      description: dataDetail?.jobDetails.lastJobId || '-',
      handleItemClick: (value: string) => {
        handleLinkClicked(
          getParamForSearch({
            name: 'classificationDetails.jobId',
            display: 'Job ID',
            type: FilterValueType.VALUE_TYPE,
            value: dataDetail?.jobDetails.lastJobId || '',
          }),
        );
      },
    },
    {
      title: 'Latest job run',
      description: `${getFormatedDateAt(dataDetail?.jobDetails.lastJobRunTime || '')} ${calculateTimeDifference(
        dataDetail?.jobDetails.lastJobRunTime || '',
      )}`,
    },
  ];

  const publicAccess: IMgdDetailKeyValueProps[] = [
    {
      title: 'Effective permission',
      description: capitalizeFirstLetter(dataDetail?.publicAccess.effectivePermission || ''),
    },
  ];
  const setOnOffString = (val?: boolean) => {
    return !val ? 'Off' : 'On';
  };

  const setYesNoString = (val?: boolean) => {
    return !val ? 'No' : 'Yes';
  };

  const accountLevel = (): IMgdDetailKeyValueProps[] => {
    const dataAccount = dataDetail?.publicAccess?.permissionConfiguration?.accountLevelPermissions?.blockPublicAccess;

    return [
      {
        title: 'Block public access through ACLs',
        description: setOnOffString(dataAccount?.blockPublicAcls),
      },
      {
        title: 'Block public access through bucket policies',
        description: setOnOffString(dataAccount?.blockPublicPolicy),
      },
      {
        title: 'Ignore ACLs that grant public access',
        description: setOnOffString(dataAccount?.ignorePublicAcls),
      },
      {
        title: 'Restrict access to public buckets',
        description: setOnOffString(dataAccount?.restrictPublicBuckets),
      },
    ];
  };

  const bucketLevel = (): IMgdDetailKeyValueProps[] => {
    const dataBucket = dataDetail?.publicAccess?.permissionConfiguration?.bucketLevelPermissions?.blockPublicAccess;

    return [
      {
        title: 'Block public access through ACLs',
        description: setOnOffString(dataBucket?.blockPublicAcls),
      },
      {
        title: 'Block public access through bucket policies',
        description: setOnOffString(dataBucket?.blockPublicPolicy),
      },
      {
        title: 'Ignore ACLs that grant public access',
        description: setOnOffString(dataBucket?.ignorePublicAcls),
      },
      {
        title: 'Restrict access to public buckets',
        description: setOnOffString(dataBucket?.restrictPublicBuckets),
      },
    ];
  };

  const bucketLevelACL = (): IMgdDetailKeyValueProps[] => {
    const dataBucket = dataDetail?.publicAccess?.permissionConfiguration?.bucketLevelPermissions?.accessControlList;

    return [
      {
        title: 'Allow public read access',
        description: setYesNoString(dataBucket?.allowsPublicReadAccess),
      },
      {
        title: 'Allow public write access',
        description: setYesNoString(dataBucket?.allowsPublicWriteAccess),
      },
    ];
  };

  const bucketLevelPolicy = (): IMgdDetailKeyValueProps[] => {
    const dataBucket = dataDetail?.publicAccess?.permissionConfiguration?.bucketLevelPermissions?.bucketPolicy;

    return [
      {
        title: 'Allow public read access',
        description: setYesNoString(dataBucket?.allowsPublicReadAccess),
      },
      {
        title: 'Allow public write access',
        description: setYesNoString(dataBucket?.allowsPublicWriteAccess),
      },
    ];
  };

  const replication = (): IMgdDetailKeyValueProps[] => {
    const dataBucket = dataDetail?.replicationDetails;

    return [
      {
        title: 'Replicated',
        description: setYesNoString(dataBucket?.replicated),
      },
      {
        title: 'Replicated externally',
        description: setYesNoString(dataBucket?.replicatedExternally),
      },
    ];
  };

  const replicationAccounts = (): IMgdDetailKeyValueProps[] => {
    const dataBucket = dataDetail?.replicationDetails;

    return [
      {
        title: 'Account Id',
        description: dataBucket?.replicationAccounts.length === 0 ? 'None' : dataBucket?.replicationAccounts,
      },
    ];
  };

  return dataItem?.id ? (
    <div className="box-summary-horizontal">
      <div className="box-summary-info">
        <div className="box-summary-info-title">
          <span>bucket: {dataItem?.groupKey} </span>
          <Icon className="suffix-btn" onClick={() => setDataItem(undefined)} width={10} height={10} src={CloseIcon} />
        </div>
      </div>

      <DetailItem title="Findings by severity" data={findingsBySeverityDetail} findingStyle="justify-between" />

      <DetailItem title="Findings by type" data={findingsByType} findingStyle="justify-between" />

      <DetailItem title="Findings by job" data={findingsByJob} findingStyle="justify-between" />

      <DetailItem title="Overview" data={overview} />

      <DetailItem title="Object statistics" data={objectStatistics} />

      <DetailItem title="Classifiable objects" data={classifiableObjects} />

      <DetailItem title="Unclassifiable objects" data={unClassifiableObjects} />

      <DetailItem title="Unclassifiable objects: Storage class" data={unClassifiableStorageClassObjects} />

      <DetailItem title="Unclassifiable objects: File type" data={unClassifiableFileTypeClassObjects} />

      <DetailItem title="Objects by encryption type" data={objectsByEncryptionType} />

      <DetailItem title="Server-side encryption" data={serverSideEncryption} />

      <DetailItem title="Sensitive data discovery" data={sensitiveDataDiscovery} />

      <DetailItem title="Public access" data={publicAccess} />

      <DetailItem title="Account-level setting" data={accountLevel()} />

      <DetailItem title="Bucket-level setting" data={bucketLevel()} />

      <DetailItem title="Bucket-level ACL setting" data={bucketLevelACL()} />

      <DetailItem title="Bucket-level policy setting" data={bucketLevelPolicy()} />

      <DetailItem title="Replication" data={replication()} />

      <DetailItem title="Replication accounts" data={replicationAccounts()} />
    </div>
  ) : null;
};

export default FindingsBucketSummary;
