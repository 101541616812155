import MgdTabLayout from 'layouts/v3/MgdTabLayout';
import { MgdTabLayoutTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import React from 'react';
import './index.scss';
import Button, { ButtonTypeEnum } from '../../components/Button';

const MgdWelCome = () => {
  return (
    <div className="mgd-welcome">
      <div className="mgd-welcome-container">
        <div className="mgd-welcome-content">
          <div className="mgd-welcome-content-header">
            <h4 className="mgd-welcome-title">Welcome to Guard Duty</h4>
            <p className="mgd-welcome-description">30 day trial</p>
          </div>
          <div className="mgd-welcome-content-body">
            <h4 className="mgd-welcome-title">Enable Guardduty</h4>
            <div className="mgd-welcome-content-body-wrapper">
              <p className="mgd-welcome-sub-title">Service permissions</p>
              <p className="mgd-welcome-description">When you enable GuardDuty, you grant GuardDuty permissions to:</p>
            </div>
            <ul className="mgd-welcome-content-list">
              <li className="mgd-welcome-description">
                Analyze VPC Flow lags, AWS CloudTrail management event logs, AWS CloudTrail S3 data event logs,
                Kubernete (EKS) audit logs, and RDS login activity logs to generate security findings.Analyze Elastic
                Block Storage (EBS) volume data to generate malware detection findings.
              </li>
              <li className="mgd-welcome-description">
                Analyze Elastic Block Storage (EBS) volume data to generate malware detection findings.
              </li>
            </ul>
          </div>
        </div>
        <div className="mgd-welcome-btn flex j-end">
          <Button type={ButtonTypeEnum.PRIMARY} label="Enable guard duty" onClick={() => {}} />
        </div>
      </div>
    </div>
  );
};

export default MgdWelCome;
