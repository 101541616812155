import RequiredIcon from 'assets/svgs/v2/ico_require.svg';
import InputAtom from 'components/v2/atoms/InputAtom';
import { useCallback } from 'react';
import { IRuleGroupDetails } from '../type';
import { ICreationDataPropsType } from './type';

interface IDescribeRuleGroupProps {
  title: string;
  subTitle: string;
  creationData: ICreationDataPropsType;
  updateCreationData: (data: ICreationDataPropsType) => void;
}

const DescribeRuleGroup = ({ title, subTitle, creationData, updateCreationData }: IDescribeRuleGroupProps) => {
  const { ruleGroupDetails } = creationData;
  const handleChangeRuleGroupDetails = useCallback(
    (name: 'name' | 'description' | 'capacity', value: string) => {
      const newRuleGroupDetails: IRuleGroupDetails = {
        ...ruleGroupDetails,
        [name]: value,
      };
      updateCreationData({ ...creationData, ruleGroupDetails: newRuleGroupDetails });
    },
    [creationData, ruleGroupDetails, updateCreationData],
  );

  return (
    <div className="rule-group-container">
      <div className="title-group">
        <p className="title">{title}</p>
        <p className="sub-title">{subTitle}</p>
      </div>
      <div className="rule-group-details-container">
        <div className="rule-group-detail-title">Rule group details</div>
        <div className="rule-group-detail-content">
          <div className="detail-content-row">
            <div className="detail-content-column-left">
              Name
              <img style={{ marginLeft: 5 }} src={RequiredIcon} width={4} height={4} />
            </div>
            <div className="detail-content-column-right">
              <p>Enter a name for the rule group that’s unique within your stateful rule groups.</p>
              <div className="full-width">
                <InputAtom
                  value={ruleGroupDetails.name}
                  noClear={true}
                  onChangeValue={(value: string) => handleChangeRuleGroupDetails('name', value)}
                />
              </div>
              <p>
                The name must have 1-128 characters. Valid characters:a-z, A-Z, 0-9, and (hyphen), The name can’t start
                or end with a hyphen, and it can’t contain two consecutive hyphens.
              </p>
            </div>
          </div>
          <div className="detail-content-row">
            <div className="detail-content-column-left">Description</div>
            <div className="detail-content-column-right">
              <p>
                This description appears when you view the rule group’s details. It can help you quickly identify what
                your rule group is used for.
              </p>
              <div className="full-width">
                <InputAtom
                  value={ruleGroupDetails.description}
                  noClear={true}
                  onChangeValue={(value: string) => handleChangeRuleGroupDetails('description', value)}
                />
              </div>
              <p>The description can have 0-256 characters.</p>
            </div>
          </div>
          <div className="detail-content-row">
            <div className="detail-content-column-left">
              Capacity
              <img style={{ marginLeft: 5 }} src={RequiredIcon} width={4} height={4} />
            </div>
            <div className="detail-content-column-right">
              <p>
                The number of rules you expect to have in this rule group during its lifetime. You can’t change capacity
                after rule group creation, so leave room to grow.
              </p>
              <div className="full-width">
                <InputAtom
                  value={ruleGroupDetails.capacity}
                  noClear={true}
                  onChangeValue={(value: string) => handleChangeRuleGroupDetails('capacity', value)}
                />
              </div>
              <p>The capacity must be greater than or equal to 1 and less than 30,000.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescribeRuleGroup;
