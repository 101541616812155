import { useState, useEffect, useMemo, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconEditDetail from 'assets/svgs/v3/ico_edit.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import lazyGetAwsResources, { IGetResourcesVariables } from 'graphql/queries/getAwsResources';
import { ColumnType, OrderDirection } from '@Types/v2/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdTabLayout/types';
import MultiSelectAutocomplete from '../../components/AutocompleteInputAtom';
import TagKeyMatchingResource from '../../components/TagKeyMatchingResource';
import { TagType } from '../../types';
import { AwsResourceTagMappingType } from 'graphql/types/AwsGetResources';
import { extractResourceArnData } from '../../../../Function';
import { FIREWALL_COMMOM_VALUE_MAPPING } from '../../../configs';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { useNavigate } from 'react-router-dom';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
interface IEditFirewallPolicyDetailProps extends IBaseModalProps {
  cloudId: number;
  region: string;
  header: string;
  resourceGroup: any;
  handleUpdateGroupDetail: (desc: string) => Promise<boolean>;
  onClose: () => void;
  loading: boolean;
}
export type dropdownType = {
  id: number,
  name: string,
  value: string
}
const EditGroupingCriteria = ({
  cloudId,
  region,
  header,
  onClose,
  loading,
  resourceGroup,
  handleUpdateGroupDetail,
  ...baseModalProps
}: IEditFirewallPolicyDetailProps) => {
  const [getAwsResources, { loading: getAwsResourceLoading }] = lazyGetAwsResources();
  const navigate = useNavigate();
  const [resourceTypes, setResourceTypes] = useState<dropdownType[]>([]);
  const [tags, setTags] = useState<TagType[]>([{ key: '', values: [''] }]);
  const [resourcesTblRows, setResourcesTblRows] = useState<any[]>([]);
  const [resourcesTblotal, setResourcesTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [resourcesTablePagination, setResourcesTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const DATA_RESOURCES: dropdownType[] = [
    {
      id: 1,
      name: 'AWS::EC2::Instance',
      value: 'ec2:instance',
    },
    {
      id: 2,
      name: 'AWS::EC2::NetworkInterface',
      value: 'ec2:networkinterface',
    },
  ];

  const COLMUN_MATCHING_RESOURCE = useMemo((): ColumnType[] => {
    return [
      { label: 'Identifier', field: 'identifier', sort: true, width: 180, renderCell: row => {
        return (
          <p
            className="link"
            onClick={() =>
              navigate('/organ/1/manage/instance', {
                replace: true,
                state: {
                  managementType: ManagementTypeEnum.INSTANCE,
                  tabId: row.identifier,
                  value: row.identifier,
                },
              })
            }
          >
            {row.identifier}
          </p>
        );
      } },
      { label: 'Name', width: 150, sort: true, field: 'name' },
      { label: 'Service', width: 80, sort: true, field: 'service' },
      { label: 'Type', width: 80, sort: true, field: 'type' },
      { label: 'Region', width: 150, sort: true, field: 'region' },
    ];
  }, []);

  const fetchDataResources = async (tagFilters: any, resourceTypeFilters: dropdownType[]) => {
    const variablesResources: IGetResourcesVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        tagFilters: tagFilters?.map((item: TagType) => ({ key: item.key, values: item.values?.length > 0 ? item?.values : null })),
        resourceTypeFilters: resourceTypeFilters?.map(item => item.value),
      },
    };
    const dataFetch: { identifier: string; name: string | undefined; service: string; type: string; region: string | undefined; }[] = [];
    const awsResourcesData = await getAwsResources({ variables: variablesResources });
    const result = awsResourcesData?.data?.getAwsResources?.data;
    if (result) {
      result[0]?.resourceTagMappingList?.map((item: AwsResourceTagMappingType) => {
        dataFetch.push({
          identifier: extractResourceArnData(item.resourceARN).id,
          name: item.tags.find(i => i.key === 'Name')?.value,
          service: FIREWALL_COMMOM_VALUE_MAPPING[extractResourceArnData(item.resourceARN).service || ''],
          type: FIREWALL_COMMOM_VALUE_MAPPING[extractResourceArnData(item.resourceARN).resourceType || ''],
          region: extractResourceArnData(item.resourceARN).region,
        });
      });
    }
    setResourcesTblRows(dataFetch);
    setResourcesTblTotal({
      totalPage: Math.ceil(dataFetch.length / resourcesTablePagination.itemPerPage),
      totalElement: dataFetch.length,
    });
  };

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (resourcesTablePagination.currentPage - 1) * resourcesTablePagination.itemPerPage;
    const endIndex = startIndex + resourcesTablePagination.itemPerPage;

    return orderAlphabetical(resourcesTblRows, resourcesTablePagination.target, resourcesTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [resourcesTablePagination, resourcesTblRows]);

  const updateTablePagination = useCallback((key: string, value: number | string | OrderDirection) => {
    setResourcesTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const onSavePolicy = async () => {
    const validType = resourceTypes.length > 0;
    const validTags = tags.length > 0;
    if (validType && validTags) {
      const reqData = {
        ResourceTypeFilters: resourceTypes.map(item => item.name),
        TagFilters: tags.map(item => ({ Key: item.key, Values: item.values[0] !== '' ? item.values : null })),
      };
      const result = await handleUpdateGroupDetail(JSON.stringify(reqData));
      result ? onClose() : null;
    }
  };

  const formattedObject = (obj: any) => {
    return Object.keys(obj).reduce((acc: any, key) => {
      const lowerKey = key.charAt(0).toLowerCase() + key.slice(1).toLowerCase();
      acc[lowerKey] = obj[key];
      
      return acc;
    }, {});
  };

  useEffect(() => {
    if (baseModalProps.open) {
      const firstResourceType = DATA_RESOURCES.filter(item => resourceGroup?.ResourceTypeFilters.includes(item.name));
      const firstTags = resourceGroup?.TagFilters.map((data: any) => formattedObject(data));
      setResourceTypes(firstResourceType);
      setTags(firstTags);
      fetchDataResources(firstTags, firstResourceType);
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditDetail} />
          {header}
        </>
      )}
      onClose={onClose}
      {...baseModalProps}
    >
      <div className="resource-matching-edit-modal">
        <div className={'resource-matching-details'}>
          <div className="row-1 mt-16">
            <p className="title-grid">Grouping criteria</p>
            <p className="sub-title-grid">
              Define a resource group based on tags and resource types. Choose Preview resources to see the resources
              that match the grouping criteria.
            </p>
            <div className="mt-16">
              <p className="text-title">Resource type</p>
              <MultiSelectAutocomplete
                data={DATA_RESOURCES}
                selectedValues={resourceTypes}
                placeholder="Choose one or more options"
                handleClick={setResourceTypes}
                error={'Choose one or more options'}
              />
            </div>
            <div className="my-16">
              <TagKeyMatchingResource
                cloudId={cloudId}
                region={region}
                resourceTypes={resourceTypes}
                selectedValues={tags}
                handleClick={setTags}
              />
            </div>
            <Button
              type={ButtonTypeEnum.PRIMARY}
              disabled={tags.length === 0}
              label="Matching Resources"
              onClick={() => {
                console.log(tags);
                
                fetchDataResources(tags, resourceTypes);
              }}
            />
          </div>
          <div className="row-1">
            <div className=" my-16">
              <p className="title-grid">Matching resources</p>
              <p className="sub-title-grid">
              The resources in your account in the current Region that match the grouping criteria.
              </p>
            </div>
            <div className="detail-info">
              <div className="table-matching">
                <div className="detail-info-content">
                  <div className="data-grid-wrap">
                    <Table
                      rows={mainRowsCurrentPage}
                      columns={COLMUN_MATCHING_RESOURCE}
                      sortOption={{
                        target: resourcesTablePagination.target,
                        direction: resourcesTablePagination.direction,
                        onChangeSort: (target: string, dir: OrderDirection) => {
                          updateTablePagination('target', target);
                          updateTablePagination('direction', dir);
                        },
                      }}
                      isLoading={getAwsResourceLoading}
                      horizontalScrollable={true}
                      isEmpty
                      titleEmpty="No resources"
                    />

                    {mainRowsCurrentPage?.length && !getAwsResourceLoading ? (
                      <div className="fleet-instance pagination-wrapper flex a-center">
                        <p className="flex a-center">
                          Total <span>{resourcesTblotal.totalElement}</span>
                        </p>

                        <TableManagePagination
                          ableFetchMore={false}
                          currentPage={resourcesTablePagination.currentPage}
                          updateCurrentPage={page => updateTablePagination('currentPage', page)}
                          totalPage={resourcesTblotal.totalPage}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-group">
          <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={onClose} />
          <Button label="Save" type={ButtonTypeEnum.PRIMARY} loading={loading} onClick={onSavePolicy} />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditGroupingCriteria;
