import { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import BaseModal from 'components/v2/modals/BaseModal';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import {
  DEFAULT_CUSTOM_RULE,
  RULE_TYPE_DATA,
  RuleTypeEnum,
} from 'pages/v2/Organ/Management/WAF/CustomRuleModal/constant';
import IpSet from 'pages/v2/Organ/Management/WAF/CustomRuleModal/IpSet';
import RuleBuilder from 'pages/v2/Organ/Management/WAF/CustomRuleModal/RuleBuilder';
import RuleGroup from 'pages/v2/Organ/Management/WAF/CustomRuleModal/RuleGroup';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import './index.scss';
import lazyGetAwsListIPSets from 'graphql/queries/getAwsListIPSets';
import { AwsIPSets } from 'graphql/types/AwsListIPSet';
import Footer from './components/Footer';
import _ from 'lodash';
import { AwsRulelogType } from 'graphql/types/AwsUpdateRuleGroup';
import lazyGetAwsCheckCapacity from 'graphql/queries/getAwsCheckCapacity';
import { AwsRulelogType as AwsRulelogFromCheckCapacityType } from 'graphql/types/AwsCheckCapacity';
import { WafScopeEnum } from '../Commons/Constant';
import lazyGetAwsValidateTemplate from 'graphql/queries/getAwsValidateTemplate';
import { nameRegexUtils } from 'utils/Regex';
import { pascalCaseKeys } from 'utils/Json';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import InputValidIcon from 'assets/svgs/ic_green_check.svg';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';

type CustomRuleModalPropsType = {
  isOpen: boolean;
  onClose: () => void;
  cloudId: number;
  selectedRegion: DropdownListDataType;
  onAddRule?: (rule: any) => void;
  onEditRule?: (rule: any) => void;
  webAcl?: any;
  isRuleBuilderOnly?: boolean;
  rule?: AwsRulelogType;
  isEditing?: boolean;
};

const CustomRuleModal = (props: CustomRuleModalPropsType) => {
  const {
    isOpen,
    onClose,
    cloudId,
    selectedRegion,
    onAddRule,
    webAcl,
    isRuleBuilderOnly,
    rule: editingRule,
    isEditing,
    onEditRule,
  } = props;

  const [checkCapacity, { loading: checkCapacityLoading }] = lazyGetAwsCheckCapacity();
  const [validateTemplate, { loading: validateTemplateLoading }] = lazyGetAwsValidateTemplate();

  const [ruleType, setRuleType] = useState(RuleTypeEnum.RULE_BUILDER);
  const [ipSets, setIpSets] = useState<Array<AwsIPSets>>([]);
  const [customRule, setCustomRule] = useState<AwsRulelogType>(editingRule || { ...DEFAULT_CUSTOM_RULE });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSubmitloading, setSubmitLoading] = useState(false);
  const [ruleError, setRuleError] = useState('');
  const [ruleValid, setRuleValid] = useState('');

  const [getAwsListIPSets] = lazyGetAwsListIPSets();

  const getIPSets = useCallback(() => {
    const variables = {
      cloudId: cloudId,
      region: selectedRegion.value !== 'CloudFront' ? String(selectedRegion.value) : 'us-east-1',
      request: {
        limit: 100,
        scope: selectedRegion.value !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
      },
    };

    getAwsListIPSets({ variables }).then(res => {
      setIpSets(res?.data?.getAwsListIPSets?.data?.[0]?.ipSets ?? []);
    });
  }, [getAwsListIPSets, selectedRegion, cloudId]);

  useEffect(() => {
    getIPSets();
  }, [getIPSets]);

  useEffect(() => {
    if (isOpen && isEditing) {
      if (editingRule?.statement?.ruleGroupReferenceStatement) {
        setRuleType(RuleTypeEnum.RULE_GROUP);
        return;
      }

      if (editingRule?.statement?.ipSetReferenceStatement) {
        setRuleType(RuleTypeEnum.IP_SET);
        return;
      }

      setRuleType(RuleTypeEnum.RULE_BUILDER);
    }
  }, [editingRule, isEditing, isOpen]);

  const title = () => {
    if (isRuleBuilderOnly) {
      return <></>;
    }
    return <div>Rule types</div>;
  };

  const onError = useCallback(() => {}, []);

  const addRuleModalRef = useRef(null);

  const handleErrors = useCallback((key: string, errorMessage: string) => {
    setErrors(prev => {
      return {
        ...prev,
        [key]: errorMessage,
      };
    });
  }, []);

  const clearError = useCallback((key: string) => {
    setErrors(prev => {
      const newState: { [key: string]: string } = { ...prev };
      delete newState?.[key];

      return newState;
    });
  }, []);

  const validateInputs = useCallback((): boolean => {
    let isValid = true;

    if (!customRule?.name) {
      handleErrors('name', 'Name is a required field');
      isValid = false;
    } else if (!!errors?.name) {
      clearError('name');
    }

    if (!nameRegexUtils.test(customRule?.name ?? '')) {
      handleErrors('name', 'Name is invalid');
      isValid = false;
    } else if (!!errors?.name) {
      clearError('name');
    }

    if (_.isEmpty(customRule?.statement)) {
      handleErrors('inspect', 'Inspect is a required field');
      isValid = false;
    } else {
      clearError('inspect');
    }

    if (
      !_.isEmpty(customRule?.statement?.geoMatchStatement) &&
      _.isEmpty(customRule?.statement?.geoMatchStatement?.countryCodes)
    ) {
      handleErrors('countryCodes', 'Country codes is a required field');
      isValid = false;
    } else {
      clearError('countryCodes');
    }

    return isValid;
  }, [customRule, customRule?.name]);

  const handleCheckCapacity = useCallback(
    async (rules: Array<AwsRulelogType>) => {
      const variables = {
        cloudId: cloudId,
        region: selectedRegion.value !== 'CloudFront' ? String(selectedRegion.value) : 'us-east-1',
        request: {
          scope: selectedRegion.value !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
          rules: rules as Array<AwsRulelogFromCheckCapacityType>,
        },
      };
      return await checkCapacity({ variables }).then(res => {
        return res?.data?.getAwsCheckCapacity?.data?.[0]?.capacity ?? 0;
      });
    },
    [cloudId, selectedRegion],
  );

  const handleFormatRules = (rule: any) => {
    if (!rule) return {};

    if (ruleType === RuleTypeEnum.RULE_BUILDER) return rule;

    if (ruleType === RuleTypeEnum.RULE_GROUP) {
      return _.omit(
        {
          ...rule,
          visibilityConfig: {
            sampledRequestsEnabled: false,
            cloudWatchMetricsEnabled: false,
            metricName: rule?.name,
          },
        },
        'action',
      );
    }

    if (ruleType === RuleTypeEnum.IP_SET) {
      return {
        ...rule,
        action: {
          ...rule?.action,
          allow: !rule?.action?.allow?.customRequestHandling?.insertHeaders?.length ? {} : rule?.action?.allow,
        },
        visibilityConfig: {
          sampledRequestsEnabled: false,
          cloudWatchMetricsEnabled: false,
          metricName: rule?.name,
        },
      };
    }
  };

  const handleValidateTemplate = useCallback(async () => {
    const formatRule = handleFormatRules(customRule);

    const request = {
      AWSTemplateFormatVersion: '2010-09-09',
      Resources: {
        MyWebACL: {
          Type: 'AWS::WAFv2::WebACL',
          Properties: {
            Name: webAcl?.name,
            Scope: selectedRegion.value === 'CloudFront' ? WafScopeEnum.CLOUDFRONT : WafScopeEnum.REGIONAL,
            DefaultAction: webAcl?.defaultAction,
            Rules: [pascalCaseKeys(formatRule)],
            VisibilityConfig: {
              SampledRequestsEnabled: true,
              CloudWatchMetricsEnabled: true,
              MetricName: webAcl?.cloudWatchMetricName,
            },
          },
        },
      },
    };

    const variables = {
      cloudId: cloudId,
      region: selectedRegion.value !== 'CloudFront' ? String(selectedRegion.value) : 'us-east-1',
      request: {
        templateBody: JSON.stringify(request),
      },
    };

    return await validateTemplate({ variables }).then(res => {
      if (res?.data?.getAwsValidateTemplate?.data?.[0]?.parameters?.length === 0) {
        return true;
      }

      return false;
    });
  }, [webAcl, selectedRegion, customRule, cloudId]);

  const handleValidateRule = useCallback(async () => {
    const formatRule = handleFormatRules(customRule);
    const isInputsValid = validateInputs();
    const isValidCapacity = (await handleCheckCapacity([formatRule])) > 0;
    const isValidTemplate = await handleValidateTemplate();
    const isValid = isInputsValid && isValidCapacity && isValidTemplate;

    return isValid;
  }, [validateInputs, handleCheckCapacity, handleValidateTemplate, customRule]);

  const handleSubmitRule = useCallback(async () => {
    setSubmitLoading(true);
    const isValid = await handleValidateRule();

    if (isValid) {
      const formattedRule = handleFormatRules(customRule);

      if (isEditing) {
        onEditRule?.(formattedRule);
      } else {
        onAddRule?.(formattedRule);
      }

      setCustomRule({ ...DEFAULT_CUSTOM_RULE });
      setSubmitLoading(false);

      return;
    }

    setRuleError('Rule is invalid');
    useToast(ErrorCode.UNKNOWN, 'Rule is invalid');

    setSubmitLoading(false);
  }, [customRule, handleValidateRule]);

  const ruleContentNode = useMemo(() => {
    switch (ruleType) {
      case RuleTypeEnum.IP_SET:
        return (
          <IpSet
            cloudId={cloudId}
            selectedRegion={selectedRegion}
            ipSets={ipSets}
            setRule={setCustomRule}
            rule={editingRule ?? customRule}
            isEditing={isEditing}
          />
        );

      case RuleTypeEnum.RULE_BUILDER:
        return (
          <RuleBuilder
            cloudId={cloudId}
            selectedRegion={selectedRegion}
            rule={customRule}
            setRule={setCustomRule}
            webAcl={webAcl}
            errors={errors}
            onError={onError}
            validateInputs={validateInputs}
            validateLoading={validateTemplateLoading || checkCapacityLoading}
            handleValidateRule={handleValidateRule}
            ipSets={ipSets}
            setRuleError={setRuleError}
            setRuleValid={setRuleValid}
          />
        );

      case RuleTypeEnum.RULE_GROUP:
        return (
          <RuleGroup
            cloudId={cloudId}
            selectedRegion={selectedRegion}
            setRule={setCustomRule}
            rule={editingRule ?? customRule}
            isEditing={isEditing}
          />
        );

      default:
        return null;
    }
  }, [
    ruleType,
    ipSets,
    customRule,
    errors,
    validateTemplateLoading,
    checkCapacityLoading,
    handleValidateRule,
    validateInputs,
    isEditing,
    editingRule,
  ]);

  return (
    <BaseModal open={isOpen} onClose={onClose} title={title} className="custom-rule-modal" ref={addRuleModalRef}>
      {!isRuleBuilderOnly && (
        <div className="rule-type">
          <p className="rule-type-title">Rule type</p>

          <div className="rule-type-option">
            {RULE_TYPE_DATA.map(({ id, label, value, name, description }) => (
              <RadioAtom
                key={id}
                label={label}
                value={value}
                name={name}
                description={description}
                checked={ruleType}
                onChange={value => setRuleType(value as RuleTypeEnum)}
              />
            ))}
          </div>
        </div>
      )}
      {!!ruleError && (
        <div className="rule-error-container">
          <img src={InputErrorIcon} width={18} height={18} />
          <p className="rule-error-message">{ruleError}</p>
        </div>
      )}

      {!ruleError && !!ruleValid && (
        <div className="rule-valid-container">
          <img src={InputValidIcon} width={18} height={18} />
          <p className="rule-valid-message">{ruleValid}</p>
        </div>
      )}

      {ruleContentNode}

      <Footer onCancel={onClose} onSubmit={handleSubmitRule} submitLoading={isSubmitloading} />
    </BaseModal>
  );
};

export default CustomRuleModal;
