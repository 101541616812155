import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconEditRuleGroup from 'assets/svgs/v3/ico_policy_variables.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';

type EditDescriptionModalPropsType = {
  onSave?: (description: string) => void;
  alias?: string;
  placeholderDescription?: string;
  description?: string;
} & IBaseModalProps;

const EditDescriptionModal = (props: EditDescriptionModalPropsType) => {
  const { alias, placeholderDescription, description, onSave, ...baseModalProps } = props;
  const [payload, setPayload] = useState<string>('');

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload(description ?? '');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditRuleGroup} />
          {'Edit general configuration'}
        </>
      )}
      {...baseModalProps}
    >
      <div className="modal-content-container">
        <p className="title">{'Key settings'}</p>
        <div className="input">
          <p>Alias</p>
          <InputAtom type={'text'} value={alias} noClear={true} disabled={true} />
        </div>
        <div className="input">
          <p>Description</p>
          <TextareaAtom
            placeholder={'Description of the key'}
            value={payload}
            noClear={true}
            onChangeValue={val => setPayload(val)}
          />
          <p>Maximum 8192 characters</p>
        </div>

        <div className="button-group">
          <Button type={ButtonTypeEnum.GENERAL} onClick={() => baseModalProps?.onClose?.()} label="Cancel" />
          <Button type={ButtonTypeEnum.PRIMARY} onClick={() => onSave?.(payload)} label="Save" />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditDescriptionModal;
