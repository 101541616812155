import { useLazyQuery } from '@apollo/client';
import { IGqlResponseNewData } from 'graphql/types';
import query from './query';

export interface IGetAwsDescribeLockedSnapshotsVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults?: number;
    snapshotIds?: string[];
  };
}

export interface IGetAwsDescribeLockedSnapshotsResponseData {
  getAwsDescribeLockedSnapshots: IGqlResponseNewData<any>;
}

const lazyGetAwsDescribeLockedSnapshots = () =>
  useLazyQuery<IGetAwsDescribeLockedSnapshotsResponseData, IGetAwsDescribeLockedSnapshotsVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeLockedSnapshots;
