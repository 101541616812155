export const CREATE_FIREWALL_STEPS = [
  { id: 'describeFirewall', title: 'Step1. Describe firewall' },
  {
    id: 'configVpcSubnet',
    title: 'Step2. Configure VPC and subnets',
  },
  {
    id: 'configAdvanceSetting',
    title: 'Step 3. Configure advanced settings',
  },
  {
    id: 'associateFirewallPolicy',
    title: 'Step 4. Associate firewall policy',
  },
  {
    id: 'addTags',
    title: 'Step 5. Add tags',
  },
  { id: 'review', title: 'Review' },
];

export enum ENCRYPTION_CONFIGURATION_DATA {
  AWS_OWNED_KMS_KEY = 'AWS_OWNED_KMS_KEY',
  CUSTOMER_KMS = 'CUSTOMER_KMS'
};