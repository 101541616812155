import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import { handleFormatText } from 'utils/Common';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import Icon from 'components/v2/atoms/Icon';
import DeleteIcon from 'assets/svgs/v3/icon_delete_button.svg';
import DeleteRuleModal from '../../DeleteRuleModal';
import EditIPSetReferenceModal from '../../EditIPSetReferenceModal';

const IpStateReferences = ({
  data,
  isLoading,
  isEdit,
  managedPrefixLists,
  handleUpdateIpsetReference,
  handleDeleteIpSetReferences,
}: any) => {
  const { ruleGroup } = data || {};

  const { referenceSets } = ruleGroup || {};

  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState('');
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [ipSetReferences, setIpSetReferences] = useState<any>([]);

  const handleGetData = () => {
    if (!referenceSets?.ipSetReferences) return;

    try {
      const mainTblRows = Object.keys(referenceSets?.ipSetReferences).map(key => ({
        id: key,
        name: key,
        arn: referenceSets?.ipSetReferences[key]?.referenceArn,
      }));

      const referenceArray = Object.keys(referenceSets?.ipSetReferences).map((key, index) => {
        const reference = managedPrefixLists?.find(
          (item: any) => item?.prefixListArn === referenceSets?.ipSetReferences[key]?.referenceArn,
        );
        return {
          index: index,
          variableName: key,
          resourceId: {
            id: reference?.prefixListId,
            name: reference?.prefixListId ?? '',
            description: reference?.prefixListName ?? '',
            value: reference?.prefixListArn ?? '',
          },
          cidr: '0',
        };
      });

      setIpSetReferences(referenceArray);
      setMainTblRows(mainTblRows);
      setMainTblTotal({
        totalPage: Math.ceil(mainTblRows.length / 10),
        totalElement: mainTblRows.length,
      });
    } catch (error) {}
  };

  useEffect(() => {
    handleGetData();
  }, [referenceSets]);

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: row => {
          return <p className="link">{handleFormatText(row.name)}</p>;
        },
      },
      {
        label: 'Arn',
        field: 'arn',
        sort: true,
      },
    ];
  }, []);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotal, tablePagination]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <>
      <div className="detail-info">
        <div className="detail-info-title">
          <p>IP state references</p>

          {isEdit ? (
            <div style={{ display: 'flex', gap: '8px' }}>
              <Icon width={30} height={30} src={DeleteIcon} onClick={() => setIsOpenDelete(true)} />

              <button style={{ whiteSpace: 'nowrap' }} className="button" onClick={() => setIsOpenEdit(true)}>
                Edit
              </button>
            </div>
          ) : null}
        </div>

        {isLoading ? (
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        ) : (
          <div className="detail-info-content">
            {!mainRowsCurrentPage.length && !isLoading ? (
              <div className="data-grid-wrap">
                <p className="empty-row">Empty</p>
              </div>
            ) : (
              <div className="data-grid-wrap">
                <Table
                  rows={mainRowsCurrentPage}
                  columns={columns}
                  sortOption={{
                    target: tablePagination.target,
                    direction: tablePagination.direction,
                    onChangeSort: (target: string, dir: OrderDirection) => {
                      updateTablePagination('target', target);
                      updateTablePagination('direction', dir);
                    },
                  }}
                  isLoading={isLoading}
                  horizontalScrollable={true}
                  reportCheckedList={isEdit ? e => setCheckedList(e) : undefined}
                  reportSelected={setSelected}
                />

                {mainRowsCurrentPage?.length && !isLoading ? (
                  <div className="fleet-instance pagination-wrapper flex a-center">
                    <p className="flex a-center">
                      Total <span>{mainTblTotal.totalElement}</span>
                    </p>

                    <TableManagePagination
                      ableFetchMore={false}
                      currentPage={tablePagination.currentPage}
                      updateCurrentPage={page => updateTablePagination('currentPage', page)}
                      totalPage={mainTblTotal.totalPage}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>

      {isOpenEdit ? (
        <EditIPSetReferenceModal
          header={'Edit IP set references'}
          currentDatas={ipSetReferences}
          columns={['Variable name', 'Resource ID', 'CIDRs']}
          note={
            'Define IP sets and ports as variables. These variables can be used within this rule group for standard stateful rules and Suricate compatible rule strings.'
          }
          open={isOpenEdit}
          onClose={() => setIsOpenEdit(false)}
          onSave={() => {}}
          managedPrefixLists={managedPrefixLists}
          handleUpdateIpsetReference={handleUpdateIpsetReference}
        />
      ) : null}

      {isOpenDelete ? (
        <DeleteRuleModal
          header={'Delete IP set references'}
          titleWarning={`Are you sure you want to delete the following references from the rule group: ${data?.ruleGroupResponse?.ruleGroupName}?`}
          note={'IP Set references to delete'}
          valueInput={checkedList.join(', ')}
          open={isOpenDelete}
          onClose={() => setIsOpenDelete(false)}
          onDelete={async () => {
            await handleDeleteIpSetReferences(checkedList);
            setIsOpenDelete(false);
          }}
        />
      ) : null}
    </>
  );
};

export default IpStateReferences;
