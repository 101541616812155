import './index.scss';
import { useState } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconCheck from 'assets/svgs/v3/ico_filled_check.svg';
import Icon from 'components/v2/atoms/Icon';
import Divider from 'pages/v2/Organ/Management/components/Divider';
import InputAtom from 'components/v2/atoms/InputAtom';

interface IDeleteSecretModalProps extends IBaseModalProps {
  data: string;
  onSubmit: (value: string) => void;
}

const daysDefault = '30';

const DeleteSecretModal = ({ data, onSubmit, ...baseModalProps }: IDeleteSecretModalProps) => {
  const [days, setDays] = useState<string>(daysDefault);

  const [daysError, setDaysError] = useState<boolean>(false);

  const handleChangeDays = (value: string) => {
    setDaysError(false);

    if (!value) {
      setDays('');
      setDaysError(true);
      return;
    }

    if (/^\d+$/.test(value)) {
      setDays(value);
    }

    if (!/^(?:[7-9]|1[0-9]|2[0-9]|30)$/.test(value)) {
      setDaysError(true);
    }
  };

  const handleClose = () => {
    setDays(daysDefault);
    if (baseModalProps?.onClose) baseModalProps.onClose();
  };

  const handleSave = () => {
    if (daysError) return;

    onSubmit(days);
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconCheck} />

          <span>Disable secret and schedule deletion</span>
        </>
      )}
      onClose={handleClose}
      {...baseModalProps}
    >
      <div className="modal-body">
        <p className="note">
          You are attempting to delete the primary secret dev/app/nemo/testing in ap-northeast-2. The last time this
          secret was accessed was 51 days ago. Secrets Manager requires a minimum waiting period of 7 days before
          deleting a secret. You will not be able to retrieve the secret once it is scheduled for deletion.
        </p>

        <Divider />

        <div className="input-container">
          <p className="note">Waiting period</p>

          <div className="input-group">
            <InputAtom
              required
              value={days?.toString()}
              onChangeValue={handleChangeDays}
              placeholder=""
              error={daysError}
            />

            <p className="note">days</p>
          </div>

          <p className="input-note">Must be from 7 to 30 days.</p>
        </div>

        <Divider />

        <div className="button-group">
          <button onClick={handleClose}>Cancel</button>

          <button className="save-btn" disabled={daysError} onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteSecretModal;
