import classNames from 'classnames';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import ClearIcon from 'assets/svgs/v2/ico_input_clear.svg';
import Button, { ButtonTypeEnum } from '../Button';
import './index.scss';
import { TagInputPropsType, TagRowData } from '../types';
import InputAtom from 'components/v2/atoms/InputAtom';

type ValueDataType = {
  value?: string;
};

type InputFormPropsType = {
  type?: 'INPUT' | 'DROPDOWN' | 'VALUE';
  field?: string;
  index?: number;
  clearable?: boolean;
  value?: ValueDataType;
  error?: boolean;
  setValue?: (key: string, value: string, index: number) => void;
};

export const InputForm = (props: InputFormPropsType) => {
  const { type, value, setValue, field, index, error } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnchange = (value: string) => {
    if (setValue) {
      setValue(field ?? '', value, index ?? 0);
    }
  };

  const handleClear = () => {
    handleOnchange('');
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="input-form-container">
      {type === 'INPUT' && (
        <input
          className="input-form-border"
          value={value?.value as string}
          onChange={event => {
            setValue && setValue('', event.target.value, 0);
          }}
        />
      )}
      {type === 'VALUE' && (
        <div className="value-wrapper input-form-border">
          <input
            className={classNames('value', error && 'error')}
            value={value?.value as string}
            onChange={event => {
              handleOnchange(event.target.value);
            }}
            ref={inputRef}
          />
          {value?.value && (
            <img
              src={ClearIcon}
              width={26}
              height={26}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleClear();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export type TagPropsType = {
  key: string;
  value: string;
};

export type TagsListPropsType = {
  tags: TagRowData[];
  onRemoveTag: (indexToRemove: number) => void;
  onUpdateTag: (index: number, value: string, propName: keyof TagRowData) => void;
};

const TagsList = (props: TagsListPropsType) => {
  const { tags, onRemoveTag, onUpdateTag } = props;

  return (
    <div className="tags-list-container">
      <div className="tags-header">
        <div className="tags-header-item title">Key</div>
        <div className="tags-header-item title">Value</div>
        <div style={{ width: 110 }}></div>
      </div>

      <div className="tags-body">
        {tags?.map(tag => {
          return (
            <div key={tag.index} className="tags-row">
              <div className="tags-column">
                <InputAtom
                  type={'text'}
                  placeholder={'Add unique key'}
                  value={tag.keyStr}
                  onChangeValue={(value: string) => onUpdateTag(tag.index, value, 'keyStr')}
                  defaultValue={''}
                  noClear={tag.keyStr === ''}
                  error={tag.keyStr === ''}
                  hasPrefixIcon={false}
                />
              </div>
              <div className="tags-column">
                <InputAtom
                  type={'text'}
                  placeholder={'Add Value'}
                  value={tag.valueStr}
                  onChangeValue={(value: string) => onUpdateTag(tag.index, value, 'valueStr')}
                  defaultValue={''}
                  noClear={tag.valueStr === ''}
                  error={tag.valueStr === ''}
                  hasPrefixIcon={false}
                />
              </div>
              <Button label="Remove tag" onClick={() => onRemoveTag(tag.index)} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const NoItem = () => {
  return (
    <div className="no-item-wrapper">
      <div className="title" style={{ textAlign: 'center', marginBottom: '15px' }}>
        No item
      </div>
    </div>
  );
};

const TagInput = (props: TagInputPropsType) => {
  const { currentTags, onChange } = props;

  const [listTags, setListTags] = useState<TagRowData[]>(currentTags);

  const handleAddTag = useCallback(() => {
    const newTags = listTags.slice();
    newTags.push({ index: listTags.length, keyStr: '', valueStr: '' });

    setListTags(newTags);
  }, [listTags]);

  const handleRemoveTag = useCallback(
    (indexToRemove: number) => {
      const newData = listTags.filter((_, index) => index != indexToRemove);
      setListTags(newData);
    },
    [listTags],
  );

  const handleUpdateTag = useCallback(
    (index: number, value: string, propName: keyof TagRowData) => {
      const newRows = listTags.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
      setListTags(newRows);
    },
    [listTags],
  );

  useEffect(() => {
    onChange(listTags);
  }, [listTags]);

  if (listTags.length === 0) {
    return (
      <Fragment>
        <NoItem />
        <Button label="Add tag" type={ButtonTypeEnum.PRIMARY} onClick={handleAddTag} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <TagsList
        tags={listTags}
        onRemoveTag={indexToRemove => handleRemoveTag(indexToRemove)}
        onUpdateTag={handleUpdateTag}
      />
      <Button label="Add tag" type={ButtonTypeEnum.PRIMARY} onClick={handleAddTag} />
    </Fragment>
  );
};

export default TagInput;
