import lazyGetAwsResourceProfile, { IGetAwsResourceProfileVariables } from 'graphql/queries/getAwsResourceProfile';
import { AwsGetResourceProfileResponse } from 'graphql/types/AwsResourceProfile';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import { appendKBUnit, convertBytesToKB } from 'pages/v2/Organ/Management/Macie/Function';
import { useEffect, useMemo, useState } from 'react';
import { IResourceCoverageSummaryProps } from '../../../../../types';
import DetailItem from '../../../../DetailItem';
import './index.scss';

interface ISensitiveDataDiscoveryProps extends IResourceCoverageSummaryProps {}

const SensitiveDataDiscovery = ({ cloudId, region, selectedResourceCoverage }: ISensitiveDataDiscoveryProps) => {
  const [resourceProfile, setResourceProfile] = useState<AwsGetResourceProfileResponse | null>(null);
  console.log('🚀 ~ SensitiveDataDiscovery ~ resourceProfile:', resourceProfile);
  const [getAwsResourceProfile] = lazyGetAwsResourceProfile();

  const fetchResourceProfile = async () => {
    const variables: IGetAwsResourceProfileVariables = {
      cloudId,
      region,
      request: {
        resourceArn: selectedResourceCoverage.bucketArn,
      },
    };

    const response = await getAwsResourceProfile({ variables });
    const resources = response.data?.getAwsResourceProfile.data?.[0] ?? null;
    setResourceProfile(resources);
  };

  const discoveryStatistics: IMgdDetailKeyValueProps[] = useMemo(() => {
    const totalKb = appendKBUnit(Math.round(convertBytesToKB(resourceProfile?.statistics.totalBytesClassified ?? 0)));

    return [
      {
        title: 'Analyzed bytes',
        description: totalKb,
      },
      {
        title: 'Classifiable bytes',
        description: totalKb,
      },
      {
        title: 'Total detections',
        description: resourceProfile?.statistics.totalDetections,
      },
    ];
  }, [resourceProfile]);

  const statistics = useMemo(() => {
    const sensitive = resourceProfile?.statistics.totalItemsSensitive || 0;
    const classified = resourceProfile?.statistics.totalItemsClassified || 0;
    const skipped = resourceProfile?.statistics.totalItemsSkipped || 0;
    const notSensitive = classified - sensitive;

    const total = sensitive + notSensitive + skipped;

    const sensitivePercent = (sensitive / total) * 100;
    const notSensitivePercent = (notSensitive / total) * 100;
    const skippedPercent = (skipped / total) * 100;

    return {
      sensitive,
      notSensitive,
      skipped,
      sensitivePercent,
      notSensitivePercent,
      skippedPercent,
    } as const;
  }, [resourceProfile]);

  const objectAnalyzed: IMgdDetailKeyValueProps[] = useMemo(
    () => [
      {
        title: 'Skipped: invalid encryption',
        description: resourceProfile?.statistics.totalItemsSkippedInvalidEncryption,
      },
      {
        title: 'Skipped: invalid KMS',
        description: resourceProfile?.statistics.totalItemsSkippedInvalidKms,
      },
      {
        title: 'Skipped: permission denied',
        description: resourceProfile?.statistics.totalItemsSkippedPermissionDenied,
      },
    ],
    [resourceProfile],
  );

  useEffect(() => {
    fetchResourceProfile();
  }, [JSON.stringify(selectedResourceCoverage)]);

  return (
    <div className="mdg-discovery-tab">
      <DetailItem title="Discovery statistics" data={discoveryStatistics} />

      <div className="mdg-discovery-analyzed">
        <div className="mdg-discovery-analyzed-container">
          <div className="mdg-discovery-analyzed-row mdg-discovery-analyzed-row--1 ">
            <p className="mdg-discovery-analyzed-title">Objects analyzed (4)</p>
            <div className="mdg-discovery-analyzed-list-bar">
              <div
                className="mdg-discovery-analyzed-bar mdg-discovery-analyzed-sensitive"
                style={{ width: statistics.sensitivePercent + '%' }}
              ></div>
              <div
                className="mdg-discovery-analyzed-bar mdg-discovery-analyzed-not-sensitive"
                style={{ width: statistics.notSensitivePercent + '%' }}
              ></div>
              <div
                className="mdg-discovery-analyzed-bar mdg-discovery-analyzed-skipped"
                style={{ width: statistics.skippedPercent + '%' }}
              ></div>
            </div>
          </div>
          <div className="mdg-discovery-analyzed-row mdg-discovery-analyzed-row--2">
            <div className="mdg-discovery-analyzed-col">
              <div className="mdg-discovery-analyzed-box mdg-discovery-analyzed-sensitive"></div>
              <span>Sensitive objects</span>
              <span>{statistics.sensitive}</span>
            </div>
            <div className="mdg-discovery-analyzed-col">
              <div className="mdg-discovery-analyzed-box mdg-discovery-analyzed-not-sensitive"></div>
              <span>Not sensitive objects</span>
              <span>{statistics.notSensitive}</span>
            </div>
            <div className="mdg-discovery-analyzed-col">
              <div className="mdg-discovery-analyzed-box mdg-discovery-analyzed-skipped"></div>
              <span>Object skipped </span>
              <span>{statistics.skipped}</span>
            </div>
          </div>
          {objectAnalyzed.map((o, index) => (
            <div
              key={index}
              className="mdg-discovery-analyzed-row mdg-discovery-analyzed-row--2 mdg-discovery-analyzed-row--skipped"
            >
              <span>{o.title}</span>
              <span>{o.description}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SensitiveDataDiscovery;
