import './style.scss';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import { getBetweenTwoDate } from 'utils/Common';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { NETWORK_TAB_LIST } from 'pages/v2/Organ/Management/Network/configs';
import { subnetFilterDropdown, vpcFilterDropdown } from 'pages/v2/Organ/Management/Network/Constants';
import { handleLinkClicked } from 'pages/v2/Organ/Management/Utils';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import { SECURITY_GROUP_FILTER_DROPDOWN } from 'pages/v2/Organ/Management/Network/components/SecurityGroup/configs';
import { EC2_TAB_LIST, INSTANCE_SEARCH_TYPES, LINK } from 'pages/v2/Organ/Management/configs';

function Details({ data }: any) {
  const navigate = useNavigate();
  const {
    // networkDetails
    networkInterfaceId,
    tagSet,
    description,
    status,
    interfaceType,
    groups,
    vpcId,
    subnetId,
    availabilityZone,
    rootAccountId,
    requesterId,
    requesterManaged,
    sourceDestCheck,

    // ipAddresses
    privateIpAddress,
    privateDnsName,
    privateIpAddresses,
    ipv6Addresses,
    association,
    macAddress,
    ipv4Prefixes,
    ipv6Prefixes,

    // instanceDetails
    // networkInterfaceAttachment
    attachment,

    // idleConnectionTrackingTimeout
    connectionTrackingConfiguration,
  } = data || {};
  console.log(data)

  const handleGetNameTag = (data: any) => {
    if (!data?.length) return '';

    const item = data.find((e: any) => e.key === 'Name');

    return item?.value || '';
  };

  const networkDetails: IMgdDetailKeyValueProps[] = [
    {
      title: 'Network interface ID',
      description: networkInterfaceId,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'Name',
      description: handleGetNameTag(tagSet),
    },
    {
      title: 'Description',
      description: description,
    },
    {
      title: 'Network interface status',
      description: status || '...',
    },
    {
      title: 'Interface Type',
      description: interfaceType === 'interface' ? 'Elastic network interface' : interfaceType,
    },
    {
      title: 'Security groups',
      description: groups?.length ? groups.map((e: any) => `${e.groupId}(${e.groupName})`) : '',
      type: groups?.length ? TextTypeEnum.COPY_LINK : TextTypeEnum.NORMAL,
      handleItemClick: groups?.length
        ? (value: string) =>
            handleLinkClicked({
              navigate,
              link: '/organ/1/manage/network',
              type: ManagementTypeEnum.NETWORK,
              tabId: NETWORK_TAB_LIST[7].id,
              key: SECURITY_GROUP_FILTER_DROPDOWN[0].value.toString(),
              value: value?.split('(')?.[0],
            })
        : null,
    },
    {
      title: 'VPC ID',
      description: vpcId,
      type: TextTypeEnum.LINK,
      handleItemClick: () =>
        handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[0].id,
          key: vpcFilterDropdown[0].value.toString(),
          value: vpcId,
        }),
    },
    {
      title: 'Subnet ID',
      description: subnetId,
      type: TextTypeEnum.LINK,
      handleItemClick: () =>
        handleLinkClicked({
          navigate,
          link: '/organ/1/manage/network',
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[2].id,
          key: subnetFilterDropdown[0].value.toString(),
          value: subnetId,
        }),
    },
    {
      title: 'Availability zone',
      description: availabilityZone,
    },
    {
      title: 'Owner',
      description: rootAccountId,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'Requester ID',
      description: requesterId,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'Requester-managed',
      description: requesterManaged?.toString() || '...',
    },
    {
      title: 'Source/dest. check',
      description: sourceDestCheck?.toString() || 'false',
    },
  ];

  const ipAddresses: IMgdDetailKeyValueProps[] = [
    {
      title: 'Private IPv4 address',
      description: privateIpAddress,
    },
    {
      title: 'Private IPv4 DNS',
      description: privateDnsName,
    },
    {
      title: 'Elastic Fabric Adapter',
      description: interfaceType === 'efa' ? 'true' : 'false',
    },
    {
      title: 'Public IPv4 address',
      description: privateIpAddresses?.length
        ? privateIpAddresses.map((e: any) => e.association?.publicIp).filter((e: string) => e)
        : '',
    },
    {
      title: 'Public IPv4 DNS',
      description: privateIpAddresses?.length
        ? privateIpAddresses.map((e: any) => e.association?.publicDnsName).filter((e: string) => e)
        : '',
    },
    {
      title: 'IPv6 addresses',
      description: ipv6Addresses?.length ? ipv6Addresses.map((e: any) => e.ipv6Address).filter((e: string) => e) : '',
    },
    {
      title: 'Secondary private IPv4 addresses',
      description: privateIpAddresses?.length
        ? privateIpAddresses.map((e: any) => !e.primary && e.privateIpAddress).filter((e: string) => e)
        : '',
    },
    {
      title: 'Association ID',
      description: association?.associationId,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'Elastic IP address owner',
      description: association?.ipOwnerId,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'MAC address',
      description: macAddress,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'IPv4 Prefix Delegation',
      description: ipv4Prefixes?.length ? ipv4Prefixes.map((e: any) => e.ipv4Prefix).filter((e: string) => e) : '',
    },
    {
      title: 'IPv6 Prefix Delegation',
      description: ipv6Prefixes?.length ? ipv6Prefixes.map((e: any) => e.ipv6Prefix).filter((e: string) => e) : '',
    },
    {
      title: 'Primary IPv6 address',
      description: ipv6Prefixes?.length
        ? ipv6Prefixes.map((e: any) => e.isPrimaryIpv6 && e.ipv6Address).filter((e: string) => e)
        : '',
    },
  ];

  const instanceDetails: IMgdDetailKeyValueProps[] = [
    {
      title: 'Instance ID',
      description: attachment?.instanceId,
      type: TextTypeEnum.LINK,
      handleItemClick: () =>
        handleLinkClicked({
          navigate,
          link: LINK.INSTANCE,
          type: ManagementTypeEnum.NETWORK,
          tabId: EC2_TAB_LIST[0].id,
          key: INSTANCE_SEARCH_TYPES[0].value.toString(),
          value: attachment?.instanceId,
        }),
    },
    {
      title: 'Instance owner',
      description: attachment?.instanceOwnerId,
    },
    {
      title: 'Device index',
      description: attachment?.deviceIndex?.toString(),
    },
    {
      title: 'Allocation ID',
      description: association?.allocationId,
      type: TextTypeEnum.COPY,
    },
  ];

  const networkInterfaceAttachment: IMgdDetailKeyValueProps[] = [
    {
      title: 'Attachment status',
      description: attachment?.status,
    },
    {
      title: 'Attachment ID',
      description: attachment?.attachmentId,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'Attachment time',
      description: getBetweenTwoDate(attachment?.attachTime),
    },
    {
      title: 'Delete on termination',
      description: attachment?.deleteOnTermination?.toString() || 'false',
    },
    {
      title: 'Network card index',
      description: attachment?.networkCardIndex?.toString(),
    },
    {
      title: 'ENA Express',
      description: attachment?.enaSrdSpecification?.enaSrdEnabled ? 'enabled' : 'disabled',
    },
    {
      title: 'ENA express UDP',
      description: attachment?.enaSrdSpecification?.enaSrdUdpSpecification?.enaSrdUdpEnabled ? 'enabled' : 'disabled',
    },
  ];

  const idleConnectionTrackingTimeout: IMgdDetailKeyValueProps[] = [
    {
      title: 'TCP established timeout',
      description: connectionTrackingConfiguration?.tcpEstablishedTimeout,
    },
    {
      title: 'UDP stream timeout',
      description: connectionTrackingConfiguration?.udpStreamTimeout,
    },
    {
      title: 'UDP timeout',
      description: connectionTrackingConfiguration?.udpTimeout,
    },
  ];

  return (
    <div className="network-interface-details-tab">
      <DetailTab title="Network interface details" data={networkDetails} />

      <DetailTab title="IP addresses" data={ipAddresses} />

      <DetailTab title="Instance details" data={instanceDetails} />

      <DetailTab title="Network interface attachment" data={networkInterfaceAttachment} />

      <DetailTab title="Idle connection tracking timeout" data={idleConnectionTrackingTimeout} />
    </div>
  );
}

export default Details;
