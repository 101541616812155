import { useMutation } from '@apollo/client';
import { IGqlResponseData } from 'graphql/types';
import query from './query';

export interface ITagAwsResourceMacieVariables {
  cloudId: number;
  region: string;
  request: {
    resourceArn: string;
    tags: Record<string, string | undefined>;
  };
}

export type TagAwsResourceMacieResponse = {};

export interface ITagAwsResourceMacieResponseData {
  tagAwsResourceMacie: IGqlResponseData<TagAwsResourceMacieResponse>;
}

const lazyTagAwsResourceMacieMutation = () =>
  useMutation<ITagAwsResourceMacieResponseData, ITagAwsResourceMacieVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyTagAwsResourceMacieMutation;
