import { gql } from '@apollo/client';

const query = gql`
  query getAwsDescribeSnapshotTierStatus(
    $request: AwsDescribeSnapshotTierStatusRequest
    $cloudId: Long
    $region: String
  ) {
    getAwsDescribeSnapshotTierStatus(describeSnapshotTierStatusRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsDescribeSnapshotTierStatusResponse {
          snapshotTierStatuses {
            snapshotId
            volumeId
            status
            ownerId
            tags {
              key
              value
            }
            storageTier
            lastTieringStartTime
            lastTieringProgress
            lastTieringOperationStatus
            lastTieringOperationStatusDetail
            archivalCompleteTime
            restoreExpiryTime
          }
          nextToken
        }
      }
    }
  }
`;
export default query;
