import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface ITagAwsVariables {
  cloudId: number;
  region: string;
  request: {
    arn: string;
    tags: JSON;
  };
}

export interface ITagAwsResponseData {
  editAwsTag: IGqlResponseData<undefined>;
}

const editAwsTagMutation = () =>
  useMutation<ITagAwsResponseData, ITagAwsVariables>(query);
export default editAwsTagMutation;
