export const FirewallConfigurationSyncStateMapping: {[key: string]: {text: string, style: string}} = {
  'IN_SYNC': {text: 'In sync', style: 'text-green-01'},
  'PENDING': {text: 'Pending', style: 'text-green-01'},
  'CAPACITY_CONSTRAINED': {text: 'Capacity constrained', style: 'text-green-01'}
};

export const FirewallStatusMapping: {[key: string]: {text: string, style: string}} = {
  'READY': {text: 'Ready', style: 'text-green-01'},
  'PROVISIONING': {text: 'Provisioning', style: 'text-green-01'},
  'DELETING': {text: 'Deleting', style: 'text-red'}
};

export const firewallTab = [
  { id: 'detail', name: 'Firewall details', title: 'Firewall details' },
  { id: 'policy', name: 'Firewall policy settings', title: 'Firewall policy settings' },
  { id: 'monitoring', name: 'Monitoring', title: 'Monitoring' },
];
