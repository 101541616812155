import { gql } from '@apollo/client';

const query = gql`
  mutation testAwsCustomDataIdentifier($request: AwsTestCustomDataIdentifierRequest, $cloudId: Long, $region: String) {
    testAwsCustomDataIdentifier(awsTestCustomDataIdentifierRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsTestCustomDataIdentifierResponse {
          matchCount
        }
      }
    }
  }
`;

export default query;
