import { gql } from '@apollo/client';

const query = gql`
  mutation cancelAwsKeyDeletion($reqData: AwsCancelKeyDeletionRequest, $cloudId: Long, $region: String) {
    cancelAwsKeyDeletion(cancelKeyDeletionRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsCancelKeyDeletionResponse {
          keyId
        }
      }
    }
  }
`;
export default query;
