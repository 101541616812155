import { IMgdTabProps } from 'layouts/v3/MgdTabLayout/types';

export enum PageNodeEnum {
  MGD_LIST = 'MGD_LIST',
  MGD_CREATE = 'MGD_CREATE',
  MGD_DETAIL = 'MGD_DETAIL',
}

export const MACIE_TAB: IMgdTabProps[] = [
  { id: 'Findings', name: 'Findings', title: 'Findings' },
  { id: 'Resource coverage', name: 'Resource coverage', title: 'Resource coverage' },
  { id: 'S3 bucket', name: 'S3 bucket', title: 'S3 bucket' },
  { id: 'Job', name: 'Job', title: 'Job' },
  { id: 'Setting', name: 'Setting', title: 'Setting' },
];

export const FINDINGS_TAB: IMgdTabProps[] = [
  { id: 'Items', name: 'Items', title: 'Items' },
  { id: 'Bucket', name: 'Bucket', title: 'Bucket' },
  { id: 'Type', name: 'Type', title: 'Type' },
  { id: 'Job', name: 'Job', title: 'Job' },
];

export const RESOURCE_COVERAGE_TAB: IMgdTabProps[] = [
  { id: 'All', name: 'All', title: 'All' },
  { id: 'Access denied', name: 'Access denied', title: 'Access denied' },
  { id: 'Classification error', name: 'Classification error', title: 'Classification error' },
  { id: 'Unclassifiable', name: 'Unclassifiable', title: 'Unclassifiable' },
];


export const RESOURCE_COVERAGE_SUMMARY_TAB: IMgdTabProps[] = [
  { id: 'Sensitivity', name: 'Sensitivity', title: 'Sensitivity Summary' },
  { id: 'Bucket Details', name: 'Bucket details', title: 'Bucket Details' },
  { id: 'Object Samples', name: 'Object samples', title: 'Object Samples' },
  { id: 'Sensitive data discovery', name: 'Sensitive data discovery', title: 'Sensitive data discovery' },
];

export const SETTING_TAB: IMgdTabProps[] = [
  { id: 'Overviews', name: 'Items', title: 'Items' },
  { id: 'Allow list', name: 'Allow list', title: 'Allow list' },
  { id: 'Custom identifier', name: 'Custom identifier', title: 'Custom identifier' },
  { id: 'Automated discovery', name: 'Automated discovery', title: 'Automated discovery' },
  { id: 'Discovery result', name: 'Discovery result', title: 'Discovery result' },
];
