import { ErrorCode } from '@Types/error';
import NetworkTitle from 'components/v3/NetworkTitle';
import { ICriteria } from 'graphql/mutations/createAwsAllowList';
import updateAwsAllowListMutation from 'graphql/mutations/updateAwsAllowList';
import lazyGetAwsAllowList from 'graphql/queries/getAwsAllowList';
import { useToast } from 'hooks/v2/useToast';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import { useCallback, useEffect, useState } from 'react';
import { ICreateAllowListData, ICreateAllowListErrors, ListType } from '../types';
import { CustomRadio } from './CreateMacieSettingAllowList';
import PredefinedText from './PredefinedText';
import RegularExpression from './RegularExpression';

type UpdateAllowListProps = {
  cloudId: number;
  region: string;
  allowListId: string | null;
  pageBackClick: () => void;
};

const UpdateAllowList = ({ cloudId, region, allowListId, pageBackClick }: UpdateAllowListProps) => {
  const initData: ICreateAllowListData = {
    listType: ListType.REGULAR_EXPRESSION,
    name: '',
    regularExpression: '',
    predefinedS3Name: '',
    predefinedS3Object: '',
    tags: [],
  };
  const [creationData, updateCreationData] = useState<ICreateAllowListData>(initData);
  const [getAwsAllowList] = lazyGetAwsAllowList();
  const [updateAwsAllowList] = updateAwsAllowListMutation();
  const [error, setError] = useState<ICreateAllowListErrors>({});
  const getData = useCallback(async () => {
    if (!allowListId) return;
    const { data } = await getAwsAllowList({ variables: { cloudId, region, request: { id: allowListId } } });
    if (!data?.getAwsAllowList?.data?.length) return;
    const {
      name,
      description,
      criteria: { regex, s3WordsList },
    } = data?.getAwsAllowList?.data?.[0] || {};
    const parseData: ICreateAllowListData = {
      name,
      description,
      listType: regex ? ListType.REGULAR_EXPRESSION : ListType.PREDEFINED_TEXT,
      regularExpression: regex || '',
      predefinedS3Name: s3WordsList ? s3WordsList.bucketName : '',
      predefinedS3Object: s3WordsList ? s3WordsList.objectKey : '',
      tags: [],
    };
    updateCreationData(parseData);
  }, [allowListId, cloudId, getAwsAllowList, region]);
  const validate = (): boolean => {
    let isValid = true;
    const { name, listType, regularExpression, predefinedS3Name, predefinedS3Object, tags } = creationData;
    let nameError, regular, s3Name, s3Object;
    if (!name || !name.trim()) {
      nameError = 'Name is required';
      isValid = false;
    }
    if (listType === ListType.REGULAR_EXPRESSION && (!regularExpression || !regularExpression.trim())) {
      regular = 'A regular expression is required';
      isValid = false;
    } else if (listType === ListType.PREDEFINED_TEXT) {
      if (!predefinedS3Name || !predefinedS3Name.trim()) {
        s3Name = 'A bucket name is required';
        isValid = false;
      }
      if (!predefinedS3Object || !predefinedS3Object.trim()) {
        s3Object = 'A object name is required';
        isValid = false;
      }
    }
    setError({
      name: nameError,
      regular: regular,
      s3Name,
      s3Object,
    });

    return isValid;
  };
  const handleSaveUpdate = async () => {
    if (!allowListId) return;
    if (!validate()) return;
    const { name, description, listType, regularExpression, predefinedS3Name, predefinedS3Object } = creationData;
    const criteria: ICriteria = {};
    if (listType === ListType.REGULAR_EXPRESSION) {
      criteria.regex = regularExpression;
    } else if (listType === ListType.PREDEFINED_TEXT) {
      criteria.s3WordsList = {
        bucketName: predefinedS3Name,
        objectKey: predefinedS3Object,
      };
    }
    const { errors } = await updateAwsAllowList({
      variables: {
        cloudId,
        region,
        request: {
          id: allowListId,
          name,
          description: description || undefined,
          criteria,
        },
      },
    });
    if (!errors) {
      useToast(ErrorCode.SUCCESS, 'Update allow list successful.');
      pageBackClick();
    } else {
      useToast(ErrorCode.UNKNOWN, 'Update allow list failed.');
    }
  };
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div id="mgd-list-layout">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center" id="title">
          <NetworkTitle
            pageTitle="Update allow list"
            id={321}
            name="name"
            hasPrefixIcon={false}
            hasFavorite={false}
            pageBackClick={pageBackClick}
          />
        </div>
      </div>
      <div className="one-page" style={{ height: 'calc(100% - 70px)', overflowY: 'auto' }}>
        <div className="row-3 flex j-between a-center">
          <div className="title">
            <p>Allow macie</p>
            <p className="sub">
              An allow list can be a file that lists predefined text to ignore or a regular expression that defines a
              text parttern to ignore. Choose the type to create
            </p>
          </div>
        </div>
        <div className="allow-list-screen">
          <div className="allow-list-container">
            <div className="allow-list-setting-type-container">
              <div
                className={`allow-list-flex ${
                  creationData.listType === ListType.REGULAR_EXPRESSION ? 'radio-selected' : ''
                }`}
              >
                <CustomRadio
                  checked={creationData.listType === ListType.REGULAR_EXPRESSION}
                  label="Regular expression"
                  description="Specify a regular expression (regex) that defines a text pattern to ignore."
                  onClick={() => {
                    if (creationData.listType === ListType.REGULAR_EXPRESSION) {
                      updateCreationData({ ...creationData, listType: ListType.REGULAR_EXPRESSION });
                      setError({});
                    }
                  }}
                  disabled={creationData.listType === ListType.REGULAR_EXPRESSION ? false : true}
                />
              </div>
              <div
                className={`allow-list-flex ${
                  creationData.listType === ListType.PREDEFINED_TEXT ? 'radio-selected' : ''
                }`}
              >
                <CustomRadio
                  checked={creationData.listType === ListType.PREDEFINED_TEXT}
                  label="Predefined text"
                  description="Specify the location of a plaintext file that lists specific text to ignore and is stored in an S3 bucket."
                  onClick={() => {
                    if (creationData.listType === ListType.PREDEFINED_TEXT) {
                      updateCreationData({ ...creationData, listType: ListType.PREDEFINED_TEXT });
                      setError({});
                    }
                  }}
                  disabled={creationData.listType === ListType.PREDEFINED_TEXT ? false : true}
                />
              </div>
            </div>
            {creationData.listType === ListType.REGULAR_EXPRESSION ? (
              <RegularExpression
                cloudId={cloudId}
                region={region}
                creationData={creationData}
                updateCreationData={updateCreationData}
                error={error}
              />
            ) : (
              <PredefinedText creationData={creationData} updateCreationData={updateCreationData} error={error} />
            )}
          </div>
          <div className="allow-list-bottom">
            {(error.name || error.regular || error.s3Name || error.s3Object) && (
              <p className="error-message">{error.name || error.regular || error.s3Name || error.s3Object}</p>
            )}
            <Button label="Cancel" onClick={pageBackClick} />
            <Button label="Save" type={ButtonTypeEnum.PRIMARY} onClick={handleSaveUpdate} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAllowList;
