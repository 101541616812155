import { useCallback, useEffect, useMemo, useState } from 'react';
import { AwsDescribeSecretType } from 'graphql/types/AwsDescribeSecret';
import { DETAIL_KEYS, DETAIL_TEXT_DES } from '../configs';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import lazyGetAwsListAliases from 'graphql/queries/getAwsListAliases';
import DetailTab from '../../../../components/DetailTab';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { DropdownListDataType } from 'components/Dropdown/types';
import EditDescriptionModal from '../Components/EditDescriptionModal';
import updateAwsSecretMutation, { IUpdateAwsSecretVariables } from 'graphql/mutations/updateAwsSecret';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import DeleteSecretModal from '../Components/DeleteSecret';
import EditEncryptionModal from '../Components/EditEncryptionModal';
import TagTab from 'pages/v2/Organ/Management/components/TagTab';
import { ColumnType, RowType } from '@Types/v2/Table';
import AddOrEditTagsModal, { TagRowData } from '../Components/AddOrEditTagsModal';
import { AwsTagType } from 'graphql/types/AwsVpc';
import { IGqlResponseData } from 'graphql/types';
import untagAwsResourceSecretsMutation, {
  IUntagAwsResourceSecretsVariables,
} from 'graphql/mutations/untagAwsResourceSecrets';
import tagAwsResourceSecretsMutation, {
  ITagAwsResourceSecretsVariables,
} from 'graphql/mutations/tagAwsResourceSecrets';

const Tags = (props: any) => {
  const { cloudId, region, secret, onReloadData } = props;

  const [isOpenEditTag, setIsOpenEditTag] = useState(false);

  const [untagAwsResourceSecret] = untagAwsResourceSecretsMutation();
  const [tagAwsResourceSecrets] = tagAwsResourceSecretsMutation();

  const tagRows = useMemo((): RowType[] => {
    return secret?.tags ?? [];
  }, [secret]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const currentTagData = useMemo((): TagRowData[] => {
    const tagRowData: TagRowData[] = [];
    if (secret?.tags && secret.tags.length > 0) {
      secret.tags.map((tag: any, index: number) => {
        tagRowData.push({
          index,
          keyStr: tag.key,
          valueStr: tag.value,
        });
      });
    }
    return tagRowData;
  }, [secret?.tags]);

  const handleDeleteTags = async (tags: TagRowData[]): Promise<IGqlResponseData<undefined> | undefined> => {
    try {
      const tagKeys: string[] = tags.map(currentTag => currentTag.keyStr);
      const deleteReqVar: IUntagAwsResourceSecretsVariables = {
        cloudId,
        region,
        reqData: {
          secretId: secret?.arn,
          tagKeys,
        },
      };
      const { data: responseData } = await untagAwsResourceSecret({ variables: deleteReqVar });

      return responseData?.untagAwsResourceSecrets;
    } catch (error) {}
  };

  const handleCreateTags = async (tags: TagRowData[]): Promise<IGqlResponseData<undefined> | undefined> => {
    try {
      const awsTags: AwsTagType[] = tags.map(currentTag => {
        return { key: currentTag.keyStr, value: currentTag.valueStr };
      });

      const createReqVar: ITagAwsResourceSecretsVariables = {
        cloudId,
        region,
        reqData: {
          secretId: secret?.arn,
          tags: awsTags,
        },
      };
      const { data: responseData } = await tagAwsResourceSecrets({ variables: createReqVar });

      return responseData?.tagAwsResourceSecrets;
    } catch (error) {}
  };

  const handleUpdateTags = async (oldTags: TagRowData[], newTags: TagRowData[]) => {
    const isCurrentTagChanged = JSON.stringify(currentTagData) !== JSON.stringify(oldTags);
    if (isCurrentTagChanged) {
      const [deleteResponse, createResponse] = await Promise.all([
        handleDeleteTags(currentTagData),
        handleCreateTags([...oldTags, ...newTags]),
      ]);
      if (deleteResponse?.result === ErrorCode.SUCCESS && createResponse?.result === ErrorCode.SUCCESS) {
        useToast(ErrorCode.SUCCESS, 'Update tags successful.');
        onReloadData();
        setIsOpenEditTag(false);
      } else {
        useToast(ErrorCode.UNKNOWN, 'Update tags failed.');
      }
    } else {
      if (newTags.length > 0) {
        const createResponse = await handleCreateTags(newTags);
        if (createResponse?.result === ErrorCode.SUCCESS) {
          useToast(ErrorCode.SUCCESS, 'Update tags successful.');
          onReloadData();
          setIsOpenEditTag(false);
        } else {
          useToast(ErrorCode.UNKNOWN, 'Update tags failed.');
        }
      } else {
        setIsOpenEditTag(false);
      }
    }
  };

  return (
    <>
      <TagTab
        columns={tagColumns}
        rows={tagRows}
        title={'Tags'}
        rightButtons={[{ id: 'edit-tags', label: 'Edit tags', onClick: () => setIsOpenEditTag(true) }]}
      />

      {isOpenEditTag ? (
        <AddOrEditTagsModal
          open={isOpenEditTag}
          onClose={() => setIsOpenEditTag(false)}
          currentDatas={currentTagData}
          onSave={handleUpdateTags}
        />
      ) : null}
    </>
  );
};

export default Tags;
