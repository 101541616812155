import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsGetFindingStatisticsResponse } from 'graphql/types/AwsGetFindingStatisticsResponse';

export interface IGetAwsFindingStatisticsVariables {
  cloudId: number;
  region: string;
  reqData: {
    groupBy: string;
    size?: number;
    findingCriteria?: {
      criterion: string
    };
  };
}

export interface IGetAwsFindingStatisticsResponseData {
  getAwsFindingStatistics: IGqlResponseNewData<AwsGetFindingStatisticsResponse>;
}

const lazyGetAwsFindingStatistics = () =>
  useLazyQuery<IGetAwsFindingStatisticsResponseData, IGetAwsFindingStatisticsVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsFindingStatistics;
