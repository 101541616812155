import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, from } from '@apollo/client';
import { PropsWithChildren, useMemo, createContext, useContext, useState, useCallback, useEffect } from 'react';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth } from '../AuthProvider';
import { ErrorCode } from '@Types/error';
import { useSnackbar } from 'hooks/useSnackbar';
import { isDev } from 'utils/Common';

const GraphqlContext = createContext(null);

/**
 * 현재 서비스 타입에 맞는 api url 획득
 *
 * - standalone: 유저가 직접 api서버 주소를 입력 가능
 * - cloud: 고정 url 사용
 * */
const getDefaultURI = (testMode?: 'DEFAULT' | 'LOCAL') => {
  return `${process.env.REACT_APP_NEMO_API}/graphql`;
};

/**
 * ApolloProvider 제공을 위한 wrapper 컴포넌트
 *
 * - ApolloClient 인스턴스 생성
 * - API url 설정 (dev)
 */
interface IGraphqlProviderProps {
  testMode?: 'DEFAULT' | 'LOCAL';
}
const GraphqlProvider = ({ testMode = 'DEFAULT', children }: PropsWithChildren<IGraphqlProviderProps>) => {
  const { token, logout,changeGqlTokenValid } = useAuth();

  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: operation => {
        if (isDev) {
          return `${getDefaultURI()}?${operation.operationName}`;
        }

        return getDefaultURI();
      },
    });

    const authLink = setContext((_, { headers }) => {
      const localToken = localStorage.getItem('token');
      return {
        headers: {
          ...headers,
          ...(localToken && { authorization: `Bearer ${localToken}` }),
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        if (
          graphQLErrors[0].extensions.result === ErrorCode.INVLAID_AUTH_TOKEN ||
          graphQLErrors[0].extensions.result === ErrorCode.TOKEN_ERROR
        ) {
          changeGqlTokenValid(false);
          logout();
        } else {
          if (graphQLErrors[0].path) {
            if (
              graphQLErrors[0].path[0] === 'getEventSettingPageByParam' &&
              graphQLErrors[0].extensions.result === 'sc0001'
            ) {
              useSnackbar(graphQLErrors[0].extensions.result as ErrorCode, '알림 목록에서 알림을 선택해주세요');
            } else if (graphQLErrors[0].path[0] !== 'getAwsLoggingConfiguration') {
              useSnackbar(graphQLErrors[0].extensions.result as ErrorCode);
            }
          } else {
            useSnackbar(graphQLErrors[0].extensions.result as ErrorCode);
          }
        }
        // console.log('graphQLErrors', graphQLErrors);
        // console.log(graphQLErrors);
        // graphQLErrors.forEach(({ extensions, message, locations, path }) =>
        //   console.log(
        //     `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}, extensions: ${JSON.stringify(extensions)}`
        //   )
        // );
        // if (graphQLErrors[0].message === 'AUTH_FAIL_TOKEN_EXPIRED' || graphQLErrors[0].message === 'AUTH_FAIL_TOKEN INVALID') {
        //   console.log
        // } else {
        //   showBoundary(graphQLErrors[0]);
        // }
      }
      if (networkError) {
        console.log('network', networkError);
        // showBoundary(networkError);
      }
    });

    return new ApolloClient({
      link: from([errorLink, authLink.concat(httpLink)]),
      cache: new InMemoryCache({
        addTypename: false,
      }),
    });
  }, [token, logout]);

  return (
    <GraphqlContext.Provider value={null}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </GraphqlContext.Provider>
  );
};

export default GraphqlProvider;
export const useGqlContext = () => useContext(GraphqlContext);
