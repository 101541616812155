import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconEditFirewallDetail from 'assets/svgs/v3/ico_edit_firewall_detail.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';

interface IEditFirewallPolicyDetailProps extends IBaseModalProps {
  header: string;
  firewallPolicyName: string,
  description: string;
  statefulEngineOptions: any;
  handleUpdateFirewallPolicyDetail: (desc: string, streamExceptionPolicy: string) => boolean;
  onChangeValue?: (index: number, value: string) => void;
  onClose: () => void;
  loading: boolean;
}

const EditFirewallPolicyDetailModal = ({
  header,
  firewallPolicyName,
  description,
  statefulEngineOptions,
  onChangeValue,
  onClose,
  handleUpdateFirewallPolicyDetail,
  loading,
  ...baseModalProps
}: IEditFirewallPolicyDetailProps) => {

  const [firewallHandleValues, setfirewallHandleValues] = useState<string>('drop');
  const [descriptionValue, setDescriptionValue] = useState<string>(description || '');

  const onSavePolicy = async() => {
    const result = await handleUpdateFirewallPolicyDetail(descriptionValue, firewallHandleValues);
    result ? onClose() : null;
  };
  
  useEffect(() => {
    if (baseModalProps.open) {
      setfirewallHandleValues(statefulEngineOptions?.streamExceptionPolicy);
      setDescriptionValue(description);
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditFirewallDetail} />
          {header}
        </>
      )}
      onClose={onClose}
      {...baseModalProps}>

      <div className="firewall-policy-details-modal">
        <div className="firewall-policy-details">
          <div className="row-1">
            <p className="text-title">Name</p>
            <p className="text-description">{firewallPolicyName}</p>
            <p className="text-title">Description</p>
            <InputAtom
              placeholder={'Add empty item'}
              onChangeValue={val => {
                if (val.length <= 256) setDescriptionValue(val);
              }}
              value={descriptionValue}
              required={true}
              noClear={false}
            />
            <p className="text-title">The description can have 0-256 characters.</p>
          </div>

          <div className="row-2">
            <p className="text-title">Stream exception policy</p>
            <p className="text-description">Choose how Network Firewall handles traffic when a network connection breaks midstream.</p>
            <RadioAtom
              label={'Drop'}
              description={'Drop all subsequent traffic going to the firewall.'}
              value={'DROP'}
              name={''}
              checked={firewallHandleValues}
              onChange={() => {setfirewallHandleValues('DROP');}}
            />

            <RadioAtom
              label={'Continue'}
              description={'Continue processing rules without context from previous traffic.'}
              value={'CONTINUE'}
              name={''}
              checked={firewallHandleValues}
              onChange={() => {setfirewallHandleValues('CONTINUE');}}
            />

            <RadioAtom
              label={'Reject'}
              description={'Fails closed, sends a TCP reset packet to the sender, and drops all subsequent traffic going to the firewall.'}
              value={'REJECT'}
              name={''}
              checked={firewallHandleValues}
              onChange={() => {setfirewallHandleValues('REJECT');}}
            />

          </div>
        </div>
        <div className="button-group">
          <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={onClose} />
          <Button
            label="Save"
            type={ButtonTypeEnum.PRIMARY}
            loading={loading}
            onClick={onSavePolicy}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditFirewallPolicyDetailModal;
