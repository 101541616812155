import ClearIcon from 'assets/svgs/v2/ico_input_clear.svg';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import Button, { ButtonTypeEnum } from '../../../components/Button';
import MacieSearchBarFilterTag from './components/FilterTag';
import MacieSearchBarDropdown from './components/SearchDropdown';
import {
  FINDING_FILTERS_OPTIONS,
  FINDING_STATUS,
  generateSearchParam,
  JOB_FILTERS_OPTIONS,
  RESOURCE_COVERAGE_FILTERS_OPTIONS,
  S3_BUCKET_FILTERS_OPTIONS
} from './config';
import './index.scss';
import { FilterCriteria, FilterType, FilterValueType, MultiSelectType } from './types';

type IMacieSearchBarPropType = {
  filterType: FilterType;
  onSearch: () => any;
  onSearchDataChange: (param: any) => any;
  initialAppliedFilters: FilterCriteria[];
};

const MacieSearchBar = ({
  onSearch,
  filterType,
  initialAppliedFilters,
  onSearchDataChange,
}: IMacieSearchBarPropType) => {
  const [findingStatus, setFindingStatus] = useState<DropdownListDataType>(FINDING_STATUS[0]);
  const [appliedFilters, setAppliedFilters] = useState<FilterCriteria[]>(initialAppliedFilters);
  const [resetSearchDataSignal, setResetSearchDataSignal] = useState(false);

  const filterCriteriaList = useMemo(() => {
    switch (filterType) {
      case FilterType.FINDING:
        return FINDING_FILTERS_OPTIONS;
      case FilterType.S3_BUCKET:
        return S3_BUCKET_FILTERS_OPTIONS;
      case FilterType.RESOURCE_COVERAGE:
        return RESOURCE_COVERAGE_FILTERS_OPTIONS;
      case FilterType.JOB:
        return JOB_FILTERS_OPTIONS;
      default:
        return [];
    }
  }, [filterType]);

  const isFindingStatusShow = useMemo(() => filterType === FilterType.FINDING, [filterType]);

  useEffect(() => {
    setAppliedFilters(initialAppliedFilters);
    setResetSearchDataSignal(prev => !prev);
  }, [filterType]);

  const onFilterApply = (filterData: FilterCriteria) => {
    if (appliedFilters.find(filter => _.isEqual(filter, filterData))) {
      return;
    }
    switch (filterData.type) {
      case FilterValueType.VALUE_TYPE:
        setAppliedFilters(prev => [...prev, filterData]);
        return;
      case FilterValueType.MULTI_SELECT_TYPE:
        const indexOfExistFilter = appliedFilters.findIndex(filter => filter.name === filterData.name);
        if (indexOfExistFilter > -1) {
          const newAppliedFilters = _.cloneDeep(appliedFilters);
          const existFilter = newAppliedFilters[indexOfExistFilter];
          const valueOfExistFilter = existFilter.value as MultiSelectType;
          valueOfExistFilter.push(...(filterData.value as MultiSelectType));
          const uniqueValue = _.uniqBy(valueOfExistFilter, 'value');
          existFilter.value = uniqueValue;
          setAppliedFilters(newAppliedFilters);
        } else {
          setAppliedFilters(prev => [...prev, filterData]);
        }
        return;
      case FilterValueType.FROM_TO_TYPE:
        setAppliedFilters(prev => [...prev, filterData]);
        return;
      case FilterValueType.DATE_TYPE:
        setAppliedFilters(prev => [...prev, filterData]);
        return;
    }
  };

  const onRemoveFilter = (filterData: FilterCriteria) => {
    setAppliedFilters(prev =>
      prev.filter(filter => {
        if (filter.type === FilterValueType.MULTI_SELECT_TYPE) {
          return filter.name !== filterData.name;
        }
        return !(filter.name === filterData.name && _.isEqual(filter.value, filterData.value));
      }),
    );
  };

  const removeAllFilter = () => {
    setAppliedFilters([]);
    setResetSearchDataSignal(prev => !prev);
  };

  const searchData = useMemo(() => {
    return generateSearchParam(filterType, appliedFilters, findingStatus);
  }, [filterType, appliedFilters, findingStatus]);

  useEffect(() => {
    onSearchDataChange(searchData);
  }, [searchData]);

  return (
    <div className="search-filter-main-container">
      {isFindingStatusShow && (
        <div className="macro-filter">
          <div className="macro-filter-container">
            <div className="macro-filter-control-label">Resource type</div>
            <div className="macro-filter-control">
              <DropdownAtom
                id={'macro-filter-control-dropdown'}
                className="macro-filter-control-dropdown"
                data={FINDING_STATUS}
                value={findingStatus}
                handleClick={value => {
                  setFindingStatus(value);
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="search-filter">
        <div className="search-filter-control-label">Search</div>
        <div className="search-filter-input-container">
          <div className="search-filter-input-control">
            {appliedFilters.map((filterData, index) => (
              <MacieSearchBarFilterTag
                key={`macie-search-bar-filter-tag-${index}`}
                filterData={filterData}
                onRemoveFilter={onRemoveFilter}
              />
            ))}
            <MacieSearchBarDropdown
              id={'macie-search-dropdown'}
              data={filterCriteriaList}
              placeholder="Input search item"
              onFilterApply={onFilterApply}
              resetDataSignal={resetSearchDataSignal}
            />
          </div>
          {appliedFilters.length > 0 && (
            <div className="search-filter-input-action">
              <img src={ClearIcon} width={24} height={24} onClick={removeAllFilter} />
            </div>
          )}
        </div>
      </div>

      <div className="search-button-container">
        <div className="search-filter-control-label">Button</div>
        <div className="search-button">
          <Button type={ButtonTypeEnum.PRIMARY} label="Search" onClick={onSearch} />
        </div>
      </div>
    </div>
  );
};

export default MacieSearchBar;
