import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { AwsDisableKeyRotationRequestType } from 'graphql/types/AwsDisableKeyRotation';

export type AwsDisableKeyVariablesType = {
  cloudId: number;
  region: string;
  reqData: {
    keyId?: string;
  };
};

export type AwsDisableKeyResponseDataType = {
  disableAwsKey: IGqlResponseData<{}>;
};

const disableAwsKeyMutation = () => useMutation<AwsDisableKeyResponseDataType, AwsDisableKeyVariablesType>(query);
export default disableAwsKeyMutation;
