import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import { useState } from 'react';
import CusMultiSelectAutocomplete from '../../components/CusMultiSelectAutocomplete';
import CustomRadio from '../../components/CustomRadio';
import { listMasksFlags, listPortOptions, listProtocols, listSourceOptions } from '../../configs';
import { IStatelessErrors } from '../../type';
import { ICreationDataPropsType } from '../type';
import CustomDropdown from '../../../Common/Components/CustomDropdown';

interface IStatelessProps {
  creationData: ICreationDataPropsType;
  updateCreationData: (data: ICreationDataPropsType) => void;
}

const Stateless = ({ creationData, updateCreationData }: IStatelessProps) => {
  const { ruleRowsDomainList } = creationData;
  const [statelessRulePriority, setStatelessRulePriority] = useState<string>('1');
  const [statelessRuleProtocols, setStatelessRuleProtocols] = useState<DropdownListDataType[]>([listProtocols[0]]);
  const [ruleAction, setRuleAction] = useState<'pass' | 'drop' | 'forward'>('pass');
  const [statelessDestination, setStatelessDestination] = useState<DropdownListDataType>(listSourceOptions[0]);
  const [statelessListDestination, setStatelessListDestination] = useState<string>('');
  const [statelessDestinationPort, setStatelessDestinationPort] = useState<DropdownListDataType>(listPortOptions[1]);
  const [statelessListDestinationPort, setStatelessListDestinationPort] = useState<string>('-');
  const [statelessSource, setStatelessSource] = useState<DropdownListDataType>(listSourceOptions[0]);
  const [statelessListSource, setStatelessListSource] = useState<string>('');
  const [statelessSourcePort, setStatelessSourcePort] = useState<DropdownListDataType>(listPortOptions[1]);
  const [statelessListSourcePort, setStatelessListSourcePort] = useState<string>('-');
  const [statelessMasks, setStatelessMasks] = useState<DropdownListDataType[]>([]);
  const [statelessFlag, setStatelessFlag] = useState<DropdownListDataType[]>([]);
  const [errors, setErrors] = useState<IStatelessErrors>({
    priority: false,
    source: false,
    sourcePort: false,
    destination: false,
    destinationPort: false,
  });
  const handleSelectProtocol = (val: DropdownListDataType) => {
    const newStatelessRuleProtocols = statelessRuleProtocols.concat(val);
    setStatelessRuleProtocols(newStatelessRuleProtocols);
  };
  const handleRemoveSelectedProtocol = (val: DropdownListDataType) => {
    const index = statelessRuleProtocols.indexOf(val);
    if (index > -1) {
      const newStatelessRuleProtocols = statelessRuleProtocols.slice();
      newStatelessRuleProtocols.splice(index, 1);
      setStatelessRuleProtocols(newStatelessRuleProtocols);
    }
  };
  const handleSelectMask = (val: DropdownListDataType) => {
    const newStatelessMasks = statelessMasks.concat(val);
    setStatelessMasks(newStatelessMasks);
  };
  const handleRemoveSelectedMask = (val: DropdownListDataType) => {
    const index = statelessMasks.indexOf(val);
    if (index > -1) {
      const newStatelessMasks = statelessMasks.slice();
      newStatelessMasks.splice(index, 1);
      setStatelessMasks(newStatelessMasks);
    }
  };
  const handleSelectFlags = (val: DropdownListDataType) => {
    const newStatelessFlag = statelessFlag.concat(val);
    setStatelessFlag(newStatelessFlag);
  };
  const handleRemoveSelectedFlags = (val: DropdownListDataType) => {
    const index = statelessMasks.indexOf(val);
    if (index > -1) {
      const newStatelessFlag = statelessFlag.slice();
      newStatelessFlag.splice(index, 1);
      setStatelessFlag(newStatelessFlag);
    }
  };

  const validate = (): boolean => {
    let priority = false,
      source = false,
      sourcePort = false,
      destination = false,
      destinationPort = false;
    if (ruleRowsDomainList.find(item => item.priority === statelessRulePriority)) {
      priority = true;
    }
    if (statelessSource.id === 1 && !statelessListSource.trim()) {
      source = true;
    }
    if (statelessSourcePort.id === 1 && !statelessListSourcePort.trim()) {
      sourcePort = true;
    }
    if (statelessDestination.id === 1 && !statelessListDestination.trim()) {
      destination = true;
    }
    if (statelessDestinationPort.id === 1 && !statelessListDestinationPort.trim()) {
      destinationPort = true;
    }
    setErrors({
      priority,
      source,
      sourcePort,
      destination,
      destinationPort,
    });

    return !priority && !source && !sourcePort && !destination && !destinationPort;
  };
  const handleAddRuleDomainList = () => {
    if (!validate()) return;
    const protocol = statelessRuleProtocols.reduce((result, current) => {
      result += result ? `/${current.value}` : current.value;

      return result;
    }, '');
    const data = {
      priority: statelessRulePriority,
      protocol: protocol,
      source: statelessListSource,
      destination: statelessListDestination,
      sourcePort: statelessListSourcePort,
      destinationPort: statelessListDestinationPort,
      action: ruleAction,
      masks: statelessMasks.map(item => item.value),
      flags: statelessFlag.map(item => item.value),
    };
    const arr = [...ruleRowsDomainList];
    arr.push(data);
    updateCreationData({ ...creationData, ruleRowsDomainList: arr });
  };
  const STR_SOURCE = '10.1.0.0/16\n10.1.0.0\n2001:db8:2de::e13';

  return (
    <>
      <div className="rule-group-details-container" style={{ flex: 1, marginTop: 12 }}>
        <div className="rule-group-detail-title">
          Stateless rule
          <div className="rule-group-detail-sub-title">
            Add the stateless rule that you need in your rule group. Each rule that you add is listed in the Rules table
            below.
          </div>
        </div>
        <>
          <div
            className="rule-group-detail-title"
            style={{
              borderBottom: 'none',
              padding: '8px 8px 0px 16px',
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            }}
          >
            Priority
            <div className="rule-group-detail-sub-title">
              Rule with lower priority are evaluated first. Each rule within a rule group must have a unique priority
              setting.
            </div>
          </div>
          <div className="rule-group-detail-content" style={{ gap: 16, padding: '8px 16px' }}>
            <div style={{ width: '50%' }}>
              <InputAtom
                noClear={true}
                value={statelessRulePriority}
                onChangeValue={value => setStatelessRulePriority(value.replace(/\D/g, ''))}
                error={errors.priority}
              />
            </div>
          </div>
        </>
        <>
          <div
            className="rule-group-detail-title"
            style={{
              borderBottom: 'none',
              padding: '8px 8px 0px 16px',
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            }}
          >
            Protocol
            <div className="rule-group-detail-sub-title">Transport protocols to inspect for</div>
          </div>
          <div className="rule-group-detail-content" style={{ gap: 16, padding: '8px 16px' }}>
            <div style={{ width: '50%' }}>
              <CusMultiSelectAutocomplete
                placeholder="Choose options"
                data={listProtocols}
                selectedValues={statelessRuleProtocols}
                handleClick={(val: DropdownListDataType) => handleSelectProtocol(val)}
                handleRemove={(val: DropdownListDataType) => handleRemoveSelectedProtocol(val)}
              />
            </div>
          </div>
          <div className="rule-group-detail-content" style={{ gap: 8, padding: '8px 16px' }}>
            <div className="config-rule-row">
              <div className="config-rule-column">
                <div className="rule-group-detail-title" style={{ padding: 0, borderBottom: 'none' }}>
                  Source
                  <div className="rule-group-detail-sub-title">
                    The source IP addresses and address ranges to inspect for. You can provide single addresses and CIDR
                    blocks.
                  </div>
                </div>
              </div>
              <div className="config-rule-column">
                <div className="rule-group-detail-title" style={{ padding: 0, borderBottom: 'none' }}>
                  Source port range
                  <div className="rule-group-detail-sub-title">
                    The source ports and port ranges to inspect for. This only applies to TCP and UDP protocols.
                  </div>
                </div>
              </div>
            </div>

            <div className="config-rule-row">
              <div className="config-rule-column">
                <CustomDropdown
                  id="stateless-rule-source"
                  data={listSourceOptions}
                  value={statelessSource}
                  handleClick={val => {
                    setStatelessSource(val);
                    setStatelessListSource(val.value.toString());
                  }}
                  error={errors.source}
                  style={{ height: 40 }}
                />
              </div>
              <div className="config-rule-column">
                <CustomDropdown
                  id="stateless-rule-source-port-range"
                  data={listPortOptions}
                  value={statelessSourcePort}
                  handleClick={data => {
                    setStatelessSourcePort(data);
                    setStatelessListSourcePort(data.value.toString());
                  }}
                  error={errors.sourcePort}
                  disabled={
                    statelessRuleProtocols.length > 0 &&
                    statelessRuleProtocols.filter(rule => rule.value !== 'TCP' && rule.value !== 'UDP').length > 0
                  }
                  style={{ height: 40 }}
                />
              </div>
            </div>
            <div className="config-rule-row">
              <div className="config-rule-column">
                <div className="config-rule-textarea">
                  <textarea
                    disabled={statelessSource.id !== 1}
                    placeholder={STR_SOURCE}
                    value={statelessListSource}
                    onChange={event => setStatelessListSource(event.target.value)}
                  />
                </div>
              </div>
              <div className="config-rule-column">
                <div className="config-rule-textarea">
                  <textarea
                    disabled={statelessSourcePort.id !== 1}
                    placeholder="10:1000"
                    value={statelessListSourcePort}
                    onChange={event => {
                      setStatelessListSourcePort(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="config-rule-row">
              <div className="config-rule-column">
                <div className="config-rule-description">
                  Enter one value per line and use either IPv4 or IPv6 values but not both together.
                </div>
              </div>
              <div className="config-rule-column">
                <div className="config-rule-description">
                  Allowed port ranges are 0-66365. Enter one port range per line.
                </div>
              </div>
            </div>
          </div>
          <div className="rule-group-detail-content" style={{ gap: 8, padding: '8px 16px' }}>
            <div className="config-rule-row">
              <div className="config-rule-column">
                <div className="rule-group-detail-title" style={{ padding: 0, borderBottom: 'none' }}>
                  Destination
                  <div className="rule-group-detail-sub-title">
                    The destination IP addresses and address ranges to inspect for. You can provide single addresses and
                    CIDR blocks.
                  </div>
                </div>
              </div>
              <div className="config-rule-column">
                <div className="rule-group-detail-title" style={{ padding: 0, borderBottom: 'none' }}>
                  Destination port range
                  <div className="rule-group-detail-sub-title">
                    The description ports and port ranges to inspect for. This only applies to TCP and UDP protocols
                  </div>
                </div>
              </div>
            </div>

            <div className="config-rule-row">
              <div className="config-rule-column">
                <CustomDropdown
                  id="stateless-rule-destination"
                  data={listSourceOptions}
                  value={statelessDestination}
                  error={errors.destination}
                  handleClick={val => {
                    setStatelessDestination(val);
                    setStatelessListDestination(val.value.toString());
                  }}
                  style={{ height: 40 }}
                />
              </div>
              <div className="config-rule-column">
                <CustomDropdown
                  id="stateless-rule-destination-port-range"
                  data={listPortOptions}
                  value={statelessDestinationPort}
                  error={errors.destinationPort}
                  handleClick={data => {
                    setStatelessDestinationPort(data);
                    setStatelessListDestinationPort(data.value.toString());
                  }}
                  disabled={
                    statelessRuleProtocols.length > 0 &&
                    statelessRuleProtocols.filter(rule => rule.value !== 'TCP' && rule.value !== 'UDP').length > 0
                  }
                  style={{ height: 40 }}
                />
              </div>
            </div>
            <div className="config-rule-row">
              <div className="config-rule-column">
                <div className="config-rule-textarea">
                  <textarea
                    disabled={statelessDestination.id !== 1}
                    placeholder={STR_SOURCE}
                    value={statelessListDestination}
                    onChange={event => setStatelessListDestination(event.target.value)}
                  />
                </div>
              </div>
              <div className="config-rule-column">
                <div className="config-rule-textarea">
                  <textarea
                    disabled={statelessDestinationPort.id !== 1}
                    placeholder="10:1000"
                    value={statelessListDestinationPort}
                    onChange={event => {
                      setStatelessListDestinationPort(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="config-rule-row">
              <div className="config-rule-column">
                <div className="config-rule-description">
                  Enter one value per line and use either Enter one value per line and use either IPv4 or IPv6 values
                  but not both together.
                </div>
              </div>
              <div className="config-rule-column">
                <div className="config-rule-description">
                  Allowed port ranges are 0-66365. Enter one port range per line.
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '10px 16px' }}>
            <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: 0 }}>
              Rule action
              <div className="rule-group-detail-sub-title">
                Choose how you want the firewall to handle packets that match the rule criteria.
              </div>
            </div>
            <div className="rule-group-type-content" style={{ gap: 8, padding: 0 }}>
              <div className="type-content-row">
                <CustomRadio
                  checked={ruleAction === 'pass'}
                  label="Pass"
                  description="Discontinue all inspection of the packet and permit it to go to intended destination."
                  onClick={() => setRuleAction('pass')}
                />
              </div>
              <div className="type-content-row">
                <CustomRadio
                  checked={ruleAction === 'drop'}
                  label="Drop"
                  description="Discontinue all inspection of the packet and block it from going to intended destination."
                  onClick={() => setRuleAction('drop')}
                />
              </div>
              <div className="type-content-row">
                <CustomRadio
                  checked={ruleAction === 'forward'}
                  label="Forward to stateful rule groups"
                  description="Discontinue stateless inspection of packet and forward it to the stateful rule engine for inspection."
                  onClick={() => setRuleAction('forward')}
                />
              </div>
            </div>
          </div>
        </>
      </div>
      <div className="rule-group-container">
        <div className="title-group">
          <p className="title">TCP flags-optional</p>
          <p className="sub-title">
            The TCP flags and masks to inspect for. If not specified, this matches with any settings. This setting is
            only used for TCP protocol.
          </p>
        </div>
        <div className="rule-group-details-container" style={{ flex: 1, marginTop: 12 }}>
          <>
            <div
              className="rule-group-detail-title"
              style={{
                borderBottom: 'none',
                padding: '8px 8px 0px 16px',
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
              }}
            >
              Mask-optional
              <div className="rule-group-detail-sub-title">
                The set of flags to consider in the inspection. To inspect all available flags, don’t select an option.
              </div>
            </div>
            <div className="rule-group-detail-content" style={{ gap: 16, padding: '8px 16px' }}>
              <div style={{ width: '50%' }}>
                <CusMultiSelectAutocomplete
                  placeholder="Choose options"
                  data={listMasksFlags}
                  selectedValues={statelessMasks}
                  handleClick={(val: DropdownListDataType) => handleSelectMask(val)}
                  handleRemove={(val: DropdownListDataType) => handleRemoveSelectedMask(val)}
                />
              </div>
            </div>
          </>
          <>
            <div
              className="rule-group-detail-title"
              style={{
                borderBottom: 'none',
                padding: '8px 8px 0px 16px',
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
              }}
            >
              Flags-optional
              <div className="rule-group-detail-sub-title">
                Used in conjunction with the Masks setting to define the flags that must be set and flags that must not
                be set in order for the packet to match. This setting can only specify values that are also specified in
                the Masks setting.
              </div>
            </div>
            <div className="rule-group-detail-content" style={{ gap: 16, padding: '8px 16px' }}>
              <div style={{ width: '50%' }}>
                <CusMultiSelectAutocomplete
                  placeholder="Choose options"
                  data={listMasksFlags}
                  selectedValues={statelessFlag}
                  handleClick={val => handleSelectFlags(val)}
                  handleRemove={val => handleRemoveSelectedFlags(val)}
                />
              </div>
            </div>
          </>
          <div className="rule-group-detail-content" style={{ padding: '8px 16px' }}>
            <button className="button next-button" style={{ margin: 0 }} onClick={() => handleAddRuleDomainList()}>
              Add rule
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stateless;
