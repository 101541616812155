import React, {useEffect, useState} from 'react';
import {variableCombineNextToken} from "pages/v2/Organ/Management/Utils";
import PageDetailTitle from "pages/v2/Organ/Management/EC2/components/PageDetailTitle";
import {networkInterfaceTab} from "pages/v2/Organ/Management/EC2/components/NetworkInterface/Common/Constant";
import lazyGetAwsNetworkInterfaces from "graphql/queries/getAwsNetworkInterfaces";
import Details from "pages/v2/Organ/Management/EC2/components/NetworkInterface/Common/Components/Details";
import Tags from "pages/v2/Organ/Management/EC2/components/NetworkInterface/Common/Components/Tags";

import './index.scss';

function NetworkInterfaceDetail({cloudId, region, rootAccountId, pageDetail, pageBackClick}: any) {
  const [isLoading, setIsLoading] = useState(false);

  const networkInterfaceTabDetail = [...networkInterfaceTab];
  networkInterfaceTabDetail.shift();
  const [data, setData] = useState<any>();
  // @ts-ignore
  const [tabSelected, setTabSelected] = useState(networkInterfaceTabDetail[0]);

  const [getAwsNetworkInterfaces] = lazyGetAwsNetworkInterfaces();

  const handleGetAwsNetworkInterfaces = async (networkInterfaceIds : string) => {
    if (!networkInterfaceIds) return {};

    const payload = {
      cloudId,
      region,
      request: {
        networkInterfaceIds
      },
    };

    return getAwsNetworkInterfaces({
      variables: variableCombineNextToken(payload),
    })
  }

  const handleGetDetail = async () => {
    try {
      setIsLoading(true)
      const res: any = await handleGetAwsNetworkInterfaces(pageDetail?.networkInterfaceId)

      const data = {
        ...res?.data?.getAwsNetworkInterfaces?.data?.[0]?.networkInterfaces?.[0],
      }

      setData({...data, rootAccountId})
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setTabSelected(networkInterfaceTabDetail[0])

    if (!pageDetail?.id) return;

    handleGetDetail()
  }, [pageDetail?.id]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case networkInterfaceTabDetail[0].id:
        return <Tags data={data} />

      default:
        return null
    }
  }

  return (
    <div className='ec2-detail'>
      <PageDetailTitle
        title={pageDetail?.networkInterfaceId}
        pageBackClick={pageBackClick}
        isDelete
        onDelete={() => {
        }}
      />

      {
        !data && isLoading ? (
          <div className="detail-info">
            <div className="progress-container">
              <div className="progress-gif" />
              Loading ...
            </div>
          </div>
        ) : (
          <>
            <Details data={data} />

            <div className='ec2-detail-tab'>
              <div className="detail-tab flex a-center">
                {networkInterfaceTabDetail.map(tab => {
                  return (
                    <button
                      className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
                      key={tab.id}
                      data-tab={tab.id}
                      onClick={e => setTabSelected(tab)}
                    >
                      <p>{tab.name}</p>
                    </button>
                  );
                })}
              </div>

              <div className="content-tab">
                {renderTab()}
              </div>
            </div>
          </>
        )
      }
    </div>
  );
}

export default NetworkInterfaceDetail;