import { ErrorCode } from '@Types/error';
import deleteAwsAllowListMutation from 'graphql/mutations/deleteAwsAllowList';
import { useToast } from 'hooks/v2/useToast';
import Button from 'pages/v2/Organ/Management/components/Button';
import { useState } from 'react';
import DeleteAllowList from './components/DeleteAllowList';
import './index.scss';
import ManageTags from './components/ManageTags';

interface ISettingAllowListProps {
  cloudId: number;
  region: string;
  allowListId: string | null;
  onNavigateCreation: () => void;
  onNavigateUpdation: () => void;
}

const SettingAllowList = ({
  cloudId,
  region,
  allowListId,
  onNavigateCreation,
  onNavigateUpdation,
}: ISettingAllowListProps) => {
  const [showDelete, setShowDelete] = useState(false);
  const [deleteAllowList] = deleteAwsAllowListMutation();
  const handleDelete = async () => {
    if (!allowListId) return;
    const { errors } = await deleteAllowList({ variables: { cloudId, region, request: { id: allowListId } } });
    if (!errors) {
      useToast(ErrorCode.SUCCESS, 'Create allow list successful.');
    } else {
      useToast(ErrorCode.UNKNOWN, 'Create allow list failed.');
    }
    setShowDelete(false);
  };
  const [showUpdate, setShowUpdate] = useState(false);

  return (
    <div className="row-3 flex j-between a-center">
      <div className="title">
        <p>Listup allow list</p>
        <Button label="Create Allow List" onClick={onNavigateCreation} />
        <Button label="Edit" onClick={onNavigateUpdation} />
        <Button label="Delete" onClick={() => setShowDelete(true)} />
        <Button label="Manage tags" onClick={() => setShowUpdate(true)} />
      </div>
      <DeleteAllowList
        header={'Delete allow list'}
        titleWarning={
          "You configured one or more sensitive data discovery jobs to use this list. If you delete the list, Macie won't ignore text that matches the list's criteria when those jobs run. Also, the list can't be recovered after you delete it."
        }
        onDelete={handleDelete}
        open={showDelete}
        onClose={() => setShowDelete(false)}
      />
      <ManageTags
        cloudId={cloudId}
        region={region}
        resourceArn={'arn:aws:macie2:ap-northeast-2:767398087120:allow-list/8u1o8rfelsw18j23pvfghy'}
        header={'Delete allow list'}
        allowListName="allow list"
        tagsList={{}}
        description="You configured one or more sensitive data discovery job"
        open={showUpdate}
        onSave={() => {}}
        onClose={() => setShowUpdate(false)}
      />
    </div>
  );
};

export default SettingAllowList;
