import { gql } from "@apollo/client";

const query = gql`
query getAwsListCoverage($reqData: AwsListCoverageRequest, $cloudId: Long, $region: String) {
  getAwsListCoverage(awsListCoverageRequest: $reqData, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsListCoverageResponse {
        resources {
          accountId
          coverageStatus
          detectorId
          issue
          resourceDetails {
            ec2InstanceDetails {
              agentDetails {
                version
              }
              clusterArn
              instanceId
              instanceType
              managementType
            }
            ecsClusterDetails {
              clusterName
              containerInstanceDetails {
                compatibleContainerInstances
                coveredContainerInstances
              }
              fargateDetails {
                issues
                managementType
              }
            }
            eksClusterDetails {
              addonDetails {
                addonStatus
                addonVersion
              }
              clusterName
              compatibleNodes
              coveredNodes
              managementType
            }
            resourceType
          }
          resourceId
          updatedAt
        }
      }
    }
  }
}
`

export default query;