import { useCallback, useEffect, useState } from 'react';
import { AwsDescribeSecretType } from 'graphql/types/AwsDescribeSecret';
import { DETAIL_KEYS, DETAIL_TEXT_DES } from './../configs';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import lazyGetAwsListAliases from 'graphql/queries/getAwsListAliases';
import DetailTab from '../../../../components/DetailTab';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { DropdownListDataType } from 'components/Dropdown/types';
import EditDescriptionModal from '../Components/EditDescriptionModal';
import updateAwsSecretMutation, { IUpdateAwsSecretVariables } from 'graphql/mutations/updateAwsSecret';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import DeleteSecretModal from '../Components/DeleteSecret';
import EditEncryptionModal from '../Components/EditEncryptionModal';
import deleteAwsSecretMutation, { IDeleteAwsSecretVariables } from 'graphql/mutations/deleteAwsSecret';

const actionsDropdown: DropdownListDataType[] = [
  { id: 1, name: 'Edit description', value: 'Edit description' },
  { id: 2, name: 'Edit encryption', value: 'Edit encryption' },
  { id: 3, name: 'Delete secrets', value: 'Delete secrets' },
];

const SecretDetails = (props: any) => {
  const { cloudId, region, describeSecret, onReloadData } = props;

  const [getAwsListAliases, { loading: listAliasLoading }] = lazyGetAwsListAliases();
  const [updateAwsSecret, { loading: updateAwsSecretLoading }] = updateAwsSecretMutation();
  const [deleteAwsSecret, { loading: deleteAwsSecretLoading }] = deleteAwsSecretMutation();

  const [detailsOrderedData, setDetailsOrderedData] = useState<IMgdDetailKeyValueProps[]>([]);
  const [isOpenEditDescription, setIsOpenEditDescription] = useState(false);
  const [isOpenEditEncryption, setIsOpenEditEncryption] = useState(false);
  const [isOpenDeleteSecrets, setIsOpenDeleteSecrets] = useState(false);

  const getDetailContent = async (describeSecret: AwsDescribeSecretType) => {
    const detailsData: IMgdDetailKeyValueProps[] = [];
    const orderedDetailsData: IMgdDetailKeyValueProps[] = [];
    for (const [key, value] of Object.entries(describeSecret)) {
      if (DETAIL_KEYS.indexOf(key) > -1) {
        let desValue = '';
        if (key === DETAIL_KEYS[0]) {
          if (value) {
            const awsAliasesVariable = {
              cloudId: cloudId,
              region: region,
              request: { keyId: value as string },
            };
            const aliasName = await getAwsListAliases({ variables: awsAliasesVariable }).then(({ data: alias }) => {
              return alias?.getAwsListAliases?.data?.[0]?.aliases?.[0]?.aliasName;
            });
            desValue = aliasName || '-';
          } else {
            desValue = 'aws/secretsmanager';
          }
        }
        const textDes = DETAIL_TEXT_DES.find(text => text.id == key);
        if (typeof value === 'string') {
          desValue = value.toString();
        }

        if (textDes) {
          detailsData.push({
            id: key,
            type: textDes.type as TextTypeEnum,
            title: textDes.value,
            description: desValue,
          });
        }
      }
    }
    DETAIL_KEYS.map(key => {
      const dataByKey = detailsData.find(data => data.id == key);
      if (dataByKey) orderedDetailsData.push(dataByKey);
    });
    setDetailsOrderedData(orderedDetailsData);
  };

  useEffect(() => {
    if (!describeSecret) return;

    getDetailContent(describeSecret);
  }, [describeSecret]);

  const handleClickActions = (values: DropdownListDataType) => {
    switch (values?.value) {
      case actionsDropdown[0].value:
        setIsOpenEditDescription(true);
        return;

      case actionsDropdown[1].value:
        setIsOpenEditEncryption(true);
        return;

      case actionsDropdown[2].value:
        setIsOpenDeleteSecrets(true);
        return;

      default:
        return;
    }
  };

  const handleEditDescription = async (description: string) => {
    if (updateAwsSecretLoading) return;

    try {
      const variables: IUpdateAwsSecretVariables = {
        cloudId,
        region,
        reqData: {
          secretId: describeSecret?.arn,
          description,
        },
      };
      await updateAwsSecret({ variables });
      onReloadData();
      setIsOpenEditDescription(false);
      useToast(ErrorCode.SUCCESS, 'Edit description successful.');
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Edit description failed.');
      setIsOpenEditDescription(false);
    }
  };

  const handleEditEncryption = async (kmsKeyId: string) => {
    if (updateAwsSecretLoading) return;

    try {
      const variables: IUpdateAwsSecretVariables = {
        cloudId,
        region,
        reqData: {
          secretId: describeSecret?.arn,
          kmsKeyId,
        },
      };
      await updateAwsSecret({ variables });
      onReloadData();
      setIsOpenEditDescription(false);
      useToast(ErrorCode.SUCCESS, 'Edit encryption successful.');
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Edit encryption failed.');
      setIsOpenEditDescription(false);
    }
  };

  const handleDeleteSecret = async (days: string) => {
    if (deleteAwsSecretLoading) return;

    try {
      const variables: IDeleteAwsSecretVariables = {
        cloudId,
        region,
        reqData: {
          secretId: describeSecret?.arn,
          recoveryWindowInDays: parseInt(days),
        },
      };
      await deleteAwsSecret({ variables });
      onReloadData();
      setIsOpenDeleteSecrets(false);
      useToast(ErrorCode.SUCCESS, 'Delete secret successful.');
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Delete secret failed.');
      setIsOpenDeleteSecrets(false);
    }
  };

  const renderAction = () => {
    return (
      <DropdownAtom
        id="actions-dropdown"
        className=""
        data={actionsDropdown}
        value={{ name: 'Actions', value: 'Actions' }}
        handleClick={handleClickActions}
      />
    );
  };

  return (
    <>
      <DetailTab
        title={'Secret details'}
        data={detailsOrderedData}
        isApiLoading={listAliasLoading}
        actions={renderAction()}
      />

      {isOpenEditDescription ? (
        <EditDescriptionModal
          open={isOpenEditDescription}
          onClose={() => setIsOpenEditDescription(false)}
          data={detailsOrderedData?.[1]?.description}
          onSubmit={handleEditDescription}
        />
      ) : null}

      {isOpenEditEncryption ? (
        <EditEncryptionModal
          open={isOpenEditEncryption}
          onClose={() => setIsOpenEditEncryption(false)}
          cloudId={cloudId}
          region={region}
          data={detailsOrderedData?.[1]?.description}
          onSubmit={handleEditEncryption}
        />
      ) : null}

      {isOpenDeleteSecrets ? (
        <DeleteSecretModal
          open={isOpenDeleteSecrets}
          onClose={() => setIsOpenDeleteSecrets(false)}
          data={detailsOrderedData?.[1]?.description}
          onSubmit={handleDeleteSecret}
        />
      ) : null}
    </>
  );
};

export default SecretDetails;
