import ArrowIcon from 'assets/svgs/v2/ico_dropdown_arrow.svg';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import React, { Fragment, MouseEvent, useCallback, useEffect, useState } from 'react';
import { randomString } from 'utils/Common';
import { DropdownListDataType } from '../DropdownAtom';
import './index.scss';
interface AutoCompleteDropdownProps {
  data: DropdownListDataType[];
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  icon?: string;
  value?: string;
  handleClick: (val: DropdownListDataType) => void;
  handleChange: (val: string) => void;
  handleAddClass: (val: boolean) => void;
}

const AutoCompleteDropdownAtom: React.FC<AutoCompleteDropdownProps> = ({
  className,
  data,
  placeholder,
  icon,
  error,
  handleClick,
  handleChange,
  handleAddClass,
  value,
}) => {
  const [inputValue, setInputValue] = useState<string>(value ?? '');
  const [filteredData, setFilteredData] = useState<DropdownListDataType[]>(data);
  const [showData, setShowData] = useState<boolean>(false);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    handleChange(value);
    handleAddClass(false);
    if (value) {
      const filtered = data.filter(suggestion => String(suggestion.value).toLowerCase().includes(value.toLowerCase()));
      setFilteredData(filtered);
      handleAddClass(filtered.length > 0);
      setShowData(filtered.length > 0);
    } else {
      setShowData(false);
    }
  };

  const handleOnFocus = useCallback(() => {
    setShowData(true);
    if (value === undefined) {
      setFilteredData(data)
    }
  }, [handleInputChange, data]);

  useEffect(() => {
    setInputValue( value ?? '');
  }, [value]);

  return (
    <div className="auto-completed-dropdown-atom">
      <div className={'input-region ' + className}>
        <input type="text" value={inputValue} onFocus={handleOnFocus} onChange={handleInputChange} placeholder={placeholder}/>

        <img src={!!icon ? icon : ArrowIcon} width={24} height={24} />
      </div>
      {error && (
        <div className="error-container">
          <img src={InputErrorIcon} width="16" height="16" />
          <p className="error">{error}</p>
        </div>
      )}
      <div className="dropdown-list">
        {showData && (
          <ul className="no-scrollbar">
            {filteredData.map((data, index) => (
              <Fragment key={`dropdown-list-${index}`}>
                <li
                  className={`${data.children ? 'parent' : ''}`}
                  key={data?.id}
                  onClick={() => {
                    if (data?.children) return;

                    handleClick(data);
                    setInputValue(data?.value as string);
                    setShowData(false);
                  }}
                >
                  {data.value}
                  {data.description && (
                    <>
                      {<pre className="text-description">{data.description}</pre>}
                    </>
                  )}
                </li>

                {data?.children?.length
                  ? data.children.map(i => (
                    <li
                      key={randomString()}
                      className={`children ${i?.disable ? 'disable' : ''} ${data?.isBetween ? 'flex j-between' : ''}`}
                      onClick={e => {
                        e.stopPropagation();
                        handleClick(i);
                      }}
                    >
                      {i.name}
                      {i.description && <p className="text-description">{i.description}</p>}
                    </li>
                  ))
                  : null}
              </Fragment>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
export default AutoCompleteDropdownAtom;
