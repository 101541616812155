import { DropdownListDataType } from 'components/Dropdown/types';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';

export const LINK = {
  NETWORK: '/organ/1/manage/network',
  FIREWALL: '/organ/1/manage/firewall',
  KEY_SECRET: '/organ/1/manage/key-secret',
  INSTANCE: '/organ/1/manage/instance',
  IAM: '/organ/1/manage/iam',
  WAF: '/organ/1/manage/waf',
  GUARD_DUTY: '/organ/1/manage/guard-duty',
  MACIE: '/organ/1/manage/macie',
};

export const EC2_TAB_LIST: Array<IMgdTabProps> = [
  { id: 'instance', name: 'Instance', title: 'Instance' },
  { id: 'spotRequest', name: 'Spot request', title: 'Spot request' },
  { id: 'image', name: 'Image', title: 'AWS Machine Images' },
  { id: 'ebsVolumn', name: 'EBS Volumn', title: 'EBS Volumn' },
  { id: 'ebsSnapshot', name: 'EBS snapshot', title: 'Elastic Block store volumes' },
  { id: 'keyPair', name: 'Key pair', title: 'Key pair' },
  { id: 'networkInterface', name: 'Network interface', title: 'Network interface' },
  { id: 'loadBalancer', name: 'Load balancer', title: 'Load balancer' },
  { id: 'lbTargetGroup', name: 'LB target group', title: 'LB target group' },
];

export const INSTANCE_SEARCH_TYPES: DropdownListDataType[] = [
  { id: 1, name: 'Instance ID', value: 'instance-id' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'Instance state', value: 'instance-state-name' },
  { id: 4, name: 'Instance type', value: 'instance-type' },
  { id: 5, name: 'Availability Zone', value: 'availability-zone' },
  { id: 6, name: 'Public IPv4 DNS', value: 'dns-name' },
  { id: 7, name: 'VPC ID', value: 'vpc-id' },
  { id: 8, name: 'Subnet IDs', value: 'subnet-id' },
  { id: 9, name: 'Image ID', value: 'image-id' },
  { id: 10, name: 'Volume ID', value: 'block-device-mapping.volume-id' },
];

export const VPC_FILTER: DropdownListDataType[] = [
  { id: 1, name: 'VPC ID', value: 'vpc-id' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'IPv4 CIDR', value: 'cidr-block-association.cidr-block' },
  { id: 4, name: 'IPv6 CIDR', value: 'ipv6-cidr-block-association.ipv6-cidr-block' },
];

export const ROUTE_TABLE_FILTER: DropdownListDataType[] = [
  { id: 1, name: 'Route table ID', value: 'route-table-id' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'VPC', value: 'vpc-id' },
];

export const SUBNET_FILTER: DropdownListDataType[] = [
  { id: 1, name: 'Subnet ID', value: 'subnet-id' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'VPC ID', value: 'vpc-id' },
];

export const NETWORK_ACL_FILTER: DropdownListDataType[] = [
  { id: 1, name: 'Network ACL ID', value: 'network-acl-id' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'VPC ID', value: 'vpc-id' },
];

export const ELASTIC_IP_FILTER: DropdownListDataType[] = [
  { id: 1, name: 'Allocated IPv4 addresss', value: 'public-ip' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'Allocation ID', value: 'allocation-id' },
];

export const EBS_VOLUME_FILTER: DropdownListDataType[] = [
  {id: 1, name: 'Volume ID', value: 'volume-id'},
  {id: 2, name: 'Name', value: 'tag:Name'},
  {id: 3, name: 'Volume state', value: 'status'},
  {id: 4, name: 'Type', value: 'volume-type'},
]
