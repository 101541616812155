import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconEditDetail from 'assets/svgs/v3/ico_edit.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import InputAtom from 'components/v2/atoms/InputAtom';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import { descriptionRegex } from 'utils/Regex';
import { descriptionRequired } from '../../config';

interface IEditFirewallPolicyDetailProps extends IBaseModalProps {
  header: string;
  description: string;
  groupData: any;
  handleUpdateGroupDetail: (desc: string) => Promise<boolean>;
  onClose: () => void;
  loading: boolean;
}

const EditResourceGroupDetails = ({
  header,
  description,
  onClose,
  loading,
  groupData,
  handleUpdateGroupDetail,
  ...baseModalProps
}: IEditFirewallPolicyDetailProps) => {
  const [nameGroup, setNameGroup] = useState<string>('');
  const [descriptionValue, setDescriptionValue] = useState<string>('');
  const [errorDesc, setErrorDesc] = useState<string>('');

  const onSavePolicy = async () => {
    const result = errorDesc === '' ? await handleUpdateGroupDetail(descriptionValue) : null;
    result ? onClose() : null;
  };

  useEffect(() => {
    if (baseModalProps.open) {
      setNameGroup(groupData?.name);
      setDescriptionValue(groupData?.description);
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditDetail} />
          {header}
        </>
      )}
      onClose={onClose}
      {...baseModalProps}
    >
      <div className="firewall-policy-details-modal">
        <p className="sup-title">Edit resource group.</p>
        <div className={`firewall-policy-details ${errorDesc === '' ? 'pb-20' : 'pb-28'}`}>
          <div className="row-1">
            <div>
              <p className="title-content">Resource group details</p>
            </div>
            <div className="mt-16">
              <p className="text-title">Name</p>
              <InputAtom
                placeholder={'Enter resource group name'}
                disabled
                onChangeValue={val => {
                  setNameGroup(val);
                }}
                value={nameGroup}
                required={true}
                noClear={false}
              />
            </div>
            <div className="my-16">
              <p className="text-title">Description</p>
              <InputAtom
                placeholder={'Enter resource group description'}
                onChangeValue={val => {
                  setDescriptionValue(val);
                  (!descriptionRegex.test(val) && !!val || val.length > 512) ?
                    setErrorDesc(
                      descriptionRequired,
                    ) : setErrorDesc('');
                }}
                bottomDescription={descriptionRequired}
                value={descriptionValue}
                noClear={false}
                error={errorDesc}
              />
            </div>
          </div>
        </div>
        <div className="button-group">
          <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={onClose} />
          <Button label="Save" type={ButtonTypeEnum.PRIMARY} loading={loading} onClick={onSavePolicy} />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditResourceGroupDetails;
