import { Key, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import lazyGetAwsDescribeDbInstances, {
  IAwsDescribeDbInstancesVariable,
} from 'graphql/queries/getAwsDescribeDbInstances';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import './index.scss';
import TableRadio from 'components/v2/dataDisplay/TableRadio';
import lazyGetAwsDescribeDbClusters, { IAwsDescribeDbClustersVariable } from 'graphql/queries/getAwsDescribeDbClusters';
import lazyGetAwsDescribeClusters, { IAwsDescribeClustersVariable } from 'graphql/queries/getAwsDescribeClusters';
import lazyGetAwsListWorkgroups, { IAwsListWorkgroupsVariable } from 'graphql/queries/getAwsListWorkgroups';
import { AwsWorkGroupType } from 'graphql/types/AwsListWorkgroups';
import lazyGetAwsNamespace, { IAwsGetNamespaceVariable } from 'graphql/queries/getAwsNamespace';
import { AwsDbClusterType } from 'graphql/types/AwsDescribeDbClusters';
import { AwsClusterType } from 'graphql/types/AwsDescribeClusters';
import SectionTitle from 'pages/v2/Organ/Management/components/SectionTitle';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import SectionContainer from 'pages/v2/Organ/Management/components/SectionContainer';
import { AwsDbInstanceType } from 'graphql/types/AwsDescribeDbIntaces';
import LabelInput from 'components/v2/LabelInput';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import moment from 'moment';
import { DATABASE_IMGS } from '../../configs';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';

const DatabasePanel = (props: any) => {
  const { secretType, creationData, handleValueChanged, errors, cloudId, region } = props;
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [tableSelected, setTableSelected] = useState<string>(creationData?.rds?.idTable);
  const [dataImgSelected, setDataimgSelected] = useState<string>(creationData?.otherDB?.idTable || 'mariadb');

  const formatStringWhenEmpty = (val: string) => {
    return !val || val === '' ? '-' : val;
  };
  const getFormatedDateAt = (dateString: string) => {
    //Coordinated Universal Time
    return moment(dateString)
      .utc()
      .format('MMMM DD, YYYY [at] HH:mm:ss');
  };
  //Database of ‘Credentials for Amazon RDS database’secret type
  const COLUMNS_RDS: ColumnType[] = useMemo(() => {
    return [
      {
        label: 'DB instance',
        field: 'dbInstanceIdentifier',
        renderCell: (row: RowType) => <>{formatStringWhenEmpty(row.dbInstanceIdentifier)}</>,
        sort: true,
      },
      {
        label: 'DB engine',
        field: 'engine',
        renderCell: (row: RowType) => <>{formatStringWhenEmpty(row.engine)}</>,
        sort: true,
      },
      {
        label: 'Status',
        field: 'dbInstanceStatus',
        renderCell: (row: RowType) => <>{formatStringWhenEmpty(row.dbInstanceStatus)}</>,
        sort: true,
      },
      {
        label: 'Creation date (UTC)',
        field: 'instanceCreateTime',
        renderCell: (row: RowType) => <>{getFormatedDateAt(row.instanceCreateTime)}</>,
        sort: true,
      },
    ];
  }, [secretType, mainTblRows]);

  //Database of ‘Credentials for Amazon DocumentDB’ secret type
  const COLUMNS_DDB: ColumnType[] = useMemo(() => {
    return [
      {
        label: 'DB cluster',
        field: 'dbClusterIdentifier',
        renderCell: (row: RowType) => <>{formatStringWhenEmpty(row.dbClusterIdentifier)}</>,
        sort: true,
      },
      {
        label: 'Status',
        field: 'status',
        renderCell: (row: RowType) => <>{formatStringWhenEmpty(row.status)}</>,
        sort: true,
      },
      {
        label: 'Creation date (UTC)',
        field: 'clusterCreateTime',
        renderCell: (row: RowType) => <>{getFormatedDateAt(row.clusterCreateTime)}</>,
        sort: true,
      },
    ];
  }, [secretType, mainTblRows]);

  //Data warehouse of ‘Credentials for Amazon Redshift data warehouse’ secret type
  const COLUMNS_WH: ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Data warehouse ID',
        field: 'namespaceName',
        renderCell: (row: RowType) => <>{formatStringWhenEmpty(row.namespaceName)}</>,
        sort: true,
      },
      {
        label: 'Serverless',
        field: 'serverless',
        sort: true,
      },
      {
        label: 'Status',
        field: 'status',
        renderCell: (row: RowType) => <>{formatStringWhenEmpty(row.status)}</>,
        sort: true,
      },
      {
        label: 'Creation date (UTC)',
        field: 'creationDate',
        renderCell: (row: RowType) => <>{getFormatedDateAt(row.creationDate)}</>,
        sort: true,
      },
    ];
  }, [secretType, mainTblRows]);

  const handleChangeDatabaseColumn = useCallback(() => {
    switch (secretType) {
      case 'rds': {
        return COLUMNS_RDS;
      }
      case 'ddb': {
        return COLUMNS_DDB;
      }
      case 'wh': {
        return COLUMNS_WH;
      }
      default: {
        return COLUMNS_RDS;
      }
    }
  }, [secretType, mainTblRows]);

  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [getAwsDescribeDbInstances, { loading: describeDbInstancesLoading }] = lazyGetAwsDescribeDbInstances();
  const [getAwsDescribeDbClusters, { loading: describeDbClustersLoading }] = lazyGetAwsDescribeDbClusters();
  const [getAwsDescribeClusters, { loading: describeClustersLoading }] = lazyGetAwsDescribeClusters();
  const [getAwsListWorkgroups, { loading: listWorkgroupsLoading }] = lazyGetAwsListWorkgroups();
  const [getAwsNamespace] = lazyGetAwsNamespace();

  const isApiLoading = useMemo((): boolean => {
    return describeDbInstancesLoading || describeDbClustersLoading || describeClustersLoading || listWorkgroupsLoading;
  }, [describeDbInstancesLoading, describeDbClustersLoading, describeClustersLoading, listWorkgroupsLoading]);

  const handleGetAwsListsDescribeDbInstances = useCallback(() => {
    const requestVariable: IAwsDescribeDbInstancesVariable = {
      cloudId: cloudId,
      region: region,
      reqData: {
        marker: '',
        dbInstanceIdentifier: '',
        filters: [],
      },
    };
    const combinedVariable = variableCombineNextToken(requestVariable, '');
    
    return getAwsDescribeDbInstances({ variables: combinedVariable });
  }, [cloudId, region]);

  const handleGetAwsDescribeClusters = useCallback(() => {
    const requestVariable: IAwsDescribeClustersVariable = {
      cloudId: cloudId,
      region: region,
      reqData: {
        clusterIdentifier: '',
        marker: '',
        tagKeys: [],
        tagValues: [],
      },
    };
    const combinedVariable = variableCombineNextToken(requestVariable, '');
    
    return getAwsDescribeClusters({ variables: combinedVariable });
  }, [cloudId, region]);

  const handleGetAwsDescribeDbClusters = useCallback(() => {
    const requestVariable: IAwsDescribeDbClustersVariable = {
      cloudId: cloudId,
      region: region,
      reqData: {
        dbClusterIdentifier: '',
        filters: [],
        marker: '',
      },
    };
    const combinedVariable = variableCombineNextToken(requestVariable, '');
    
    return getAwsDescribeDbClusters({ variables: combinedVariable });
  }, [cloudId, region]);

  const handleGetAwsListWorkgroups = useCallback(() => {
    const requestVariable: IAwsListWorkgroupsVariable = {
      cloudId: cloudId,
      region: region,
      reqData: {
      },
    };
    
    return getAwsListWorkgroups({ variables: requestVariable });
  }, [cloudId, region]);

  const handleGetNamespace = useCallback(
    (nameValue: string) => {
      const requestVariable: IAwsGetNamespaceVariable = {
        cloudId: cloudId,
        region: region,
        reqData: {
          namespaceName: nameValue,
        },
      };
      
      return getAwsNamespace({ variables: requestVariable });
    },
    [cloudId, region],
  );

  //Database of ‘Credentials for Amazon RDS database’secret type
  const fetchRdsData = useCallback(async () => {
    try {
      const result = await handleGetAwsListsDescribeDbInstances();
      const data = result?.data?.getAwsDescribeDbInstances?.data;
      if (!data?.length) {
        return;
      }
      let totalResult: RowType[] = [];
      totalResult = data[0].dBInstances.map((dbInstance: AwsDbInstanceType, index: Key) => ({
        id: index,
        ...dbInstance,
      }));
      setMainTblRows(totalResult);
      setMainTblTotal({
        totalPage: Math.ceil(totalResult.length / tablePagination.itemPerPage),
        totalElement: totalResult.length,
      });
    } catch (error) { /* empty */ }
  }, [tablePagination, cloudId, region, mainTblRows]);

  //Database of ‘Credentials for Amazon DocumentDB’ secret type
  const fetchDdbData = useCallback(async () => {
    try {
      const result = await handleGetAwsDescribeDbClusters();
      const data = result?.data?.getAwsDescribeDbClusters?.data;
      if (!data?.length) {
        return;
      }
      let totalResult: RowType[] = [];
      totalResult = data[0].dBClusters.map((dBCluster: AwsDbClusterType, index: Key) => ({
        id: index,
        ...dBCluster,
      }));
      setMainTblRows(totalResult);
      setMainTblTotal({
        totalPage: Math.ceil(totalResult.length / tablePagination.itemPerPage),
        totalElement: totalResult.length,
      });
    } catch (error) { /* empty */ }
  }, [tablePagination, cloudId, region, mainTblRows]);

  //Data warehouse of ‘Credentials for Amazon Redshift data warehouse’ secret type
  const fetchWhData = useCallback(async () => {
    try {
      const totalResult: RowType[] = [];
      const resultDescClusters = await handleGetAwsDescribeClusters();
      const resultListWorkGroup = await handleGetAwsListWorkgroups();
      const dataDescClusters = resultDescClusters?.data?.getAwsDescribeClusters;
      const dataListWorkGroup = resultListWorkGroup?.data?.getAwsListWorkgroups?.data;
      if (dataDescClusters?.data.length === 1) {
        const clusters = dataDescClusters?.data[0].clusters;
        totalResult.push(
          ...clusters.map((dataCluster: AwsClusterType, index: Key) => ({
            namespaceName: dataCluster.clusterIdentifier,
            status: dataCluster.clusterAvailabilityStatus || dataCluster.clusterStatus,
            creationDate: dataCluster.clusterCreateTime,
            serverless: 'No',
            id: index,
            endpoint: {
              address: dataCluster.endpoint?.address,
              port: dataCluster.endpoint?.port,
            },
          })),
        );
      }
      if (dataListWorkGroup?.length) {
        const getServerless = await Promise.all(
          dataListWorkGroup[0].workgroups.map(async (dataWork: AwsWorkGroupType, index: Key) => {
            const dataSeverless: any = {};
            dataSeverless.namespaceName = dataWork.namespaceName;
            dataSeverless.status = dataWork.status;
            dataSeverless.creationDate = dataWork.creationDate;
            dataSeverless.serverless = 'Yes';
            dataSeverless.id = totalResult.length + 1;
            dataSeverless.endpoint = {
              address: dataWork.endpoint?.address,
              port: dataWork.endpoint?.port,
            };
            try {
              const detailNameSpaceResult = await handleGetNamespace(dataWork.namespaceName);
              const detailNameSpace = detailNameSpaceResult?.data?.getAwsNamespace?.data;
              if (detailNameSpace?.length) {
                dataSeverless.dbName = detailNameSpace[0].namespace.dbName;
              }
              
              return dataSeverless;
            } catch (error) { /* empty */ }
          }),
        );
        totalResult.push(...getServerless);
      }
      setMainTblRows(totalResult);
      setMainTblTotal({
        totalPage: Math.ceil(totalResult.length / tablePagination.itemPerPage),
        totalElement: totalResult.length,
      });
    } catch (error) { /* empty */ }
  }, [tablePagination, cloudId, region, mainTblRows]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleGetDatabase = useCallback(() => {
    setMainTblRows([]);
    setMainTblTotal({
      totalPage: 0,
      totalElement: 0,
    });
    switch (secretType) {
      case 'rds': {
        fetchRdsData();
        break;
      }
      case 'ddb': {
        fetchDdbData();
        break;
      }
      case 'wh': {
        fetchWhData();
        break;
      }
      default: {
        fetchRdsData();
        break;
      }
    }
  }, [secretType]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;
    
    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotal, tablePagination, mainTblRows]);

  const getTitlePanel = useCallback(() => {
    if (secretType !== 'wh') {
      return 'Database';
    }
    
    return 'Data warehouse';
  }, [secretType]);

  useEffect(() => {
    handleGetDatabase();
  }, [secretType]);

  const setDataWithSelectedId = useCallback(() => {
    switch (secretType) {
      case 'rds': {
        const dataFind = mainTblRows.find((data: RowType) => data.id?.toString() === tableSelected);
        if (dataFind) {
          handleValueChanged('rds', {
            engine: dataFind['engine'],
            host: dataFind['endpoint']['address'],
            port: dataFind['endpoint']['port'],
            dbname: dataFind['dbName'],
            dbInstanceIdentifier: dataFind['dbInstanceIdentifier'],
            idTable: tableSelected,
          });
        }
        break;
      }
      case 'ddb': {
        const dataFind = mainTblRows.find((data: RowType) => data.id?.toString() === tableSelected);
        if (dataFind) {
          handleValueChanged('ddb', {
            engine: 'mongo',
            host: dataFind['endpoint']['address'],
            port: dataFind['endpoint']['port'],
            ssl: true,
            dbInstanceIdentifier: dataFind['dbInstanceIdentifier'],
            idTable: tableSelected,
          });
        }
        break;
      }
      case 'wh': {
        const dataFind = mainTblRows.find((data: RowType) => data.id?.toString() === tableSelected);
        if (dataFind) {
          handleValueChanged(
            'wh',
            dataFind.serverless === 'Yes'
              ? {
                engine: 'redshift',
                host: dataFind['endpoint']['address'],
                port: dataFind['endpoint']['port'],
                dbName: dataFind['dbName'],
                namespaceName: dataFind['namespaceName'],
                idTable: tableSelected,
              }
              : {
                engine: 'redshift',
                host: dataFind['endpoint']['address'],
                port: dataFind['endpoint']['port'],
                dbClusterIdentifier: dataFind['namespaceName'],
                idTable: tableSelected,
              },
          );
        }
        break;
      }
      case 'otherDB': {
        break;
      }
    }
  }, [secretType, tableSelected]);

  useEffect(() => {
    setDataWithSelectedId();
  }, [tableSelected]);

  return secretType !== 'otherDB' ? (
    <>
      <div className={'database-panel'}>
        <SectionTitle customStyle="border-title" title={getTitlePanel()} description={'You must select a database'} />
        <div className="data-grid-wrap">
          <TableRadio
            className="radio-atom"
            rows={mainRowsCurrentPage}
            columns={handleChangeDatabaseColumn()}
            sortOption={{
              target: tablePagination.target,
              direction: tablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={isApiLoading}
            name="database"
            radioValue={tableSelected}
            onRowSelected={setTableSelected}
          />

          {mainRowsCurrentPage?.length && !isApiLoading ? (
            <div className="fleet-instance pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{mainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={false}
                currentPage={tablePagination.currentPage}
                updateCurrentPage={page =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          ) : null}
        </div>
      </div>
      {errors?.idTable ? (
        <div className="error-container">
          <img src={InputErrorIcon} width={16} height={16} />
          <p className="error-message">{errors.idTable}</p>
        </div>
      ) : null}
    </>
  ) : (
    <SectionContainer title="Database" description={'You must select a database'} customStyle="database-panel">
      <div className="content-image">
        {DATABASE_IMGS.map((data, index: Key) => (
          <div
            key={index}
            className={`container ${dataImgSelected === data.value ? ' color-selected' : ''}`}
            onClick={() => {
              setDataimgSelected(data.value);
              handleValueChanged('otherDB', { ...creationData.otherDB, engine: data.value, idTable: data.value });
            }}
          >
            <img src={data.img} alt={data.title} className="logo" width={100} height={60} />
            <div className="radio-button">
              <RadioAtom
                label={data.title}
                value={data.value}
                name={data.value}
                checked={dataImgSelected}
                onChange={() => {}}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="content-input">
        <LabelInput
          title="Server address"
          required
          placeholder="127.0.0.1"
          value={creationData.otherDB?.host || ''}
          onChangeValue={val => handleValueChanged('otherDB', { ...creationData.otherDB, host: val })}
          errorMsg={errors.host}
        />
        <LabelInput
          title="Database name"
          required
          value={creationData.otherDB?.dbName || ''}
          onChangeValue={val => handleValueChanged('otherDB', { ...creationData.otherDB, dbName: val })}
          errorMsg={errors.dbName}
        />
        <LabelInput
          title="Port"
          required
          value={creationData.otherDB?.port || ''}
          onChangeValue={val => handleValueChanged('otherDB', { ...creationData.otherDB, port: val })}
          errorMsg={errors.port}
        />
      </div>
    </SectionContainer>
  );
};
export default DatabasePanel;
