import NetworkTitle from 'components/v3/NetworkTitle';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/WAF/WebACL/CreateWebACL/components/Button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CREATE_RULE_GROUP_STEPS } from './configs';
import './index.scss';
import Review from './review';
import AddTags from './steps/AddTags';
import ConfigureAdvancedSettings from './steps/ConfigureAdvancedSettings';
import ConfigureRules from './steps/ConfigureRules';
import DescribeRuleGroup from './steps/DescribeRuleGroup';
import RuleGroupType from './steps/RuleGroupType';
import { ICreationDataPropsType } from './steps/type';
import { isEmpty } from './validate';

type RuleGroupCreationProps = {
  cloudId: number;
  region: string;
  pageBackClick: () => void;
};
const RuleGroupCreation = (props: RuleGroupCreationProps) => {
  const { cloudId, region, pageBackClick } = props;
  const initCreation: ICreationDataPropsType = {
    cloudId,
    region,
    ruleGroupType: 'stateful',
    ruleGroupFormat: { id: 0, name: '', description: '', value: '' },
    ruleEvaluationOrder: 'strictOrder',
    ipSetVariables: [{ name: '', value: '' }],
    portVariables: [{ name: '', value: '' }],
    ipSetReferenceVariables: [
      { name: '', prefixListId: '', prefixListArn: '', prefixListName: '', maxEntries: '', resource: { value: '' } },
    ],
    suricataRule: '',
    ruleGroupDetails: {
      name: '',
      description: '',
      capacity: '',
    },
    ruleGroupDetailsError: {},
    ruleGroupTypeError: {},
    configureRulesError: {},
    cidrRanges: 'default',
    domainName: '',
    domainListProtocol: ['HTTP_HOST', 'TLS_SNI'],
    domainListAction: 'ALLOWLIST',
    domainListRuleCIDRRanges: '',
    statefulProtocol: { id: 0, name: '', description: '', value: '' },
    statefulSourceIP: 'ANY',
    statefulSourcePort: 'ANY',
    statefulDestinationIP: 'ANY',
    statefulDestinationPort: 'ANY',
    ruleRowsDomainList: [],
    tags: [],
    isUseCustomizeEncryption: false,
    awsKey: { id: '' },
  };
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [creationData, setCreationData] = useState<ICreationDataPropsType>(initCreation);

  // Step 1 Validation
  const onRuleGroupDetailsValidate = useCallback((): boolean => {
    const {
      ruleGroupDetails: { name, capacity },
    } = creationData;
    let isValid = true,
      nameError,
      capacityError;
    if (isEmpty(name)) {
      nameError = 'Name is required field';
      isValid = false;
    }
    if (isEmpty(capacity)) {
      capacityError = 'Capacity is required field';
      isValid = false;
    }
    setCreationData({
      ...creationData,
      ruleGroupDetailsError: {
        nameError,
        capacityError,
      },
    });

    return isValid;
  }, [creationData]);

  // Step 2 Validation
  const onRuleGroupTypeValidate = useCallback((): boolean => {
    const {
      ruleGroupType,
      ruleGroupFormat: { value },
    } = creationData;
    let isValid = true,
      typeError;
    if (ruleGroupType === 'stateful' && isEmpty(value.toString())) {
      typeError = 'A rule group format is required.';
      isValid = false;
    }
    setCreationData({
      ...creationData,
      ruleGroupTypeError: {
        typeError,
      },
    });

    return isValid;
  }, [creationData]);

  // Step 3 Validation
  const onSuricataConfigValidate = useCallback((): boolean => {
    const { ipSetVariables, ipSetReferenceVariables, portVariables, suricataRule } = creationData;
    let isValid = true,
      suricataError;
    if (
      !ipSetVariables ||
      ipSetVariables.length === 0 ||
      ipSetVariables.some(item => isEmpty(item.name) || isEmpty(item.value))
    ) {
      suricataError = 'Check variables';
      isValid = false;
    } else if (
      !portVariables ||
      portVariables.length === 0 ||
      portVariables.some(item => isEmpty(item.name) || isEmpty(item.name) || isEmpty(item.value))
    ) {
      suricataError = 'Check variables';
      isValid = false;
    } else if (
      !ipSetReferenceVariables ||
      ipSetReferenceVariables.length === 0 ||
      ipSetReferenceVariables.some(
        item => isEmpty(item.resource.value + '') || isEmpty(item.resource.name) || isEmpty(item.resource.description),
      )
    ) {
      suricataError = 'Check IP set references';
      isValid = false;
    } else if (
      !ipSetReferenceVariables ||
      ipSetReferenceVariables.length === 0 ||
      ipSetReferenceVariables.some(item => isEmpty(item.name))
    ) {
      suricataError = 'There is unknown variable name';
      isValid = false;
    } else if (isEmpty(suricataRule)) {
      suricataError = 'Rule group must contain at least one rule(suricata).';
      isValid = false;
    }
    setCreationData({
      ...creationData,
      configureRulesError: {
        suricataError,
      },
    });

    return isValid;
  }, [creationData]);
  const onDomainListConfigValidate = useCallback((): boolean => {
    const { domainName, cidrRanges, domainListRuleCIDRRanges } = creationData;
    let isValid = true,
      domainNameError,
      domainCIDRError,
      domainProtocolsError;
    if (isEmpty(domainName)) {
      domainNameError = 'Check domain names';
      isValid = false;
    }
    if (cidrRanges === 'custom' && isEmpty(domainListRuleCIDRRanges)) {
      domainCIDRError = 'Source IPs must contain at least one CIDR range.';
      isValid = false;
    }
    setCreationData({
      ...creationData,
      configureRulesError: {
        domainNameError,
        domainCIDRError,
        domainProtocolsError,
      },
    });

    return isValid;
  }, [creationData]);
  const onStandardStatefulValidate = useCallback((): boolean => {
    const { ruleRowsDomainList } = creationData;
    let isValid = true,
      standardRuleError;
    if (ruleRowsDomainList.length === 0) {
      standardRuleError = 'Rule group must contain at least one rule.';
      isValid = false;
    }
    setCreationData({
      ...creationData,
      configureRulesError: {
        standardRuleError,
      },
    });

    return isValid;
  }, [creationData]);
  const onConfigureRulesValidate = useCallback((): boolean => {
    const {
      ruleGroupFormat: { id },
    } = creationData;
    switch (id) {
      default:
      case 1:
        return onStandardStatefulValidate();
      case 2:
        return onDomainListConfigValidate();
      case 3:
        return onSuricataConfigValidate();
    }
  }, [creationData, onDomainListConfigValidate, onStandardStatefulValidate, onSuricataConfigValidate]);
  // step 4 validation
  const onConfigureAdvancedValidate = useCallback((): boolean => {
    const {
      isUseCustomizeEncryption,
      awsKey: { id },
    } = creationData;
    if (!isUseCustomizeEncryption) return true;
    let isValid = true,
      configureAdvancedError;
    if (isEmpty(id)) {
      configureAdvancedError = 'An AWS KMS key is required.';
      isValid = false;
    }
    setCreationData({
      ...creationData,
      configureAdvancedError,
    });

    return isValid;
  }, [creationData]);
  // Step 5 validation
  const onAddTagsValidate = useCallback((): boolean => {
    const { tags } = creationData;
    let isValid = true,
      addTagsError;
    if (tags.length !== 0 && tags.some(item => isEmpty(item.key) || isEmpty(item.value))) {
      addTagsError = 'Must enter a key.';
      isValid = false;
    }
    setCreationData({
      ...creationData,
      addTagsError,
    });

    return isValid;
  }, [creationData]);
  const validateInputs = useCallback((): boolean => {
    switch (currentStepIndex) {
      default:
        return true;
      case 0:
        return onRuleGroupDetailsValidate();
      case 1:
        return onRuleGroupTypeValidate();
      case 2:
        return onConfigureRulesValidate();
      case 3:
        return onConfigureAdvancedValidate();
      case 4:
        return onAddTagsValidate();
    }
  }, [
    currentStepIndex,
    onAddTagsValidate,
    onConfigureAdvancedValidate,
    onConfigureRulesValidate,
    onRuleGroupDetailsValidate,
    onRuleGroupTypeValidate,
  ]);

  const onPreviousButtonClicked = useCallback(() => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  }, [currentStepIndex]);

  const onNextButtonClicked = useCallback(() => {
    if (currentStepIndex < CREATE_RULE_GROUP_STEPS.length - 1 && validateInputs()) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  }, [currentStepIndex, validateInputs]);

  const stepNode = useMemo(() => {
    switch (currentStepIndex) {
      default:
      case 0:
        return (
          <DescribeRuleGroup
            title={CREATE_RULE_GROUP_STEPS[currentStepIndex].title}
            subTitle={CREATE_RULE_GROUP_STEPS[currentStepIndex].subTitle}
            creationData={creationData}
            updateCreationData={value => setCreationData(value)}
          />
        );
      case 1:
        return (
          <RuleGroupType
            title={CREATE_RULE_GROUP_STEPS[currentStepIndex].title}
            subTitle={CREATE_RULE_GROUP_STEPS[currentStepIndex].subTitle}
            creationData={creationData}
            updateCreationData={value => setCreationData(value)}
          />
        );
      case 2:
        return (
          <ConfigureRules
            title={CREATE_RULE_GROUP_STEPS[currentStepIndex].title}
            subTitle={CREATE_RULE_GROUP_STEPS[currentStepIndex].subTitle}
            creationData={creationData}
            updateCreationData={value => setCreationData(value)}
          />
        );
      case 3:
        return (
          <ConfigureAdvancedSettings
            title={CREATE_RULE_GROUP_STEPS[currentStepIndex].title}
            subTitle={CREATE_RULE_GROUP_STEPS[currentStepIndex].subTitle}
            creationData={creationData}
            updateCreationData={value => setCreationData(value)}
          />
        );
      case 4:
        return (
          <AddTags
            title={CREATE_RULE_GROUP_STEPS[currentStepIndex].title}
            subTitle={CREATE_RULE_GROUP_STEPS[currentStepIndex].subTitle}
            creationData={creationData}
            updateCreationData={value => setCreationData(value)}
          />
        );
      case 5:
        return (
          <Review
            creationData={creationData}
            cancelFunction={pageBackClick}
            prevStepFunction={onPreviousButtonClicked}
          />
        );
    }
  }, [creationData, currentStepIndex, onPreviousButtonClicked, pageBackClick]);

  const errorNode = useMemo(() => {
    const {
      ruleGroupFormat: { id },
      ruleGroupDetailsError: { nameError, capacityError },
      ruleGroupTypeError: { typeError },
      configureRulesError: { standardRuleError, suricataError, domainNameError, domainCIDRError, domainProtocolsError },
      configureAdvancedError,
      addTagsError,
    } = creationData;
    switch (currentStepIndex) {
      default:
        return null;
      case 0:
        return <p className="error-message">{nameError || capacityError}</p>;
      case 1:
        return <p className="error-message">{typeError}</p>;
      case 2: {
        switch (id) {
          case 1:
            return <p className="error-message">{standardRuleError}</p>;
          case 2:
            return <p className="error-message">{domainNameError || domainCIDRError || domainProtocolsError}</p>;
          case 3:
            return <p className="error-message">{suricataError}</p>;
          default:
            return null;
        }
      }
      case 3:
        return <p className="error-message">{configureAdvancedError}</p>;
      case 4:
        return <p className="error-message">{addTagsError}</p>;
    }
  }, [creationData, currentStepIndex]);

  const buttonGroupNode = useMemo(() => {
    const isFirstItem = currentStepIndex === 0;
    const label = currentStepIndex === CREATE_RULE_GROUP_STEPS.length - 1 ? 'Create rule group' : 'Next';

    if (currentStepIndex === 5) {
      return null;
    }

    const {
      configureRulesError: { domainNameError, domainCIDRError },
    } = creationData;

    return (
      <div className="firewall-rule-group-bottom">
        {errorNode}
        <Button label="Cancel" onClick={pageBackClick} />
        {!isFirstItem && <Button label="Previous" onClick={onPreviousButtonClicked} />}
        <Button label={label} type={ButtonTypeEnum.PRIMARY} onClick={onNextButtonClicked} />
      </div>
    );
  }, [creationData, currentStepIndex, errorNode, onNextButtonClicked, onPreviousButtonClicked, pageBackClick]);

  return (
    <div id="mgd-list-layout">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center" id="title">
          <NetworkTitle
            pageTitle="Create rule group"
            id={321}
            name="name"
            hasPrefixIcon={false}
            hasFavorite={false}
            pageBackClick={pageBackClick}
          />
        </div>
      </div>

      <div className="one-page" style={{ height: 'calc(100% - 70px)', overflowY: 'auto' }}>
        <div className="flex j-between a-center">
          <div className="firewall-rule-group">
            {stepNode}
            {buttonGroupNode}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RuleGroupCreation;
