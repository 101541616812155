import { gql } from '@apollo/client';

const query = gql`
mutation updateAwsGroupQuery($request: AwsUpdateGroupQueryRequest, $cloudId: Long, $region: String) {
  updateAwsGroupQuery(awsUpdateGroupQueryRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsUpdateGroupQueryResponse {
        groupQuery {
          groupName
          resourceQuery{
            type
            query
          }
        }
        }
      }
    }
  }
`;
export default query;