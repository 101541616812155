import lazyGetAwsListResourceTags, { IAwsListResourceTagsVariables } from 'graphql/queries/getAwsListResourceTags';
import { TagDetailPropsType } from './types';
import { ColumnType, RowType } from '@Types/v2/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TagTab from 'pages/v2/Organ/Management/components/TagTab';

const Tag = (props: TagDetailPropsType) => {
  const { cloudId, region, customerKey, actionButtons, tags, loading } = props;

  // const [getAwsListResourceTags, {loading: listTagLoading}] = lazyGetAwsListResourceTags();

  // const [tagRows, setTagRows] = useState<RowType[]>([]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  // const reqVariable = useMemo((): IAwsListResourceTagsVariables => {
  //   return {
  //     cloudId: cloudId,
  //     region: region,
  //     request: { keyId: customerKey.keyId },
  //   };
  // }, [cloudId, region, customerKey]);

  // const fetchResourceTags = useCallback(() => {
  //   getAwsListResourceTags({variables: reqVariable}).then(({data: responseData}) => {
  //     const tags = responseData?.getAwsListResourceTags?.data?.[0]?.tags;
  //     if (tags) {
  //       const rows: RowType[] = [];
  //       tags.map(item => {
  //         rows.push({
  //           key: item.key,
  //           value: item.value,
  //         });
  //       });
  //       setTagRows(rows);
  //     }
  //   });
  // }, [reqVariable]);

  // useEffect(() => {
  //   fetchResourceTags();
  // }, []);

  if (loading) {
    return (
      <div className="progress-container">
        <div className="progress-gif" />
        Loading ...
      </div>
    );
  }

  return <TagTab rows={tags ?? []} columns={tagColumns} title={'Tags'} rightButtons={actionButtons} />;
};

export default Tag;
