import { useCallback } from 'react';
import { ICreationDataPropsType } from '../steps/type';

interface IRuleVariablesProps {
  creationData: ICreationDataPropsType;
  updateCreationData: (data: ICreationDataPropsType) => void;
}

const RuleVariables = ({ creationData, updateCreationData }: IRuleVariablesProps) => {
  const { ipSetVariables, portVariables } = creationData;
  const handleChange = useCallback(
    (index: number, listname: 'ipSetVariables' | 'portVariables', type: 'name' | 'value', value: string) => {
      const newList = [...creationData[listname]];
      newList[index][type] = value;
      updateCreationData({ ...creationData, [listname]: newList });
    },
    [creationData, updateCreationData],
  );
  const handleAdd = useCallback(
    (listname: 'ipSetVariables' | 'portVariables') => {
      const newList = [...creationData[listname], { name: '', value: '' }];
      updateCreationData({ ...creationData, [listname]: newList });
    },
    [creationData, updateCreationData],
  );
  const handleRemove = useCallback(
    (listname: 'ipSetVariables' | 'portVariables', index: number) => {
      const newPortVariables = [...creationData[listname]];
      newPortVariables.splice(index, 1);
      updateCreationData({ ...creationData, [listname]: newPortVariables });
    },
    [creationData, updateCreationData],
  );

  return (
    <div className="rule-group-details-container">
      <div className="rule-group-detail-title">
        Rule variables - optional
        <div className="rule-group-detail-sub-title">
          Define IP sets and ports in variables. These variables can be used within this rule group for standard
          stateful rules and Sumatra compatible rule strings.
        </div>
      </div>
      <div className="rule-group-detail-content" style={{ gap: 16 }}>
        <div className="title">IP set Variables</div>
        <div style={{ width: '100%' }}>
          <div className="config-rule-row">
            <div className="config-rule-column">
              <div className="config-rule-label">Variable name</div>
            </div>
            <div className="config-rule-column">
              <div className="config-rule-label">Values</div>
            </div>
            <div style={{ marginLeft: 6, opacity: 0, pointerEvents: 'none' }}>
              <button className="button" style={{ margin: 0 }} onClick={() => {}}>
                Remove
              </button>
            </div>
          </div>
          {ipSetVariables.map((item, index) => {
            return (
              <div key={index} className="config-rule-row">
                <div className="config-rule-column">
                  <div className="config-rule-textarea">
                    <textarea
                      value={item.name}
                      placeholder="HOME_NET"
                      onChange={event => handleChange(index, 'ipSetVariables', 'name', event.target.value)}
                    />
                  </div>
                </div>
                <div className="config-rule-column">
                  <div className="config-rule-textarea">
                    <textarea
                      value={item.value}
                      placeholder="10.0.0.0/24"
                      onChange={event => handleChange(index, 'ipSetVariables', 'value', event.target.value)}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: 6 }}>
                  <button
                    className="button"
                    style={{ margin: 0 }}
                    onClick={() => handleRemove('ipSetVariables', index)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            );
          })}
          <div className="config-rule-row">
            <div className="config-rule-column">
              <div className="config-rule-description">Enter one variable name</div>
            </div>
            <div className="config-rule-column">
              <div className="config-rule-description">Enter one value per line for the website</div>
            </div>
            <div style={{ marginLeft: 6, opacity: 0, pointerEvents: 'none' }}>
              <button className="button" style={{ margin: 0 }} onClick={() => {}}>
                Remove
              </button>
            </div>
          </div>
        </div>
        <button className="button next-button" style={{ margin: 0 }} onClick={() => handleAdd('ipSetVariables')}>
          Add another IP set variable
        </button>
        <div className="title">Port variables</div>
        <div style={{ width: '100%' }}>
          <div className="config-rule-row">
            <div className="config-rule-column">
              <div className="config-rule-label">Variable name</div>
            </div>
            <div className="config-rule-column">
              <div className="config-rule-label">Values</div>
            </div>
            <div style={{ marginLeft: 6, opacity: 0, pointerEvents: 'none' }}>
              <button className="button" style={{ margin: 0 }} onClick={() => {}}>
                Remove
              </button>
            </div>
          </div>
          {portVariables.map((item, index) => {
            return (
              <div key={index} className="config-rule-row">
                <div className="config-rule-column">
                  <div className="config-rule-textarea">
                    <textarea
                      value={item.name}
                      placeholder="ALLOW_PORTS"
                      onChange={event => handleChange(index, 'portVariables', 'name', event.target.value)}
                    />
                  </div>
                </div>
                <div className="config-rule-column">
                  <div className="config-rule-textarea">
                    <textarea
                      value={item.value}
                      placeholder="22"
                      onChange={event => handleChange(index, 'portVariables', 'value', event.target.value)}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: 6 }}>
                  <button className="button" style={{ margin: 0 }} onClick={() => handleRemove('portVariables', index)}>
                    Remove
                  </button>
                </div>
              </div>
            );
          })}
          <div className="config-rule-row">
            <div className="config-rule-column">
              <div className="config-rule-description">Enter one variable name</div>
            </div>
            <div className="config-rule-column">
              <div className="config-rule-description">Enter one value per line for the website</div>
            </div>
            <div style={{ marginLeft: 6, opacity: 0, pointerEvents: 'none' }}>
              <button className="button" style={{ margin: 0 }} onClick={() => {}}>
                Remove
              </button>
            </div>
          </div>
        </div>
        <button className="button next-button" style={{ margin: 0 }} onClick={() => handleAdd('portVariables')}>
          Add another ports variables
        </button>
      </div>
    </div>
  );
};

export default RuleVariables;
