import './index.scss';
import { useEffect, useState } from 'react';
import Details from '../Common/Components/Details';
import lazyGetAwsDescribeRuleGroup from 'graphql/queries/getAwsDescribeRuleGroup';
import RulesVariables from '../Common/Components/RulesVariables';
import IpStateReferences from '../Common/Components/IpStateReferences';
import Rules from '../Common/Components/Rules';
import CustomerManagedKeys from '../Common/Components/CustomerManagedKeys';
import Tags from '../Common/Components/Tags';

const RuleGroupSummary = ({ cloudId, region, data, setPageDetail }: any) => {
  const [describeRuleGroup, setDescribeRuleGroup] = useState<any>(null);

  const [getAwsDescribeRuleGroup, { loading }] = lazyGetAwsDescribeRuleGroup();

  const handleGetAwsFirewallPolicy = async () => {
    if (!data?.id) return;

    try {
      const variables = {
        cloudId,
        region,
        request: {
          ruleGroupName: data?.name || '',
          ruleGroupArn: data?.id || '',
        },
      };

      const { data: describeRuleGroup } = await getAwsDescribeRuleGroup({
        variables,
      });

      setDescribeRuleGroup(describeRuleGroup?.getAwsDescribeRuleGroup?.data?.[0]);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetAwsFirewallPolicy();
  }, [data?.id]);

  return data?.id ? (
    <div className="box-summary">
      <div className="box-summary-id">{data?.name}</div>

      <div className="content-tab">
        <Details data={describeRuleGroup} isLoading={loading} />

        <RulesVariables data={describeRuleGroup} isLoading={loading} />

        <IpStateReferences data={describeRuleGroup} isLoading={loading} />

        <Rules data={describeRuleGroup} isLoading={loading} />

        <CustomerManagedKeys data={describeRuleGroup} isLoading={loading} />

        <Tags data={describeRuleGroup} isLoading={loading} />
      </div>
    </div>
  ) : (
    <div className="detail-data">
      <p className="empty-page">Select a item</p>
    </div>
  );
};

export default RuleGroupSummary;
