import IconEditFirewallDetail from 'assets/svgs/v3/ico_edit_firewall_detail.svg';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import Icon from 'components/v2/atoms/Icon';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import lazyGetAwsListFirewallPolicies, {
  IAwsListFirewallPoliciesVariables,
} from 'graphql/queries/getAwsListFirewallPolicies';
import { AwsFirewallPolicyResponseType } from 'graphql/types/AwsFirewallPolicy';
import { useEffect, useState } from 'react';
import './index.scss';
import updateAssociateAwsFirewallPolicyMutation, {
  AssociateAwsFirewallPolicyVariablesType,
} from 'graphql/mutations/associateAwsFirewallPolicy';
import { IAwsFirewall, IBaseFirewallModalProps } from '../../../types';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';

interface IEditFirewallPolicyProps extends IBaseFirewallModalProps {
  firewallPolicyResponse?: AwsFirewallPolicyResponseType;
  onChangeValue?: (index: number, value: string) => void;
}

const EditFirewallPolicyModal = ({
  header,
  cloudId,
  region,
  data,
  setData = () => { },
  firewallPolicyResponse,
  onChangeValue,
  ...baseModalProps
}: IEditFirewallPolicyProps) => {
  const { firewallName = '', firewallArn = '' } = data?.firewall || {};

  const updateToken = data?.updateToken || '';

  const { firewallPolicyArn = '', firewallPolicyName } = firewallPolicyResponse || {};

  const initialState = {
    name: firewallPolicyName,
    value: firewallPolicyArn,
  };

  const [firewallPolicySelected, SetFirewallPolicySelected] = useState<DropdownListDataType>(initialState);
  const [allListFirewallPolicies, setAllListFirewallPolices] = useState<DropdownListDataType[]>([]);

  const [getAwsListFirewallPolicies] = lazyGetAwsListFirewallPolicies();
  const [updateAssociateAwsFirewallPolicy] = updateAssociateAwsFirewallPolicyMutation();

  const fetchFirewallPolices = async () => {
    try {
      const variables: IAwsListFirewallPoliciesVariables = {
        cloudId,
        region,
        request: {},
      };
      const { data } = await getAwsListFirewallPolicies({ variables });
      const firewallPolicies = data?.getAwsListFirewallPolicies.data[0].firewallPolicies;
      const dropdownFirewallPolicies = firewallPolicies?.map(firewallPolicy => {
        return {
          name: firewallPolicy.name,
          value: firewallPolicy.arn,
        };
      });

      setAllListFirewallPolices(dropdownFirewallPolicies || []);
    } catch (error) {
      setAllListFirewallPolices([]);
    }
  };

  const handleUpdateAwsFirewallPolicy = async () => {
    try {
      const variables: AssociateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          firewallPolicyArn: firewallPolicySelected.value.toString(),
          firewallName,
          firewallArn,
          updateToken,
        },
      };

      const res = await updateAssociateAwsFirewallPolicy({ variables });
      const firewallPolicyData = res?.data?.associateAwsFirewallPolicy?.data?.[0];

      setData((prevState: IAwsFirewall) => ({
        ...prevState,
        updateToken: firewallPolicyData?.updateToken || '',
        firewall: {
          ...prevState.firewall,
          firewallPolicyArn: firewallPolicyData?.firewallPolicyArn || '',
        },
      }));
      useToast(ErrorCode.SUCCESS, 'Update associate firewall policy successfully.');
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Update associate firewall policy failed.');
    } finally {
      baseModalProps?.onClose?.()
    }
  };

  useEffect(() => {
    fetchFirewallPolices();
  }, []);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditFirewallDetail} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-firewall-policy-modal">
        <div className="edit-firewall-policy">
          <p>Firewall policy</p>
          <DropdownAtom
            id="list-firewall-policy"
            data={allListFirewallPolicies}
            value={{
              name: firewallPolicySelected.name,
              value: firewallPolicySelected.value,
            }}
            handleClick={val => {
              SetFirewallPolicySelected(val);
            }}
            className="list-firewall-policy-wrap"
          />
        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={handleUpdateAwsFirewallPolicy}>
            Save changes
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditFirewallPolicyModal;
