import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { KeyAndSecretPropsType } from './types';
import { useOutletContext } from 'react-router-dom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { LIST_UP_TAB_LIST, PageNodeEnum } from './configs';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { MgdTabLayoutTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import MgdTabLayout from 'layouts/v3/MgdTabLayout';
import AwsKeyList from './Components/AwsKey/AwsKeyList';
import { AwsAliasRowData } from 'graphql/types/AwsAliases';
import AwsKeyDetail from './Components/AwsKey/AwsKeyDetail';
import './index.scss';
import CustomerKeyList from './Components/CustomerKey/CustomerKeyList';
import { CustomerKeyRowType } from './Components/CustomerKey/types';
import CustomerKeyDetail from './Components/CustomerKey/CustomerKeyDetail';
import { AwsSecretListEntryType } from 'graphql/types/AwsAllListSecrets';
import SecretList from './Components/Secret/SecretList';
import SecretDetail from './Components/Secret/SecretDetail';
import CreateCustomerKey from './Components/CustomerKey/CreateCustomerKey';
import CreateSecret from './Components/Secret/CreateSecret';

const KeyAndSecret = (props: KeyAndSecretPropsType) => {
  const {
    onBackButtonClicked,
    projectId,
    cloudName,
    regionCode,
    regions,
    recentRelatedClouds,
    relatedCloudSelected,
    recentRelatedCloudOnChange,
    currentRouteState,
    setCurrentRouteState,
    adminAccountId,
  } = props;

  // Context
  const { defaultRegionList, updateDefaultRegionList } = useOutletContext<{
    defaultRegionList: { projectId: number; cloudId: number; name: string; value: string }[];
    updateDefaultRegionList: (projectId: number, cloudId: number, name: string, value: string) => void;
  }>();

  // API
  const [getEnumTypeCode, { loading: enumTypeLoading }] = lazyGetEnumTypeCode();

  // State
  const [pageType, setPageType] = useState<PageNodeEnum>(PageNodeEnum.MGD_LIST);
  const [currentRegion, setCurrentRegion] = useState<DropdownListDataType>({ name: '', value: '' });
  const [regionList, setRegionList] = useState<DropdownListDataType[]>([]);
  const [mainTabSelected, setMainTabSelected] = useState<IMgdTabProps>(LIST_UP_TAB_LIST[0]);
  const [detailAwsKey, setDetailAwsKey] = useState<AwsAliasRowData | undefined>();
  const [detailCustomerKey, setDetailCustomerKey] = useState<CustomerKeyRowType | undefined>();
  const [detailSecret, setDetailSecret] = useState<AwsSecretListEntryType | undefined>();

  useEffect(() => {
    if (!currentRouteState) return;

    const currentTab = LIST_UP_TAB_LIST.find(e => e.id === currentRouteState.tabId);

    if (currentTab) setMainTabSelected(currentTab);
  }, [currentRouteState]);

  const cloudId = useMemo((): number => {
    return Number(relatedCloudSelected.value);
  }, [relatedCloudSelected]);

  const regionValue = useMemo((): string => {
    return currentRegion?.value?.toString();
  }, [currentRegion]);

  // Get initial region
  const getInitialRegion = useCallback(() => {
    const hasDefaultRegion = defaultRegionList.find(r => {
      return r.projectId === projectId && r.cloudId === cloudId;
    });

    if (!!hasDefaultRegion) {
      setCurrentRegion({
        name: hasDefaultRegion.name,
        value: hasDefaultRegion.value,
      });
    } else {
      getEnumTypeCode({
        variables: { text: regionCode || 'AwsRegionCode' },
      }).then(res => {
        if (res.data) {
          const regionsRes: DropdownListDataType[] = res.data.getEnumTypeCode.data
            .filter(val => regions.includes(val.value))
            .map(val => ({
              ...val,
              name: val.value,
            }));
          setRegionList(regionsRes);
          const region = regionsRes.find(r => r.name === currentRegion.name && r.value === currentRegion.value);
          setCurrentRegion(region || regionsRes[0]);
        }
      });
    }
  }, [cloudId, defaultRegionList]);

  // For initial data
  useEffect(() => {
    getInitialRegion();
  }, [getInitialRegion]);

  const resetMainStates = useCallback(() => {
    if (!currentRouteState) return;

    setCurrentRouteState(null);
  }, [currentRouteState]);

  const mainTabClickHandler = useCallback(
    (tabSelected: IMgdTabProps) => {
      setMainTabSelected(LIST_UP_TAB_LIST.find(tabItem => tabItem.id === tabSelected.id) || LIST_UP_TAB_LIST[0]);
      resetMainStates();

      switch (tabSelected) {
        case LIST_UP_TAB_LIST[0]:
          setDetailAwsKey(undefined);
          break;
        case LIST_UP_TAB_LIST[1]:
          setDetailCustomerKey(undefined);
          break;
        case LIST_UP_TAB_LIST[2]:
          setDetailSecret(undefined);
          break;
        default:
          break;
      }
    },
    [setMainTabSelected, resetMainStates],
  );

  const pinButtonClickHandle = useCallback(() => {
    updateDefaultRegionList(
      projectId,
      Number(relatedCloudSelected.value),
      currentRegion.name || '',
      currentRegion.value.toString(),
    );
  }, [relatedCloudSelected, currentRegion, projectId]);

  const tabContentNode = useMemo((): ReactNode => {
    if (!currentRegion?.value || !cloudId) return;

    switch (mainTabSelected) {
      case LIST_UP_TAB_LIST[0]:
        return (
          <AwsKeyList
            cloudId={cloudId}
            region={regionValue}
            tabInformation={mainTabSelected}
            onAwsKeySelected={setDetailAwsKey}
            onAwsKeyIdClicked={() => setPageType(PageNodeEnum.MGD_DETAIL)}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
          />
        );
      case LIST_UP_TAB_LIST[1]:
        return (
          <CustomerKeyList
            cloudId={cloudId}
            region={regionValue}
            tabInformation={mainTabSelected}
            onCustomerKeySelected={setDetailCustomerKey}
            onCustomerKeyIdClicked={() => setPageType(PageNodeEnum.MGD_DETAIL)}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
          />
        );
      case LIST_UP_TAB_LIST[2]:
        return (
          <SecretList
            cloudId={cloudId}
            region={regionValue}
            tabInformation={mainTabSelected}
            onSecretSelected={setDetailSecret}
            onSecretIdClicked={() => setPageType(PageNodeEnum.MGD_DETAIL)}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
          />
        );
      default:
        return null;
    }
  }, [mainTabSelected, currentRegion, regionValue, cloudId, currentRouteState]);

  const tabSummaryNode = useMemo((): ReactNode => {
    switch (mainTabSelected) {
      case LIST_UP_TAB_LIST[0]:
      case LIST_UP_TAB_LIST[1]:
      case LIST_UP_TAB_LIST[2]:
      default:
        return null;
    }
  }, [mainTabSelected, cloudId, regionValue, projectId]);

  const onPageBackClick = useCallback(() => {
    setDetailAwsKey(undefined);
    setDetailCustomerKey(undefined);
    setDetailSecret(undefined);
    setPageType(PageNodeEnum.MGD_LIST);
  }, []);

  const listNode = useMemo((): ReactNode => {
    return (
      <MgdTabLayout
        layoutType={MgdTabLayoutTypeEnum.MGD_LIST_SUMMARY}
        pageTitle={cloudName}
        hasFavorite={true}
        onBackButtonClicked={onBackButtonClicked}
        headerDropList={regionList}
        headerDropValue={currentRegion}
        headerDropOnchange={val => {
          setCurrentRegion(() => ({
            name: val.name ? val.name : '',
            value: String(val.value),
          }));
        }}
        isPined={
          !!defaultRegionList.find(r => {
            return r.projectId === projectId && r.cloudId === relatedCloudSelected.value;
          })
        }
        pinBtnActHandle={pinButtonClickHandle}
        pinBtnDisabled={false}
        mainTabList={LIST_UP_TAB_LIST}
        mainTabItemClick={mainTabClickHandler}
        mainTitle={mainTabSelected?.title || ''}
        mainTabSelected={mainTabSelected}
        tabContentNode={tabContentNode}
        tabSummaryNode={tabSummaryNode}
        recentRelatedClouds={recentRelatedClouds}
        relatedCloudSelected={relatedCloudSelected}
        recentRelatedCloudOnChange={relatedCloud => recentRelatedCloudOnChange(relatedCloud)}
      />
    );
  }, [
    cloudName,
    onBackButtonClicked,
    regionList,
    currentRegion,
    defaultRegionList,
    projectId,
    relatedCloudSelected,
    pinButtonClickHandle,
    mainTabClickHandler,
    mainTabSelected,
    tabContentNode,
    tabSummaryNode,
    recentRelatedClouds,
    recentRelatedCloudOnChange,
  ]);

  const detailNode = useMemo((): ReactNode => {
    switch (mainTabSelected) {
      case LIST_UP_TAB_LIST[0]:
        return (
          <AwsKeyDetail cloudId={cloudId} region={regionValue} awsKey={detailAwsKey!} pageBackClick={onPageBackClick} />
        );
      case LIST_UP_TAB_LIST[1]:
        return (
          <CustomerKeyDetail cloudId={cloudId} region={regionValue} customerKey={detailCustomerKey!} pageBackClick={onPageBackClick} />
        );
      case LIST_UP_TAB_LIST[2]:
        return (
          <SecretDetail cloudId={cloudId} region={regionValue} secret={detailSecret!} pageBackClick={onPageBackClick} />
        );
      default:
        break;
    }
  }, [mainTabSelected, cloudId, regionValue, onPageBackClick, detailAwsKey, detailCustomerKey, detailSecret]);

  const creationNode = useMemo((): ReactNode => {
    switch (mainTabSelected) {
      case LIST_UP_TAB_LIST[1]:
        return <CreateCustomerKey cloudId={cloudId} region={regionValue} adminAccountId={adminAccountId} onBackButtonClicked={onPageBackClick} />
      case LIST_UP_TAB_LIST[2]:
        return <CreateSecret cloudId={cloudId} region={regionValue} onBackButtonClicked={onPageBackClick} setPageType={setPageType}/>;
      default:
        break;
    }
  }, [mainTabSelected, cloudId, regionValue, adminAccountId]);

  const pageNode = useMemo((): ReactNode => {
    switch (pageType) {
      default:
      case PageNodeEnum.MGD_LIST:
        return listNode;

      case PageNodeEnum.MGD_DETAIL:
        return detailNode;

      case PageNodeEnum.MGD_CREATE:
        return creationNode;
    }
  }, [pageType, listNode, detailNode, creationNode]);

  return <div className="network-container">{pageNode}</div>;
};

export default KeyAndSecret;
