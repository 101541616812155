import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsGetTagKeysVariables {
  cloudId: number;
  region: string;
  request: {
    paginationToken?: string;
  }
}
export type AwsGetTagKeysResponseType = {
  paginationToken: string;
  tagKeys: string[];
}
export interface IAwsGetTagKeysResponseData {
  getAwsTagKeys: IGqlResponseNewData<AwsGetTagKeysResponseType>;
}

const lazyGetAwsTagKeys = () => useLazyQuery<IAwsGetTagKeysResponseData, IAwsGetTagKeysVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsTagKeys;