import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { TlsInspectionConfigDetailPropsType } from './types';
import PageDetailTitle from '../../../components/PageDetailTitle';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdTabLayout/types';
import lazyGetAwsTLSInspectionConfiguration, {
  IGetAwsTLSInspectionConfigurationVariables,
} from 'graphql/queries/getAwsTLSInspectionConfiguration';
import {
  AwsServerCertificateScope,
  AwsTLSInspectionConfigurationResponse,
} from 'graphql/types/AwsTlsInspectionConfiguration';
import DetailTab from '../../../components/DetailTab';
import { TextTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import { FIREWALL_COMMOM_VALUE_MAPPING } from '../configs';
import InboundCertificateInspection from './components/InboundCertificateInspection';
import OutboundCertificateInspection from './components/OutboundCertificateInspection';
import ScopeConfig from './components/ScopeConfig';
import { ENCRYPTION_TYPE_MAPPING } from './components/configs';
import TagTab from '../../../components/TagTab';
import { ColumnType } from '@Types/v2/Table';
import UpdateDescriptionModal from './Modals/UpdateDescriptionModal';
import UpdateServerCerInbound from './Modals/UpdateServerCerInbound';
import UpdateServerCerOutbound from './Modals/UpdateServerCerOutbound';
import UpdateScopeConfiguration from './Modals/UpdateScopeConfiguration';
import UpdateKMSEncryption from './Modals/UpdateKMSEncryption';
import UpdateTag from './Modals/UpdateTag';
import DeleteTlsInspection from './Modals/DeleteTlsInspection';

const TlsInspectionConfigDetail = (props: TlsInspectionConfigDetailPropsType) => {
  const { tlsInspectionConfig, cloudId, region, pageBackClick, editable = false } = props;

  // API
  const [getAwsTLSInspectionConfiguration, { loading: tlsInpsectionDetailLoading }] =
    lazyGetAwsTLSInspectionConfiguration();

  // State
  const [inspectionDetail, setInspectionDetail] = useState<AwsTLSInspectionConfigurationResponse>();
  const [inboundCertificateInspectionArns, setInboundCertificateInspectionArns] = useState<string[]>([]);
  const [outboundCertificateInspectionArns, setOutboundCertificateInspectionArns] = useState<string[]>([]);
  const [scopeConfig, setScopeConfig] = useState<AwsServerCertificateScope[]>([]);

  // Modal state
  const [editDescriptionModalVisible, setEditDescriptionModalVisible] = useState(false);
  const [editInboundCerModalVisible, setEditInboundCerModalVisible] = useState(false);
  const [editOutboundCerModalVisible, setEditOutboundCerModalVisible] = useState(false);
  const [editScopeConfigModalVisible, setEditScopeConfigModalVisible] = useState(false);
  const [editKMSModalVisible, setEditKMSModalVisible] = useState(false);
  const [editTagModalVisible, setEditTagModalVisible] = useState(false);
  const [deleteResourceModalVisible, setDeleteResourceModalVisible] = useState(false);

  const detailSectionData = useMemo((): IMgdDetailKeyValueProps[] => {
    const tlsInspectionConfigurationResponse = inspectionDetail?.tlsInspectionConfigurationResponse;
    return [
      {
        title: 'Name',
        description: tlsInspectionConfigurationResponse?.tlsInspectionConfigurationName ?? '-',
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Description',
        description: tlsInspectionConfigurationResponse?.description ?? '-',
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Status',
        description:
          FIREWALL_COMMOM_VALUE_MAPPING[tlsInspectionConfigurationResponse?.tlsInspectionConfigurationStatus ?? ''],
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'User count',
        description: tlsInspectionConfigurationResponse?.numberOfAssociations.toString() ?? '-',
        type: TextTypeEnum.NORMAL,
      },
    ];
  }, [inspectionDetail]);

  const customerManagedKeyData = useMemo(() => {
    const tlsInspectionConfigurationResponse = inspectionDetail?.tlsInspectionConfigurationResponse;
    return [
      {
        title: 'Key type',
        description: ENCRYPTION_TYPE_MAPPING[
          tlsInspectionConfigurationResponse?.encryptionConfiguration.type ?? '-'
        ] as string,
        type: TextTypeEnum.NORMAL,
      },
    ];
  }, [inspectionDetail]);

  const cerRevocationStatusData = useMemo(() => {
    const serverCertificateConfigurations =
      inspectionDetail?.tlsInspectionConfiguration?.serverCertificateConfigurations;
    const cerRevocationValue =
      serverCertificateConfigurations && serverCertificateConfigurations?.length > 0 ? 'Yes' : 'No';
    return [
      {
        title: 'Enabled',
        description: cerRevocationValue,
        type: TextTypeEnum.NORMAL,
      },
    ];
  }, [inspectionDetail]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo(() => {
    return inspectionDetail?.tlsInspectionConfigurationResponse?.tags ?? [];
  }, [inspectionDetail]);

  const rightButtonNode = useMemo(() => {
    return [{ id: 'edit-btn', label: 'Edit', onClick: () => setEditTagModalVisible(true) }];
  }, [setEditTagModalVisible]);

  const reqVariable = useMemo((): IGetAwsTLSInspectionConfigurationVariables => {
    return {
      cloudId,
      region,
      request: {
        tlsInspectionConfigurationName: tlsInspectionConfig.name,
        tlsInspectionConfigurationArn: tlsInspectionConfig.arn,
      },
    };
  }, [cloudId, region, tlsInspectionConfig]);

  const fetchInspectionDetailData = useCallback(async () => {
    const tlsInspectionConf = await getAwsTLSInspectionConfiguration({
      variables: reqVariable,
    }).then(({ data: tlsInspectionConfData }) => {
      return tlsInspectionConfData?.getAwsTLSInspectionConfiguration?.data?.[0];
    });

    if (tlsInspectionConf) {
      setInspectionDetail(tlsInspectionConf);
      const certificateInboundArn: string[] = [];
      const certificateOutboundArn: string[] = [];
      tlsInspectionConf.tlsInspectionConfiguration?.serverCertificateConfigurations?.map(item => {
        item.serverCertificates?.map(el => {
          if (el.resourceArn) {
            certificateInboundArn.push(el.resourceArn);
          }
        });
        if (item.certificateAuthorityArn) {
          certificateOutboundArn.push(item.certificateAuthorityArn);
        }
        if (item.scopes) {
          setScopeConfig(item.scopes);
        }
        if (certificateInboundArn.length > 0) {
          setInboundCertificateInspectionArns(certificateInboundArn);
        }
        if (certificateOutboundArn.length > 0) {
          setOutboundCertificateInspectionArns(certificateOutboundArn);
        }
      });
    }
  }, [reqVariable]);

  const renderAction = useCallback(
    (setIsOpen: (value: boolean) => void): ReactNode => {
      return editable ? (
        <button className="button" onClick={() => setIsOpen(true)}>
          Edit
        </button>
      ) : null;
    },
    [editable],
  );

  const onUpdatedHandler = useCallback(() => {
    fetchInspectionDetailData();
    setEditDescriptionModalVisible(false);
    setEditInboundCerModalVisible(false);
    setEditOutboundCerModalVisible(false);
    setEditScopeConfigModalVisible(false);
    setEditKMSModalVisible(false);
    setEditTagModalVisible(false);
  }, []);

  useEffect(() => {
    fetchInspectionDetailData();
  }, []);

  return (
    <Fragment>
      <div className="page-detail">
        <PageDetailTitle
          title={tlsInspectionConfig?.name}
          pageBackClick={pageBackClick}
          isDelete={true}
          onDelete={() => setDeleteResourceModalVisible(true)}
        />

        <DetailTab
          title="TLS inspection configuration details"
          data={detailSectionData}
          isApiLoading={tlsInpsectionDetailLoading}
          actions={renderAction(setEditDescriptionModalVisible)}
        />

        <InboundCertificateInspection
          cloudId={cloudId}
          region={region}
          actionNode={renderAction(setEditInboundCerModalVisible)}
          certificateInboundArn={inboundCertificateInspectionArns}
        />

        <OutboundCertificateInspection
          cloudId={cloudId}
          region={region}
          actionNode={renderAction(setEditOutboundCerModalVisible)}
          certificateOutboundArn={outboundCertificateInspectionArns}
        />

        <ScopeConfig actionNode={renderAction(setEditScopeConfigModalVisible)} scopeConfig={scopeConfig} />

        {/* Customer managed key */}
        <DetailTab
          title="Customer managed key"
          data={customerManagedKeyData}
          isApiLoading={tlsInpsectionDetailLoading}
          actions={renderAction(setEditKMSModalVisible)}
        />

        {/* Certificate revocation status */}
        <DetailTab
          title="Certificate revocation status"
          data={cerRevocationStatusData}
          isApiLoading={tlsInpsectionDetailLoading}
        />

        <TagTab
          title={'Tags'}
          rows={tagRows}
          columns={tagColumns}
          rightButtons={rightButtonNode}
          isLoading={tlsInpsectionDetailLoading}
        />
      </div>
      {editDescriptionModalVisible && inspectionDetail && (
        <UpdateDescriptionModal
          open={editDescriptionModalVisible}
          onClose={() => setEditDescriptionModalVisible(false)}
          onCancel={() => setEditDescriptionModalVisible(false)}
          onUpdated={onUpdatedHandler}
          cloudId={cloudId}
          region={region}
          tlsInspectionDetail={inspectionDetail}
        />
      )}
      {editInboundCerModalVisible && inspectionDetail && (
        <UpdateServerCerInbound
          open={editInboundCerModalVisible}
          onClose={() => setEditInboundCerModalVisible(false)}
          onCancel={() => setEditInboundCerModalVisible(false)}
          onUpdated={onUpdatedHandler}
          cloudId={cloudId}
          region={region}
          tlsInspectionDetail={inspectionDetail}
        />
      )}
      {editOutboundCerModalVisible && inspectionDetail && (
        <UpdateServerCerOutbound
          open={editOutboundCerModalVisible}
          onClose={() => setEditOutboundCerModalVisible(false)}
          onCancel={() => setEditOutboundCerModalVisible(false)}
          onUpdated={onUpdatedHandler}
          cloudId={cloudId}
          region={region}
          tlsInspectionDetail={inspectionDetail}
        />
      )}
      {editScopeConfigModalVisible && inspectionDetail && (
        <UpdateScopeConfiguration
          open={editScopeConfigModalVisible}
          onClose={() => setEditScopeConfigModalVisible(false)}
          onCancel={() => setEditScopeConfigModalVisible(false)}
          onUpdated={onUpdatedHandler}
          cloudId={cloudId}
          region={region}
          tlsInspectionDetail={inspectionDetail}
        />
      )}
      {editKMSModalVisible && inspectionDetail && (
        <UpdateKMSEncryption
          open={editKMSModalVisible}
          onClose={() => setEditKMSModalVisible(false)}
          onCancel={() => setEditKMSModalVisible(false)}
          onUpdated={onUpdatedHandler}
          cloudId={cloudId}
          region={region}
          tlsInspectionDetail={inspectionDetail}
        />
      )}
      {editTagModalVisible && inspectionDetail && (
        <UpdateTag
          open={editTagModalVisible}
          onClose={() => setEditTagModalVisible(false)}
          onCancel={() => setEditTagModalVisible(false)}
          onUpdated={onUpdatedHandler}
          cloudId={cloudId}
          region={region}
          tlsInspectionDetail={inspectionDetail}
        />
      )}
      {deleteResourceModalVisible && inspectionDetail && (
        <DeleteTlsInspection
          open={deleteResourceModalVisible}
          onClose={() => setDeleteResourceModalVisible(false)}
          onCancel={() => setDeleteResourceModalVisible(false)}
          onDeleted={() => {
            setDeleteResourceModalVisible(false);
            pageBackClick();
          }}
          cloudId={cloudId}
          region={region}
          tlsInspectionDetail={inspectionDetail}
        />
      )}
    </Fragment>
  );
};

export default TlsInspectionConfigDetail;
