type AwsArnData = {
  id: string;
  service?: string;
  region?: string;
  accountId?: string;
  resourceType?: string;
  resourceName?: string;
};

export const extractCertificateArnData = (arn: string) => {
  // Split the ARN string
  const arnComponents = arn.split(':');

  // Extract specific parts
  const service = arnComponents[2];
  const region = arnComponents[3];
  const accountId = arnComponents[4];
  const resourceData = arnComponents[5].split('/'); // Split resource type and name

  const resourceType = resourceData[0];
  const resourceId = resourceData[1];

  const arnData: AwsArnData = {
    id: resourceId,
    service: service,
    region: region,
    accountId: accountId,
    resourceType: resourceType,
  };
  return arnData;
};

export const extractResourceArnData = (arn: string) => {
  // Split the ARN string
  const arnComponents = arn.split(':');

  // Extract specific parts
  const service = arnComponents[2];
  const region = arnComponents[3];
  const accountId = arnComponents[4];
  const resourceData = arnComponents[5].split('/'); // Split resource type and name

  const resourceType = resourceData[0];
  const resourceId = resourceData[1];

  const arnData: AwsArnData = {
    id: resourceId,
    service: service,
    region: region,
    accountId: accountId,
    resourceType: resourceType,
  };
  return arnData;
};

export const extractBucketName = (bucketArn: string) => {
  const parts = bucketArn.split('/');
  return parts[parts.length - 1];
};

export const convertBytesToKB = (bytes: number) => {
  const BYTES_IN_KB = 1024;
  return bytes / BYTES_IN_KB;
};

export const appendKBUnit = (value: number | string) => {
  return Number(value) > 0 ? value + " KB" : 0
}

export const capitalizeFirstLetter = (str: string) => {
  //Convert string to lowercase and replace underscores with spaces
  const lowerCased = str.toLowerCase().replace(/_/g, ' ');
  // Capitalize the first letter
  return lowerCased.charAt(0).toUpperCase() + lowerCased.slice(1);
};

export const handleLinkClicked = ({
  navigate,
  link,
  type,
  tabId,
  key,
  value,
}: {
  navigate: any;
  link: string;
  type: string;
  tabId: string;
  key: string;
  value: any;
}) => {

  navigate(link, {
    replace: true,
    state: {
      type,
      tabId,
      key,
      value,
    },
  });
};