import './index.scss';

type ISwitchProps = {
  checked?: boolean;
  label?: string;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
};

const Switch = ({ checked, label, disabled, onChange }: ISwitchProps) => {
  return (
    <div className="switch-container">
      <label className="switch">
        <input type="checkbox" disabled={disabled} checked={checked} onChange={e => onChange?.(e.target.checked)} />

        <span className="slider round" />
      </label>

      <span>{label}</span>
    </div>
  );
};

export default Switch;
