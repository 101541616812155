import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { FIREWALL_RULES_PROTOCOL_MAPPING } from '../../configs';
import EditRuleModal from '../../EditRuleModal';
import Button from 'pages/v2/Organ/Management/components/Button';
import _ from 'lodash';
import DeleteRuleModal from '../../DeleteRuleModal';

const Rules = ({ data, isLoading, isEdit, handleEditRule, handleAddRule, handleDeleteRule }: any) => {
  const { ruleGroup, ruleGroupResponse } = data || {};
  const { rulesSource } = ruleGroup || {};
  const { statelessRulesAndCustomActions, statefulRules } = rulesSource || {};

  const isStateless = ruleGroupResponse?.type === 'STATELESS';

  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [isAddRuleModalVisible, setAddRuleModalVisible] = useState(false);
  const [isEditRuleModalVisible, setEditRuleModalVisible] = useState(false);
  const [isDeleteRuleModalVisible, setDeleteRuleModalVisible] = useState(false);
  const [nextSid, setNextSid] = useState(0);

  const [resourceSelected, setResourceSelected] = useState('');

  const handleGetData = () => {
    if (!statefulRules?.length) return;

    try {
      let mainTblRows = [];
      if (isStateless) {
        mainTblRows = statelessRulesAndCustomActions?.statelessRules.map((item: any) => ({
          priority: item.priority,
          protocol:
            FIREWALL_RULES_PROTOCOL_MAPPING[item.ruleDefinition?.matchAttributes?.protocols?.[0]]?.text || 'All',
          source: item.ruleDefinition?.matchAttributes?.sources?.[0]?.addressDefinition || '0.0.0.0/0',
          destination: item.ruleDefinition?.matchAttributes?.destinations?.[0]?.addressDefinition || '0.0.0.0/0',
          sourcePort: item.ruleDefinition?.matchAttributes?.sourcePorts?.[0]
            ? `${item.ruleDefinition?.matchAttributes?.sourcePorts?.[0]?.fromPort}:${item.ruleDefinition?.matchAttributes?.sourcePorts?.[0]?.toPort}`
            : '-',
          destinationPort: item.ruleDefinition?.matchAttributes?.destinationPorts?.[0]
            ? `${item.ruleDefinition?.matchAttributes?.destinationPorts?.[0]?.fromPort}:${item.ruleDefinition?.matchAttributes?.destinationPorts?.[0]?.toPort}`
            : '-',
          action: item.ruleDefinition?.actions?.[0] || '-',
          customAction: statelessRulesAndCustomActions.customActions?.[0]?.actionName || '-',
          masks: item.ruleDefinition?.matchAttributes?.tcpFlags?.[0]?.masks || '-',
          flags: item.ruleDefinition?.matchAttributes?.tcpFlags?.[0]?.flags || '-',
        }));
      } else {
        mainTblRows = statefulRules.map((item: any) => ({
          id: _.uniqueId('rule-'),
          protocol: item.header?.protocol || 'All',
          source: item.header?.source || 'ANY',
          destination: item.header?.destination || 'ANY',
          sourcePort: item.header?.sourcePort || 'ANY',
          destinationPort: item.header?.destinationPort || 'ANY',
          direction: item.header?.direction || 'Forward',
          action: item.action || '-',
          keyword: item.ruleOptions?.[0] ? `${item.ruleOptions[0].keyword}:${item.ruleOptions[0].settings[0]}` : '-',
        }));

        const sids = statefulRules?.map(
          (rule: any) =>
            Number(rule?.ruleOptions?.find((option: any) => option?.keyword === 'sid')?.settings?.[0]) ?? 0,
        );

        const sidMax = sids.sort((a: number, b: number) => b - a)[0];
        setNextSid(sidMax + 1);
      }

      setMainTblRows(mainTblRows);
      setMainTblTotal({
        totalPage: Math.ceil(mainTblRows.length / 10),
        totalElement: mainTblRows.length,
      });
    } catch (error) {}
  };

  useEffect(() => {
    handleGetData();
  }, [isStateless, statelessRulesAndCustomActions, statefulRules]);

  const columns = useMemo((): ColumnType[] => {
    if (isStateless) {
      return [
        {
          label: 'Priority',
          field: 'priority',
        },
        {
          label: 'Protocol',
          field: 'protocol',
        },
        {
          label: 'Source',
          field: 'source',
        },
        {
          label: 'Destination',
          field: 'destination',
        },
        {
          label: 'Source port range',
          field: 'sourcePort',
        },
        {
          label: 'Destination port range',
          field: 'destinationPort',
        },
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'Custom Action',
          field: 'customAction',
        },
        {
          label: 'Masks',
          field: 'masks',
        },
        {
          label: 'Flags',
          field: 'flags',
        },
      ];
    }

    return [
      {
        label: 'Protocol',
        field: 'protocol',
      },
      {
        label: 'Source',
        field: 'source',
      },
      {
        label: 'Destination',
        field: 'destination',
      },
      {
        label: 'Source port',
        field: 'sourcePort',
      },
      {
        label: 'Destination port',
        field: 'destinationPort',
      },
      {
        label: 'Direction',
        field: 'direction',
      },
      {
        label: 'Action',
        field: 'action',
      },
      {
        label: 'Keyword',
        field: 'keyword',
      },
    ];
  }, [isStateless]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotal, tablePagination]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const deleteRuleDescription = useMemo(() => {
    const selectedRule = mainTblRows.find(row => row?.id === resourceSelected);
    return `Protocol: ${selectedRule?.protocol}
    Source: ${selectedRule?.source}, Port: ${selectedRule?.sourcePort}
    Destination: ${selectedRule?.destination}, Port: ${selectedRule?.destinationPort}
    Direction: ${_.startCase(_.lowerCase(selectedRule?.direction))}
    Action: ${_.startCase(_.lowerCase(selectedRule?.action))}
    Rule Option: ${selectedRule?.keyword}
    `;
  }, [mainTblRows, resourceSelected]);

  const onDeleteRule = useCallback(async () => {
    const selectedRule = mainTblRows.find(row => row?.id === resourceSelected);
    const sid = selectedRule?.keyword?.split(':')?.[1] ?? '';
    await handleDeleteRule(sid);
    setDeleteRuleModalVisible(false);
  }, [mainTblRows, resourceSelected]);

  return (
    <>
      <div className="detail-info">
        <div className="detail-info-title">
          <p>Rules</p>

          <div className="button-group">
            <Button label="Add rule" onClick={() => setAddRuleModalVisible(true)} />
            <Button label="Delete" disabled={!resourceSelected} onClick={() => setDeleteRuleModalVisible(true)} />
            <Button label="Edit" disabled={!resourceSelected} onClick={() => setEditRuleModalVisible(true)} />
          </div>
        </div>

        {isLoading ? (
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        ) : (
          <div className="detail-info-content">
            {!mainRowsCurrentPage.length && !isLoading ? (
              <div className="data-grid-wrap">
                <p className="empty-row">Empty</p>
              </div>
            ) : (
              <div className="data-grid-wrap">
                <Table
                  rows={mainRowsCurrentPage}
                  columns={columns}
                  sortOption={{
                    target: tablePagination.target,
                    direction: tablePagination.direction,
                    onChangeSort: (target: string, dir: OrderDirection) => {
                      updateTablePagination('target', target);
                      updateTablePagination('direction', dir);
                    },
                  }}
                  isLoading={isLoading}
                  horizontalScrollable={true}
                  reportCheckedList={() => {}}
                  reportSelected={setResourceSelected}
                />

                {mainRowsCurrentPage?.length && !isLoading ? (
                  <div className="fleet-instance pagination-wrapper flex a-center">
                    <p className="flex a-center">
                      Total <span>{mainTblTotal.totalElement}</span>
                    </p>

                    <TableManagePagination
                      ableFetchMore={false}
                      currentPage={tablePagination.currentPage}
                      updateCurrentPage={page => updateTablePagination('currentPage', page)}
                      totalPage={mainTblTotal.totalPage}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>

      {isEditRuleModalVisible && (
        <EditRuleModal
          enumType={'editRule'}
          header={'Edit rule'}
          open={isEditRuleModalVisible}
          onClose={() => setEditRuleModalVisible(false)}
          handleSaveRule={async (rule: any) => {
            await handleEditRule(rule);
            setEditRuleModalVisible(false);
          }}
          rule={mainTblRows.find(row => row?.id === resourceSelected)}
          nextSid={nextSid}
        />
      )}
      {isAddRuleModalVisible && (
        <EditRuleModal
          enumType={'addRule'}
          header={'Add new rule'}
          open={isAddRuleModalVisible}
          onClose={() => setAddRuleModalVisible(false)}
          handleSaveRule={async (rule: any) => {
            await handleAddRule(rule);
            setResourceSelected('');
            setAddRuleModalVisible(false);
          }}
          nextSid={nextSid}
        />
      )}
      {isDeleteRuleModalVisible && (
        <DeleteRuleModal
          open={isDeleteRuleModalVisible}
          onClose={() => setDeleteRuleModalVisible(false)}
          header="Delete rule"
          note={deleteRuleDescription}
          onDelete={onDeleteRule}
          titleWarning={`Are you sure you want to delete the following rule from the rule group: ${ruleGroupResponse?.ruleGroupName}`}
        />
      )}
    </>
  );
};

export default Rules;
