import { IMgdTabProps } from "layouts/v3/MgdTabLayout/types";

export enum PageNodeEnum {
  MGD_LIST = 'MGD_LIST',
  MGD_CREATE = 'MGD_CREATE',
  MGD_DETAIL = 'MGD_DETAIL',
}

export const LIST_UP_TAB_LIST: IMgdTabProps[] = [
  { id: 'firewall', name: 'Firewall', title: 'Firewalls' },
  { id: 'firewallpolicy', name: 'Firewall Policy', title: 'Firewall policies' },
  { id: 'rulegroup', name: 'Rule group', title: 'Firewall rule groups' },
  { id: 'awsrulegroup', name: 'AWS rule group', title: 'AWS Managed rule groups' },
  { id: 'tlsinspection', name: 'TLS inspection', title: 'TLS inspection configurations' },
  { id: 'resourcegroup', name: 'Resource group', title: 'Resource group' },
];
