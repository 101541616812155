import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CustomerKeyDetailPropsType } from './types';
import { AwsKeyMetadata } from 'graphql/types/AwsKey';
import { DETAIL_TAB_LIST, DETAIL_TEXT_DESCRIPTION } from './configs';
import { IMgdDetailKeyValueProps, IMgdTabProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import PageDetailTitle from '../../../components/PageDetailTitle';
import DetailTab from '../../../components/DetailTab';
import lazyGetAwsDescribeKey, { IAwsDescribeKeyVariables } from 'graphql/queries/getAwsDescribeKey';
import lazyGetAwsListAliases, { IAwsListAliasesVariables } from 'graphql/queries/getAwsListAliases';
import { AwsListAliasesResponse } from 'graphql/types/AwsListAliases';
import KeyPolicy from './Tabs/KeyPolicy';
import CryptoConfig from './Tabs/CryptoConfig';
import Tag from './Tabs/Tag';
import KeyRotation from './Tabs/KeyRotation';
import PublicKey from './Tabs/PublicKey';
import Regionality from './Tabs/Regionality';
import Aliases from './Tabs/Aliases';
import Button, { ButtonTypeEnum } from '../../../components/Button';
import { ButtonPropsType } from '../../../components/types';
import lazyGetAwsKeyPolicy, { IAwsKeyPolicyVariables } from 'graphql/queries/getAwsKeyPolicy';
import EditDescriptionModal from './Modals/EditDescriptionModal';
import updateKeyDescriptionMutation from 'graphql/mutations/updateKeyDescription';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import EditKeyPolicyModal from './Modals/EditKeyPolicyModal';
import putKeyPolicyMutation from 'graphql/mutations/putAwsKeyPolicy';
import UpdateTagsModal from './Modals/UpdateTagsModal';
import tagAwsResourcesKmsMutation from 'graphql/mutations/tagAwsResourceKms';
import lazyGetAwsListResourceTags, { IAwsListResourceTagsVariables } from 'graphql/queries/getAwsListResourceTags';
import { RowType } from '@Types/v2/Table';
import untagAwsResourcesKmsMutation from 'graphql/mutations/untagAwsResourceKms';
import EditKeyRotationModal from './Modals/EditKeyRotationModal';
import enableAwsKeyRotationMutation from 'graphql/mutations/enableAwsKeyRotation';
import disableAwsKeyRotationMutation from 'graphql/mutations/disableAwsKeyRotation';
import lazyGetAwsKeyRotationStatus from 'graphql/queries/getAwsKeyRotationStatus';
import lazyGetAwsListKeyRotations from 'graphql/queries/getAwsListKeyRotations';
import CreateAliasesModal from './Modals/CreateAliasesModal';
import createAwsAliasKmsMutation from 'graphql/mutations/createAwsAliasKms';
import DeleteAliasesModal from './Modals/DeleteAliasesModal';
import deleteAwsAliasKmsMutation from 'graphql/mutations/deleteAwsAliasKms';
import { DropdownListDataType } from 'components/Dropdown/types';
import DisableKeyModal from './Modals/DisableKeyModal';
import disableAwsKeyMutation from 'graphql/mutations/disableAwsKey';
import enableAwsKeyMutation from 'graphql/mutations/enableAwsKey';
import ScheduleKeyDeletionModal from './Modals/ScheduleKeyDeletionModal';
import scheduleAwsKeyDeletionMutation from 'graphql/mutations/scheduleAwsKeyDeletion';
import cancelAwsKeyDeletionMutation from 'graphql/mutations/cancelAwsKeyDeletion';
import ChangePrimaryRegionModal from './Modals/ChangePrimaryRegionModal';
import updateAwsPrimaryRegionMutation from 'graphql/mutations/updateAwsPrimaryRegion';

const CustomerKeyDetail = (props: CustomerKeyDetailPropsType) => {
  const { cloudId, region, customerKey, pageBackClick } = props;

  // API
  const [getAwsDescribeKey, { loading: getAwsDescribeKeyLoading }] = lazyGetAwsDescribeKey();
  const [getAwsListAliases, { loading: getAwsListAliasLoading }] = lazyGetAwsListAliases();
  const [getAwsKeyPolicy, { loading: keyPolicyLoading }] = lazyGetAwsKeyPolicy();
  const [updateKeyDescription, { loading: updateKeyDescriptionLoading }] = updateKeyDescriptionMutation();
  const [putKeyPolicy, { loading: putKeyPolicyLoading }] = putKeyPolicyMutation();
  const [tagAwsResources] = tagAwsResourcesKmsMutation();
  const [untagAwsResources] = untagAwsResourcesKmsMutation();
  const [getAwsListResourceTags, { loading: listTagLoading }] = lazyGetAwsListResourceTags();
  const [enableAwsKeyRotation, { loading: enableAwsKeyRotationLoading }] = enableAwsKeyRotationMutation();
  const [disableAwsKeyRotation, { loading: disableAwsKeyRotationLoading }] = disableAwsKeyRotationMutation();
  const [getAwsKeyRotationStatus, { loading: keyRotationStatusLoading }] = lazyGetAwsKeyRotationStatus();
  const [getAwsListKeyRotations, { loading: keyRotationLoading }] = lazyGetAwsListKeyRotations();
  const [createAwsAliasKms] = createAwsAliasKmsMutation();
  const [deleteAwsAliasKms] = deleteAwsAliasKmsMutation();
  const [disableAwsKey] = disableAwsKeyMutation();
  const [enableAwsKey] = enableAwsKeyMutation();
  const [scheduleAwsKeyDeletion] = scheduleAwsKeyDeletionMutation();
  const [cancelAwsKeyDeletion] = cancelAwsKeyDeletionMutation();
  const [updateAwsPrimaryRegion] = updateAwsPrimaryRegionMutation();

  // State
  const [detailTabList, setDetailTabList] = useState<IMgdTabProps[]>(DETAIL_TAB_LIST);
  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>(detailTabList[0]);
  const [describeKey, setDescribeKey] = useState<AwsKeyMetadata>();
  const [aliasEntry, setAliasEntry] = useState<AwsListAliasesResponse>();
  const [policyString, setPolicyString] = useState<string>();
  const [isEditKeyPolicyModalVisible, setEditKeyPolicyModalVisible] = useState(false);
  const [isEditTagModalVisible, setEditTagModalVisible] = useState(false);
  const [isEditKeyRotationModalVisible, setEditKeyRotationModalVisible] = useState(false);
  const [isEditPublicKeyModalVisible, setEditPublicKeyModalVisible] = useState(false);
  const [isRegionalityModalVisible, setRegionalityModalVisible] = useState(false);
  const [isEditAliasesModalVisible, setEditAliasesModalVisible] = useState(false);
  const [isDeleteAliasesModalVisible, setDeleteAliasesModalVisible] = useState(false);
  const [isEditDescriptionModalVisible, setEditDescriptionModalVisible] = useState(false);
  const [isDisableKeyModalVisible, setDisableKeyModalVisible] = useState(false);
  const [isScheduleDeletionModalVisible, setScheduleDeletionModalVisible] = useState(false);
  const [tags, setTags] = useState<RowType[]>([]);
  const [rotationContent, setRotationContent] = useState<IMgdDetailKeyValueProps[]>([]);
  const [selectedAlias, setSelectedAlias] = useState('');
  const [checkedAliases, setCheckedAliases] = useState<Array<string>>([]);

  const keyPolicyVariable = useMemo((): IAwsKeyPolicyVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        keyId: customerKey?.id ?? '',
      },
    };
  }, [cloudId, region, customerKey]);

  const fetchKeyPolicy = useCallback(() => {
    getAwsKeyPolicy({ variables: keyPolicyVariable }).then(({ data: awsKeyPolicyResponse }) => {
      const awsKeyPolicyData = awsKeyPolicyResponse?.getAwsKeyPolicy?.data?.[0];
      if (awsKeyPolicyData) {
        setPolicyString(awsKeyPolicyData?.policy);
      }
    });
  }, [keyPolicyVariable]);

  const reqVariable = useMemo((): IAwsListResourceTagsVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: { keyId: customerKey.keyId },
    };
  }, [cloudId, region, customerKey]);

  const fetchResourceTags = useCallback(async () => {
    getAwsListResourceTags({ variables: reqVariable }).then(({ data: responseData }) => {
      const _tags = responseData?.getAwsListResourceTags?.data?.[0]?.tags;
      if (_tags) {
        const rows: RowType[] = [];
        _tags.map(item => {
          rows.push({
            key: item.key,
            value: item.value,
          });
        });
        setTags(_tags);
      }
    });
  }, [reqVariable]);

  const fetchKeyRotation = useCallback(async () => {
    const keyRotationStatus = await getAwsKeyRotationStatus({ variables: reqVariable }).then(
      ({ data: awsKeyRotationStatus }) => {
        return awsKeyRotationStatus?.getAwsKeyRotationStatus?.data?.[0];
      },
    );
    let lastDateRotation = '-';
    if (keyRotationStatus && keyRotationStatus.keyRotationEnabled) {
      const keyRotations = await getAwsListKeyRotations({ variables: reqVariable }).then(({ data: awsKeyRotation }) => {
        return awsKeyRotation?.getAwsListKeyRotations?.data?.[0].rotations;
      });
      lastDateRotation = keyRotations?.[0]?.rotationDate || '-';
    }

    setRotationContent([
      {
        id: 'status',
        type: TextTypeEnum.STATUS,
        title: 'Status',
        description: keyRotationStatus?.keyRotationEnabled ? 'Enabled' : 'Disabled',
      },
      {
        id: 'rotationPeriod',
        type: TextTypeEnum.NORMAL,
        title: 'Rotation period',
        description: keyRotationStatus?.rotationPeriodInDays || '-',
      },
      {
        id: 'lastDateRotation',
        type: TextTypeEnum.NORMAL,
        title: 'Date of last automatic rotation',
        description: lastDateRotation,
      },
      {
        id: 'nextRotationDate',
        type: TextTypeEnum.NORMAL,
        title: 'Next rotation date',
        description: keyRotationStatus?.nextRotationDate || '-',
      },
    ]);
  }, []);

  useEffect(() => {
    fetchKeyPolicy();
    fetchResourceTags();
    fetchKeyRotation();
  }, []);

  const onEditKeyPolicyClicked = useCallback(() => {
    setEditKeyPolicyModalVisible(true);
  }, []);
  const onEditTagClicked = useCallback(() => {
    setEditTagModalVisible(true);
  }, []);
  const onEditKeyRotationClicked = useCallback(() => {
    setEditKeyRotationModalVisible(true);
  }, []);
  const onEditPublicKeyClicked = useCallback(() => {}, []);
  const onEditRegionalityClicked = useCallback(() => {
    setRegionalityModalVisible(true);
  }, []);
  const onEditAliasesClicked = useCallback(() => {
    setEditAliasesModalVisible(true);
  }, []);
  const onDeleteAliasesClicked = () => {
    setDeleteAliasesModalVisible(true);
  };

  const keyPolicyActionButtons = useMemo((): Array<ButtonPropsType> => {
    return [
      {
        label: 'Edit',
        onClick: onEditKeyPolicyClicked,
      },
    ];
  }, [onEditKeyPolicyClicked]);

  const tagActionButtons = useMemo((): Array<ButtonPropsType> => {
    return [
      {
        label: 'Edit',
        onClick: onEditTagClicked,
      },
    ];
  }, [onEditTagClicked]);

  const keyRotationActionButtons = useMemo((): Array<ButtonPropsType> => {
    return [
      {
        label: 'Edit',
        onClick: onEditKeyRotationClicked,
      },
    ];
  }, [onEditKeyRotationClicked]);

  const publicKeyActionButtons = useMemo((): Array<ButtonPropsType> => {
    return [
      {
        label: 'Edit',
        onClick: onEditPublicKeyClicked,
      },
    ];
  }, [onEditPublicKeyClicked]);

  const regionalityActionButtons = useMemo((): Array<ButtonPropsType> => {
    return [
      {
        label: 'Edit',
        onClick: onEditRegionalityClicked,
      },
    ];
  }, [onEditRegionalityClicked]);

  const aliasesActionButtons = useMemo((): Array<ButtonPropsType> => {
    return [
      {
        label: 'Delete',
        onClick: onDeleteAliasesClicked,
        disabled: checkedAliases.length === 0,
      },
      {
        label: 'Create aliases',
        onClick: onEditAliasesClicked,
      },
    ];
  }, [onEditAliasesClicked, checkedAliases]);

  const handleEditDescription = useCallback(
    async (description: string) => {
      const variables = {
        cloudId: cloudId,
        region: region,
        reqData: {
          description: description,
          keyId: customerKey?.id ?? '',
        },
      };
      await updateKeyDescription({ variables })
        .then(() => {
          useToast(ErrorCode.SUCCESS, 'Update description successfully.');
        })
        .catch(error => {
          useToast(ErrorCode.UNKNOWN, error?.message ?? 'Update failed.');
        });

      fetchAwsDescribeKey();
      setEditDescriptionModalVisible(false);
    },
    [cloudId, region, customerKey],
  );

  const handleEditKeyRotation = useCallback(
    async (keyRotation: boolean, period: string) => {
      const variables = {
        cloudId: cloudId,
        region: region,
        reqData: {
          keyId: customerKey?.id ?? '',
        },
      };

      if (keyRotation) {
        await enableAwsKeyRotation({
          variables: {
            cloudId: cloudId,
            region: region,
            reqData: {
              keyId: customerKey?.id ?? '',
              rotationPeriodInDays: Number(period) ?? 90,
            },
          },
        })
          .then(() => {
            useToast(ErrorCode.SUCCESS, 'Enable key rotation successfully.');
          })
          .catch(error => {
            useToast(ErrorCode.UNKNOWN, error?.message ?? 'Enable key rotation failed.');
          });
      } else {
        await disableAwsKeyRotation({
          variables: {
            cloudId: cloudId,
            region: region,
            reqData: {
              keyId: customerKey?.id ?? '',
            },
          },
        })
          .then(() => {
            useToast(ErrorCode.SUCCESS, 'Disable key rotation successfully.');
          })
          .catch(error => {
            useToast(ErrorCode.UNKNOWN, error?.message ?? 'Disable key rotation failed.');
          });
      }

      fetchKeyRotation();
      setEditKeyRotationModalVisible(false);
    },
    [cloudId, region, customerKey],
  );

  const handleEditKeyPolicy = useCallback(
    async (policy: string) => {
      const variables = {
        cloudId: cloudId,
        region: region,
        reqData: {
          keyId: customerKey?.id ?? '',
          policy: policy,
          policyName: 'default',
        },
      };
      await putKeyPolicy({ variables })
        .then(() => {
          useToast(ErrorCode.SUCCESS, 'Update key policy successfully.');
        })
        .catch(error => {
          useToast(ErrorCode.UNKNOWN, error?.message ?? 'Update key policy failed.');
        });

      fetchAwsDescribeKey();
      setEditKeyPolicyModalVisible(false);
    },
    [cloudId, region, customerKey],
  );

  const handleEditTags = useCallback(
    async (editedTags: Array<RowType>) => {
      try {
        await untagAwsResources({
          variables: {
            cloudId: cloudId,
            region: region,
            reqData: {
              keyId: customerKey?.id ?? '',
              tagKeys: tags.map(tag => tag?.key),
            },
          },
        });

        await tagAwsResources({
          variables: {
            cloudId: cloudId,
            region: region,
            reqData: {
              keyId: customerKey?.id ?? '',
              tags: editedTags?.map(tag => ({
                tagKey: tag?.valueKey,
                tagValue: tag?.valueOptional,
              })),
            },
          },
        });

        setEditTagModalVisible(false);
        await fetchResourceTags();

        useToast(ErrorCode.SUCCESS, 'Update tags successfully.');
      } catch (error) {
        useToast(ErrorCode.UNKNOWN, 'Update tags failed.');
      }
    },
    [cloudId, region, customerKey, tags, untagAwsResources],
  );

  const handleCreateAlias = useCallback(
    async (alias: string) => {
      await createAwsAliasKms({
        variables: {
          cloudId: cloudId,
          region: region,
          reqData: {
            targetKeyId: customerKey?.id ?? '',
            aliasName: alias,
          },
        },
      })
        .then(() => {
          useToast(ErrorCode.SUCCESS, 'Create alias successfully.');
          setEditAliasesModalVisible(false);
          fetchAwsAliasEntry();
        })
        .catch(() => {
          useToast(ErrorCode.UNKNOWN, 'Create alias failed.');
        });
    },
    [cloudId, region, customerKey],
  );

  const handleDeleteAliases = useCallback(async () => {
    try {
      await Promise.all(
        checkedAliases.map(alias => {
          return deleteAwsAliasKms({
            variables: {
              cloudId: cloudId,
              region: region,
              reqData: {
                aliasName: alias,
              },
            },
          });
        }),
      );
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Delete aliases failed.');
    }

    useToast(ErrorCode.SUCCESS, 'Delete aliases successfully.');
    setDeleteAliasesModalVisible(false);
    setCheckedAliases([]);
    fetchAwsAliasEntry();
  }, [cloudId, region, customerKey, checkedAliases]);

  const handleEnableKey = useCallback(async () => {
    await enableAwsKey({
      variables: {
        cloudId: cloudId,
        region: region,
        reqData: {
          keyId: customerKey?.keyId ?? '',
        },
      },
    })
      .then(() => {
        setTimeout(() => {
          useToast(ErrorCode.SUCCESS, 'Enable key successfully.');
          fetchAwsDescribeKey();
          setDisableKeyModalVisible(false);
        }, 500);
      })
      .catch(() => {
        useToast(ErrorCode.UNKNOWN, 'Enable key failed.');
      });
  }, [cloudId, region, customerKey]);

  const handleDisableKey = useCallback(async () => {
    await disableAwsKey({
      variables: {
        cloudId: cloudId,
        region: region,
        reqData: {
          keyId: customerKey?.keyId ?? '',
        },
      },
    })
      .then(() => {
        useToast(ErrorCode.SUCCESS, 'Disable key successfully.');
        fetchAwsDescribeKey();
        setDisableKeyModalVisible(false);
      })
      .catch(() => {
        useToast(ErrorCode.UNKNOWN, 'Disable key failed.');
      });
  }, [cloudId, region, customerKey]);

  const handleScheduleDeletion = useCallback(
    async (period: string) => {
      await scheduleAwsKeyDeletion({
        variables: {
          cloudId: cloudId,
          region: region,
          reqData: {
            keyId: customerKey?.keyId ?? '',
            pendingWindowInDays: Number(period) ?? 30,
          },
        },
      })
        .then(() => {
          useToast(ErrorCode.SUCCESS, 'Schedule deletion successfully.');
          fetchAwsDescribeKey();
          setScheduleDeletionModalVisible(false);
        })
        .catch(() => {
          useToast(ErrorCode.UNKNOWN, 'Schedule deletion failed.');
        });
    },
    [cloudId, region, customerKey],
  );

  const handleChangeRegion = useCallback(
    async (region: string) => {
      await updateAwsPrimaryRegion({
        variables: {
          cloudId: cloudId,
          region: region,
          reqData: {
            keyId: customerKey?.keyId ?? '',
            primaryRegion: region,
          },
        },
      })
        .then(() => {
          useToast(ErrorCode.SUCCESS, 'Change primary region successfully.');
          fetchAwsDescribeKey();
          setRegionalityModalVisible(false);
        })
        .catch(() => {
          useToast(ErrorCode.UNKNOWN, 'Change primary region failed.');
        });
    },
    [cloudId, region, customerKey],
  );

  const handleCancelKeyDeletion = useCallback(async () => {
    await cancelAwsKeyDeletion({
      variables: {
        cloudId: cloudId,
        region: region,
        reqData: {
          keyId: customerKey?.keyId ?? '',
        },
      },
    })
      .then(() => {
        useToast(ErrorCode.SUCCESS, 'Cancel deletion successfully.');
        fetchAwsDescribeKey();
        setScheduleDeletionModalVisible(false);
      })
      .catch(() => {
        useToast(ErrorCode.UNKNOWN, 'Cancel deletion failed.');
      });
  }, [cloudId, region, customerKey]);

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    if (customerKey) {
      return DETAIL_TEXT_DESCRIPTION.map(item => {
        let description = '';
        if (item.id === 'name') {
          description = aliasEntry?.aliases?.[0]?.aliasName || '-';
        } else if (item.id === 'status') {
          description = describeKey?.keyState || '-';
        } else if (item.id === 'createDate') {
          description = describeKey?.creationDate || '-';
        } else if (item.id === 'arn') {
          description = describeKey?.arn || '-';
        } else if (item.id === 'description') {
          description = describeKey?.description || '-';
        } else {
          description = describeKey?.multiRegion
            ? `Multi Region ${describeKey?.multiRegionConfiguration.multiRegionKeyType}`
            : 'Single Region';
        }
        return {
          id: item.id,
          type: item.type as TextTypeEnum,
          description: description,
          title: item.value,
        };
      });
    }
    return [];
  }, [customerKey, aliasEntry, describeKey]);

  const detailContentNode = useMemo((): ReactNode => {
    switch (detailTabSelected) {
      default:
      case DETAIL_TAB_LIST[0]:
        return (
          <KeyPolicy
            cloudId={cloudId}
            region={region}
            customerKey={customerKey}
            actionButtons={keyPolicyActionButtons}
          />
        );
      case DETAIL_TAB_LIST[1]:
        if (describeKey) {
          return <CryptoConfig metaData={describeKey} />;
        }
        return null;
      case DETAIL_TAB_LIST[2]:
        return (
          <Tag
            cloudId={cloudId}
            region={region}
            customerKey={customerKey}
            actionButtons={tagActionButtons}
            tags={tags ?? []}
          />
        );
      case DETAIL_TAB_LIST[3]:
        return (
          <KeyRotation
            cloudId={cloudId}
            region={region}
            customerKey={customerKey}
            actionButtons={keyRotationActionButtons}
            rotationContent={rotationContent}
            loading={keyRotationStatusLoading || keyRotationLoading}
          />
        );
      case DETAIL_TAB_LIST[4]:
        return (
          <PublicKey
            cloudId={cloudId}
            region={region}
            customerKey={customerKey}
            actionButtons={publicKeyActionButtons}
          />
        );
      case DETAIL_TAB_LIST[5]:
        if (describeKey) {
          return <Regionality metaData={describeKey} actionButtons={regionalityActionButtons} />;
        }
        return null;
      case DETAIL_TAB_LIST[6]:
        return (
          <Aliases
            cloudId={cloudId}
            region={region}
            customerKey={customerKey}
            actionButtons={aliasesActionButtons}
            aliases={aliasEntry?.aliases ?? []}
            loading={getAwsListAliasLoading}
            setSelectedAlias={setSelectedAlias}
            selectedAlias={selectedAlias}
            checkedAliases={checkedAliases}
            setCheckedAliases={setCheckedAliases}
          />
        );
    }
  }, [
    detailTabList,
    detailTabSelected,
    cloudId,
    region,
    describeKey,
    customerKey,
    keyPolicyActionButtons,
    tagActionButtons,
    keyRotationActionButtons,
    publicKeyActionButtons,
    regionalityActionButtons,
    aliasesActionButtons,
    tags,
    rotationContent,
    keyRotationStatusLoading,
    keyRotationLoading,
    aliasEntry,
    getAwsListAliasLoading,
    selectedAlias,
    checkedAliases,
  ]);

  const describeVariable = useMemo((): IAwsDescribeKeyVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        keyId: customerKey?.id ?? '',
      },
    };
  }, [cloudId, region, customerKey]);

  const fetchAwsDescribeKey = useCallback(() => {
    getAwsDescribeKey({ variables: describeVariable }).then(({ data: describeKeyResponse }) => {
      setDescribeKey(describeKeyResponse?.getAwsDescribeKey?.data?.[0]?.keyMetadata);
    });
  }, [describeVariable]);

  const aliasReq = useMemo((): IAwsListAliasesVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: { keyId: customerKey.keyId },
    };
  }, [cloudId, region, customerKey]);

  const fetchAwsAliasEntry = useCallback(() => {
    getAwsListAliases({ variables: aliasReq }).then(({ data: aliasReponse }) => {
      setAliasEntry(aliasReponse?.getAwsListAliases?.data?.[0]);
    });
  }, [aliasReq]);

  useEffect(() => {
    fetchAwsDescribeKey();
    fetchAwsAliasEntry();

    const tabList = [...DETAIL_TAB_LIST];
    if (customerKey.keyType === 'Asymmetric') {
      tabList.splice(3, 1);
    } else {
      tabList.splice(4, 1);
    }
    if (!customerKey.isMultiRegion) {
      tabList.splice(4, 1);
    } else {
      tabList.splice(3, 1);
    }
    setDetailTabList(tabList);
  }, [customerKey]);

  const onEditConfigurationClicked = useCallback(() => {
    setEditDescriptionModalVisible(true);
  }, []);

  const onKeyActionsDropDownChanged = useCallback((item: DropdownListDataType) => {
    if (item.value === 'disable') {
      setDisableKeyModalVisible(true);
    }

    if (item.value === 'scheduleKeyDeletion') {
      setScheduleDeletionModalVisible(true);
    }

    if (item.value === 'enable') {
      handleEnableKey();
    }

    if (item.value === 'cancelKeyDeletion') {
      handleCancelKeyDeletion();
    }
  }, []);

  const keyActions = useMemo(() => {
    let buttons = [
      { name: describeKey?.enabled ? 'Disable' : 'Enable', value: describeKey?.enabled ? 'disable' : 'enable' },
      { name: 'Schedule key deletion', value: 'scheduleKeyDeletion' },
    ];

    if (describeKey?.keyState === 'PendingDeletion') {
      buttons = [{ name: 'Cancel key deletion', value: 'cancelKeyDeletion' }];
    }

    return (
      <div className="button-group">
        <Button
          type={ButtonTypeEnum.DROPDOWN}
          label="Key actions"
          onClick={() => {}}
          dropdownList={buttons}
          dropdownValue={{ name: 'Key actions', value: 'keyActions' }}
          onDropdownValueChanged={onKeyActionsDropDownChanged}
        />
        <Button
          label="Edit"
          onClick={onEditConfigurationClicked}
          disabled={describeKey?.keyState === 'PendingDeletion'}
        />
      </div>
    );
  }, [describeKey]);

  return (
    <div className="ec2-detail">
      <PageDetailTitle title={`${customerKey?.name}`} pageBackClick={pageBackClick} />

      {getAwsDescribeKeyLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <Fragment>
          {!describeKey ? (
            <div>
              <p>You do not have permission to access the KMS keys</p>
            </div>
          ) : (
            <Fragment>
              {customerKey && (
                <Fragment>
                  <DetailTab
                    title={'General configuration'}
                    data={detailTabContentData}
                    isApiLoading={false}
                    actions={keyActions}
                  />

                  <div className="tab-container">
                    <div className="detail-tab flex a-center">
                      {detailTabList.map(tab => {
                        return (
                          <button
                            className={`detail-tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                            key={tab.id}
                            data-tab={tab.id}
                            onClick={e => setDetailTabSelected(tab)}
                          >
                            <p>{tab.name}</p>
                          </button>
                        );
                      })}
                    </div>

                    <div className="content-tab">{detailContentNode}</div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
      <EditDescriptionModal
        alias={aliasEntry?.aliases?.[0]?.aliasName || '-'}
        description={describeKey?.description ?? ''}
        open={isEditDescriptionModalVisible}
        onClose={() => setEditDescriptionModalVisible(false)}
        onSave={handleEditDescription}
      />
      <EditKeyPolicyModal
        policyString={policyString ?? ''}
        onClose={() => setEditKeyPolicyModalVisible(false)}
        onSave={handleEditKeyPolicy}
        open={isEditKeyPolicyModalVisible}
      />
      {isEditTagModalVisible && (
        <UpdateTagsModal
          header={'Edit tags'}
          subHeader={'Edit firewall tags'}
          open={isEditTagModalVisible}
          onClose={() => setEditTagModalVisible(false)}
          currentDatas={tags.map((tag, index) => ({
            index,
            valueKey: tag?.key ?? '',
            valueOptional: tag?.value ?? '',
          }))}
          columns={['Tag key', 'Tag value']}
          onSave={handleEditTags}
          note={`You can add up to <limit> more tags.`}
        />
      )}
      {isEditKeyRotationModalVisible && (
        <EditKeyRotationModal
          period={rotationContent?.[1]?.description ?? ''}
          keyRotation={rotationContent?.[0]?.description === 'Enabled'}
          open={isEditKeyRotationModalVisible}
          onClose={() => setEditKeyRotationModalVisible(false)}
          onSave={handleEditKeyRotation}
        />
      )}
      {isEditAliasesModalVisible && (
        <CreateAliasesModal
          arn={describeKey?.arn ?? ''}
          alias=""
          open={isEditAliasesModalVisible}
          onClose={() => setEditAliasesModalVisible(false)}
          onSave={handleCreateAlias}
        />
      )}
      {isDeleteAliasesModalVisible && (
        <DeleteAliasesModal
          aliases={checkedAliases}
          keyId={customerKey?.keyId}
          open={isDeleteAliasesModalVisible}
          onClose={() => setDeleteAliasesModalVisible(false)}
          onSave={handleDeleteAliases}
        />
      )}
      {isDisableKeyModalVisible && (
        <DisableKeyModal
          aliases={aliasEntry?.aliases?.map(alias => alias?.aliasName)}
          keyId={customerKey?.keyId}
          open={isDisableKeyModalVisible}
          onClose={() => setDisableKeyModalVisible(false)}
          onSave={handleDisableKey}
        />
      )}
      {isScheduleDeletionModalVisible && (
        <ScheduleKeyDeletionModal
          aliases={aliasEntry?.aliases?.map(alias => alias?.aliasName)}
          keyId={customerKey?.keyId}
          describeKey={describeKey}
          open={isScheduleDeletionModalVisible}
          onClose={() => setScheduleDeletionModalVisible(false)}
          onSave={handleScheduleDeletion}
        />
      )}
      {isRegionalityModalVisible && (
        <ChangePrimaryRegionModal
          open={isRegionalityModalVisible}
          onClose={() => setRegionalityModalVisible(false)}
          onSave={handleChangeRegion}
          describeKey={describeKey}
        />
      )}
    </div>
  );
};

export default CustomerKeyDetail;
