import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsCreateFirewallRequestType, AwsCreateFirewallResponseType } from 'graphql/types/AwsCreateFirewall';

export interface ICreateAwsFirewallVariables {
  reqData: AwsCreateFirewallRequestType;
  cloudId: number;
  region: string;
}

export interface ICreateAwsFirewallResponseData {
  createAwsFirewall: IGqlResponseNewData<AwsCreateFirewallResponseType>;
}

const createAwsFirewallMutation = () => useMutation<ICreateAwsFirewallResponseData, ICreateAwsFirewallVariables>(query);
export default createAwsFirewallMutation;