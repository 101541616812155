import React, { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IMgdDetailKeyValueProps, IMgdTabProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import './ImageDetail.scss';
import { AwsBlockDeviceMappingType } from 'graphql/types/AwsImage';
import { ColumnType, RowType } from '@Types/v2/Table';
import { ImageDetailPropsType } from '../types';
import { DETAIL_TAB_LIST, detailTabKeys, detailTabTextDes } from './Common/Constants';
import Permission from './components/Permission';
import Storage from './components/Storage';
import TagTab from '../TagTab';
import PageDetailTitle from '../PageDetailTitle';
import DetailTab from '../DetailTab';
import { getFormatedDateWithTimezone } from 'utils/Common';

const ImageDetail = (props: ImageDetailPropsType) => {
  const { detailData, cloudId, region, pageBackClick } = props;

  // State
  const [apiIsLoading, setApiIsLoading] = useState<boolean>(false);
  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>(DETAIL_TAB_LIST[0]);

  const imageId = useMemo((): string => {
    return detailData?.imageId ?? '';
  }, [detailData]);

  const getBlockDeviceMapping = useCallback((blockDeviceMappings: AwsBlockDeviceMappingType[]): string[] => {
    const result = blockDeviceMappings.map(block => {
      const ebs = block?.ebs;
      if (!!ebs) {
        const { snapshotId, volumeSize, deleteOnTermination, volumeType } = ebs;
        return `${block.deviceName}=${snapshotId}:${volumeSize}:${deleteOnTermination}:${volumeType}`;
      }
      return '';
    });
    return result;
  }, []);

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    const detailTabData: IMgdDetailKeyValueProps[] = [];
    const detailTabDataOrdered: IMgdDetailKeyValueProps[] = [];
    if (detailData) {
      Object.entries(detailData).map(([key, value]) => {
        if (detailTabKeys.indexOf(key) > -1) {
          const textDes = detailTabTextDes.find(text => text.id == key);
          let desValue: string | string[] = '-';

          if (typeof value === 'string') {
            desValue = value.toString();

            if (key === detailTabKeys[0]) desValue += ` (${detailData.name})`;

            if (key === detailTabKeys[14] || key === detailTabKeys[20])
              desValue = getFormatedDateWithTimezone(desValue);
          }
          if (key === detailTabKeys[13]) {
            desValue = detailData?.stateReason?.message ?? '-';
          }

          if (key === detailTabKeys[17]) {
            desValue = detailData?.productCodes?.[0].productCodeId ?? '-';
          }

          if (key === detailTabKeys[21]) {
            desValue = getBlockDeviceMapping(detailData.blockDeviceMappings).filter(item => item !== '');
          }

          // TODO: deregistrationProtection and lastLaunchTime

          if (textDes) {
            detailTabData.push({
              id: key,
              type: textDes.type as TextTypeEnum,
              title: textDes.value,
              description: desValue,
            });
          }
        }
      });

      // order data
      detailTabKeys.map(key => {
        const dataByKey = detailTabData.find(data => data.id == key);
        if (dataByKey) detailTabDataOrdered.push(dataByKey);
      });
    }
    return detailTabDataOrdered;
  }, [detailData]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return detailData?.tags ?? [];
  }, [detailData]);

  const detailContentNode = useMemo((): ReactNode => {
    switch (detailTabSelected) {
      default:
      case DETAIL_TAB_LIST[0]:
        return <Permission imageId={imageId} cloudId={cloudId} region={region} />;
      case DETAIL_TAB_LIST[1]:
        return <Storage data={detailData} />;
      case DETAIL_TAB_LIST[2]:
        return <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} />;
    }
  }, [detailTabSelected, detailTabContentData, apiIsLoading, imageId, detailData, tagRows, tagColumns]);

  useEffect(() => {
    setDetailTabSelected(DETAIL_TAB_LIST[0]);
  }, [detailData]);

  return (
    <div className="ec2-detail">
      <PageDetailTitle title={imageId} pageBackClick={pageBackClick} />
      {detailData && (
        <Fragment>
          <DetailTab title={'Description'} data={detailTabContentData} isApiLoading={apiIsLoading} />
          <div className="tab-container">
            <div className="detail-tab flex a-center">
              {DETAIL_TAB_LIST.map(tab => {
                return (
                  <button
                    className={`detail-tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                    key={tab.id}
                    data-tab={tab.id}
                    onClick={e => setDetailTabSelected(tab)}
                  >
                    <p>{tab.name}</p>
                  </button>
                );
              })}
            </div>

            <div className="content-tab">{detailContentNode}</div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ImageDetail;
