import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconEditRuleGroup from 'assets/svgs/v3/ico_policy_variables.svg';
import IconInfo from 'assets/svgs/v4/ico_info.svg';

import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import Dropdown from 'components/molecules/Dropdown';
import { AwsKeyMetadata } from 'graphql/types/AwsKey';
import { DropdownListDataType } from 'components/molecules/Dropdown/types';

type ChangePrimaryRegionModalPropsType = {
  onSave?: (description: string) => void;
  alias?: string;
  placeholderDescription?: string;
  description?: string;
  describeKey?: AwsKeyMetadata;
} & IBaseModalProps;

const ChangePrimaryRegionModal = (props: ChangePrimaryRegionModalPropsType) => {
  const { alias, placeholderDescription, description, describeKey, onSave, ...baseModalProps } = props;
  const [region, setRegion] = useState<string>('');
  const [regions, setRegions] = useState<Array<DropdownListDataType>>([]);

  useEffect(() => {
    if (baseModalProps.open) {
      setRegions(
        describeKey?.multiRegionConfiguration?.replicaKeys?.map(key => ({
          name: key?.region ?? '',
          value: key?.region ?? '',
        })) ?? [],
      );
    }
  }, [baseModalProps.open, describeKey]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditRuleGroup} />
          {'Change primary Region'}
        </>
      )}
      {...baseModalProps}
    >
      <div className="modal-content-container">
        <p className="title">{'Choose a Region'}</p>
        <p className="description">{'The related replica key in the chosen Region will become the primary key.'}</p>
        <div className="input">
          <Dropdown
            data={regions}
            id="region-dropdown"
            handleClick={item => setRegion(item?.value?.toString() ?? '')}
            placeholder="Choose a region"
            value={{
              value: region,
              name: region,
            }}
          />
        </div>
        <div className="notice-container">
          <Icon width={24} height={24} src={IconInfo} />
          <p>
            This action might take a few moments to complete. During this time, the key state of the new and former
            primary keys is Updating.
          </p>
        </div>

        <div className="button-group">
          <Button type={ButtonTypeEnum.GENERAL} onClick={() => baseModalProps?.onClose?.()} label="Cancel" />
          <Button
            type={ButtonTypeEnum.PRIMARY}
            onClick={() => onSave?.(region)}
            label="Change primary Region"
            disabled={!region}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default ChangePrimaryRegionModal;
