import { ErrorCode } from '@Types/error';
import CopyableText from 'components/v3/CopyableText';
import { useToast } from 'hooks/v2/useToast';

const AmazonSnsTopic = ({ data, isLoading }: any) => {
  const { ruleGroupResponse } = data || {};

  const { snsTopic } = ruleGroupResponse || {};

  const copyToClipBoard = () => {
    if (!snsTopic) return;

    navigator.clipboard
      .writeText(snsTopic)
      .then(() => {
        useToast(ErrorCode.SUCCESS, 'Copied.');
      })
      .catch(() => {
        useToast(ErrorCode.SUCCESS, 'Copy to clipboard failed.');
      });
  };

  return (
    <div className="detail-info amazon-sns-topic-detail">
      <div className="detail-info-title">
        <div>
          <p>Amazon SNS topic</p>
          <p>
            Subscribe to Amazon SNS change notifications about this rule group. Copy the ARN, and use it as the Topic
            ARN when you Crete a subscription in the SNS console.
          </p>
        </div>
      </div>

      {isLoading ? (
        <div className="detail-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <div className="content-container">
          <div className="content-item-container">{snsTopic ? <CopyableText text={snsTopic} /> : '-'}</div>

          <button className="button" disabled={!snsTopic} onClick={copyToClipBoard}>
            <p>Copy ARN</p>
          </button>
        </div>
      )}
    </div>
  );
};

export default AmazonSnsTopic;
