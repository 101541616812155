import { gql } from '@apollo/client';

const query = gql`
  mutation untagAwsResourceMacie($request: AwsUntagResourceMacieRequest, $cloudId: Long, $region: String) {
    untagAwsResourceMacie(awsUntagResourceMacieRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        __typename
      }
    }
  }
`;

export default query;
