import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { AwsTLSInspectionConfigurationMetadata } from 'graphql/types/AwsTLSInspectionConfigurationMetadata';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsListTlsInspectionConfigurationsVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults?: number;
    nextToken?: string;
    pageInfo?: Paging;
  }
}

export interface IAwsListTlsInspectionConfigurationsResponseData {
  getAwsAllListTLSInspectionConfigurations: IGqlResponseNewData<AwsTLSInspectionConfigurationMetadata> ;
}

const lazyGetAwsListTlsInspectionConfigurationsPage = () => useLazyQuery<IAwsListTlsInspectionConfigurationsResponseData, IAwsListTlsInspectionConfigurationsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListTlsInspectionConfigurationsPage;