import { gql } from '@apollo/client';

const query = gql`
  mutation updateAwsRuleGroupFirewall($request: AwsUpdateRuleGroupFirewallRequest, $cloudId: Long, $region: String) {
    updateAwsRuleGroupFirewall(awsUpdateRuleGroupFirewallRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsUpdateRuleGroupFirewallResponse {
          updateToken
          ruleGroupResponse {
            ruleGroupArn
            ruleGroupName
            ruleGroupId
            description
            type
            capacity
            ruleGroupStatus
            tags {
              key
              value
            }
            consumedCapacity
            numberOfAssociations
            encryptionConfiguration {
              keyId
              type
            }
            sourceMetadata {
              keyId
              type
            }
            snsTopic
            lastModifiedTime
            analysisResults {
              identifiedRuleIds
              identifiedType
              analysisDetail
            }
          }
        }
      }
    }
  }
`;
export default query;
