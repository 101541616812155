import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { CustomerKeyListPropsType } from "../types";
import { ColumnType, OrderDirection, RowType } from "@Types/v2/Table";
import { CUSTOMER_KEY_FILTER_DROPDOWN } from "./configs";
import { DropdownListDataType } from "components/Dropdown/types";
import { FilterPropsType } from "../../../EC2/components/Image/types";
import { IMgdTablePaginationProps, IMgdTotalPageProps } from "layouts/v3/MgdLayout";
import { handleFormatText } from "utils/Common";
import { orderAlphabetical } from "../../../Utils/Sorting";
import { variableCombineNextToken } from "../../../Utils";
import lazyGetAwsAllListAliasesPage, { IAwsAllListAliasesVariables } from "graphql/queries/getAwsAllListAliases";
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import lazyGetAwsDescribeKey from "graphql/queries/getAwsDescribeKey";
import DropdownAtom from "components/v2/atoms/DropdownAtom";
import InputAtom from "components/v2/atoms/InputAtom";
import Table from "components/v2/dataDisplay/Table";
import TableManagePagination from "components/v2/dataDisplay/TableManagePagination";
import { CustomerKeyRowType } from "./types";

const CustomerKeyList = (props: CustomerKeyListPropsType) => {
  const { cloudId, region, tabInformation, onCustomerKeySelected, onCustomerKeyIdClicked, currentRouteState, setCurrentRouteState, onNavigateCreation } = props;

  // API
  const [getAwsAllListAliases, {loading: getAllAliasesLoading}] = lazyGetAwsAllListAliasesPage();
  const [getAwsDescribeKey, {loading: getAwsDescribeKeyLoading}] = lazyGetAwsDescribeKey();
  
  // State
  const [mainTblTotalRows, setMainTblTotalRows] = useState<RowType[]>([]);
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [customerKeysSelected, setCustomerKeysSelected] = useState<CustomerKeyRowType[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const currentFilter = currentRouteState && CUSTOMER_KEY_FILTER_DROPDOWN.find(e => e.value === currentRouteState.key);
  const [awsKeyFilterDropdownValue, setAwsKeyFilterDropdownValue] = useState<DropdownListDataType>(
    currentFilter || CUSTOMER_KEY_FILTER_DROPDOWN[0],
  );
  const [awsKeyFilterInputValue, setAwsKeyFilterInputValue] = useState<FilterPropsType>({
    name: awsKeyFilterDropdownValue.value as string,
    value: currentRouteState?.value || '',
  });
  const [awsKeyFilter, setAwsKeyFilter] = useState<FilterPropsType | undefined>(currentRouteState?.value ? awsKeyFilterInputValue : undefined);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });

  const apiLoading = useMemo((): boolean => {
    return getAllAliasesLoading || getAwsDescribeKeyLoading;
  }, [getAllAliasesLoading, getAwsDescribeKeyLoading]);

  const mainTblColumns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Aliases',
        field: 'name',
        sort: true,
        renderCell: row => {
          return (
            <div style={{ display: 'flex' }}>
              <p
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  onCustomerKeyIdClicked();
                  onCustomerKeySelected(row as CustomerKeyRowType)
                }}
              >
                {handleFormatText(row.name)}
              </p>
              {row.count > 0 && (
                <>
                  &nbsp;(
                  <p
                    className="link"
                    onClick={e => {
                      e.stopPropagation();
                      onCustomerKeyIdClicked();
                      onCustomerKeySelected(row as CustomerKeyRowType)
                    }}
                  >
                    +{row.count} more
                  </p>
                  )
                </>
              )}
            </div>
          );
        },
      },
      {
        label: 'Key ID',
        field: 'keyId',
        sort: true,
        renderCell: row => (
          <p className="link" onClick={() => {}}>
            {handleFormatText(row.keyId)}
          </p>
        ),
      },
      { label: 'Status', field: 'status', sort: true },
      { label: 'Key type', field: 'keyType', sort: true },
      { label: 'Key spec', field: 'keySpec', sort: true },
      { label: 'Key usage', field: 'keyUsage', sort: true },
    ];
  }, []);

  const handleSearchFunction = useCallback(() => {
    if (awsKeyFilterInputValue.value !== '') {
      setAwsKeyFilter({ name: awsKeyFilterInputValue.name, value: awsKeyFilterInputValue.value });
      const searchedRows = mainTblTotalRows?.filter(row => row[awsKeyFilterInputValue.name] === awsKeyFilterInputValue.value);
      setMainTblTotal({
        totalPage: Math.ceil(searchedRows.length / mainTablePagination.itemPerPage),
        totalElement: searchedRows.length,
      });
    } else {
      setAwsKeyFilter(undefined);
      setMainTblTotal({
        totalPage: Math.ceil(mainTblTotalRows.length / mainTablePagination.itemPerPage),
        totalElement: mainTblTotalRows.length,
      });
    }
  }, [awsKeyFilterInputValue, mainTblTotalRows]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setMainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchAwsAllCustomerKeys(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    let totalRows = [...mainTblTotalRows];
    if (awsKeyFilter && awsKeyFilter?.value !== '' && awsKeyFilter?.name !== '') {
      totalRows = totalRows?.filter(row => {
        return row?.[awsKeyFilter?.name ?? '']?.includes(awsKeyFilter?.value || '');
      });
    }

    return orderAlphabetical(totalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotalRows, mainTblTotal, mainTablePagination, mainNextToken, awsKeyFilter]);
  
  const awsCustomerKeyRequestVariables = useMemo((): IAwsAllListAliasesVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        limit: mainTablePagination.limit
      },
    };
  }, [cloudId, region, mainTablePagination]);

  const fetchAwsAllCustomerKeys = useCallback(async (nextToken?: string, isInitial?: boolean) => {
    const combinedVariable = variableCombineNextToken(awsCustomerKeyRequestVariables, nextToken);
    const data = await getAwsAllListAliases({ variables: combinedVariable }).then(({ data: listAliases }) => {
      return listAliases;
    });
    const listAliases = data?.getAwsAllListAliases?.data ?? [];
    const rows: RowType[] = [];
    if (listAliases.length > 0) {
      for (const aliases of listAliases) {
        if (!aliases.aliasName.startsWith('alias/aws/') && aliases.targetKeyId) {
          const variable = {
            cloudId: cloudId,
            region: region,
            request: { keyId: aliases.targetKeyId },
          };
          const keyData = await getAwsDescribeKey({ variables: variable }).then(({ data: keyData }) => {
            return keyData?.getAwsDescribeKey?.data?.[0];
          });

          rows.push({
            id: aliases.targetKeyId,
            name: aliases.aliasName.substring(6),
            keyId: aliases.targetKeyId,
            status: keyData?.keyMetadata?.keyState || 'Not authorized',
            keyType: keyData?.keyMetadata?.keySpec
              ? keyData?.keyMetadata?.keySpec.startsWith('RSA_')
                ? 'Asymmetric'
                : 'Symmetric'
              : 'Not authorized',
            keySpec: keyData?.keyMetadata?.keySpec || 'Not authorized',
            keyUsage: keyData?.keyMetadata?.keyUsage || 'Not authorized',
            isMultiRegion: keyData?.keyMetadata?.multiRegion,
          });
        }
      }

      // Count duplicate keyId and merge data
      const itemMap = new Map<string, RowType>();
      rows.forEach(item => {
        if (itemMap.has(item.keyId)) {
          const existingItem = itemMap.get(item.keyId)!;
          existingItem.count!++;
        } else {
          itemMap.set(item.keyId, { ...item, count: 0 });
        }
      });

      const finalAwsCustomerKeys = [...Array.from(itemMap.values())];
      let allCustomerKeys: RowType[] = [];
      if (isInitial) {
        allCustomerKeys = finalAwsCustomerKeys;
      } else {
        allCustomerKeys = [...mainTblTotalRows, ...finalAwsCustomerKeys];
      }

      setMainTblTotalRows(allCustomerKeys);
      if (allCustomerKeys.length > mainTablePagination.limit) {
        setMainNextToken(data?.getAwsAllListAliases?.nextToken as string);
      } else {
        setMainNextToken('');
      }
      setMainTblTotal({
        totalPage: Math.ceil(allCustomerKeys.length / mainTablePagination.itemPerPage),
        totalElement: allCustomerKeys.length,
      });
    }
  }, [awsCustomerKeyRequestVariables, cloudId, region]);

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const handleCustomerKeySelected = useCallback((listId: string[]) => {
    const awsKeys: CustomerKeyRowType[] = [];
    listId.map(rowId => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);
      if (rowSelected) {
        awsKeys.push(rowSelected as CustomerKeyRowType);
      }
    });
    setCustomerKeysSelected(awsKeys);
  }, [mainTblTotalRows]);

  const handleMainRowSelected = useCallback(
    (rowId: string) => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);

      if (!rowSelected) return;
      handleCustomerKeySelected([rowId]);
      onCustomerKeySelected(rowSelected as CustomerKeyRowType);
    },
    [mainTblTotalRows],
  );

  // For initial data
  useEffect(() => {
    fetchAwsAllCustomerKeys('', true);
  }, [awsCustomerKeyRequestVariables]);

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>{tabInformation.title}</p>
        </div>
        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={CUSTOMER_KEY_FILTER_DROPDOWN}
            value={awsKeyFilterDropdownValue}
            handleClick={val => {
              setAwsKeyFilterDropdownValue(val);
              setAwsKeyFilterInputValue(prev => ({ ...prev, name: val.value as string }));
            }}
          />
          <InputAtom
            type={'text'}
            value={awsKeyFilterInputValue.value}
            defaultValue={''}
            onChangeValue={str => setAwsKeyFilterInputValue(prev => ({ name: prev?.name, value: str }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearchFunction();
              }
            }}
          />

          <button className="action-btn" onClick={handleSearchFunction}>
            Search
          </button>
          <button className="action-btn" onClick={() => onNavigateCreation()}>
            Create key
          </button>
          {/* <button className="action-btn" onClick={() => setDeleteModalVisible(true)} disabled={deleteButtonDisabled}>
            Delete
          </button> */}
        </div>
      </div>
      <div className="data-grid-wrap">
        <Table
          rows={mainRowsCurrentPage}
          columns={mainTblColumns}
          reportCheckedList={listId => handleCustomerKeySelected(listId)}
          reportSelected={id => handleMainRowSelected(id)}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              updateTablePagination('target', target);
              updateTablePagination('direction', dir);
            },
          }}
          isLoading={apiLoading}
          horizontalScrollable={true}
        />
        {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && apiLoading === false && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center">
              Total <span>{mainTblTotal.totalElement}</span>
            </p>
            <TableManagePagination
              ableFetchMore={!!mainNextToken}
              currentPage={mainTablePagination.currentPage}
              updateCurrentPage={page =>
                setMainTablePagination(prev => ({
                  ...prev,
                  ['currentPage']: page,
                }))
              }
              totalPage={mainTblTotal.totalPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default CustomerKeyList;