import { gql } from '@apollo/client';

const query = gql`
  mutation PutAwsLoggingConfiguration($request: AwsPutLoggingConfigurationRequest, $cloudId: Long, $region: String) {
    putAwsLoggingConfiguration(putLoggingConfigurationRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsPutLoggingConfigurationResponse {
          loggingConfiguration {
            resourceArn
            logDestinationConfigs
            redactedFields {
              singleHeader {
                name
              }
              singleQueryArgument {
                name
              }
              allQueryArguments
              uriPath
              queryString
              body {
                oversizeHandling
              }
              method
              jsonBody {
                matchPattern {
                  all
                  includedPaths
                }
                matchScope
                invalidFallbackBehavior
                oversizeHandling
              }
              headers {
                matchPattern {
                  all
                  includedHeaders
                  excludedHeaders
                }
              }
              cookies {
                matchPattern {
                  all
                  includedCookies
                  excludedCookies
                }
                matchScope
                oversizeHandling
              }
              headerOrder {
                oversizeHandling
              }
              ja3Fingerprint {
                fallbackBehavior
              }
            }
            managedByFirewallManager
            loggingFilter {
              filters {
                name
                values
              }
              defaultBehavior
            }
          }
        }
      }
    }
  }
`;
export default query;
