import { useLazyQuery } from '@apollo/client';
import { IGqlResponseNewData } from 'graphql/types';
import { IGetAwsListDetectorsRequest, IListDetectors } from 'graphql/types/AwsDetectors';
import query from './query';

export interface IGetAwsListDetectorsVariables {
  cloudId: number;
  region: string;
  request: IGetAwsListDetectorsRequest;
}

export interface IGetAwsListDetectorsResponse {
  getAwsListDetectors: IGqlResponseNewData<IListDetectors>;
}

const lazyGetAwsListDetectors = () =>
  useLazyQuery<IGetAwsListDetectorsResponse, IGetAwsListDetectorsVariables>(query, {
    fetchPolicy: 'no-cache',
  });
  
export default lazyGetAwsListDetectors;
