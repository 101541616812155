import { useMutation } from '@apollo/client';
import { IGqlResponseNewData } from 'graphql/types';
import query from './query';

export interface IS3WordsList {
  bucketName: string;
  objectKey: string;
}

export interface ICriteria {
  regex?: string;
  s3WordsList?: IS3WordsList;
}

export interface ICreateAwsAllowListVariables {
  cloudId: number;
  region: string;
  request: {
    name: string;
    description?: string;
    criteria: ICriteria;
    tags?: Record<string, string | undefined>;
  };
}

export type CreateAwsAllowListResponse = {
  arn: string;
  id: string;
};

export interface ICreateAwsAllowListResponseData {
  createAwsAllowList: IGqlResponseNewData<CreateAwsAllowListResponse>;
}

const lazyCreateAwsAllowList = () =>
  useMutation<ICreateAwsAllowListResponseData, ICreateAwsAllowListVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyCreateAwsAllowList;
