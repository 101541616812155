import { gql } from '@apollo/client';

const query = gql`
  mutation createAwsCustomDataIdentifier(
    $request: AwsCreateCustomDataIdentifierRequest
    $cloudId: Long
    $region: String
  ) {
    createAwsCustomDataIdentifier(awsCreateCustomDataIdentifierRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsCreateCustomDataIdentifierResponse {
          customDataIdentifierId
        }
      }
    }
  }
`;

export default query;
