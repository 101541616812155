import { useLazyQuery } from '@apollo/client';
import { IGqlResponseNewData } from 'graphql/types';
import {
  IIGetAwsListResourceProfileDetectionsRequest,
  IIGetAwsListResourceProfileDetectionsResponse,
} from 'graphql/types/AwsResourceProfileDetections';
import query from './query';

export interface IGetAwsListResourceProfileDetectionsVariables {
  cloudId: number;
  region: string;
  request: IIGetAwsListResourceProfileDetectionsRequest;
}

export interface IIGetAwsListResourceProfileDetectionsResponseData {
  getAwsListResourceProfileDetections: IGqlResponseNewData<IIGetAwsListResourceProfileDetectionsResponse>;
}

const lazyGetAwsListResourceProfileDetections = () =>
  useLazyQuery<IIGetAwsListResourceProfileDetectionsResponseData, IGetAwsListResourceProfileDetectionsVariables>(
    query,
    {
      fetchPolicy: 'no-cache',
    },
  );
export default lazyGetAwsListResourceProfileDetections;
