import { gql } from '@apollo/client';

const query = gql`
  mutation updateAwsKeyDescription($reqData: AwsUpdateKeyDescriptionRequest, $cloudId: Long, $region: String) {
    updateAwsKeyDescription(updateKeyDescriptionRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
    }
  }
`;
export default query;
