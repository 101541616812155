import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserInfoType } from '@Types/User';
import jwtDecode from 'jwt-decode';

interface IContextProps {
  token: string;
  loggedIn: boolean;
  userInfo: UserInfoType | null;
  login: (token: string, success: boolean) => void
  logout: () => void;
  updateToken: (token:string) => void;
  role:  "sy_admin" | "pj_admin" | "pj_membr" | "pj_owner" | null;
  checkTokenValid: boolean;
  changeGqlTokenValid: (val: boolean) => void;
}
const Context = createContext<IContextProps>({} as IContextProps);

const AuthProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const navigate = useNavigate();
  /** 챗봇이 gql 호출 후에 호출되어야 하기 때문에 gql token valid 문제 있는지 확인 
   * getMemberInfo then 에서 한번 확인
  */
  const [checkTokenValid, setCheckTokenValid] = useState(false);
  /* Access token */
  const [token, setToken] = useState(localStorage.getItem('token') || '');

  /** 로그인 상태 */
  const loggedIn = useMemo(() => {
    /* MARK: 토큰 존재 여부 이외에 다른 검증이 필요하면 여기에 추가 */
    /* 예를 들어 토큰말도고 다른 정보를 더 줘야할 때 */
    return !!token;
  }, [token]);
  
  const userInfo = useMemo(() => {
    if (token) {
      const decodedData = jwtDecode(token) as UserInfoType;
      return decodedData;
    } else { return null;}
  },[token]);

  const role:"sy_admin" | "pj_admin" | "pj_membr" | "pj_owner" | null = useMemo(() => {
    if (userInfo?.nemo.role === 'sy_admin') 
      return 'sy_admin';

    const splitURL = location.pathname.split('/');
    const ownerShip = userInfo?.nemo.ownership
    const isOwner = userInfo?.nemo.projects.some(val => val.roleCode.includes('pj_owner'));

    if (isOwner || ownerShip) {
      return 'pj_owner';
    } else {
      if (splitURL[3] !== 'project') {
        return null;
      } else {
        const findMyRoleCode = userInfo?.nemo.projects.find(val => val.projectId === Number(splitURL[4].toString()))?.roleCode;
        if (findMyRoleCode === undefined) {
          return null;
        } else {
          return findMyRoleCode;
        }
      }
    }
  }, [userInfo, location])

  const updateToken = (token: string) => {
    setToken(token);
    localStorage.setItem('token', token);
  }

  const login = (token: string, success: boolean) => {
    localStorage.setItem('token', token);
    setToken(token);
    const decodedData = jwtDecode(token) as UserInfoType;

    /* 이메일 인증이 성공한 전제하의 라우트 관리 */
    if (success) {
      if (decodedData.nemo.organId === null) { 
        navigate('/join-organ'); 
      } else { navigate(`/organ/${decodedData.nemo.organId}/dashboard/overview`); }
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setToken('');
    navigate('/auth');
  };
  const changeGqlTokenValid = (val: boolean) => {
    setCheckTokenValid(val);
  }

  /* 라우터 이동 시 토큰 검증 */
  useEffect(() => {
    const splitURL =  location.pathname.split('/');

    if (location.pathname === '/') { navigate('/');} 
    else {
      /* 토큰이 있을 때 */
      // console.log('userInfo', userInfo);
      if (userInfo) {
        if (location.pathname === '/auth') {
          const organId = userInfo.nemo.organId

          if (organId) { navigate(`/organ/${organId}/dashboard/overview`)} 
          else { navigate('/join-organ'); }
        }
      } 
      /* 토큰이 없을 때 */
      else {
        if (
          splitURL[1] !== 'auth' && 
          location.pathname !== '/inquiry' && 
          splitURL[1] !== 'terms'
        ) { 
          navigate('/auth');
        } 
      }
    }

  }, [location.pathname, userInfo]);
  
  return (
    <Context.Provider 
      value={{ 
        token, 
        userInfo, 
        loggedIn, 
        login, 
        logout, 
        updateToken, 
        role,
        checkTokenValid,
        changeGqlTokenValid
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AuthProvider;
export const useAuth = () => useContext(Context);