import { gql } from '@apollo/client';

const query = gql`
  mutation editAwsTag($request: AwsTagRequest, $cloudId: Long, $region: String) {
  editAwsTag(awsTagRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsTagResponse {
        arn
        tags
        }
      }
    }
  }
`;
export default query;
