import { useMutation } from '@apollo/client';
import { IGqlResponseNewData } from '../../types';
import query from './query';
import { AwsUpdateFirewallDescriptionRequestType, AwsUpdateFirewallDescriptionResponseType } from 'graphql/types/AwsUpdateFirewall';

export type UpdateFirewallDescriptionVariablesType =  {
    cloudId: number;
    region: string;
    request : AwsUpdateFirewallDescriptionRequestType
}

export type UpdateFirewallDescriptionResponseDataType = {
  updateAwsFirewallDescription: IGqlResponseNewData<AwsUpdateFirewallDescriptionResponseType>;
}

const updateAwsFirewallDescriptionMutation = () =>
  useMutation<UpdateFirewallDescriptionResponseDataType, UpdateFirewallDescriptionVariablesType>(query);
export default updateAwsFirewallDescriptionMutation;
