import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconEditRuleGroup from 'assets/svgs/v3/ico_policy_variables.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';

type EditKeyPolicyModalModalPropsType = {
  onSave?: (description: string) => void;
  policyString?: string;
} & IBaseModalProps;

const EditKeyPolicyModal = (props: EditKeyPolicyModalModalPropsType) => {
  const { policyString, onSave, ...baseModalProps } = props;
  const [payload, setPayload] = useState<string>('');

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload(policyString ?? '');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditRuleGroup} />
          {'Edit general configuration'}
        </>
      )}
      {...baseModalProps}
    >
      <div className="modal-content-container">
        <p className="title">Key policy</p>
        <div className="input">
          <TextareaAtom
            placeholder={'Description of the key'}
            value={payload}
            noClear={true}
            onChangeValue={val => setPayload(val)}
          />
        </div>

        <div className="button-group">
          <Button type={ButtonTypeEnum.GENERAL} onClick={() => baseModalProps?.onClose?.()} label="Cancel" />
          <Button type={ButtonTypeEnum.PRIMARY} onClick={() => onSave?.(payload)} label="Save changes" />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditKeyPolicyModal;
