import { useMutation } from '@apollo/client';
import { IGqlResponseNewData } from '../../types';
import query from './query';
import { AwsUpdateGroupQueryRequestType, AwsUpdateGroupQueryResponseType } from 'graphql/types/AwsUpdateGroupQuery';

export type UpdateAwsGroupQueryVariablesType = {
    cloudId: number;
    region: string;
    request : AwsUpdateGroupQueryRequestType
}

export type UpdateAwsGroupQueryDataType = {
  updateAwsGroupQuery: IGqlResponseNewData<AwsUpdateGroupQueryResponseType>;
}

const updateAwsGroupQueryMutation = () =>
  useMutation<UpdateAwsGroupQueryDataType, UpdateAwsGroupQueryVariablesType>(query);
export default updateAwsGroupQueryMutation;
