import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IUntagAwsResourceSecretsVariables {
  cloudId: number;
  region: string;
  reqData: {
    secretId: string;
    tagKeys: string[];
  };
}

export interface IUntagAwsResourceSecretsResponseData {
  untagAwsResourceSecrets: IGqlResponseData<undefined>;
}

const untagAwsResourceSecretsMutation = () =>
  useMutation<IUntagAwsResourceSecretsResponseData, IUntagAwsResourceSecretsVariables>(query);
export default untagAwsResourceSecretsMutation;
