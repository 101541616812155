import { RowType } from '@Types/v2/Table';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { useCallback, useState } from 'react';
import CustomRadio from '../../components/CustomRadio';
import IPSetReferences from '../../components/IPSetReferences';
import RuleVariables from '../../components/RuleVariables';
import { listProtocolData } from '../../configs';
import { isEmpty } from '../../validate';
import { ICreationDataPropsType } from '../type';
import CustomDropdown from '../../../Common/Components/CustomDropdown';

interface IStandardStatefulRulesProps {
  creationData: ICreationDataPropsType;
  updateCreationData: (data: ICreationDataPropsType) => void;
}

const StandardStatefulRules = ({ creationData, updateCreationData }: IStandardStatefulRulesProps) => {
  const {
    statefulProtocol,
    statefulSourceIP,
    statefulSourcePort,
    statefulDestinationIP,
    statefulDestinationPort,
    ruleRowsDomainList,
  } = creationData;
  const [trafficDirection, setTrafficDirection] = useState<'FORWARD' | 'ANY'>('FORWARD');
  const [action, setAction] = useState<'DROP' | 'PASS' | 'ALERT' | 'REJECT'>('PASS');
  const listIP: DropdownListDataType[] = [
    { id: 1, name: 'Any', value: 'ANY' },
    { id: 2, name: 'Custom', value: '' },
  ];
  const listPort: DropdownListDataType[] = [
    { id: 1, name: 'Any port', value: 'ANY' },
    { id: 2, name: 'Custom', value: '' },
  ];
  const STR_IP = '10.0.0.0/16\n10.0.0.0\n$VARIABLE_NAME';
  const STR_PORT = '100\n0:65535\n$VARIABLE_NAME';
  const [sourceIP, setSourceIP] = useState<DropdownListDataType>(listIP[0]);
  const [sourcePort, setSourcePort] = useState<DropdownListDataType>(listPort[0]);
  const [destinationIP, setDestinationIP] = useState<DropdownListDataType>(listIP[0]);
  const [destinationPort, setDestinationPort] = useState<DropdownListDataType>(listPort[0]);
  const [protocolError, setProtocolError] = useState('');
  const [sourceIPError, setSourceIPError] = useState('');
  const [sourcePortError, setSourcePortError] = useState('');
  const [destinationIPError, setDestinationIPError] = useState('');
  const [destinationPortError, setDestinationPortError] = useState('');
  const onChangeProtocol = useCallback(
    (value: DropdownListDataType) => {
      updateCreationData({ ...creationData, statefulProtocol: value });
    },
    [creationData, updateCreationData],
  );
  const onChangeValue = useCallback(
    (name: string, value: string) => {
      updateCreationData({ ...creationData, [name]: value });
    },
    [creationData, updateCreationData],
  );
  const validation = useCallback(() => {
    let isValid = true;
    if (!statefulProtocol.value) {
      setProtocolError('Protocol cannot be empty.');
      isValid = false;
    }
    if (sourceIP.id === 2 && isEmpty(statefulSourceIP)) {
      setSourceIPError('Invalid IP CIDR format.');
      isValid = false;
    }
    if (sourcePort.id === 2 && isEmpty(statefulSourcePort)) {
      setSourcePortError('Invalid port format.');
      isValid = false;
    }
    if (destinationIP.id === 2 && isEmpty(statefulDestinationIP)) {
      setDestinationIPError('Invalid IP CIDR format.');
      isValid = false;
    }
    if (destinationPort.id === 2 && isEmpty(statefulDestinationPort)) {
      setDestinationPortError('Invalid port format.');
      isValid = false;
    }
    if (isValid) {
      setProtocolError('');
    }

    return isValid;
  }, [
    destinationIP.id,
    destinationPort.id,
    sourceIP.id,
    sourcePort.id,
    statefulDestinationIP,
    statefulDestinationPort,
    statefulProtocol.value,
    statefulSourceIP,
    statefulSourcePort,
  ]);
  const addNewRule = useCallback(() => {
    if (!validation()) return;
    const newItem: RowType = {
      id: ruleRowsDomainList.length + 1,
      priority: 1,
      protocol: statefulProtocol.value,
      source: statefulSourceIP,
      destination: statefulDestinationIP,
      sourcePort: statefulSourcePort,
      destinationPort: statefulDestinationPort,
      trafficDirection,
      action,
    };
    const newList: RowType[] = ruleRowsDomainList.map((item, id) => ({
      ...item,
      id: id + 1,
    }));
    newList.push(newItem);
    updateCreationData({
      ...creationData,
      ruleRowsDomainList: newList,
    });
  }, [
    action,
    creationData,
    ruleRowsDomainList,
    statefulDestinationIP,
    statefulDestinationPort,
    statefulProtocol.value,
    statefulSourceIP,
    statefulSourcePort,
    trafficDirection,
    updateCreationData,
    validation,
  ]);
  const handleSelectTrafficDirection = (direction: 'FORWARD' | 'ANY') => {
    setTrafficDirection(direction);
  };
  const handleSelectAction = (action: 'DROP' | 'PASS' | 'ALERT' | 'REJECT') => {
    setAction(action);
  };

  return (
    <>
      {<RuleVariables creationData={creationData} updateCreationData={updateCreationData} />}
      {<IPSetReferences creationData={creationData} updateCreationData={updateCreationData} />}
      <div className="rule-group-container">
        <div className="title-group">
          <p className="title">Standard stateful rules</p>
          <p className="sub-title">
            Add the stateful rules that you need to your rule group. Each rule that you add is listed in the Rules table
            below.
          </p>
        </div>

        <div className="rule-group-details-container">
          <div style={{ display: 'flex', gap: 10, padding: '10px 16px' }}>
            <div className="rule-group-details-container" style={{ flex: 1, border: 'none' }}>
              <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: '0px 0px 4px 0px' }}>
                Protocol
                <div className="rule-group-detail-sub-title">Transport protocols to inspect for.</div>
              </div>
              <div className="rule-group-detail-content" style={{ padding: 0 }}>
                <CustomDropdown
                  id="protocol-stateful-rules"
                  placeholder="IP-set reference"
                  style={{ height: 40 }}
                  data={listProtocolData}
                  value={statefulProtocol}
                  handleClick={onChangeProtocol}
                />
              </div>
              {protocolError && (
                <div className="error-container">
                  <img src={InputErrorIcon} width={16} height={16} />
                  <p className="error-message">{protocolError}</p>
                </div>
              )}
            </div>
            <div
              className="rule-group-details-container"
              style={{ flex: 1, border: 'none', opacity: 0, pointerEvents: 'none' }}
            >
              <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: '0px 0px 4px 0px' }}>
                Protocol
                <div className="rule-group-detail-sub-title">Transport protocols to inspect for.</div>
              </div>
              <div className="rule-group-detail-content" style={{ padding: 0 }}>
                <CustomDropdown
                  id="protocol-stateful-rules-dummy"
                  style={{ height: 40 }}
                  data={[]}
                  value={{ value: '' }}
                  handleClick={() => {}}
                />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: 10, padding: '10px 16px' }}>
            <div className="rule-group-details-container" style={{ flex: 1, border: 'none' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
                <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: 0 }}>
                  Source
                  <div className="rule-group-detail-sub-title">
                    The source IP addresses and address ranges to inspect for.
                  </div>
                </div>
                <div className="rule-group-detail-content" style={{ padding: 0 }}>
                  <CustomDropdown
                    id="source-stateful-rules"
                    style={{ height: 40 }}
                    data={listIP}
                    value={sourceIP}
                    handleClick={data => {
                      setSourceIP(data);
                      onChangeValue('statefulSourceIP', data.value.toString());
                    }}
                  />
                </div>
                <div className="rule-group-detail-content" style={{ padding: 0 }}>
                  <textarea
                    disabled={sourceIP.id === 1}
                    placeholder={STR_IP}
                    value={statefulSourceIP}
                    onChange={event => onChangeValue('statefulSourceIP', event.target.value)}
                  />
                </div>
                <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: 0 }}>
                  <div className="rule-group-detail-sub-title">You can provide single addresses and CIDR blocks</div>
                </div>
                {sourceIPError && (
                  <div className="error-container">
                    <img src={InputErrorIcon} width={16} height={16} />
                    <p className="error-message">Invalid IP CIDR format.</p>
                  </div>
                )}
              </div>
            </div>
            <div className="rule-group-details-container" style={{ flex: 1, border: 'none' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
                <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: 0 }}>
                  Source port
                  <div className="rule-group-detail-sub-title">The source port or port range to inspect for.</div>
                </div>
                <div className="rule-group-detail-content" style={{ padding: 0 }}>
                  <CustomDropdown
                    id="source-port-stateful-rules"
                    style={{ height: 40 }}
                    data={listPort}
                    value={sourcePort}
                    handleClick={data => {
                      setSourcePort(data);
                      onChangeValue('statefulSourcePort', data.value.toString());
                    }}
                  />
                </div>
                <div className="rule-group-detail-content" style={{ padding: 0 }}>
                  <textarea
                    disabled={sourcePort.id === 1}
                    placeholder={STR_PORT}
                    value={statefulSourcePort}
                    onChange={event => onChangeValue('statefulSourcePort', event.target.value)}
                  />
                </div>
                <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: 0 }}>
                  <div className="rule-group-detail-sub-title">Supported ports are :34354</div>
                </div>
                {sourcePortError && (
                  <div className="error-container">
                    <img src={InputErrorIcon} width={16} height={16} />
                    <p className="error-message">Invalid port format.</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', gap: 10, padding: '10px 16px' }}>
            <div className="rule-group-details-container" style={{ flex: 1, border: 'none' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
                <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: 0 }}>
                  Destination
                  <div className="rule-group-detail-sub-title">
                    The destination IP addresses and address ranges to inspect for
                  </div>
                </div>
                <div className="rule-group-detail-content" style={{ padding: 0 }}>
                  <CustomDropdown
                    id="destination-stateful-rules"
                    style={{ height: 40 }}
                    data={listIP}
                    value={destinationIP}
                    handleClick={data => {
                      setDestinationIP(data);
                      onChangeValue('statefulDestinationIP', data.value.toString());
                    }}
                  />
                </div>
                <div className="rule-group-detail-content" style={{ padding: 0 }}>
                  <textarea
                    disabled={destinationIP.id === 1}
                    placeholder={STR_IP}
                    value={statefulDestinationIP}
                    onChange={event => onChangeValue('statefulDestinationIP', event.target.value)}
                  />
                </div>
                <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: 0 }}>
                  <div className="rule-group-detail-sub-title">You can provide single addresses and CIDR blocks</div>
                </div>
                {destinationIPError && (
                  <div className="error-container">
                    <img src={InputErrorIcon} width={16} height={16} />
                    <p className="error-message">Invalid IP CIDR format.</p>
                  </div>
                )}
              </div>
            </div>
            <div className="rule-group-details-container" style={{ flex: 1, border: 'none' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
                <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: 0 }}>
                  Destination port
                  <div className="rule-group-detail-sub-title">The destination port or port range to inspect for.</div>
                </div>
                <div className="rule-group-detail-content" style={{ padding: 0 }}>
                  <CustomDropdown
                    id="destination-port-stateful-rules"
                    style={{ height: 40 }}
                    data={listPort}
                    value={destinationPort}
                    handleClick={data => {
                      setDestinationPort(data);
                      onChangeValue('statefulDestinationPort', data.value.toString());
                    }}
                  />
                </div>
                <div className="rule-group-detail-content" style={{ padding: 0 }}>
                  <textarea
                    disabled={destinationPort.id === 1}
                    placeholder={STR_PORT}
                    value={statefulDestinationPort}
                    onChange={event => onChangeValue('statefulDestinationPort', event.target.value)}
                  />
                </div>
                <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: 0 }}>
                  <div className="rule-group-detail-sub-title">Supported ports are : 54546</div>
                </div>
                {destinationPortError && (
                  <div className="error-container">
                    <img src={InputErrorIcon} width={16} height={16} />
                    <p className="error-message">Invalid port format.</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '10px 16px' }}>
            <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: 0 }}>
              Traffic direction
              <div className="rule-group-detail-sub-title">
                The traffic direction to inspect for. Network Firewall doesn’t automatically add the direction key word
                to_server, and will inspect all the packets in the flow, irrespective of the flow state.
              </div>
            </div>

            <div className="rule-group-type-content" style={{ padding: 0 }}>
              <div className="type-content-row">
                <CustomRadio
                  checked={trafficDirection === 'FORWARD'}
                  label="Forward"
                  description="Inspect traffic that flows forward, from the specified source to the specified destination."
                  onClick={() => handleSelectTrafficDirection('FORWARD')}
                />
              </div>
              <div className="type-content-row">
                <CustomRadio
                  checked={trafficDirection === 'ANY'}
                  label="Any"
                  description="Inspect traffic from any direction."
                  onClick={() => handleSelectTrafficDirection('ANY')}
                />
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '10px 16px' }}>
            <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: 0 }}>
              Action
              <div className="rule-group-detail-sub-title">
                The action that you want Network Firewall to take when a packet matches the rule settings.
              </div>
            </div>
            <div className="rule-group-type-content" style={{ padding: 0 }}>
              <div className="type-content-row">
                <CustomRadio
                  checked={action === 'PASS'}
                  label="Pass"
                  description=""
                  onClick={() => handleSelectAction('PASS')}
                />
              </div>
              <div className="type-content-row">
                <CustomRadio
                  checked={action === 'DROP'}
                  label="Drop"
                  description=""
                  onClick={() => handleSelectAction('DROP')}
                />
              </div>
              <div className="type-content-row">
                <CustomRadio
                  checked={action === 'ALERT'}
                  label="Alert"
                  description=""
                  onClick={() => handleSelectAction('ALERT')}
                />
              </div>
              <div className="type-content-row">
                <CustomRadio
                  checked={action === 'REJECT'}
                  label="Reject "
                  description=""
                  onClick={() => handleSelectAction('REJECT')}
                />
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '10px 16px' }}>
            <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: 0 }}>
              Rule options - optional
              <div className="rule-group-detail-sub-title">No rule options associated with this rule.</div>
            </div>
          </div>

          <div style={{ padding: '10px 16px' }}>
            <button className="button next-button" style={{ margin: 0 }} onClick={addNewRule}>
              Add rule
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StandardStatefulRules;
