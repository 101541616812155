import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteAwsSecretVariables {
  cloudId: number;
  region: string;
  reqData: {
    secretId: string;
    recoveryWindowInDays?: number;
  };
}

export interface IDeleteAwsSecretResponseData {
  deleteAwsSecret: IGqlResponseData<undefined>;
}

const deleteAwsSecretMutation = () => useMutation<IDeleteAwsSecretResponseData, IDeleteAwsSecretVariables>(query);
export default deleteAwsSecretMutation;
