import { useState, useMemo, useEffect, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconManageTlsConfiguration from 'assets/svgs/v3/ico_manage_tls.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import DropdownAtom, {
  DropdownListDataType,
} from 'components/v2/atoms/DropdownAtom';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import lazyGetAwsListTlsInspectionConfigurationsPage, { IAwsListTlsInspectionConfigurationsVariables } from 'graphql/queries/getAwsAllListTLSInspectionConfigurations';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { RowType } from '@Types/v2/Table';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IManageTLSConfigurationProps extends IBaseModalProps {
  header: string;
  loadingSave: boolean;
  cloudId: number;
  region: string;
  dataInspection: string;
  onClose: () => void;
  handleUpdateTLSInspection: (TLSValue: string) => Promise<boolean>;
  onChangeValue?: (index: number, value: string) => void;
}

const ManageTLSConfigurationModal = ({
  header,
  onChangeValue,
  loadingSave,
  region,
  cloudId,
  onClose,
  dataInspection,
  handleUpdateTLSInspection,
  ...baseModalProps
}: IManageTLSConfigurationProps) => {

  const [dropdownValues, setDropdownValues] = useState<any>([]);
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(true);
  const [dataSelected, setDataSelected] = useState<any>();
  const navigate = useNavigate();
  const [getAwsAllListTLSInspectionConfigurations, { loading: getTlsInspectionLoading }] = lazyGetAwsListTlsInspectionConfigurationsPage();
  
  const awsTlsInpsectionRequestVariables = useMemo((): IAwsListTlsInspectionConfigurationsVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {},
    };
  }, [cloudId, region]);

  const fetchAwsAllTlsInspections = useCallback(async () => {
  
    const combinedVariable = variableCombineNextToken(awsTlsInpsectionRequestVariables, '');

    const listTLS = await getAwsAllListTLSInspectionConfigurations({ variables: combinedVariable }).then(({ data: listTLS }) => {
      return listTLS;
    });

    const awsAllListTLSInspectionConfigurations = listTLS?.getAwsAllListTLSInspectionConfigurations;

    if (awsAllListTLSInspectionConfigurations?.data && awsAllListTLSInspectionConfigurations.data.length > 0) {
      const tlsData: RowType[] = [];
      awsAllListTLSInspectionConfigurations.data.forEach(item => {
        tlsData.push({
          ...item,
          id: item.arn,
        });
      });
      
      setDropdownValues(tlsData);
      setDataSelected(tlsData.find(data => data.id === dataInspection));
    }

  }, [awsTlsInpsectionRequestVariables, cloudId, region]);

  const onSavePolicy = async() => {
    const result = await handleUpdateTLSInspection(dataSelected?.id || null);
    if (result) {
      onClose();
    }
  };

  useEffect(() => {
    if (baseModalProps.open) {
      fetchAwsAllTlsInspections();
      setIsShowDropdown(true);
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconManageTlsConfiguration} />
          {header}
        </>
      )}
      onClose={onClose}
      {...baseModalProps}>

      <div className="tls-configuration-modal">
        <div className="tls-configuration">
          <div className="tls-container">
            <p className="text-description">
              Choose a TLS inspection configuration to add to your policy. If you haven’t yet created a TLS inspection, then 
              <a className="link"> create a TLS inspection configuration.</a>
            </p>
          </div>

          <div className="tls-configuration-detail">
            <p className="text-title">TLS inspection configuration</p>
            {isShowDropdown && (
              <div className="tls-container-dropdown">
                <DropdownAtom
                  id={'tls-configuration-modal'}
                  className={'tls-dropdown'}
                  data={dropdownValues}
                  value={{
                    name: dataSelected?.name,
                    value: dataSelected?.id || '',
                  }}
                  handleClick={(val) => {setDataSelected(val);}}
                />
                <button className="remove-btn" onClick={() => {
                  setIsShowDropdown(false);
                  setDataSelected(null);
                }}>
                  Remove
                </button>
              </div>
            )}

            <p className="text-note">
              You can add 1 TLS inspection configuration.
            </p>
          </div>
        </div>

        <div className="button-group ">
          <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={onClose} />
          <Button
            label="Save"
            type={ButtonTypeEnum.PRIMARY}
            loading={loadingSave}
            onClick={onSavePolicy}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default ManageTLSConfigurationModal;
