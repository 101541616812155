import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IAwsUntagRequestVariables {
  cloudId: number;
  region: string;
  request: {
    arn: string;
    keys: string[];
  };
}

export interface IUntagResponseData {
  unAwsTag: IGqlResponseData<undefined>;
}

const untagAwsMutation = () =>
  useMutation<IUntagResponseData, IAwsUntagRequestVariables>(query);
export default untagAwsMutation;
