import { gql } from '@apollo/client';

const query = gql`
mutation createAwsFirewall($reqData: AwsCreateFirewallRequest, $cloudId: Long, $region: String) {
  createAwsFirewall(createFirewallRequest: $reqData, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsCreateFirewallResponse {
        	firewall {
            firewallName
            firewallArn
            firewallPolicyArn
            vpcId
            subnetMappings {
              subnetId
              ipAddressType
            }
            deleteProtection
            subnetChangeProtection
            firewallPolicyChangeProtection
            description
            firewallId
            tags {
              key
              value
            }
            encryptionConfiguration {
              keyId
              type
            }
          }
        firewallStatus {
          status
          configurationSyncStateSummary
          syncStates
          capacityUsageSummary {
            cidRs {
              availableCIDRCount
              utilizedCIDRCount
              ipSetReferences
            }
          }
        }
        }
      }
    }
  }
`;

export default query;