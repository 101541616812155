import { gql } from '@apollo/client';

const query = gql`
query getAwsResources($request: AwsGetResourcesRequest, $cloudId: Long, $region: String) {
  getAwsResources(awsGetResourcesRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsGetResourcesResponse {
        paginationToken
        resourceTagMappingList {
          resourceARN
          tags {
            key
            value
          }
          complianceDetails {
            noncompliantKeys
            keysWithNoncompliantValues
            complianceStatus
          }
        }
        }
      }
    }
  } 
`;
export default query;