import './index.scss';
import lazyGetAwsFirewallPolicy from 'graphql/queries/getAwsFirewallPolicy';
import { AwsDescribeFirewallPolicyResponse } from 'graphql/types/AwsFirewallPolicy';
import Space from 'pages/v2/Organ/Management/components/Space';
import { useEffect, useState } from 'react';
import Details from '../Common/Components/Details';
import FirewallPolicySettings from '../Common/Components/FirewallPolicySettings';
import Overview from '../Common/Components/Overview';
import { firewallTab } from '../configs';
import { IBaseFirewallProps } from '../../../types';

interface IFirewallSummaryProps extends IBaseFirewallProps {}
const FirewallSummary = ({ cloudId, region, data }: IFirewallSummaryProps) => {
  const [tabSelected, setTabSelected] = useState(firewallTab[0]);
  const [firewallPolicies, setFirewallPolicies] = useState<AwsDescribeFirewallPolicyResponse | undefined>(undefined);

  const [getAwsFirewallPolicy, { loading }] = lazyGetAwsFirewallPolicy();

  const handleGetAwsFirewallPolicy = async () => {
    if (!data?.id) return;

    try {
      const variables = {
        cloudId,
        region,
        request: {
          firewallPolicyArn: data?.firewall?.firewallPolicyArn,
        },
      };

      const response = await getAwsFirewallPolicy({
        variables,
      });

      setFirewallPolicies(response.data?.getAwsFirewallPolicy?.data?.[0]);
    } catch (error) {}
  };

  useEffect(() => {
    setTabSelected(firewallTab[0]);
    handleGetAwsFirewallPolicy();
  }, [data?.id]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case firewallTab[0].id:
        return (
          <Details
            cloudId={cloudId}
            region={region}
            data={data}
            firewallPolicyResponse={firewallPolicies?.firewallPolicyResponse}
          />
        );

      case firewallTab[1].id:
        return (
          <FirewallPolicySettings
            cloudId={cloudId}
            region={region}
            data={data}
            firewallPolicies={firewallPolicies}
            isEdit
          />
        );

      case firewallTab[2].id:

      default:
        return null;
    }
  };

  return data?.id ? (
    <div className="box-summary">
      <div className="box-summary-id">{data?.name}</div>

      <div style={{ padding: '8px' }}>
        <Overview
          isLoading={loading}
          firewallStatus={data?.firewallStatus.status}
          firewallPolicyName={firewallPolicies?.firewallPolicyResponse.firewallPolicyName}
          vpcId={data.firewall.vpcId}
        />

        <Space size={10} />

        <div className="detail-tab firewall-sumary-tab flex a-center">
          {firewallTab.map(tab => {
            return (
              <button
                className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
                key={tab.id}
                data-tab={tab.id}
                onClick={e => setTabSelected(tab)}
              >
                <p>{tab.name}</p>
              </button>
            );
          })}
        </div>

        <div className="content-tab firewall-sumary-content-tab">{renderTab()}</div>
      </div>
    </div>
  ) : (
    <div className="detail-data">
      <p className="empty-page">Select a item</p>
    </div>
  );
};

export default FirewallSummary;
