import { StepContainer } from 'pages/v2/Organ/Management/WAF/WebACL/CreateWebACL/components';
import { ConfigKeyPropsType, CreationDataPropsType, RadioDataPropsType } from './types';
import {
  KEY_ASYMMETRIC_USAGE_DATA,
  KEY_SYMMETRIC_MATERIAL_ORIGIN_DATA,
  KEY_REGIONALITY_DATA,
  KEY_SPEC_ASYMMETRIC_ENCRYPTDECRYPT_DATA,
  KEY_SPEC_ASYMMETRIC_SIGNVERIFY_DATA,
  KEY_SPEC_SYMMETRIC_GENERATEVERIFYMAC_DATA,
  KEY_SYMMETRIC_USAGE_DATA,
  KEY_TYPE_DATA,
  KeyMaterialOriginEnum,
  KeyRegionalityEnum,
  KeySpecEnum,
  KeyTypeEnum,
  KeyUsageEnum,
  KEY_ASYMMETRIC_MATERIAL_ORIGIN_DATA,
} from './configs';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import RadioGroupSection from '../components/RadioGroupSection';
import CollapsibleContainer from '../components/CollapsibleContainer';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import './index.scss';

const ConfigKey = (props: ConfigKeyPropsType) => {
  const { title, creationData, updateCreationData } = props;

  const [keyType, setKeyType] = useState(creationData.keyType);
  const [keyUsage, setKeyUsage] = useState(creationData.keyUsage);
  const [keySpec, setKeySpec] = useState(creationData.keySpec);
  const [keyMaterialOrigin, setKeyMaterialOrigin] = useState(creationData.origin);
  const [keyRegionality, setKeyRegionality] = useState<boolean>(creationData.multiRegion);
  const [kmsKeyAgree, setKmsKeyAgree] = useState<boolean>(false);

  const getKeyUsageData = useCallback((keyType: string): RadioDataPropsType[] => {
    if (keyType === KeyTypeEnum.SYMMETRIC) {
      return KEY_SYMMETRIC_USAGE_DATA;
    }
    return KEY_ASYMMETRIC_USAGE_DATA;
  }, []);

  const getKeySpecData = useCallback((keyType: string, keyUsage: string): RadioDataPropsType[] => {
    if (keyType === KeyTypeEnum.ASYMMETRIC) {
      if (keyUsage === KeyUsageEnum.ASYMMETRIC_ENCRYPTDECRYPT) {
        return KEY_SPEC_ASYMMETRIC_ENCRYPTDECRYPT_DATA;
      }
      if (keyUsage === KeyUsageEnum.ASYMMETRIC_SIGNVERRIFY) {
        return KEY_SPEC_ASYMMETRIC_SIGNVERIFY_DATA;
      }
    }

    if (keyType === KeyTypeEnum.SYMMETRIC) {
      if (keyUsage === KeyUsageEnum.SYMMETRIC_GENERATEVERIFYMAC) {
        return KEY_SPEC_SYMMETRIC_GENERATEVERIFYMAC_DATA;
      }
    }

    return [];
  }, []);

  const keyMaterialOriginData = useMemo((): RadioDataPropsType[] => {
    if (keyType === KeyTypeEnum.SYMMETRIC) {
      return KEY_SYMMETRIC_MATERIAL_ORIGIN_DATA;
    }
    return KEY_ASYMMETRIC_MATERIAL_ORIGIN_DATA;
  }, [keyType]);

  const regionalityData = useMemo((): RadioDataPropsType[] => {
    if (
      keyMaterialOrigin === KeyMaterialOriginEnum.KMSRECOMMEND ||
      keyMaterialOrigin === KeyMaterialOriginEnum.EXTERNAL
    ) {
      return KEY_REGIONALITY_DATA;
    }
    return [];
  }, [keyMaterialOrigin]);

  const onImportKeyChange = useCallback(
    (value: boolean) => {
      let securityAndDurabilityError;
      if (!value) {
        securityAndDurabilityError =
          'To use external key you must check the box to indicate that you understand the implications.';
      }
      const updatedCreationData: CreationDataPropsType = {
        ...creationData,
        securityAndDurabilityAgreement: value,
        securityAndDurabilityError: securityAndDurabilityError,
      };
      updateCreationData(updatedCreationData);
    },
    [creationData],
  );

  const protectingImportedKeyMeterialNode = useMemo((): ReactNode => {
    if (keyMaterialOrigin === KeyMaterialOriginEnum.EXTERNAL) {
      return (
        <div className="import-key-container">
          <p className="import-key-container-title">
            You can import key material from your key management infrastructure into AWS KMS and use it like any other
            AWS KMS key.
          </p>
          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <CheckboxAtom
              checked={creationData.securityAndDurabilityAgreement}
              onchange={() => onImportKeyChange(!creationData.securityAndDurabilityAgreement)}
            />
            <p className="import-key-container-checkbox-text">
              I understand the security and durability implications of using an imported key.
            </p>
          </div>
          {!!creationData?.securityAndDurabilityError && (
            <div className="error-container">
              <img src={InputErrorIcon} width={16} height={16} />
              <p className="error-message">{creationData?.securityAndDurabilityError}</p>
            </div>
          )}
        </div>
      );
    }
    return null;
  }, [keyMaterialOrigin, creationData, onImportKeyChange]);

  const kmsKeyAgreementNode = useMemo((): ReactNode => {
    if (keyMaterialOrigin === KeyMaterialOriginEnum.EXTERNALKEYSTORE) {
      return (
        <div className="kms-key-container">
          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <CheckboxAtom checked={kmsKeyAgree} onchange={() => setKmsKeyAgree(!kmsKeyAgree)} />
            <p className="kms-key-container-checkbox-text">
              I understand that a KMS key backed by an external key store could have degraded latency, durability, and
              availability because it depends on an external key manager managed outside of AWS.
            </p>
          </div>
        </div>
      );
    }
    return null;
  }, [keyMaterialOrigin, kmsKeyAgree]);

  const onKeyTypeChange = useCallback(
    (value: string) => {
      setKeyType(value as KeyTypeEnum);
      const usageData = getKeyUsageData(value);
      const updatedCreationData: CreationDataPropsType = { ...creationData, keyType: value, keyUsageData: usageData };
      updateCreationData(updatedCreationData);
    },
    [creationData],
  );

  const onKeyUsageChange = useCallback(
    (value: string) => {
      setKeyUsage(value as KeyUsageEnum);
      const specData = getKeySpecData(keyType, value);
      const updatedCreationData: CreationDataPropsType = {
        ...creationData,
        keyUsage: value,
        keyUsageError: '',
        keySpecData: specData,
      };
      updateCreationData(updatedCreationData);
    },
    [creationData, keyType],
  );

  const onKeySpecChange = useCallback(
    (value: string) => {
      setKeySpec(value as KeySpecEnum);
      const updatedCreationData: CreationDataPropsType = { ...creationData, keySpec: value, keySpecError: '' };
      updateCreationData(updatedCreationData);
    },
    [creationData],
  );

  const onKeyMaterialOriginChange = useCallback(
    (value: string) => {
      setKeyMaterialOrigin(value as KeyMaterialOriginEnum);
      const updatedCreationData: CreationDataPropsType = { ...creationData, origin: value };
      updateCreationData(updatedCreationData);
    },
    [creationData],
  );

  const onRegionalityChange = useCallback((value: string) => {
    const destinationValue = value === 'true';
    setKeyRegionality(destinationValue);
    const updatedCreationData: CreationDataPropsType = { ...creationData, multiRegion: destinationValue };
    updateCreationData(updatedCreationData);
  }, [creationData]);

  return (
    <StepContainer title={title}>
      {/* Key type */}
      <RadioGroupSection
        title={'Key type'}
        data={KEY_TYPE_DATA}
        valueSelected={keyType}
        onChangeValue={value => onKeyTypeChange(value)}
      />
      {/* Key usage */}
      <RadioGroupSection
        title={'Key usage'}
        data={getKeyUsageData(keyType)}
        valueSelected={keyUsage}
        error={creationData?.keyUsageError}
        onChangeValue={value => onKeyUsageChange(value)}
      />
      {/* Key spec */}
      {getKeySpecData(keyType, keyUsage).length > 0 && (
        <RadioGroupSection
          title={'Key spec'}
          description={'Choose the type of key material in your signing key.'}
          numberOfColumn={1}
          data={getKeySpecData(keyType, keyUsage)}
          valueSelected={keySpec}
          error={creationData?.keySpecError}
          onChangeValue={value => onKeySpecChange(value)}
        />
      )}

      <CollapsibleContainer title={'Advanced options'}>
        {/* Key material origin */}
        <RadioGroupSection
          title={'Key material origin'}
          description={
            'Key material origin is a KMS key property that represents the source of the key material when creating the KMS key.'
          }
          data={keyMaterialOriginData}
          valueSelected={keyMaterialOrigin}
          onChangeValue={value => onKeyMaterialOriginChange(value)}
        />
        {protectingImportedKeyMeterialNode}
        {kmsKeyAgreementNode}
        {/* Regionality */}
        {regionalityData.length > 0 && (
          <RadioGroupSection
            title={'Regionality'}
            description={
              'Create your KMS key in a single AWS Region (default) or create a KMS key that you can replicate into multiple AWS Regions.'
            }
            data={regionalityData}
            valueSelected={keyRegionality.toString()}
            onChangeValue={value => onRegionalityChange(value)}
          />
        )}
      </CollapsibleContainer>
    </StepContainer>
  );
};

export default ConfigKey;
