import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsTLSInspectionConfigurationResponse } from 'graphql/types/AwsTlsInspectionConfiguration';

export interface DeleteTlsInspectionConfigurationVariables {
  cloudId: number;
  region: string;
  reqData: {
    tlsInspectionConfigurationArn: string;
    tlsInspectionConfigurationName: string;
  };
}

export interface DeleteTlsInspectionConfigurationResponseDataType {
  deleteAwsTLSInspectionConfiguration: IGqlResponseNewData<AwsTLSInspectionConfigurationResponse>;
}

const deleteAwsTLSInspectionConfigurationMutation = () =>
  useMutation<DeleteTlsInspectionConfigurationResponseDataType, DeleteTlsInspectionConfigurationVariables>(query);
export default deleteAwsTLSInspectionConfigurationMutation;
