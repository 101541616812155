import { useEffect, useState } from 'react';
import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import LabelInput from 'components/v2/LabelInput';
import Icon from 'components/v2/atoms/Icon';
import QRCode from 'assets/svgs/v4/sample_qr_code.svg';
import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { useAuth } from 'contexts/AuthProvider';
import { ErrorCode, ErrorMessage } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';

interface IMFASetupDeviceModalModalProps extends IBaseModalProps {
  content?: () => JSX.Element;
  buttonTitle?: string;
  onConfirm?: (code1: string) => void;
}

const MFASetupDeviceModal = ({
  title,
  content,
  buttonTitle,
  onConfirm,
  ...baseModalProps
}: IMFASetupDeviceModalModalProps) => {
  const { token } = useAuth();
  const [code1, setCode1] = useState('');
  const [qrImage, setQrImage] = useState(QRCode);

  const sendApi = () => {
    //todo gql 쏘고 부모한테 결과 올려주기
    onConfirm && onConfirm(code1);
  };
  const { mutateAsync: _genQrcode } = useMutation((payload: string) => apis.User.genQrcode(payload));

  const getQrcodeImage = () => {
    _genQrcode(token)
      .then(({ data }) => {
        if (data.result === ErrorCode.SUCCESS) {
          setQrImage(data.data[0].base64Image);
        } else {
          useToast(ErrorCode.UNKNOWN);
        }
      })
      .catch(() => {
        baseModalProps.onClose && baseModalProps.onClose();
        useToast(ErrorCode.UNKNOWN);
      });
  };

  useEffect(() => {
    if (baseModalProps.open) {
      setCode1('');
      getQrcodeImage();
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal title={title} className="uniform" closeIcon={false} {...baseModalProps}>
      <div className="mfa-setup-device-modal">
        <h5>{content && content()}</h5>
        <div className="step-box">
          <div className="step-info-box">
            <div className="flex">
              <label>01.</label>
              Install a compatible application such as Google Authenticator or Authy app on your mobile device or
              computer.
            </div>
          </div>
          <div className="step-info-box flex col a-center">
            <div className="flex">
              <label>02.</label>
              <pre>
                Open your authenticator app, choose <span>Show QR code</span> on this page, then use the app to scan the
                code. Alternatively, you can type a secret key.
              </pre>
            </div>
            <div className="qr-box flex a-center j-center">
              <Icon width={250} height={250} src={qrImage} />
            </div>
          </div>
          <div className="step-info-box">
            <div className="flex">
              <label>03.</label>
              Type a MFA code below
            </div>
          </div>
        </div>
        <div className="input-box">
          <LabelInput
            title="Enter a code from your virtual app below"
            value={code1}
            onChangeValue={(val: string) => setCode1(val)}
            required
            placeholder="MFA Code"
          />
        </div>
        <div className="btns flex j-end">
          <button className="big-sub-btn-body2regular flex j-center a-center" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button
            className="big-main-btn-body2regular flex j-center a-center"
            onClick={sendApi}
            disabled={code1 === ''}
          >
            {buttonTitle}
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default MFASetupDeviceModal;
