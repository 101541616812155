import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsGetUserPolicyResponse } from 'graphql/types/AwsGetUserPolicyResponse';

export interface IAwsValidateResourcePolicyVariables {
  cloudId: number;
  region: string;
  reqData: {
    resourcePolicy: string;
    secretId?: string;
  };
}

export interface IAwsValidationErrors {
  checkName: string;
  errorMessage: string;
}
export interface IAwsValidateResourcePolicyResponse {
  policyValidationPassed: boolean;
  validationErrors: IAwsValidationErrors;
}
export interface IAwsValidateResourcePolicyData {
  getAwsValidateResourcePolicy: IGqlResponseNewData<IAwsValidateResourcePolicyResponse>;
}

const lazyGetAwsValidateResourcePolicy = () =>
  useLazyQuery<IAwsValidateResourcePolicyData, IAwsValidateResourcePolicyVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsValidateResourcePolicy;
