import { Fragment, useCallback, useMemo, useState } from 'react';
import { RegionalityDetailPropsType } from './types';
import DetailMultiColumn, { DetailMultiColumProps } from 'layouts/v3/MgdLayout/components/DetailMultiColumns';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import CopyableText from 'components/v3/CopyableText';
import { handleFormatText } from 'utils/Common';
import Table from 'components/v2/dataDisplay/Table';
import ButtonGroup from 'pages/v2/Organ/Management/components/ButtonGroup';

const Regionality = (props: RegionalityDetailPropsType) => {
  const { metaData, actionButtons } = props;

  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 100,
    itemPerPage: 100,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const keySectionData = useMemo((): DetailMultiColumProps[] => {
    const overviewTitle =
      metaData?.multiRegionConfiguration?.multiRegionKeyType === 'PRIMARY' ? 'Primary key' : 'Replica key';
    const overviewDescription =
      metaData?.multiRegionConfiguration?.multiRegionKeyType === 'PRIMARY'
        ? `This is a multi-Region primary key. It has ${metaData?.multiRegionConfiguration?.replicaKeys?.length} replica. You can change any replica to the primary key.`
        : 'This is a replica key. Replica keys share key material with their related multi-Region keys.';
    return [{ title: overviewTitle, subTitle: overviewDescription }];
  }, [metaData]);

  const columns = useMemo((): ColumnType[] => {
    return [
      { label: '', field: '', sort: false, width: 16 },
      { label: 'Regions', field: 'regions', sort: true },
      {
        label: 'Key ARN',
        field: 'keyArn',
        sort: true,
        renderCell: row => {
          return <CopyableText value={row.keyArn} text={handleFormatText(row.keyArn)} />;
        },
      },
      { label: 'Status', field: 'status', sort: true },
      { label: 'Regionality', field: 'regionality', sort: true },
    ];
  }, []);

  const rows = useMemo((): RowType[] => {
    const regionalitySectionContentRows: RowType[] = [];

    if (metaData.multiRegionConfiguration.multiRegionKeyType !== 'PRIMARY') {
      const primaryRegion = metaData.multiRegionConfiguration.primaryKey;
      regionalitySectionContentRows.push({
        regions: primaryRegion.region,
        keyArn: primaryRegion.arn,
        status: metaData.keyState,
        regionality: 'Primary',
      });
    } else {
      const replicaRegion = metaData.multiRegionConfiguration.replicaKeys;
      replicaRegion.map(item => {
        regionalitySectionContentRows.push({
          regions: item.region,
          keyArn: item.arn,
          status: metaData.keyState,
          regionality: 'Replica',
        });
      });
    }
    return regionalitySectionContentRows;
  }, [metaData]);

  const rowsCurrentPage = useMemo(() => {
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(rows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [rows, mainTablePagination]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setMainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  return (
    <Fragment>
      <DetailMultiColumn
        data={keySectionData}
        handleHDetailHyperlinkMainClick={() => {}}
        currentTab={{ id: '', name: '', title: '' }}
      />
      <div className="detail-block-container">
        <div className="header-container">
          <div className="table-header">Related multi-Region keys</div>
          <ButtonGroup buttons={actionButtons ?? []} />
        </div>
        <Table
          rows={rowsCurrentPage}
          columns={columns}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              updateTablePagination('target', target);
              updateTablePagination('direction', dir);
            },
          }}
          isLoading={false}
          horizontalScrollable={true}
        />
      </div>
    </Fragment>
  );
};

export default Regionality;
