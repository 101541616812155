import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeDbClusters($reqData: AwsDescribeDbClustersRequest, $cloudId: Long, $region: String) {
  getAwsDescribeDbClusters(describeDbClustersRequest: $reqData, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeDbClustersResponse {
        dBClusters {
          engine
          dbClusterIdentifier
        	status
          clusterCreateTime
          endpoint {
            address
            hostedZoneId
            port
          }
        }
        marker
      }
    }
  }
}`;
export default query;