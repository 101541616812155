import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import ResetIcon from 'assets/svgs/v3/ico_reset.svg';
import IconStateful from 'assets/svgs/v3/ico_stateful_actions.svg';
import IconStateless from 'assets/svgs/v3/ico_stateless_actions.svg';
import Icon from 'components/v2/atoms/Icon';
import Table from 'components/v2/dataDisplay/Table';
import BaseModal from 'components/v2/modals/BaseModal';
import lazyGetAwsAllListRuleGroupsFirewall from 'graphql/queries/getAwsAllListRuleGroupsFirewall';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import { useEffect, useMemo, useState } from 'react';
import { IBaseFirewallModalProps } from '../../../types';
import './index.scss';
import lazyGetAwsDescribeRuleGroup from 'graphql/queries/getAwsDescribeRuleGroup';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';

interface IUnmanagedStateProps extends IBaseFirewallModalProps {
  rows: RowType[];
  columns: ColumnType[];
  isStateless: boolean;
  onAdd: (selectedRows: RowType[]) => void;
}

const UnmanagedStateModal = ({
  cloudId,
  region,
  isStateless,
  rows,
  columns,
  onAdd,
  ...baseModalProps
}: IUnmanagedStateProps) => {
  const [rowCheckedList, setRowCheckedList] = useState<RowType[]>([]);
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [getAwsAllListRuleGroupsFirewall, { loading: getAwsAllListRuleGroupsFirewallLoading }] =
    lazyGetAwsAllListRuleGroupsFirewall();

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return mainTblRows.slice(startIndex, endIndex);
  }, [mainTblTotal, mainTablePagination]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleRowsChecked = (rowIds: string[]) => {
    const rows = mainTblRows.filter(r => rowIds.includes(r?.id?.toString() || ''));
    setRowCheckedList(rows);
  };

  const handleGetAllListRuleGroups = async () => {
    try {
      const variables = {
        cloudId,
        region,
        request: {
          type: isStateless ? 'STATELESS' : 'STATEFUL',
        },
      };
      const { data: listRuleGroupsFirewall } = await getAwsAllListRuleGroupsFirewall({
        variables,
      });

      if (!listRuleGroupsFirewall?.getAwsAllListRuleGroupsFirewall?.data?.length) {
        return;
      }

      const allRows: RowType[] = listRuleGroupsFirewall.getAwsAllListRuleGroupsFirewall.data.map(item => ({
        id: item.arn,
        name: item.name,
      }));

      const filteredRows = allRows.filter(a => !rows.some(r => a.id === r.id));
      setMainTblRows(filteredRows);
      setMainTblTotal({
        totalPage: Math.ceil(filteredRows.length / mainTablePagination.itemPerPage),
        totalElement: filteredRows.length,
      });
    } catch (error) {}
  };

  useEffect(() => {
    handleGetAllListRuleGroups();
  }, []);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={isStateless ? IconStateless : IconStateful} />
          Add unmanaged {isStateless ? 'stateless' : 'stateful'} rule groups
        </>
      )}
      {...baseModalProps}
    >
      <div className="unmanaged-state-modal">
        <p className="note">
          {' '}
          Select and add the {isStateless ? 'stateless' : 'stateful'} rule groups that you want in your firewall policy.
        </p>
        <div className="unmanaged-state">
          <div className="row-1">
            <p className="text-title">{isStateless ? 'Stateless' : 'Stateful'} rule group</p>
            <button className="reset-btn" onClick={handleGetAllListRuleGroups}>
              <img src={ResetIcon} width={24} height={24} />
            </button>
          </div>

          <div className="row-2">
            <Table
              rows={mainRowsCurrentPage}
              columns={columns}
              className={'table-state-rule'}
              isLoading={getAwsAllListRuleGroupsFirewallLoading}
              reportCheckedList={(checkedList: string[]) => handleRowsChecked(checkedList)}
              reportSelected={(id: string) => handleRowsChecked([id])}
              horizontalScrollable={true}
            />
            {mainRowsCurrentPage?.length && !getAwsAllListRuleGroupsFirewallLoading ? (
              <div className="fleet-instance pagination-wrapper flex a-center">
                <p className="flex a-center">
                  Total <span>{mainTblTotal.totalElement}</span>
                </p>

                <TableManagePagination
                  ableFetchMore={false}
                  currentPage={mainTablePagination.currentPage}
                  updateCurrentPage={page => updateTablePagination('currentPage', page)}
                  totalPage={mainTblTotal.totalPage}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className={'button-group'}>
          <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={() => baseModalProps?.onClose?.()} />
          <div className="save-btn">
            <Button
              label={`Add ${isStateless ? 'stateless' : 'stateful'} rule group`}
              type={ButtonTypeEnum.PRIMARY}
              disabled={mainTblRows.length === 0}
              loading={false}
              onClick={() => onAdd(rowCheckedList)}
            />
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default UnmanagedStateModal;
