import { KeyRotationDetailPropsType } from './types';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import ButtonGroup from 'pages/v2/Organ/Management/components/ButtonGroup';

const KeyRotation = (props: KeyRotationDetailPropsType) => {
  const { actionButtons, rotationContent, loading } = props;

  return (
    <DetailTab
      title={'Automatic key rotation'}
      description={'AWS KMS automatically rotates the key based on the rotation period that you define.'}
      data={rotationContent}
      isApiLoading={loading}
      actions={<ButtonGroup buttons={actionButtons ?? []} />}
    />
  );
};

export default KeyRotation;
