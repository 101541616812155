import { ConfigAdvanceSettingPropsType, CreationDataPropsType } from './types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import StepContainer from 'pages/v2/Organ/Management/components/StepContainer';
import SectionContainer from 'pages/v2/Organ/Management/components/SectionContainer';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import InputGroup from 'pages/v2/Organ/Management/components/InputGroup';
import IconSearch from 'assets/svgs/v3/ico_search.svg';
import AutoCompleteDropdownAtom from 'components/v2/atoms/AutoCompleteDropdownAtom';
import Button from 'pages/v2/Organ/Management/components/Button';
import { AwsKeyListEntry } from 'graphql/types/AwsListKey';
import { AwsAlias } from 'graphql/types/AwsAliases';
import { DropdownListDataType } from 'components/molecules/Dropdown/types';
import { AwsEncryptionConfigurationLogType } from 'graphql/types/AwsCreateFirewall';
import { ENCRYPTION_CONFIGURATION_DATA } from '../configs';
import CopyableText from 'components/v3/CopyableText';
import lazyGetAwsListKeys, { AwsListKeyVariablesType } from 'graphql/queries/getAwsListKeys';
import lazyGetAwsAllListAliasesPage, { IAwsAllListAliasesVariables } from 'graphql/queries/getAwsAllListAliases';
import lazyGetAwsDescribeKey, { IAwsDescribeKeyVariables } from 'graphql/queries/getAwsDescribeKey';
import './index.scss';

interface AwsKeyDetail {
  keyArn: string;
  keyStatus: boolean;
  keyAlias: string;
}

const ConfigAdvanceSetting = (props: ConfigAdvanceSettingPropsType) => {
  const { title, cloudId, region, creationData, updateCreationData } = props;

  // API
  const [getAwsListKeys] = lazyGetAwsListKeys();
  const [getAwsDescribeKey, { loading: getDesribeKeyLoading }] = lazyGetAwsDescribeKey();
  const [getAwsAllListAliases] = lazyGetAwsAllListAliasesPage();

  const [delteProtectionValue, setDeleteProtectionValue] = useState<boolean>(creationData.deleteProtection);
  const [subnetChangeProtectionValue, setSubnetChangeProtectionValue] = useState<boolean>(
    creationData.subnetChangeProtection,
  );
  const [customizeEncryptionValue, setCustomizeEncryptionValue] = useState<boolean>(
    creationData.customizeEncryptionSetting,
  );

  const [awsListKey, setAwsListKey] = useState<AwsKeyListEntry[]>([]);
  const [awsAlias, setAwsAlias] = useState<AwsAlias[]>([]);
  const [kmsSelected, setKmsSelected] = useState<DropdownListDataType>();
  const [awsKeyDetail, setAwsKeyDetail] = useState<AwsKeyDetail | null>(null);
  const [detailVisible, setVisibleDetailConent] = useState<boolean>(false);

  const dropdownListKeys = useMemo(() => {
    return awsListKey.reduce((data, awsKey) => {
      const alias = awsAlias.find(item => item.targetKeyId === awsKey.keyId);
      let description = '';

      if (alias) {
        const startIdx = alias.aliasName.lastIndexOf('/') + 1;
        const endIdx = alias.aliasName.length;
        description = alias.aliasName.substring(startIdx, endIdx);
      }

      data.push({
        name: awsKey.keyId,
        value: awsKey.keyId,
        description,
      });

      return data;
    }, [] as DropdownListDataType[]);
  }, [awsAlias, awsListKey]);

  const onDeleteProtectionChange = useCallback(
    (value: boolean) => {
      setDeleteProtectionValue(value);
      const creationDateUpdated: CreationDataPropsType = {
        ...creationData,
        deleteProtection: value,
      };
      updateCreationData(creationDateUpdated);
    },
    [creationData],
  );

  const onSubnetChangeProtectionChange = useCallback(
    (value: boolean) => {
      setSubnetChangeProtectionValue(value);
      const creationDateUpdated: CreationDataPropsType = {
        ...creationData,
        subnetChangeProtection: value,
      };
      updateCreationData(creationDateUpdated);
    },
    [creationData],
  );

  const onCustomerManagedKeyChange = useCallback(
    (value: boolean) => {
      setCustomizeEncryptionValue(value);
      let encryptionConfiguration = creationData.encryptionConfiguration;
      if (!value) {
        encryptionConfiguration = { type: ENCRYPTION_CONFIGURATION_DATA.AWS_OWNED_KMS_KEY}
      }
      const creationDateUpdated: CreationDataPropsType = {
        ...creationData,
        encryptionConfiguration: encryptionConfiguration,
        customizeEncryptionSetting: value,
      };
      updateCreationData(creationDateUpdated);
    },
    [creationData],
  );

  const fetchAllListAliases = useCallback(async () => {
    const variables: IAwsAllListAliasesVariables = {
      cloudId,
      region,
      request: {},
    };
    const response = await getAwsAllListAliases({ variables });
    const aliases = response.data?.getAwsAllListAliases?.data || [];
    setAwsAlias(aliases);
  }, []);

  const fetchAllListKeys = useCallback(async () => {
    const variables: AwsListKeyVariablesType = {
      cloudId,
      region,
      request: {},
    };

    const response = await getAwsListKeys({ variables });
    const listKeys = response.data?.getAwsListKeys?.data?.[0]?.keys || [];
    setAwsListKey(listKeys);
  }, []);

  const onRemoveKmsKey = useCallback(() => {
    setKmsSelected(undefined);
    const updatedCreationData: CreationDataPropsType = {
      ...creationData,
      encryptionConfiguration: { type: ENCRYPTION_CONFIGURATION_DATA.AWS_OWNED_KMS_KEY },
    };
    updateCreationData(updatedCreationData);
  }, [creationData]);

  const onOptionSelected = useCallback(
    (optionSelected: DropdownListDataType) => {
      const keyId = optionSelected.value.toString();
      setKmsSelected(optionSelected);
      const variables: IAwsDescribeKeyVariables = {
        cloudId,
        region,
        request: {
          keyId: keyId,
        },
      };
      getAwsDescribeKey({ variables }).then(({ data: response }) => {
        const keyMetadata = response?.getAwsDescribeKey?.data?.[0].keyMetadata;
        const alias = awsAlias.find(item => item.targetKeyId === keyId)?.aliasName?.split('/')?.[1] ?? '';

        const keyDetail: AwsKeyDetail = {
          keyStatus: keyMetadata?.enabled ?? false,
          keyArn: keyMetadata?.arn || '',
          keyAlias: alias,
        };
        setVisibleDetailConent(true);
        setAwsKeyDetail(keyDetail);
      });
      // update creation data
      // encryptionConfiguration
      const encryptionConfiguration: AwsEncryptionConfigurationLogType = {
        type: ENCRYPTION_CONFIGURATION_DATA.CUSTOMER_KMS,
        keyId: keyId,
      };
      const updatedCreationData: CreationDataPropsType = {
        ...creationData,
        encryptionConfiguration: encryptionConfiguration,
        encryptionConfigurationError: '',
      };
      updateCreationData(updatedCreationData);
    },
    [cloudId, region, awsAlias, creationData],
  );

  useEffect(() => {
    fetchAllListKeys();
    fetchAllListAliases();
  }, []);

  useEffect(() => {
    if (creationData?.encryptionConfiguration && creationData.customizeEncryptionSetting) {
      const optionSelected = dropdownListKeys.find(item => item.value === creationData.encryptionConfiguration?.keyId);
      if (optionSelected) {
        onOptionSelected(optionSelected);
      }
    }
  }, [dropdownListKeys]);

  return (
    <StepContainer
      title={title}
      description={
        'Enable protection against changes and configure a customer manages AW5 Key Management Service (KMS) key to encrypt and decrypt your resources.'
      }
    >
      <SectionContainer
        title="Protection against changes"
        description={'Protect your firewall from accidental deletion and against changes to subnet associations.'}
      >
        <InputGroup>
          <div className="input-section-title-container">
            <p className="title">Delete protection</p>
            <p className="description">
              Protects the firewall from deletion. If enabled, Network firewall won’t delete the firewall if it’s in
              use.
            </p>
          </div>

          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <CheckboxAtom
              checked={delteProtectionValue}
              onchange={() => onDeleteProtectionChange(!delteProtectionValue)}
              label={'Enable'}
            />
          </div>
        </InputGroup>

        <InputGroup>
          <div className="input-section-title-container">
            <p className="title">Subnet change protection</p>
            <p className="description">
              Protects the firewall against changes to the subnet associations. If enabled, you can’t change in active
              firewall’s subnet associations.
            </p>
          </div>

          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <CheckboxAtom
              checked={subnetChangeProtectionValue}
              onchange={() => onSubnetChangeProtectionChange(!subnetChangeProtectionValue)}
              label={'Enable'}
            />
          </div>
        </InputGroup>
      </SectionContainer>

      <SectionContainer
        title="Customer managed key"
        description={
          'You can use a customer managed key in AWS Key Management Service (KMS) to encrypt your data at rest. If you don’t configure a customer managed key, Network Firewall encrypts your data using an AWS managed key.'
        }
      >
        <InputGroup>
          <div className="input-section-title-container">
            <p className="description">
              Your data is encrypted by default with a key that AWS owns and manages for you. To choose a different key,
              customize your encryption settings.
            </p>
          </div>

          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <CheckboxAtom
              checked={customizeEncryptionValue}
              onchange={() => onCustomerManagedKeyChange(!customizeEncryptionValue)}
              label={'Customize encryption settings (advanced)'}
              description={`${customizeEncryptionValue ? 'To use the default key, clear this option.' : ''}`}
            />
          </div>

          {customizeEncryptionValue && (
            <Fragment>
              <div className="input-section-title-container">
                <p className="title">Choose an AWS KMS key</p>
                <p className="description">This key will be used for encryption instead of the default key.</p>
              </div>

              <div className="input-group-container">
                <AutoCompleteDropdownAtom
                  icon={IconSearch}
                  data={dropdownListKeys}
                  value={kmsSelected?.value.toString()}
                  placeholder="Choose an AWS KMS key or enter an ARN"
                  handleClick={val => onOptionSelected(val)}
                  handleChange={() => {}}
                  handleAddClass={() => {}}
                  error={creationData?.encryptionConfigurationError}
                />
                <Button
                  label="Remove"
                  onClick={() => {
                    onRemoveKmsKey()
                  }}
                />
              </div>

              {detailVisible && kmsSelected && !getDesribeKeyLoading && (
                <div className="kms-container">
                  <p className="text-title">AWS KMS key details</p>
                  <div className="kms-key-detail">
                    <p className="text-title">Key ARN</p>
                    <CopyableText text={awsKeyDetail?.keyArn || ''} />
                  </div>
                  <div className="kms-key-detail">
                    <p className="text-title">Key status</p>
                    <p className="text-description">{awsKeyDetail?.keyStatus ? 'Enabled' : 'Disabled'}</p>
                  </div>
                  <div className="kms-key-detail">
                    <p className="text-title">Key aliases</p>
                    <p className="text-description">{awsKeyDetail?.keyAlias}</p>
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </InputGroup>
      </SectionContainer>
    </StepContainer>
  );
};

export default ConfigAdvanceSetting;
