import { gql } from '@apollo/client';

const query = gql`
  mutation associateAwsSubnets($request: AwsAssociateSubnetsRequest, $cloudId: Long, $region: String) {
    associateAwsSubnets(
      associateSubnetsRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      nextToken
      data {
        ... on AwsAssociateSubnetsResponse {
          firewallName
          firewallArn
          subnetMappings {
            subnetId
            ipAddressType
          }
          updateToken
        }
      }
    }
  } 
`;
export default query;