import { gql } from '@apollo/client';

const query = gql`
  mutation putAwsSecretValue($reqData: AwsPutSecretValueRequest, $cloudId: Long, $region: String) {
    putAwsSecretValue(awsPutSecretValueRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsPutSecretValueResponse {
          arn
          namespace
          versionId
          versionStages
        }
      }
    }
  }
`;
export default query;
