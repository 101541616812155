import { gql } from '@apollo/client';

const query = gql`
  mutation deleteAwsAllowList($request: AwsDeleteAllowListRequest, $cloudId: Long, $region: String) {
    deleteAwsAllowList(awsDeleteAllowListRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        __typename
      }
    }
  }
`;
export default query;
