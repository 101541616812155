import StepContainer from 'pages/v2/Organ/Management/components/StepContainer';
import SectionContainer from 'pages/v2/Organ/Management/components/SectionContainer';
import InputGroup from 'pages/v2/Organ/Management/components/InputGroup';
import Dropdown from 'components/molecules/Dropdown';
import FirewallSubnet from '../components/FirewallSubnet';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import { ConfigVpcAndSubnetPropsType, CreationDataPropsType } from './types';
import { useCallback, useEffect, useState } from 'react';
import { DropdownListDataType } from 'components/molecules/Dropdown/types';
import { AwsSubnetType } from 'graphql/types/AwsSubnet';
import { FirewallSubnetRowDataPropsType } from '../components/types';
import lazyGetAllAwsVpc, { IAllAwsVpcVariables } from 'graphql/queries/getAwsAllVPC';
import lazyGetAwsSubnetsPageByParam, { IAwsSubnetsVariables } from 'graphql/queries/getAwsSubnets';
import './index.scss';
import _ from 'lodash';

const ConfigVpcAndSubnet = (props: ConfigVpcAndSubnetPropsType) => {
  const { title, cloudId, region, creationData, updateCreationData } = props;

  // API
  const [getAllAwsVpc] = lazyGetAllAwsVpc();
  const [getAwsSubnets] = lazyGetAwsSubnetsPageByParam();

  // State
  const [vpcData, setVpcData] = useState<DropdownListDataType[]>([]);
  const [selectedVpc, setSelectedVpc] = useState<DropdownListDataType>();
  const [subnetsByVpc, setSubnetsByVpc] = useState<AwsSubnetType[]>([]);

  const fetchVpcData = useCallback(() => {
    const reqVariable: IAllAwsVpcVariables = { cloudId, region, request: {} };
    getAllAwsVpc({ variables: reqVariable }).then(({ data: response }) => {
      const vpcs = response?.getAwsAllVPC?.data;
      if (vpcs && vpcs.length > 0) {
        const listVpc: DropdownListDataType[] = [];
        vpcs.map((vpc, idx) => {
          const vpcName = vpc?.tags?.find(tag => tag.key === 'Name')?.value ?? vpc.vpcId;
          listVpc.push({
            id: idx,
            value: vpc.vpcId,
            name: vpcName,
            description: `${vpc.cidrBlock}    ${vpc.vpcId}`,
            // isVertical: true,
          });
        });
        setVpcData(listVpc);
      }
    });
  }, [cloudId, region]);

  const onVpcDropdownChange = useCallback(
    (value: DropdownListDataType, inital?: boolean) => {
      setSelectedVpc(value);
      const reqVariable: IAwsSubnetsVariables = {
        cloudId,
        region,
        request: { filters: { name: 'vpc-id', values: [value.value.toString()] } },
      };
      getAwsSubnets({ variables: reqVariable }).then(({ data: response }) => {
        const subnets = response?.getAwsSubnets?.data?.[0]?.subnets;
        if (subnets && subnets.length > 0) {
          setSubnetsByVpc(subnets);
        }
      });
      
      if (!inital) {
        const updatedCreationData: CreationDataPropsType = {
          ...creationData,
          vpcId: value.value.toString(),
          vpcIdError: false,
          listFirewallSubnet: [
            {
              availabilityZoneData: [],
              availabilityZone: { value: '', name: '' },
              availabilityZoneError: false,
              subnet: { value: '', name: '' },
              subnetError: false,
              ipAddress: { value: '', name: '' },
              ipAddressError: false,
            },
          ],
          listFirewallSubnetError: '',
        };
        updateCreationData(updatedCreationData);
      }
      
    },
    [cloudId, region],
  );

  const onListSubnetChange = useCallback(
    (firewallSubnets: FirewallSubnetRowDataPropsType[]) => {
      const updatedCreationData: CreationDataPropsType = { ...creationData, listFirewallSubnet: firewallSubnets };
      updateCreationData(updatedCreationData);
    },
    [creationData],
  );

  useEffect(() => {
    fetchVpcData();
  }, []);

  useEffect(() => {
    if (creationData.vpcId !== '') {
      const vpc = vpcData.find(item => item.value === creationData.vpcId);
      if (vpc) {
        setSelectedVpc(vpc);
        onVpcDropdownChange(vpc, true);
      }
    }
  }, [vpcData]);

  return (
    <StepContainer
      title={title}
      description={
        'The firewall protects the subnets within an Amazon Virtual Private Cloud (VPC) by filtering traffic going between the subnets and locations outside of your VPC. After you create the firewall and its associated firewall policy, configure your VPC to route traffic through the endpoints created by the firewall.'
      }
    >
      <SectionContainer
        title="VPC"
        description={
          'For each Availability Zone where you want protection, provide Network Firewall with a public subnet that’s dedicated to the firewall endpoint. Only use the firewall subnets that you specify here for the firewall. Don’t use them for any other purpose.'
        }
      >
        <InputGroup>
          <Dropdown
            id={_.uniqueId('dropdown-')}
            className={'mw-60'}
            data={vpcData}
            value={selectedVpc}
            handleClick={value => onVpcDropdownChange(value)}
            placeholder={'Choose a VPC'}
            isVertical={true}
            disabled={vpcData.length === 0}
            error={creationData.vpcIdError}
            description={'Choose the VPC where you want to.create this firewall.'}
            title={'VPC'}
          />
        </InputGroup>

        <InputGroup>
          <div className="input-section-title-container">
            <p className="title">Firewall subnets</p>
            <p className="description">
              Each subnet must have on available IP address. You can’t change the Subnet’s IP address type after
              creation.
            </p>
          </div>

          {/* render firewall subnets */}
          <FirewallSubnet
            subnets={subnetsByVpc}
            currentFirewallSubnetRows={creationData.listFirewallSubnet}
            onListSubnetChange={subnests => onListSubnetChange(subnests)}
          />

          {creationData.listFirewallSubnet.length === 0 && creationData?.listFirewallSubnetError && (
            <div className="error-container right-column-content">
              <img src={InputErrorIcon} width={16} height={16} />
              <p className="error-message">{creationData?.listFirewallSubnetError}</p>
            </div>
          )}
        </InputGroup>
      </SectionContainer>
    </StepContainer>
  );
};

export default ConfigVpcAndSubnet;
