import './index.scss';
import Logo from 'assets/svgs/v2/auth_logo.svg';
import LabelInput from 'components/v2/LabelInput';
import VerticalSplitLinkGroup from 'components/v2/VerticalSplitLinkGroup';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authLinks, termLinks } from 'utils/Links';
import NotificationAtom from 'components/v2/atoms/NotificationAtom';
import { useMutation } from 'react-query';
import { ICheckMfaReq, ILoginReq } from 'apis/v2/Auth/schema';
import apis from 'apis/v2/index';
import { ErrorCode, ErrorMessage } from '@Types/error';
import { useAuth } from 'contexts/AuthProvider';
import { useToast } from 'hooks/v2/useToast';

const LoginPage = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({ id: '', pw: '' });
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [passwordKorean, setPasswordKorean] = useState(false);
  const [step, setStep] = useState({step: 0, token: ''});
  const [passcodeTryCount, setPasscodeTryCount] = useState(0);
  const [loadingMfaResponse, setLoadingMfaResponse] = useState(false);

  const { mutateAsync: _login } = useMutation((payload:ILoginReq) => apis.Auth.Login(payload));
  const { mutateAsync: _getStatus } = useMutation((token: string) => apis.User.getStatus({ userToken: token }));
  const { mutateAsync: _checkMfa } = useMutation((payload:ICheckMfaReq) => apis.Auth.checkMfa(payload));
  
  const disabledLoginBtn = useMemo(() => {
    if (inputs.id.length === 0 || inputs.pw.length === 0) {
      return true;
    } else {
      return false;
    }
  },[inputs]);
  const updateInputs = (key: 'id' | 'pw', value: string) => {
    if(key === 'pw') {
      setError('');
      setPasswordKorean(/[ㄱ-힣]/g.test(value));
    }
    setInputs(prev => ({ ...prev, [key]: value }));
  };
  const tryLogin = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    _login({
      userId: inputs.id,
      userPw: inputs.pw,
      authTypeCode: 'password'
    }).then(({ data: loginData }) => {
      if (loginData.result === ErrorCode.SUCCESS || loginData.result === ErrorCode.PARTIAL_SUCCESS) {
        //이메일 인증 했는지 확인
        setError('');
        _getStatus(loginData.data[0].userToken || '').then(({ data }) => {
          if (data.result === ErrorCode.EMAIL_UNAUTHORIZED) {
            //이메일 인증 안했으니까 회원가입 이메일 인증 페이지로 이동, 로그인 상태는 저장
            login(loginData.data[0].userToken || '', false);
            navigate('/auth/signup-sendemail', { state: { access: true, userId: inputs.id }});
          } else {
            //이메일 인증 했으니까 정상적으로 이동
            /** 
             * todo MFA setting user 면 다음 step 으로 이동
             * 아니면 login 진행
             * */
            // if() {
            //   setStep({ step: 1, token: loginData.data(0).userToken });
            // } else {
              login(loginData.data[0].userToken || '', true);
            // }
          }
        });
      } else if (loginData.result === ErrorCode.MFA_REQUIRED) {
        setPasscodeTryCount(0);
        setStep({step: 1, token: loginData.data[0].mfaToken || ''})
      } else if (loginData.result === ErrorCode.INVALID_USER) {
        setError('The ID (email) or password you entered is incorrect. (er0005)');
      } else if (loginData.result === ErrorCode.USER_INFO_NOT_FOUND) {
        setError('Please check the requested data. (er0011)');
      } else {
        setError(ErrorMessage[loginData.result] || 'An unknown error has occurred. (er9999)');
      }
    });
  };
  const tryLoginWithCode = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoadingMfaResponse(true);
    _checkMfa({ mfaToken: step.token, passcode: code}).then(({data: mfaData}) => {
      setLoadingMfaResponse(false);
      if(mfaData.result === ErrorCode.SUCCESS || mfaData.result === ErrorCode.PARTIAL_SUCCESS) {
        _getStatus(mfaData.data[0].userToken || '').then(({ data }) => {
          if (data.result === ErrorCode.EMAIL_UNAUTHORIZED) {
            //이메일 인증 안했으니까 회원가입 이메일 인증 페이지로 이동, 로그인 상태는 저장
            login(mfaData.data[0].userToken || '', false);
            navigate('/auth/signup-sendemail', { state: { access: true, userId: inputs.id }});
          } else {
            //이메일 인증 했으니까 정상적으로 이동
            login(mfaData.data[0].userToken || '', true);
          }
        });
      } else if(mfaData.result === 'er0005') {
        useToast(mfaData.result, "MFA code is wrong. Check and retry.")
        if(passcodeTryCount + 1 >= 3) {
          navigate('/');
        } else {
          setPasscodeTryCount(prev => prev + 1);
        }
      } else {
        useToast(ErrorCode.UNKNOWN);
      }
    }).catch(() => {
      setLoadingMfaResponse(false);
      useToast(ErrorCode.UNKNOWN);
    });
  }
  const renderBody = useMemo(() => {
    switch (step.step) {
    case 0:
      return (
        <div className="content">
          <h2>Login</h2>
          <form onSubmit={tryLogin}>
            <LabelInput 
              title="ID or email" 
              id="login-id" 
              required
              placeholder="Input your ID or email"
              value={inputs.id} 
              onChangeValue={(value: string) => updateInputs('id', value)} 
            />
            <LabelInput 
              title="Password" 
              required
              type="password" 
              id="login-pw" 
              placeholder="Input your password"
              value={inputs.pw} 
              onChangeValue={(value: string) => updateInputs('pw', value)} 
            />
            {passwordKorean && <div className="password-korean-error">Unsupported characters are included in your password.</div>}
            {error !== '' && 
            <NotificationAtom text={error} />
            }
            <button className="submit-btn big-main-btn-body2regular" disabled={disabledLoginBtn || passwordKorean}>Login</button>
          </form>
          <VerticalSplitLinkGroup list={authLinks} />
        </div>
      );
    case 1:
      return (
        <div className="content-step2">
          <h2>Multi-factor authentication</h2>
          <h3>Enter an MFA code to complete sign-in</h3>
          <form onSubmit={tryLoginWithCode}>
            <LabelInput 
              title="MFA Code" 
              id="login-mfa" 
              required
              placeholder="Input your MFA code"
              value={code} 
              onChangeValue={(value: string) => setCode(value)} 
            />
            {error !== '' && 
            <NotificationAtom text={error} />
            }
            <button className="submit-btn big-main-btn-body2regular" disabled={code === '' || loadingMfaResponse}>Submit</button>
          </form>
        </div>
      );
    default:
      return <></>;
    }
  },[step, inputs, code, error, passcodeTryCount, loadingMfaResponse]);

  return (
    <>
      <article id="login-page-article">
        <div className="header">
          <img src={Logo} width={91} height={30} />
        </div>
        {renderBody}
      </article>
      <VerticalSplitLinkGroup list={termLinks} />
    </>
  );
};
export default LoginPage;