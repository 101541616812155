import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import { useState } from 'react';
import TagTab from 'pages/v2/Organ/Management/components/TagTab';
import { ColumnType } from '@Types/v2/Table';
import EditFirewallPolicyDetailModal from '../../EditFirewallPolicyDetailModal';
import EditKMSEncryptionModal from '../../EditKMSEncryptionModal';
import EditPolicyVariablesModal from '../../EditPolicyVariablesModal';
import UpdateTagsModal from '../../UpdateTagsModal';
import Button from 'pages/v2/Organ/Management/WAF/WebACL/CreateWebACL/components/Button';
import { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import { getStrValueOrNull } from 'utils/Common';

const Details = ({
  firewallPolicy,
  firewallPolicyResponse,
  handleSaveTagsDetail,
  isLoading,
  isEdit,
  loadingEdit,
  handleUpdateAwsFirewallPolicy,
  handleUpdateFirewallPolicyDetail,
  handleUpdateKMSencryption,
  cloudId,
  region,
}: any) => {
  const { statefulEngineOptions, policyVariables } = firewallPolicy || {};

  const { firewallPolicyName, description, numberOfAssociations, firewallPolicyStatus, encryptionConfiguration, tags } =
    firewallPolicyResponse || {};

  const [isOpenEditDetails, setIsOpenEditDetails] = useState(false);
  const [isOpenEditCustomerManagedKey, setIsOpenEditCustomerManagedKey] = useState(false);
  const [isOpenEditPoliciesVariable, setIsOpenEditPoliciesVariable] = useState(false);
  const [isOpenEditTag, setIsOpenEditTag] = useState(false);
  
  const details: IMgdDetailKeyValueProps[] = [
    {
      title: 'Name',
      description: firewallPolicyName,
    },
    {
      title: 'Desciption',
      description: description,
    },
    {
      title: 'Use count',
      description: numberOfAssociations,
    },
    {
      title: 'Stream exception policy',
      description: statefulEngineOptions?.streamExceptionPolicy,
    },
    {
      title: 'Status',
      description: firewallPolicyStatus,
    },
  ];

  const customerManagedKey: IMgdDetailKeyValueProps[] = [
    {
      title: 'Key type',
      description: getStrValueOrNull(encryptionConfiguration?.type),
    },
    {
      title: 'Key ID',
      description: getStrValueOrNull(encryptionConfiguration?.keyId),
    },
  ];

  const customerManagedKeyDef: IMgdDetailKeyValueProps[] = [
    {
      title: 'Key type',
      description: getStrValueOrNull(encryptionConfiguration?.type),
    },
  ];
  
  const formatDesc = (arr: string[]) => {
    return `${policyVariables?.ruleVariables?.HOME_NET?.definition[0]} + ${policyVariables?.ruleVariables?.HOME_NET?.definition.length}`;
  };

  const policiesVariable: IMgdDetailKeyValueProps[] = [
    {
      type: TextTypeEnum.LINK,
      title: 'HOME_Net variable override values',
      description: policyVariables?.ruleVariables?.HOME_NET?.definition || '-',
      isTooltip: true,
    },
  ];

  const tagColumns: ColumnType[] = [
    {
      label: 'Key',
      field: 'key',
      sort: true,
    },
    {
      label: 'Value',
      field: 'value',
      sort: true,
    },
  ];

  const renderAction = (setIsOpen: (value: boolean) => void) => {
    return isEdit ? (
      <Button
        label="Edit"
        type={ButtonTypeEnum.GENERAL}
        onClick={() => setIsOpen(true)}
      />
    ) : null;
  };

  return (
    <>
      <DetailTab title="Details" data={details} actions={renderAction(setIsOpenEditDetails)} isApiLoading={isLoading} />

      <DetailTab
        title="Customer managed key"
        data={encryptionConfiguration?.keyId ? customerManagedKey : customerManagedKeyDef}
        actions={renderAction(setIsOpenEditCustomerManagedKey)}
        isApiLoading={isLoading}
      />

      <DetailTab
        title="Policies variable"
        data={policiesVariable}
        actions={renderAction(setIsOpenEditPoliciesVariable)}
        isApiLoading={isLoading}
      />

      <TagTab
        columns={tagColumns}
        rows={tags || []}
        title="Firewall tags"
        rightButtons={isEdit ? [{ id: 'edit-tags', label: 'Edit', onClick: () => setIsOpenEditTag(true) }] : []}
      />

      {isOpenEditDetails ? (
        <EditFirewallPolicyDetailModal
          header={'Edit firewall policy details'}
          firewallPolicyName={firewallPolicyName}
          description={description}
          statefulEngineOptions={statefulEngineOptions}
          open={isOpenEditDetails}
          handleUpdateFirewallPolicyDetail={handleUpdateFirewallPolicyDetail}
          loading={loadingEdit}
          onClose={() => setIsOpenEditDetails(false)}
        />
      ) : null}

      {isOpenEditCustomerManagedKey ? (
        <EditKMSEncryptionModal
          header={'Edit KMS encryption settings'}
          cloudId={cloudId}
          region={region}
          loadingSave={loadingEdit}
          encryptionConfiguration={encryptionConfiguration}
          handleUpdateKMSencryption={handleUpdateKMSencryption}
          open={isOpenEditCustomerManagedKey}
          onClose={() => setIsOpenEditCustomerManagedKey(false)}
        />
      ) : null}

      {isOpenEditPoliciesVariable ? (
        <EditPolicyVariablesModal
          header={'Edit policy variables'}
          data={policyVariables?.ruleVariables?.HOME_NET?.definition?.join('\n')}
          open={isOpenEditPoliciesVariable}
          handleUpdateAwsFirewallPolicy={handleUpdateAwsFirewallPolicy}
          onClose={() => setIsOpenEditPoliciesVariable(false)}
          loadingSave={loadingEdit}
        />
      ) : null}

      {isOpenEditTag ? (
        <UpdateTagsModal
          header={'Edit Tags'}
          subHeader={'Edit policy tags'}
          note={'You can add up to 48 more tags.'}
          columns={['Key', 'Value-option']}
          currentDatas={tags}
          onSave={handleSaveTagsDetail}
          open={isOpenEditTag}
          loadingTagsCreat={loadingEdit}
          onClose={() => setIsOpenEditTag(false)}
        />
      ) : null}
    </>
  );
};

export default Details;
