import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export type AwsEnableKeyVariablesType = {
  cloudId: number;
  region: string;
  reqData: {
    keyId?: string;
  };
};

export type AwsEnableKeyResponseDataType = {
  enableAwsKey: IGqlResponseData<{}>;
};

const enableAwsKeyMutation = () => useMutation<AwsEnableKeyResponseDataType, AwsEnableKeyVariablesType>(query);
export default enableAwsKeyMutation;
