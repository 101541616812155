import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsTagType } from 'graphql/types/AwsFirewall';
import { AwsEncryptionConfigurationLogType } from 'graphql/types/AwsFirewallPolicy';

export type AwsDeleteFirewallPolicyRequestType = {
  firewallPolicyName?: string;
  firewallPolicyArn?: string;
}
export type AwsFirewallPolicyResponseType = {
  firewallPolicyName: string;
  firewallPolicyArn: string;
  firewallPolicyId: string;
  description: string;
  firewallPolicyStatus: string;
  tags: AwsTagType[];
  consumedStatelessRuleCapacity: number;
  consumedStatefulRuleCapacity: number;
  numberOfAssociations: number;
  encryptionConfiguration: AwsEncryptionConfigurationLogType
  lastModifiedTime: string;
}
export interface IAwsFirewallPolicyVariables {
  cloudId: number;
  region: string;
  request: AwsDeleteFirewallPolicyRequestType;
}

export interface IAwsFirewallPolicyResponseData {
  deleteAwsFirewallPolicy: IGqlResponseNewData<AwsFirewallPolicyResponseType>;
}

const deleteAwsFirewallPolicyMutation = () => useMutation<IAwsFirewallPolicyResponseData, IAwsFirewallPolicyVariables>(query);
export default deleteAwsFirewallPolicyMutation;