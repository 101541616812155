import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import PageDetailTitle from "../../../components/PageDetailTitle";
import { AwsKeyDetailPropsType } from "./type";
import { IMgdDetailKeyValueProps, IMgdTabProps, TextTypeEnum } from "layouts/v3/MgdLayout";
import { DETAIL_TAB_LIST, DETAIL_TEXT_DESCRIPTION } from "./configs";
import DetailTab from "../../../components/DetailTab";
import lazyGetAwsDescribeKey, { IAwsDescribeKeyVariables } from "graphql/queries/getAwsDescribeKey";
import KeyPolicy from "./Tabs/KeyPolicy";
import CryptoConfig from "./Tabs/CryptoConfig";
import { AwsKeyMetadata } from "graphql/types/AwsKey";
import { getFormatedDateWithTimezone } from "utils/Common";

const AwsKeyDetail = (props: AwsKeyDetailPropsType) => {
  const {cloudId, region, awsKey, pageBackClick} = props;

  // API
  const [getAwsDescribeKey, {loading: describeKeyLoading}] = lazyGetAwsDescribeKey();

  // State
  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>(DETAIL_TAB_LIST[0]);
  const [describeKey, setDescribeKey] = useState<AwsKeyMetadata>();

  // Detail Section
  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    if (describeKey && awsKey) {
      return DETAIL_TEXT_DESCRIPTION.map(item => {
        let description = describeKey[item.id as keyof AwsKeyMetadata];

        if (item.id === DETAIL_TEXT_DESCRIPTION[0].id) {
          description = awsKey?.name
        }
        
        if (item.id === DETAIL_TEXT_DESCRIPTION[2].id) {
          description = getFormatedDateWithTimezone(description.toString());
        }

        if (item.id === DETAIL_TEXT_DESCRIPTION[5].id) {
          description = description ? `Multi-Region ${describeKey?.multiRegionConfiguration?.multiRegionKeyType}` : 'Single region';
        }

        return {
          id: item.id,
          type: item.type as TextTypeEnum,
          description: description,
          title: item.value,
        };
      });
    }
    return [];
  }, [describeKey, awsKey]);

  const awsKeyVariable = useMemo((): IAwsDescribeKeyVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        keyId: awsKey?.id ?? '',
      },
    }
  }, [cloudId, region, awsKey]);

  const fetchAwsDescribeKey = useCallback(() => {
    getAwsDescribeKey({ variables: awsKeyVariable }).then(({data: describeKeyResponse}) => {
      setDescribeKey(describeKeyResponse?.getAwsDescribeKey?.data?.[0]?.keyMetadata);
    });
  }, [awsKeyVariable]);

  useEffect(() => {
    fetchAwsDescribeKey();
  }, []);

  const detailContentNode = useMemo((): ReactNode => {
    switch (detailTabSelected) {
      default:
      case DETAIL_TAB_LIST[0]:
        return <KeyPolicy cloudId={cloudId} region={region} awsKey={awsKey} />;
      case DETAIL_TAB_LIST[1]:
        if (describeKey) {
          return <CryptoConfig metaData={describeKey} />;
        }
        return null;
    }
  }, [awsKey, detailTabSelected, cloudId, region, describeKey]);

  return (
    <div className="ec2-detail">
      <PageDetailTitle title={`${awsKey?.name}`} pageBackClick={pageBackClick} />
      {awsKey &&
        <Fragment>
          <DetailTab title={'General configuration'} data={detailTabContentData} isApiLoading={false} />

          <div className="tab-container">
            <div className="detail-tab flex a-center">
              {DETAIL_TAB_LIST.map(tab => {
                return (
                  <button
                    className={`detail-tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                    key={tab.id}
                    data-tab={tab.id}
                    onClick={e => setDetailTabSelected(tab)}
                  >
                    <p>{tab.name}</p>
                  </button>
                );
              })}
            </div>

            <div className="content-tab">{detailContentNode}</div>
          </div>
        </Fragment>
      }
    </div>
  );
}

export default AwsKeyDetail;