import lazyGetAwsDescribeSecret, { IAwsDescribeSecretVariables } from 'graphql/queries/getAwsDescribeSecret';
import { SecretDetailPropsType } from './types';
import PageDetailTitle from '../../../components/PageDetailTitle';
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AwsDescribeSecretType } from 'graphql/types/AwsDescribeSecret';
import { DETAIL_TAB_LIST } from './configs';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import Overview from './Tabs/Overview';
import Rotations from './Tabs/Rotations';
import Replication from './Tabs/Replication';
import { ColumnType, RowType } from '@Types/v2/Table';
import TagTab from '../../../components/TagTab';
import Version from './Tabs/Version';
import SecretDetails from './Tabs/SecreetDetails';
import Tags from './Tabs/Tags';

const SecretDetail = (props: SecretDetailPropsType) => {
  const { cloudId, region, secret, pageBackClick } = props;

  const [getAwsDescribeSecret, { loading: describeSecretLoading }] = lazyGetAwsDescribeSecret();

  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>(DETAIL_TAB_LIST[0]);
  const [describeSecret, setDesribeSecret] = useState<AwsDescribeSecretType>();

  const isApiLoading = useMemo((): boolean => {
    return describeSecretLoading;
  }, [describeSecretLoading]);

  const reqVariable = useMemo((): IAwsDescribeSecretVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: { secretId: secret.arn || secret.name },
    };
  }, [cloudId, region, secret]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return secret?.tags ?? [];
  }, [secret]);

  const fetchDescribeSecret = useCallback(() => {
    getAwsDescribeSecret({ variables: reqVariable }).then(({ data: responseData }) => {
      const secretDetail = responseData?.getAwsDescribeSecret?.data?.[0];
      if (secretDetail) {
        setDesribeSecret(secretDetail);
      }
    });
  }, [reqVariable]);

  useEffect(() => {
    fetchDescribeSecret();
  }, [secret]);

  const detailContentNode = useMemo((): ReactNode => {
    switch (detailTabSelected) {
      case DETAIL_TAB_LIST[0]:
        return <Overview cloudId={cloudId} region={region} secret={describeSecret} onReloadData={fetchDescribeSecret} />;
      case DETAIL_TAB_LIST[1]:
        return <Rotations cloudId={cloudId} region={region} secret={describeSecret} onReloadData={fetchDescribeSecret} />;
      case DETAIL_TAB_LIST[2]:
        return <Version cloudId={cloudId} region={region} secret={secret} />;
      case DETAIL_TAB_LIST[3]:
        return <Replication />;
      case DETAIL_TAB_LIST[4]:
        return <Tags cloudId={cloudId} region={region} secret={describeSecret} onReloadData={fetchDescribeSecret} />;
      default:
        return null;
    }
  }, [detailTabSelected, cloudId, region, tagColumns, tagRows, secret, describeSecret]);

  return (
    <div className="ec2-detail">
      <PageDetailTitle title={`${secret?.name}`} pageBackClick={pageBackClick} />

      {isApiLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <Fragment>
          {describeSecret && (
            <Fragment>
              <SecretDetails
                cloudId={cloudId}
                region={region}
                describeSecret={describeSecret}
                onReloadData={fetchDescribeSecret}
              />

              <div className="tab-container">
                <div className="detail-tab flex a-center">
                  {DETAIL_TAB_LIST.map(tab => {
                    return (
                      <button
                        className={`detail-tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                        key={tab.id}
                        data-tab={tab.id}
                        onClick={e => setDetailTabSelected(tab)}
                      >
                        <p>{tab.name}</p>
                      </button>
                    );
                  })}
                </div>

                <div className="content-tab">{detailContentNode}</div>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default SecretDetail;
