import { gql } from '@apollo/client';

const query = gql`
query getAwsGetNamespace($reqData: AwsGetNamespaceRequest, $cloudId: Long, $region: String) {
  getAwsNamespace(getNamespaceRequest: $reqData, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsGetNamespaceResponse {
        namespace {
          adminPasswordSecretArn
          adminPasswordSecretKmsKeyId
          adminUsername
          creationDate
          dbName
          defaultIamRoleArn
          iamRoles
          kmsKeyId
          logExports
          namespaceArn
          namespaceId
          namespaceName
          status
        }
      }
    }
  }
}`;
export default query;