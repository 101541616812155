import { useMutation } from '@apollo/client';
import {
  AwsUpdateFirewallSubnetChangeProtectionRequestType,
  AwsUpdateFirewallSubnetChangeProtectionResponseType,
} from 'graphql/types/AwsUpdateFirewall';
import { IGqlResponseNewData } from '../../types';
import query from './query';

export type AwsUpdateFirewallSubnetChangeProtectionVariablesType = {
  cloudId: number;
  region: string;
  request: AwsUpdateFirewallSubnetChangeProtectionRequestType;
};

export type AwsUpdateFirewallSubnetChangeProtectionResponseDataType = {
  updateAwsSubnetChangeProtection: IGqlResponseNewData<AwsUpdateFirewallSubnetChangeProtectionResponseType>;
};

const updateFirewallSubnetChangeProtectionMutation = () =>
  useMutation<
    AwsUpdateFirewallSubnetChangeProtectionResponseDataType,
    AwsUpdateFirewallSubnetChangeProtectionVariablesType
  >(query);
export default updateFirewallSubnetChangeProtectionMutation;
