import { useMutation } from '@apollo/client';
import { IGqlResponseNewData } from '../../types';
import query from './query';
import { AwsUpdateFirewallPolicyRequestType, AwsUpdateFirewallPolicyResponseType } from 'graphql/types/AwsUpdateFirewallPolicy';

export type UpdateAwsFirewallPolicyVariablesType = {
    cloudId: number;
    region: string;
    request : AwsUpdateFirewallPolicyRequestType
}

export type UpdateAwsFirewallPolicyDataType = {
    updateAwsFirewallPolicy: IGqlResponseNewData<AwsUpdateFirewallPolicyResponseType>;
}

const updateAwsFirewallPolicyMutation = () =>
  useMutation<UpdateAwsFirewallPolicyDataType, UpdateAwsFirewallPolicyVariablesType>(query);
export default updateAwsFirewallPolicyMutation;
