/* eslint-disable max-len */
import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import { ACCESS_KEYS } from 'utils/DummyData/Dropdown';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import { checkLinkedCloudName } from 'utils/v2/ValidCheck';
import { ProjectCloudType } from 'graphql/types/ProjectCloud';
import { AllRuleCloudType } from 'graphql/types/AllRuleCloud';
import InfoCard from '../cards/InfoCard';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import updateProjectCloud from 'graphql/mutations/updateProjectCloud';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import MultiSelectAutocomplete from 'components/v2/atoms/AutocompleteInputAtom';
import { IEnumTypeCodeProps } from '@Types/v3/content';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';

export type CloudDetailType = {
  id: number;
  name: string;
  cloudId: number;
  rootAccount: string;
  serviceType: string;
  accountId: string;
  externalId: string;
  roleArn: string;
  features: string;
  region: string[];
  attributes: string;
  attrValue1: string;
  verifyInfo: {
    id: number;
    attrKey: string;
    attrValue: string;
  }[];
}

interface ILinkedCloudDetailProps {
  data: ProjectCloudType;
  cloudTypeList: AllRuleCloudType[];
  doRefecthCloudList: () => void
  getOffPage: () => void;
  onDelete: () => void;
  onEditSuccess: () => void;
}

const LinkedCloudDetail = ({
  data,
  cloudTypeList,
  doRefecthCloudList,
  getOffPage,
  onDelete,
  onEditSuccess
}: ILinkedCloudDetailProps) => {
  const { tooltip } = useGModal();
  const [detail, setDetail] = useState<CloudDetailType>({
    id: -1, /* row id */
    name: '', /* 연계계정이름 */
    cloudId: -1, /* 클라우드 종류 */
    rootAccount: '', /* 클라우드 계정 */
    serviceType: '',
    accountId: '',
    externalId: '',
    roleArn: '',
    features: '',
    region: [],
    attributes: '',
    attrValue1: '',
    verifyInfo: [] /* 화면단 작업을 통한 dropdown 리스트를 여기에 넣기 */
  }); 
  const [accessKeys, setAccessKeys] = useState<{ name: string; value: string; }[]>([]);
  const [edit, setEdit] = useState(false);
  const [regionList, setRegionList] = useState<IEnumTypeCodeProps[]>([]); 

  /* 연계 클라우드 수정 */
  const [updateCloud] = updateProjectCloud();
  const [getEnumTypeCode] = lazyGetEnumTypeCode();

  const accessKeysList = useMemo(() => {
    const attrKeys = detail.verifyInfo.map(val => val.attrKey);
    return accessKeys.filter(acc => !attrKeys.includes(acc.value))
  }, [accessKeys, detail])

  const ableEditCloudDetail = useMemo(() => {
    if (detail.serviceType === 'mssp0000' && 
      checkLinkedCloudName(detail.name)) { 
      return true; 
    } else if (detail.serviceType === 'monitor0' && (
      checkLinkedCloudName(detail.name) ||
      detail.verifyInfo.length === 0 ||
      detail.verifyInfo.some(val => val.attrKey === '' || val.attrValue === ''))
    ) { 
      return true; 
    } else return false;
  }, [detail]);


  const origin = useMemo(() => {
    let newArray:{id: number, attrKey: string, attrValue: string}[] = [];
    let attr2Parse:{[key: string]: string} = {};
    let msspParse:{[key:string]:string|string[]} = {};
    try {
      const parsedObject = JSON.parse(data.attrValue1);

      newArray = Object.keys(parsedObject).map((key, idx) => {
        const id = idx + 1;
        const attrKey = `${key}`;
        const attrValue = parsedObject[key];

        return { 
          id, 
          attrKey, 
          attrValue 
        };
      });
    } catch {

    }

    try {
      attr2Parse = JSON.parse(data.attrValue2);
    } catch {

    }

    try {
      msspParse = JSON.parse(data.msspAttrValue);
    } catch {

    }

    return {
      id: data.id, 
      name: data.name, 
      cloudId: data.cloudId, 
      rootAccount: data.rootAccount,
      serviceType: data.serviceType,
      accountId: attr2Parse.accountId || '',
      externalId: attr2Parse.externalId || '',
      roleArn: attr2Parse.roleArn || '',
      features: data.features,
      region: (msspParse.region as string[]) || [],
      attributes: data.attributes,
      attrValue1: data.attrValue1,
      verifyInfo: [ ...newArray ]
    }
  }, []);

  const attrJson = (arr: { id: number, attrKey: string, attrValue: string }[]) => {          
    const outputObject: { [key: string]: string }  = {};
    
    for (let i = 0; i < arr.length; i++) {
      const currentObj = arr[i];
      const currentKey = currentObj.attrKey;
      const currentValue = currentObj.attrValue;
    
      outputObject[currentKey] = currentValue;
    }

    return JSON.stringify(outputObject, null, 0);
  }

  useEffect(() => { 
    setDetail({ ...origin });
    setAccessKeys(ACCESS_KEYS);
    
    getEnumTypeCode({variables: { text:  origin.cloudId === 2 ? 'GcpRegionCode': origin.cloudId === 3 ? 'AzureRegionCode': 'AwsRegionCode' }}).then(res => {
      if (res.data) {
        setRegionList(res.data.getEnumTypeCode.data.map(val => ({
          name: val.name,
          value: val.value
        })));
      }
    })
  }, []);

  useEffect(() => {
    const linkedCloudPageEl = document.getElementById('cloud-detail-page');
    const projectPageEl = document.getElementById('project-occupied-page');

    if (linkedCloudPageEl && projectPageEl) {
      if (projectPageEl.offsetHeight < linkedCloudPageEl.scrollHeight) {
        projectPageEl.classList.remove('none-scroll');
      } else {
        projectPageEl.classList.add('none-scroll');
      }
    }

    return () => projectPageEl?.classList.remove('none-scroll');
  }, [detail.verifyInfo.length]);
  
  return (
    <div id="cloud-detail-page">

      <div className="cloud-detail-header flex a-center"> 
        <button className="back-btn" onClick={getOffPage} /> 
        {data.name}
      </div>

      <div className="detail-content">
        <InfoCard
          title="Name" 
          required
          content={() => 
            <InputAtom
              value={detail.name}
              disabled={!edit}
              onChangeValue={(val) => setDetail((prev) => ({
                ...prev,
                name: val
              }))}
            />
          } 
        />
        <InfoCard
          title="Service type" 
          required
          content={() => 
            <>{enumFormatter(detail.serviceType)}</>
          } 
        />
        <InfoCard 
          title="Cloud provider" 
          required
          content={() => 
            <>
              {cloudTypeList.find(cloud => cloud.cloudId === detail.cloudId)?.name}
            </>
          } 
        />
        {detail.serviceType === 'mssp0000' && 
        <>
          <InfoCard 
            title="Using region" 
            className="region"
            required
            content={() => 
              <>
                <MultiSelectAutocomplete
                 className={!edit ? 'readable-region' : '' } 
                  data={regionList}
                  selectedValues={detail.region}
                  placeholder="Select your regions"
                  handleClick={(val:DropdownListDataType) => {
                    const newValue = String(val.value);
                    if (!detail.region.includes(newValue)) {
                      setDetail((prev) => ({
                        ...prev,
                        region: [...prev.region, newValue]
                      }))
                    }
                  }}
                  handleRemove={(val:string) => {
                    const updatedValues = detail.region.filter((item) => item !== val);
                    setDetail((prev) => ({
                      ...prev,
                      region: updatedValues
                    }))
                  }}
                  />
              </>
            } 
          />
          <InfoCard 
            title="Feature" 
            required
            content={() => 
              <>
                {detail.features}
              </>
            } 
          />
        </>}
        <InfoCard 
          title="Account ID" 
          required
          content={() => {
            if(detail.serviceType === 'mssp0000') {
              return <>{detail.accountId}</>;
            } else {
              return <InputAtom
              disabled={!edit}
              placeholder="rootAccount value"
              value={detail.rootAccount}
              onChangeValue={(str) => {
                setDetail(prev => ({
                  ...prev,
                  rootAccount: str
                }));
              }}
            />;
            }
          }} 
        />
        {detail.serviceType === 'mssp0000' ? 
        <>
          <InfoCard 
            title="External ID" 
            required
            content={() => 
              <>{detail.externalId}</>
            } 
          />
          <InfoCard 
            title="IAM Role ARN" 
            required
            content={() => 
              <>{detail.roleArn}</>
            } 
          />
        </>: 
        <InfoCard 
          title="Credentials"
          className='credentials'
          required
          content={() => 
            <>
              {detail.verifyInfo.map(verify => (
                <div 
                  key={verify.id}
                  className="access-info flex a-center"
                  data-focus={`${verify.id}`}
                >
                  <DropdownAtom 
                    disabled={!edit}
                    id={`access-key ${verify.id}`} 
                    data={ accessKeysList.length === 0 
                        ? [{
                          name: 'No more selectable key.',
                          value: -1
                        }]
                      : accessKeysList }
                    placeholder="Select key"
                    value={{
                      name: accessKeys.find(acc => acc.value === verify.attrKey)?.name 
                      ? accessKeys.find(acc => acc.value === verify.attrKey)?.name
                      : '',
                      value: verify.attrKey
                    }} 
                    handleClick={(val: DropdownListDataType) => {
                      if (val.value === -1) return;

                      const copy = detail.verifyInfo.map(val => val);
                      const findIdx = copy.findIndex(data => data.id === verify.id);
                      if (findIdx === -1) return;
                      
                      copy[findIdx].attrKey = String(val.value);
                      setDetail(prev => ({
                        ...prev,
                        verifyInfo: copy
                      }));
                    }} 
                  />
                  <InputAtom
                    disabled={!edit}
                    placeholder="Input value"
                    value={verify.attrValue}
                    onChangeValue={(str) => {
                      const copy = detail.verifyInfo.map(val => val);
                      const findIdx = copy.findIndex(data => data.id === verify.id);

                      copy[findIdx].attrValue = str;
                      setDetail(prev => ({
                        ...prev,
                        verifyInfo: copy
                      }));
                    }}
                  />
                  {edit && <button 
                    className="delete-btn" 
                    onClick={() => {
                      const left = detail.verifyInfo.filter(val => val.id !== verify.id);
                      setDetail(prev => ({
                        ...prev,
                        verifyInfo: left
                      }));
                    }} 
                  />}
                </div>
              ))}
              { edit && detail.verifyInfo.length < 3 && <button className="add-btn">Add</button> }
            </>
          } 
        />}
        <InfoCard 
          title="Created" 
          required 
          content={() => 
            <>
              <b
                onMouseOver={(e) => {
                  tooltip.userTooltip({ 
                    top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                      ? e.currentTarget.getBoundingClientRect().bottom - 115
                      : e.currentTarget.getBoundingClientRect().bottom + 12,
                    left: e.currentTarget.getBoundingClientRect().left, 
                    userInfo: { userId: data.createdByUserId, fullName: data.createdByName, createdAt: data.createdAt, thumbnail: '' }
                  });
                }}
                onMouseLeave={tooltip.close}
              >
                {data.createdByName}({(data.createdByUserId)})</b> / {dateFormatter(data.createdAt, 'datetime')}
            </> 
          }
        />
        <InfoCard 
          title="Updated"
          required
          content={() => 
            <>
              <b
                onMouseOver={(e) => {
                  tooltip.userTooltip({ 
                    top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                      ? e.currentTarget.getBoundingClientRect().bottom - 115
                      : e.currentTarget.getBoundingClientRect().bottom + 12,
                    left: e.currentTarget.getBoundingClientRect().left, 
                    userInfo: { userId: data.modifiedByUserId, fullName: data.modifiedByName, createdAt: data.modifiedAt, thumbnail: '' }
                  });
                }}
                onMouseLeave={tooltip.close}
              >
                {data.modifiedByName}({(data.modifiedByUserId)})</b> / {dateFormatter(data.modifiedAt, 'datetime')}
            </> 
          }
        />
      </div>

      <div className="btns flex j-end">
        {
          !edit 
            ? <>
              <button 
                className="big-sub-btn"
                onClick={() => {
                  setEdit(true);
                  doRefecthCloudList();
                }}
              >
                Update
              </button>
              <button 
                className="big-main-btn"
                onClick={onDelete}
              >
                Delete
              </button> 
            </>
            : <>
              <button
                className="big-sub-btn"
                onClick={() => {
                  setDetail({ ...origin });
                  setEdit(false);
                }}
              >
                Cancel
              </button>
              <button 
                className="big-main-btn"
                onClick={() => {
                  const updateCloudData = {
                    reqUpdProjectCloud: {
                      id: detail.id,
                      name: detail.name,
                      ...(detail.serviceType === 'monitor0' && {
                        rootAccount: detail.rootAccount,
                        attrValue1: attrJson(detail.verifyInfo),
                      }),
                      ...(detail.serviceType === 'mssp0000' && {msspAttrValue: JSON.stringify({region: detail.region})})
                    }
                  }
      
                  updateCloud({ variables: updateCloudData}).then(({ data }) => {
                    if (data) {
                      if (data.updateProjectCloud.result === ErrorCode.SUCCESS) {
                        useToast(ErrorCode.SUCCESS, 'Related cloud is updated successfully.');
                        setEdit(false);
                        onEditSuccess();
                      } else {
                        useToast(ErrorCode.UNKNOWN, 'Update related cloud failed.');
                      }
                    } else {
                      useToast(ErrorCode.UNKNOWN, 'Update related cloud failed.');
                    }
                  })
                }}
                disabled={ ableEditCloudDetail }
              >
                Update
              </button>
            </>
        }
      </div>
    </div>
  );
};

export default LinkedCloudDetail;
