import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import InputGroup from 'pages/v2/Organ/Management/components/InputGroup';
import SectionContainer from 'pages/v2/Organ/Management/components/SectionContainer';
import SectionTitle from 'pages/v2/Organ/Management/components/SectionTitle';
import InputAtom from 'components/v2/atoms/InputAtom';
import JSONEditor from 'components/JSONEditor';
import { TextContent, type Content } from 'vanilla-jsoneditor';
import { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import InputCustomDesc from '../components/InputCustomDesc';
import TagTableCustom from '../components/TagTableCustom';
import { secretNameRegex } from 'utils/Regex';
import { KeyPairtType, TagType } from '../type';
import { create } from 'domain';

const configureSecretStep = forwardRef((props: any, ref: Ref<any>) => {
  const { stepTitle, creationData, handleChangeCreationData, validationResourcePolicy } = props;
  const initTag = {
    key: '',
    value: '',
  };
  const json: Object = {
    Version: '2012-10-17',
    Statement: [],
  };
  const [jsonEditorContent, setJsonEditorContent] = useState<Content>({ json });
  const [startValid, setStartValid] = useState<boolean>(false);
  const [tags, setTags] = useState<TagType[]>(creationData?.tags || []);
  const [errors, setErrors] = useState<any>({
    secretNameError: '',
    descriptionError: '',
  });

  const rightButtonsNode = useMemo((): any => {
    if (creationData.editPermission) {
      return [
        {
          id: 'edit-cancel',
          label: 'Cancel',
          onClick: () => {
            handleChangeCreationData('editPermission', false);
          },
        },
        {
          id: 'edit-save',
          label: 'Save',
          type: ButtonTypeEnum.PRIMARY,
          onClick: () => {
            validationResourcePolicy();
          },
        },
      ];
    } else {
      return [
        {
          id: 'edit-permissions-secert',
          label: 'Edit permissions',
          onClick: () => {
            handleChangeCreationData('editPermission', true);
          },
        },
      ];
    }
  }, [creationData.editPermission, creationData]);

  const handleAddTag = useCallback(() => {
    const newTags = [...tags, initTag];
    setTags(newTags);
    handleChangeCreationData('tags', newTags);
  }, [tags, setTags]);

  const handleSetTags = useCallback(
    (rowIndex: number, keyName: string, value: string): TagType[] => {
      const dataUpdate = tags.map((tag: TagType, index: number) => {
        let updateTag = tag;
        if (index === rowIndex) {
          if (keyName === 'key') {
            updateTag = { ...updateTag, [keyName]: value, error: handleSetErrorTags(tags, value) };
          } else {
            updateTag = { ...updateTag, [keyName]: value };
          }
        }
        
        return updateTag;
      });
      setTags(dataUpdate);
      handleChangeCreationData('tags', dataUpdate);
      
      return dataUpdate;
    },
    [creationData, tags],
  );

  const handleRemoveTag = useCallback(
    (index: number) => {
      const newTags = tags.filter((pair: KeyPairtType, idx: number) => idx !== index);
      setTags(newTags);
      handleChangeCreationData('tags', newTags);
    },
    [tags, creationData],
  );

  const handleOnChangeJson = (value: Content) => {
    const valueChange = (value as TextContent).text;
    console.log('run gere', valueChange);
    handleChangeCreationData('resourcePolicy', valueChange);
    setJsonEditorContent(value);
  };

  const checkDuplicates = (arr: TagType[], keyAValue: string) => {
    const filtered = arr.filter(obj => obj.key === keyAValue && obj.key !== '');
    
    return filtered.length > 1;
  };

  const handleSetErrorTags = (tags: TagType[], value: string) => {
    if (value !== '') {
      if (checkDuplicates(tags, value)) {
        return 'You can\'t create two keys with the same name. Key names are case sensitive.';
      } else {
        return '';
      }
    } else {
      return 'You must enter a key.';
    }
  };

  const checkValidationTags = useCallback(() => {
    let validation = false;
    if (tags?.length !== 0) {
      const checkValid = tags.map(data => ({ ...data, error: handleSetErrorTags(tags, data.key) }));
      validation = checkValid.some(data => data.error !== '');
      setTags(checkValid);
    }
    
    return validation;
  }, [tags, handleSetErrorTags]);

  const inputValidation = useCallback((): boolean => {
    const { secretName, description } = creationData;
    let validated = true;
    validated = !checkValidationTags();
    let secretNameError = '';
    if (!secretName || secretName === undefined) {
      secretNameError = 'Secret name must contain only alphanumeric characters and the characters /_+=.@-';
      validated = false;
    } else if (!secretNameRegex.test(secretName)) {
      secretNameError = 'Secret name must contain only alphanumeric characters and the characters /_+=.@-';
      validated = false;
    }
    let descriptionError = '';
    if (!description && description?.length > 250) {
      descriptionError = 'Maximum 250 characters.';
      validated = false;
    }
    const creationDataValidated = { secretNameError: secretNameError, descriptionError: descriptionError };
    setErrors(creationDataValidated);
    
    return validated;
  }, [errors, creationData, setErrors]);

  const onConfigureSecretVadidate = useCallback(() => {
    setStartValid(true);
    
    return inputValidation();
  }, [startValid, creationData, errors]);

  useImperativeHandle(ref, () => ({
    onConfigureSecretVadidate,
  }));

  useEffect(() => {
    if (startValid) {
      inputValidation();
    }
  }, [startValid, creationData]);

  return (
    <div>
      <SectionTitle title={stepTitle} customStyle="section-step" />
      <div className="type-step">
        <SectionContainer title={'Credentials'} customStyle="credential-panel">
          <InputGroup>
            <InputCustomDesc
              label="Secret name"
              topDescription="A descriptive name that helps you find your secret later."
              bottomDescription="Secret name must contain only alphanumeric characters and the characters /_+=.@-"
              error={errors.secretNameError}
              required
            >
              <InputAtom
                value={creationData.secretName}
                type="text"
                required
                onChangeValue={value => handleChangeCreationData('secretName', value)}
                error={!!errors.secretNameError}
                placeholder="prod/Appbeta/MySql"
              />
            </InputCustomDesc>
            <InputCustomDesc
              label="Description"
              bottomDescription="Maximum 250 characters."
              error={errors.descriptionError}
            >
              <TextareaAtom
                value={creationData?.description}
                onChangeValue={value => handleChangeCreationData('description', value)}
                className="value"
                placeholder="Access to MYSQL prod database for my AppBeta"
                error={!!errors.descriptionError}
              />
            </InputCustomDesc>
          </InputGroup>
        </SectionContainer>
        <SectionContainer title="Tags" caption="- optional">
          <InputGroup>
            <p className="tags-description">No tags associated with the secret.</p>
            <TagTableCustom
              optionHide={true}
              tags={tags}
              errors={[]}
              addPair={handleAddTag}
              handleValueChanged={handleSetTags}
              handleCurrentKeyDelete={handleRemoveTag}
            />
          </InputGroup>
        </SectionContainer>
        <SectionContainer
          title="Resource permissions"
          caption="- optional"
          description="Add or edit a resource policy to access secrets across AWS accounts."
          rightButtons={rightButtonsNode}
        >
          {creationData.editPermission && <JSONEditor content={jsonEditorContent} onChange={handleOnChangeJson}/>}
        </SectionContainer>
      </div>
    </div>
  );
});
configureSecretStep.displayName = 'configureSecretStep';
export default configureSecretStep;
