import './index.scss';
import { useState, useEffect, useMemo } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import Icon from 'components/v2/atoms/Icon';
import IconTag from 'assets/svgs/v3/ico_tag.svg';

interface IAddOrEditTagsModalProps extends IBaseModalProps {
  currentDatas: TagRowData[];
  note?: string;
  onSave: (oldTags: TagRowData[], newTags: TagRowData[]) => void;
}

export interface TagRowData {
  index: number;
  keyStr: string;
  valueStr: string;
}

const AddOrEditTagsModal = ({
  currentDatas,
  note,
  onSave,
  ...baseModalProps
}: IAddOrEditTagsModalProps) => {
  const [tagRows, setTagRows] = useState<TagRowData[]>([]);
  const [currentTagRows, setCurrentTagRows] = useState<TagRowData[]>([]);

  useEffect(() => {
    if (baseModalProps.open) {
      if (currentDatas.length == 0) setTagRows([{ index: 0, keyStr: '', valueStr: '' }]);
      setCurrentTagRows(currentDatas);
    } else {
      setTagRows([]);
      setCurrentTagRows([]);
    }
  }, [baseModalProps.open]);

  const handleCreate = () => {
    setTagRows(prevState => {
      const newState = prevState.concat({ index: tagRows.length, keyStr: '', valueStr: '' });
      return newState;
    });
  };

  const handleDelete = (indexToRemove: number) => {
    const newData = tagRows.filter((_, index) => index != indexToRemove);
    setTagRows(newData);
  };

  const handleChange = (index: number, value: string, propName: keyof TagRowData) => {
    const newRows = tagRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setTagRows(newRows);
  };

  const handleCurrentTagDelete = (indexToRemove: number) => {
    const newData = currentTagRows.filter((_, index) => index != indexToRemove);
    setCurrentTagRows(newData);
  };

  const handleCurrentTagChange = (index: number, value: string, propName: keyof TagRowData) => {
    const newRows = currentTagRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setCurrentTagRows(newRows);
  };

  const columns = useMemo((): string[] => {
    return ['Key', 'Value-option'];
  }, []);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconTag} />
          
          <span>Add or edit tags</span>
        </>
      )}
      {...baseModalProps}
    >
      <div className="update-tag-model">
        {columns && (
          <div className="tag-row-container">
            <div className="tag-row-input">
              {columns.map((column, index) => {
                return (
                  <div key={`udpate-tags-table-column-${index}`} className="column">
                    <p>{column}</p>
                  </div>
                );
              })}
            </div>
            <div className="tag-row-btn"></div>
          </div>
        )}

        {currentTagRows && (
          <>
            {currentTagRows.map((row, index) => (
              <div className="tag-row-container" key={`udpate-tags-table-row-${index}`}>
                <div className="tag-row-input">
                  <div className="column">
                    <InputAtom
                      type={'text'}
                      value={row.keyStr}
                      onChangeValue={(value: string) => handleCurrentTagChange(index, value, 'keyStr')}
                      defaultValue={''}
                      noClear={true}
                      hasPrefixIcon={true}
                    />
                  </div>
                  <div className="column">
                    <InputAtom
                      type={'text'}
                      value={row.valueStr}
                      onChangeValue={(value: string) => handleCurrentTagChange(index, value, 'valueStr')}
                      defaultValue={''}
                      noClear={false}
                      hasPrefixIcon={true}
                    />
                  </div>
                </div>
                <div className="tag-row-btn">
                  <button className="button" onClick={() => handleCurrentTagDelete(index)}>
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </>
        )}

        {tagRows && (
          <>
            {tagRows.map((row, index) => (
              <div className="tag-row-container" key={`udpate-tags-table-new-row-${index}`}>
                <div className="tag-row-input">
                  <div className="column">
                    <InputAtom
                      type={'text'}
                      value={row.keyStr}
                      onChangeValue={(value: string) => handleChange(index, value, 'keyStr')}
                      defaultValue={''}
                      noClear={true}
                      hasPrefixIcon={true}
                    />
                  </div>
                  <div className="column">
                    <InputAtom
                      type={'text'}
                      value={row.valueStr}
                      onChangeValue={(value: string) => handleChange(index, value, 'valueStr')}
                      defaultValue={''}
                      noClear={false}
                      hasPrefixIcon={true}
                    />
                  </div>
                </div>
                <div className="tag-row-btn">
                  <button className="button" onClick={() => handleDelete(index)}>
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </>
        )}

        <div className="add-new-tag-container">
          <button className="normal-btn" onClick={handleCreate}>
            Add new tag
          </button>
          {!!note && <p className="note">{note}</p>}
        </div>

        <div className="cancle-save-container">
          <button className="button" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button className="normal-btn" onClick={() => onSave(currentTagRows, tagRows)}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default AddOrEditTagsModal;
