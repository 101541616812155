import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { MemberInfo } from 'graphql/types/MemberInfo';
import { AwsUpdateKeyDescriptionRequestType } from 'graphql/types/AwsUpdateKeyDescription';

export type AwsUpdatePrimaryRegionVariables = {
  cloudId: number;
  region: string;
  reqData: {
    keyId?: string;
    primaryRegion?: string;
  };
};

export type AwsUpdatePrimaryRegionResponseData = {
  updateAwsPrimaryRegion: IGqlResponseNewData<{}>;
};

const updateAwsPrimaryRegionMutation = () =>
  useMutation<AwsUpdatePrimaryRegionResponseData, AwsUpdatePrimaryRegionVariables>(query);
export default updateAwsPrimaryRegionMutation;
