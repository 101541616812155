import { AddTagsStepPropsType } from '../types';
import { FIREWALL_POLICY_CREATION } from '../configs';
import InputAtom from 'components/v2/atoms/InputAtom';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';

const maxRows = 50;

const AddTagsStep = (props: AddTagsStepPropsType) => {
  const { tags, onValueChanged } = props;

  const count = maxRows - (tags?.length || 0);

  const handleChangeTag = (field: 'key' | 'value', value: string, index: number) => {
    const newTags = tags.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          [field]: value,
          errorMessage: ''
        };
      } else {
        return item;
      }
    });
    onValueChanged('tags', newTags);
  };

  const handleRemoveTag = (index: number) => {
    const newTags = tags.slice();
    newTags.splice(index, 1);
    onValueChanged('tags', newTags);
  };

  const handleAddTag = () => {
    if (!count) return;

    const newTags = tags.concat({ key: '', value: '' });
    onValueChanged('tags', newTags);
  };

  return (
    <div className="rule-group-container">
      <div className="title-group">
        <p className="title">{FIREWALL_POLICY_CREATION.ADD_TAGS_TITLE}</p>
        <p className="sub-title">{FIREWALL_POLICY_CREATION.ADD_TAGS_DESCRIPTION}</p>
      </div>
      <div className="rule-group-details-container">
        <div
          className="rule-group-detail-title"
          style={{ gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          {FIREWALL_POLICY_CREATION.FIREWALL_POLICY_TAGS_TITLE}
          <span className="rule-group-detail-sub-title">{FIREWALL_POLICY_CREATION.FIREWALL_POLICY_DESCRIPTION}</span>
        </div>
        <div
          className="rule-group-detail-title"
          style={{ gap: 8, justifyContent: 'center', display: 'flex', flexDirection: 'column' }}
        >
          {tags?.length ? (
            <div className="firewall-policy-tags">
              <div className="config-rule-row">
                <div className="firewall-detail-sub-title">Key</div>

                <div className="firewall-detail-sub-title">Value - optional</div>

                <div style={{ opacity: 0, pointerEvents: 'none' }}>
                  <Button label="Remove tag" onClick={() => {}} />
                </div>
              </div>

              {tags.map((item, index) => {
                return (
                  <div key={index} className="config-rule-row">
                    <InputAtom
                      value={item.key}
                      error={item?.errorMessage}
                      onChangeValue={value => handleChangeTag('key', value, index)}
                    />

                    <InputAtom value={item.value} onChangeValue={value => handleChangeTag('value', value, index)} />

                    <Button label="Remove tag" onClick={() => handleRemoveTag(index)} />
                  </div>
                );
              })}
            </div>
          ) : (
            <span className="rule-group-detail-sub-title">{FIREWALL_POLICY_CREATION.ADD_TAG_TITLE}</span>
          )}

          <Button
            label={FIREWALL_POLICY_CREATION.ADD_TAG_BUTTON}
            type={ButtonTypeEnum.PRIMARY}
            disabled={!count}
            onClick={handleAddTag}
          />

          <span className="rule-group-detail-sub-title">You can add up to {count} more tags.</span>
        </div>
      </div>
    </div>
  );
};

export default AddTagsStep;
