import './index.scss';
import { useEffect, useState } from 'react';
import PageDetailTitle from 'pages/v2/Organ/Management/components/PageDetailTitle';
import lazyGetAwsDescribeRuleGroup from 'graphql/queries/getAwsDescribeRuleGroup';
import Details from '../Common/Components/Details';
import AmazonSnsTopic from '../Common/Components/AmazonSnsTopic';
import Rules from '../Common/Components/Rules';
import RulesVariables from '../Common/Components/RulesVariables';

const AwsRuleGroupDetail = (props: any) => {
  const { data, pageBackClick, cloudId, region } = props;

  const [describeRuleGroup, setDescribeRuleGroup] = useState<any>(null);

  const [getAwsDescribeRuleGroup, { loading }] = lazyGetAwsDescribeRuleGroup();

  const handleGetAwsFirewallPolicy = async () => {
    if (!data?.id) return;

    try {
      const variables = {
        cloudId,
        region,
        request: {
          ruleGroupName: data?.name || '',
          ruleGroupArn: data?.id || '',
        },
      };

      const { data: describeRuleGroup } = await getAwsDescribeRuleGroup({
        variables,
      });

      setDescribeRuleGroup(describeRuleGroup?.getAwsDescribeRuleGroup?.data?.[0]);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetAwsFirewallPolicy();
  }, [data?.id]);

  return (
    <>
      <div className="page-detail">
        <PageDetailTitle title={data?.name} pageBackClick={pageBackClick} />

        <div className="page-detail-tab">
          <div className="content-tab">
            <Details data={describeRuleGroup} isLoading={loading} />

            <AmazonSnsTopic data={describeRuleGroup} isLoading={loading} />

            <Rules data={describeRuleGroup} isLoading={loading} />

            <RulesVariables data={describeRuleGroup} isLoading={loading} />
            {/* 
            <IpStateReferences data={describeRuleGroup} isLoading={loading} isEdit />

            <Rules data={describeRuleGroup} isLoading={loading} isEdit />

            <CustomerManagedKeys data={describeRuleGroup} isLoading={loading} isEdit />

            <Tags data={describeRuleGroup} isLoading={loading} isEdit /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AwsRuleGroupDetail;
