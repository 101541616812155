import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListBucketsRequestType, AwsListBucketsResponseType } from 'graphql/types/AwsListBuckets';

export type AwsListBucketsVariables = {
  cloudId: number;
  region: string;
  request: AwsListBucketsRequestType;
};

export type AwsListBucketsResponseData = {
  getAwsListBuckets: IGqlResponseNewData<AwsListBucketsResponseType>;
};

const lazyGetAwsListBuckets = () =>
  useLazyQuery<AwsListBucketsResponseData, AwsListBucketsVariables>(query, {
    fetchPolicy: 'no-cache',
  });

export default lazyGetAwsListBuckets;
