import { useEffect, useState, useMemo, useCallback } from 'react';
import './index.scss';
import { RESOURCE_TYPE_DATA } from '../../Commons/Constant';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { DescribeWebAclStep, PageTitle } from './components';
import { CREATE_WEB_ACL_STEPS, DefaultActionEnum } from './configs';
import Button, { ButtonTypeEnum } from './components/Button';
import AddRulesAndRuleGroupsStep from './steps/AddRulesAndRuleGroupsStep';
import { AwsDistributionSummaryType } from 'graphql/types/AwsListDistributionsByWebACLId';
import SetRulePriority from './steps/SetRulePriority';
import ConfigureMetrics from './steps/ConfigureMetrics';
import { nameRegexUtils } from 'utils/Regex';
import Review from '../Review';
import createWebAclMutation from 'graphql/mutations/createWebAcl';
import { AwsCreateWebAclRequestType } from 'graphql/types/AwsCreateWebAcl';
import _ from 'lodash';

interface ICreateWebAclProps {
  regions: string[];
  onBackButtonClicked: () => void;
  onAddResourceButtonClicked: () => void;
  cloudId: number;
  region: string;
  awsResources: Array<AwsDistributionSummaryType>;
  onReview: (data: any) => void;
  selectedRegion: DropdownListDataType;
}

const CreateWebAcl = (props: ICreateWebAclProps) => {
  const { onBackButtonClicked, onAddResourceButtonClicked, cloudId, region, awsResources, onReview, selectedRegion, regions } =
    props;
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [getEnumTypeCode] = lazyGetEnumTypeCode();
  const [createWebAcl, { loading: createWebAclLoading }] = createWebAclMutation();

  const [creationData, setCreationData] = useState<any>(null);

  const handleSetCreationData = useCallback((data: any) => {
    setCreationData((prevState: any) => ({
      ...prevState,
      ...data,
    }));
  }, []);

  useEffect(() => {
    switch (currentStepIndex) {
      case 0:
        const { name, cloudWatchMetricName } = creationData || {};
        const isDisabled = !nameRegexUtils.test(name) || !nameRegexUtils.test(cloudWatchMetricName);
        setIsDisabled(isDisabled);
        break;
      case 1:
        const { defaultAction, domains, customResponse, responseCode, headers } = creationData || {};

        const isDomainsValid = domains?.some((e: string) => !e);
        const isHeadersValid = headers?.some(({ key, value }: { key: string; value: string }) => !(key && value));

        setIsDisabled(
          isDomainsValid ||
            (defaultAction === DefaultActionEnum.ALLOW
              ? isHeadersValid
              : customResponse && (!responseCode || isHeadersValid)),
        );
        break;
      default:
        setIsDisabled(false);
        break;
    }
  }, [currentStepIndex, creationData]);

  useEffect(() => {
    if (!isSubmit) return;

    setTimeout(() => {
      setIsSubmit(false);
    }, 1000);
  }, [isSubmit]);

  const onCreateButtonClicked = useCallback(() => {
    const { name, description, cloudWatchMetricName, defaultAction, rules, region, bodySizeLimit } = creationData;

    let request: AwsCreateWebAclRequestType = {
      scope: region.value !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
      name,
      description,
      visibilityConfig: {
        sampledRequestsEnabled: true,
        cloudWatchMetricsEnabled: true,
        metricName: cloudWatchMetricName,
      },
      defaultAction: { [defaultAction]: {} },
      rules: rules?.map((rule: any) => _.omit(rule, 'id')),
    };

    if (region.value === 'CloudFront') {
      request = {
        ...request,
        associationConfig: {
          requestBody: {
            CLOUDFRONT: bodySizeLimit === 'default' ? 'KB_16' : bodySizeLimit,
          },
        },
      };
    }

    const variables = {
      cloudId,
      region: region.value !== 'CloudFront' ? String(region.value) : 'us-east-1',
      request: _.omitBy(request, _.isEmpty),
    };

    createWebAcl({ variables })
      .then(res => {
        if (res?.data?.CreateWebACL?.data?.[0]?.summary?.lockToken) {
          onBackButtonClicked();
        }
      })
      .catch(error => {
        alert(error?.message);
      });
  }, [creationData, region]);

  const stepNode = useMemo(() => {
    switch (currentStepIndex) {
      default:
      case 0: {
        return (
          <DescribeWebAclStep
            title={CREATE_WEB_ACL_STEPS[currentStepIndex].title}
            onAddResourceButtonClicked={onAddResourceButtonClicked}
            cloudId={cloudId}
            region={region}
            resources={awsResources}
            setCreationData={handleSetCreationData}
            creationData={creationData}
            isSubmit={isSubmit}
            regions={regions}
          />
        );
      }

      case 1: {
        return (
          <AddRulesAndRuleGroupsStep
            title={CREATE_WEB_ACL_STEPS[currentStepIndex].title}
            description={CREATE_WEB_ACL_STEPS[currentStepIndex].description!}
            setCreationData={handleSetCreationData}
            cloudId={cloudId}
            region={creationData.region}
            creationData={creationData}
            isSubmit={isSubmit}
          />
        );
      }

      case 2: {
        return (
          <SetRulePriority
            title={CREATE_WEB_ACL_STEPS[currentStepIndex].title}
            rules={creationData?.rules ?? []}
            setCreationData={handleSetCreationData}
            creationData={creationData}
          />
        );
      }

      case 3: {
        return (
          <ConfigureMetrics
            title={CREATE_WEB_ACL_STEPS[currentStepIndex].title}
            setCreationData={handleSetCreationData}
            rules={creationData?.rules ?? []}
            creationData={creationData}
          />
        );
      }

      case 4: {
        return (
          <Review
            creationData={creationData}
            cloudId={cloudId}
            region={String(selectedRegion.value)}
            onBackButtonClicked={onBackButtonClicked}
          />
        );
      }
    }
  }, [currentStepIndex, awsResources, creationData, isSubmit, regions]);

  const onNextButtonClicked = useCallback(() => {
    setIsSubmit(true);

    if (isDisabled) return;

    if (currentStepIndex < CREATE_WEB_ACL_STEPS.length - 1) {
      if (currentStepIndex === 1) {
        let data = { ...creationData };

        if (creationData.defaultAction === DefaultActionEnum.ALLOW) {
          data = {
            ...creationData,
            responseCode: '',
            customResponse: false,
          };
        } else if (!creationData.customResponse) {
          data = {
            ...creationData,
            responseCode: '',
            headers: [],
          };
        }

        setCreationData(data);
      }

      setCurrentStepIndex(currentStepIndex + 1);
      return;
    }
  }, [currentStepIndex, isDisabled, creationData]);

  const onPreviousButtonClicked = useCallback(() => {
    setCurrentStepIndex(currentStepIndex - 1);
  }, [currentStepIndex]);

  const onCancelButtonClicked = useCallback(() => {
    onBackButtonClicked();
  }, [currentStepIndex]);

  const buttonGroupNode = useMemo(() => {
    const isFirstItem = currentStepIndex === 0;
    const isReviewScreen = currentStepIndex === CREATE_WEB_ACL_STEPS.length - 1;

    if (isReviewScreen) {
      return (
        <div className="button-group">
          <Button label="Cancel" onClick={onCancelButtonClicked} />
          {!isFirstItem && <Button label="Previous" onClick={onPreviousButtonClicked} />}
          <Button
            label="Create web ACL"
            type={ButtonTypeEnum.PRIMARY}
            loading={createWebAclLoading}
            onClick={onCreateButtonClicked}
          />
        </div>
      );
    }

    return (
      <div className="button-group">
        <Button label="Cancel" onClick={onCancelButtonClicked} />
        {!isFirstItem && <Button label="Previous" onClick={onPreviousButtonClicked} />}
        <Button label="Next" type={ButtonTypeEnum.PRIMARY} onClick={onNextButtonClicked} />
      </div>
    );
  }, [currentStepIndex, isDisabled, creationData, createWebAclLoading, onCreateButtonClicked]);

  return (
    <div id="create-webacl-page-container">
      <PageTitle
        title={currentStepIndex === CREATE_WEB_ACL_STEPS.length - 1 ? 'Create web ACL: Review' : 'Create web ACL'}
        hasBackButton
        onBackButtonClicked={onBackButtonClicked}
      />
      {stepNode}
      {buttonGroupNode}
    </div>
  );
};

export default CreateWebAcl;
