import { ColumnType } from '@Types/v2/Table';
import { AwsFirewallPolicyResponseType } from 'graphql/types/AwsFirewallPolicy';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import TagTab from 'pages/v2/Organ/Management/components/TagTab';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IBaseFirewallProps } from '../../../../types';
import { EditFirewallDetailModalTypeEnum } from '../../../types';
import EditFirewallDetailModal from '../../EditFirewallDetailModal';
import EditFirewallPolicyModal from '../../EditFirewallPolicy';
import EditKMSEncryptionModal from '../../EditKMSEncryptionModal';
import EditVPCAndSubnetsModal from '../../EditVPCAndSubnetsModal';
import ProtectionAgainstChangeModal from '../../ProtectionAgainstChange';
import UpdateTagsModal from '../../UpdateTagsModal';
import ConfigurationSyncState from './ConfigurationSyncState';
import FirewallEndpoints from './FirewallEndpoints';
import { getStrValueOrNull } from 'utils/Common';
interface IDetailProps extends IBaseFirewallProps {
  isEdit?: boolean;
  firewallPolicyResponse?: AwsFirewallPolicyResponseType;
  onUpdateFirewallPolicy?: () => void;
}

const Details = ({
  cloudId,
  region,
  firewallPolicyResponse,
  data,
  setData = () => { },
  isEdit = false,
}: IDetailProps) => {
  const navigate = useNavigate();
  const [isOpenEditFirewallDetails, setIsOpenEditFirewallDetails] = useState(false);
  const [isOpenEditVpc, setIsOpenEditVpc] = useState(false);
  const [isOpenEditChangeProtection, setIsOpenEditChangeProtection] = useState(false);
  const [isOpenEditCustomerManagedKey, setIsOpenEditCustomerManagedKey] = useState(false);
  const [isOpenEditTag, setIsOpenEditTag] = useState(false);
  const [isOpenEditFirewallPolicy, setIsOpenEditFirewallPolicy] = useState(false);

  const {
    firewallName = '',
    description = '',
    vpcId = '',
    subnetMappings = [],
    deleteProtection = false,
    subnetChangeProtection = false,
    encryptionConfiguration,
    tags = [],
  } = data?.firewall || {};

  const subnetDescriptions = useMemo(() => {
    return subnetMappings.map(s => s.subnetId);
  }, [subnetMappings]);

  const handleItemClicked = (screen: string, tabId: string, key: string, value: string) => {
    navigate('/organ/1/manage/network', {
      replace: true,
      state: {
        managementType: screen || ManagementTypeEnum.NETWORK,
        tabId: tabId,
        key: key,
        value: value,
      },
    });
  };

  const firewallDetails: IMgdDetailKeyValueProps[] = [
    {
      title: 'Name',
      description: firewallName,
    },
    {
      title: 'Desciption',
      description: description,
    },
  ];

  const vpc: IMgdDetailKeyValueProps[] = [
    {
      title: 'Associated VPC',
      description: vpcId,
      type: TextTypeEnum.LINK,
      handleItemClick: () => handleItemClicked(ManagementTypeEnum.NETWORK, 'vpc', 'vpc-id', vpcId ?? ''),
    },
    {
      title: 'Firewall subnets',
      description: subnetDescriptions,
      type: TextTypeEnum.LINK,
      handleItemClick: () =>
        handleItemClicked(ManagementTypeEnum.NETWORK, 'subnet', 'subnet-id', subnetMappings?.[0]?.subnetId ?? ''),
    },
  ];

  const changeProtection: IMgdDetailKeyValueProps[] = [
    {
      title: 'Delete protection',
      description: deleteProtection ? 'Enabled' : 'Disabled',
    },
    {
      title: 'Subnet change protection',
      description: subnetChangeProtection ? 'Enabled' : 'Disabled',
    },
  ];

  const customerManagedKey: IMgdDetailKeyValueProps[] = [
    {
      title: 'Key type',
      description: getStrValueOrNull(encryptionConfiguration?.type || ''),
    },
  ];

  const tagColumns: ColumnType[] = [
    {
      label: 'Key',
      field: 'key',
      sort: true,
    },
    {
      label: 'Value',
      field: 'value',
      sort: true,
    },
  ];

  const firewallPolicy: IMgdDetailKeyValueProps[] = [
    {
      title: 'Associated firewall policy',
      description: getStrValueOrNull(firewallPolicyResponse?.firewallPolicyName ?? ''),
      type: TextTypeEnum.LINK,
    },
  ];

  const renderAction = (setIsOpen: (value: boolean) => void) => {
    return isEdit ? (
      <button className="button" onClick={() => setIsOpen(true)}>
        Edit
      </button>
    ) : null;
  };

  return (
    <>
      <DetailTab title="Firewall details" data={firewallDetails} actions={renderAction(setIsOpenEditFirewallDetails)} />

      <DetailTab title="VPC" data={vpc} actions={renderAction(setIsOpenEditVpc)} />

      <FirewallEndpoints data={data} />

      <DetailTab title="Firewall Policy" data={firewallPolicy} actions={renderAction(setIsOpenEditFirewallPolicy)} />

      <ConfigurationSyncState data={data} />

      <DetailTab
        title="Change protection"
        data={changeProtection}
        actions={renderAction(setIsOpenEditChangeProtection)}
      />

      <DetailTab
        title="Customer managed key"
        data={customerManagedKey}
        actions={renderAction(setIsOpenEditCustomerManagedKey)}
      />

      <TagTab
        columns={tagColumns}
        rows={tags || []}
        title="Firewall tags"
        rightButtons={isEdit ? [{ id: 'edit-tags', label: 'Edit', onClick: () => setIsOpenEditTag(true) }] : []}
      />

      {isOpenEditFirewallDetails && (
        <EditFirewallDetailModal
          type={EditFirewallDetailModalTypeEnum.EditRuleGroup}
          header={'Edit firewall details'}
          cloudId={cloudId}
          region={region}
          data={data}
          setData={setData}
          placeholderDescription={'description'}
          open={isOpenEditFirewallDetails}
          onClose={() => setIsOpenEditFirewallDetails(false)}
        />
      )}

      {isOpenEditFirewallPolicy && (
        <EditFirewallPolicyModal
          header={'Edit firewall policy'}
          cloudId={cloudId}
          region={region}
          data={data}
          setData={setData}
          firewallPolicyResponse={firewallPolicyResponse}
          open={isOpenEditFirewallPolicy}
          onClose={() => setIsOpenEditFirewallPolicy(false)}
        />
      )}

      {isOpenEditVpc && (
        <EditVPCAndSubnetsModal
          header="Edit VPC and subnets"
          cloudId={cloudId}
          region={region}
          data={data}
          setData={setData}
          vpcId={vpcId}
          open={isOpenEditVpc}
          onClose={() => setIsOpenEditVpc(false)}
        />
      )}

      {isOpenEditChangeProtection && (
        <ProtectionAgainstChangeModal
          header={'Protection against changes'}
          cloudId={cloudId}
          region={region}
          data={data}
          setData={setData}
          open={isOpenEditChangeProtection}
          onClose={() => setIsOpenEditChangeProtection(false)}
        />
      )}

      {isOpenEditCustomerManagedKey && (
        <EditKMSEncryptionModal
          header={'Edit KMS encryption settings'}
          cloudId={cloudId}
          region={region}
          data={data}
          setData={setData}
          open={isOpenEditCustomerManagedKey}
          onClose={() => setIsOpenEditCustomerManagedKey(false)}
        />
      )}

      {isOpenEditTag && (
        <UpdateTagsModal
          header={'Edit tags'}
          subHeader={'Edit firewall tags'}
          cloudId={cloudId}
          region={region}
          data={data}
          setData={setData}
          open={isOpenEditTag}
          onClose={() => setIsOpenEditTag(false)}
        />
      )}
    </>
  );
};

export default Details;
