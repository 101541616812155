import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { AwsDeleteAliasKmsRequestType } from 'graphql/types/AwsDeleteAliasKms';

export type AwsDeleteAliasKmsVariablesType = {
  cloudId: number;
  region: string;
  reqData: AwsDeleteAliasKmsRequestType;
};

export type AwsDeleteAliasKmsResponseDataType = {
  deleteAwsAliasKms: IGqlResponseData<{}>;
};

const deleteAwsAliasKmsMutation = () =>
  useMutation<AwsDeleteAliasKmsResponseDataType, AwsDeleteAliasKmsVariablesType>(query);
export default deleteAwsAliasKmsMutation;
