import { gql } from '@apollo/client';

const query = gql`
  mutation associateAwsFirewallPolicy($request: AwsAssociateFirewallPolicyRequest, $cloudId: Long, $region: String) {
    associateAwsFirewallPolicy(
      associateFirewallPolicyRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsAssociateFirewallPolicyResponse {
          firewallArn
          firewallName
          firewallPolicyArn
          updateToken
        }
      }
    }
  }
`;
export default query;