import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import InputAtom from 'components/v2/atoms/InputAtom';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import Memo from 'components/v2/Memo';
import lazyGetMemos from 'graphql/queries/getMemos';
import addMemo from 'graphql/mutations/addMemo';
import { MemoInfo } from 'graphql/types/MemoInfo';

interface IMemoDetailProps {
  memoRelObjTypeList: {
    name: string;
    value: string;
  }[];
  pjList: {
    name: string;
    value: number;
  }[];
  data: MemoInfo;
  convertGridIsShowing: () => void;
  openDeleteModal: () => void;
  onEditSuccess: () => void;
}

const MemoDetail = ({
  memoRelObjTypeList,
  pjList,
  data,
  convertGridIsShowing,
  openDeleteModal,
  onEditSuccess
}: IMemoDetailProps) => {

  const { tooltip } = useGModal();

  const [edit, setEdit] = useState(false)

  const [detail, setDetail] = useState({
    id: -1,
    relObjectType: '', /* 메모 종류 */
    name: '', /* 아이템 이름 */
    checkYn: false, /* 사용여부 */
    text: '', /* 본문 */
    projectId: -1, 
    projectName: ''
  });

  const [memoData, setMemoData] = useState<MemoInfo[]>([]);
  const [memoSaveTrigger, setMemoSaveTrigger] = useState(false);
  const [updateMemoResult, setUpdateMemoResult] = useState(false)

  /* memo 요청사항 조회 */
  const [lazyGetMemo] = lazyGetMemos();
  /* memo 추가 */
  const [addMemos] = addMemo();

  const origin = useMemo(() => {
    return {
      id: Number(data.id),
      relObjectType: data.relObjectType, 
      name: data.name, 
      checkYn: data.checkYn, 
      text: data.text,
      projectId: data.projectId,
      projectName: data.projectName
    }
  }, []);

  const enableButton = useMemo(() => {
    if (
      detail.relObjectType === '' ||
      detail.name === '' ||
      detail.projectId === -1 ||
      detail.text === ''
    ) {return true } else return false;
  }, [detail])

  /** 리포트 메모 재호출 */
  const refetchMemos = () => {
    const selectReport = data;

    if (selectReport) {
      lazyGetMemo({ variables: { reqGetMemo: { 
        relObjectId: Number(selectReport.relObjectId), 
        relObjectType: String(selectReport.relObjectType)
      }}}).then(({ data }) => {
        if (data) {
          if (data.getMemos.data.length === 0) {
            addMemos({ variables: { memo: {
              relObjectId: selectReport.relObjectId, 
              relObjectType: selectReport.relObjectType,
              text: '', 
              checkYn: true
            }} }).then(({ data }) => {
              if (data) {
                setMemoData(data.addMemo.data);
              }
            });
          }
          else {
            setMemoData(data.getMemos.data);
          }
        }
      });
    }
  };

  useEffect(() => {
    refetchMemos();
    setDetail({
      id: Number(data.id),
      relObjectType: data.relObjectType, 
      name: data.name, 
      checkYn: data.checkYn, 
      text: data.text,
      projectId: data.projectId, 
      projectName: data.projectName
    });

    /* editor js codex-tooltip dom 삭제 */
    return () => {
      const ct_list = document.querySelectorAll('.ct');

      if (ct_list) {
        ct_list.forEach(val => val.remove());
      }
    }
  }, [data]);

  useEffect(() => {
    if (updateMemoResult) {
      setEdit(false);
      onEditSuccess();
      setUpdateMemoResult(false);
    }
  }, [updateMemoResult])

  return (
    <div className='user-information-detail'>
      <div className='user-information-detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />  
        {!edit ? `${data.name} (comment)` : 'Edit comment'}
      </div>
      <div className='detail-contents'> 
      <InfoCard
        title="Name" 
        // required={edit}
        className={`${edit && 'edit'}`}
        content={() => 
          <>
            {detail.name}
          </>
        } 
      />
      <InfoCard
        title="Type" 
        // required={edit}
        className={`${edit && 'edit'}`}
        content={() => <>{enumFormatter(detail.relObjectType)}</>
        } 
      />
      <InfoCard
        title="Project" 
        // required={edit}
        className={`${edit && 'edit'}`}
        content={() => 
          <>{data.projectName}</>
        } 
      />
      {!edit && <InfoCard
        title="Used" 
        content={() => 
          <>{detail.checkYn ? 'Yes' : 'No'}</>
        } 
      />}
      {
        !edit &&
      <>
      <InfoCard
        title="Created"
        content={() => 
          <>
          <u
            onMouseOver={(e) => {
              tooltip.userTooltip({ 
                top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                  ? e.currentTarget.getBoundingClientRect().bottom - 115
                  : e.currentTarget.getBoundingClientRect().bottom + 12,
                left: e.currentTarget.getBoundingClientRect().left, 
                userInfo: { userId: data.createdByUserId, fullName: data.createdByName, createdAt: !data.createdAt ? '-' : data.createdAt, thumbnail: '' }
              });
            }}
            onMouseLeave={tooltip.close}
          >
          {data.createdByName}({data.createdByUserId})</u> / {!data.createdAt ? '-' : dateFormatter(data.createdAt, 'datetime')}
          </> 
        } 
      />
      <InfoCard
        title="Updated"
        content={() => 
          <>
              <u
                onMouseOver={(e) => {
                  tooltip.userTooltip({ 
                    top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                      ? e.currentTarget.getBoundingClientRect().bottom - 115
                      : e.currentTarget.getBoundingClientRect().bottom + 12,
                    left: e.currentTarget.getBoundingClientRect().left, 
                    userInfo: { userId: data.modifiedByUserId, fullName: data.modifiedByName, createdAt: !data.modifiedAt ? '-' : data.modifiedAt, thumbnail: '' }
                  });
                }}
                onMouseLeave={tooltip.close}
              >
                {data.modifiedByName}({data.modifiedByUserId})</u> / {!data.modifiedAt ? '-' : dateFormatter(data.modifiedAt, 'datetime')}
          </> 
        } 
      />
      </>
      }
      <InfoCard
        title="Body" 
        className={`${edit && 'edit'}`}
        content={() => 
        <div className="memo-content">
          <div className='editor-wrap'>
            <Memo 
              data={memoData} 
              reportId={Number(data.id)} 
              projectId={data.projectId} 
              saveTrigger={memoSaveTrigger} 
              updateMemoResult={(status:boolean) => setUpdateMemoResult(status)}
              checkYn={detail.checkYn}
              resetSaveTrigger={() => {setMemoSaveTrigger(false)}}
            />
          </div>
          { !edit && <div className='prevent-edit-wrap' /> }
        </div>}
      />
      {edit && <InfoCard
        title="Used" 
        className={`${edit && 'edit'}`}
        content={() => 
          <div className='radio-wrap flex a-center'>
            <div className='flex a-center'>
              <input 
                type="radio" 
                id="checkYn-rd1" 
                name="checkYn" 
                checked={detail.checkYn}  
                onChange={() => setDetail(prev => ({
                  ...prev,
                  checkYn: true
                }))}
              />
              <label 
                htmlFor="checkYn-rd1" 
                className={`checkYn-raido ${detail.checkYn && 'checked'}`}
              />
              Yes
            </div>
            <div className='flex a-center'>
              <input 
                type="radio" 
                id="checkYn-rd2" 
                name="checkYn" 
                checked={!detail.checkYn} 
                onChange={() => setDetail(prev => ({
                  ...prev,
                  checkYn: false
                }))}
              />
              <label 
                htmlFor="checkYn-rd2" 
                className={`checkYn-raido ${!detail.checkYn && 'checked'}`}
              />
              No
            </div>
          </div>
        } 
      />}
      </div>
      <div className="btns flex j-end">
        {
          !edit 
            ? <>
              <button 
                className="big-sub-btn"
                onClick={() => setEdit(true)}
              >
                Edit
              </button>
              <button 
                className="big-main-btn"
                onClick={openDeleteModal}
              >
                Delete
              </button> 
            </>
            : <>
              <button
                className="big-sub-btn"
                onClick={() => {
                  setDetail({ ...origin });
                  setEdit(false);
                }}
              >
                Cancel
              </button>
              <button 
                className="big-main-btn"
                onClick={() => { 
                  setMemoSaveTrigger(true);
                }}
              >
                Save
              </button>
            </>
        }
      </div>
    </div>
  )
}
export default MemoDetail;