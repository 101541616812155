import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';

type AwsStatelessRuleGroupReferenceLog = {
  resourceArn: string;
  priority: number;
};

type AwsDimensionLog = {
  value: string;
};

type AwsPublishMetricActionLog = {
  dimensions: AwsDimensionLog[];
};

type AwsActionDefinitionLog = {
  publishMetricAction: AwsPublishMetricActionLog;
};

type AwsCustomActionLog = {
  ractionName: string;
  actionDefinition: AwsActionDefinitionLog;
};

type AwsStatefulRuleGroupOverrideLog = {
  action: string;
};

type AwsStatefulRuleGroupReferenceLog = {
  resourceArn: string;
  priority: number;
  override: AwsStatefulRuleGroupOverrideLog;
};

type AwsStatefulEngineOptionsLog = {
  ruleOrder?: string;
  streamExceptionPolicy?: string;
};

type AwsPolicyVariablesLog = {
  ruleVariables?: any;
};

type AwsTaglog = {
  key: string;
  value: string;
};

export interface ICreateAwsFirewallPolicyVariables {
  cloudId: number;
  region: string;
  request: {
    firewallPolicyName: string;
    encryptionConfiguration: {
      type: string;
      keyId?: string;
    };
    description?: string;
    firewallPolicy?: {
      statelessRuleGroupReferences?: AwsStatelessRuleGroupReferenceLog[];
      statelessDefaultActions?: string[];
      statelessFragmentDefaultActions?: string[];
      statelessCustomActions?: AwsCustomActionLog[];
      statefulRuleGroupReferences?: AwsStatefulRuleGroupReferenceLog[];
      statefulDefaultActions?: string[];
      statefulEngineOptions?: AwsStatefulEngineOptionsLog;
      tlsInspectionConfigurationArn?: string;
      policyVariables?: AwsPolicyVariablesLog;
    };
    tags?: AwsTaglog[];
  };
}

export interface ICreateAwsFirewallPolicyResponseData {
  createAwsFirewallPolicy: IGqlResponseNewData<undefined>;
}

const createAwsFirewallPolicyMutation = () =>
  useMutation<ICreateAwsFirewallPolicyResponseData, ICreateAwsFirewallPolicyVariables>(query);
export default createAwsFirewallPolicyMutation;
