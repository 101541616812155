import { gql } from '@apollo/client';

const query = gql`
  mutation createAwsAliasKms($reqData: AwsCreateAliasKmsRequest, $cloudId: Long, $region: String) {
    createAwsAliasKms(
      createAliasKmsRequest: $reqData
      cloudId: $cloudId
      region: $region
    ) {
      result
    }
  }
`;

export default query;