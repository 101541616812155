import { AwsDescribeFirewallPolicyResponse } from 'graphql/types/AwsFirewallPolicy';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import { useState } from 'react';
import { IBaseFirewallProps } from '../../../../types';
import StatelessActionsModal from '../../StatelessActionsModal';
import StateRuleGroups from './StateRuleGroups';
interface IFirewallPolicySettingProps extends IBaseFirewallProps {
  firewallPolicies?: AwsDescribeFirewallPolicyResponse;
  onRefreshFirewallPolicy?: () => void;
  isEdit: boolean;
}

function FirewallPolicySettings({ cloudId, region, firewallPolicies, onRefreshFirewallPolicy = () => { }, isEdit }: IFirewallPolicySettingProps) {
  const { statelessDefaultActions, statelessFragmentDefaultActions, statefulEngineOptions, statefulDefaultActions } =
    firewallPolicies?.firewallPolicy || {};

  const [isOpenEditStateless, setIsOpenEditStateless] = useState(false);

  const statelessDefaultActionsData: IMgdDetailKeyValueProps[] = [
    {
      title: 'Actions for full packets',
      description:
        statelessDefaultActions?.[0] === 'aws:forward_to_sfe'
          ? 'Forward to stateful rule groups.'
          : statelessDefaultActions?.[0]?.substring(4),
    },
    {
      title: 'Actions for fragmented packets',
      description:
        statelessFragmentDefaultActions?.[0] === 'aws:forward_to_sfe'
          ? 'Forward to stateful rule groups.'
          : statelessFragmentDefaultActions?.[0].substring(4),
    },
  ];

  const statefulRuleEvaluation: IMgdDetailKeyValueProps[] = [
    {
      title: 'Rule order',
      description: statefulEngineOptions?.ruleOrder,
    },
    {
      title: 'Default actions',
      description: statefulDefaultActions?.[0].substring(4),
    },
  ];

  const renderAction = (setIsOpen: (value: boolean) => void) => {
    return isEdit ? (
      <button className="button" onClick={() => setIsOpen(true)}>
        Edit
      </button>
    ) : null;
  };

  return (
    <>
      <DetailTab
        title="Stateless default actions"
        description="Stateless default actions determine how Network Firewall should handle packets that don’t match any stateless rule group contained in this policy. You must set stateless default action regardless of whether you define stateless rule groups for the policy."
        data={statelessDefaultActionsData}
        actions={renderAction(setIsOpenEditStateless)}
      />

      <StateRuleGroups
        cloudId={cloudId}
        region={region}
        firewallPolicies={firewallPolicies}
        type="STATELESS"
        isEdit={isEdit}
        onRefreshFirewallPolicy={onRefreshFirewallPolicy}
      />

      <DetailTab
        title="Stateful rule evaluation order and default actions section"
        description="The way that your stateful rules are ordered for evaluation."
        data={statefulRuleEvaluation}
      />

      <StateRuleGroups
        cloudId={cloudId}
        region={region}
        firewallPolicies={firewallPolicies}
        type="STATEFUL"
        isEdit={isEdit}
        onRefreshFirewallPolicy={onRefreshFirewallPolicy}
      />

      {isOpenEditStateless && (
        <StatelessActionsModal
          header={'Stateless default actions'}
          cloudId={cloudId}
          region={region}
          open={isOpenEditStateless}
          firewallPolicies={firewallPolicies}
          onClose={() => setIsOpenEditStateless(false)}
          onRefreshFirewallPolicy={onRefreshFirewallPolicy}
        />
      )}
    </>
  );
}

export default FirewallPolicySettings;
