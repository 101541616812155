import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import lazyGetAwsListResourceProfileDetections, {
  IGetAwsListResourceProfileDetectionsVariables,
} from 'graphql/queries/getAwsListResourceProfileDetections';
import { IMgdDetailKeyValueProps, IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { IResourceCoverageSummaryProps } from 'pages/v2/Organ/Management/Macie/types';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { useEffect, useMemo, useState } from 'react';
import DetailItem from '../../../../DetailItem';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { DropdownListDataType } from 'components/Dropdown/types';
import "./index.scss"

interface ISensitivityProps extends IResourceCoverageSummaryProps {}

const dropdownActions: DropdownListDataType[] = [{ name: 'Actions', value: '' }];

const Sensitivity = ({ cloudId, region, selectedResourceCoverage }: ISensitivityProps) => {
  // TODO: design figma not described
  const [selectedAction, setSelectedAction] = useState<DropdownListDataType>(dropdownActions[0]);
  const [nextToken, setNextToken] = useState<string>('');
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [getAwsListResourceProfileDetections, { loading }] = lazyGetAwsListResourceProfileDetections();

  const mainRowsCurrentPage = useMemo((): RowType[] => {
    // if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!nextToken) {
    //   // handleGetMainDataRows(nextToken);
    // }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    setMainTblTotal({
      totalPage: Math.ceil(mainTblRows.length / mainTablePagination.itemPerPage),
      totalElement: mainTblRows.length,
    });

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, mainTablePagination]);

  const sensitivityDetails: IMgdDetailKeyValueProps[] = [
    { title: `${selectedResourceCoverage.sensitivityScore} / 100`, description: '' },
  ];

  const columns: ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Sensitive data type',
        field: 'type',
        sort: true,
        renderCell: (row: RowType) => <span>{row.type}</span>,
      },
      {
        label: 'Count',
        field: 'count',
        sort: true,
        renderCell: (row: RowType) => <span>{row.count}</span>,
      },
      {
        label: 'Scoring status',
        field: 'classificationResultStatus',
        sort: true,
        renderCell: (row: RowType) => <span>{row.classificationResultStatus}</span>, // TODO: design figma not described
      },
    ];
  }, []);

  const fetchListResourceProfileDetections = async () => {
    let variables: IGetAwsListResourceProfileDetectionsVariables = {
      cloudId,
      region,
      request: {
        maxResults: mainTablePagination.limit,
        resourceArn: selectedResourceCoverage.bucketArn,
      },
    };

    variables = variableCombineNextToken(variables, nextToken);

    const response = await getAwsListResourceProfileDetections({ variables });

    const { nextToken: token = '', detections = [] } =
      response.data?.getAwsListResourceProfileDetections.data?.[0] || {};
    setNextToken(token);
    setMainTblRows(detections);

    setMainTblTotal({
      totalPage: Math.ceil(detections.length / mainTablePagination.limit),
      totalElement: detections.length,
    });
  };

  useEffect(() => {
    fetchListResourceProfileDetections();
  }, []);

  return (
    <div className="mgd-sensitivity-tab">
      <DetailItem title="Sensitivity score" data={sensitivityDetails} />

      <div className="mgd-sensitivity-tab-detection flex a-center j-between">
        <p className='mgd-sensitivity-tab-title'>Detections</p>
        <DropdownAtom
          id="mgd-sensitivity-tab-actions"
          disabled
          data={dropdownActions}
          value={selectedAction}
          handleClick={() => {}}
        />
      </div>
      <div className="data-grid-wrap">
        <Table
          className='mgd-sensitivity-table'
          rows={mainRowsCurrentPage}
          columns={columns}
          reportCheckedList={() => {}}
          reportSelected={() => {}}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              setMainTablePagination(prev => ({ ...prev, direction: dir, target }));
            },
          }}
          isLoading={loading}
          horizontalScrollable
        />
        {!!mainRowsCurrentPage?.length && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center">
              Total <span>{mainTblTotal.totalElement}</span>
            </p>
            <TableManagePagination
              ableFetchMore={!!nextToken}
              currentPage={mainTablePagination.currentPage}
              updateCurrentPage={page =>
                setMainTablePagination(prev => ({
                  ...prev,
                  ['currentPage']: page,
                }))
              }
              totalPage={mainTblTotal.totalPage}
            />
          </div>
        )}
        {!mainRowsCurrentPage?.length && !loading && (
          <div className="data-grid-wrap">
            <p className="empty-row">No detection found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sensitivity;
