import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsDeleteRuleGroupFirewallResponseType } from 'graphql/types/AwsDeleteRuleGroupFirewall';

export type AwsDeleteFirewallRuleGroupVariables = {
  cloudId: number;
  region: string;
  request: {
    ruleGroupName?: string;
    ruleGroupArn?: string;
    type?: string;
  };
};

export type AwsDeleteFirewallRuleGroupResponseData = {
  deleteAwsRuleGroupFirewall: IGqlResponseNewData<AwsDeleteRuleGroupFirewallResponseType>;
};

const deleteAwsRuleGroupFirewallMutation = () =>
  useMutation<AwsDeleteFirewallRuleGroupResponseData, AwsDeleteFirewallRuleGroupVariables>(query);
export default deleteAwsRuleGroupFirewallMutation;
