import { useState, useEffect, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import Icon from 'components/v2/atoms/Icon';
import IconTag from 'assets/svgs/v3/ico_tag.svg';
import './index.scss';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import InputCustomDesc from '../../components/InputCustomDesc';
import { TagRowDataErrType, TagRowDataType } from '../../../types';
import SearchPrefixIcon from 'assets/svgs/v4/ico_search.svg';

interface IUpdateTagsModalProps extends IBaseModalProps {
  header: string;
  subHeader?: string;
  currentDatas: any;
  columns: string[];
  note?: string;
  loadingTagsCreat?: boolean;
  onClose: () => void;
  onSave: (oldTags: TagRowDataType[], newTags: TagRowDataType[]) => Promise<boolean>;
}

const UpdateTagsModal = ({
  header,
  subHeader,
  currentDatas,
  columns,
  note,
  onSave,
  onClose,
  loadingTagsCreat,
  ...baseModalProps
}: IUpdateTagsModalProps) => {
  const [tagRows, setTagRows] = useState<TagRowDataType[]>([]);
  const [currentTagRows, setCurrentTagRows] = useState<TagRowDataType[]>([]);
  const [errorTags, setErrorTags] = useState<TagRowDataErrType[]>([]);
  const [validation, setValidation] = useState<boolean>(true);

  useEffect(() => {
    if (baseModalProps.open) {
      if (currentDatas?.length === 0) setTagRows([{ index: 0, key: '', value: '' }]);
      setCurrentTagRows(currentDatas);
    } else {
      setTagRows([]);
      setCurrentTagRows([]);
    }
  }, [baseModalProps.open]);

  const handleSaveTagsDetail = useCallback(async(oldTags: TagRowDataType[], newTags: TagRowDataType[]) => {
    if (validation) {
      const result = await onSave(oldTags, newTags);
      result ? onClose() : null;
    }}, [validation, currentTagRows, tagRows]);

  const handleCreate = useCallback(() => {
    setTagRows(prevState => {
      const newState = prevState.concat({ index: tagRows.length, key: '', value: '' });

      return newState;
    });
  },[tagRows, setTagRows]);

  const handleDelete = useCallback( (indexToRemove: number) => {
    const newData = tagRows.filter((_, index) => index !== indexToRemove);
    setTagRows(newData);
  }, [tagRows, setTagRows]);

  const handleChange = (index: number, value: string, propName: keyof TagRowDataType) => {
    const newRows = tagRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setTagRows(newRows);
  };

  const handleCurrentTagDelete = (indexToRemove: number) => {
    const newData = currentTagRows.map((data, index) => (index === indexToRemove ? { ...data, flagDel: true } : data));
    setCurrentTagRows(newData);
  };

  const handleUndoCurrentTagDelete = (indexToUndo: number) => {
    const newData = currentTagRows.map((data, index) => (index === indexToUndo ? { ...data, flagDel: false } : data));
    setCurrentTagRows(newData);
  };

  const checkDuplicates = (arr: TagRowDataType[], keyAValue: string) => {
    const filtered = arr.filter(obj => obj.key === keyAValue && obj.key !== '' && !obj.flagDel);

    return filtered.length > 1;
  };

  const handleValidationTags = () => {
    const allTags = [...currentTagRows, ...tagRows];
    const errData = tagRows.map((tag, index) => {
      let errKey = '';
      if (!tag.key) {
        errKey = 'You must specify a tag key.';
      } else if (checkDuplicates(allTags, tag.key)) {
        errKey = 'You must specify a unique tag key.';
      }
      setValidation(errKey === '');

      return { index, key: tag.key, errorKey: errKey };
    });
    setErrorTags(errData);
  };

  const handleGetCurrentTagDup = useCallback(
    (dataTag: TagRowDataType) => {
      let err = '';
      if (!dataTag.flagDel) {
        const checkDup = tagRows.filter(tag => tag.key === dataTag.key);
        err = checkDup.length > 0 ? 'You must specify a unique tag key.' : '';
      }

      return err;
    },
    [tagRows, setTagRows],
  );

  useEffect(() => {
    handleValidationTags();
  }, [tagRows, currentTagRows]);

  const handleCurrentTagChange = (index: number, value: string, propName: keyof TagRowDataType) => {
    const newRows = currentTagRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setCurrentTagRows(newRows);
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconTag} />
          {header}
        </>
      )}
      onClose={onClose}
      {...baseModalProps}
    >
      <div className="update-tag-model">
        {subHeader && <div className="sub-title">{subHeader}</div>}

        {columns && (
          <div className="tag-row-container">
            <div className="tag-row-input">
              {columns.map((column, index) => {
                return (
                  <div key={`udpate-tags-table-column-${index}`} className="column">
                    <p>{column}</p>
                  </div>
                );
              })}
            </div>
            <div className="tag-row-btn" />
          </div>
        )}

        {currentTagRows && (
          <>
            {currentTagRows.map((tag, index) => (
              <div className="tag-row-container" key={`udpate-tags-table-row-${index}`}>
                <div className="tag-row-input">
                  <div className="column">
                    <InputCustomDesc error={handleGetCurrentTagDup(tag)}>
                      <InputAtom
                        type={'text'}
                        value={tag.key}
                        onChangeValue={(value: string) => handleCurrentTagChange(index, value, 'key')}
                        defaultValue={''}
                        disabled
                        noClear={true}
                        hasPrefixIcon={true}
                        srcPrefixIcon={SearchPrefixIcon}
                        error={!!handleGetCurrentTagDup(tag)}
                      />
                    </InputCustomDesc>
                  </div>
                  <div className="column">
                    {!tag.flagDel ? (
                      <InputCustomDesc error={''}>
                        <InputAtom
                          type={'text'}
                          value={tag.value}
                          onChangeValue={(value: string) => handleCurrentTagChange(index, value, 'value')}
                          defaultValue={''}
                          noClear={false}
                          hasPrefixIcon={true}
                          srcPrefixIcon={SearchPrefixIcon}
                        />
                      </InputCustomDesc>
                    ) : (
                      <div className="tag-note-del">
                        This tag will be removed when you save your changes{' '}
                        <a className="link" onClick={() => handleUndoCurrentTagDelete(index)}>Undo</a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="tag-row-btn">
                  {!tag.flagDel ? (
                    <button className="button" onClick={() => handleCurrentTagDelete(index)}>
                      Remove
                    </button>
                  ) : null}
                </div>
              </div>
            ))}
          </>
        )}

        {tagRows && (
          <>
            {tagRows.map((row, index) => (
              <div className="tag-row-container" key={`udpate-tags-table-new-row-${index}`}>
                <div className="tag-row-input">
                  <div className="column">
                    <InputCustomDesc error={errorTags[index]?.errorKey}>
                      <InputAtom
                        type={'text'}
                        value={row.key}
                        onChangeValue={(value: string) => handleChange(index, value, 'key')}
                        defaultValue={''}
                        noClear={true}
                        hasPrefixIcon={true}
                        srcPrefixIcon={SearchPrefixIcon}
                        error={!!errorTags[index]?.errorKey}
                      />
                    </InputCustomDesc>
                  </div>
                  <div className="column">
                    <InputCustomDesc error={''}>
                      <InputAtom
                        type={'text'}
                        value={row.value}
                        onChangeValue={(value: string) => handleChange(index, value, 'value')}
                        defaultValue={''}
                        noClear={false}
                        hasPrefixIcon={true}
                        srcPrefixIcon={SearchPrefixIcon}
                      />
                    </InputCustomDesc>
                  </div>
                </div>
                <div className={`tag-row-btn ${errorTags[index]?.errorKey !== '' ? ' mg-bottom-30' : ''}`}>
                  <button className="button" onClick={() => handleDelete(index)}>
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </>
        )}

        <div className="add-new-tag-container">
          <button className="normal-btn" onClick={handleCreate}>
            Add new tag
          </button>
          {!!note && <p className="note">{note}</p>}
        </div>

        <div className="cancle-save-container">
          <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={onClose} />
          <Button
            label="Save"
            type={ButtonTypeEnum.PRIMARY}
            loading={loadingTagsCreat}
            onClick={() => handleSaveTagsDetail(currentTagRows, tagRows)}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default UpdateTagsModal;
