import { useLazyQuery } from '@apollo/client';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListCertificatesRequest, AwsListCertificatesResponse } from 'graphql/types/AwsListCertificates';
import query from './query';

export interface IGetAwsListCertificateVariables {
  cloudId: number;
  region: string;
  reqData: AwsListCertificatesRequest;
}

export interface IAwsListCertificateResponseData {
  getAwsListCertificates: IGqlResponseNewData<AwsListCertificatesResponse>;
}

const lazyGetAwsListCertificate = () =>
  useLazyQuery<IAwsListCertificateResponseData, IGetAwsListCertificateVariables>(query, {
    fetchPolicy: 'no-cache',
  });

export default lazyGetAwsListCertificate;
