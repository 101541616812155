import { useEffect, useMemo, useRef, useState } from 'react';
import './index.scss';
import Profile from './Profile';
import { useAuth } from 'contexts/AuthProvider';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import { OrganById } from 'apis/v2/Organ/schema';
import { ProfileType } from 'components/v2/Header';
import Environment from './Environment';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import updateMember from 'graphql/mutations/updateMember';
import { checkEmail, checkUserName } from 'utils/v2/ValidCheck';
import { myProjectAlertList } from 'utils/DummyData/Default';
import { ProfileAlertType } from '@Types/v4/Member';

export interface IMyProfileModalProps {
  /** 모달이 켜지는 조건 */
  open: boolean;
  /** 모달이 꺼지는 함수 */
  onClose: () => void;
  /** className을 통한 외부 css 커스텀 @default 'default' */
  className?: string;
  /** 모달 내 우상단 닫기아이콘 보이기 유무 @default true */
  closeIcon?: boolean;
  /** 모달 바깥쪽 클릭을 통한 모달종료 @default true */
  closeOnClickBg?: boolean;
  /** 모달 애니메이션 재생시간 100ms단위, 설정범위: 양의정수 1~10 @default 3 */
  duration?: number;
  /** 모달 외부 장막의 opacity값 0.1단위, 설정범위: 양의정수 1~10 @default 3 */
  backgroundOpacity?: number;
  /** 기존에 켜져있는 모달의 중첩(레이어드)모달로써 사용할 경우 border-radius값 조정 필요 */
  borderRadius?: number;
  /* 프로젝트 리스트 */
  projectList: ProjectInfo[];
  organInfo: OrganById;
  data: ProfileType;
  onSuccessUpdateProfileInfo: (data:ProfileType) => void;
  userThumbnail: string;
  onSuccessUploadUserThumbnail: () => void;
}

let timer:NodeJS.Timeout;

const  MyProfileModal = ({
  open,
  onClose,
  className,
  closeIcon = true,
  closeOnClickBg = true,
  duration = 3,
  backgroundOpacity = 3,
  borderRadius,
  projectList,
  organInfo,
  data,
  onSuccessUpdateProfileInfo,
  userThumbnail,
  onSuccessUploadUserThumbnail
}: IMyProfileModalProps) => {

  const modalRef = useRef<HTMLDivElement>(null);

  const { userInfo, role } = useAuth();

  /** 모달 고유 ID */
  const [id] = useState(Date.now());
  
  const [profile, setProfile] = useState<ProfileType>({
    pictureResourceId: -1,
    id: '',
    fullName: '',
    userId: '',
    email: '',
    organName: '',
    ranks: '',
    description: '',
    countryCode: '',
    mobileNumber: '',
    alertYn: false,
    statusCode: '',
    mfaYn: false,
    mfaModifiedAt: null
  });
  const [myJoinedProjectAlert, setMyJoinedProjectAlert] = useState<ProfileAlertType[]>([]);

  /* 사용자 정보수정 */
  const [updateProfile] = updateMember();

  const enableSaveButton = useMemo(() => {
    if (checkUserName(profile.fullName) || checkEmail(profile.email) || profile.mobileNumber.length < 5 ) {
      return true;
    } else return false;
  }, [profile]);

  const onEditProfileInfo = (key: string, value: string|number|boolean) => {
    setProfile(prev => ({...prev, [key]:value}));
  }
  const onEditMyJoinedProjectAlert = (data: ProfileAlertType[]) => {
    setMyJoinedProjectAlert(data);
  }
  
  useEffect(() => {
    if (open) {
      document.body.setAttribute(`modal-id-${id}`, 'true');
      modalRef.current?.classList.remove('out', 'active');
      modalRef.current?.classList.add('active');

      clearTimeout(timer);
      
      setProfile(data);
      //todo
      setMyJoinedProjectAlert(myProjectAlertList);
    } else {
      modalRef.current?.classList.add('out');
      document.body.removeAttribute(`modal-id-${id}`);
    }
  }, [open, userInfo]);

    return (
      <div
        ref={modalRef}
        className="profile-modal-container modal-duration-5"
        data-duration={duration}
        data-bg-opacity={backgroundOpacity}
        data-border-radius={borderRadius}
      >
      <div 
        className="modal-background" 
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (!closeOnClickBg) return;
          if (e.target === e.currentTarget) onClose && onClose();
        }}
      >
        <div className={`profile-modal ${className} flex`}>
          <div className={`col-right`}>
            <div className="modal-head flex j-between a-center">
              <div className="modal-title flex a-center">My profile</div>
              { closeIcon && <button className="close-btn" onClick={onClose} /> }
            </div>
            <div className="modal-content">
              <Profile 
                userThumbnail={userThumbnail} 
                onSuccessUploadUserThumbnail={onSuccessUploadUserThumbnail} 
                profileInfo={profile}
                onEditProfileInfo={onEditProfileInfo} 
              />
              <Environment 
                mfaData={profile.mfaYn ? profile.mfaModifiedAt : null}
                alertList={myJoinedProjectAlert}
                editAlertList={onEditMyJoinedProjectAlert}
              />
              <div className="btns flex j-end a-end">
                <button 
                  className="big-main-btn flex j-center a-center"
                  disabled={ enableSaveButton }
                  onClick={() => {
                    const updateMemberData = {
                      reqUpdMember: {
                        id: String(userInfo?.nemo.memberId),
                        fullName: profile.fullName,
                        mobileNumber: profile.mobileNumber,
                        ranks: profile.ranks,
                        description: profile.description,
                        alertYn: profile.alertYn,
                        systemAuthCode: userInfo?.nemo.role!
                      }
                    }

                    /* 성공시 getMemberInfos로 리패치 했으나, 응답값이 갱신안 된 채로 와서 이api응답값으로 Header에 있는 내 이름표기 갱신 필요 */
                    updateProfile({variables:  updateMemberData }).then(({ data }) => {
                      if (data) {
                        if (data.updateMember.result === ErrorCode.SUCCESS ) {
                          useToast(ErrorCode.SUCCESS, '저장이 완료되었습니다.');
                          const result = data.updateMember.data[0];
                          const newData = {
                            id: result.id,
                            fullName: result.fullName,
                            email: result.email,
                            organName: result.organName,
                            ranks: result.ranks,
                            userId: result.userId,
                            alertYn: result.alertYn,
                            description: !result.description ? '' : result.description,
                            countryCode: '+82', /* temp 임시로 국가코드 이렇게 넣어주기. 실제 값이 어떻게 오는 지에 따라 변경필요! */
                            mobileNumber: result.mobileNumber,
                            statusCode: result.statusCode,
                            pictureResourceId: 123,
                            mfaYn: result.mfaYn,
                            mfaModifiedAt: result.mfaModifiedAt
                          }
                          onSuccessUpdateProfileInfo(newData);
                        } else {
                          console.log(data.updateMember.result);
                          useToast(ErrorCode.UNKNOWN, '저장을 실패했었습니다.');
                        }

                      } else {
                        useToast(ErrorCode.UNKNOWN, '저장을 실패했었습니다.');
                      }
                    })
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfileModal;
