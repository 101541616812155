import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export type AwsUntagResourceKmsRequestVariables = {
  cloudId: number;
  region: string;
  reqData: {
    keyId?: string;
    tagKeys?: string[];
  };
};

export type AwsUntagResourceKmsResponseData = {
  untagAwsResourceKms: IGqlResponseData<undefined>;
};

const untagAwsResourcesKmsMutation = () =>
  useMutation<AwsUntagResourceKmsResponseData, AwsUntagResourceKmsRequestVariables>(query);
export default untagAwsResourcesKmsMutation;
