import { ErrorCode } from '@Types/error';
import IconStatelessAction from 'assets/svgs/v3/ico_stateless_actions.svg';
import Icon from 'components/v2/atoms/Icon';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import BaseModal from 'components/v2/modals/BaseModal';
import updateAwsFirewallPolicyMutation, { UpdateAwsFirewallPolicyVariablesType } from 'graphql/mutations/updateAwsFirewallPolicy';
import { AwsDescribeFirewallPolicyResponse } from 'graphql/types/AwsFirewallPolicy';
import { useToast } from 'hooks/v2/useToast';
import { useState } from 'react';
import { IBaseFirewallModalProps } from '../../../types';
import './index.scss';

interface IStatelessActionsProps extends IBaseFirewallModalProps {
  header: string;
  firewallPolicies?: AwsDescribeFirewallPolicyResponse;
  onRefreshFirewallPolicy: () => void
}

interface IPackets {
  name: string;
  value: string;
}

const fragmentedPackets: IPackets[] = [
  {
    name: 'Use the same actions for all packets',
    value: 'same',
  },
  {
    name: 'Use different actions for full packets and fragmented packets',
    value: 'different',
  },
];

const defaultActionsPackets: IPackets[] = [
  {
    name: 'Pass',
    value: 'aws:pass',
  },
  {
    name: 'Drop',
    value: 'aws:drop',
  },
  {
    name: 'Forward to stateful rule groups',
    value: 'aws:forward_to_sfe',
  },
];

const StatelessActionsModal = ({ header, cloudId, region, firewallPolicies, onRefreshFirewallPolicy, ...baseModalProps }: IStatelessActionsProps) => {
  const { statelessDefaultActions, statelessFragmentDefaultActions, statefulEngineOptions, statefulDefaultActions } =
    firewallPolicies?.firewallPolicy || {};

  const initialPacketValue =
    statelessDefaultActions?.[0] === statelessFragmentDefaultActions?.[0] ? 'same' : 'different';
  const initialFullPacketValue = statelessDefaultActions?.[0] || 'aws:pass';
  const initialFragmentedPacketValue = statelessFragmentDefaultActions?.[0] || 'aws:pass';

  const [packetValue, setPacketValue] = useState<string>(initialPacketValue);
  const [fullPacketValue, setFullPacketValue] = useState<string>(initialFullPacketValue);
  const [fragmentedPacketValue, setFragmentedPacketValue] = useState<string>(initialFragmentedPacketValue);

  const [updateAwsFirewallPolicy] = updateAwsFirewallPolicyMutation();

  const handleSave = async () => {

    try {
      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken: firewallPolicies?.updateToken || '',
          firewallPolicyArn: firewallPolicies?.firewallPolicyResponse.firewallPolicyArn || '',
          firewallPolicyName: firewallPolicies?.firewallPolicyResponse.firewallPolicyName || '',
          firewallPolicy: {
            statelessDefaultActions: [fullPacketValue],
            statelessFragmentDefaultActions: packetValue === 'same' ? [fullPacketValue] : [fragmentedPacketValue],
            statefulDefaultActions: statefulDefaultActions!,
            statefulEngineOptions: statefulEngineOptions!,
          }
        }
      }

      await updateAwsFirewallPolicy({ variables });
      onRefreshFirewallPolicy();
      useToast(ErrorCode.SUCCESS, "Update Stateless default actions successfully.")
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, "Update Stateless default actions failed.")
    } finally {
      baseModalProps?.onClose?.();
    }
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconStatelessAction} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="stateless-actions-modal">
        <div className="stateless-actions">
          <div className="row">
            <p className="text-description">Fragmented packets</p>
            <div className="radio-content">
              {fragmentedPackets.map((packet, index) => (
                <RadioAtom
                  key={index}
                  label={packet.name}
                  value={packet.value}
                  name={"radio-fragmented-packets"}
                  checked={packetValue}
                  onChange={() => {
                    setPacketValue(packet.value);
                  }}
                />
              ))}
            </div>
          </div>

          <div className="row">
            {packetValue === 'different' && <p className="text-title">Default actions for full packets</p>}

            <p className="text-description">Rule action</p>
            {defaultActionsPackets.map((packet, index) => (
              <RadioAtom
                key={`full-packets-${index}`}
                label={packet.name}
                value={packet.value}
                name='radio-default-action-full-packets'
                checked={fullPacketValue}
                onChange={() => {
                  setFullPacketValue(packet.value);
                }}
              />
            ))}
          </div>

          {packetValue === 'different' && (
            <>
              <div className="row">
                <p className="text-title">Default actions for fragmented packets</p>
                <p className="text-description">Rule action</p>
                {defaultActionsPackets.map((packet, index) => (
                  <RadioAtom
                    key={`fragmented-packets-${index}`}
                    label={packet.name}
                    value={packet.value}
                    name='radio-default-action-fragmented-packets'
                    checked={fragmentedPacketValue}
                    onChange={() => {
                      setFragmentedPacketValue(packet.value);
                    }}
                  />
                ))}
              </div>
            </>
          )}
        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default StatelessActionsModal;
