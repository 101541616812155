import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import { useState } from 'react';
import { FIREWALL_COMMOMVALUE_MAPPING } from '../../configs';
import { getFormatedDateWithTimezone, getStrValueOrNull } from 'utils/Common';

const Details = ({ data, isLoading, isEdit }: any) => {
  const { ruleGroup, ruleGroupResponse } = data || {};

  const { ruleGroupName, type, description, capacity, lastModifiedTime } = ruleGroupResponse || {};

  const [isOpenEditDetails, setIsOpenEditDetails] = useState(false);

  const details: IMgdDetailKeyValueProps[] = [
    {
      title: 'Name',
      description: getStrValueOrNull(ruleGroupName),
    },
    {
      title: 'Desciption',
      description: getStrValueOrNull(description),
    },
    {
      title: 'Type',
      description: type,
    },
    {
      id: 'ruleOrder',
      title: 'Stateful rule order',
      description: getStrValueOrNull(FIREWALL_COMMOMVALUE_MAPPING[ruleGroup?.statefulRuleOptions?.ruleOrder]),
    },
    {
      title: 'Capacity',
      description: capacity,
    },
    {
      id: 'lastUpdated',
      title: 'Last updated',
      description: getFormatedDateWithTimezone(lastModifiedTime),
    },
  ];

  const renderAction = (setIsOpen: (value: boolean) => void) => {
    return isEdit ? (
      <button className="button" onClick={() => setIsOpen(true)}>
        Edit
      </button>
    ) : null;
  };

  return (
    <>
      <DetailTab
        title="Stateful standard rule group details"
        data={details}
        actions={renderAction(setIsOpenEditDetails)}
        isApiLoading={isLoading}
      />
    </>
  );
};

export default Details;
