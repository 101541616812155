import { gql } from '@apollo/client';

const query = gql`
  query getAwsListResourceProfileDetections(
    $request: AwsListResourceProfileDetectionsRequest
    $cloudId: Long
    $region: String
  ) {
    getAwsListResourceProfileDetections(
      awsListResourceProfileDetectionsRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsListResourceProfileDetectionsResponse {
          detections {
            arn
            count
            id
            name
            suppressed
            type
          }
          nextToken
        }
      }
    }
  }
`;

export default query;
