import { gql } from '@apollo/client';

const query = gql`
  mutation rotateAwsSecret($reqData: AwsRotateSecretRequest, $cloudId: Long, $region: String) {
    rotateAwsSecret(rotateSecretRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsRotateSecretResponse {
          arn
          name
          versionId
        }
      }
    }
  }
`;
export default query;
