import { useOutletContext, useParams } from 'react-router-dom';
import './index.scss';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import { useMemo, useState } from 'react';
import UpdateUserDashboardModal from 'components/v4/modals/UpdateUserDashboard';
import { useAuth } from 'contexts/AuthProvider';
import RedashComp from 'components/v4/RedashComp';
import Icon from 'components/v2/atoms/Icon';
import RedCircleGraph from 'assets/svgs/v4/ico_red_circle_graph.svg';
import { DashboardListType } from 'graphql/types/DashboardListType';

const Dashboard = () => {
  const { organizationId, projectList, getOrganDashboardList, dashboardList } = useOutletContext<{
    organizationId: number | null;
    projectList: ProjectInfo[];
    getOrganDashboardList: () => void;
    dashboardList: DashboardListType[];
  }>();
  const {dashboardId} = useParams();
  const {role} = useAuth();
  const [modalOpen, setModalOpen] = useState(false);

  const selectDashboardData = useMemo(() => {
    return dashboardList.find(item => item.id.toString() === dashboardId);
  }, [dashboardId, dashboardList]);
  const src = useMemo(() => {
    if (organizationId) {
      if(dashboardId === 'overview') {
        if(process.env.REACT_APP_TYPE === 'DEV') {
          const source = `${process.env.REACT_APP_NEMO_DASHBOARD}/dashboard?organId=0`;
          
          return source;
        } else {
          const source = `${process.env.REACT_APP_NEMO_DASHBOARD}/dashboard?organId=${organizationId}`;
          
          return source;
        }
      } else {
        if(process.env.REACT_APP_TYPE === 'DEV') {
          const source = `${process.env.REACT_APP_NEMO_DASHBOARD}/dashboard?organId=0&dashId=${dashboardId}&name=${encodeURI(selectDashboardData?.name || '')}`;

          return source;
        } else {
          const source = `${process.env.REACT_APP_NEMO_DASHBOARD}/dashboard?organId=${organizationId}&dashId=${dashboardId}&name=${encodeURI(selectDashboardData?.name || '')}`;
          
          return source;
        }
      }
    } else {
      return '';
    }
  }, [organizationId, selectDashboardData]);

  return (
    <div id="dashboard-page">
      <h3>{dashboardId === 'overview' ? 'Overview' : selectDashboardData?.name || 'Overview'}
        <button 
          className="setting-btn" 
          disabled={role !== 'sy_admin' || dashboardId === 'overview'}
          onClick={() => setModalOpen(true)}
        />
      </h3>
      <div className="dashboard-content">
        <RedashComp src={src}/>
      </div>
      <UpdateUserDashboardModal
        open={modalOpen} 
        dashboardId={Number(dashboardId)}
        title={() => 
          <>
            <Icon width={32} height={32} src={RedCircleGraph} />
            Setting dashboard
          </>
        }
        onClose={() => setModalOpen(false)}
        onUpdate={(data: string) => {
          console.log('update dashboard', data);
          // gql success 일 경우 들어옴
          // aside 에 있는 dashboard list 에 업데이트
          setModalOpen(false);
          getOrganDashboardList();
        }}
        onDelete={(data: string) => {
          console.log('delete dashboard', data);
          // gql success 일 경우 들어옴
          // aside 에 있는 dashboard list 에 삭제
          setModalOpen(false);
          getOrganDashboardList();
        }}
      />
    </div>
  );
};
export default Dashboard;
