import { Fragment, useCallback, useEffect, useState } from 'react';
import { AccountRowType, AddAnotherAwsAccountPropsType } from '../types';
import Button, { ButtonTypeEnum } from '../Button';
import InputAtom from 'components/v2/atoms/InputAtom';
import './index.scss';
import { twelveNumberRegex } from 'utils/Regex';

const AddAnotherAwsAccount = (props: AddAnotherAwsAccountPropsType) => {
  const { currentAccounts, onChange } = props;
  const [accountRows, setAccountRows] = useState<AccountRowType[]>(currentAccounts);

  const handleAddAccount = useCallback(() => {
    const newAccounts = accountRows.slice();
    newAccounts.push({ index: accountRows.length, name: '' });

    setAccountRows(newAccounts);
  }, [accountRows]);

  const onUpdateAccount = useCallback(
    (index: number, value: string) => {
      const newAccounts = accountRows.map((row, i) => (i === index ? { ...row, name: value } : row));
      setAccountRows(newAccounts);
    },
    [accountRows],
  );

  const onRemoveAccount = useCallback(
    (indexToRemove: number) => {
      const newAccounts = accountRows.filter((_, index) => index != indexToRemove);
      setAccountRows(newAccounts);
    },
    [accountRows],
  );

  const onValidateValue = useCallback((value: string): string => {
    if (!twelveNumberRegex.test(value)) {
      return 'Must contain 12 numeric characters';
    }
    return '';
  }, []);

  useEffect(() => {
    onChange(accountRows);
  }, [accountRows]);

  return (
    <Fragment>
      {accountRows?.map(accountRow => {
        return (
          <div className={`account-row-container ${onValidateValue(accountRow.name) !== '' ? 'error' : ''}`} key={`account-row-containe-${accountRow.index}`}>
            <p className="prefix-title">Arn:aws:lam::</p>
            <InputAtom
              type={'text'}
              placeholder="Enter the ID of another AWS account."
              value={accountRow.name}
              onChangeValue={(value: string) => onUpdateAccount(accountRow.index, value)}
              defaultValue={''}
              noClear={accountRow.name === ''}
              hasPrefixIcon={false}
              error={onValidateValue(accountRow.name)}
            />
            <p className="subfix-title">:root</p>
            <Button label="Remove" onClick={() => onRemoveAccount(accountRow.index)} />
          </div>
        );
      })}
      <Button type={ButtonTypeEnum.PRIMARY} label="Add another AWS account" onClick={handleAddAccount} />
    </Fragment>
  );
};

export default AddAnotherAwsAccount;
