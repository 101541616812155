import InputAtom from 'components/v2/atoms/InputAtom';
import NetworkTitle from 'components/v3/NetworkTitle';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import RequiredIcon from 'assets/svgs/v2/ico_require.svg';
import { CustomRadio } from '../AllowList/components/CreateMacieSettingAllowList';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import { SEVERITY_DATA } from './config';
import {
  AwsSeverityLevelInputEnum,
  CreateAwsCustomDataIdentifierVariablesRequest,
} from 'graphql/mutations/createAwsAllowList copy';
import { ICreateCustomDataIdentifier, ICreateCustomDataIdentifierError } from './types';
import { useState } from 'react';

interface ICreateCustomDataIdentifierProps {
  cloudId: number;
  region: string;
  pageBackClick: () => void;
}

const CreateCustomDataIdentifier = ({ pageBackClick }: ICreateCustomDataIdentifierProps) => {
  const initData: ICreateCustomDataIdentifier = {
    name: '',
    description: '',
    regex: '',
    keywords: '',
    ignoreWords: '',
    severityLevels: [
      { occurrencesThreshold: 1, severity: AwsSeverityLevelInputEnum.LOW },
      { occurrencesThreshold: 2, severity: AwsSeverityLevelInputEnum.MEDIUM },
      { occurrencesThreshold: 100, severity: AwsSeverityLevelInputEnum.HIGH },
    ],
    tags: [],
  };
  const [creationData, updateCreationData] = useState<ICreateCustomDataIdentifier>(initData);
  const { name, description, regex, keywords, ignoreWords, maximumMatchDistance, severityLevels, tags } = creationData;
  const [severityType, setSeverityType] = useState<'default' | 'custom'>('default');
  const [sampleRegex, setSampleRegex] = useState('');
  const [matched, setMatched] = useState('');
  const [error, setError] = useState<ICreateCustomDataIdentifierError>({});

  return (
    <div id="mgd-list-layout">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center" id="title">
          <NetworkTitle
            pageTitle="Create macie job"
            id={321}
            name="name"
            hasPrefixIcon={false}
            hasFavorite={false}
            pageBackClick={pageBackClick}
          />
        </div>
      </div>
      <div className="one-page" style={{ height: 'calc(100% - 70px)', overflowY: 'auto' }}>
        <div className="allow-list-screen" style={{ borderTop: '1px solid var(--Curidy-grey-200)' }}>
          <div className="allow-list-container">
            <div className="allow-list-setting-type-container">
              <div className="allow-lists-container" style={{ display: 'flex' }}>
                <div style={{ width: '60%' }}>
                  <div className="allow-list-title">
                    <p>New custom data identifier</p>
                    <p className="allow-list-sub-title">
                      We recommend that you never enter sensitive data in the name or description of an identifier.
                      Other users might be able to see the data in these fields.
                    </p>
                  </div>
                  <div className="allow-list-content">
                    <div className="detail-content-row">
                      <div className="detail-content-column-left">
                        Name
                        <img style={{ marginLeft: 5 }} src={RequiredIcon} width={4} height={4} />
                      </div>
                      <div className="detail-content-column-right">
                        <div className="full-width">
                          <InputAtom
                            value={name}
                            noClear={true}
                            onChangeValue={(value: string) => updateCreationData({ ...creationData, name: value })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="detail-content-row">
                      <div className="detail-content-column-left">Description - optional</div>
                      <div className="detail-content-column-right">
                        <div className="full-width">
                          <InputAtom
                            value={description}
                            noClear={true}
                            onChangeValue={(value: string) =>
                              updateCreationData({ ...creationData, description: value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="detail-content-row">
                      <div className="detail-content-column-left">
                        Regular expression
                        <img style={{ marginLeft: 5 }} src={RequiredIcon} width={4} height={4} />
                      </div>
                      <div className="detail-content-column-right">
                        <p>Enter the regular expression (regex) that defines the pattern to match.</p>
                        <div className="full-width">
                          <InputAtom
                            value={regex}
                            placeholder='Use "," to separate expressions'
                            noClear={true}
                            onChangeValue={(value: string) => updateCreationData({ ...creationData, regex: value })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="detail-content-row">
                      <div className="detail-content-column-left">Keywords - optional</div>
                      <div className="detail-content-column-right">
                        <p>
                          Enter up to 50 keywords, separated by commas (,) that define specific text to match. Macie
                          includes a result for text that contains any of these keywords, if the result matches the
                          regex pattern and is within distance of one of these words.
                        </p>
                        <div className="full-width">
                          <InputAtom
                            value={keywords}
                            placeholder='Use "," to separate expressions'
                            noClear={true}
                            onChangeValue={(value: string) => updateCreationData({ ...creationData, keywords: value })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="detail-content-row">
                      <div className="detail-content-column-left">Ignore words - optional</div>
                      <div className="detail-content-column-right">
                        <p>
                          Enter up to 10 ignore words, separated by commas (,) that define specific text to exclude from
                          the results. Macie excludes results for text that contains any of these ignore words.
                        </p>
                        <div className="full-width">
                          <InputAtom
                            value={ignoreWords}
                            placeholder='Use "," to separate expressions'
                            noClear={true}
                            onChangeValue={(value: string) =>
                              updateCreationData({ ...creationData, ignoreWords: value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="detail-content-row">
                      <div className="detail-content-column-left">Maximum match distance - optional</div>
                      <div className="detail-content-column-right">
                        <p>
                          Enter the maximum allowable distance between text that matches the regex pattern and the
                          keywords. The default value is 50. Macie includes or excludes a result based on the proximity
                          of a keyword to text that matches the regex pattern.
                        </p>
                        <div className="full-width">
                          <InputAtom
                            value={maximumMatchDistance ? String(maximumMatchDistance) : ''}
                            placeholder="Default: 50"
                            noClear={true}
                            type="number"
                            onChangeValue={(value: string) =>
                              updateCreationData({ ...creationData, maximumMatchDistance: Number(value) })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="detail-content-row">
                      <div className="detail-content-column-left">Severity</div>
                      <div className="detail-content-column-right">
                        <p>
                          Finding severity is based on the number of occurrences of text that matches the preceding
                          criteria.
                        </p>
                        <div className="full-width">
                          <CustomRadio
                            style={{ padding: 0 }}
                            checked={severityType === 'default' ? true : false}
                            label="Use Medium severity for any number of matches (default)"
                            onClick={() => setSeverityType('default')}
                          />
                          <CustomRadio
                            style={{ padding: 0 }}
                            checked={severityType === 'custom' ? true : false}
                            label="Use custom settings to determine severity"
                            onClick={() => setSeverityType('custom')}
                          />
                          {severityType === 'custom' && (
                            <div className="allow-list-content">
                              <div style={{ display: 'flex', flexDirection: 'column', gap: 8, width: '100%' }}>
                                <div className="allow-list-content" style={{ gap: 4, padding: 0 }}>
                                  <div className="config-rule-row">
                                    <div className="config-rule-column">
                                      <div className="firewall-detail-sub-title">Occurrences threshold</div>
                                    </div>
                                    <p style={{ opacity: 0, pointerEvents: 'none' }}>or more</p>
                                    <div className="config-rule-column">
                                      <div className="firewall-detail-sub-title">Severity level</div>
                                    </div>
                                    <div style={{ marginLeft: 6, opacity: 0, pointerEvents: 'none' }}>
                                      <button className="button" style={{ margin: 0 }} onClick={() => {}}>
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                  {severityLevels?.length &&
                                    severityLevels.map(({ occurrencesThreshold, severity }, id) => {
                                      const severityItem = SEVERITY_DATA.find(item => item.value === severity);

                                      return (
                                        <div key={id} className="config-rule-row">
                                          <div className="config-rule-column">
                                            <InputAtom
                                              value={occurrencesThreshold ? String(occurrencesThreshold) : ''}
                                              type="number"
                                              onChangeValue={value => {
                                                const newSeverityLevels = [...severityLevels];
                                                newSeverityLevels[id].occurrencesThreshold = Number(value);
                                                updateCreationData({
                                                  ...creationData,
                                                  severityLevels: newSeverityLevels,
                                                });
                                              }}
                                            />
                                          </div>
                                          <p>or more</p>
                                          <div className="config-rule-column">
                                            <DropdownAtom
                                              id="Severity level"
                                              placeholder="Enter value"
                                              data={SEVERITY_DATA}
                                              value={severityItem || { value: '' }}
                                              handleClick={data => {
                                                const newSeverityLevels = [...severityLevels];
                                                newSeverityLevels[id].severity =
                                                  data.value as AwsSeverityLevelInputEnum;
                                                updateCreationData({
                                                  ...creationData,
                                                  severityLevels: newSeverityLevels,
                                                });
                                              }}
                                            />
                                          </div>
                                          {severityLevels?.length && severityLevels?.length > 1 && (
                                            <button
                                              className="button"
                                              style={{ margin: 0 }}
                                              onClick={() => {
                                                const newSeverityLevels = [...severityLevels];
                                                newSeverityLevels.splice(id, 1);
                                                updateCreationData({
                                                  ...creationData,
                                                  severityLevels: newSeverityLevels,
                                                });
                                              }}
                                            >
                                              Remove tag
                                            </button>
                                          )}
                                        </div>
                                      );
                                    })}
                                </div>
                                <div>
                                  <button
                                    className="button next-button"
                                    style={{ margin: 0 }}
                                    disabled={severityLevels.length >= 3}
                                    onClick={() => {
                                      updateCreationData({
                                        ...creationData,
                                        severityLevels: [
                                          ...creationData.severityLevels,
                                          { occurrencesThreshold: 1, severity: AwsSeverityLevelInputEnum.MEDIUM },
                                        ],
                                      });
                                    }}
                                  >
                                    Add
                                  </button>
                                </div>
                                <div className="allow-list-title" style={{ borderBottom: 'none', padding: 0 }}>
                                  {severityLevels.length < 3 ? (
                                    <div className="allow-list-sub-title">
                                      You can add settings for {3 - severityLevels.length} more severity levels.
                                    </div>
                                  ) : (
                                    <div className="allow-list-sub-title">
                                      You can specify settings for up to 3 severity levels.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                  <div className="allow-list-title">
                    <p>Evaluate</p>
                    <p className="allow-list-sub-title">
                      Test and refine the identifier before you submit it. You can’t edit an identifier after you submit
                      it.
                    </p>
                  </div>
                  <div className="allow-list-content" style={{ flex: 1 }}>
                    <div className="detail-content-row">
                      <div className="detail-content-column-left">Sample data</div>
                      <div className="detail-content-column-right">
                        <div className="full-width">
                          <TextareaAtom
                            value={sampleRegex}
                            placeholder={
                              'Enter sample data to test the regular expression.\nThe example data can contain up to 1,000 characters'
                            }
                            noClear={true}
                            onChangeValue={(value: string) => setSampleRegex(value)}
                            className="text-start"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="detail-content-row a-center" style={{ alignItems: 'center' }}>
                      <button
                        className="button next-button"
                        style={{ margin: 0, width: 'max-content' }}
                        onClick={() => {}}
                      >
                        Test
                      </button>
                    </div>
                    {matched && (
                      <div className="allow-list-title" style={{ border: 'none' }}>
                        Result:
                        <div className="allow-list-sub-title">{matched} matched</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="allow-lists-container" style={{ display: 'flex' }}>
              <div style={{ width: '60%' }}>
                <div className="allow-list-title">
                  <p>Tags - optional</p>
                  <p className="allow-list-sub-title">
                    A tag is a label that you assign to an AWS resource. Each tag consists of a key and an optional
                    value.
                  </p>
                </div>
                <div className="allow-list-content">
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 8, width: '100%' }}>
                    <div className="allow-list-content" style={{ gap: 4, padding: 0 }}>
                      {tags.length > 0 ? (
                        <>
                          <div className="config-rule-row">
                            <div className="config-rule-column">
                              <div className="firewall-detail-sub-title">Key</div>
                            </div>
                            <div className="config-rule-column">
                              <div className="firewall-detail-sub-title">Value - optional</div>
                            </div>
                            <div style={{ marginLeft: 6, opacity: 0, pointerEvents: 'none' }}>
                              <button className="button" style={{ margin: 0 }} onClick={() => {}}>
                                Remove tag
                              </button>
                            </div>
                          </div>
                          {tags.map((item, id) => {
                            return (
                              <div key={id} className="config-rule-row">
                                <div className="config-rule-column">
                                  <InputAtom
                                    value={item.key}
                                    placeholder="Enter key"
                                    onChangeValue={value => {
                                      // const newTags = [...creationData.tags];
                                      // newTags[id].key = value;
                                      // updateCreationData({ ...creationData, tags: newTags });
                                    }}
                                    // error={creationData.tags.some(
                                    //   (tag, i) => tag.key !== '' && i !== id && tag.key === item.key,
                                    // )}
                                  />
                                </div>
                                <div className="config-rule-column">
                                  <InputAtom
                                    value={item.value}
                                    placeholder="Enter value"
                                    onChangeValue={value => {
                                      // const newTags = [...creationData.tags];
                                      // newTags[id].value = value;
                                      // updateCreationData({ ...creationData, tags: newTags });
                                    }}
                                  />
                                </div>
                                <button
                                  className="button"
                                  style={{ margin: 0 }}
                                  onClick={() => {
                                    // const newTags = [...creationData.tags];
                                    // newTags.splice(id, 1);
                                    // updateCreationData({ ...creationData, tags: newTags });
                                  }}
                                >
                                  Remove tag
                                </button>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <p className="allow-list-sub-title" style={{ padding: '10px 0' }}>
                          No tags associated with the resource.
                        </p>
                      )}
                    </div>
                    <div>
                      <button
                        className="button next-button"
                        style={{ margin: 0 }}
                        onClick={() =>
                          updateCreationData({ ...creationData, tags: [...creationData.tags, { key: '' }] })
                        }
                      >
                        Add tags
                      </button>
                    </div>
                    <div className="allow-list-title" style={{ borderBottom: 'none', padding: 0 }}>
                      <div className="allow-list-sub-title">You can add up to {50} more tags.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="allow-list-bottom">
            {(error.name || error.regex) && <p className="error-message">{error.name || error.regex}</p>}
            <Button label="Cancel" onClick={pageBackClick} />
            <Button label="Create" type={ButtonTypeEnum.PRIMARY} onClick={() => {}} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCustomDataIdentifier;
