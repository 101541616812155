import './index.scss';
import { useEffect, useState } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconEdit from 'assets/svgs/v3/ico_kms_encryption.svg';
import Icon from 'components/v2/atoms/Icon';
import Divider from 'pages/v2/Organ/Management/components/Divider';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { randomString } from 'utils/Common';
import Space from 'pages/v2/Organ/Management/components/Space';
import InputAtom from 'components/v2/atoms/InputAtom';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import Switch from 'pages/v2/Organ/Management/components/Switch';
import lazyGetAwsListFunctions, { IAwsListFunctionsVariables } from 'graphql/queries/getAwsListFunctions';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';

interface IEditRotationConfigurationModalProps extends IBaseModalProps {
  cloudId: number;
  region: string;
  data: string;
  onSubmit: (value: any) => void;
}

const EditRotationConfigurationModal = ({
  cloudId,
  region,
  data,
  onSubmit,
  ...baseModalProps
}: IEditRotationConfigurationModalProps) => {
  const [isAutoRotation, setIsAutoRotation] = useState(false);
  const [rotationSchedule, setRotationSchedule] = useState('');
  const [duration, setDuration] = useState('');
  const [lambdaRotationFuncOptions, setLambdaRotationFuncOptions] = useState<any>([]);
  const [lambdaRotationFunc, setLambdaRotationFunc] = useState<any>({ name: '', value: '' });
  const [isCreateNew, setIsCreateNew] = useState(false);

  const [getAwsListFunctions] = lazyGetAwsListFunctions();

  const fetchAwsAllAwsKeys = async () => {
    const awsAwsKeyRequestVariables: IAwsListFunctionsVariables = {
      cloudId,
      region,
      reqData: {
        maxItems: 1000,
      },
    };
    const combinedVariable = variableCombineNextToken(awsAwsKeyRequestVariables);

    const { data: listFunctions } = await getAwsListFunctions({ variables: combinedVariable });

    if (!listFunctions?.getAwsListFunctions?.data?.[0]?.functions?.length) return;

    const arr = listFunctions.getAwsListFunctions.data[0].functions.map(
      ({ functionName, functionArn }: { functionName: string; functionArn: string }) => ({
        name: functionName,
        value: functionArn,
      }),
    );

    setLambdaRotationFuncOptions(arr);
  };

  useEffect(() => {
    fetchAwsAllAwsKeys();
  }, []);

  const handleClose = () => {
    if (baseModalProps?.onClose) baseModalProps.onClose();
  };

  const handleSave = () => {
    const payload = {
      isAutoRotation,
      rotateImmediately: isCreateNew,
      rotationLambdaArn: lambdaRotationFunc.value,
      rotationRules: {
        duration: `${duration}h`,
        scheduleExpression: rotationSchedule
      }
    };
    onSubmit(payload);
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEdit} />

          <span>Edit rotation configuration</span>
        </>
      )}
      onClose={handleClose}
      {...baseModalProps}
    >
      <div className="modal-body edit-rotation-configuration-modal">
        <Divider />

        <div className="input-container">
          <p className="title-container">Configure automatic rotation</p>

          <p>Configure AWS Secrets Manager to rotate this secret automatically.</p>

          <Space size={12} />

          <Switch label="Automatic rotation" checked={isAutoRotation} onChange={setIsAutoRotation} />
        </div>

        <Space size={24} />

        <div className="input-container">
          <p className="title-container">Rotation schedule</p>

          <p>Schedule expression</p>

          <InputAtom
            disabled={!isAutoRotation}
            required
            value={rotationSchedule}
            onChangeValue={setRotationSchedule}
            placeholder="cron(0 2 1 * ? *) or rate (10days)"
          />

          <p className="input-note">
            Enter a cron expression such as cron ( a b c d e f) or a rate expression such as rate (10days).
          </p>
        </div>

        <Space size={24} />

        <div className="input-container">
          <p>Window duration-optional</p>

          <InputAtom
            disabled={!isAutoRotation}
            required
            value={duration}
            onChangeValue={value => {
              if (value && !/^\d+$/.test(value)) return;

              setDuration(value);
            }}
            placeholder="4h"
          />

          <p className="input-note">Enter the time in hours.</p>
        </div>

        <Space size={24} />

        <label className="check-box-group">
          <CheckboxAtom
            disabled={!isAutoRotation}
            checked={isCreateNew}
            onchange={() => setIsCreateNew(!isCreateNew)}
          />

          <p>Rotate immediately when the secret is stored. The next rotation will begin on your schedule.</p>
        </label>

        <Space size={24} />

        <div className="input-container">
          <p className="title-container">Rotation function</p>

          <p>Lambda rotation function</p>

          <p>Choose a Lambda function that can rotate this secret.</p>

          <DropdownAtom
            id={randomString()}
            disabled={!isAutoRotation}
            placeholder="Lambda rotation function."
            className=""
            data={lambdaRotationFuncOptions}
            value={lambdaRotationFunc}
            handleClick={value => setLambdaRotationFunc(value)}
          />
        </div>

        <Divider />

        <div className="button-group">
          <button onClick={handleClose}>Cancel</button>

          <button className="save-btn" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditRotationConfigurationModal;
