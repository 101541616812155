import '../index.scss';
import { useEffect, useMemo, useState } from 'react';
import { AddRuleGroupsStepPropsType, TablePaginationConfigType } from '../types';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import {
  DROP_ACTION_DROPDOWN_LIST,
  FIREWALL_POLICY_CREATION,
  RULE_ORDER,
  STATE_LESS_DEFAULT_ACTION,
  STATE_LESS_FRAGMENT_DEFAULT_ACTIONS,
  STATE_LESS_FULL_PACKETS_DEFAULT_ACTION,
} from '../configs';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import Table from 'components/v2/dataDisplay/Table';
import { OrderDirection, RowType } from '@Types/v2/Table';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import Space from 'pages/v2/Organ/Management/components/Space';
import AddRuleGroups from './AddRuleGroups';
import { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import ButtonGroup from 'pages/v2/Organ/Management/components/ButtonGroup';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';

const AddRuleGroupsStep = (props: AddRuleGroupsStepPropsType) => {
  const {
    ruleAction,
    ruleActionFullPackets,
    ruleActionFragmentedPackets,
    onValueChanged,
    isSameActionForAll,
    ruleGroups,
    statelessRuleGroupList,
    setStatelessRuleGroupRows,
    statefulRuleGroupList,
    setStatefulRuleGroupRows,
    statefulRuleEvaluationOrder,
    statefulRuleDropAction,
    alertOptionAll,
    alertOptionEstablished,
    statelessRuleGroupCapacityUnitConsumed,
    statefulRuleGroupCapacityUnitConsumed,
  } = props;

  const [isOpenAddStateLessRuleGroups, setIsOpenAddStateLessRuleGroups] = useState(false);
  const [isOpenAddStateFulRuleGroups, setIsOpenAddStateFulRuleGroups] = useState(false);
  const [stateLessCheckedList, setStateLessCheckedList] = useState<string[]>([]);
  const [stateFulCheckedList, setStateFulCheckedList] = useState<string[]>([]);

  const [statelessTblTotal, setStatelessTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [statelessTablePagination, setStatelessTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [statefulTblTotal, setStatefulTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [statefulTablePagination, setStatefulTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  useEffect(() => {
    setStatelessTblTotal({
      totalPage: Math.ceil(statelessRuleGroupList.length / statelessTablePagination.itemPerPage),
      totalElement: statelessRuleGroupList.length,
    });
  }, [statelessRuleGroupList?.length, statelessTablePagination]);

  useEffect(() => {
    setStatefulTblTotal({
      totalPage: Math.ceil(statefulRuleGroupList.length / statefulTablePagination.itemPerPage),
      totalElement: statefulRuleGroupList.length,
    });
  }, [statefulRuleGroupList?.length, statefulTablePagination]);

  const statelessRowsCurrentPage = useMemo((): RowType[] => {
    const startIndex = (statelessTablePagination.currentPage - 1) * statelessTablePagination.itemPerPage;
    const endIndex = startIndex + statelessTablePagination.itemPerPage;

    setStatelessTblTotal({
      totalPage: Math.ceil(statelessRuleGroupList.length / statelessTablePagination.itemPerPage),
      totalElement: statelessRuleGroupList.length,
    });

    return orderAlphabetical(
      statelessRuleGroupList,
      statelessTablePagination.target,
      statelessTablePagination.direction,
    ).slice(startIndex, endIndex);
  }, [statelessRuleGroupList, statelessTablePagination]);

  const statefulRowsCurrentPage = useMemo((): RowType[] => {
    const startIndex = (statefulTablePagination.currentPage - 1) * statefulTablePagination.itemPerPage;
    const endIndex = startIndex + statefulTablePagination.itemPerPage;

    setStatefulTblTotal({
      totalPage: Math.ceil(statefulRuleGroupList.length / statefulTablePagination.itemPerPage),
      totalElement: statefulRuleGroupList.length,
    });

    return orderAlphabetical(
      statefulRuleGroupList,
      statefulTablePagination.target,
      statefulTablePagination.direction,
    ).slice(startIndex, endIndex);
  }, [statefulRuleGroupList, statefulTablePagination]);

  const capacityStateless = useMemo(() => {
    return statelessRuleGroupList.reduce((result, cur) => {
      return result + cur.capacity;
    }, 0);
  }, [statelessRuleGroupList]);

  const capacityStatefull = useMemo(() => {
    return statefulRuleGroupList.reduce((result, cur) => {
      return result + cur.capacity;
    }, 0);
  }, [statefulRuleGroupList]);

  useEffect(() => {
    onValueChanged('statelessRuleGroupCapacityUnitConsumed', `${capacityStateless}/30000`);
  }, [capacityStateless]);

  useEffect(() => {
    onValueChanged('statefulRuleGroupCapacityUnitConsumed', `${capacityStatefull}/30000`);
  }, [capacityStatefull]);

  const updateStatelessTablePagination = (key: string, value: number | string | OrderDirection) => {
    setStatelessTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const updateStatefulTablePagination = (key: string, value: number | string | OrderDirection) => {
    setStatefulTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const STATELESS_RULE_GROUP_COLUMN_TITLE_LIST = [
    { label: 'Priority', field: 'priority', sort: true },
    { label: 'Name', field: 'name', sort: true },
    { label: 'Capacity', field: 'capacity', sort: true },
  ];

  const STATEFUL_RULE_GROUP_COLUMN_TITLE_LIST = [
    {
      label: FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_PRIORITY_COLUMN_TITLE,
      field: 'priority',
      sort: true,
    },
    { label: FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_NAME_COLUMN_TITLE, field: 'name', sort: true },
    {
      label: FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_CAPACITY_COLUMN_TITLE,
      field: 'capacity',
      sort: true,
    },
  ];

  const statelessRuleActionNode = useMemo(() => {
    if (isSameActionForAll) {
      return (
        <div className="firewall-detail-title">
          {FIREWALL_POLICY_CREATION.RULE_ACTION_TITLE}
          <div className="firewall-detail-sub-title">{FIREWALL_POLICY_CREATION.RULE_ACTION_SUBTITLE}</div>

          {STATE_LESS_DEFAULT_ACTION.map(e => (
            <RadioAtom
              key={e.value}
              label={e.label}
              description={e.description}
              checked={e.value}
              name=""
              onChange={() => onValueChanged('ruleActionFullPackets', e.value)}
              value={ruleActionFullPackets}
            />
          ))}
        </div>
      );
    }

    return (
      <>
        <div className="firewall-detail-title">
          {FIREWALL_POLICY_CREATION.RULE_ACTION_FULL_PACKETS_TITLE}
          <div className="firewall-detail-sub-title">{FIREWALL_POLICY_CREATION.RULE_ACTION_FULL_PACKETS_SUBTITLE}</div>

          {STATE_LESS_FULL_PACKETS_DEFAULT_ACTION.map(e => (
            <RadioAtom
              key={e.value}
              label={e.label}
              description={e.description}
              checked={e.value}
              name=""
              onChange={() => onValueChanged('ruleActionFullPackets', e.value)}
              value={ruleActionFullPackets}
            />
          ))}
        </div>

        <div className="firewall-detail-title">
          {FIREWALL_POLICY_CREATION.RULE_ACTION_FRAGMENTED_PACKETS_TITLE}
          <div className="firewall-detail-sub-title">
            {FIREWALL_POLICY_CREATION.RULE_ACTION_FRAGMENTED_PACKETS_SUBTITLE}
          </div>

          {STATE_LESS_FRAGMENT_DEFAULT_ACTIONS.map(e => (
            <RadioAtom
              key={e.value}
              label={e.label}
              description={e.description}
              checked={e.value}
              name=""
              onChange={() => onValueChanged('ruleActionFragmentedPackets', e.value)}
              value={ruleActionFragmentedPackets}
            />
          ))}
        </div>
      </>
    );
  }, [ruleActionFullPackets, ruleActionFragmentedPackets, onValueChanged, isSameActionForAll]);

  const handleDeleteRuleGroups = (type: string) => {
    if (type === 'STATELESS') {
      if (!stateLessCheckedList?.length) return;

      const arr = statelessRuleGroupList
        .filter(e => !stateLessCheckedList.includes(`${e.id}`))
        .map((e, index) => ({
          ...e,
          priority: index + 1,
        }));

      setStatelessRuleGroupRows(arr);
      return;
    }

    if (!stateFulCheckedList?.length) return;

    const arr = statefulRuleGroupList
      .filter(e => !stateFulCheckedList.includes(`${e.id}`))
      .map((e, index) => ({
        ...e,
        priority: index + 1,
      }));

    setStatefulRuleGroupRows(arr);
  };

  const handleMoveUp = (type: string) => {
    if (type === 'STATELESS') {
      if (stateLessCheckedList?.length !== 1) return;

      const index = statelessRuleGroupList.findIndex(e => e.id === stateLessCheckedList[0]);

      if (index < 1) return;

      const arr = [...statelessRuleGroupList];
      arr[index].priority = arr[index].priority - 1;
      arr[index - 1].priority = arr[index - 1].priority + 1;

      arr.sort((a, b) => a.priority - b.priority);

      setStatelessRuleGroupRows(arr);
    }

    if (stateFulCheckedList?.length !== 1) return;

    const index = statefulRuleGroupList.findIndex(e => e.id === stateFulCheckedList[0]);

    if (index < 1) return;

    const arr = [...statefulRuleGroupList];
    arr[index].priority = arr[index].priority - 1;
    arr[index - 1].priority = arr[index - 1].priority + 1;

    arr.sort((a, b) => a.priority - b.priority);

    setStatefulRuleGroupRows(arr);
  };

  const handleMoveDown = (type: string) => {
    if (type === 'STATELESS') {
      if (stateLessCheckedList?.length !== 1) return;

      const index = statelessRuleGroupList.findIndex(e => e.id === stateLessCheckedList[0]);

      if (index < 0 || index > statelessRuleGroupList?.length - 2) return;

      const arr = [...statelessRuleGroupList];
      arr[index].priority = arr[index].priority + 1;
      arr[index + 1].priority = arr[index + 1].priority - 1;

      arr.sort((a, b) => a.priority - b.priority);

      setStatelessRuleGroupRows(arr);
    }

    if (stateFulCheckedList?.length !== 1) return;

    const index = statefulRuleGroupList.findIndex(e => e.id === stateFulCheckedList[0]);

    if (index < 0 || index > statefulRuleGroupList?.length - 2) return;

    const arr = [...statefulRuleGroupList];
    arr[index].priority = arr[index].priority + 1;
    arr[index + 1].priority = arr[index + 1].priority - 1;

    arr.sort((a, b) => a.priority - b.priority);

    setStatefulRuleGroupRows(arr);
  };

  const statelessRuleGroupNode = useMemo(() => {
    return (
      <div className="rule-group-container">
        <div className="rule-group-details-container">
          <div
            className="rule-group-detail-title"
            style={{ display: 'flex', gap: 10, justifyContent: 'space-between', alignItems: 'center' }}
          >
            <p>{FIREWALL_POLICY_CREATION.STATELESS_RULE_GROUP_TITLE}</p>

            <ButtonGroup
              buttons={[
                {
                  label: FIREWALL_POLICY_CREATION.STATELESS_RULE_GROUP_MOVE_UP_BUTTON,
                  disabled: stateLessCheckedList?.length !== 1,
                  onClick: () => handleMoveUp('STATELESS'),
                },
                {
                  label: FIREWALL_POLICY_CREATION.STATELESS_RULE_GROUP_MOVE_DOWN_BUTTON,
                  disabled: stateLessCheckedList?.length !== 1,
                  onClick: () => handleMoveDown('STATELESS'),
                },
                {
                  label: FIREWALL_POLICY_CREATION.STATELESS_RULE_GROUP_DELETE_BUTTON,
                  type: ButtonTypeEnum.PRIMARY,
                  disabled: !stateLessCheckedList?.length,
                  onClick: () => handleDeleteRuleGroups('STATELESS'),
                },
                {
                  label: FIREWALL_POLICY_CREATION.STATELESS_RULE_GROUP_ADD_STATELESS_BUTTON,
                  onClick: () => setIsOpenAddStateLessRuleGroups(true),
                },
              ]}
            />
          </div>

          {statelessRowsCurrentPage?.length ? (
            <div
              className="detail-table"
              style={{
                margin: 0,
                border: 'none',
              }}
            >
              <Table
                columns={STATELESS_RULE_GROUP_COLUMN_TITLE_LIST}
                rows={statelessRowsCurrentPage}
                reportCheckedList={setStateLessCheckedList}
                sortOption={{
                  target: statelessTablePagination.target,
                  direction: statelessTablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateStatelessTablePagination('target', target);
                    updateStatelessTablePagination('direction', dir);
                  },
                }}
              />

              <div className="pagination-wrapper flex a-center">
                <p className="flex a-center">
                  Total <span>{statelessTblTotal.totalElement}</span>
                </p>

                <TablePagination
                  currentPage={statelessTablePagination.currentPage}
                  updateCurrentPage={pageNumber => {
                    updateStatelessTablePagination('currentPage', pageNumber);
                  }}
                  totalPage={statelessTblTotal.totalPage}
                />
              </div>
            </div>
          ) : (
            <div className="rule-groups-empty">No stateless rule groups</div>
          )}
        </div>
      </div>
    );
  }, [statelessRowsCurrentPage, statelessTblTotal, statelessTablePagination, stateLessCheckedList, ruleAction]);

  const handleChangeStatefulRuleEvaluationOrder = (value: string) => {
    onValueChanged('statefulRuleEvaluationOrder', value);

    if (value === RULE_ORDER[1].value) {
      setStatefulRuleGroupRows([]);
    }
  };

  const statefulRuleActionNode = useMemo(() => {
    return (
      <div className="rule-group-container">
        <div className="title-group">
          <p className="title">{FIREWALL_POLICY_CREATION.STATEFUL_DEFAULTS_ACTIONS_TITLE}</p>
          <p className="sub-title">{FIREWALL_POLICY_CREATION.STATEFUL_DEFAULTS_ACTIONS_SUBTITLE}</p>
        </div>

        <div className="rule-group-details-container">
          <div className="firewall-detail-title">
            <p>{FIREWALL_POLICY_CREATION.RULE_EVALUATION_ORDER_TITLE}</p>

            <div className="firewall-detail-sub-title">{FIREWALL_POLICY_CREATION.RULE_EVALUATION_ORDER_SUBTITLE}</div>

            {RULE_ORDER.map(e => (
              <RadioAtom
                key={e.value}
                label={e.label}
                description={e.description}
                checked={e.value}
                name=""
                onChange={() => handleChangeStatefulRuleEvaluationOrder(e.value)}
                value={statefulRuleEvaluationOrder}
              />
            ))}
          </div>

          {statefulRuleEvaluationOrder === RULE_ORDER[0].value ? (
            <>
              <div className="firewall-detail-title">
                <p>{FIREWALL_POLICY_CREATION.RULE_EVALUATION_DROP_ACTION_TITLE}</p>

                <div className="firewall-detail-sub-title">
                  {FIREWALL_POLICY_CREATION.RULE_EVALUATION_DROP_ACTION_SUBTITLE}
                </div>

                <DropdownAtom
                  id="msg-use"
                  data={DROP_ACTION_DROPDOWN_LIST}
                  value={
                    DROP_ACTION_DROPDOWN_LIST.find(item => item.value === statefulRuleDropAction) ||
                    DROP_ACTION_DROPDOWN_LIST[0]
                  }
                  handleClick={val => onValueChanged('statefulRuleDropAction', val.value)}
                />
              </div>

              <div className="firewall-detail-title">
                {FIREWALL_POLICY_CREATION.RULE_EVALUATION_ALERT_ACTION_TITLE}
                <div className="firewall-detail-sub-title">
                  {FIREWALL_POLICY_CREATION.RULE_EVALUATION_ALERT_ACTION_SUBTITLE}
                </div>

                <CheckboxAtom
                  label={FIREWALL_POLICY_CREATION.RULE_EVALUATION_ALERT_ACTION_ALL_LABEL}
                  description={FIREWALL_POLICY_CREATION.RULE_EVALUATION_ALERT_ACTION_ALL_DESCRIPTION}
                  checked={alertOptionAll}
                  onchange={() => onValueChanged('alertOptionAll', !alertOptionAll)}
                />

                <Space size={8} />

                <CheckboxAtom
                  label={FIREWALL_POLICY_CREATION.RULE_EVALUATION_ALERT_ACTION_ESTABLISHED_LABEL}
                  description={FIREWALL_POLICY_CREATION.RULE_EVALUATION_ALERT_ACTION_ESTABLISHED_DESCRIPTION}
                  checked={alertOptionEstablished}
                  onchange={() => onValueChanged('alertOptionEstablished', !alertOptionEstablished)}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }, [statefulRuleDropAction, alertOptionAll, alertOptionEstablished, statefulRuleEvaluationOrder]);

  const statefulRuleGroupNode = useMemo(() => {
    return (
      <div className="rule-group-container">
        <div className="rule-group-details-container">
          <div
            className="rule-group-detail-title"
            style={{ display: 'flex', gap: 20, justifyContent: 'space-between', alignItems: 'center' }}
          >
            <p>{FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_TITLE}</p>

            <ButtonGroup
              buttons={[
                {
                  label: FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_MOVE_UP_BUTTON,
                  disabled: stateFulCheckedList?.length !== 1,
                  onClick: () => handleMoveUp('STATEFUL'),
                },
                {
                  label: FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_MOVE_DOWN_BUTTON,
                  disabled: stateFulCheckedList?.length !== 1,
                  onClick: () => handleMoveDown('STATEFUL'),
                },
                {
                  label: FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_DELETE_BUTTON,
                  type: ButtonTypeEnum.PRIMARY,
                  disabled: !stateFulCheckedList?.length,
                  onClick: () => handleDeleteRuleGroups('STATEFUL'),
                },
                {
                  label: FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_ADD_STATEFUL_BUTTON,
                  onClick: () => setIsOpenAddStateFulRuleGroups(true),
                },
              ]}
            />
          </div>

          {statefulRowsCurrentPage?.length ? (
            <div
              className="detail-table"
              style={{
                margin: 0,
                border: 'none',
              }}
            >
              <Table
                columns={STATEFUL_RULE_GROUP_COLUMN_TITLE_LIST}
                rows={statefulRowsCurrentPage}
                reportCheckedList={setStateFulCheckedList}
                sortOption={{
                  target: statefulTablePagination.target,
                  direction: statefulTablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateStatefulTablePagination('target', target);
                    updateStatefulTablePagination('direction', dir);
                  },
                }}
              />

              <div className="pagination-wrapper flex a-center">
                <p className="flex a-center">
                  Total <span>{statefulTblTotal.totalElement}</span>
                </p>

                <TablePagination
                  currentPage={statefulTablePagination.currentPage}
                  updateCurrentPage={pageNumber => {
                    updateStatefulTablePagination('currentPage', pageNumber);
                  }}
                  totalPage={statefulTblTotal.totalPage}
                />
              </div>
            </div>
          ) : (
            <div className="rule-groups-empty">No stateful rule groups</div>
          )}
        </div>
      </div>
    );
  }, [statefulRowsCurrentPage, statefulTblTotal, statefulTablePagination, stateFulCheckedList]);

  const capacityUnitConsumedNode = useMemo(() => {
    return (
      <div className="rule-group-container">
        <div className="rule-group-details-container" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="firewall-detail-title" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {FIREWALL_POLICY_CREATION.CAPACITY_UNITS_BY_STATELESS_GROUP_TITLE}
            <div className="firewall-detail-sub-title">
              {FIREWALL_POLICY_CREATION.CAPACITY_UNITS_BY_STATELESS_GROUP_DESCRIPTION}
            </div>
          </div>
          <div className="firewall-detail-title" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {FIREWALL_POLICY_CREATION.CAPACITY_UNITS_BY_STATEFUL_GROUP_TITLE}
            <div className="firewall-detail-sub-title">
              {FIREWALL_POLICY_CREATION.CAPACITY_UNITS_BY_STATEFUL_GROUP_DESCRIPTION}
            </div>
          </div>
        </div>
        <div className="rule-group-details-container" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="firewall-detail-title" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {statelessRuleGroupCapacityUnitConsumed}
          </div>
          <div className="firewall-detail-title" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {statefulRuleGroupCapacityUnitConsumed}
          </div>
        </div>
      </div>
    );
  }, [statelessRuleGroupCapacityUnitConsumed, statefulRuleGroupCapacityUnitConsumed]);

  const handleGetRuleGroupsType = (type: string) => {
    if (!ruleGroups?.length) return [];

    const arrId = type === 'STATELESS' ? statelessRuleGroupList.map(e => e.id) : statefulRuleGroupList.map(e => e.id);

    return ruleGroups.filter(e => e.type === type).filter(e => (arrId?.length ? !arrId.includes(e.id) : e));
  };

  const handleAddRuleGroups = (values: RowType[], onChange: (values: any) => void) => {
    const arr = [...values].map((e, index) => ({
      ...e,
      priority: index + 1,
    }));
    onChange(arr);
  };

  return (
    <>
      <div>
        <div className="rule-group-container">
          <div className="title-group">
            <p className="title">{FIREWALL_POLICY_CREATION.ADD_RULE_GROUP_TITLE}</p>

            <p className="sub-title">{FIREWALL_POLICY_CREATION.ADD_RULE_GROUP_SUBTITLE}</p>
          </div>

          <div className="rule-group-details-container">
            <div className="rule-group-detail-title">
              <p>{FIREWALL_POLICY_CREATION.STATELESS_DEFAULTS_ACTIONS_TITLE}</p>

              <span className="rule-group-detail-sub-title">
                {FIREWALL_POLICY_CREATION.STATELESS_DEFAULTS_ACTIONS_SUBTITLE}
              </span>
            </div>

            <div className="firewall-detail-title">
              {FIREWALL_POLICY_CREATION.FRAGMENTED_PACKETS_TITLE}
              <div className="firewall-detail-sub-title">{FIREWALL_POLICY_CREATION.FRAGMENTED_PACKETS_SUB_TITLE}</div>
              <RadioAtom
                label={FIREWALL_POLICY_CREATION.FRAGMENTED_PACKETS_USE_SAME_ACTION_RADIO_LABEL}
                checked="Same"
                name=""
                onChange={() => onValueChanged('ruleAction', 'Same')}
                value={ruleAction}
              />

              <RadioAtom
                label={FIREWALL_POLICY_CREATION.FRAGMENTED_PACKETS_USE_DIFFERENT_ACTION_RADIO_LABEL}
                checked="Different"
                name=""
                onChange={() => onValueChanged('ruleAction', 'Different')}
                value={ruleAction}
              />
            </div>
          </div>
        </div>
        {statelessRuleActionNode}
        {statelessRuleGroupNode}
        {statefulRuleActionNode}
        {statefulRuleGroupNode}
        {capacityUnitConsumedNode}
      </div>

      {isOpenAddStateLessRuleGroups ? (
        <AddRuleGroups
          open={isOpenAddStateLessRuleGroups}
          onClose={() => {
            setIsOpenAddStateLessRuleGroups(false);
          }}
          rows={handleGetRuleGroupsType('STATELESS')}
          onAdd={values => handleAddRuleGroups([...statelessRuleGroupList, ...values], setStatelessRuleGroupRows)}
        />
      ) : null}

      {isOpenAddStateFulRuleGroups ? (
        <AddRuleGroups
          open={isOpenAddStateFulRuleGroups}
          onClose={() => {
            setIsOpenAddStateFulRuleGroups(false);
          }}
          rows={statefulRuleEvaluationOrder === RULE_ORDER[0].value ? handleGetRuleGroupsType('STATEFUL') : []}
          onAdd={values => handleAddRuleGroups([...statefulRuleGroupList, ...values], setStatefulRuleGroupRows)}
        />
      ) : null}
    </>
  );
};

export default AddRuleGroupsStep;
