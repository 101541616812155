import { useCallback, useMemo, useState } from 'react';
import { CreationDataPropsType } from './steps/types';
import { FirewallCreationProps } from './types';
import { CREATE_FIREWALL_STEPS, ENCRYPTION_CONFIGURATION_DATA } from './configs';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import {
  AwsCreateFirewallRequestType,
  AwsSubnetMappingLogType,
  AwsTagType,
} from 'graphql/types/AwsCreateFirewall';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import PageTitle from 'pages/v2/Organ/Management/components/PageTitle';
import DescribeFirewall from './steps/DescribeFirewall';
import ConfigVpcAndSubnet from './steps/ConfigVpcAndSubnet';
import ConfigAdvanceSetting from './steps/ConfigAdvanceSetting';
import AssociateFirewallPolicy from './steps/AssociateFirewallPolicy';
import AddTag from './steps/AddTag';
import Review from './Review';
import createAwsFirewallMutation, { ICreateAwsFirewallVariables } from 'graphql/mutations/createAwsFirewall';
import './index.scss';

const FirewallCreation = (props: FirewallCreationProps) => {
  const { cloudId, region, onBackButtonClicked } = props;

  const initCreation: CreationDataPropsType = {
    firewallName: '',
    firewallPolicyArn: '',
    vpcId: '',
    vpcIdError: false,
    listFirewallSubnet: [
      {
        availabilityZoneData: [],
        availabilityZone: { value: '', name: '' },
        availabilityZoneError: false,
        subnet: { value: '', name: '' },
        subnetError: false,
        ipAddress: { value: '', name: '' },
        ipAddressError: false,
      },
    ],
    deleteProtection: true,
    subnetChangeProtection: true,
    customizeEncryptionSetting: false,
    encryptionConfiguration: { type: ENCRYPTION_CONFIGURATION_DATA.AWS_OWNED_KMS_KEY },
    firewallPolicyChangeProtection: true,
  };

  // API
  const [createAwsFirewall, { loading: createFirewallLoading }] = createAwsFirewallMutation();

  // State
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [creationData, setCreationData] = useState<CreationDataPropsType>(initCreation);

  // Step 1 - Describe firewall validation
  const onDescribeFirewallValidate = useCallback((): boolean => {
    const { firewallName, description } = creationData;
    let validated = true;
    let firewallNameError, descriptionError;
    if (firewallName === '') {
      firewallNameError = 'Name is a required field.';
      validated = false;
    }

    if (description && description.length > 256) {
      descriptionError = 'The description must be smaller than 256 characters.';
    }
    const creationDateValidated: CreationDataPropsType = {
      ...creationData,
      firewallNameError: firewallNameError,
      descriptionError: descriptionError,
    };
    setCreationData(creationDateValidated);
    return validated;
  }, [creationData]);

  // Step 2 - Configure VPC and subnets validation
  const onConfigVpcANdSubnetValidate = useCallback((): boolean => {
    const { vpcId, listFirewallSubnet } = creationData;
    let validated = true;
    let vpcIdError = false;
    let listFirewallSubnetError = '';
    if (vpcId === '') {
      vpcIdError = true;
      validated = false;
    }

    if (listFirewallSubnet.length === 0) {
      listFirewallSubnetError = 'An availability zone is required.';
      validated = false;
    }

    const firewallSubnetValidated = listFirewallSubnet.map(firewallSubnet => {
      const azValidated = firewallSubnet.availabilityZone.value === '';
      const subnetValidated = firewallSubnet.subnet.value === '';
      const ipAddresssValidated = firewallSubnet.ipAddress.value === '';
      if (azValidated || subnetValidated || ipAddresssValidated) {
        validated = false;
      }
      return {
        ...firewallSubnet,
        availabilityZoneError: azValidated,
        subnetError: subnetValidated,
        ipAddressError: ipAddresssValidated,
      };
    });

    const creationDateValidated: CreationDataPropsType = {
      ...creationData,
      vpcIdError: vpcIdError,
      listFirewallSubnet: firewallSubnetValidated,
      listFirewallSubnetError: listFirewallSubnetError,
    };
    setCreationData(creationDateValidated);
    return validated;
  }, [creationData]);

  // Step 3 - Configure advanced settings validation
  const onConfigAdvanceSettingValidate = useCallback((): boolean => {
    const { customizeEncryptionSetting, encryptionConfiguration } = creationData;
    let validated = true;
    let encryptionConfigurationError;
    if (customizeEncryptionSetting) {
      if (!encryptionConfiguration?.keyId || encryptionConfiguration?.keyId === '') {
        encryptionConfigurationError = 'An AWS KMS key is required.';
        validated = false;
      }
    }
    const creationDateValidated: CreationDataPropsType = {
      ...creationData,
      encryptionConfigurationError: encryptionConfigurationError,
    };
    setCreationData(creationDateValidated);
    return validated;
  }, [creationData]);

  // Step 4 - Associate firewall policy validation
  const onAssiciatePolicyValidate = useCallback((): boolean => {
    const { firewallPolicyArn } = creationData;
    let validated = true;
    let firewallPolicyArnError;
    if (firewallPolicyArn === '') {
      firewallPolicyArnError = 'You must choose a firewall policy.';
      validated = false;
    }
    const creationDateValidated: CreationDataPropsType = {
      ...creationData,
      firewallPolicyArnError: firewallPolicyArnError,
    };
    setCreationData(creationDateValidated);
    return validated;
  }, [creationData]);

  // Step 4 - Add tags validation
  const onAddTagValidate = useCallback((): boolean => {
    const { tags } = creationData;
    let validated = true;
    if (tags && tags.length > 0) {
      tags.map(tag => {
        if (tag.keyStr === '' || tag.valueStr === '') {
          validated = false;
        }
      });
    }
    return validated;
  }, [creationData]);

  const validateInputs = useCallback((): boolean => {
    switch (currentStepIndex) {
      default:
      case 0:
        return onDescribeFirewallValidate();
      case 1:
        return onConfigVpcANdSubnetValidate();
      case 2:
        return onConfigAdvanceSettingValidate();
      case 3:
        return onAssiciatePolicyValidate();
      case 4:
        return onAddTagValidate();
    }
  }, [
    currentStepIndex,
    onDescribeFirewallValidate,
    onConfigVpcANdSubnetValidate,
    onConfigAdvanceSettingValidate,
    onAssiciatePolicyValidate,
    onAddTagValidate,
  ]);

  const onPreviousButtonClicked = useCallback(() => {
    setCurrentStepIndex(currentStepIndex - 1);
  }, [currentStepIndex]);

  const onNextButtonClicked = useCallback(() => {
    if (currentStepIndex < CREATE_FIREWALL_STEPS.length - 1 && validateInputs()) {
      setCurrentStepIndex(currentStepIndex + 1);
      return;
    }
  }, [currentStepIndex, creationData, validateInputs]);

  const onCancelButtonClicked = useCallback(() => {
    onBackButtonClicked();
  }, [currentStepIndex]);

  const firewallCreationVariable = useMemo((): ICreateAwsFirewallVariables => {
    const {
      firewallName,
      firewallPolicyArn,
      vpcId,
      listFirewallSubnet,
      deleteProtection,
      subnetChangeProtection,
      // optional props
      description,
      encryptionConfiguration,
      tags,
    } = creationData;
    const subnetMappings: AwsSubnetMappingLogType[] = listFirewallSubnet.map(item => {
      return {
        subnetId: item.subnet.value.toString(),
        ipAddressType: item.ipAddress.value.toString(),
      };
    });

    let finalCreationData: AwsCreateFirewallRequestType = {
      firewallName,
      firewallPolicyArn,
      subnetMappings: subnetMappings,
      vpcId,
      deleteProtection,
      subnetChangeProtection,
    };
    if (description !== '') {
      finalCreationData = { ...finalCreationData, description };
    }
    if (encryptionConfiguration) {
      finalCreationData = { ...finalCreationData, encryptionConfiguration };
    }
    if (tags && tags.length > 0) {
      const finalTags: AwsTagType[] = tags.map(item => {
        return { key: item.keyStr, value: item.valueStr };
      });
      finalCreationData = { ...finalCreationData, tags: finalTags };
    }

    return {
      cloudId,
      region,
      reqData: finalCreationData,
    };
  }, [cloudId, region, creationData]);

  const creationApiLoading = useMemo((): boolean => {
    return createFirewallLoading;
  }, [createFirewallLoading]);

  // handle excute API

  const onCreateButtonClicked = useCallback(() => {
    createAwsFirewall({variables: firewallCreationVariable}).then(({data: firewallCreationResponse}) => {
      const firewall = firewallCreationResponse?.createAwsFirewall?.data?.[0]?.firewall;
      if (firewall) {
        useToast(ErrorCode.SUCCESS, 'Create firewall successful.');
        onBackButtonClicked();
      } else {
        useToast(ErrorCode.UNKNOWN, 'Create firewall failed.');
      }
    });
  }, [firewallCreationVariable]);

  const buttonGroupNode = useMemo(() => {
    const isFirstItem = currentStepIndex === 0;

    if (currentStepIndex === 5) {
      return null;
    }

    return (
      <div className="button-group">
        <Button label="Cancel" onClick={onCancelButtonClicked} />
        {!isFirstItem && <Button label="Previous" onClick={onPreviousButtonClicked} />}
        <Button label="Next" type={ButtonTypeEnum.PRIMARY} onClick={onNextButtonClicked} />
      </div>
    );
  }, [currentStepIndex, creationData]);

  const stepNode = useMemo(() => {
    switch (currentStepIndex) {
      default:
      case 0:
        return (
          <DescribeFirewall
            title={CREATE_FIREWALL_STEPS[currentStepIndex].title}
            creationData={creationData}
            updateCreationData={data => setCreationData(data)}
          />
        );
      case 1:
        return (
          <ConfigVpcAndSubnet
            cloudId={cloudId}
            region={region}
            title={CREATE_FIREWALL_STEPS[currentStepIndex].title}
            creationData={creationData}
            updateCreationData={data => setCreationData(data)}
          />
        );
      case 2:
        return (
          <ConfigAdvanceSetting
            cloudId={cloudId}
            region={region}
            title={CREATE_FIREWALL_STEPS[currentStepIndex].title}
            creationData={creationData}
            updateCreationData={data => setCreationData(data)}
          />
        );
      case 3:
        return (
          <AssociateFirewallPolicy
            cloudId={cloudId}
            region={region}
            title={CREATE_FIREWALL_STEPS[currentStepIndex].title}
            creationData={creationData}
            updateCreationData={data => setCreationData(data)}
          />
        );
      case 4:
        return (
          <AddTag
            title={CREATE_FIREWALL_STEPS[currentStepIndex].title}
            creationData={creationData}
            updateCreationData={data => setCreationData(data)}
          />
        );
      case 5:
        return (
          <Review
            creationData={creationData}
            onCancelButtonClicked={onCancelButtonClicked}
            onPreviousButtonClicked={onPreviousButtonClicked}
            onCreateButtonClicked={onCreateButtonClicked}
            submitLoading={creationApiLoading}
            updateCreationData={data => setCreationData(data)}
          />
        );
    }
  }, [currentStepIndex, cloudId, region, creationData, creationApiLoading]);

  return (
    <div id="create-firewall-page-container">
      <PageTitle
        title={currentStepIndex === 5 ? 'Create firewall: Review' : 'Create firewall'}
        hasBackButton
        onBackButtonClicked={onBackButtonClicked}
      />
      {stepNode}
      {buttonGroupNode}
    </div>
  );
};

export default FirewallCreation;
