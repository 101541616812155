import { gql } from '@apollo/client';

const query = gql`
  mutation cancelRotateAwsSecret($reqData: AwsCancelRotateSecretRequest, $cloudId: Long, $region: String) {
    cancelRotateAwsSecret(cancelRotateSecretRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsCancelRotateSecretResponse {
          arn
          name
          versionId
        }
      }
    }
  }
`;
export default query;
