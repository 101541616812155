import { useState, useMemo, useEffect, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconStatelessAction from 'assets/svgs/v3/ico_stateless_actions.svg';
import IconSearch from 'assets/svgs/v3/ico_search.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import MultiSelectAutocomplete from 'components/v2/atoms/AutocompleteInputAtom';
import CopyableText from 'components/v3/CopyableText';
import AutoInputSelected from 'components/v2/atoms/AutoCompleteDropdownAtom';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';

interface IStatelessActionsProps extends IBaseModalProps {
  header: string;
  statelessDefaultActions: string;
  statelessFragmentDefaultActions: string;
  creationApiLoading: boolean;
  handleUpdateStatelessAction: Function;
  onClose: () => void;
}

const StatelessActionsModal = ({
  header,
  statelessDefaultActions,
  statelessFragmentDefaultActions,
  creationApiLoading,
  handleUpdateStatelessAction,
  onClose,
  ...baseModalProps
}: IStatelessActionsProps) => {

  const [packetValues, setPacketValues] = useState<string>('same');
  const [fullPacketValues, setFullPacketValues] = useState<string>('aws:pass');
  const [fragmentedPacketValues, setFragmentedPacketValues] = useState<string>('aws:pass');
  const [ isShowFragment, setIsShowFragment] = useState<boolean>(false);

  const onSavePolicy = async () => {
    const result = await handleUpdateStatelessAction(fullPacketValues,fragmentedPacketValues);
    if (result) {
      onClose();
    }
  };
  useEffect(() => {
    if (statelessDefaultActions[0] === statelessFragmentDefaultActions[0]) {
      setFullPacketValues(statelessDefaultActions[0]);  
    }
    else {
      setPacketValues('different');
      setFullPacketValues(statelessDefaultActions[0]);
      setFragmentedPacketValues(statelessFragmentDefaultActions[0]);
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => <><Icon width={32} height={32} src={IconStatelessAction} />{header}</>}
      {...baseModalProps}
      onClose={onClose}
    >
      <div className="stateless-actions-modal">
        <div className="stateless-actions">
          <div className="row-1">
            <p className="text-title">Fragmented packets</p>
            <div className="radio-content">
              <RadioAtom
                label={'Use the same actions for all packets'}
                value={'same'}
                name={''}
                checked={packetValues}
                onChange={() => {
                  setPacketValues('same');
                }}/>

              <RadioAtom
                label={'Use different actions for full packets and fragmented packets'}
                value={'different'}
                name={''}
                checked={packetValues}
                onChange={() => {
                  setPacketValues('different');
                  setIsShowFragment(true);
                }}/>
            </div>
          </div>

          <div className="row-3">
            <p className="text-title">Default actions for full packets</p>
            <p className="text-description">Rule action</p>
            <RadioAtom
              label={'Pass'}
              value={'aws:pass'}
              name={''}
              checked={fullPacketValues}
              onChange={() => {
                setFullPacketValues('aws:pass');
              }}/>

            <RadioAtom
              label={'Drop'}
              value={'aws:drop'}
              name={''}
              checked={fullPacketValues}
              onChange={() => {
                setFullPacketValues('aws:drop');
              }}/>

            <RadioAtom
              label={'Forward to stateful rule groups'}
              value={'aws:forward_to_sfe'}
              name={''}
              checked={fullPacketValues}
              onChange={() => {
                setFullPacketValues('aws:forward_to_sfe');
              }} />
          </div>

          {packetValues === 'different' &&
            <>
              <div className="row-3">
                <p className="text-title">Default actions for fragmented packets</p>
                <p className="text-description">Rule action</p>
                <RadioAtom
                  label={'Pass'}
                  value={'aws:pass'}
                  name={''}
                  checked={fragmentedPacketValues}
                  onChange={() => {
                    setFragmentedPacketValues('aws:pass');
                  }} />

                <RadioAtom
                  label={'Drop'}
                  value={'aws:drop'}
                  name={''}
                  checked={fragmentedPacketValues}
                  onChange={() => {
                    setFragmentedPacketValues('aws:drop');
                  }} />

                <RadioAtom
                  label={'Forward to stateful rule groups'}
                  value={'aws:forward_to_sfe'}
                  name={''}
                  checked={fragmentedPacketValues}
                  onChange={() => {
                    setFragmentedPacketValues('aws:forward_to_sfe');
                  }} />
              </div>
            </>
          }

        </div>
        <div className="button-group">
          <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={onClose} />
          <Button
            label="Save"
            type={ButtonTypeEnum.PRIMARY}
            loading={creationApiLoading}
            onClick={onSavePolicy}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default StatelessActionsModal;
