import { UpdateServerCerOutboundPropsType } from '../types';
import IconEditTlsInspection from 'assets/svgs/v3/ico_edit_subnet.svg';
import Icon from 'components/v2/atoms/Icon';
import BaseModal from 'components/v2/modals/BaseModal';
import IconSearch from 'assets/svgs/v3/ico_search.svg';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import updateAwsTLSInspectionConfigurationMutation, {
  AwsUpdateTLSInspectionConfigurationVariables,
} from 'graphql/mutations/updateAwsTLSInspectionConfiguration';
import lazyGetAwsListCertificate, { IGetAwsListCertificateVariables } from 'graphql/queries/getAwsListCertificates';
import { DropdownListDataType } from 'components/molecules/Dropdown/types';
import { extractResourceArnData } from 'utils/Common';
import { AwsServerCertificateConfiguration } from 'graphql/types/AwsTlsInspectionConfiguration';
import './index.scss';
import AutoCompleteDropdownAtom from 'components/v2/atoms/AutoCompleteDropdownAtom';

const UpdateServerCerOutbound = (props: UpdateServerCerOutboundPropsType) => {
  const { cloudId, region, tlsInspectionDetail, onCancel, onUpdated, ...baseModalProps } = props;

  // API
  const [getAwsListCertificate] = lazyGetAwsListCertificate();
  const [updateAwsTLSInspectionConfiguration, { loading: updateLoading }] =
    updateAwsTLSInspectionConfigurationMutation();

  // State
  const [listDropdownData, setListDropdownData] = useState<DropdownListDataType[]>([]);
  const [cerSelected, setCerSelected] = useState<DropdownListDataType>();

  const fetchListCertificateData = useCallback(() => {
    const reqData: IGetAwsListCertificateVariables = { cloudId, region, reqData: { includes: {} } };
    getAwsListCertificate({ variables: reqData }).then(({ data: response }) => {
      const certificateSummaryList = response?.getAwsListCertificates?.data?.[0]?.certificateSummaryList;
      if (certificateSummaryList) {
        const listDropdown: DropdownListDataType[] = certificateSummaryList.map(item => {
          const extractedResource = extractResourceArnData(item?.certificateArn ?? '');
          return {
            name: extractedResource.id,
            value: item?.certificateArn ?? '',
            isVertical: true,
            description: `Account ID: ${extractedResource?.accountId ?? '-'} \nDomain name: ${item?.domainName ?? '-'}`,
          };
        });
        setListDropdownData(listDropdown);
      }
    });
  }, [cloudId, region]);

  const requestData = useMemo((): AwsUpdateTLSInspectionConfigurationVariables => {
    const { updateToken, tlsInspectionConfiguration, tlsInspectionConfigurationResponse } = tlsInspectionDetail;
    const { tlsInspectionConfigurationArn, tlsInspectionConfigurationName, encryptionConfiguration, description } =
      tlsInspectionConfigurationResponse;

    const { serverCertificateConfigurations } = tlsInspectionConfiguration;
    const certificateAuthorityArn = cerSelected?.value.toString() ?? '';
    const serverCertificateConfigurationUpdated: AwsServerCertificateConfiguration[] =
      serverCertificateConfigurations.map(serverCertificateConfig => {
        const { serverCertificates, scopes, checkCertificateRevocationStatus } = serverCertificateConfig;

        return {
          serverCertificates,
          scopes,
          certificateAuthorityArn,
          checkCertificateRevocationStatus,
        };
      });

    return {
      cloudId,
      region,
      reqData: {
        tlsInspectionConfigurationArn,
        tlsInspectionConfigurationName,
        tlsInspectionConfiguration: {
          serverCertificateConfigurations: serverCertificateConfigurationUpdated,
        },
        description,
        encryptionConfiguration,
        updateToken,
      },
    };
  }, [cloudId, region, tlsInspectionDetail, cerSelected]);

  const updateCertificate = useCallback(() => {
    if (cerSelected?.value.toString() !== undefined) {
      updateAwsTLSInspectionConfiguration({ variables: requestData }).then(({ data: response }) => {
        const result = response?.updateAwsTLSInspectionConfiguration?.result ?? '';
        if (result === ErrorCode.SUCCESS) {
          useToast(ErrorCode.SUCCESS, 'Update TLS Inspection Configuration successfully.');
          onUpdated();
        } else {
          useToast(ErrorCode.SUCCESS, 'Update TLS Inspection Configuration failed.');
        }
      });
    }
    onCancel();
  }, [requestData, cerSelected]);

  useEffect(() => {
    fetchListCertificateData();
  }, []);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditTlsInspection} />
          {'CA certificate for outbound SSL/TLS inspection - new'}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-tls-inpsection-detail-modal">
        <div className="edit-tls-inpsection-title">
          <p className="header">CA certificate for outbound SSL/TLS inspection-new</p>
          <p className="description">
            Choose an imported certificate authority (CA) certificate within ACM for outbound SSL/TLS inspection.
            Network Firewall uses the imported CA certificate to sign and generate a server certificate that’s trusted
            by the client during SSL/TLS inspection. If you don’t already have a certificate for this purpose, import a
            CA certificate in ACM
          </p>
          <div className="link-group">
            <p className="link">Request certificate</p> or <p className="link">import certificate.</p>
          </div>
        </div>

        <div className="edit-tls-inpsection-out-container">
          <p className="label">CA certificate</p>
          <div className="input-group-container">
            <AutoCompleteDropdownAtom
              icon={IconSearch}
              data={listDropdownData}
              value={cerSelected?.value?.toString()}
              placeholder="Choose an AWS KMS key or enter an ARN"
              handleClick={val => setCerSelected(val)}
              handleChange={() => {}}
              handleAddClass={() => {}}
            />
            <Button label="Remove" onClick={() => setCerSelected(undefined)} />
          </div>
        </div>

        <div className="button-group">
          <Button label="Cancel" onClick={onCancel} />
          <Button
            label="Save changes"
            type={ButtonTypeEnum.PRIMARY}
            onClick={() => updateCertificate()}
            loading={updateLoading}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default UpdateServerCerOutbound;
