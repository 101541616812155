import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { AwsTagType } from 'graphql/types/AwsVpc';

export interface ITagAwsResourceSecretsVariables {
  cloudId: number;
  region: string;
  reqData: {
    secretId: string;
    tags: AwsTagType[];
  };
}

export interface ITagAwsResourceSecretsResponseData {
  tagAwsResourceSecrets: IGqlResponseData<undefined>;
}

const tagAwsResourceSecretsMutation = () =>
  useMutation<ITagAwsResourceSecretsResponseData, ITagAwsResourceSecretsVariables>(query);
export default tagAwsResourceSecretsMutation;
