import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsSecretValueVariables {
  cloudId: number;
  region: string;
  request: {
    secretId?: string;
  };
}

export interface IAwsSecretValueResponseData {
  getAwsSecretValue: IGqlResponseNewData<any>;
}

const lazyGetAwsSecretValue = () =>
  useLazyQuery<IAwsSecretValueResponseData, IAwsSecretValueVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsSecretValue;
