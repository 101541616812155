import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListUsersResponseType } from 'graphql/types/AwsListUsersResponse';

export interface IAwsListUsersVariables {
  cloudId: number;
  region: string;
  request: {
    maxItems: number;
    nextToken?: string;
    filters?: {
      name: string;
      values: string[];
    };
  };
}

export interface IAwsListUsersResponseData {
  getAwsListUsers: IGqlResponseNewData<AwsListUsersResponseType>;
}

const lazyGetAwsListUsers = () =>
  useLazyQuery<IAwsListUsersResponseData, IAwsListUsersVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListUsers;
