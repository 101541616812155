import { useCallback, useEffect, useState } from 'react';
import IconMoveUp from 'assets/svgs/v3/ico_move_up.svg';
import IconMoveDown from 'assets/svgs/v3/ico_move_down.svg';
import './index.scss';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import RadioAtom from 'components/v2/atoms/RadioAtom';

interface IFilterLogsModalProps {
  columns: string[];
  dataDropdown: DropdownListDataType[];
  onChangeValue?: () => void;
}

type FilterConditionType = {
  conditionType: string;
  conditionValue: string;
};

export interface FilterRowData {
  index: number;
  valueFilterRequirement: string;
  valueFilterBehavior: string;
  filterConditions: Array<FilterConditionType>;
}

const dropdownTypeDummyData: DropdownListDataType[] = [
  { id: 0, value: 'ruleAction', name: 'Rule action on request' },
  { id: 1, value: 'requestLabel', name: 'Request has label' },
];

const dropdownValueRuleActionDummyData: DropdownListDataType[] = [
  { id: 0, value: 'allow', name: 'Allow' },
  { id: 1, value: 'block', name: 'Block' },
  { id: 1, value: 'count', name: 'Count' },
  { id: 1, value: 'capcha', name: 'CAPCHA' },
];

const dropdownValueRequestLabelDummyData: DropdownListDataType[] = [
  { id: 0, value: 'AWSManagedIPReputationList', name: 'awswaf:managed:aws:amazon-ip-list:AWSManagedIPReputationList' },
  { id: 1, value: 'AWSManagedIPDDoSList', name: 'awswaf:managed:aws:amazon-ip-list:AWSManagedIPDDoSList' },
  {
    id: 1,
    value: 'AWSManagedReconnaissanceList',
    name: 'awswaf:managed:aws:amazon-ip-list:AWSManagedReconnaissanceList',
  },
  { id: 1, value: 'HostingProviderIPList', name: 'awswaf:managed:aws:anonymous-ip-list:HostingProviderIPList' },
];

const FilterLogsModal = ({ columns, dataDropdown, onChangeValue }: IFilterLogsModalProps) => {
  const [filterRows, setFilterRows] = useState<FilterRowData[]>([]);
  const [loggingBehaviorValue, setLoggingBehaviorValue] = useState<string>('keep');

  const handleAddFilter = () => {
    setFilterRows(prevState => {
      const newState = prevState.concat({
        index: filterRows.length,
        valueFilterRequirement: 'all',
        filterConditions: [
          {
            conditionType: '',
            conditionValue: '',
          },
        ],
        valueFilterBehavior: 'keep',
      });
      return newState;
    });
  };

  const handleMoveUp = useCallback(
    (index: number) => {
      if (index > 0) {
        const updatedRows = [...filterRows];
        const temp = updatedRows[index];
        updatedRows[index] = updatedRows[index - 1];
        updatedRows[index - 1] = temp;
        setFilterRows(updatedRows);
      }
    },
    [filterRows],
  );

  const handleMoveDown = useCallback(
    (index: number) => {
      if (index < filterRows.length - 1) {
        const updatedRows = [...filterRows];
        const temp = updatedRows[index];
        updatedRows[index] = updatedRows[index + 1];
        updatedRows[index + 1] = temp;
        setFilterRows(updatedRows);
      }
    },
    [filterRows],
  );

  const handleChange = (index: number, value: string, key: keyof FilterRowData) => {
    const newRows = filterRows.map((row, i) => (i === index ? { ...row, [key]: value } : row));
    setFilterRows(newRows);
  };

  const removeMainFilterRows = (mainIndex: number) => {
    const newRowsData = filterRows.filter((_, index) => index !== mainIndex);
    setFilterRows(newRowsData);
  };

  const handleAddCondition = useCallback(
    (index: number) => {
      const updatingRow = filterRows[index];

      updatingRow.filterConditions.push({
        conditionType: '',
        conditionValue: '',
      });

      const newFilterRows = filterRows.map((filterRow, idx) => {
        if (idx === index) {
          return updatingRow;
        }

        return filterRow;
      });

      setFilterRows(newFilterRows);
    },
    [filterRows],
  );

  const handleChangeCondition = (
    mainRowIndex: number,
    subRowIndex: number,
    value: string,
    key: keyof FilterConditionType,
  ) => {
    setFilterRows(
      filterRows.map((row, index) =>
        index === mainRowIndex
          ? {
              ...row,
              filterConditions: row.filterConditions.map((subrow, i) =>
                i === subRowIndex ? { ...subrow, [key]: value } : subrow,
              ),
            }
          : row,
      ),
    );
  };

  const removeFilterConditionFromRows = (subIndex: number) => {
    const newRowsData = filterRows.map(row => ({
      ...row,
      filterConditions: row.filterConditions.filter((_, index) => index !== subIndex),
    }));
    setFilterRows(newRowsData);
  };

  const renderFilterNode = useCallback(
    (filterRowIndex: number) => {
      return (
        <>
          <div className="header">
            <div className="text-title">Filter {filterRowIndex + 1}</div>
            <div className="btn-group">
              <button className="btn" onClick={() => handleMoveUp(filterRowIndex)}>
                <img src={IconMoveUp} className="icon-btn" alt="" />
                Move Up
              </button>

              <button className="btn" onClick={() => handleMoveDown(filterRowIndex)}>
                <img src={IconMoveDown} className="icon-btn" alt="" />
                Move Down
              </button>
              <button className="btn" onClick={() => removeMainFilterRows(filterRowIndex)}>
                Remove
              </button>
            </div>
          </div>
          <div className="body">
            <div className="filter-requirement">
              <div className="text-subtitle">Filter requirement</div>
              <div className="text-description">Criteria for a request to be a match for the filter conditions.</div>

              <div className="container-radio">
                <RadioAtom
                  label={'Match all of the filter conditions'}
                  value={'all'}
                  name={''}
                  checked={filterRows[filterRowIndex]?.valueFilterRequirement ?? 'all'}
                  onChange={val => handleChange(filterRowIndex, val, 'valueFilterRequirement')}
                />

                <RadioAtom
                  label={'Match at least one of the filter conditions'}
                  value={'one'}
                  name={''}
                  checked={filterRows[filterRowIndex]?.valueFilterRequirement ?? 'all'}
                  onChange={val => handleChange(filterRowIndex, val, 'valueFilterRequirement')}
                />
              </div>
            </div>
            <div className="filter-conditions">
              <div className="text-subtitle">Filter conditions</div>
              <div className="text-description">Select the filtering criteria.</div>
              {columns && (
                <div className="filter-condition-row-container">
                  <div className="filter-condition-row">
                    {columns.map((column, columnIndex) => {
                      return (
                        <div key={`filter-condition-table-column-${columnIndex}}`} className="column">
                          <p className="label-dropdown">{column}</p>
                        </div>
                      );
                    })}
                  </div>
                  <div className="tag-row-btn"></div>
                </div>
              )}

              {filterRows?.[filterRowIndex]?.filterConditions?.map((filterCondition, conditionIndex) => {
                return (
                  <div
                    className="filter-condition-row-container"
                    key={`filter-condition-mainrow-${filterRowIndex}-subrow-${conditionIndex}`}
                  >
                    <div className="filter-condition-row">
                      <div className="column">
                        <DropdownAtom
                          id={`conditionTypeMainrow-${filterRowIndex}-Subrow-${conditionIndex}`}
                          data={dropdownTypeDummyData}
                          placeholder={'Select a condition type'}
                          value={{
                            name:
                              dropdownTypeDummyData.find(val => val.value.toString() === filterCondition?.conditionType)
                                ?.name ?? '',
                            value: '',
                          }}
                          handleClick={val =>
                            handleChangeCondition(filterRowIndex, conditionIndex, val.value.toString(), 'conditionType')
                          }
                        />
                      </div>
                      <div className="column">
                        {filterCondition.conditionType === 'ruleAction' ? (
                          <>
                            <DropdownAtom
                              id={`conditionValueRuleActionMainRow${filterRowIndex}Subrow${conditionIndex}`}
                              data={dropdownValueRuleActionDummyData}
                              placeholder={'Select a condition value'}
                              disabled={!filterCondition.conditionType}
                              value={{
                                name:
                                  dropdownValueRuleActionDummyData.find(
                                    val => val.value.toString() === filterCondition?.conditionValue,
                                  )?.name ?? '',
                                value: '',
                              }}
                              handleClick={val =>
                                handleChangeCondition(
                                  filterRowIndex,
                                  conditionIndex,
                                  val.value.toString(),
                                  'conditionValue',
                                )
                              }
                            />
                          </>
                        ) : (
                          <>
                            <DropdownAtom
                              id={`conditionValueRequestLabelMainrow-${filterRowIndex}-Subrow-${conditionIndex}`}
                              data={dropdownValueRequestLabelDummyData}
                              placeholder={'Select a condition value'}
                              disabled={!filterCondition.conditionType}
                              value={{
                                name:
                                  dropdownValueRequestLabelDummyData.find(
                                    val => val.value.toString() === filterCondition?.conditionValue,
                                  )?.name ?? '',
                                value: '',
                              }}
                              handleClick={val =>
                                handleChangeCondition(
                                  filterRowIndex,
                                  conditionIndex,
                                  val.value.toString(),
                                  'conditionValue',
                                )
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="filter-row-btn">
                      <button className="btn" onClick={() => removeFilterConditionFromRows(conditionIndex)}>
                        Remove
                      </button>
                    </div>
                  </div>
                );
              })}
              <button onClick={() => handleAddCondition(filterRowIndex)} className="add-btn btn">
                Add condition
              </button>
            </div>
            <div className="filter-behavior">
              <div className="text-subtitle">Filter behavior</div>
              <div className="text-description">
                Select the action to take for requests that match the filter criteria.
              </div>
              <div className="container-dropdown">
                <RadioAtom
                  label={'Keep in logs'}
                  value={'keep'}
                  name={''}
                  checked={filterRows?.[filterRowIndex]?.valueFilterBehavior ?? 'keep'}
                  onChange={val => handleChange(filterRowIndex, val, 'valueFilterBehavior')}
                />
                <RadioAtom
                  label={'Drop from logs'}
                  value={'drop'}
                  name={''}
                  checked={filterRows?.[filterRowIndex]?.valueFilterBehavior ?? 'keep'}
                  onChange={val => handleChange(filterRowIndex, val, 'valueFilterBehavior')}
                />
              </div>
            </div>
          </div>
        </>
      );
    },
    [
      handleChange,
      handleAddFilter,
      handleChangeCondition,
      removeFilterConditionFromRows,
      handleMoveDown,
      handleMoveUp,
      filterRows,
    ],
  );

  return (
    <div className="filter-logs-content">
      <div className="row-1">
        <div className="add-filter-log-content">
          {filterRows.map((_, filterRowIndex) => {
            return (
              <div className="container-filter" key={`container-filter-${filterRowIndex}`}>
                {renderFilterNode(filterRowIndex)}
              </div>
            );
          })}

          <button className="add-filter-btn btn" onClick={handleAddFilter}>
            Add filter
          </button>
        </div>
      </div>
      {filterRows.length > 0 && (
        <div className="row-2">
          <div className="header">
            <div className="text-title">Default logging behavior</div>
            <div className="text-subtitle">Default logging behavior</div>
            <div className="text-description">
              Indicate how to handle requests that don’t match any of the specified log filters.
            </div>
          </div>
          <div className="radio-container">
            <RadioAtom
              label={'Keep in logs'}
              value={'keep'}
              name={''}
              checked={loggingBehaviorValue}
              onChange={() => {
                setLoggingBehaviorValue('keep');
              }}
            />

            <RadioAtom
              label={'Drop from logs'}
              value={'drop'}
              name={''}
              checked={loggingBehaviorValue}
              onChange={() => {
                setLoggingBehaviorValue('drop');
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterLogsModal;
