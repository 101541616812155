import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IRotateAwsSecretVariables {
  cloudId: number;
  region: string;
  reqData: {
    secretId: string;
    rotateImmediately: boolean;
    rotationLambdaArn: string;
    rotationRules: {
      duration: string;
      scheduleExpression: string;
    };
  };
}

export interface IRotateAwsSecretResponseData {
  rotateAwsSecret: IGqlResponseData<undefined>;
}

const rotateAwsSecretMutation = () => useMutation<IRotateAwsSecretResponseData, IRotateAwsSecretVariables>(query);
export default rotateAwsSecretMutation;
