import { gql } from '@apollo/client';

const query = gql`
  query getAwsResourcePolicy($reqData: AwsGetResourcePolicyRequest, $cloudId: Long, $region: String) {
    getAwsResourcePolicy(getResourcePolicyRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetResourcePolicyResponse {
          arn
          name
          resourcePolicy
        }
      }
    }
  }
`;
export default query;
