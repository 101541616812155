import { InputAreaPropsType } from '../types';
import RequiredIcon from 'assets/svgs/v2/ico_require.svg';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import './index.scss';

const InputArea = (props: InputAreaPropsType) => {
  const {
    title,
    description,
    customerStyle,
    topDescription,
    bottomDescription,
    placeholder,
    value,
    onValueChanged,
    isRequired,
    disabled,
    isVertical,
    error,
  } = props;

  if (isVertical) {
    return (
      <div id="input-area-container">
        <div className="left-column-content">
          <p className="title">{title}</p>
          {isRequired && <img src={RequiredIcon} width={4} height={4} />}
        </div>
        <TextareaAtom
          className={'value' + ' ' + customerStyle}
          required
          value={value}
          onChangeValue={onValueChanged}
          placeholder={placeholder}
          disabled={disabled}
          error={error}
        />
      </div>
    );
  }

  return (
    <div id="input-area-container">
      <div className="row-content">
        <div className="left-column-content" />
        <div>
          <p className="description">{topDescription}</p>
        </div>
      </div>
      <div className="row-content">
        <div className="left-column-content">
          <p className="title">{title}</p>
          {isRequired && <img src={RequiredIcon} width={3.5} height={3.5} />}
        </div>
        <TextareaAtom
          className={'value' + ' ' + customerStyle}
          required
          value={value}
          onChangeValue={onValueChanged}
          placeholder={placeholder}
          disabled={disabled}
          error={!!error}
        />
      </div>
      {error && typeof error === 'string' && (
        <div className="row-content">
          <div className="left-column-content" />
          <div className="error-container right-column-content">
            <img src={InputErrorIcon} width={16} height={16} />
            <p className="error-message">{error}</p>
          </div>
        </div>
      )}
      <div className="row-content">
        <div className="left-column-content" />
        <div className="right-column-content">
          <p className="description">{bottomDescription}</p>
        </div>
      </div>
    </div>
  );
};

export default InputArea;
