import './index.scss';
import { useEffect, useMemo, useState } from 'react';
import LabelInput from 'components/v2/LabelInput';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';

interface IDeleteAllowListModalProps extends IBaseModalProps {
  header: string;
  titleWarning?: string;
  onDelete: () => void;
}

const DeleteAllowList = ({ header, titleWarning, onDelete, ...baseModalProps }: IDeleteAllowListModalProps) => {
  const [payload, setPayload] = useState<string>('');

  const ableDelete = useMemo(() => {
    if (payload !== 'delete') {
      return true;
    }

    return false;
  }, [payload]);

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal {...baseModalProps} title={() => <>{header}</>}>
      <div className="delete-allow-list-modal">
        <div className="delete-allow-list">
          <div className="header">
            <div className="delete-warning">
              <div className="content">
                <p className="note">{titleWarning}</p>
              </div>
            </div>
            <LabelInput
              title="To confirm that you want to delete this list, enter “delete” in the following field, and then choose Delete."
              value={payload}
              placeholder={'delete'}
              onChangeValue={val => setPayload(val)}
              required
            />
          </div>
        </div>

        <div className={'button-group'}>
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" disabled={ableDelete} onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteAllowList;
