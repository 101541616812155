import { ErrorCode } from '@Types/error';
import IconEditFirewallDetail from 'assets/svgs/v3/ico_edit_firewall_detail.svg';
import IconEditRuleGroup from 'assets/svgs/v3/ico_policy_variables.svg';
import Icon from 'components/v2/atoms/Icon';
import InputAtom from 'components/v2/atoms/InputAtom';
import Labeled from 'components/v2/atoms/Labeled';
import BaseModal from 'components/v2/modals/BaseModal';
import updateAwsFirewallDescriptionMutation, {
  UpdateFirewallDescriptionVariablesType,
} from 'graphql/mutations/updateAwsFirewallDescription';
import { useToast } from 'hooks/v2/useToast';
import { useState } from 'react';
import { IAwsFirewall, IBaseFirewallModalProps } from '../../../types';
import { EditFirewallDetailModalTypeEnum } from '../../types';
import './index.scss';

interface IEditFirewallDetailProps extends IBaseFirewallModalProps {
  type: EditFirewallDetailModalTypeEnum;
  placeholderDescription?: string;
}

const EditFirewallDetailModal = ({
  type,
  header,
  cloudId,
  region,
  data,
  setData = () => { },
  placeholderDescription = '',
  ...baseModalProps
}: IEditFirewallDetailProps) => {
  const { firewallName = '', description = '', firewallArn = '' } = data?.firewall || {};

  const updateToken = data?.updateToken || '';

  const [editedDescription, setEditedDescription] = useState<string>(description);
  const [error, setError] = useState<string>('');
  const [updateAwsFirewallDescription] = updateAwsFirewallDescriptionMutation();

  const handleUpdateAwsFirewallDescription = async () => {
    const MAX_LENGTH = 256;
    if (type === EditFirewallDetailModalTypeEnum.EditRuleGroup && editedDescription.length > MAX_LENGTH) {
      setError('Description must have length less than or equal to 256.');
      return;
    }

    try {
      const variables: UpdateFirewallDescriptionVariablesType = {
        cloudId,
        region,
        request: {
          firewallArn,
          firewallName,
          description: editedDescription,
          updateToken,
        },
      };

      const response = await updateAwsFirewallDescription({ variables });

      const policyDescriptionData = response?.data?.updateAwsFirewallDescription?.data[0];

      setData((prevState: IAwsFirewall) => ({
        ...prevState,
        updateToken: policyDescriptionData?.updateToken,
        firewall: {
          ...data?.firewall,
          description: policyDescriptionData?.description,
        },
      }))

      useToast(ErrorCode.SUCCESS, 'Update firewall description successfully.');
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Update firewall description failed.');
    } finally {
      baseModalProps?.onClose?.();
    }
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon
            width={32}
            height={32}
            src={
              type === EditFirewallDetailModalTypeEnum.EditFireWallDetail ? IconEditFirewallDetail : IconEditRuleGroup
            }
          />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-firewall-detail-modal">
        <div className="edit-firewall-name">
          {type === EditFirewallDetailModalTypeEnum.EditFireWallDetail ? (
            <p>Name</p>
          ) : (
            <Labeled title={'Name'} required></Labeled>
          )}
          <InputAtom type={'text'} value={firewallName} noClear={true} disabled={true} />
        </div>
        <div className="edit-firewall-description">
          <p>Description</p>
          <div className="edit-firewall-input-description">
            <InputAtom
              type={'text'}
              placeholder={placeholderDescription}
              value={editedDescription}
              noClear={true}
              onChangeValue={val => setEditedDescription(val)}
              error={error}
            />
          </div>
        </div>

        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={handleUpdateAwsFirewallDescription}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditFirewallDetailModal;
