import {gql} from '@apollo/client';

const query = gql`
query getAwsListCertificates($reqData: AwsListCertificatesRequest, $cloudId: Long, $region: String) {
  getAwsListCertificates(
    awsListCertificatesRequest: $reqData
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsListCertificatesResponse {
        nextToken
        certificateSummaryList {
          certificateArn
          domainName
          subjectAlternativeNameSummaries
          hasAdditionalSubjectAlternativeNames
          status
          type
          keyAlgorithm
          keyUsages
          extendedKeyUsages
          inUse
          exported
          renewalEligibility
          notBefore
          notAfter
          createdAt
          issuedAt
          importedAt
          revokedAt
        }
      }
    }
  }
}
`;
export default query;