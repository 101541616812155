import './style.scss';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import { getFormatedDateWithTimezone } from 'utils/Common';
import DetailTab from '../../../../components/DetailTab';
import { useState } from 'react';
import EditRotationConfigurationModal from '../Components/EditRotationConfigurationModal';
import canceRotateAwsSecretMutation, { ICanceRotateAwsSecretVariables } from 'graphql/mutations/canceRotateAwsSecret';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import rotateAwsSecretMutation, { IRotateAwsSecretVariables } from 'graphql/mutations/rotateAwsSecret';

function Rotations({ cloudId, region, secret = {}, onReloadData }: any) {
  const { rotationEnabled, rotationRules, lastRotatedDate, nextRotationDate, rotationLambdaARN } = secret;

  const [isOpenEditRotationConfiguration, setIsOpenEditRotationConfiguration] = useState(false);

  const [canceRotateAwsSecret, { loading: canceRotateAwsSecretLoading }] = canceRotateAwsSecretMutation();
  const [rotateAwsSecret, { loading: rotateAwsSecretLoading }] = rotateAwsSecretMutation();

  const detailTabData: IMgdDetailKeyValueProps[] = [
    {
      title: 'Rotation status',
      description: rotationEnabled || 'Disabled',
    },
    {
      title: 'Rotation schedule',
      description: rotationRules?.cheduleExpression,
    },
    {
      title: 'Last rotated date (UTC)',
      description: getFormatedDateWithTimezone(lastRotatedDate),
    },
    {
      title: 'Net rotation date (UTC) \n The next rotation is scheduled to occur on or before this date.',
      description: getFormatedDateWithTimezone(nextRotationDate),
    },
    {
      title: 'Lambda rotation function \n The Lambda function that has permissions to rotate this secret.',
      description: rotationLambdaARN,
    },
  ];

  const renderAction = () => {
    return (
      <button className="button" onClick={() => setIsOpenEditRotationConfiguration(true)}>
        Edit rotation
      </button>
    );
  };

  const handleEdit = async (values: any) => {
    if (canceRotateAwsSecretLoading || rotateAwsSecretLoading) return;

    try {
      if (!values?.isAutoRotation) {
        const variables: ICanceRotateAwsSecretVariables = {
          cloudId,
          region,
          reqData: {
            secretId: secret.arn,
          },
        };
        await canceRotateAwsSecret({ variables });
        onReloadData();
        useToast(ErrorCode.SUCCESS, 'Edit rotation successful.');
        setIsOpenEditRotationConfiguration(false);
      }

      delete values.isAutoRotation;

      const variables: IRotateAwsSecretVariables = {
        cloudId,
        region,
        reqData: {
          secretId: secret.arn,
          ...values,
        },
      };
      await rotateAwsSecret({ variables });
      onReloadData();
      useToast(ErrorCode.SUCCESS, 'Edit rotation successful.');
      setIsOpenEditRotationConfiguration(false);
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Edit rotation failed.');
    }
  };

  return (
    <>
      <div className="details-tab rotation-tab">
        <DetailTab title="Rotations configuration" data={detailTabData} actions={renderAction()} />
      </div>

      {isOpenEditRotationConfiguration ? (
        <EditRotationConfigurationModal
          open={isOpenEditRotationConfiguration}
          onClose={() => setIsOpenEditRotationConfiguration(false)}
          cloudId={cloudId}
          region={region}
          data={secret}
          onSubmit={handleEdit}
        />
      ) : null}
    </>
  );
}

export default Rotations;
