import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsFindingsResponse, AwsListFindingsResponse } from 'graphql/types/AwsListFindings';

export interface IGetAwsFindingVariables {
  cloudId: number;
  region: string;
  reqData: {
    findingIds: string[];
  };
}

export interface IGetAwsFindingResponseData {
  getAwsFindings: IGqlResponseNewData<AwsFindingsResponse>;
}

const lazyGetAwsFinding = () =>
  useLazyQuery<IGetAwsFindingResponseData, IGetAwsFindingVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsFinding;
