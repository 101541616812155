import BaseModal from 'components/v2/modals/BaseModal';
import { UpdateScopeConfigurationPropsType } from '../types';
import IconEditTlsInspection from 'assets/svgs/v3/ico_edit_subnet.svg';
import Icon from 'components/v2/atoms/Icon';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import updateAwsTLSInspectionConfigurationMutation, {
  AwsUpdateTLSInspectionConfigurationVariables,
} from 'graphql/mutations/updateAwsTLSInspectionConfiguration';
import Dropdown from 'components/molecules/Dropdown';
import { DropdownListDataType } from 'components/molecules/Dropdown/types';
import { randomString } from 'utils/Common';
import {
  AwsAddressLog,
  AwsPortRangeLog,
  AwsServerCertificateConfiguration,
  AwsServerCertificateScope,
} from 'graphql/types/AwsTlsInspectionConfiguration';
import _ from 'lodash';
import './index.scss';
import MultiSelectAutocomplete from 'components/v2/atoms/AutocompleteInputAtom';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import { IP_SET_DROPDOWN_DATA, PORT_RANGE_DROPDOWN_DATA } from '../../configs';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdTabLayout/types';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { FIREWALL_RULES_PROTOCOL_MAPPING } from '../../../configs';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import CidrRegex from 'utils/Regex/CidrRegex';

const UpdateScopeConfiguration = (props: UpdateScopeConfigurationPropsType) => {
  const { cloudId, region, tlsInspectionDetail, onCancel, onUpdated, ...baseModalProps } = props;

  // API
  const [updateAwsTLSInspectionConfiguration, { loading: updateLoading }] =
    updateAwsTLSInspectionConfigurationMutation();

  // State
  const [sourceIpDropData, setSourceIpDropData] = useState<DropdownListDataType[]>(IP_SET_DROPDOWN_DATA);
  const [sourceIpDropValue, setSourceIpDropValue] = useState<DropdownListDataType>(IP_SET_DROPDOWN_DATA[1]);
  const [sourceIpTextValue, setSourceIpTextValue] = useState<string>('0.0.0.0/0');
  const [sourceIpError, setSourceIpError] = useState<string>('');
  const [sourcePRDropData, setSourcePRDropData] = useState<DropdownListDataType[]>(PORT_RANGE_DROPDOWN_DATA);
  const [sourcePRDropValue, setSourcePRDropValue] = useState<DropdownListDataType>(PORT_RANGE_DROPDOWN_DATA[1]);
  const [sourcePRTextValue, setSourcePRTextValue] = useState<string>('0:65535');
  const [sourcePRError, setSourcePRError] = useState<string>('');

  const [desIpDropData, setDesIpDropData] = useState<DropdownListDataType[]>(IP_SET_DROPDOWN_DATA);
  const [desIpDropValue, setDesIpDropValue] = useState<DropdownListDataType>(IP_SET_DROPDOWN_DATA[1]);
  const [desIpTextValue, setDesIpTextValue] = useState<string>('0.0.0.0/0');
  const [desIpError, setDesIpError] = useState<string>('');
  const [desPRDropData, setDesPRDropData] = useState<DropdownListDataType[]>(PORT_RANGE_DROPDOWN_DATA);
  const [desPRDropValue, setDesPRDropValue] = useState<DropdownListDataType>(PORT_RANGE_DROPDOWN_DATA[1]);
  const [desPRTextValue, setDesPRTextValue] = useState<string>('0:65535');
  const [desPRError, setDesPRError] = useState<string>('');

  const [totalScopeConfigs, setTotalScopeCongifs] = useState<AwsServerCertificateScope[]>([]);
  const [scopeConfigSelected, setScopeConfigSelected] = useState<string>('');
  const [listScopeConfigSelected, setListScopeConfigSelected] = useState<string[]>([]);

  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });

  const tableColumns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Protocol',
        field: 'protocols',
        sort: true,
        renderCell: (row: RowType) =>
          row?.protocols?.map((protocol: number) => <p>{FIREWALL_RULES_PROTOCOL_MAPPING[protocol]?.text ?? ''}</p>),
      },
      {
        label: 'Source',
        field: 'source',
        sort: true,
        renderCell: (row: RowType) => row?.sources?.map((source: AwsAddressLog) => <p>{source.addressDefinition}</p>),
      },
      {
        label: 'Destination',
        field: 'destination',
        sort: true,
        renderCell: (row: RowType) =>
          row?.destinations?.map((destination: AwsAddressLog) => <p>{destination.addressDefinition}</p>),
      },
      {
        label: 'Source port',
        field: 'sourcePort',
        sort: true,
        renderCell: (row: RowType) =>
          row?.sourcePorts?.map((sourcePort: AwsPortRangeLog) => {
            if (sourcePort.fromPort === sourcePort.toPort) {
              return <p>{`${sourcePort.fromPort}`}</p>;
            } else {
              return <p>{`${sourcePort.fromPort}:${sourcePort.toPort}`}</p>;
            }
          }),
      },
      {
        label: 'Destination port',
        field: 'desPort',
        sort: true,
        renderCell: (row: RowType) =>
          row?.destinationPorts?.map((destinationPort: AwsPortRangeLog) => {
            if (destinationPort.fromPort === destinationPort.toPort) {
              return <p>{`${destinationPort.fromPort}`}</p>;
            } else {
              return <p>{`${destinationPort.fromPort}:${destinationPort.toPort}`}</p>;
            }
          }),
      },
    ];
  }, []);

  const rowsCurrentPage = useMemo((): RowType[] => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    const totalRows: RowType[] = totalScopeConfigs.slice();

    return orderAlphabetical(totalRows, tablePagination.target, tablePagination.direction).slice(startIndex, endIndex);
  }, [mainTblTotal, tablePagination, totalScopeConfigs]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setMainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const onScopeConfigurationValidate = useCallback((): boolean => {
    let validated = true;
    sourceIpTextValue
      .trim()
      ?.split('\n')
      ?.map(cidr => {
        if (!CidrRegex({ exact: true }).test(cidr)) {
          validated = false;
          setSourceIpError('Invalid IP CIDR format.');
        }
      });

    desIpTextValue
      .trim()
      ?.split('\n')
      ?.map(cidr => {
        if (!CidrRegex({ exact: true }).test(cidr)) {
          validated = false;
          setDesIpError('Invalid IP CIDR format.');
        }
      });

    const portRangeRegex =
      /^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])(?::([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/;

    sourcePRTextValue
      .trim()
      ?.split('\n')
      ?.map(portRange => {
        let finalPortRange = portRange;
        if (!portRange.indexOf(':')) {
          finalPortRange = `${finalPortRange}:${finalPortRange}`;
        }
        const portRangeReplaced = portRange.replaceAll(' ', '');
        if (!portRangeRegex.test(portRangeReplaced)) {
          validated = false;
          setSourcePRError('The port format is not valid.');
        } else {
          if (portRangeReplaced.includes('-')) {
            const portRangeSplited = portRangeReplaced.split('-');
            if (parseInt(portRangeSplited[1]) < parseInt(portRangeSplited[0])) {
              validated = false;
              setSourcePRError('The port format is not valid.');
            }
          }
        }
      });

    desPRTextValue
      .trim()
      ?.split('\n')
      ?.map(portRange => {
        let finalPortRange = portRange;
        if (!portRange.indexOf(':')) {
          finalPortRange = `${finalPortRange}:${finalPortRange}`;
        }
        const portRangeReplaced = portRange.replaceAll(' ', '');
        if (!portRangeRegex.test(portRangeReplaced)) {
          validated = false;
          setSourcePRError('The port format is not valid.');
        } else {
          ``;
          if (portRangeReplaced.includes('-')) {
            const portRangeSplited = portRangeReplaced.split('-');
            if (parseInt(portRangeSplited[1]) < parseInt(portRangeSplited[0])) {
              validated = false;
              setSourcePRError('The port format is not valid.');
            }
          }
        }
      });

    return validated;
  }, [sourceIpTextValue, desIpTextValue, sourcePRTextValue, desPRTextValue]);

  const getSourceAddresses = useCallback((ipSources: string): AwsAddressLog[] => {
    const addLogs: AwsAddressLog[] = [];
    ipSources
      .trim()
      ?.split('\n')
      ?.map(ipSource => {
        addLogs.push({ addressDefinition: ipSource });
      });
    return addLogs;
  }, []);

  const getPortRanges = useCallback((portRanges: string): AwsPortRangeLog[] => {
    const portRangeLogs: AwsPortRangeLog[] = [];
    portRanges
      .trim()
      ?.split('\n')
      ?.map(portRange => {
        const portSeparate = portRange.split(':');
        portRangeLogs.push({ fromPort: parseInt(portSeparate[0]), toPort: parseInt(portSeparate[1]) });
      });
    return portRangeLogs;
  }, []);

  const onAddConfigurationHanlder = useCallback(() => {
    if (onScopeConfigurationValidate()) {
      const totalScopes = totalScopeConfigs.slice();

      let finalSourcePRTextValue = sourcePRTextValue;
      let finalDesPRTextValue = desPRTextValue;
      if (!finalSourcePRTextValue.includes(':')) {
        finalSourcePRTextValue = `${finalSourcePRTextValue}:${finalSourcePRTextValue}`;
      }
      if (!finalDesPRTextValue.includes(':')) {
        finalDesPRTextValue = `${finalDesPRTextValue}:${finalDesPRTextValue}`;
      }

      const addedScope: AwsServerCertificateScope = {
        sources: getSourceAddresses(sourceIpTextValue),
        destinations: getSourceAddresses(desIpTextValue),
        sourcePorts: getPortRanges(finalSourcePRTextValue),
        destinationPorts: getPortRanges(finalDesPRTextValue),
        protocols: [6],
        id: randomString(),
      };

      totalScopes.push(addedScope);
      setTotalScopeCongifs(totalScopes);
      setMainTblTotal({
        totalPage: Math.ceil(totalScopes.length / tablePagination.itemPerPage),
        totalElement: totalScopes.length,
      });

      // clear input controls
      setSourceIpDropValue(IP_SET_DROPDOWN_DATA[1]);
      setSourceIpTextValue('0.0.0.0/0');
      setSourcePRDropValue(PORT_RANGE_DROPDOWN_DATA[1]);
      setSourcePRTextValue('0:65535');
      setDesIpDropValue(IP_SET_DROPDOWN_DATA[1]);
      setDesIpTextValue('0.0.0.0/0');
      setDesPRDropValue(PORT_RANGE_DROPDOWN_DATA[1]);
      setDesPRTextValue('0:65535');
    }
  }, [
    onScopeConfigurationValidate,
    totalScopeConfigs,
    sourceIpTextValue,
    desIpTextValue,
    sourcePRTextValue,
    desPRTextValue,
    tablePagination,
  ]);

  const onDeleteConfigurationHanlder = useCallback(() => {
    const finalListScopeSelected = [...listScopeConfigSelected, scopeConfigSelected];
    const scopeConfigs = totalScopeConfigs.slice();
    const removedScopeConfig = scopeConfigs.map(config => {
      if (!finalListScopeSelected.includes(config?.id ?? '')) {
        return config;
      }
    });
    const totalScopes = _.compact(removedScopeConfig);
    setTotalScopeCongifs(totalScopes);
    setListScopeConfigSelected([]);
    setScopeConfigSelected('');
    setMainTblTotal({
      totalPage: Math.ceil(totalScopes.length / tablePagination.itemPerPage),
      totalElement: totalScopes.length,
    });
  }, [totalScopeConfigs, scopeConfigSelected, listScopeConfigSelected]);

  const getInitConfigurationData = useCallback(() => {
    const { scopes } = tlsInspectionDetail?.tlsInspectionConfiguration?.serverCertificateConfigurations?.[0];
    const finalScopes = scopes.map(scope => {
      return { ...scope, id: randomString() };
    });
    setTotalScopeCongifs(finalScopes);
    setMainTblTotal({
      totalPage: Math.ceil(finalScopes.length / tablePagination.itemPerPage),
      totalElement: finalScopes.length,
    });
  }, [tlsInspectionDetail, tablePagination]);

  const requestData = useMemo((): AwsUpdateTLSInspectionConfigurationVariables => {
    const { updateToken, tlsInspectionConfiguration, tlsInspectionConfigurationResponse } = tlsInspectionDetail;
    const { tlsInspectionConfigurationArn, tlsInspectionConfigurationName, encryptionConfiguration, description } =
      tlsInspectionConfigurationResponse;

    const { serverCertificateConfigurations } = tlsInspectionConfiguration;
    const finalScopeConfigs = totalScopeConfigs.slice().map(config => _.omit(config, ['id']));
    const serverCertificateConfigurationUpdated: AwsServerCertificateConfiguration[] =
      serverCertificateConfigurations.map(serverCertificateConfig => {
        const { serverCertificates, scopes, checkCertificateRevocationStatus, certificateAuthorityArn } =
          serverCertificateConfig;

        return {
          serverCertificates,
          scopes: finalScopeConfigs,
          certificateAuthorityArn,
          checkCertificateRevocationStatus,
        };
      });

    return {
      cloudId,
      region,
      reqData: {
        tlsInspectionConfigurationArn,
        tlsInspectionConfigurationName,
        tlsInspectionConfiguration: {
          serverCertificateConfigurations: serverCertificateConfigurationUpdated,
        },
        description,
        encryptionConfiguration,
        updateToken,
      },
    };
  }, [cloudId, region, tlsInspectionDetail, totalScopeConfigs]);

  const updateCertificate = useCallback(() => {
    updateAwsTLSInspectionConfiguration({ variables: requestData }).then(({ data: response }) => {
      const result = response?.updateAwsTLSInspectionConfiguration?.result ?? '';
      if (result === ErrorCode.SUCCESS) {
        useToast(ErrorCode.SUCCESS, 'Update TLS Inspection Configuration successfully.');
        onUpdated();
      } else {
        useToast(ErrorCode.SUCCESS, 'Update TLS Inspection Configuration failed.');
      }
    });
  }, [requestData]);

  useEffect(() => {
    getInitConfigurationData();
  }, []);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditTlsInspection} />
          {'Edit scope configuration'}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-tls-inpsection-detail-modal">
        <div className="edit-tls-inpsection-title">
          <p className="header">Scope configuration</p>
          <p className="description">
            Network Firewall will decrypt traffic matching the properties for the scope configuration. After decryption,
            Network Firewall inspects the traffic according to your firewall policy’s stateful rules, then re-encrypts
            the traffic before sending it to is destination. Each scope configuration appears in the Scope Configuration
            table below.
          </p>
        </div>

        <div className="section-group-container">
          <div className="section-input-container">
            <div className="section-item">
              <p className="header">Protocol</p>
              <p className="description">Transport protocols to inspect for</p>
              <MultiSelectAutocomplete
                className={''}
                data={[{ value: 'TCP protocol 6', name: 'TCP protocol 6', disable: true }]}
                disabled={true}
                selectedValues={['TCP protocol 6']}
                placeholder="Choose protocol"
                handleClick={() => {}}
                handleRemove={() => {}}
              />
            </div>
          </div>

          <div className="section-input-container">
            <div className="section-item">
              <p className="header">Source IP</p>
              <p className="description-29">
                The source IP addresses and address ranges to decrypt for inspection. You can provide single addresses
                and CIDR blocks.
              </p>
              <Dropdown
                id={'source-ip'}
                data={sourceIpDropData}
                value={sourceIpDropValue}
                handleClick={value => {
                  if (value === IP_SET_DROPDOWN_DATA[1]) {
                    setSourceIpTextValue('0.0.0.0/0');
                  } else {
                    setSourceIpTextValue('');
                  }
                  setSourceIpError('');
                  setSourceIpDropValue(value);
                }}
                error={sourceIpError}
              />
              <TextareaAtom
                required
                value={sourceIpTextValue}
                onChangeValue={value => {
                  setSourceIpError('');
                  setSourceIpTextValue(value);
                }}
                placeholder={'10.1.0.0/16\n10.1.0.0'}
                disabled={sourceIpDropValue === IP_SET_DROPDOWN_DATA[1]}
                error={sourceIpError}
              />
              {sourceIpError && (
                <div className="error-container">
                  <img src={InputErrorIcon} width={16} height={16} />
                  <p className="error-message">{sourceIpError}</p>
                </div>
              )}
              <p className="description">
                Enter one value per line and use either IPv4 or IPv6 values but not both together.
              </p>
            </div>

            <div className="section-item">
              <p className="header">Source port range</p>
              <p className="description-29">
                Source ports and port ranges to inspect for. This only applies to TCP protocols
              </p>
              <Dropdown
                id={'source-port-range'}
                data={sourcePRDropData}
                value={sourcePRDropValue}
                handleClick={value => {
                  if (value === PORT_RANGE_DROPDOWN_DATA[1]) {
                    setSourcePRTextValue('0:65535');
                  } else {
                    setSourcePRTextValue('');
                  }
                  setSourcePRError('');
                  setSourcePRDropValue(value);
                }}
                error={sourcePRError}
              />
              <TextareaAtom
                required
                value={sourcePRTextValue}
                onChangeValue={value => {
                  setSourcePRError('');
                  setSourcePRTextValue(value);
                }}
                placeholder={'10:1000'}
                disabled={sourcePRDropValue === PORT_RANGE_DROPDOWN_DATA[1]}
                error={sourcePRError}
              />
              {sourcePRError && (
                <div className="error-container">
                  <img src={InputErrorIcon} width={16} height={16} />
                  <p className="error-message">{sourcePRError}</p>
                </div>
              )}
              <p className="description">Allowed port ranges are 0-55555. Enter one port range per line.</p>
            </div>
          </div>

          <div className="section-input-container">
            <div className="section-item">
              <p className="header">Destination IP</p>
              <p className="description-29">
                The destination AP addresses and address ranges to decrypt for inspection. You can provide single
                addresses and CIDR blocks.
              </p>
              <Dropdown
                id={'destination-ip'}
                data={desIpDropData}
                value={desIpDropValue}
                handleClick={value => {
                  if (value === IP_SET_DROPDOWN_DATA[1]) {
                    setDesIpTextValue('0.0.0.0/0');
                  } else {
                    setDesIpTextValue('');
                  }
                  setDesIpError('');
                  setDesIpDropValue(value);
                }}
                error={desIpError}
              />
              <TextareaAtom
                required
                value={desIpTextValue}
                onChangeValue={value => {
                  setDesIpError('');
                  setDesIpTextValue(value);
                }}
                placeholder={'10.1.0.0/16\n10.1.0.0'}
                disabled={desIpDropValue === IP_SET_DROPDOWN_DATA[1]}
                error={desIpError}
              />
              {desIpError && (
                <div className="error-container">
                  <img src={InputErrorIcon} width={16} height={16} />
                  <p className="error-message">{desIpError}</p>
                </div>
              )}
              <p className="description">
                Enter one value per line and use either IPv4 or IPv6 values but not both together
              </p>
            </div>

            <div className="section-item">
              <p className="header">Destination port range</p>
              <p className="description-29">
                Destination ports and port ranges to inspect for. This only applies to TCP and UDP protocols.
              </p>
              <Dropdown
                id={'destination-port-range'}
                data={desPRDropData}
                value={desPRDropValue}
                handleClick={value => {
                  if (value === PORT_RANGE_DROPDOWN_DATA[1]) {
                    setDesPRTextValue('0:65535');
                  } else {
                    setDesPRTextValue('');
                  }
                  setDesPRError('');
                  setDesPRDropValue(value);
                }}
                error={desPRError}
              />
              <TextareaAtom
                required
                value={desPRTextValue}
                onChangeValue={value => {
                  setDesPRError('');
                  setDesPRTextValue(value);
                }}
                placeholder={'10:1000'}
                disabled={desPRDropValue === PORT_RANGE_DROPDOWN_DATA[1]}
                error={desPRError}
              />
              {desPRError && (
                <div className="error-container">
                  <img src={InputErrorIcon} width={16} height={16} />
                  <p className="error-message">{desPRError}</p>
                </div>
              )}
              <p className="description">Allowed port ranges are 0-65533. Enter one port range per line.</p>
            </div>
          </div>

          <div className="section-input-container">
            <Button
              label="Add scope configuration"
              type={ButtonTypeEnum.PRIMARY}
              onClick={() => onAddConfigurationHanlder()}
            />
          </div>

          <div className="section-input-container">
            <div className="table-section">
              <div className="table-section-container">
                <p className="header">Scope configuration</p>
                <Button
                  label="Delete"
                  disabled={scopeConfigSelected !== '' || listScopeConfigSelected.length > 0 ? false : true}
                  type={
                    scopeConfigSelected !== '' || listScopeConfigSelected.length > 0
                      ? ButtonTypeEnum.PRIMARY
                      : ButtonTypeEnum.GENERAL
                  }
                  onClick={() => onDeleteConfigurationHanlder()}
                />
              </div>

              <Table
                rows={rowsCurrentPage}
                columns={tableColumns}
                reportCheckedList={listId => {
                  setListScopeConfigSelected(listId);
                  setScopeConfigSelected('');
                }}
                reportSelected={id => {
                  setScopeConfigSelected(id);
                  setListScopeConfigSelected([id]);
                }}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                isLoading={false}
                horizontalScrollable={true}
              />
              {rowsCurrentPage.length === 0 && (
                <div className="no-item-configuration">
                  <p className="header">No results.</p>
                  <p className="description">There are no results to display.</p>
                </div>
              )}
              {rowsCurrentPage && rowsCurrentPage.length > 0 && (
                <div className="pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>
                  <TableManagePagination
                    ableFetchMore={false}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page =>
                      setMainTablePagination(prev => ({
                        ...prev,
                        ['currentPage']: page,
                      }))
                    }
                    totalPage={mainTblTotal.totalPage}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="button-group">
          <Button label="Cancel" onClick={onCancel} />
          <Button
            label="Save changes"
            type={ButtonTypeEnum.PRIMARY}
            onClick={() => updateCertificate()}
            loading={updateLoading}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default UpdateScopeConfiguration;
