import { gql } from '@apollo/client';

const query = gql`
  query getAwsFindings($reqData: AwsGetFindingsRequest, $cloudId: Long, $region: String) {
    getAwsFindings(getFindingsRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetFindingsResponse {
          findings {
            accountId
            archived
            category
            classificationDetails {
              detailedResultsLocation
              jobArn
              jobId
              originType
              result {
                additionalOccurrences
                customDataIdentifiers {
                  detections {
                    arn
                    count
                    name
                    occurrences {
                      cells {
                        cellReference
                        column
                        columnName
                        row
                      }
                      lineRanges {
                        end
                        start
                        startColumn
                      }
                      offsetRanges {
                        end
                        start
                        startColumn
                      }
                      pages {
                        lineRange {
                          end
                          start
                          startColumn
                        }
                        offsetRange {
                          end
                          start
                          startColumn
                        }
                        pageNumber
                      }
                      records {
                        jsonPath
                        recodeIndex
                      }
                    }
                  }
                  totalCount
                }
                mimeType
                sensitiveData {
                  category
                  detections {
                    count
                    occurrences {
                      cells {
                        cellReference
                        column
                        columnName
                        row
                      }
                      lineRanges {
                        end
                        start
                        startColumn
                      }
                      offsetRanges {
                        end
                        start
                        startColumn
                      }
                      pages {
                        lineRange {
                          end
                          start
                          startColumn
                        }
                        offsetRange {
                          end
                          start
                          startColumn
                        }
                        pageNumber
                      }
                      records {
                        jsonPath
                        recodeIndex
                      }
                    }
                    type
                  }
                  totalCount
                }
                sizeClassified
                status {
                  code
                  reason
                }
              }
            }
            count
            createdAt
            description
            id
            partition
            policyDetails {
              action {
                actionType
                apiCallDetails {
                  api
                  apiServiceName
                  firstSeen
                  lastSeen
                }
              }
              actor {
                domainDetails {
                  domainName
                }
                ipAddressDetails {
                  ipAddressV4
                  ipCity {
                    name
                  }
                  ipCountry {
                    code
                    name
                  }
                  ipGeoLocation {
                    lat
                    lon
                  }
                  ipOwner {
                    asn
                    asnOrg
                    isp
                    org
                  }
                }
                userIdentity {
                  assumedRole {
                    accessKeyId
                    accountId
                    arn
                    principalId
                    sessionContext {
                      attributes {
                        creationDate
                        mfaAuthenticated
                      }
                      sessionIssuer {
                        accountId
                        arn
                        principalId
                        type
                        userName
                      }
                    }
                  }
                  awsAccount {
                    accountId
                    principalId
                  }
                  awsSerivce {
                    invokedBy
                  }
                  federatedUser {
                    accessKeyId
                    accountId
                    arn
                    principalId
                    sessionContext {
                      attributes {
                        creationDate
                        mfaAuthenticated
                      }
                      sessionIssuer {
                        accountId
                        arn
                        principalId
                        type
                        userName
                      }
                    }
                  }
                  iamUser {
                    accountId
                    arn
                    principalId
                    userName
                  }
                  root {
                    accountId
                    arn
                    principalId
                  }
                  type
                }
              }
            }
            region
            resourcesAffected {
              s3Bucket {
                allowsUnencryptedObjectUploads
                arn
                createdAt
                defaultServerSideEncryption {
                  encryptionType
                  kmsMasterKeyId
                }
                name
                owner {
                  displayName
                  id
                }
                publicAccess {
                  effectivePermission
                  permissionConfiguration {
                    accountLevelPermissions {
                      blockPublicAccess {
                        blockPublicAcls
                        blockPublicPolicy
                        ignorePublicAcls
                        restrictPublicBuckets
                      }
                    }
                    bucketLevelPermissions {
                      accessControlList {
                        allowsPublicReadAccess
                        allowsPublicWriteAccess
                      }
                      blockPublicAccess {
                        blockPublicAcls
                        blockPublicPolicy
                        ignorePublicAcls
                        restrictPublicBuckets
                      }
                      bucketPolicy {
                        allowsPublicReadAccess
                        allowsPublicWriteAccess
                      }
                    }
                  }
                }
                tags {
                  key
                  value
                }
              }
              s3Object {
                bucketArn
                eTag
                extension
                key
                lastModified
                path
                publicAccess
                serverSideEncryption {
                  encryptionType
                  kmsMasterKeyId
                }
                size
                storageClass
                tags {
                  key
                  value
                }
                versionId
              }
            }
            sample
            schemaVersion
            severity {
              description
              score
            }
            title
            type
            updatedAt
          }
        }
      }
    }
  }
`;
export default query;
