import { IDefaultRes } from 'apis/v2/schema';
import { BaseDummyRes } from '..';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ErrorCode } from '@Types/error';
import { IGenQrcodeRes, IGetStatusRes, IGetUserTokenRes, IInviteProjectMemberReq, IMemberVerifyRes, IPostVerifyRes, ISetMfaReq, IUpdatePasswordReq } from './schema';

const getStatus = (instance: AxiosInstance) => (data: { userToken: string }): Promise<AxiosResponse<IGetStatusRes, { userToken: string }>> => {
  return instance.request({
    method: 'GET',
    url: '/user/getStatus',
    data: data,
    headers: {
      'Authorization': `Bearer ${data.userToken}`
    }
  });
};
const verify = (instance: AxiosInstance) => (token: string ):Promise<AxiosResponse<IPostVerifyRes, string>> => {
  return instance.request({
    method: 'POST',
    url: '/user/verify',
    headers: {
      'authToken': `${token}`
    }
  });
};
const resetPw = (instance: AxiosInstance) => (data: {token:string, userPw: string }):Promise<AxiosResponse<IDefaultRes, { data: {token: string, userPw: string} }>> => {
    return instance.request({
      method: 'POST',
      url: '/user/resetPw',
      headers: {
        'authToken': data.token
      },
      data: {
        userPw: data.userPw
      },
  });
};

/** 프로필 모달 내 이메일 변경에서 새로 입력한 이메일로 인증요청 전송 */
const reqUpdateEmail = (instance: AxiosInstance) => (token: string, email:string):Promise<AxiosResponse<IDefaultRes, {token: string, email:string}>> => {
  return instance.request({
    method: 'POST',
    url: '/user/reqUpdateEmail',
    headers: {
      'Authorization': `Bearer ${token}`
    },
    data: { email: email }
  });
};

/** 이메일 변경 인증 이메일을 통해 시작되며, 이메일이 유효한지 인증하는 기능 (토큰으로 확인) */
const updateEmailVerify = (instance: AxiosInstance) => (token: string):Promise<AxiosResponse<IPostVerifyRes, string>> => {
  return instance.request({
    method: 'POST',
    url: '/user/updateEmailVerify',
    headers: {
      'authToken': `${token}`
    }
  });
};

/** 프로젝트 구성원 초대 */
const inviteProjectMember = (instance: AxiosInstance) => (token:string, data: IInviteProjectMemberReq[]):Promise<AxiosResponse<IDefaultRes, {token:string, data: IInviteProjectMemberReq}>> => {
  return instance.request({
    method: 'POST',
    url: '/user/inviteProjectMember',
    headers: { 'Authorization': `Bearer ${token}` },
    data: data
});
};

/* 초대받은 멤버 인증하는 기능 (토큰으로 확인) */
const invitedMemberVerify = (instance: AxiosInstance) => (token: string):Promise<AxiosResponse<IMemberVerifyRes, string>> => {
  return instance.request({
    method: 'POST',
    url: '/user/memberVerify',
    headers: { 'authToken': `${token}` }
  });
};

const getUserToken = (instance: AxiosInstance) => (token: string):Promise<AxiosResponse<IGetUserTokenRes, string>> => {
  return instance.request({
    method: 'POST',
    url: '/user/getUserToken',
    headers: { 'Authorization': `Bearer ${token}` }
  });
}; 

const genQrcode = (instance: AxiosInstance) => (token: string):Promise<AxiosResponse<IGenQrcodeRes, string>> => {
  return instance.request({
    method: 'GET',
    url: '/user/genQrcode',
    headers: { 'Authorization': `Bearer ${token}` }
  });
};

const setMfa = (instance: AxiosInstance) => (data: ISetMfaReq):Promise<AxiosResponse<IDefaultRes, ISetMfaReq>> => {
  return instance.request({
    method: 'POST',
    url: '/user/setMfa',
    data: {passcode: data.passcode},
    headers: { 'Authorization': `Bearer ${data.token}` }
  });
};

const unsetMfa = (instance: AxiosInstance) => (token: string):Promise<AxiosResponse<IDefaultRes, string>> => {
  return instance.request({
    method: 'GET',
    url: '/user/unsetMfa',
    headers: { 'Authorization': `Bearer ${token}` }
  });
};

const DummyGetStatus = (data: { userToken: string }): Promise<BaseDummyRes<IGetStatusRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetStatusRes>) => void) => {
    if (data.userToken === 'firstLogin') {
      resolve({
        data: {
          result: ErrorCode.EMAIL_UNAUTHORIZED,
          data: [
            {
              emailVerifyYn: false,
              terms: []
            }
          ]
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: [
            {
              emailVerifyYn: true,
              terms: []
            }
          ]
        }
      });
    }
  });

  return promise;
};
const DummyVerify = (token: string): Promise<BaseDummyRes<IPostVerifyRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IPostVerifyRes>) => void) => {
    if (token === 'success') {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: [{
            userToken: 'token'
          }]
        }
      });
    } else if (token === 'tokenerror') {
      resolve({
        data: {
          result: ErrorCode.EMAIL_TOKEN_ERROR,
          data: [{
            userToken: ''
          }]
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: [{
            userToken: ''
          }]
        }
      });
    }
  });

  return promise;
};
const DummyResetPw = (data: { token: string, userPw: string } ): Promise<BaseDummyRes<IDefaultRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IDefaultRes>) => void) => {
    if (data.token === 'success') {
      resolve({
        data: {
          result: ErrorCode.SUCCESS
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN
        }
      });
    }
  });

  return promise;
};

const DummyUpdatePassword = (data:IUpdatePasswordReq): Promise<BaseDummyRes<{data: undefined}>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<{data: undefined}>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: undefined,
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: undefined,
        }
      });
    }
  });

  return promise;
};

export const DummyUser = {
  getStatus: DummyGetStatus,
  verify: DummyVerify,
  resetPw: DummyResetPw,
  updatePassword: DummyUpdatePassword
};
const User = (instance: AxiosInstance) => ({
  getStatus: getStatus(instance),
  verify: verify(instance),
  resetPw: resetPw(instance),
  updatePassword: DummyUpdatePassword,
  reqUpdateEmail: reqUpdateEmail(instance),
  updateEmailVerify: updateEmailVerify(instance),
  inviteProjectMember: inviteProjectMember(instance),
  invitedMemberVerify: invitedMemberVerify(instance),
  getUserToken: getUserToken(instance),
  genQrcode: genQrcode(instance),
  setMfa: setMfa(instance),
  unsetMfa: unsetMfa(instance)
});
export default User;