import classNames from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './index.scss';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

type IMacieSearchBarCalendarPopupInputPropType = {
  id?: string;
  dateValue?: Date;
  placeholder?: string;
  error?: boolean;
  baseModalOpen?: boolean;
  onChangeDate: (newDate: Date) => any;
  maxDate?: Date;
  minDate?: Date;
};

const MacieSearchBarCalendarPopupInput = ({
  id,
  dateValue,
  placeholder,
  error,
  baseModalOpen,
  onChangeDate,
  maxDate,
  minDate,
}: IMacieSearchBarCalendarPopupInputPropType) => {
  const [open, setOpen] = useState(false);
  const [bg, setBg] = useState({
    top: 0,
    left: 0,
  });

  const changeDateValue = (value: Value) => {
    onChangeDate(value as Date);
    closeAll();
  };
  const openMenu = () => {
    const calendarBgEl = document.getElementById(`calendar-bg-${id}`);
    if (!calendarBgEl) return;

    if (calendarBgEl.classList.contains('none')) {
      calendarBgEl.classList.remove('none');
      setOpen(true);
    } else {
      calendarBgEl.classList.remove('none');
    }
  };

  const closeAll = () => {
    const calendarBgEl = document.getElementById(`calendar-bg-${id}`);
    if (!calendarBgEl) return;

    calendarBgEl.classList.add('none');
  };

  return (
    <div className="macie-search-dropdown-calendar-popup" id={`${id}-calendar-card`}>
      <button
        type="button"
        id={id}
        className={`${error && 'error'}`}
        onClick={e => {
          e.stopPropagation();
          openMenu();

          const calendarCardEl = document.getElementById(`${id}-calendar-card`);
          const calendarEl = document.getElementById('calendar');

          if (!calendarCardEl || !calendarEl) return;
          if (
            window.innerHeight - e.currentTarget.getBoundingClientRect().bottom >
            calendarCardEl.offsetHeight + calendarEl.offsetHeight
          ) {
            setBg({
              top: e.currentTarget.getBoundingClientRect().bottom + 8,
              left: e.currentTarget.getBoundingClientRect().left,
            });
          } else {
            setBg({
              top:
                e.currentTarget.getBoundingClientRect().bottom -
                (calendarCardEl.offsetHeight + calendarEl.offsetHeight) -
                8,
              left: e.currentTarget.getBoundingClientRect().left,
            });
          }
        }}
      >
        <div className={classNames('date-display', !dateValue && 'no-value')}>
          {dateValue ? moment(dateValue).format('YYYY-MM-DD') : placeholder}
        </div>
        <div className="calendar-icon" />
      </button>
      <div id={`calendar-bg-${id}`} className="calendar-bg none" onClick={closeAll}>
        <div
          id="calendar"
          className="flex"
          style={{
            top: bg.top,
            left: bg.left,
          }}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {open && (
            <Calendar
              onChange={changeDateValue}
              value={dateValue}
              defaultActiveStartDate={new Date()}
              calendarType="iso8601"
              minDetail="month"
              locale="en"
              formatDay={(_locale, date) => moment(date).format('D')}
              formatShortWeekday={(_locale, date) => moment(date).format('dd')}
              maxDate={maxDate}
              minDate={minDate}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MacieSearchBarCalendarPopupInput;
