import { gql } from '@apollo/client';

const query = gql`
mutation deleteAwsTLSInspectionConfiguration($reqData: AwsDeleteTlsInspectionConfigurationRequest, $cloudId: Long, $region: String) {
  deleteAwsTLSInspectionConfiguration(
    awsDeleteTlsInspectionConfigurationRequest: $reqData
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsDeleteTlsInspectionConfigurationResponse {
        tlsInspectionConfigurationResponse {
          tlsInspectionConfigurationArn
          tlsInspectionConfigurationName
          tlsInspectionConfigurationId
          tlsInspectionConfigurationStatus
          description
          tags {
            key
            value
          }
          lastModifiedTime
          numberOfAssociations
          encryptionConfiguration {
            keyId
            type
          }
          certificates {
            certificateArn
            certificateSerial
            status
            statusMessage
          }
          certificateAuthority {
            certificateArn
            certificateSerial
            status
            statusMessage
          }
        }
      }
    }
  }
}
`;
export default query;
