import { useState, useEffect, useMemo, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import Icon from 'components/v2/atoms/Icon';
import IconEditRuleVariable from 'assets/svgs/v3/ico_policy_variables.svg';
import './index.scss';

interface IEditRuleVariablesModalProps extends IBaseModalProps {
  header: string;
  note?: string;
  currentIpDatas: IpRowData[];
  currentPortDatas: PortRowData[];
  columnIpVariables: string[];
  columnPortVariables: string[];
  onSave: (oldIps: IpRowData[], newIps: IpRowData[], oldPorts: PortRowData[], newPorts: PortRowData[]) => void;
}

export interface IpRowData {
  index: number;
  ipName: string;
  ipValue: string;
}

export interface PortRowData {
  index: number;
  portName: string;
  portValue: string;
}

const EditRuleVariablesModal = ({
  header,
  note,
  currentIpDatas,
  currentPortDatas,
  columnIpVariables,
  columnPortVariables,
  onSave,
  ...baseModalProps
}: IEditRuleVariablesModalProps) => {
  const [ipVariableRows, setIpVariablesRows] = useState<IpRowData[]>([]);
  const [currentIpRows, setCurrentIpRows] = useState<IpRowData[]>([]);
  const [portVariableRows, setPortVariablesRows] = useState<PortRowData[]>([]);
  const [currentPortRows, setCurrentPortRows] = useState<PortRowData[]>([]);

  useEffect(() => {
    if (baseModalProps.open) {
      if (currentIpDatas.length == 0) setIpVariablesRows([{ index: 0, ipName: '', ipValue: '' }]);
      if (currentPortDatas.length == 0) setPortVariablesRows([{ index: 0, portName: '', portValue: '' }]);
      setCurrentIpRows(currentIpDatas);
      setCurrentPortRows(currentPortDatas);
    } else {
      setIpVariablesRows([]);
      setCurrentIpRows([]);
    }
  }, [baseModalProps.open, currentIpDatas]);

  const handleCreateIpSet = () => {
    setIpVariablesRows(prevState => {
      const newState = prevState.concat({ index: ipVariableRows.length, ipName: '', ipValue: '' });
      return newState;
    });
  };

  const handleDeleteIp = (indexToRemove: number) => {
    const newData = ipVariableRows.filter((_, index) => index != indexToRemove);
    setIpVariablesRows(newData);
  };

  const handleIpChange = (index: number, value: string, propName: keyof IpRowData) => {
    const newRows = ipVariableRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setIpVariablesRows(newRows);
  };

  const handleCurrentIpDelete = (indexToRemove: number) => {
    const newData = currentIpRows.filter((_, index) => index != indexToRemove);
    setCurrentIpRows(newData);
  };

  const handleCurrentIpChange = (index: number, value: string, propName: keyof IpRowData) => {
    const newRows = currentIpRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setCurrentIpRows(newRows);
  };

  const handleCreatePort = () => {
    setPortVariablesRows(prevState => {
      const newState = prevState.concat({ index: portVariableRows.length, portName: '', portValue: '' });
      return newState;
    });
  };

  const handleDeletePort = (indexToRemove: number) => {
    const newData = portVariableRows.filter((_, index) => index != indexToRemove);
    setPortVariablesRows(newData);
  };

  const handlePortChange = (index: number, value: string, propName: keyof PortRowData) => {
    const newRows = portVariableRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setPortVariablesRows(newRows);
  };

  const handleCurrentPortDelete = (indexToRemove: number) => {
    const newData = currentPortRows.filter((_, index) => index != indexToRemove);
    setCurrentPortRows(newData);
  };

  const handleCurrentPortChange = (index: number, value: string, propName: keyof PortRowData) => {
    const newRows = currentPortRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setCurrentPortRows(newRows);
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditRuleVariable} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-rule-variables-model">
        {!!note && <p className="note">{note}</p>}
        <div className="ip-set-variables">
          <div className="sub-title">IP set variables</div>

          {columnIpVariables && (
            <div className="ip-row-container">
              <div className="ip-row-input">
                {columnIpVariables.map((column, index) => {
                  return (
                    <div key={`edit-ip-column-${index}`} className="column">
                      <p>{column}</p>
                    </div>
                  );
                })}
              </div>
              <div className="ip-row-btn"></div>
            </div>
          )}

          {currentIpRows && (
            <>
              {currentIpRows.map((row, index) => (
                <div className="ip-row-container" key={`edit-ip-row-${index}`}>
                  <div className="ip-row-input">
                    <div className="column">
                      <div className="ip-rule-textarea">
                        <textarea
                          value={row.ipName}
                          onChange={event => {
                            handleCurrentIpChange(index, event?.target?.value, 'ipName');
                          }}
                        />
                      </div>
                    </div>
                    <div className="column">
                      <div className="ip-rule-textarea">
                        <textarea
                          value={row.ipValue}
                          onChange={event => {
                            handleCurrentIpChange(index, event?.target?.value, 'ipValue');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ip-row-btn">
                    <button className="button" onClick={() => handleCurrentIpDelete(index)}>
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}

          {ipVariableRows && (
            <>
              {ipVariableRows.map((row, index) => (
                <div className="ip-row-container" key={`udpate-tags-table-new-row-${index}`}>
                  <div className="ip-row-input">
                    <div className="column">
                      <div className="ip-rule-textarea">
                        <textarea
                          value={row.ipName}
                          onChange={event => {
                            handleIpChange(index, event?.target?.value, 'ipName');
                          }}
                        />
                      </div>
                    </div>
                    <div className="column">
                      <div className="ip-rule-textarea">
                        <textarea
                          value={row.ipValue}
                          onChange={event => {
                            handleIpChange(index, event?.target?.value, 'ipValue');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ip-row-btn">
                    <button className="button" onClick={() => handleDeleteIp(index)}>
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}

          <div className="add-new-ip-container">
            <button className="btn" onClick={handleCreateIpSet}>
              Add another IP set variable
            </button>
          </div>
        </div>

        <div className="port-variables">
          <div className="sub-title">Port variables</div>

          {columnPortVariables && (
            <div className="port-row-container">
              <div className="port-row-input">
                {columnPortVariables.map((column, index) => {
                  return (
                    <div key={`edit-port-column-${index}`} className="column">
                      <p>{column}</p>
                    </div>
                  );
                })}
              </div>
              <div className="port-row-btn"></div>
            </div>
          )}

          {currentPortRows && (
            <>
              {currentPortRows.map((row, index) => (
                <div className="port-row-container" key={`edit-port-row-${index}`}>
                  <div className="port-row-input">
                    <div className="column">
                      <div className="port-rule-textarea">
                        <textarea
                          value={row.portName}
                          onChange={event => {
                            handleCurrentPortChange(index, event.target.value, 'portName');
                          }}
                        />
                      </div>
                    </div>
                    <div className="column">
                      <div className="port-rule-textarea">
                        <textarea
                          value={row.portValue}
                          onChange={event => {
                            handleCurrentPortChange(index, event.target.value, 'portValue');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="port-row-btn">
                    <button className="button" onClick={() => handleCurrentPortDelete(index)}>
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}

          {portVariableRows && (
            <>
              {portVariableRows.map((row, index) => (
                <div className="port-row-container" key={`udpate-port-new-row-${index}`}>
                  <div className="port-row-input">
                    <div className="column">
                      <div className="port-rule-textarea">
                        <textarea
                          value={row.portName}
                          onChange={event => {
                            handlePortChange(index, event.target.value, 'portName');
                          }}
                        />
                      </div>
                    </div>
                    <div className="column">
                      <div className="port-rule-textarea">
                        <textarea
                          value={row.portValue}
                          onChange={event => {
                            handlePortChange(index, event.target.value, 'portValue');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="port-row-btn">
                    <button className="button" onClick={() => handleDeletePort(index)}>
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}

          <div className="add-new-port-container">
            <button className="btn" onClick={handleCreatePort}>
              Add another ports variable
            </button>
          </div>
        </div>
        <div className="cancle-save-container">
          <button className="cancel-btn" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button
            className="btn save-btn"
            onClick={async () => {
              await onSave(currentIpRows, ipVariableRows, currentPortRows, portVariableRows);
              baseModalProps?.onClose?.();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditRuleVariablesModal;
