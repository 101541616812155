import CloseIcon from 'assets/svgs/v2/ico_close.svg';
import Icon from 'components/v2/atoms/Icon';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import { useEffect, useMemo, useState } from 'react';
import { RESOURCE_COVERAGE_SUMMARY_TAB } from '../../../configs';
import { IResourceCoverageSummaryProps } from '../../../types';
import BucketDetails from './ListUp/BucketDetails';
import ObjectSamples from './ListUp/ObjectSamples';
import SensitiveDataDiscovery from './ListUp/SensitiveDataDiscovery';
import Sensitivity from './ListUp/Sensitivity';
import './index.scss';

const ResourceCoverageSummary = (props: IResourceCoverageSummaryProps) => {
  const { region, cloudId, selectedResourceCoverage } = props;

  const [selectedTab, setSelectedTab] = useState<IMgdTabProps>(RESOURCE_COVERAGE_SUMMARY_TAB[1]);

  const renderTab = useMemo(() => {
    switch (selectedTab) {
      case RESOURCE_COVERAGE_SUMMARY_TAB[0]:
        return <Sensitivity {...props} />;
      case RESOURCE_COVERAGE_SUMMARY_TAB[1]:
        return <BucketDetails {...props} />;
      case RESOURCE_COVERAGE_SUMMARY_TAB[2]:
        return <ObjectSamples {...props} />;
      case RESOURCE_COVERAGE_SUMMARY_TAB[3]:
        return <SensitiveDataDiscovery {...props} />;
      default:
        return null;
    }
  }, [selectedTab, cloudId, region, JSON.stringify(selectedResourceCoverage)]);

  useEffect(() => {
    // reset selected tab if change selected row item
    setSelectedTab(RESOURCE_COVERAGE_SUMMARY_TAB[1]);
  }, [JSON.stringify(props.selectedResourceCoverage)]);

  return (
    <div className="mgd-resource-summary">
      <div className="mgd-resource-summary-container">
        <div className="mgd-resource-summary-header flex a-center j-between">
          <p className="mgd-resource-summary-name">{props.selectedResourceCoverage.bucketName}</p>
          <Icon width={10} height={10} src={CloseIcon} onClick={props.onClose} />
        </div>
        <div className="mgd-resource-tab detail-tab flex a-center">
          {RESOURCE_COVERAGE_SUMMARY_TAB.map(tab => (
            <button
              key={tab.id}
              className={`detail-tab-items ${selectedTab?.id === tab.id && 'active'}`}
              onClick={() => setSelectedTab(tab)}
            >
              <p>{tab.name}</p>
            </button>
          ))}
        </div>
        <div>{renderTab}</div>
      </div>
    </div>
  );
};

export default ResourceCoverageSummary;
