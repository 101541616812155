import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsResourcePolicyVariables {
  cloudId: number;
  region: string;
  reqData: {
    secretId?: string;
  };
}

export interface IAwsResourcePolicyResponseData {
  getAwsResourcePolicy: IGqlResponseNewData<any>;
}

const lazyGetAwsResourcePolicy = () =>
  useLazyQuery<IAwsResourcePolicyResponseData, IAwsResourcePolicyVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsResourcePolicy;
