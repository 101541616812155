import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import Input from 'pages/v2/Organ/Management/components/InputLabel';
import { useState } from 'react';
import './index.scss';
import SectionContainer from 'pages/v2/Organ/Management/components/SectionContainer';
import InputGroup from 'pages/v2/Organ/Management/components/InputGroup';

const CredentialPanel = (props: any) => {
  const { creationData, handleValueChanged, errors } = props;
  const { userName, password } = creationData ?? {};
  const [showPass, setShowPass] = useState<boolean>(false);

  return (
    <SectionContainer title={'Credentials'} customStyle="credential-panel">
      <InputGroup>
        <Input
          title={'Username'}
          value={userName}
          type="text"
          onValueChanged={value => handleValueChanged('userName', value)}
          error={errors?.userName}
        />
        <Input
          title={'Password'}
          value={password}
          type={showPass ? 'text' : 'password'}
          onValueChanged={value => handleValueChanged('password', value)}
          error={errors?.password}
        />
        <div id="input-container">
          <div className="row-content">
            <div className="left-column-content" />
            <div className="right-column-content">
              <div className="description">
                <div className="checkbox-content">
                  <CheckboxAtom id="showPassword-checkbox" checked={showPass} onchange={() => setShowPass(!showPass)} />
                  <p
                    className="text-content"
                    onClick={() => {
                      setShowPass(!showPass);
                    }}
                  >
                    Show password
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </InputGroup>
    </SectionContainer>
  );
};
export default CredentialPanel;
