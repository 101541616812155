import RequiredIcon from 'assets/svgs/v2/ico_require.svg';
import InputAtom from 'components/v2/atoms/InputAtom';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import lazyTestAwsCustomDataIdentifier from 'graphql/mutations/testAwsCustomDataIdentifier';
import { ICreateAllowListData, ICreateAllowListErrors } from '../types';
import { useState } from 'react';

type RegularExpressionProps = {
  cloudId: number;
  region: string;
  creationData: ICreateAllowListData;
  updateCreationData: (data: ICreateAllowListData) => void;
  error: ICreateAllowListErrors;
};

const RegularExpression = ({ cloudId, region, creationData, updateCreationData, error }: RegularExpressionProps) => {
  const { name, description, regularExpression } = creationData;
  const [testRegular] = lazyTestAwsCustomDataIdentifier();
  const [sampleText, setSampleText] = useState('');
  const [matchCount, setMatchCount] = useState(0);
  const handleTest = async () => {
    const { data } = await testRegular({
      variables: {
        cloudId,
        region,
        request: {
          regex: regularExpression,
          sampleText,
        },
      },
    });
    if (!data?.testAwsCustomDataIdentifier?.data?.length) return;
    setMatchCount(data?.testAwsCustomDataIdentifier?.data[0].matchCount);
  };

  return (
    <div className="allow-lists-container" style={{ display: 'flex' }}>
      <div style={{ width: '60%' }}>
        <div className="allow-list-title">
          <p>List settings</p>
          <p className="allow-list-sub-title">Enter settings for the allow list.</p>
        </div>
        <div className="allow-list-content">
          <div className="detail-content-row">
            <div className="detail-content-column-left">
              Name
              <img style={{ marginLeft: 5 }} src={RequiredIcon} width={4} height={4} />
            </div>
            <div className="detail-content-column-right">
              <div className="full-width">
                <InputAtom
                  value={name}
                  noClear={true}
                  onChangeValue={(value: string) => updateCreationData({ ...creationData, name: value })}
                  error={error.name ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="detail-content-row">
            <div className="detail-content-column-left">Description - optional</div>
            <div className="detail-content-column-right">
              <div className="full-width">
                <InputAtom
                  value={description}
                  noClear={true}
                  onChangeValue={(value: string) => updateCreationData({ ...creationData, description: value })}
                />
              </div>
            </div>
          </div>
          <div className="detail-content-row">
            <div className="detail-content-column-left">
              Regular expression
              <img style={{ marginLeft: 5 }} src={RequiredIcon} width={4} height={4} />
            </div>
            <div className="detail-content-column-right">
              <p>
                Enter the regular expression (regex) that defines the text pattern to ignore. The regex can contain as
                many as 512 characters.
              </p>
              <div className="full-width">
                <InputAtom
                  value={regularExpression}
                  noClear={true}
                  onChangeValue={(value: string) => updateCreationData({ ...creationData, regularExpression: value })}
                  error={error.regular ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
        <div className="allow-list-title">
          <p>Evaluate</p>
          <p className="allow-list-sub-title">Optionally test and refine the regular expression with sample data.</p>
        </div>
        <div className="allow-list-content" style={{ flex: 1 }}>
          <div className="detail-content-row">
            <div className="detail-content-column-left">Sample data</div>
            <div className="detail-content-column-right">
              <div className="full-width">
                <TextareaAtom
                  value={sampleText}
                  placeholder={
                    'Enter sample data to test the regular expression.\nThe example data can contain up to 1,000 characters'
                  }
                  noClear={true}
                  onChangeValue={(value: string) => setSampleText(value)}
                  className="text-start"
                />
              </div>
            </div>
            <div className="allow-list-sub-title">{matchCount} matched</div>
          </div>
          <div className="detail-content-row a-center" style={{ alignItems: 'center' }}>
            <button className="button next-button" style={{ margin: 0, width: 'max-content' }} onClick={handleTest}>
              Test
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegularExpression;
