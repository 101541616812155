import { gql } from '@apollo/client';

const query = gql`
  mutation putAwsKeyPolicy($reqData: AwsPutKeyPolicyRequest, $cloudId: Long, $region: String) {
    putAwsKeyPolicy(putKeyPolicyRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
    }
  }
`;
export default query;
