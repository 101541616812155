import { gql } from '@apollo/client';

const query = gql`
  query getAwsListFindings($reqData: AwsListFindingsRequest, $cloudId: Long, $region: String) {
    getAwsListFindings(listFindingsRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      nextToken
      data {
        ... on AwsListFindingsResponse {
          findingIds
        }
      }
    }
  }
`;
export default query;
