import './index.scss';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import { appendKBUnit, capitalizeFirstLetter, convertBytesToKB } from 'pages/v2/Organ/Management/Macie/Function';
import { IResourceCoverageSummaryProps } from 'pages/v2/Organ/Management/Macie/types';
import { useMemo } from 'react';
import DetailItem, { DetailTabPropsType } from '../../../../DetailItem';
import { getStrValueOrNull } from 'utils/Common';

interface IBucketDetailsProps extends IResourceCoverageSummaryProps {}

const BucketDetails = ({ selectedResourceCoverage }: IBucketDetailsProps) => {

  const detailInfo: DetailTabPropsType[] = useMemo(() => {
    const overview: IMgdDetailKeyValueProps[] = [
      { title: 'Account ID', description: selectedResourceCoverage.accountId },
      { title: 'Region', description: selectedResourceCoverage.region },
      { title: 'ARN', description: selectedResourceCoverage.bucketArn },
      { title: 'Name', description: selectedResourceCoverage.bucketName },
      { title: 'Created', description: selectedResourceCoverage.bucketCreatedAt },
      { title: 'Versioning', description: selectedResourceCoverage.versioning ? 'Enabled' : 'Disabled' },
      { title: 'Last updated', description: selectedResourceCoverage.lastUpdated },
      { title: 'Latest automated discovery run', description: selectedResourceCoverage.lastAutomatedDiscoveryTime },
    ];

    const objetStatistics: IMgdDetailKeyValueProps[] = [
      { title: 'Total count', description: selectedResourceCoverage.objectCount },
      {
        title: 'Total storage size',
        description: appendKBUnit(convertBytesToKB(selectedResourceCoverage.classifiableSizeInBytes).toFixed(1)),
      },
      {
        title: 'Total compressed size',
        description: appendKBUnit(convertBytesToKB(selectedResourceCoverage.sizeInBytesCompressed).toFixed(1)),
      },
    ];

    const classifiableObject: IMgdDetailKeyValueProps[] = [
      { title: 'Total count', description: selectedResourceCoverage.classifiableObjectCount },
      {
        title: 'Total storage size',
        description: appendKBUnit(convertBytesToKB(selectedResourceCoverage.classifiableSizeInBytes).toFixed(1)),
      },
    ];

    const unclassifiableObjects: IMgdDetailKeyValueProps[] = [
      { title: 'Total count', description: selectedResourceCoverage.unclassifiableObjectCount.total },
      {
        title: 'Total storage size',
        description: appendKBUnit(convertBytesToKB(selectedResourceCoverage.unclassifiableObjectSizeInBytes.total)),
      },
    ];

    const unclassifiableObjectsStorageClass: IMgdDetailKeyValueProps[] = [
      { title: 'count', description: selectedResourceCoverage.unclassifiableObjectCount.storageClass },
      {
        title: 'Storage size',
        description: appendKBUnit(convertBytesToKB(selectedResourceCoverage.unclassifiableObjectSizeInBytes.storageClass).toFixed(1)),
      },
    ];

    const unclassifiableObjectsFileType: IMgdDetailKeyValueProps[] = [
      { title: 'count', description: selectedResourceCoverage.unclassifiableObjectCount.fileType },
      {
        title: 'Storage size',
        description: appendKBUnit(convertBytesToKB(selectedResourceCoverage.unclassifiableObjectSizeInBytes.fileType).toFixed(1)),
      },
    ];

    const objectsByEncryptionType: IMgdDetailKeyValueProps[] = [
      { title: 'Customer provided', description: selectedResourceCoverage.objectCountByEncryptionType.customerManaged },
      { title: 'AWS KMS managed', description: selectedResourceCoverage.objectCountByEncryptionType.kmsManaged },
      { title: 'Amazon S3 managed', description: selectedResourceCoverage.objectCountByEncryptionType.s3Managed },
      { title: 'No encryption', description: selectedResourceCoverage.objectCountByEncryptionType.unencrypted },
      { title: 'Unknown', description: selectedResourceCoverage.objectCountByEncryptionType.unknown },
    ];

    const serverSideEncryption: IMgdDetailKeyValueProps[] = [
      {
        title: 'Encryption required by bucket policy',
        description: selectedResourceCoverage.allowsUnencryptedObjectUploads == 'TRUE' ? 'No' : 'Yes',
      },
      { title: 'Default encryption', description: selectedResourceCoverage.serverSideEncryption.type },
      {
        title: 'AWS KMS key',
        description: getStrValueOrNull(selectedResourceCoverage.serverSideEncryption.kmsMasterKeyId),
      },
    ];

    const sensitiveDataDiscovery: IMgdDetailKeyValueProps[] = [
      {
        title: 'Actively monitored by job',
        description: selectedResourceCoverage.jobDetails.isMonitoredByJob === 'TRUE' ? 'Yes' : 'No',
      },
    ];

    const publicAccess: IMgdDetailKeyValueProps[] = [
      { title: 'Effective permission', description: capitalizeFirstLetter(selectedResourceCoverage.publicAccess.effectivePermission)},
    ];

    const accountLevelSettings: IMgdDetailKeyValueProps[] = [
      {
        title: 'Block public access through ACLs',
        description: selectedResourceCoverage.publicAccess.permissionConfiguration.accountLevelPermissions
          .blockPublicAccess.blockPublicAcls
          ? 'On'
          : 'Off',
      },
      {
        title: 'Block public access through bucket policies',
        description: selectedResourceCoverage.publicAccess.permissionConfiguration.accountLevelPermissions
          .blockPublicAccess.blockPublicPolicy
          ? 'On'
          : 'Off',
      },
      {
        title: 'Ignore ACLs that grant public access',
        description: selectedResourceCoverage.publicAccess.permissionConfiguration.accountLevelPermissions
          .blockPublicAccess.ignorePublicAcls
          ? 'On'
          : 'Off',
      },
      {
        title: 'Restrict access to public buckets',
        description: selectedResourceCoverage.publicAccess.permissionConfiguration.accountLevelPermissions
          .blockPublicAccess.restrictPublicBuckets
          ? 'On'
          : 'Off',
      },
    ];

    const bucketLevelSettings: IMgdDetailKeyValueProps[] = [
      {
        title: 'Block public access through ACLs',
        description: selectedResourceCoverage.publicAccess.permissionConfiguration.bucketLevelPermissions
          .blockPublicAccess.blockPublicAcls
          ? 'On'
          : 'Off',
      },
      {
        title: 'Block public access through bucket policies',
        description: selectedResourceCoverage.publicAccess.permissionConfiguration.bucketLevelPermissions
          .blockPublicAccess.blockPublicPolicy
          ? 'On'
          : 'Off',
      },
      {
        title: 'Ignore ACLs that grant public access',
        description: selectedResourceCoverage.publicAccess.permissionConfiguration.bucketLevelPermissions
          .blockPublicAccess.ignorePublicAcls
          ? 'On'
          : 'Off',
      },
      {
        title: 'Restrict access to public buckets',
        description: selectedResourceCoverage.publicAccess.permissionConfiguration.bucketLevelPermissions
          .blockPublicAccess.restrictPublicBuckets
          ? 'On'
          : 'Off',
      },
    ];

    const bucketLevelACLSettings: IMgdDetailKeyValueProps[] = [
      {
        title: 'Allow public read access',
        description: selectedResourceCoverage.publicAccess.permissionConfiguration.bucketLevelPermissions
          .accessControlList.allowsPublicReadAccess
          ? 'On'
          : 'Off',
      },
      {
        title: 'Allow public write access',
        description: selectedResourceCoverage.publicAccess.permissionConfiguration.bucketLevelPermissions
          .accessControlList.allowsPublicWriteAccess
          ? 'On'
          : 'Off',
      },
    ];

    const bucketLevelPolicySettings: IMgdDetailKeyValueProps[] = [
      {
        title: 'Allow public read access',
        description: selectedResourceCoverage.publicAccess.permissionConfiguration.bucketLevelPermissions.bucketPolicy
          .allowsPublicReadAccess
          ? 'On'
          : 'Off',
      },
      {
        title: 'Allow public write access',
        description: selectedResourceCoverage.publicAccess.permissionConfiguration.bucketLevelPermissions.bucketPolicy
          .allowsPublicWriteAccess
          ? 'On'
          : 'Off',
      },
    ];

    const replication: IMgdDetailKeyValueProps[] = [
      { title: 'Replicated', description: selectedResourceCoverage.replicationDetails.replicated ? 'Yes' : 'No' },
      {
        title: 'Replicated externally',
        description: selectedResourceCoverage.replicationDetails.replicatedExternally ? 'Yes' : 'No',
      },
    ];

    const replicationAccounts: IMgdDetailKeyValueProps[] =
      selectedResourceCoverage.replicationDetails.replicationAccounts.length > 0
        ? selectedResourceCoverage.replicationDetails.replicationAccounts.map(account => ({
            title: account,
            description: '',
          }))
        : [{ title: 'None', description: '' }];

    return [
      { title: 'Overview', data: overview },
      {
        title: 'Object statistics',
        description: 'Macie automatically retrieves object metadata on a daily basis. Optionally refresh the data now.',
        data: objetStatistics,
      },
      { title: 'Classifiable objects', data: classifiableObject },
      { title: 'Unclassifiable objects', data: unclassifiableObjects },
      { title: 'Unclassifiable objects: Storage class', data: unclassifiableObjectsStorageClass },
      { title: 'Unclassifiable objects: File type', data: unclassifiableObjectsFileType },
      { title: 'Objects by encryption type', data: objectsByEncryptionType },
      { title: 'Server-side encryption', data: serverSideEncryption },
      { title: 'Sensitive data discovery', data: sensitiveDataDiscovery },
      { title: 'Public access', data: publicAccess },
      { title: 'Account-level settings', data: accountLevelSettings },
      { title: 'Bucket-level settings', data: bucketLevelSettings },
      { title: 'Bucket-level ACL settings', data: bucketLevelACLSettings },
      { title: 'Bucket-level policy settings', data: bucketLevelPolicySettings },
      { title: 'Replication', data: replication },
      { title: 'Replication accounts', data: replicationAccounts },
    ];
  }, [JSON.stringify(selectedResourceCoverage)]);

  return (
    <div className="mgd-bucket-details-tab">
      {detailInfo.map((item, index) => (
        <DetailItem key={index} {...item} />
      ))}
    </div>
  );
};

export default BucketDetails;
