import { gql } from '@apollo/client';

const query = gql`
mutation deleteAwsFirewallPolicy($request: AwsDeleteFirewallPolicyRequest, $cloudId: Long, $region: String) {
  deleteAwsFirewallPolicy(deleteFirewallPolicyRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsDeleteFirewallPolicyResponse {
        		firewallPolicyResponse {
              firewallPolicyName
              firewallPolicyArn
              firewallPolicyId
              description
              firewallPolicyStatus
              tags {
                key
                value
              }
              consumedStatelessRuleCapacity
              consumedStatefulRuleCapacity
              numberOfAssociations
              encryptionConfiguration {
                keyId
                type
              }
              lastModifiedTime
            }
        }
      }
    }
  }
`;

export default query;