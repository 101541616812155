import { gql } from '@apollo/client';

const query = gql`
  query getAwsValidateResourcePolicy($reqData: AwsValidateResourcePolicyRequest, $cloudId: Long, $region: String) {
    getAwsValidateResourcePolicy(validateResourcePolicyRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsValidateResourcePolicyResponse {
          policyValidationPassed
          validationErrors {
            checkName
            errorMessage
          }
        }
      }
    }
  }
`;
export default query;
