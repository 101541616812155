import './index.scss';
import { useEffect, useState, useMemo } from 'react';
import Table from 'components/v2/dataDisplay/Table';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import { OrderDirection, RowType } from '@Types/v2/Table';
import {
  FORMAT_ACTIONS,
  FORMAT_DEFAULT_ACTIONS,
  FORMAT_RULE_ORDER,
  FORMAT_STREAM_EXCEPTION_POLICY,
} from '../FirewallPolicyCreation/configs';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';

interface IMgdDetailKeyValueProps {
  id?: string;
  title?: string;
  description: any;
  visible?: boolean;
}

const rowStatefulRuleGroupDummy: RowType[] = [
  { id: 1, priority: 123, name: 'test-stateless-now', capacity: '300' },
  { id: 2, priority: 123, name: 'test-stateless-now', capacity: '400' },
];

const rowStatelessRuleGroupDummy: RowType[] = [
  { id: 1, priority: 123, name: 'test-stateless-now', capacity: '300' },
  { id: 2, priority: 123, name: 'test-stateless-now', capacity: '400' },
];

const rowsTagsDummy: RowType[] = [
  { id: 1, key: 'a', value: 'c' },
  { id: 2, key: 'b', value: 'd' },
];

type FirewallPoliciesPreviewCreationProps = {
  firewallPolicy: any;
  statelessRuleGroupRows: any;
  statefulRuleGroupRows: any;
};

const FirewallPoliciesPreviewCreation = ({
  firewallPolicy,
  statelessRuleGroupRows,
  statefulRuleGroupRows,
}: FirewallPoliciesPreviewCreationProps) => {
  const {
    name,
    description,
    streamExceptionPolicy,
    ruleAction,
    ruleActionFullPackets,
    ruleActionFragmentedPackets,
    statefulRuleEvaluationOrder,
    statefulRuleDropAction,
    alertOptionAll,
    alertOptionEstablished,
    statelessRuleGroupCapacityUnitConsumed,
    statefulRuleGroupCapacityUnitConsumed,
    policyVariables,
    customizeEncryptionSettings,
    tlsInspectionConfiguration,
    tags,
  } = firewallPolicy;

  const [currentRulePage, setCurrentRulePage] = useState<number>(1);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [statelessTblTotal, setStatelessTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [statelessTablePagination, setStatelessTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [statefulTblTotal, setStatefulTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [statefulTablePagination, setStatefulTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  useEffect(() => {
    setStatelessTblTotal({
      totalPage: Math.ceil(statelessRuleGroupRows.length / statelessTablePagination.itemPerPage),
      totalElement: statelessRuleGroupRows.length,
    });
  }, [statelessRuleGroupRows?.length, statelessTablePagination]);

  useEffect(() => {
    setStatefulTblTotal({
      totalPage: Math.ceil(statefulRuleGroupRows.length / statefulTablePagination.itemPerPage),
      totalElement: statefulRuleGroupRows.length,
    });
  }, [statefulRuleGroupRows?.length, statefulTablePagination]);

  const statelessRowsCurrentPage = useMemo((): RowType[] => {
    const startIndex = (statelessTablePagination.currentPage - 1) * statelessTablePagination.itemPerPage;
    const endIndex = startIndex + statelessTablePagination.itemPerPage;

    setStatelessTblTotal({
      totalPage: Math.ceil(statelessRuleGroupRows.length / statelessTablePagination.itemPerPage),
      totalElement: statelessRuleGroupRows.length,
    });

    return orderAlphabetical(
      statelessRuleGroupRows,
      statelessTablePagination.target,
      statelessTablePagination.direction,
    ).slice(startIndex, endIndex);
  }, [statelessRuleGroupRows, statelessTablePagination]);

  const statefulRowsCurrentPage = useMemo((): RowType[] => {
    const startIndex = (statefulTablePagination.currentPage - 1) * statefulTablePagination.itemPerPage;
    const endIndex = startIndex + statefulTablePagination.itemPerPage;

    setStatefulTblTotal({
      totalPage: Math.ceil(statefulRuleGroupRows.length / statefulTablePagination.itemPerPage),
      totalElement: statefulRuleGroupRows.length,
    });

    return orderAlphabetical(
      statefulRuleGroupRows,
      statefulTablePagination.target,
      statefulTablePagination.direction,
    ).slice(startIndex, endIndex);
  }, [statefulRuleGroupRows, statefulTablePagination]);

  const firewallPolicyDetailDummy: IMgdDetailKeyValueProps[] = [
    { id: '1', title: 'Name', description: name },
    { id: '2', title: 'Description', description: description },
    { id: '3', title: 'Stream exception policy', description: FORMAT_STREAM_EXCEPTION_POLICY[streamExceptionPolicy] },
  ];

  const ruleGroupsDummy: IMgdDetailKeyValueProps[] = [
    { id: '1', title: 'Actions for full packets', description: FORMAT_ACTIONS[ruleActionFullPackets] },
    {
      id: '2',
      title: 'Actions for fragmented packets',
      description: FORMAT_ACTIONS[ruleActionFragmentedPackets],
      visible: ruleAction !== 'Different',
    },
  ];

  const statefulDefaultActions = [FORMAT_DEFAULT_ACTIONS[statefulRuleDropAction]];

  if (alertOptionAll) {
    statefulDefaultActions.push(FORMAT_DEFAULT_ACTIONS['aws:alert_strict']);
  }

  if (alertOptionEstablished) {
    statefulDefaultActions.push(FORMAT_DEFAULT_ACTIONS['aws:alert_established']);
  }

  const ruleGroupsStatefulRuleActionDummy: IMgdDetailKeyValueProps[] = [
    { id: '1', title: 'Rule order', description: FORMAT_RULE_ORDER[statefulRuleEvaluationOrder] },
    { id: '2', title: 'Default actions', description: statefulDefaultActions },
  ];

  const capacityGroupsDummy: IMgdDetailKeyValueProps[] = [
    { id: '1', description: statelessRuleGroupCapacityUnitConsumed },
    { id: '2', description: statefulRuleGroupCapacityUnitConsumed },
  ];

  const policyVariablesDummy: IMgdDetailKeyValueProps[] = [
    { id: '1', title: 'HOME_NET_variable override values', description: policyVariables },
  ];

  const customerManagedKeyDummy: IMgdDetailKeyValueProps[] = [
    { id: '1', title: 'Key type', description: customizeEncryptionSettings ? 'AWS KMS key' : 'AWS owned key' },
  ];

  const rowsTlsDummy: RowType[] = tlsInspectionConfiguration?.value ? [{ id: 1, name: tlsInspectionConfiguration?.name, lastUpdate: '' }] : [];

  const updateStatelessTablePagination = (key: string, value: number | string | OrderDirection) => {
    setStatelessTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const updateStatefulTablePagination = (key: string, value: number | string | OrderDirection) => {
    setStatefulTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div id="firewall-policy-preview">
      <div className="one-page" style={{ height: 'calc(100% - 70px)', overflowY: 'auto' }}>
        <div className=" j-between a-center">
          <div className="firewall-policy-detail-container">
            <div className="firewall-policy-detail-title">Firewall policy details</div>

            <div className="firewall-policy-detail-table-container">
              <div className="firewall-detail-table-title">Firewall details</div>
              <div className="firewall-detail-table-content">
                {firewallPolicyDetailDummy?.map((detail, index) => {
                  return (
                    <div className="col three-columns" key={`${index}`}>
                      <div className={`content ${(index + 1) % 3 === 0 ? 'last' : ''}`}>
                        <div className="title">
                          {detail.title && (
                            <>
                              {detail.title.split('\n').map((subTitle, index) => {
                                return <p key={`content-title-${index}`}>{subTitle}</p>;
                              })}
                            </>
                          )}
                          <>
                            <div className="value">
                              <p>{detail.description}</p>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="rule-groups-container">
            <div className="rule-groups-title">Rule groups</div>

            <div className="stateless-default-actions">
              <div className="stateless-default-actions-title">Stateless default actions</div>
              <div className="stateless-default-actions-content">
                {ruleGroupsDummy?.map((detail, index) => {
                  return !detail?.visible ? (
                    <div className="col  two-columns" key={`${index}`}>
                      <div className={`content ${(index + 1) % 2 === 0 ? 'last' : ''}`}>
                        <div className="title">
                          {detail.title && (
                            <>
                              {detail.title.split('\n').map((subTitle, index) => {
                                return <p key={`content-title-${index}`}>{subTitle}</p>;
                              })}
                            </>
                          )}
                          <>
                            <div className="value">
                              <p>{detail.description}</p>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
              </div>
            </div>

            <div className="stateless-rule-groups">
              <div className="stateless-rule-groups-title">Stateless rule groups</div>
              <div className="stateless-rule-groups-table">
                <Table
                  columns={[
                    { label: 'Priority', field: 'priority', sort: true },
                    { label: 'Name', field: 'name', sort: true },
                    { label: 'Capacity', field: 'capacity', sort: true },
                  ]}
                  rows={statelessRowsCurrentPage}
                  sortOption={{
                    target: tablePagination.target,
                    direction: tablePagination.direction,
                    onChangeSort: (target: string, dir: OrderDirection) => {
                      updateStatelessTablePagination('target', target);
                      updateStatelessTablePagination('direction', dir);
                    },
                  }}
                />

                {statelessRowsCurrentPage?.length ? (
                  <div className="pagination-wrapper flex a-center">
                    <p className="flex a-center">
                      Total <span>{statelessTblTotal.totalElement}</span>
                    </p>

                    <TablePagination
                      currentPage={statelessTablePagination.currentPage}
                      updateCurrentPage={pageNumber => {
                        updateStatelessTablePagination('currentPage', pageNumber);
                      }}
                      totalPage={statelessTblTotal.totalPage}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="stateful-rule-actions">
              <div className="stateful-rule-actions-title">Stateful rule evaluation order and default actions.</div>
              <div className="stateful-rule-actions-content">
                {ruleGroupsStatefulRuleActionDummy?.map((detail, index) => {
                  return (
                    <div className="col two-columns" key={`${index}`}>
                      <div className={`content ${(index + 1) % 2 === 0 ? 'last' : ''}`}>
                        <div className="title">
                          {detail.title && (
                            <>
                              {detail.title.split('\n').map((subTitle, index) => {
                                return <p key={`content-title-${index}`}>{subTitle}</p>;
                              })}
                            </>
                          )}
                          <>
                            {detail.description instanceof Array ? (
                              detail.description.map(e => (
                                <div key={e} className="value">
                                  <p>{e}</p>
                                </div>
                              ))
                            ) : (
                              <div className="value">
                                <p>{detail.description}</p>
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="stateful-rule-groups">
              <div className="stateful-rule-groups-title">Stateful rule groups</div>
              <div className="stateful-rule-groups-table">
                <Table
                  columns={[
                    { label: 'Priority', field: 'priority', sort: true },
                    { label: 'Name', field: 'name', sort: true },
                    { label: 'Capacity', field: 'capacity', sort: true },
                  ]}
                  rows={statefulRowsCurrentPage}
                  sortOption={{
                    target: tablePagination.target,
                    direction: tablePagination.direction,
                    onChangeSort: (target: string, dir: OrderDirection) => {
                      updateStatefulTablePagination('target', target);
                      updateStatefulTablePagination('direction', dir);
                    },
                  }}
                />

                {statefulRowsCurrentPage?.length ? (
                  <div className="pagination-wrapper flex a-center">
                    <p className="flex a-center">
                      Total <span>{statefulTblTotal.totalElement}</span>
                    </p>

                    <TablePagination
                      currentPage={statefulTablePagination.currentPage}
                      updateCurrentPage={pageNumber => {
                        updateStatefulTablePagination('currentPage', pageNumber);
                      }}
                      totalPage={statefulTblTotal.totalPage}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="capacity-groups">
              <div className="capacity-groups-header">
                <div className="capacity-groups-container-title">
                  <div className="capacity-groups-title">
                    Capacity units consumed by stateless rule groups
                    <div className="capacity-groups-description">
                      The total capacity units consumed by stateless rule groups can’t exceed 30,000.
                    </div>
                  </div>
                </div>
                <div className="capacity-groups-container-title">
                  <div className="capacity-groups-title">
                    Capacity units consumed by stateful rule groups
                    <div className="capacity-groups-description">
                      The total capacity units consumed by stateful rule groups can’t exceed 30,000.
                    </div>
                  </div>
                </div>
              </div>
              <div className="capacity-groups-table-content">
                {capacityGroupsDummy?.map((detail, index) => {
                  return (
                    <div className="col two-columns" key={`${index}`}>
                      <div className={`content`}>
                        <div className="title">
                          {detail.title && (
                            <>
                              {detail.title.split('\n').map((subTitle, index) => {
                                return <p key={`content-title-${index}`}>{subTitle}</p>;
                              })}
                            </>
                          )}
                          <>
                            <div className="value">
                              <p>{detail.description}</p>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="advanced-settings-container">
            <div className="advanced-settings-title">Advanced settings</div>

            <div className="customer-managed-key">
              <div className="customer-managed-key-title">Customer managed key</div>
              <div className="customer-managed-key-content">
                {customerManagedKeyDummy?.map((detail, index) => {
                  return (
                    <div className="col one-column" key={`${index}`}>
                      <div className={`content`}>
                        <div className="title">
                          {detail.title && (
                            <>
                              {detail.title.split('\n').map((subTitle, index) => {
                                return <p key={`content-title-${index}`}>{subTitle}</p>;
                              })}
                            </>
                          )}

                          <>
                            <div className="value">
                              <p>{detail.description}</p>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="policy-variables">
              <div className="policy-variables-title">Policy variables</div>
              <div className="policy-variables-content">
                {policyVariablesDummy?.map((detail, index) => {
                  return (
                    <div className="col one-column" key={`${index}`}>
                      <div className={`content`}>
                        <div className="title">
                          {detail.title && (
                            <>
                              {detail.title.split('\n').map((subTitle, index) => {
                                return <p key={`content-title-${index}`}>{subTitle}</p>;
                              })}
                            </>
                          )}
                          <>
                            <div className="value">
                              <p>{detail.description}</p>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="tls-inspection-configuration">
              <div className="tls-inspection-configuration-title">TLS inspection configuration</div>
              <div className="tls-inspection-configuration-table">
                <Table
                  columns={[
                    { label: 'Name', field: 'name', sort: true },
                    { label: 'Last updated', field: 'lastUpdate', sort: true },
                  ]}
                  rows={rowsTlsDummy}
                  sortOption={{
                    target: tablePagination.target,
                    direction: tablePagination.direction,
                    onChangeSort: (target: string, dir: OrderDirection) => {
                      setTablePagination(prev => ({
                        ...prev,
                        target: target,
                        direction: dir,
                      }));
                    },
                  }}
                />
                <div className="pagination-wrapper flex a-center">
                  <p className="flex a-center text-paging">
                    Total <span>{rowsTlsDummy.length}</span>
                  </p>

                  <TablePagination
                    currentPage={currentRulePage}
                    updateCurrentPage={pageNumber => {
                      setCurrentRulePage(pageNumber);
                    }}
                    totalPage={1}
                  />
                </div>
              </div>
            </div>

            <div className="tags">
              <div className="tags-title">Tags</div>
              <div className="tags-table">
                <Table
                  columns={[
                    { label: 'Key', field: 'key', sort: true },
                    { label: 'Value', field: 'value', sort: true },
                  ]}
                  rows={tags}
                  sortOption={{
                    target: tablePagination.target,
                    direction: tablePagination.direction,
                    onChangeSort: (target: string, dir: OrderDirection) => {
                      setTablePagination(prev => ({
                        ...prev,
                        target: target,
                        direction: dir,
                      }));
                    },
                  }}
                />
                <div className="pagination-wrapper flex a-center">
                  <p className="flex a-center text-paging">
                    Total <span>{tags.length}</span>
                  </p>

                  <TablePagination
                    currentPage={currentRulePage}
                    updateCurrentPage={pageNumber => {
                      setCurrentRulePage(pageNumber);
                    }}
                    totalPage={1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirewallPoliciesPreviewCreation;
