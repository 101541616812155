import './index.scss';
import LabelInput from 'components/v2/LabelInput';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { useEffect, useState } from 'react';
import { ITag } from '../../types';
import lazyTagAwsResourceMacieMutation, {
  ITagAwsResourceMacieResponseData,
} from 'graphql/mutations/tagAwsResourceMacie';
import lazyUnTagAwsResourceMacieMutation, {
  IUnTagAwsResourceMacieResponseData,
} from 'graphql/mutations/untagAwsResourceMacie';
import { SingleExecutionResult } from '@apollo/client';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';

interface IDeleteAllowListModalProps extends IBaseModalProps {
  cloudId: number;
  region: string;
  header: string;
  resourceArn: string;
  allowListName: string;
  description?: string;
  tagsList: Record<string, string | undefined>;
  onSave: () => void;
}

const ManageTags = ({
  cloudId,
  region,
  header,
  resourceArn,
  allowListName,
  description,
  tagsList,
  onSave,
  ...baseModalProps
}: IDeleteAllowListModalProps) => {
  const [tags, setTags] = useState<ITag[]>([]);
  const [tagsServer, setTagsServer] = useState<ITag[]>([]);
  const [tagsResourceMacie] = lazyTagAwsResourceMacieMutation();
  const [untagsResourceMacie] = lazyUnTagAwsResourceMacieMutation();
  const handleSave = async () => {
    const listAdd: ITag[] = tags.filter(
      tag =>
        !tagsServer.every(t => tag.key !== t.key) ||
        !tagsServer.some(t => tag.key === t.key && tag.value !== tag.value),
    );
    const listUntag: ITag[] = tagsServer.filter(tag => tags.every(t => tag.key !== t.key));
    let req1: Promise<
        SingleExecutionResult<ITagAwsResourceMacieResponseData, Record<string, any>, Record<string, any>>
      > = Promise.resolve({}),
      req2: Promise<
        SingleExecutionResult<IUnTagAwsResourceMacieResponseData, Record<string, any>, Record<string, any>>
      > = Promise.resolve({});
    if (listAdd.length > 0) {
      const record: Record<string, string | undefined> = {};
      listAdd.forEach(tag => (record[tag.key] = tag.value));
      req1 = tagsResourceMacie({ variables: { cloudId, region, request: { resourceArn, tags: record } } });
    }
    if (listUntag.length > 0) {
      req2 = untagsResourceMacie({
        variables: { cloudId, region, request: { resourceArn, tagKeys: listUntag.map(tag => tag.key) } },
      });
    }
    await Promise.all([req1, req2]);
    useToast(ErrorCode.SUCCESS, 'Update tag resource successful.');
    baseModalProps?.onClose && baseModalProps?.onClose();
  };
  useEffect(() => {
    const newTags: ITag[] = [];
    Object.keys(tagsList).forEach(key =>
      newTags.push({
        key,
        value: tagsList[key] || '',
      }),
    );
    setTagsServer(newTags);
    setTags(newTags);
  }, [tagsList]);

  return (
    <BaseModal
      {...baseModalProps}
      title={() => (
        <div>
          {header}
          <p className="allow-list-sub-title">{description}</p>
        </div>
      )}
    >
      <div className="manage-tags-allow-list-modal">
        <div className="manage-tags-allow-list">
          <p>Name: {allowListName}</p>
          {tags.length > 0 ? (
            tags.map((tag, index) => {
              return (
                <div key={index} className="header">
                  <LabelInput
                    title="Key"
                    value={tag.key}
                    placeholder={'Enter key'}
                    onChangeValue={val => {
                      const newTags = [...tags];
                      newTags[index].key = val;
                      setTags(newTags);
                    }}
                    required
                  />
                  <LabelInput
                    title="Value"
                    value={tag.value}
                    placeholder={'Enter value'}
                    onChangeValue={val => {
                      const newTags = [...tags];
                      newTags[index].value = val;
                      setTags(newTags);
                    }}
                  />
                  <button
                    className="button"
                    style={{ margin: 0 }}
                    onClick={() => {
                      const newTags = [...tags];
                      newTags.splice(index, 1);
                      setTags(newTags);
                    }}
                  >
                    Remove tag
                  </button>
                </div>
              );
            })
          ) : (
            <p className="sub-title" style={{ padding: '20px 0' }}>
              No tags associated with the resource.
            </p>
          )}
          <button className="add-tags" style={{ margin: '10px 0 0' }} onClick={() => setTags([...tags, { key: '' }])}>
            Add tags
          </button>
          <div className="sub-title">You can add up to {50 - tags.length} more tags.</div>
        </div>
        <div className={'button-group'}>
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default ManageTags;
