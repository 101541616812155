import { useMutation } from '@apollo/client';
import { IGqlResponseData } from 'graphql/types';
import query from './query';

export interface IUnTagAwsResourceMacieVariables {
  cloudId: number;
  region: string;
  request: {
    resourceArn: string;
    tagKeys: string[];
  };
}

export type UnTagAwsResourceMacieResponse = {};

export interface IUnTagAwsResourceMacieResponseData {
  untagAwsResourceMacie: IGqlResponseData<UnTagAwsResourceMacieResponse>;
}

const lazyUnTagAwsResourceMacieMutation = () =>
  useMutation<IUnTagAwsResourceMacieResponseData, IUnTagAwsResourceMacieVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyUnTagAwsResourceMacieMutation;
