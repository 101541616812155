import { useState, useEffect, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconEditRule from 'assets/svgs/v3/ico_policy_variables.svg';
import IconAddNewRule from 'assets/svgs/v3/ico_add_new_rule.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import { listProtocolData } from '../RuleGroupCreation/configs';
import { RowType } from '@Types/v2/Table';

interface IEditRuleModalProps extends IBaseModalProps {
  enumType: 'editRule' | 'addRule';
  header: string;
  onChangeValue?: (index: number, value: string) => void;
  handleSaveRule: any;
  rule?: RowType;
  nextSid?: number;
}

export interface ruleTrafficRowData {
  index: number;
  valueKeyword: string;
  valueSetting: string;
}

const protocolDummy: DropdownListDataType[] = [];
const sourcePortDummy: DropdownListDataType[] = [];
const destinationPortDummy: DropdownListDataType[] = [];

const listIP: DropdownListDataType[] = [
  { id: 1, name: 'Any', value: 'ANY' },
  { id: 2, name: 'Custom', value: '' },
];
const listPort: DropdownListDataType[] = [
  { id: 1, name: 'Any port', value: 'ANY' },
  { id: 2, name: 'Custom', value: '' },
];
const STR_IP = '10.0.0.0/16\n10.0.0.0\n$VARIABLE_NAME';
const STR_PORT = '100\n0:65535\n$VARIABLE_NAME';

const EditRuleModal = ({
  enumType,
  header,
  onChangeValue,
  handleSaveRule,
  rule,
  nextSid,
  ...baseModalProps
}: IEditRuleModalProps) => {
  const [ruleTrafficRows, setRuleTrafficRows] = useState<ruleTrafficRowData[]>([]);
  const [sourceTextareaValue, setSourceTextareaValue] = useState<string>('');
  const [destinationTextareaValue, setDestinationTextareaValue] = useState<string>('');
  const [sourcePortTextareaValue, setSourcePortTextareaValue] = useState<string>('');
  const [destinationPortTextareaValue, setDestinationPortTextareaValue] = useState<string>('');
  const [protocol, setProtocol] = useState<DropdownListDataType>();
  const [source, setSource] = useState<DropdownListDataType>(listIP[0]);
  const [sourcePort, setSourcePort] = useState<DropdownListDataType>(listPort[0]);
  const [destination, setDestination] = useState<DropdownListDataType>(listIP[0]);
  const [destinationPort, setDestinationPort] = useState<DropdownListDataType>(listPort[0]);
  const [trafficValue, setTrafficValue] = useState<string>('FORWARD');
  const [actionTrafficValue, setActionTrafficValue] = useState<'DROP' | 'PASS' | 'ALERT' | 'REJECT'>('PASS');

  useEffect(() => {
    if (baseModalProps.open) {
      if (!rule) {
        return;
      }

      setActionTrafficValue(rule?.action);
      setTrafficValue(rule?.direction);
      setProtocol(listProtocolData.find(item => item.value === rule?.protocol));

      if (rule?.source === 'ANY') {
        setSource(listIP[0]);
      } else {
        setSource(listIP[1]);
        setSourceTextareaValue(rule?.source);
      }

      if (rule?.sourcePort === 'ANY') {
        setSourcePort(listPort[0]);
      } else {
        setSourcePort(listPort[1]);
        setSourcePortTextareaValue(rule?.sourcePort);
      }

      if (rule?.destination === 'ANY') {
        setDestination(listIP[0]);
      } else {
        setDestination(listIP[1]);
        setDestinationTextareaValue(rule?.destination);
      }

      if (rule?.destinationPort === 'ANY') {
        setDestinationPort(listPort[0]);
      } else {
        setDestinationPort(listPort[1]);
        setDestinationPortTextareaValue(rule?.destinationPort);
      }
    }
  }, [baseModalProps.open, rule]);

  const handleCreate = () => {
    setRuleTrafficRows(prevState => {
      const newState = prevState.concat({ index: ruleTrafficRows.length, valueKeyword: '', valueSetting: '' });
      return newState;
    });
  };

  const handleDelete = (indexToRemove: number) => {
    const newData = ruleTrafficRows.filter((_, index) => index != indexToRemove);
    setRuleTrafficRows(newData);
  };

  const handleChange = (index: number, value: string, propName: keyof ruleTrafficRowData) => {
    const newRows = ruleTrafficRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setRuleTrafficRows(newRows);
  };

  const onSave = useCallback(() => {
    const rule = {
      action: actionTrafficValue,
      header: {
        destination: destination.value === 'ANY' ? destination.value : destinationTextareaValue,
        destinationPort: destinationPort.value === 'ANY' ? destinationPort.value : destinationPortTextareaValue,
        source: source.value === 'ANY' ? source.value : sourceTextareaValue,
        sourcePort: sourcePort.value === 'ANY' ? sourcePort.value : sourcePortTextareaValue,
        direction: trafficValue,
        protocol: protocol?.value,
      },
      ruleOptions: [
        {
          keyword: 'sid',
          settings: [nextSid?.toString() ?? '1'],
        },
      ],
    };
    handleSaveRule(rule);
  }, [
    actionTrafficValue,
    destination,
    destinationTextareaValue,
    destinationPort,
    destinationPortTextareaValue,
    source,
    sourceTextareaValue,
    sourcePort,
    sourcePortTextareaValue,
    trafficValue,
    protocol,
  ]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={enumType === 'editRule' ? IconEditRule : IconAddNewRule} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-rule-modal">
        <div className="protocol-container">
          {enumType === 'addRule' && (
            <>
              <div className="add-rule-column">
                <p className="text-title">Standard stateful rules</p>
                <p className="text-detail">
                  Add the stateful rules that you need in your rule group. Each rule that you add is listed in the Rules
                  table below.
                </p>
              </div>
            </>
          )}

          <div className="column">
            <p className="text-title">Protocol</p>
            <p className="text-detail">Transport protocols to inspect for</p>
            <DropdownAtom
              id={'edit-rule-modal-protocol-dropdown'}
              placeholder={'IP-set reference'}
              data={listProtocolData}
              value={
                protocol ?? {
                  value: '',
                }
              }
              handleClick={setProtocol}
            />
          </div>
        </div>

        <div className="source-container">
          <div className="column">
            <p className="text-title">Source</p>
            <p className="text-detail">The source IP addresses and address ranges to inspect for.</p>
            <DropdownAtom id={'edit-rule-modal-source-dropdown'} data={listIP} value={source} handleClick={setSource} />
            <div className="textarea-container">
              <textarea
                disabled={source.id === 1}
                placeholder={STR_IP}
                value={source.id === 1 ? 'ANY' : sourceTextareaValue}
                onChange={event => setSourceTextareaValue(event.target.value)}
              />
            </div>
            <p className="note">You can provide single addresses and CIDR blocks</p>
          </div>

          <div className="column">
            <p className="text-title">Source port</p>
            <p className="text-detail">The source port or port range to inspect for.</p>
            <DropdownAtom
              id={'edit-rule-modal-source-port-dropdown'}
              data={listPort}
              value={sourcePort}
              handleClick={setSourcePort}
            />
            <div className="textarea-container">
              <textarea
                disabled={sourcePort.id === 1}
                placeholder={STR_PORT}
                value={sourcePort.id === 1 ? 'ANY' : sourcePortTextareaValue}
                onChange={event => setSourcePortTextareaValue(event.target.value)}
              />
            </div>
            <p className="note">Supported ports are :34354</p>
          </div>
        </div>
        <div className="destination-container">
          <div className="column">
            <p className="text-title">Destination</p>
            <p className="text-detail">The source IP addresses and address ranges to inspect fo</p>
            <DropdownAtom
              id={'edit-rule-modal-destination-dropdown'}
              data={listIP}
              value={destination}
              handleClick={setDestination}
            />
            <div className="textarea-container">
              <textarea
                disabled={destination.id === 1}
                placeholder={STR_IP}
                value={destination.id === 1 ? 'ANY' : destinationTextareaValue}
                onChange={event => setDestinationTextareaValue(event.target.value)}
              />
            </div>
            <p className="note">You can provide single addresses and CIDR blocks</p>
          </div>
          <div className="column">
            <p className="text-title">Destination port</p>
            <p className="text-detail">The destination port or port range to inspect for.</p>

            <DropdownAtom
              id={'edit-rule-modal-destination-port-dropdown'}
              data={listPort}
              value={destinationPort}
              handleClick={setDestinationPort}
            />
            <div className="textarea-container">
              <textarea
                disabled={destinationPort.id === 1}
                placeholder={STR_PORT}
                value={destinationPort.id === 1 ? 'ANY' : destinationPortTextareaValue}
                onChange={event => setDestinationPortTextareaValue(event.target.value)}
              />
            </div>
            <p className="note">Supported ports are : 54546</p>
          </div>
        </div>
        <div className="traffic-direction-container">
          <p className="text-title">Traffic direction</p>
          <p className="text-detail">
            The traffic direction to inspect for. Network Firewall doesn’t automatically add the direction key word
            to_server, and will inspect all the packets in the flow, irrespective of the flow state.
          </p>
          <div className="traffic-direction-content">
            <div className="row-1">
              <div className="radio-content">
                <RadioAtom
                  label={'Forward'}
                  description={
                    'Inspect traffic that flows forward, from the specified source to the specified destination.'
                  }
                  value={'FORWARD'}
                  name={''}
                  checked={trafficValue}
                  onChange={() => {
                    setTrafficValue('FORWARD');
                  }}
                />

                <RadioAtom
                  label={'Any'}
                  description={'Inspect traffic from any direction.'}
                  value={'ANY'}
                  name={''}
                  checked={trafficValue}
                  onChange={() => {
                    setTrafficValue('ANY');
                  }}
                />
              </div>
              <div className="action-radio-content">
                <p className="text-sub-title">Action</p>
                <p className="text-description">
                  The action that you want Network Firewall to take when a packet matches the rule settings.
                </p>
                <RadioAtom
                  label={'Pass'}
                  value={'PASS'}
                  name={''}
                  checked={actionTrafficValue}
                  onChange={() => {
                    setActionTrafficValue('PASS');
                  }}
                />

                <RadioAtom
                  label={'Drop'}
                  value={'DROP'}
                  name={''}
                  checked={actionTrafficValue}
                  onChange={() => {
                    setActionTrafficValue('DROP');
                  }}
                />

                <RadioAtom
                  label={'Alert'}
                  value={'ALERT'}
                  name={''}
                  checked={actionTrafficValue}
                  onChange={() => {
                    setActionTrafficValue('ALERT');
                  }}
                />
                <div className="reject-radio">
                  <RadioAtom
                    label={'Reject'}
                    value={'REJECT'}
                    name={''}
                    checked={actionTrafficValue}
                    onChange={() => {}}
                  />
                </div>
              </div>
            </div>

            <div className="row-2">
              <p className="text-sub-title">Rule options - optional</p>
              <p className="text-description">No rule options associated with this rule.</p>
              <div className="rule-options-container">
                <div className="content">
                  <div className="header-container">
                    <div className="column">
                      <p className="text-title">Keyword</p>
                      <p className="text-description">
                        You must include a Sid (signature ID), and can optionally include other Suricate keywords.
                      </p>
                    </div>
                    <div className="column">
                      <p className="text-title">Settings - optional</p>
                      <p className="text-description">The settings of the Suricate compatible rule option.</p>
                    </div>
                  </div>

                  {ruleTrafficRows && (
                    <>
                      {ruleTrafficRows.map((row, index) => (
                        <div className="input-container" key={`edit-rule-traffic-new-row-${index}`}>
                          <div className="rule-traffic-row-input">
                            <div className="column">
                              <InputAtom
                                type={'text'}
                                value={row.valueKeyword}
                                noClear={true}
                                disabled={false}
                                onChangeValue={(value: string) => handleChange(index, value, 'valueKeyword')}
                              />
                              <p className="note">Enter one keyword</p>
                            </div>
                            <div className="column">
                              <div className="textarea-container">
                                <textarea
                                  value={row.valueSetting}
                                  onChange={event => handleChange(index, event.target.value, 'valueSetting')}
                                />
                              </div>
                              <p className="note">Enter one value per line.</p>
                            </div>
                          </div>
                          <button className="btn remove-btn " onClick={() => handleDelete(index)}>
                            Remove
                          </button>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
              <button className="btn add-btn" onClick={handleCreate}>
                Add rule options
              </button>
            </div>
          </div>
        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={onSave}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditRuleModal;
