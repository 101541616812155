import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconPolicyVariables from 'assets/svgs/v3/ico_policy_variables.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';

interface IEditPolicyVariablesProps extends IBaseModalProps {
  header: string;
  data: string;
  onClose: () => void;
  loadingSave: boolean;
  handleUpdateAwsFirewallPolicy: (datasUpdate: string[]) => boolean;
}

const EditPolicyVariablesModal = ({
  header,
  data,
  loadingSave,
  onClose,
  handleUpdateAwsFirewallPolicy,
  ...baseModalProps
}: IEditPolicyVariablesProps) => {

  const [cidrValue, setCidrValue] = useState<string>('');

  const onSavePolicy = async () => {
    const reqData = cidrValue?.split('\n').filter(item => item !== '');
    const result = await handleUpdateAwsFirewallPolicy(reqData);
    if (result) {
      onClose();
    }
  };

  useEffect(() => {
    if (baseModalProps.open) {
      setCidrValue(data);
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconPolicyVariables} />
          {header}
        </>
      )}
      onClose={onClose}
      {...baseModalProps}>

      <div className="policy-variables-modal">
        <div className="policy-variables">
          <p className="text-title">HOME_NET variables override values-optional</p>
          <textarea
            className="input-textarea"
            placeholder="10.0.0.0/24
2001:DB8::/32"
            value={cidrValue}
            onChange={e => {
              setCidrValue(e.target.value);
            }}/>
          <p className="text-description">Enter 1 CIDR per line.</p>
        </div>

        <div className="button-group">
          <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={onClose} />
          <Button
            label="Save"
            type={ButtonTypeEnum.PRIMARY}
            loading={loadingSave}
            onClick={onSavePolicy}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditPolicyVariablesModal;
