import ReactDOM from 'react-dom';
import React, { useState, ChangeEvent, useCallback, useEffect } from 'react';
import './index.scss';
import CloseIcon from 'assets/svgs/v3/ico_close.svg';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import InputCustomDesc from '../InputCustomDesc';
import InputAtom from 'components/v2/atoms/InputAtom';
import SearchPrefixIcon from 'assets/svgs/v4/ico_search.svg';
import { TagType } from '../../types';
import { dropdownType } from '../../Modals/EditGroupingCriteria';
import lazyGetAwsTagKeys, { IAwsGetTagKeysVariables } from 'graphql/queries/getAwsTagKeys';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';

interface ITagKeyMatchingResourceProps {
  cloudId: number;
  region: string;
  selectedValues: TagType[];
  resourceTypes: dropdownType[];
  disabled?: boolean;
  handleClick: Function;
}

const TagKeyMatchingResource: React.FC<ITagKeyMatchingResourceProps> = ({
  cloudId,
  region,
  selectedValues,
  resourceTypes,
  handleClick,
}) => {
  const [getAwsTagKeys] = lazyGetAwsTagKeys();

  const [keyValue, setKeyValue] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [filteredData, setFilteredData] = useState<string[]>();
  const [showData, setShowData] = useState<boolean>(false);
  const [errorTag, setErrorTag] = useState<string>('');

  const handleOnClick = () => {
    if (keyValue !== '') {
      handleClick([...selectedValues, { key: keyValue, values: [inputValue] }]);
    } else {
      setErrorTag('You must choose at least one tag.');
    }
  };

  const handleChange = (e: string) => {
    setKeyValue(e);
    if (e) {
      const filtered = filteredData?.filter(
        suggestion =>
          String(suggestion).toLowerCase().includes(e.toLowerCase()),
      );
      setFilteredData(filtered);
    }
  };

  const fetchTags = async() => {
    const variables: IAwsGetTagKeysVariables = {
      cloudId: cloudId,
      region: region,
      request: {},
    };
    const resultTags = await getAwsTagKeys({ variables: variables });
    const dataTags = resultTags.data?.getAwsTagKeys?.data[0].tagKeys || [];
    setFilteredData(dataTags);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <div className="tagkeys-details">
      <div className="tag-row-container">
        <div className="tag-row-input">
          <div className="column">
            <p>Tag key</p>
          </div>
          <div className="column">
            <p>Tag value - optiona</p>
          </div>
        </div>
        <div className="tag-row-btn" />
      </div>
      <div className="tag-row-container">
        <div className="tag-row-input"> 
          <div
            className="column-tag-key"
            onClick={e => {
              setShowData(true);
            }}
          >
            <InputCustomDesc error={errorTag}>
              <InputAtom
                value={keyValue}
                onChangeValue={(e) => handleChange(e)}
                disabled={resourceTypes.length === 0}
                noClear={false}
                hasSuffixIcon
                placeholder="Enter tag key"
                srcSuffixIcon={SearchPrefixIcon}
                error={!!errorTag}
              />
            </InputCustomDesc>
          </div>
          <div className="column">
            <InputCustomDesc error={''}>
              <InputAtom
                value={inputValue}
                disabled={!keyValue}
                onChangeValue={setInputValue}
                defaultValue={''}
                noClear={false}
                placeholder="Enter tag value"
              />
            </InputCustomDesc>
          </div>
        </div>

        <div className={'tag-row-btn'}>
          <Button
            type={ButtonTypeEnum.GENERAL}
            label="Add"
            disabled={keyValue === '' || resourceTypes.length === 0}
            onClick={() => { 
              handleOnClick();
              setKeyValue('');
              setInputValue('');
            }}
          />
        </div>
      </div>
      <div className="dropdown-list">
        {selectedValues.map((data, index) => (
          <div key={index}>
            <span>{data?.key}{data?.values?.length > 0 ? `:${data?.values[0]}` : ''}</span>
            <button
              onClick={() => {
                const updatedValues = selectedValues.filter(item => item.key !== data?.key);
                handleClick(updatedValues);
              }}
            >
              <img src={CloseIcon} width={24} height={24} />
            </button>
          </div>
        ))}

        {showData ? (
          <ul className="no-scrollbar">
            {filteredData?.map((data, index) => (
              <li key={index} className="drop-item" onClick={(e) => {
                setKeyValue(data);
                setShowData(false);
              }}>
                {data}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
};
export default TagKeyMatchingResource;
