import { gql } from '@apollo/client';

const query = gql`
  mutation tagAwsResourceKms($reqData: AwsTagResourceKmsRequest, $cloudId: Long, $region: String) {
    tagAwsResourceKms(tagResourceKmsRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
    }
  }
`;
export default query;
