import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { DictionaryInfo } from 'apis/v2/Manage/schema';

export interface IUpdateDictionaryVariables {
  reqData: {
    id: number;
    relObjectId?: number;
    relObjectType?: string;
    defaultName?: string;
    domainName?: string;
  }
}

export interface IUpdateDictionaryResponseData {
  updateDictionary: IGqlResponseData<DictionaryInfo>;
}

const updateDictionary = () => useMutation<IUpdateDictionaryResponseData, IUpdateDictionaryVariables>(query);
export default updateDictionary;