import './index.scss';
import { RowType } from '@Types/v2/Table';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { useAuth } from 'contexts/AuthProvider';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';

interface IResourceDetailProps {
  data: RowType;
  convertGridIsShowing: () => void;
  resFormatCodeTypeList: { name: string; value: string }[];
}

const ResourceDetail = ({
  data,
  convertGridIsShowing,
  resFormatCodeTypeList
}: IResourceDetailProps) => {
  const { token } = useAuth();
  const { tooltip } = useGModal();

  /* 리소스 상세조회 (gql 0.5 미존재) */
  // const getResourceDetail 

  /* todo api 리소스썸네일 다운로드 */
  const { mutateAsync: _downloadProfileImage } = useMutation((data: { kind:string; id:number; }) => apis.File.getDownloadProfileImage(token, data));
   
  const download = async (fileType: string, downloadName: string, downloadUrl:string) => {
    if(fileType === 'excel000') {
      await fetch(downloadUrl, {
        method: "GET",
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'Accept': '*/*',
          "Authorization": `Bearer ${token}`
        }
      }).then(response => response.blob()).then((response) => {
          const objectUrl = URL.createObjectURL(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = objectUrl;
          downloadLink.download = downloadName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
      }).catch((e) => {
        console.log('catch', e);
        useToast(ErrorCode.UNKNOWN);
      });
    } else if(fileType === 'memo0000') {
      await fetch(downloadUrl, {
        method: "GET",
        headers: {
          "Content-Type": "image/png",
          'Accept': '*/*',
          "Authorization": `Bearer ${token}`
        }
      }).then(response => {
        return response.blob();
      }).then((response) => {
        const objectUrl = URL.createObjectURL(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = objectUrl;
          downloadLink.download = downloadName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
      }).catch((e) => {
        console.log('catch', e);
        useToast(ErrorCode.UNKNOWN);
      });
    } else {
      await fetch(downloadUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      }).then((response) => {
        if(response.body) {
          const reader = response.body.getReader();
          const decoder = new TextDecoder("utf-8");
          reader.read().then((result) => {
            console.log(decoder.decode(result.value));
            const parse = JSON.parse(decoder.decode(result.value));

            const downloadLink = document.createElement('a');
            downloadLink.href = parse.data;
            downloadLink.download = downloadName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
          })
        }
      }).catch((e) => {
        console.log('catch', e);
        useToast(ErrorCode.UNKNOWN);
      });
    }
  }; 
  
  return (
    <div className='resource-detail'>
      <div className='resource-detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />  
        {data.name}
      </div>
      <div className='detail-contents'>
        <InfoCard
          title="Name"
          content={() => <>{data.name}</> } 
        />
        <InfoCard
          title="Type"
          content={() => <>{enumFormatter(data.relObjectType)}</> } 
        />
        <InfoCard
          title="Format"
          content={() => <>{resFormatCodeTypeList.find(item => item.value === data.resFormatCode)?.name}</> } 
        />
        <InfoCard
          title="Attatchment"
          content={() => <div className="download-wrap flex a-center">
            <div>
              {data.name}
            </div>
            <span className="download-link" onClick={() => download(data.resFormatCode, data.name, data.url)}>
              Download
            </span>
            </div> 
          } 
        />
        <InfoCard
          title="Used"
          content={() => <>{ data.useYn ? 'Yes' : 'No' }</>} 
        />
         <InfoCard
          title="Created"
          content={() => 
            <>
            <u
              onMouseOver={(e) => {
                tooltip.userTooltip({ 
                  top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                    ? e.currentTarget.getBoundingClientRect().bottom - 115
                    : e.currentTarget.getBoundingClientRect().bottom + 12,
                  left: e.currentTarget.getBoundingClientRect().left, 
                  userInfo: { userId: data.createdByUserId, fullName: data.createdByName, createdAt: !data.createdAt ? '-' : data.createdAt, thumbnail: '' }
                });
              }}
              onMouseLeave={tooltip.close}
            >
            {data.createdByName}({data.createdByUserId})</u> / {!data.createdAt ? '-' : dateFormatter(data.createdAt, 'datetime')}
            </> 
          } 
        />
        <InfoCard
          title="Updated"
          content={() => 
            <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: data.modifiedByUserId, fullName: data.modifiedByName, createdAt: !data.modifiedAt ? '-' : data.modifiedAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {data.modifiedByName}({data.modifiedByUserId})</u> / {!data.modifiedAt ? '-' : dateFormatter(data.modifiedAt, 'datetime')}
            </> 
          } 
        />
      </div>
    </div>
  )
}
export default ResourceDetail;