import { ErrorCode } from '@Types/error';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { DropdownListDataType } from 'components/Dropdown/types';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import updateAwsFirewallPolicyMutation, {
  UpdateAwsFirewallPolicyVariablesType,
} from 'graphql/mutations/updateAwsFirewallPolicy';
import lazyGetAwsRuleGroupMetadata from 'graphql/queries/getAwsRuleGroupMetadata';
import { AwsDescribeFirewallPolicyResponse } from 'graphql/types/AwsFirewallPolicy';
import { useToast } from 'hooks/v2/useToast';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { useEffect, useMemo, useState } from 'react';
import { handleFormatText, randomString } from 'utils/Common';
import { IBaseFirewallProps } from '../../../../types';
import AddManagedStatefulRuleGroupModal from '../../AddManagedStatefulRuleGroupModal';
import DisassociateRuleGroups from '../../DisassociateRuleGroups';
import EditPriority from '../../EditPriority';
import UnmanagedStateModal from '../../UnmanagedStateModal';
interface IStateRuleGroupsProps extends IBaseFirewallProps {
  firewallPolicies?: AwsDescribeFirewallPolicyResponse;
  onRefreshFirewallPolicy?: () => void;
  isEdit: boolean;
  type: StateRuleGroupsType;
}
type StateRuleGroupsType = 'STATELESS' | 'STATEFUL';

const StateRuleGroups = ({
  cloudId,
  region,
  firewallPolicies,
  onRefreshFirewallPolicy = () => {},
  type,
  isEdit,
}: IStateRuleGroupsProps) => {
  const { updateToken = '', firewallPolicy, firewallPolicyResponse } = firewallPolicies || {};
  const statelessRuleGroupReferences = firewallPolicy?.statelessRuleGroupReferences || [];
  const statefulRuleGroupReferences = firewallPolicy?.statefulRuleGroupReferences || [];
  const firewallPolicyArn = firewallPolicyResponse?.firewallPolicyArn || '';
  const firewallPolicyName = firewallPolicyResponse?.firewallPolicyName || '';

  const isStateLess = type === 'STATELESS';

  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [stateLessCheckedList, setStateLessCheckedList] = useState<string[]>([]);
  const [stateFullCheckedList, setStateFullCheckedList] = useState<string[]>([]);
  const [isOpenEditPriority, setIsOpenEditPriority] = useState(false);
  const [isOpenAddUnmanagedState, setIsOpenAddUnmanagedState] = useState(false);
  const [isOpenAddManagedState, setIsOpenAddManagedState] = useState(false);
  const [isOpenDisassociate, setIsOpenDisassociate] = useState(false);

  const [getAwsRuleGroupMetadata, { loading }] = lazyGetAwsRuleGroupMetadata();
  const [updateAwsFirewallPolicy] = updateAwsFirewallPolicyMutation();

  const actionsDropdown = useMemo(() => {
    if (isStateLess) {
      return [
        { id: 1, name: 'Add unmanaged stateless rule groups', value: 'Add unmanaged stateless rule groups' },
        {
          id: 2,
          name: 'Disassociate from policy',
          value: 'Disassociate from policy',
          disable: !stateLessCheckedList.length,
        },
      ];
    }

    return [
      { id: 1, name: 'Add unmanaged stateful rule groups', value: 'Add unmanaged stateful rule groups' },
      { id: 2, name: 'Add managed stateful rule groups', value: 'Add managed stateful rule groups' },
      {
        id: 3,
        name: 'Disassociate from policy',
        value: 'Disassociate from policy',
        disable: !stateFullCheckedList.length,
      },
    ];
  }, [isStateLess, stateLessCheckedList, stateFullCheckedList]);

  // get rule groups
  const handleGetAwsRuleGroupMetadata = async () => {
    const stateRuleGroups: RowType[] = [];

    if (firewallPolicy) {
      if (isStateLess) {
        for (const item of statelessRuleGroupReferences) {
          const awsRuleGroupVariable = {
            cloudId,
            region,
            request: {
              ruleGroupArn: item.resourceArn,
              type,
            },
          };

          const ruleGroupMetaData = await getAwsRuleGroupMetadata({
            variables: awsRuleGroupVariable,
          }).then(({ data: ruleGroupData }) => {
            return ruleGroupData?.getAwsRuleGroupMetadata;
          });

          stateRuleGroups.push({
            id: ruleGroupMetaData?.data?.[0]?.ruleGroupArn,
            priority: item.priority,
            name: ruleGroupMetaData?.data?.[0]?.ruleGroupName,
            capacity: ruleGroupMetaData?.data?.[0]?.capacity,
          });
        }
        stateRuleGroups.sort((a, b) => {
          return a.priority - b.priority;
        });
      } else {
        for (const item of statefulRuleGroupReferences) {
          const awsRuleGroupVariable = {
            cloudId,
            region,
            request: {
              ruleGroupArn: item.resourceArn,
              type,
            },
          };

          const ruleGroupMetaData = await getAwsRuleGroupMetadata({
            variables: awsRuleGroupVariable,
          }).then(({ data: ruleGroupData }) => {
            return ruleGroupData?.getAwsRuleGroupMetadata;
          });

          stateRuleGroups.push({
            id: ruleGroupMetaData?.data?.[0]?.ruleGroupArn,
            priority: item.priority,
            name: ruleGroupMetaData?.data?.[0]?.ruleGroupName,
            capacity: ruleGroupMetaData?.data?.[0]?.capacity,
            isManaged: ruleGroupMetaData?.data?.[0]?.ruleGroupArn?.includes('aws-managed') ? 'Yes' : 'No',
          });
        }
      }

      stateRuleGroups.sort((a, b) => {
        return a.priority - b.priority;
      });

      setMainTblRows(stateRuleGroups);
      setMainTblTotal({
        totalPage: Math.ceil(stateRuleGroups.length / tablePagination.itemPerPage),
        totalElement: stateRuleGroups.length,
      });
      setStateLessCheckedList([]);
      setStateFullCheckedList([]);
    }
  };
  // edit priority
  const handleEditPriority = async (rows: RowType[]) => {
    try {
      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken,
          firewallPolicyArn,
          firewallPolicyName,
          firewallPolicy: {
            ...firewallPolicy,
          },
        },
      };

      if (isStateLess) {
        const updStatelessRuleGroups = rows.map(r => ({
          resourceArn: r.id as string,
          priority: r.priority,
        }));
        variables.request.firewallPolicy.statelessRuleGroupReferences = updStatelessRuleGroups;
      } else {
        const updStatefulRuleGroups = rows.map(r => ({
          resourceArn: r.id as string,
          priority: r.priority,
          override: statefulRuleGroupReferences.find(s => s.resourceArn === r.id)?.override
        }));
        variables.request.firewallPolicy.statefulRuleGroupReferences = updStatefulRuleGroups;
      }

      const res = await updateAwsFirewallPolicy({ variables });

      if (res.data?.updateAwsFirewallPolicy.result !== ErrorCode.SUCCESS) {
        useToast(ErrorCode.UNKNOWN, 'Update priority failed.');
        setIsOpenEditPriority(false);
        return;
      }

      useToast(ErrorCode.SUCCESS, 'Update priority successfully.');
      onRefreshFirewallPolicy();
      setIsOpenEditPriority(false);
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Update priority failed.');
      setIsOpenEditPriority(false);
    }
  };
  // add stateless|stateful rule groups
  const handleAddRuleGroups = async (selectedRows: RowType[]) => {
    try {
      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken,
          firewallPolicyArn,
          firewallPolicyName,
          firewallPolicy: {
            ...firewallPolicy,
          },
        },
      };

      if (isStateLess) {
        const updStatelessRuleGroups = [...statelessRuleGroupReferences];
        selectedRows.forEach(r => {
          const maxPriority = updStatelessRuleGroups.length ? Math.max(...updStatelessRuleGroups.map(s => s.priority)) : 0;
          updStatelessRuleGroups.push({ resourceArn: r.id as string, priority: maxPriority + 1 });
        });
        variables.request.firewallPolicy.statelessRuleGroupReferences = updStatelessRuleGroups;
      } else {
        const updStatefulRuleGroup = [...statefulRuleGroupReferences];
        selectedRows.forEach(r => {
          const maxPriority = updStatefulRuleGroup.length ? Math.max(...updStatefulRuleGroup.map(s => s.priority)) : 0;
          updStatefulRuleGroup.push({ resourceArn: r.id as string, priority: maxPriority + 1 });
        });
        variables.request.firewallPolicy.statefulRuleGroupReferences = updStatefulRuleGroup;
      }

      const res = await updateAwsFirewallPolicy({ variables });

      if (res.data?.updateAwsFirewallPolicy.result !== ErrorCode.SUCCESS) {
        useToast(ErrorCode.UNKNOWN, `Add unmanaged ${isStateLess ? 'stateless' : 'stateful'} rule groups failed.`);
        setIsOpenEditPriority(false);
        return;
      }

      useToast(ErrorCode.SUCCESS, `Add unmanaged ${isStateLess ? 'stateless' : 'stateful'} rule groups successfully.`);
      onRefreshFirewallPolicy();
      setIsOpenAddUnmanagedState(false);
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, `Add unmanaged ${isStateLess ? 'stateless' : 'stateful'} rule groups failed.`);
      setIsOpenAddUnmanagedState(false);
    }
  };
  // disassociate rule groups
  const handleDelRuleGroups = async () => {
    try {
      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken,
          firewallPolicyArn,
          firewallPolicyName,
          firewallPolicy: {
            ...firewallPolicy,
          },
        },
      };

      if (isStateLess) {
        const updStatelessRuleGroups = statelessRuleGroupReferences.filter(
          r => !stateLessCheckedList.includes(r.resourceArn),
        );
        variables.request.firewallPolicy.statelessRuleGroupReferences = updStatelessRuleGroups;
      } else {
        const updStatefulRuleGroup = statefulRuleGroupReferences.filter(
          r => !stateFullCheckedList.includes(r.resourceArn),
        );
        variables.request.firewallPolicy.statefulRuleGroupReferences = updStatefulRuleGroup;
      }

      const res = await updateAwsFirewallPolicy({ variables });

      if (res.data?.updateAwsFirewallPolicy.result !== ErrorCode.SUCCESS) {
        useToast(ErrorCode.UNKNOWN, 'Disassociate rule group failed.');
        setIsOpenDisassociate(false);
        return;
      }

      useToast(ErrorCode.SUCCESS, 'Disassociate rule group successfully.');
      onRefreshFirewallPolicy();
      setIsOpenDisassociate(false);
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Disassociate rule group failed.');
      setIsOpenDisassociate(false);
    }
  };
  // add managed rule groups
  const handleAddMangedRuleGroups = async (ruleGroups: RowType[]) => {
    try {
      const updateRules = [...statefulRuleGroupReferences];
      ruleGroups.forEach(rule => {
        const maxPriority = statefulRuleGroupReferences.length ? Math.max(...mainTblRows.map(row => row.priority)) : 0;
        updateRules.push({
          priority: maxPriority + 1,
          resourceArn: rule?.id?.toString() || '',
          override: rule?.alertMode ? { action: 'DROP_TO_ALERT' } : null,
        });
      });

      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken,
          firewallPolicyArn,
          firewallPolicyName,
          firewallPolicy: {
            ...firewallPolicy,
            statefulRuleGroupReferences: updateRules,
          },
        },
      };
      const res = await updateAwsFirewallPolicy({ variables });

      if (res.data?.updateAwsFirewallPolicy.result !== ErrorCode.SUCCESS) {
        useToast(ErrorCode.UNKNOWN, 'Add managed stateful rule groups failed.');
        setIsOpenAddManagedState(false);
        return;
      }

      onRefreshFirewallPolicy();
      useToast(ErrorCode.SUCCESS, 'Add managed stateful rule groups successfully.');
      setIsOpenAddManagedState(false);
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Add managed stateful rule groups failed.');
      setIsOpenAddManagedState(false);
    }
  };

  const columns = useMemo((): ColumnType[] => {
    if (isStateLess)
      return [
        {
          field: 'priority',
          label: 'Priority',
          width: 100,
          sort: true,
        },
        {
          field: 'name',
          label: 'Name',
          sort: true,
          renderCell: row => {
            return <p className="link">{handleFormatText(row.name)}</p>;
          },
        },
        {
          field: 'capacity',
          label: 'Capacity',
          sort: true,
        },
      ];

    return [
      {
        field: 'priority',
        label: 'Priority',
        width: 100,
        sort: true,
      },
      {
        field: 'name',
        label: 'Name',
        sort: true,
        renderCell: row => {
          return <p className="link">{handleFormatText(row.name)}</p>;
        },
      },
      {
        field: 'capacity',
        label: 'Capacity',
        width: 200,
        sort: true,
      },
      {
        field: 'isManaged',
        label: 'Is managed',
        width: 200,
        sort: true,
      },
    ];
  }, [type]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleClickActions = (values: DropdownListDataType) => {
    if (isStateLess) {
      switch (values?.value) {
        case actionsDropdown[0].value:
          setIsOpenAddUnmanagedState(true);
          return;

        case actionsDropdown[1].value:
          if (!stateLessCheckedList?.length) return;
          setIsOpenDisassociate(true);
          return;

        default:
          return;
      }
    }

    switch (values?.value) {
      case actionsDropdown[0].value:
        setIsOpenAddUnmanagedState(true);
        return;

      case actionsDropdown[1].value:
        setIsOpenAddManagedState(true);
        return;

      case actionsDropdown[2].value:
        if (!stateFullCheckedList?.length) return;

        setIsOpenDisassociate(true);
        return;

      default:
        return;
    }
  };

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;
    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotal, tablePagination, isOpenEditPriority]);
  // status button edit priority
  const isDisableBtnEdit = useMemo(() => {
    if (type === 'STATELESS') {
      return statelessRuleGroupReferences.length < 2;
    }

    return statefulRuleGroupReferences.length < 2;
  }, [type, statelessRuleGroupReferences, statefulRuleGroupReferences]);

  const disassociateRuleGroups = useMemo(() => {
    const checkedList = isStateLess ? stateLessCheckedList : stateFullCheckedList;
    return checkedList.map(id => {
      const row = mainRowsCurrentPage.find(r => r.id === id);
      return row?.name as string;
    });
  }, [isStateLess, stateLessCheckedList, stateFullCheckedList, mainRowsCurrentPage]);

  useEffect(() => {
    handleGetAwsRuleGroupMetadata();
  }, [type, JSON.stringify(firewallPolicies)]);

  return (
    <>
      <div className="detail-info">
        <div className="detail-info-title">
          <p>{isStateLess ? 'Stateless rule groups' : 'Stateful rule groups'}</p>

          {isEdit ? (
            <div style={{ display: 'flex', gap: '8px' }}>
              <button
                style={{ whiteSpace: 'nowrap' }}
                className={`button ${isDisableBtnEdit ? 'disabled' : ''}`}
                disabled={isDisableBtnEdit}
                onClick={() => setIsOpenEditPriority(true)}
              >
                Edit priority
              </button>

              <DropdownAtom
                id={randomString()}
                className=""
                data={actionsDropdown}
                value={{ name: 'Actions', value: 'Actions' }}
                handleClick={handleClickActions}
              />
            </div>
          ) : null}
        </div>

        {loading ? (
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        ) : (
          <div className="detail-info-content">
            {!mainRowsCurrentPage.length && !loading ? (
              <div className="data-grid-wrap">
                <p className="empty-row">Empty</p>
              </div>
            ) : (
              <div className="data-grid-wrap">
                <Table
                  rows={mainRowsCurrentPage}
                  columns={columns}
                  reportCheckedList={isStateLess ? setStateLessCheckedList : setStateFullCheckedList}
                  sortOption={{
                    target: tablePagination.target,
                    direction: tablePagination.direction,
                    onChangeSort: (target: string, dir: OrderDirection) => {
                      updateTablePagination('target', target);
                      updateTablePagination('direction', dir);
                    },
                  }}
                  isLoading={loading}
                  horizontalScrollable={true}
                />

                {mainRowsCurrentPage?.length && !loading && (
                  <div className="fleet-instance pagination-wrapper flex a-center">
                    <p className="flex a-center">
                      Total <span>{mainTblTotal.totalElement}</span>
                    </p>

                    <TableManagePagination
                      ableFetchMore={false}
                      currentPage={tablePagination.currentPage}
                      updateCurrentPage={page => updateTablePagination('currentPage', page)}
                      totalPage={mainTblTotal.totalPage}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {isOpenEditPriority && (
        <EditPriority
          header={'Edit priority'}
          columns={[
            {
              label: 'Priority',
              field: 'priority',
              sort: true,
            },
            {
              label: 'Name',
              field: 'name',
              sort: true,
            },
            {
              label: 'Capacity',
              field: 'capacity',
              sort: true,
            },
          ]}
          isStateless={isStateLess}
          rows={mainRowsCurrentPage}
          open={isOpenEditPriority}
          onClose={() => setIsOpenEditPriority(false)}
          onEdit={handleEditPriority}
        />
      )}

      {isOpenAddUnmanagedState && (
        <UnmanagedStateModal
          cloudId={cloudId}
          region={region}
          columns={[
            {
              label: 'Name',
              field: 'name',
              sort: true,
            },
          ]}
          rows={mainTblRows}
          isStateless={isStateLess}
          open={isOpenAddUnmanagedState}
          onClose={() => setIsOpenAddUnmanagedState(false)}
          onAdd={handleAddRuleGroups}
        />
      )}

      {isOpenAddManagedState && (
        <AddManagedStatefulRuleGroupModal
          cloudId={cloudId}
          rules={statefulRuleGroupReferences}
          region={region}
          open={isOpenAddManagedState}
          onClose={() => setIsOpenAddManagedState(false)}
          onAddRuleGroups={handleAddMangedRuleGroups}
        />
      )}

      {isOpenDisassociate && (
        <DisassociateRuleGroups
          ruleGroups={disassociateRuleGroups}
          policyName={firewallPolicyName}
          open={isOpenDisassociate}
          onClose={() => setIsOpenDisassociate(false)}
          onDelete={handleDelRuleGroups}
        />
      )}
    </>
  );
};

export default StateRuleGroups;
