import './index.scss';
import { useEffect, useState } from 'react';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import DetailItem from '../../DetailItem';
import lazyGetAwsFinding, { IGetAwsFindingVariables } from 'graphql/queries/getAwsFinding';
import Space from 'pages/v2/Organ/Management/components/Space';
import { getBetweenTwoDate, randomString } from 'utils/Common';
import Icon from 'components/v2/atoms/Icon';
import CloseIcon from 'assets/svgs/v2/ico_close.svg';

const FindingsItemSummary = ({ cloudId, region, data: dataItem, setData: setDataItem }: any) => {
  const [getAwsFinding] = lazyGetAwsFinding();

  const [data, setData] = useState<any>(null);

  const {
    type,
    id,
    severity,
    title,
    region: currentRegion,
    accountId,
    resourcesAffected,
    createdAt,
    updatedAt,
    classificationDetails,
    policyDetails,
    archived,
    sample,
    count,
  } = data || {};

  const handleGetData = async () => {
    const findingsVariable: IGetAwsFindingVariables = {
      cloudId,
      region,
      reqData: {
        findingIds: [dataItem.id],
      },
    };

    const { data: findings } = await getAwsFinding({
      variables: findingsVariable,
    });

    setData(findings?.getAwsFindings?.data?.[0]?.findings?.[0]);
  };

  useEffect(() => {
    if (!dataItem?.id) return;

    handleGetData();
  }, [dataItem?.id]);

  const overview: IMgdDetailKeyValueProps[] = [
    {
      title: 'Severity',
      description: severity?.description,
    },
    {
      title: 'Region',
      description: currentRegion,
    },
    {
      title: 'Account ID',
      description: accountId,
    },
    {
      title: 'Resource',
      description: resourcesAffected?.s3Object?.path,
    },
    {
      title: 'Created at',
      description: getBetweenTwoDate(createdAt),
    },
    {
      title: 'Updated at',
      description: getBetweenTwoDate(updatedAt),
    },
    {
      title: 'Origin type',
      description: classificationDetails?.originType,
    },
  ];

  const result: IMgdDetailKeyValueProps[] = [
    {
      title: 'Job ID',
      description: classificationDetails?.jobId,
    },
  ];

  const details: IMgdDetailKeyValueProps[] = [
    {
      title: 'Status',
      description: classificationDetails?.result?.status?.code,
    },
    {
      title: 'Size classified',
      description: classificationDetails?.result?.sizeClassified,
    },
    {
      title: 'MIME type',
      description: classificationDetails?.result?.mimeType,
    },
    {
      title: 'Detailed result location',
      description: classificationDetails?.detailedResultLocation,
    },
  ];

  const totalSensitiveData = classificationDetails?.result?.sensitiveData?.length
    ? classificationDetails?.result?.sensitiveData.reduce((result: number, cur: any) => {
        return (result += cur?.totalCount);
      }, 0)
    : 0;

  const sensitiveData: IMgdDetailKeyValueProps[] = [
    {
      title: 'Total count',
      description: +(+classificationDetails?.result?.customDataIdentifiers?.totalCount + totalSensitiveData) || 0,
    },
    {
      title: 'Reveal samples ',
      description: 'Enter setting',
    },
  ];

  const resourcesAffects: IMgdDetailKeyValueProps[] = [
    {
      title: 'Bucket name',
      description: resourcesAffected?.s3Bucket?.name,
    },
    {
      title: 'Public access',
      description: resourcesAffected?.s3Bucket?.publicAccess?.effectivePermission,
    },
    {
      title: 'Encryption required by bucket policy',
      description: resourcesAffected?.s3Bucket?.allowsUnencryptedObjectUploads ? 'No' : 'Yes',
    },
    {
      title: 'Default encryption',
      description: resourcesAffected?.s3Bucket?.defaultServerSideEncryption?.encryptionType,
    },
    {
      title: 'Created at',
      description: getBetweenTwoDate(resourcesAffected?.s3Bucket?.createdAt),
    },
    {
      title: 'Owner',
      description: resourcesAffected?.s3Bucket?.owner?.displayName,
    },
  ];

  const tagsBucket: IMgdDetailKeyValueProps[] = resourcesAffected?.s3Bucket?.tags?.length
    ? resourcesAffected?.s3Bucket?.tags.map((e: any) => ({
        title: e.key,
        description: e.value,
      }))
    : [];

  const resourcesAffectedData: IMgdDetailKeyValueProps[] = [
    {
      title: 'Key',
      description: resourcesAffected?.s3Object?.key,
    },
    {
      title: 'Public access',
      description: resourcesAffected?.s3Object?.publicAccess?.toString(),
    },
    {
      title: 'Default encryption',
      description: resourcesAffected?.s3Object?.serverSideEncryption?.encryptionType,
    },
    {
      title: 'Size',
      description: resourcesAffected?.s3Object?.size,
    },
    {
      title: 'Last modified',
      description: getBetweenTwoDate(resourcesAffected?.s3Object?.lastModified),
    },
  ];

  const tagsObject: IMgdDetailKeyValueProps[] = resourcesAffected?.s3Object?.tags?.length
    ? resourcesAffected?.s3Object?.tags.map((e: any) => ({
        title: e.key,
        description: e.value,
      }))
    : [];

  const action: IMgdDetailKeyValueProps[] = [
    {
      title: 'Action type',
      description: policyDetails?.action?.actionType,
    },
    {
      title: 'Service name',
      description: policyDetails?.action?.apiCallDetails?.apiServiceName,
    },
    {
      title: 'API',
      description: policyDetails?.action?.apiCallDetails?.api,
    },
    {
      title: 'First seen',
      description: getBetweenTwoDate(policyDetails?.action?.apiCallDetails?.firstSeen),
    },
    {
      title: 'Last seen',
      description: getBetweenTwoDate(policyDetails?.action?.apiCallDetails?.lastSeen),
    },
  ];

  const actor: IMgdDetailKeyValueProps[] = [
    {
      title: 'Identity type',
      description: policyDetails?.actor?.userIdentity?.type,
    },
    {
      title: 'User name',
      description:
        policyDetails?.actor?.userIdentity?.type === 'IAMUser' && policyDetails?.actor?.userIdentity?.iamUser?.userName,
    },
    {
      title: 'Account Id',
      description:
        policyDetails?.actor?.userIdentity?.type === 'ASSUMED_ROLE' &&
        policyDetails?.actor?.userIdentity?.assumedRole?.accountId,
    },
    {
      title: 'Principal id',
      description:
        policyDetails?.actor?.userIdentity?.type === 'ASSUMED_ROLE' &&
        policyDetails?.actor?.userIdentity?.assumedRole?.principalId,
    },
  ];

  const sessionIssuer: IMgdDetailKeyValueProps[] = [
    {
      title: 'Account id',
      description: policyDetails?.actor?.userIdentity?.assumedRole?.sessionContext?.sessionIssuer?.accountId,
    },
    {
      title: 'Arn',
      description: policyDetails?.actor?.userIdentity?.assumedRole?.sessionContext?.sessionIssuer?.arn,
    },
    {
      title: 'Principal id',
      description: policyDetails?.actor?.userIdentity?.assumedRole?.sessionContext?.sessionIssuer?.principalId,
    },
    {
      title: 'Type',
      description: policyDetails?.actor?.userIdentity?.assumedRole?.sessionContext?.sessionIssuer?.type,
    },
    {
      title: 'User name',
      description: policyDetails?.actor?.userIdentity?.assumedRole?.sessionContext?.sessionIssuer?.userName,
    },
  ];

  const ipDetails: IMgdDetailKeyValueProps[] = [
    {
      title: 'IP address',
      description: policyDetails?.actor?.ipAddressDetails?.ipAddressV4,
    },
    {
      title: 'City',
      description: policyDetails?.actor?.ipAddressDetails?.ipCity?.name,
    },
    {
      title: 'Country',
      description: policyDetails?.actor?.ipAddressDetails?.ipCountry?.name,
    },
    {
      title: 'IP owner ISP',
      description: policyDetails?.actor?.ipAddressDetails?.ipOwner?.isp,
    },
    {
      title: 'IP owner ASN org',
      description: policyDetails?.actor?.ipAddressDetails?.ipOwner?.org,
    },
  ];

  const additionalInformation: IMgdDetailKeyValueProps[] = [
    {
      title: 'Archived',
      description: archived?.toString(),
    },
    {
      title: 'Sample',
      description: sample?.toString(),
    },
    {
      title: 'Count',
      description: count,
    },
  ];

  const titleSection: any = {
    CUSTOM_IDENTIFIER: 'Custom data identifiers section',
    FINANCIAL_INFORMATION: 'Financial information section',
    PERSONAL_INFORMATION: 'Personal information section',
  };

  return dataItem?.id ? (
    <div className="box-summary-horizontal">
      <div className="box-summary-info">
        <div className="box-summary-info-title">
          <span>item: {type}</span>

          <Icon className="suffix-btn" onClick={() => setDataItem(undefined)} width={10} height={10} src={CloseIcon} />
        </div>

        <div className="box-summary-info-body">
          <div className="info-body-id">Finding ID: {id}</div>

          <Space size={8} />

          <div className="info-body-description">
            <span>{severity?.description}</span>

            <p>{title}</p>
          </div>
        </div>
      </div>

      <DetailItem title="Overview" data={overview} />

      <DetailItem title="Result" data={result} />

      <DetailItem title="Details" data={details} />

      <DetailItem title="Sensitive Data" data={sensitiveData} />

      <DetailItem
        title="Credentials section"
        data={[
          {
            title: 'All eng for sentences',
            description: classificationDetails?.result?.customDataIdentifiers?.detections?.length
              ? classificationDetails?.result?.customDataIdentifiers?.detections?.map(
                  (i: any) => `${i.name}: ${i.count}`,
                )
              : '',
          },
        ]}
      />

      {classificationDetails?.result?.sensitiveData?.length
        ? classificationDetails?.result?.sensitiveData.map((e: any) =>
            titleSection[e?.category] ? (
              <DetailItem
                key={randomString()}
                title={titleSection[e?.category]}
                data={[
                  {
                    title: 'All eng for sentences',
                    description: e?.detections?.length ? e?.detections?.map((i: any) => `${i.type}: ${i.count}`) : '',
                  },
                ]}
              />
            ) : null,
          )
        : null}

      <DetailItem title="Resources affects (S3 bucket)" data={resourcesAffects} />

      <DetailItem title="Tags" data={tagsBucket} />

      <DetailItem title="Resources affected (S3 object)" data={resourcesAffectedData} />

      <DetailItem title="Tags" data={tagsObject} />

      <DetailItem title="Action" data={action} />

      <DetailItem title="Actor" data={actor} />

      {policyDetails?.actor?.userIdentity?.type === 'ASSUMED_ROLE' ? (
        <DetailItem title="Session issuer" data={sessionIssuer} />
      ) : null}

      <DetailItem title="IP details" data={ipDetails} />

      <DetailItem title="Additional information" data={additionalInformation} />
    </div>
  ) : null;
};

export default FindingsItemSummary;
