import { gql } from '@apollo/client';

const query = gql`
  query getAwsSecretValue($request: AwsGetSecretValueRequest, $cloudId: Long, $region: String) {
    getAwsSecretValue(getSecretValueRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetSecretValueResponse {
          arn
          name
          versionId
          secretBinary {
            SdkBytes
          }
          secretString
          versionStages
          createdDate
        }
      }
    }
  }
`;
export default query;
