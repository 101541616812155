import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { AwsTagType } from 'graphql/types/AwsVpc';

export type AwsScheduleKeyDeletionVariables = {
  cloudId: number;
  region: string;
  reqData: {
    keyId?: string;
    pendingWindowInDays?: number;
  };
};

export type AwsScheduleKeyDeletionResponseData = {
  scheduleAwsKeyDeletion: IGqlResponseData<undefined>;
};

const scheduleAwsKeyDeletionMutation = () =>
  useMutation<AwsScheduleKeyDeletionResponseData, AwsScheduleKeyDeletionVariables>(query);
export default scheduleAwsKeyDeletionMutation;
