import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import RequiredIcon from 'assets/svgs/v2/ico_require.svg';
import { ReactNode } from 'react';
interface IInputProps {
  label?: string;
  error?: string;
  required?: boolean;
  topDescription?: string;
  bottomDescription?: string;
  children: ReactNode;
}

const InputCustomDesc = (props: IInputProps) => {
  const { label, error, topDescription, bottomDescription, children, required } = props;

  return (
    <div id="input-container">
      <div className="row-content">
        <div className="left-column-content" />
        <div>
          <p className="description">{topDescription}</p>
        </div>
      </div>
      <div className="row-content">
        {!!label && (
          <div className="left-column-content">
            <p className="title">{label}</p>
            {!!required && <img src={RequiredIcon} width={3.5} height={3.5} />}
          </div>
        )}
        {children}
      </div>
      {error !== '' ? (
        <div className="row-content">
          {!!label && <div className="left-column-content" />}
          <div className="error-container right-column-content">
            <img src={InputErrorIcon} width={16} height={16} />
            <p className="error-message">{error}</p>
          </div>
        </div>
      ) : (
        <div className="row-content">
          <div className="left-column-content" />
          <div className="right-column-content">
            <p className="description">{bottomDescription}</p>
          </div>
        </div>
      )}
    </div>
  );
};
export default InputCustomDesc;
