import SectionContainer from 'pages/v2/Organ/Management/components/SectionContainer';
import './index.scss';
import React, { useMemo } from 'react';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import { SCOPE_SECRET_TYPE_RADIO_LIST } from '../configs';
import { ColumnType, RowType } from '@Types/v2/Table';
import TagTab from 'pages/v2/Organ/Management/components/TagTab';
import { CreationDataType } from '../type';
type ReviewStepType = {
  creationData: CreationDataType
}
const ReviewStep = (props: ReviewStepType) => {
  const { creationData } = props;
  const secretTypeData = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      {
        id: 'secret-type',
        type: TextTypeEnum.NORMAL,
        title: 'Secret type',
        description: SCOPE_SECRET_TYPE_RADIO_LIST.find(data => data.value === creationData?.secretType)?.name,
      },
      {
        id: 'encryption-key',
        type: TextTypeEnum.NORMAL,
        title: 'Encryption key',
        description: creationData.keyEncryption,
      },
    ];
  }, [creationData]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return creationData?.tags ?? [];
  }, [creationData]);

  const secretConfigurationData = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      {
        id: 'secret-name',
        type: TextTypeEnum.NORMAL,
        title: 'Secret name',
        description: creationData.secretName,
      },
      { id: 'description', type: TextTypeEnum.NORMAL, title: 'Description', description: creationData.description },
      { id: 'tags', type: TextTypeEnum.NORMAL, title: 'tags', description: creationData.tags.length },
      {
        id: 'resource-permissions',
        type: TextTypeEnum.NORMAL,
        title: 'Resource permissions',
        description: creationData.editPermission ? creationData.resourcePolicy : '',
      },
      { id: 'secret-replication', type: TextTypeEnum.NORMAL, title: 'Secret replication', description: 'Disabled' },
    ];
  }, [creationData]);

  const secretRotationScheduleData = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      {
        id: 'automatic-rotation',
        type: TextTypeEnum.NORMAL,
        title: 'Automatic rotation',
        description: creationData.isAutoRotation ? 'Enabled' : 'Disabled',
      },
      {
        id: 'rotation-schedule',
        type: TextTypeEnum.NORMAL,
        title: 'Rotation schedule',
        description: creationData.scheduleExpression,
      },
      {
        id: 'window-duration',
        type: TextTypeEnum.NORMAL,
        title: 'Window duration',
        description: creationData.duration,
      },
    ];
  }, [creationData]);

  const secretRotationFunctionData = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      {
        id: 'lambda-rotation-function',
        type: TextTypeEnum.NORMAL,
        title: 'Lambda rotation function',
        description: creationData.lambdaRotationFuncValue,
      },
      {
        id: 'performs-rotation',
        type: TextTypeEnum.NORMAL,
        title: 'Secret that performs rotation',
        description: 'Secret I provided in step 1',
      },
    ];
  }, [creationData]);

  return (
    <div className="type-step">
      <DetailTab title={'Secret type'} data={secretTypeData} />
      <DetailTab title={'Secret configuration'} data={secretConfigurationData} />
      {creationData.tags.length > 0 ? (
        <>
          <TagTab title={'tags'} rows={tagRows} columns={tagColumns} />
          <div className="mb-8" />
        </>
      ) : (
        <></>
      )}
      <DetailTab title={'Rotation schedule'} data={secretRotationScheduleData} />
      <DetailTab title={'Rotation function'} data={secretRotationFunctionData} />
    </div>
  );
};
export default ReviewStep;
