import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import lazyGetAwsListAliases from 'graphql/queries/getAwsListAliases';
import lazyGetAwsListKeys from 'graphql/queries/getAwsListKeys';
import { useCallback, useEffect, useState } from 'react';
import CusMultiSelectAutocomplete from '../components/CusMultiSelectAutocomplete';
import { ICreationDataPropsType } from './type';
import { AwsAliasListType } from 'graphql/types/AwsListAliases';

interface IConfigureAdvancedSettings {
  title: string;
  subTitle: string;
  creationData: ICreationDataPropsType;
  updateCreationData: (data: ICreationDataPropsType) => void;
}

const ConfigureAdvancedSettings = ({
  title,
  subTitle,
  creationData,
  updateCreationData,
}: IConfigureAdvancedSettings) => {
  const { region, cloudId, isUseCustomizeEncryption, awsKey } = creationData;
  const [listKey, setListKey] = useState<DropdownListDataType[]>([]);
  const [getListKey] = lazyGetAwsListKeys();
  const [getAliases] = lazyGetAwsListAliases();
  const [error, setError] = useState('');
  const [awsKeyInfo, setAwsInfo] = useState<AwsAliasListType | undefined>(undefined);
  const seleted = awsKey.id ? [{ value: awsKey.id }] : [];
  const setIsCustomizedEncryption = (value: boolean) => {
    updateCreationData({
      ...creationData,
      isUseCustomizeEncryption: value,
      configureAdvancedError: '',
      awsKey: { id: '' },
    });
  };
  const setAwsKey = useCallback(
    (value: string) => {
      updateCreationData({ ...creationData, awsKey: { id: value } });
    },
    [creationData, updateCreationData],
  );
  const getDataListKeys = useCallback(async () => {
    const { data } = await getListKey({
      variables: {
        cloudId,
        region,
        request: {
          limit: 50,
        },
      },
    });
    if (!data?.getAwsListKeys.data.length) {
      return;
    }
    const list: DropdownListDataType[] = data.getAwsListKeys.data[0].keys.map(item => ({
      name: item.keyId,
      description: item.keyArn,
      value: item.keyId,
    }));
    setListKey(list);
  }, [cloudId, getListKey, region]);
  const handleGetAwsListAliases = useCallback(
    async (keyId: string) => {
      try {
        if (!keyId) return;
        const variables = {
          cloudId,
          region,
          request: {
            keyId,
          },
        };
        const { data } = await getAliases({ variables });
        if (!data?.getAwsListAliases?.data?.length || !data?.getAwsListAliases?.data[0].aliases?.length) {
          setError('This key is not enabled. Enable this key or choose a different key.');
          setAwsKey('');

          return;
        }
        setError('');
        setAwsInfo(data?.getAwsListAliases?.data?.[0]?.aliases?.[0]);
      } catch (err) {
        setAwsKey('');
        setError('This key is not enabled. Enable this key or choose a different key.');
      }
    },
    [cloudId, getAliases, region, setAwsKey],
  );
  useEffect(() => {
    getDataListKeys();
  }, [getDataListKeys]);
  useEffect(() => {
    if (awsKey.id) {
      handleGetAwsListAliases(awsKey.id);
    }
  }, [awsKey, handleGetAwsListAliases]);

  return (
    <div className="rule-group-container">
      <div className="title-group">
        <p className="title">{title}</p>
        <p className="sub-title">{subTitle}</p>
      </div>
      <div className="rule-group-details-container">
        <div className="rule-group-detail-title">
          Customer managed key
          <div className="rule-group-detail-sub-title">
            You can use a customer managed key in AWS Key Management Service (KMS) to encrypt your data at rest. If you
            don’t configure a customer managed key, Network Firewall encrypt your data using AWS managed key.
          </div>
        </div>
        <div className="rule-group-detail-title" style={{ borderBottom: 'none' }}>
          <div className="rule-group-detail-sub-title">
            Your data is encrypted by default with a key that AWS owns and manages for you. To choose a different key,
            customize your encryption settings.
          </div>
        </div>
        <div className="rule-group-detail-content" style={{ padding: '0px 16px 16px 16px' }}>
          <div style={{ display: 'flex', gap: 8 }}>
            <CheckboxAtom
              checked={isUseCustomizeEncryption}
              onchange={() => setIsCustomizedEncryption(!isUseCustomizeEncryption)}
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              <p className="text-content">Customize encryption settings (advanced)</p>
              {isUseCustomizeEncryption && (
                <p style={{ fontSize: 12, color: '#687078' }}>To use the default key, clear this option.</p>
              )}
            </div>
          </div>
          {isUseCustomizeEncryption && (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                <p className="text-content">Choose an AWS KMS key</p>
                <p style={{ fontSize: 12, color: '#687078' }}>
                  This key will be used for encryption instead of the default key.
                </p>
              </div>
              <div style={{ display: 'flex', gap: 10, width: 500 }}>
                <CusMultiSelectAutocomplete
                  placeholder="Choose an AWS KMS key or enter an ARN"
                  data={listKey}
                  selectedValues={seleted}
                  handleClick={(val: DropdownListDataType) => setAwsKey(val.value.toString())}
                  handleRemove={(val: DropdownListDataType) => setAwsKey('')}
                  error={error}
                />
                <button className="button" style={{ margin: 0, marginTop: 10 }} onClick={() => setAwsKey('')}>
                  Remove
                </button>
              </div>
            </div>
          )}
          {awsKey.id && (
            <div className="rule-group-detail-title" style={{ borderBottom: 'none' }}>
              AWS KMS key details
              <div className="rule-group-detail-sub-title">
                <p className="text-content">Key ARN</p>
                <p>{awsKeyInfo?.aliasArn}</p>
              </div>
              <div className="rule-group-detail-sub-title">
                <p className="text-content">Key status</p>
                <p>{!error ? 'Enabled' : 'Disabled'}</p>
              </div>
              <div className="rule-group-detail-sub-title">
                <p className="text-content">Key aliases</p>
                <p>{awsKeyInfo?.aliasName}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfigureAdvancedSettings;
