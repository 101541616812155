import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { AwsPutKeyPolicyRequestType } from 'graphql/types/AwsPutKeyPolicy';
import { AwsEnableKeyRotationRequestType } from 'graphql/types/AwsEnableKeyRotation';

export type AwsEnableKeyRotationVariablesType = {
  cloudId: number;
  region: string;
  reqData: AwsEnableKeyRotationRequestType;
};

export type AwsEnableKeyRotationResponseDataType = {
  enableAwsKeyRotation: IGqlResponseData<{}>;
};

const enableAwsKeyRotationMutation = () =>
  useMutation<AwsEnableKeyRotationResponseDataType, AwsEnableKeyRotationVariablesType>(query);
export default enableAwsKeyRotationMutation;
