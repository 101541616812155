import lazyGetAwsListAliases, { IAwsListAliasesVariables } from 'graphql/queries/getAwsListAliases';
import { AliasesDetailPropsType } from './types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Table from 'components/v2/dataDisplay/Table';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { AwsAliasListType } from 'graphql/types/AwsListAliases';
import ButtonGroup from 'pages/v2/Organ/Management/components/ButtonGroup';

const Aliases = (props: AliasesDetailPropsType) => {
  const { actionButtons, aliases, loading, selectedAlias, setSelectedAlias, checkedAliases, setCheckedAliases } = props;

  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 100,
    itemPerPage: 100,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const columns = useMemo((): ColumnType[] => {
    return [
      { label: '', field: '', sort: false, width: 16 },
      { label: 'Alias name', field: 'name', sort: true },
      { label: 'Alias ARN', field: 'arn', sort: true },
    ];
  }, []);

  const rows = useMemo((): RowType[] => {
    return aliases?.map(item => {
      return {
        id: item.aliasName,
        name: item.aliasName,
        arn: item.aliasArn,
      };
    });
  }, [aliases]);

  const rowsCurrentPage = useMemo(() => {
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(rows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [rows, mainTablePagination]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setMainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  if (loading) {
    return (
      <div className="progress-container">
        <div className="progress-gif" />
        Loading ...
      </div>
    );
  }

  return (
    <div className="detail-block-container">
      <div className="header-container">
        <div className="table-header">Aliases</div>
        <ButtonGroup buttons={actionButtons ?? []} />
      </div>
      <Table
        rows={rowsCurrentPage}
        columns={columns}
        reportSelected={setSelectedAlias}
        reportCheckedList={setCheckedAliases}
        sortOption={{
          target: mainTablePagination.target,
          direction: mainTablePagination.direction,
          onChangeSort: (target: string, dir: OrderDirection) => {
            updateTablePagination('target', target);
            updateTablePagination('direction', dir);
          },
        }}
        isLoading={false}
        horizontalScrollable={true}
      />
    </div>
  );
};

export default Aliases;
