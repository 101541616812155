import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { AwsTagType } from 'graphql/types/AwsVpc';

export type TagAwsResourceKmsVariables = {
  cloudId: number;
  region: string;
  reqData: {
    keyId?: string;
    tags?: Array<{
      tagKey?: string;
      tagValue?: string;
    }>;
  };
};

export interface TagAwsResourceKmsResponseData {
  tagAwsResourceKms: IGqlResponseData<undefined>;
}

const tagAwsResourcesKmsMutation = () => useMutation<TagAwsResourceKmsResponseData, TagAwsResourceKmsVariables>(query);
export default tagAwsResourcesKmsMutation;
