import { TagDetailPropsType } from './types';
import { ColumnType, RowType } from '@Types/v2/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TagTab from 'pages/v2/Organ/Management/components/TagTab';
import lazyGetAwsTags, { IAwsGetTagsVariables } from 'graphql/queries/getAwsTags';

const Tag = (props: TagDetailPropsType) => {
  const { cloudId, region, resourceGroup, onEditClicked, tagRows, loading } = props;
  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const rightButtonNode = useMemo(() => {
    return [
      { id: 'edit-btn', label: 'Edit', onClick: onEditClicked }
    ];
  }, [onEditClicked]);

  const reqVariable = useMemo((): IAwsGetTagsVariables => {
    return { cloudId, region, request: { arn: resourceGroup.groupArn } };
  }, [cloudId, region, resourceGroup]);

  if (loading) {
    return (
      <div className="progress-container">
        <div className="progress-gif" />
        Loading ...
      </div>
    );
  }

  return (
    <TagTab
      rows={tagRows}
      columns={tagColumns}
      title={'Resource group tags'}
      caption={
        'The tags applied to the resource group. The tags specified here are applied to the resource group itself, not the individual resources within it.'
      }
      rightButtons={rightButtonNode}
    />
  );
};

export default Tag;
