import lazyGetAwsFirewallPolicy from 'graphql/queries/getAwsFirewallPolicy';
import { AwsDescribeFirewallPolicyResponse } from 'graphql/types/AwsFirewallPolicy';
import PageDetailTitle from 'pages/v2/Organ/Management/components/PageDetailTitle';
import { useEffect, useState } from 'react';
import { IBaseFirewallProps } from '../../../types';
import Details from '../Common/Components/Details';
import FirewallPolicySettings from '../Common/Components/FirewallPolicySettings';
import Overview from '../Common/Components/Overview';
import DeleteResource from '../DeleteResource';
import { firewallTab } from '../configs';
import './index.scss';

interface IFirewallDetailProps extends IBaseFirewallProps { }

const FirewallDetail = (props: IFirewallDetailProps) => {
  const { data, setData, pageBackClick = () => { }, cloudId, region } = props;
  const [tabSelected, setTabSelected] = useState(firewallTab[0]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [firewallPolicies, setFirewallPolicies] = useState<AwsDescribeFirewallPolicyResponse | undefined>(undefined)

  const [getAwsFirewallPolicy, { loading }] = lazyGetAwsFirewallPolicy();

  const handleGetAwsFirewallPolicy = async () => {
    if (!data?.id) return;
    try {
      const variables = {
        cloudId,
        region,
        request: {
          firewallPolicyArn: data?.firewall?.firewallPolicyArn,
        },
      };

      const response = await getAwsFirewallPolicy({
        variables,
      });

      setFirewallPolicies(response.data?.getAwsFirewallPolicy?.data?.[0]);
    } catch (error) { }
  };

  useEffect(() => {
    setTabSelected(firewallTab[0]);
  }, [data?.id]);

  useEffect(() => {
    handleGetAwsFirewallPolicy();
  }, [tabSelected, data?.firewall?.firewallPolicyArn])

  const renderTab = () => {
    switch (tabSelected.id) {
      case firewallTab[0].id:
        return (
          <Details
            cloudId={cloudId}
            region={region}
            firewallPolicyResponse={firewallPolicies?.firewallPolicyResponse}
            data={data}
            setData={setData}
            isEdit
          />
        );

      case firewallTab[1].id:
        return (
          <FirewallPolicySettings
            cloudId={cloudId}
            region={region}
            firewallPolicies={firewallPolicies}
            onRefreshFirewallPolicy={handleGetAwsFirewallPolicy}
            isEdit
          />
        );

      case firewallTab[2].id:

      default:
        return null;
    }
  };

  return (
    <>
      <div className="page-detail">
        <PageDetailTitle
          title={data?.name}
          pageBackClick={pageBackClick}
          isDelete
          onDelete={() => setIsOpenDelete(true)}
        />

        <Overview
          isLoading={loading}
          firewallStatus={data?.firewallStatus.status}
          firewallPolicyName={firewallPolicies?.firewallPolicyResponse.firewallPolicyName}
          vpcId={data?.firewall.vpcId}
        />

        <div className="page-detail-tab">
          <div className="detail-tab flex a-center">
            {firewallTab.map(tab => {
              return (
                <button
                  className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
                  key={tab.id}
                  data-tab={tab.id}
                  onClick={e => setTabSelected(tab)}
                >
                  <p>{tab.name}</p>
                </button>
              );
            })}
          </div>

          <div className="content-tab">{renderTab()}</div>
        </div>
      </div>

      {isOpenDelete && (
        <DeleteResource
          header={'Delete resource'}
          cloudId={cloudId}
          region={region}
          data={data}
          setData={setData}
          open={isOpenDelete}
          onClose={() => setIsOpenDelete(false)}
        />
      )}
    </>
  );
};

export default FirewallDetail;
