import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { FilterPropsType } from 'pages/v2/Organ/Management/EC2/components/Image/types';
import { handleLinkClicked, variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import InputAtom from 'components/v2/atoms/InputAtom';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { networkInterfaceFilterDropdown } from 'pages/v2/Organ/Management/EC2/components/NetworkInterface/Common/Constant';
import lazyGetAwsNetworkInterfaces from 'graphql/queries/getAwsNetworkInterfaces';
import { handleFormatText } from 'utils/Common';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { NETWORK_TAB_LIST } from 'pages/v2/Organ/Management/Network/configs';
import { subnetFilterDropdown, vpcFilterDropdown } from 'pages/v2/Organ/Management/Network/Constants';

const NetworkInterface = ({ cloudId, region, setDetailItem, setPageDetail }: any) => {
  const navigate = useNavigate()

  const [mainIsLoading, setMainIsLoading] = useState<boolean>(false);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainNextToken, setMainNextToken] = useState<string>('');

  const [mainFilterValue, setMainFilterValue] = useState<DropdownListDataType>(networkInterfaceFilterDropdown[0]);
  const [searchValue, setSearchValue] = useState<FilterPropsType>({
    name: mainFilterValue.value as string,
    value: '',
  });
  const [scopeNFilterVariable, setScopeNFilterVariable] = useState<FilterPropsType>();

  const handleGetNameTag = (data: any) => {
    if (!data?.length) return '-';

    const item = data.find((e: any) => e.key === 'Name');

    return item?.value || '-';
  };

  const renderUnderlineItem = useCallback((row: RowType, text: string, onClick: () => void) => {
    return (
      <div
        className="link"
        onClick={onClick}
      >
        {handleFormatText(text)}
      </div>
    );
  }, []);

  const columns: Array<ColumnType> = [
    {
      label: 'Name',
      field: 'name',
      sort: true,
    },
    {
      label: 'Network interface ID',
      field: 'networkInterfaceId',
      sort: true,
      renderCell: row => (
        <div
          className="link"
          onClick={e => {
            e.stopPropagation();
            setPageDetail(row);
          }}
        >
          {handleFormatText(row?.networkInterfaceId)}
        </div>
      )
    },
    {
      label: 'Subnet ID',
      field: 'subnetId',
      sort: true,
      renderCell: row => renderUnderlineItem(row, row?.subnetId, () => handleLinkClicked({
        navigate,
        link: '/organ/1/manage/network',
        type: ManagementTypeEnum.NETWORK,
        tabId: NETWORK_TAB_LIST[2].id,
        key: subnetFilterDropdown[0].value.toString(),
        value: row?.subnetId,
      })),
    },
    {
      label: 'VPC ID',
      field: 'vpcId',
      sort: true,
      renderCell: row => renderUnderlineItem(row, row?.vpcId, () => handleLinkClicked({
        navigate,
        link: '/organ/1/manage/network',
        type: ManagementTypeEnum.NETWORK,
        tabId: NETWORK_TAB_LIST[0].id,
        key: vpcFilterDropdown[0].value.toString(),
        value: row?.vpcId,
      })),
    },
    {
      label: 'Availability zone',
      field: 'availabilityZone',
      sort: true,
    },
    {
      label: 'Security group names',
      field: 'securityGroupNames',
      sort: true,
    },
    {
      label: 'Security group IDs',
      field: 'securityGroupIds',
      sort: true,
    },
    {
      label: 'Interface Type',
      field: 'interfaceType',
      sort: true,
    },
    {
      label: 'Description',
      field: 'description',
      sort: true,
    },
    {
      label: 'Instance ID',
      field: 'instanceId',
      sort: true,
    },
    {
      label: 'Status',
      field: 'status',
      sort: true,
    },
    {
      label: 'Public IPv4 address',
      field: 'publicIPv4Address',
      sort: true,
    },
    {
      label: 'Primary private IPv4 address',
      field: 'primaryPrivateIpv4Address',
      sort: true,
    },
    {
      label: 'Secondary public IPv4 address',
      field: 'secondaryPublicIpv4Address',
      sort: true,
    },
    {
      label: 'Secondary private IPv4 addresses',
      field: 'secondaryPrivateIpv4Address',
      sort: true,
    },
    {
      label: 'IPv6 addresses',
      field: 'ipv6Addresses',
      sort: true,
    },
    {
      label: 'Primary IPv6 address',
      field: 'primaryIpv6Address',
      sort: true,
    },
    {
      label: 'IPv4 prefix',
      field: 'ipv4Prefix',
      sort: true,
    },
    {
      label: 'IPv6 Prefix',
      field: 'ipv6Prefix',
      sort: true,
    },
    {
      label: 'Owner',
      field: 'owner',
      sort: true,
    },
    {
      label: 'Outpost ID',
      field: 'outpostId',
      sort: true,
    },
    {
      label: 'ENA express',
      field: 'enaExpress',
      sort: true,
    },
    {
      label: 'ENA express UD',
      field: 'enaExpressUd',
      sort: true,
    },
  ];

  const [getAwsNetworkInterfaces] = lazyGetAwsNetworkInterfaces();

  const awsDescribeVariable = useMemo((): {
    request: { maxResults: number; filters: {}[] };
    cloudId: string;
    region: string;
  } => {
    // @ts-ignore
    let searchFilterItem = {};

    if (scopeNFilterVariable) {
      searchFilterItem = {
        name: scopeNFilterVariable?.name,
        values: [scopeNFilterVariable?.value],
      };
    }

    return {
      cloudId,
      region,
      request: {
        maxResults: mainTablePagination.limit,
        filters: [{ ...searchFilterItem }],
      },
    };
  }, [cloudId, region, scopeNFilterVariable]);

  const handleGetAwsNetworkInterfaces = useCallback(
    async (nextToken?: string) => {
      return getAwsNetworkInterfaces({
        variables: variableCombineNextToken(awsDescribeVariable, nextToken),
      });
    },
    [awsDescribeVariable],
  );

  const handleGetData = useCallback(
    async (nextToken?: string, isInitial?: boolean) => {
      try {
        setMainIsLoading(true);
        const res = await handleGetAwsNetworkInterfaces(nextToken);
        setMainNextToken(res?.data?.getAwsNetworkInterfaces?.nextToken as string);

        const arrRes = res?.data?.getAwsNetworkInterfaces?.data?.[0]?.networkInterfaces?.length
          ? res.data.getAwsNetworkInterfaces.data[0].networkInterfaces.map(networkInterface => {
              const {
                tagSet,
                networkInterfaceId,
                subnetId,
                vpcId,
                availabilityZone,
                groups,
                interfaceType,
                description,
                attachment,
                status,
                association,
                privateIpAddress,
                privateIpAddresses,
                ipv6Addresses,
                ipv4Prefixes,
                ipv6Prefixes,
                ownerIdb,
                outpostArn,
              } = networkInterface;

              return {
                ...networkInterface,
                id: networkInterfaceId,
                name: handleGetNameTag(tagSet),
                subnetId: subnetId ?? '-',
                vpcId: vpcId ?? '-',
                availabilityZone: availabilityZone ?? '-',
                securityGroupNames: groups?.map(group => group?.groupName)?.join(',') || '-',
                securityGroupIds: groups?.map(group => group?.groupId)?.join(',') || '-',
                interfaceType: interfaceType === 'interface' ? 'Elastic network interface' : interfaceType ?? '-',
                description: description || '-',
                instanceId: attachment?.instanceId ?? '-',
                status: status ?? '-',
                publicIPv4Address: association?.publicIp ?? '-',
                primaryPrivateIpv4Address: privateIpAddress ?? '-',
                secondaryPublicIpv4Address: privateIpAddresses
                  ?.filter(address => !address?.primary)
                  ?.map(address => address.association?.publicIp),
                secondaryPrivateIpv4Address: privateIpAddresses
                  ?.filter(address => !address?.primary)
                  ?.map(address => address?.privateIpAddress),
                ipv6Addresses:
                  ipv6Addresses && ipv6Addresses?.length > 0 ? ipv6Addresses?.map(ip => ip?.ipv6Address) : '-',
                primaryIpv6Address:
                  ipv6Addresses && ipv6Addresses?.length > 0
                    ? ipv6Addresses?.map(ip => (ip?.isPrimaryIpv6 ? ip?.ipv6Address : '-'))
                    : '-',
                ipv4Prefix: ipv4Prefixes?.map(ip => ip?.ipv4Prefix) || '-',
                ipv6Prefix: ipv6Prefixes?.map(ip => ip?.ipv6Prefix) || '-',
                owner: ownerIdb ?? '-',
                outpostId: outpostArn ?? '-',
                enaExpress: attachment?.enaSrdSpecification?.enaSrdEnabled ? 'Enabled' : 'Disabled' ?? '-',
                enaExpressUd: attachment?.enaSrdSpecification?.enaSrdUdpSpecification?.enaSrdUdpEnabled
                  ? 'Enabled'
                  : 'Disabled' ?? '-',
              };
            })
          : [];

        let allRows: RowType[] = [];
        if (isInitial) {
          allRows = arrRes;
        } else {
          allRows = [...mainTblRows, ...arrRes];
        }

        setMainTblTotal({
          totalPage: Math.ceil(allRows.length / mainTablePagination.itemPerPage),
          totalElement: allRows.length,
        });
        setMainTblRows(allRows);
        setMainIsLoading(false);
      } catch (e) {
        setMainIsLoading(false);
      }
    },
    [awsDescribeVariable, mainTblRows, mainNextToken, mainTblTotal, mainTablePagination],
  );

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      handleGetData(mainNextToken);
    }

    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, mainTblTotal, mainTablePagination, mainNextToken, searchValue]);

  useEffect(() => {
    handleGetData('', true);
  }, [awsDescribeVariable]);

  const handleSearch = useCallback(() => {
    if (searchValue?.value) {
      setScopeNFilterVariable(searchValue);
      return;
    }

    setScopeNFilterVariable(undefined);
  }, [searchValue]);

  const handleSelectItem = (id: string) => {
    const item = mainTblRows.find(e => e.id === id);

    if (!item) return;

    setDetailItem(item);
  };

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Elastic Block store volumes</p>
        </div>

        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={networkInterfaceFilterDropdown}
            value={mainFilterValue}
            handleClick={val => {
              setMainFilterValue(val);
              setSearchValue(prev => ({ ...prev, name: val.value as string }));
            }}
          />

          <InputAtom
            type={'text'}
            value={searchValue.value}
            defaultValue={''}
            onChangeValue={e => setSearchValue(prev => ({ name: prev?.name, value: e }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
          />

          <button className="action-btn" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>

      {!mainRowsCurrentPage.length && !mainIsLoading ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="data-grid-wrap">
          <Table
            rows={mainRowsCurrentPage}
            columns={columns}
            reportCheckedList={() => {}}
            reportSelected={id => handleSelectItem(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={mainIsLoading}
            horizontalScrollable={true}
          />

          {mainRowsCurrentPage?.length && !mainIsLoading ? (
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{mainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={!!mainNextToken}
                currentPage={mainTablePagination.currentPage}
                updateCurrentPage={page =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default NetworkInterface;
