import { useLazyQuery, useQuery } from '@apollo/client';
import query from './query';
import { ListResourcePageByParamType } from 'graphql/types/ResourcePageByParam';
import { Paging } from 'graphql/types/Paging';

export interface IGetResourcePageByParamVariables {
  reqData: {
    organId?: number;
    relObjectType?: string;
    text?: string;
    pageInfo: Paging;
  }
}
export interface IGetResourcePageByParamResponseData {
  getResourcePageByParam: ListResourcePageByParamType;
}

export const lazyGetResourcePageByParam = () => useLazyQuery<IGetResourcePageByParamResponseData, IGetResourcePageByParamVariables>(query, { fetchPolicy: 'no-cache' });