import './index.scss';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdTabLayout/types';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import ButtonGroup from '../ButtonGroup';
import { ButtonPropsType } from '../types';

export type TagPropsType = {
  title: string;
  description?: string;
  caption?: string;
  rows: RowType[];
  columns: ColumnType[];
  rightButtons?: Array<ButtonPropsType>;
  isLoading?: boolean;
};

const TagTab = (props: TagPropsType) => {
  const { title, description, caption, rows, columns, rightButtons, isLoading = false } = props;

  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const rowsCurrentPage = useMemo(() => {
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(rows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [rows, mainTablePagination]);

  const renderTableNode = useCallback((): ReactNode => {
    if (rows.length === 0) {
      return (
        <div className="empty-row-container">
          <p className="empty-row">Empty</p>
        </div>
      );
    }
    return (
      <div className="table-container">
        <Table
          rows={rowsCurrentPage}
          columns={columns}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              setMainTablePagination(prev => ({
                ...prev,
                target: target,
                direction: dir,
              }));
            },
          }}
          horizontalScrollable={true}
          isLoading={isLoading}
        />
      </div>
    );
  }, [rows, rowsCurrentPage, columns, mainTablePagination, isLoading]);

  return (
    <div className="tag-tabs">
      <div className="detail-info-title">
        <div>
          <p className="title">
            {title}
            {!!description && <span className="caption">{description}</span>}
          </p>
          {!!caption && <p className="description">{caption}</p>}
        </div>

        <ButtonGroup buttons={rightButtons ?? []} />
      </div>

      {renderTableNode()}
    </div>
  );
};

export default TagTab;
