import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import Dropdown from 'components/molecules/Dropdown';
import { FirewallSubnetPropsType, FirewallSubnetRowDataPropsType } from './types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DropdownListDataType } from 'components/molecules/Dropdown/types';
import { IP_ADDRESS_TYPE_DROPDOWN } from './configs';
import _ from 'lodash';
import './styles.scss';

const FirewallSubnet = (props: FirewallSubnetPropsType) => {
  const { subnets, currentFirewallSubnetRows, onListSubnetChange } = props;

  // Availability Zone
  const [azData, setAZData] = useState<DropdownListDataType[]>([]);

  const firewallSubnetLimited = useMemo((): number => {
    return azData.length === 0 ? 4 : azData.length;
  }, [azData]);

  const getAZDropdownData = useCallback(() => {
    const dropdownData: DropdownListDataType[] = [];
    subnets.map(subnet => {
      dropdownData.push({
        name: subnet.availabilityZone,
        value: subnet.availabilityZone,
      });
    });
    const selectedValues = currentFirewallSubnetRows.map(e => e.availabilityZone.value);
    const availabilityZones = _.uniqBy(dropdownData, 'name').filter(item => !selectedValues.includes(item.value));
    if (currentFirewallSubnetRows.length === 1) {
      onListSubnetChange([
        {
          ...currentFirewallSubnetRows[0],
          availabilityZoneData: availabilityZones
        },
      ]);
    }
    
    setAZData(availabilityZones);
  }, [subnets, currentFirewallSubnetRows]);

  const getSubnetDropdownData = useCallback(
    (azValue: DropdownListDataType): DropdownListDataType[] => {
      const dropdownData: DropdownListDataType[] = [];
      subnets.map(subnet => {
        if (subnet.availabilityZone === azValue.value) {
          dropdownData.push({
            name: subnet.tags?.find(tag => tag.key === 'Name')?.value ?? subnet.subnetId,
            value: subnet.subnetId,
          });
        }
      });
      return dropdownData;
    },
    [subnets],
  );

  const getIpAddressDropdownData = useCallback(
    (index: number) => {
      const firewallSubnetRow = currentFirewallSubnetRows.find((item, idx) => idx === index);
      if (firewallSubnetRow?.subnet?.value !== '') {
        return IP_ADDRESS_TYPE_DROPDOWN;
      }
      return [];
    },
    [currentFirewallSubnetRows],
  );

  const onAddFirewallSubnetRow = useCallback(() => {
    const selectedValues = currentFirewallSubnetRows.map(e => e.availabilityZone.value).filter(e => !!e);

    const azDataRemain = azData.filter(e => !selectedValues.includes(e.value));

    const newSubnet: FirewallSubnetRowDataPropsType[] = currentFirewallSubnetRows.concat({
      availabilityZoneData: azDataRemain,
      availabilityZone: { value: '', name: '' },
      availabilityZoneError: false,
      subnet: { value: '', name: '' },
      subnetError: false,
      ipAddress: { value: '', name: '' },
      ipAddressError: false,
    });

    onListSubnetChange(newSubnet);
  }, [currentFirewallSubnetRows, azData]);

  const onRemoveFirewallSubnetRow = useCallback(
    (index: number) => {
      const newFirewallSubnets = currentFirewallSubnetRows.slice();
      newFirewallSubnets.splice(index, 1);

      const newData = newFirewallSubnets.map((item, i) => {
        const selectedValues = newFirewallSubnets
          .filter(e => e.availabilityZone !== item.availabilityZone)
          .map(e => e.availabilityZone.value);
        const azDataRemain = azData.filter(e => !selectedValues.includes(e.value));

        return {
          ...item,
          availabilityZoneData: azDataRemain,
        };
      });
      onListSubnetChange(newData);
    },
    [currentFirewallSubnetRows, azData],
  );

  const onFirewallSubnetRowChange = useCallback(
    (field: string, value: DropdownListDataType, index: number) => {
      const newFirewallSubnets = currentFirewallSubnetRows.map((item, i) => {
        if (index === i) {
          return {
            ...item,
            [field]: value,
          };
        } else {
          const selectedValues = currentFirewallSubnetRows
            .filter(e => e.availabilityZone !== item.availabilityZone)
            .map(e => e.availabilityZone.value);
          selectedValues.push(value.value);
          const azDataRemain = azData.filter(e => !selectedValues.includes(e.value));

          return {
            ...item,
            availabilityZoneData: azDataRemain,
          };
        }
      });
      onListSubnetChange(newFirewallSubnets);
    },
    [currentFirewallSubnetRows],
  );

  useEffect(() => {
    if (subnets.length > 0) {
      getAZDropdownData();
    }
  }, [subnets]);

  return (
    <div className="firewall-subnet-container">
      {currentFirewallSubnetRows.length > 0 && (
        <div className="firewall-subnet-row">
          <div className="firewall-subnet-column">
            <p className="title">Availability Zone</p>
          </div>
          <div className="firewall-subnet-column">
            <p className="title">Subnet</p>
          </div>
          <div className="firewall-subnet-column">
            <p className="title">IP address type</p>
          </div>
          <div className="firewall-subnet-column-last">
            <Button label={'Remove subnet'} onClick={() => {}} />
          </div>
        </div>
      )}

      {currentFirewallSubnetRows.length === 0 && (
        <div className="firewall-subnet-row">
          <div className="firewall-subnet-column">
            <p className="title">No subnet specified</p>
          </div>
        </div>
      )}

      {currentFirewallSubnetRows?.map((firewallSubnet, index) => {
        return (
          <div key={index} className="firewall-subnet-row">
            <div className="firewall-subnet-column">
              <Dropdown
                id={_.uniqueId('dropdown-az-')}
                data={firewallSubnet.availabilityZoneData}
                value={firewallSubnet.availabilityZone}
                handleClick={val => onFirewallSubnetRowChange('availabilityZone', val, index)}
                placeholder={'Choose an Availability Zone'}
                disabled={azData.length === 0 && subnets.length === 0}
                error={firewallSubnet.availabilityZoneError}
              />
            </div>

            <div className="firewall-subnet-column">
              <Dropdown
                id={_.uniqueId('dropdown-subnet-')}
                data={getSubnetDropdownData(firewallSubnet.availabilityZone)}
                value={firewallSubnet.subnet}
                handleClick={val => onFirewallSubnetRowChange('subnet', val, index)}
                placeholder={'Choose a subnet'}
                disabled={firewallSubnet.availabilityZone.value === ''}
                error={firewallSubnet.subnetError}
              />
            </div>

            <div className="firewall-subnet-column">
              <Dropdown
                id={_.uniqueId('dropdown-ip-')}
                data={getIpAddressDropdownData(index)}
                value={firewallSubnet.ipAddress}
                handleClick={val => onFirewallSubnetRowChange('ipAddress', val, index)}
                placeholder={'Choose an IP address'}
                disabled={firewallSubnet.subnet.value === ''}
                error={firewallSubnet.ipAddressError}
              />
            </div>

            <Button label={'Remove subnet'} onClick={() => onRemoveFirewallSubnetRow(index)} />
          </div>
        );
      })}

      <Button
        label={'Add new subnet'}
        onClick={onAddFirewallSubnetRow}
        type={ButtonTypeEnum.PRIMARY}
        disabled={currentFirewallSubnetRows.length === firewallSubnetLimited}
      />
    </div>
  );
};

export default FirewallSubnet;
