export const isEmpty = (value: string = '') => {
  return value === null || value === undefined || value === '' || value.trim().length === 0;
};

export const isAlphaNumeric = (value: string = '') => {
  return /^(?!-)(?!.*--)[a-zA-Z0-9-]{1,128}(?<!-)$/.test(value);
};

export const isMinLength = (value: string = '', len: number) => {
  return value.length >= len;
};

export const isNumber = (value: string = '') => {
  return /^[0-9]+$/.test(value);
};
