import RadioAtom from 'components/v2/atoms/RadioAtom';
import './index.scss';
import SectionContainer from 'pages/v2/Organ/Management/components/SectionContainer';
import { SCOPE_SECRET_TYPE_RADIO_LIST } from '../../configs';
import { CreationDataType } from '../../type';
type SecretTypePanelType = {
   creationData: CreationDataType, 
   onChangeValue: Function
}
const SecretTypePanel = (props: SecretTypePanelType) => {
  const { creationData, onChangeValue } = props;

  return (
    <SectionContainer title={'Secret type'} customStyle="secret-panel">
      <div className="secret-container">
        {SCOPE_SECRET_TYPE_RADIO_LIST.map((radioButton: any) => (
          <div
            key={radioButton.id}
            className="secret-item"
            onClick={() => onChangeValue('secretType', radioButton.value)}
          >
            <div className="secret-radio">
              <RadioAtom
                value={radioButton.value}
                name={radioButton.name}
                label=""
                checked={creationData.secretType}
                onChange={() => {}}
              />
            </div>
            <div className="secret-content">
              <span className="secret-title">{radioButton.name}</span>
              <span className="secret-title">{radioButton.desc}</span>
            </div>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export { SecretTypePanel };
