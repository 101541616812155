import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { MemberInfo } from 'graphql/types/MemberInfo';
import { AwsUpdateKeyDescriptionRequestType } from 'graphql/types/AwsUpdateKeyDescription';

export type AwsUpdateKeyDescriptionVariables = {
  cloudId: number;
  region: string;
  reqData: AwsUpdateKeyDescriptionRequestType;
};

export interface IUpdateMemberResponseData {
  updateAwsKeyDescription: IGqlResponseNewData<{}>;
}

const updateKeyDescriptionMutation = () => useMutation<IUpdateMemberResponseData, AwsUpdateKeyDescriptionVariables>(query);
export default updateKeyDescriptionMutation;
