import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { AwsWebACL } from 'graphql/types/AwsWebACL';
import { OrderDirection } from '@Types/v2/Table';
import { AwsArnData } from '../../../Commons/Constant';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import EnableLoggingModal from '../../../EnableLoggingModal';
import EditSampledRequestsModal from '../../../EditSampledRequestsModal';
import EditCloudworkMetricsModal from '../../../EditCloudworkMetricsModal';
import DisableLoggingModal from '../DisableLoggingModal';
import lazyGetAwsLoggingConfiguration from 'graphql/queries/getAwsLoggingConfiguration';
import deleteAwsLoggingConfigurationMutation, {
  IDisableLoggingMetricVariables,
} from 'graphql/mutations/deleteAwsLoggingConfiguration';
import _ from 'lodash';
import updateAwsWebACL from 'graphql/mutations/updateAwsWebACL';

interface ILoggingProps {
  cloudId: number;
  region: string;
  webAcl: AwsWebACL;
  lockToken: string;
  fetchWebACL: () => void;
}

const Logging = ({ cloudId, region, webAcl, lockToken, fetchWebACL }: ILoggingProps) => {
  const [loggingConfig, setLoggingConfig] = useState<AwsArnData[]>([]);
  const [isEnableLoggingOpened, setIsEnableLoggingOpened] = useState<boolean>(false);
  const [isEditSampleRequestOpened, setIsEditSampleRequestOpened] = useState<boolean>(false);
  const [isEditCloudworkMetricsOpened, setIsEditCloudworkMetricsOpened] = useState<boolean>(false);
  const [loggingMetricModalVisible, setVisibleLoggingMetricModal] = useState<boolean>(false);

  const [updateWebAcl] = updateAwsWebACL();

  const handleUpdateWebAcl = useCallback(
    async (newWebAcl: any) => {
      const variables = {
        cloudId,
        region: region !== 'CloudFront' ? String(region) : 'us-east-1',
        request: {
          scope: region !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
          ..._.omit(newWebAcl, [
            'arn',
            'capacity',
            'preProcessFirewallManagerRuleGroups',
            'managedByFirewallManager',
            'labelNamespace',
            'description',
          ]),
          lockToken: lockToken,
        },
      };

      await updateWebAcl({ variables });
      fetchWebACL();
    },
    [cloudId, region, lockToken],
  );

  const enableBtnDisabled = useMemo((): boolean => {
    return loggingConfig.length != 0;
  }, [loggingConfig]);

  const loggingState = useMemo((): string => {
    return loggingConfig.length === 0 ? 'Disabled' : 'Enabled';
  }, [loggingConfig]);

  const [getAwsLoggingConfiguration] = lazyGetAwsLoggingConfiguration();
  const [deleteAwsLoggingConfiguration] = deleteAwsLoggingConfigurationMutation();

  const getLoggingConfig = () => {
    getAwsLoggingConfiguration({
      variables: {
        cloudId: cloudId,
        region: region,
        request: {
          resourceArn: webAcl.arn,
        },
      },
    }).then(({ data }) => {
      if (data && data.getAwsLoggingConfiguration) {
        if (data?.getAwsLoggingConfiguration?.data?.[0]?.loggingConfiguration) {
          let logDestinationConfigs: AwsArnData[] = [];
          const loggingConfiguration = data.getAwsLoggingConfiguration.data[0].loggingConfiguration;
          loggingConfiguration.logDestinationConfigs.map(log => {
            const logData = extractArnsLoggingData(log);
            logDestinationConfigs.push(logData);
          });
          setLoggingConfig(logDestinationConfigs);
        }
      }
    });
  };

  useEffect(() => {
    getLoggingConfig();
  }, [webAcl.arn]);

  const extractArnsLoggingData = (arn: string) => {
    // Split the ARN string
    const arnComponents = arn.split(':');

    // Extract specific parts
    const service = arnComponents[2];
    const region = arnComponents[3];
    const accountId = arnComponents[4];
    const loggingName = arnComponents[arnComponents.length - 1];

    const arnData: AwsArnData = {
      id: loggingName,
      service: service,
      region: region,
      accountId: accountId,
      resourceType: '',
      resourceName: loggingName,
    };
    return arnData;
  };

  const disableLoggingMetric = useCallback(() => {
    const requestVariable: IDisableLoggingMetricVariables = {
      cloudId: cloudId,
      region: region,
      reqData: {
        resourceArn: webAcl.arn,
      },
    };
    deleteAwsLoggingConfiguration({ variables: requestVariable }).then(({ data: responseData }) => {
      if (responseData?.deleteAwsLoggingConfiguration?.result === ErrorCode.SUCCESS) {
        useToast(ErrorCode.SUCCESS, 'Delete the logging configuration successful.');
        setVisibleLoggingMetricModal(false);
        getLoggingConfig();
      } else {
        useToast(ErrorCode.UNKNOWN, 'Delete the logging configuration failed.');
      }
    });
  }, [cloudId, region, webAcl]);

  const handleEditCloudworkMetrics = useCallback(
    async (ruleMetrics: any) => {
      let newRules = webAcl?.rules?.map(rule => {
        return {
          ...rule,
          visibilityConfig: {
            ...rule?.visibilityConfig,
            metricName: ruleMetrics.find((metric: any) => rule?.name === metric.rules)?.cloudmetricName ?? '',
          },
        };
      });

      await handleUpdateWebAcl({
        ...webAcl,
        rules: newRules,
      });

      setIsEditCloudworkMetricsOpened(false);
    },
    [webAcl, handleUpdateWebAcl],
  );

  const handleEditSampledRequests = useCallback(
    async (requestSamplingOptionValues: string, checkedRules: Array<string>, defaultActionValue: string) => {
      let newWebAcl = { ...webAcl };

      if (requestSamplingOptionValues === 'enable') {
        newWebAcl.visibilityConfig.sampledRequestsEnabled = true;
        newWebAcl.rules.forEach(rule => (rule.visibilityConfig.sampledRequestsEnabled = true));
      }

      if (requestSamplingOptionValues === 'disable') {
        newWebAcl.visibilityConfig.sampledRequestsEnabled = false;
        newWebAcl.rules.forEach(rule => (rule.visibilityConfig.sampledRequestsEnabled = false));
      }

      if (requestSamplingOptionValues === 'enableWithExclutions') {
        newWebAcl.rules.forEach(rule => {
          if (checkedRules.includes(rule.name)) {
            rule.visibilityConfig.sampledRequestsEnabled = false;
          } else {
            rule.visibilityConfig.sampledRequestsEnabled = true;
          }
        });

        if (defaultActionValue === 'enable') {
          newWebAcl.visibilityConfig.sampledRequestsEnabled = true;
        } else {
          newWebAcl.visibilityConfig.sampledRequestsEnabled = false;
        }
      }

      let newRules = webAcl?.rules?.map(rule => {
        return {
          ...rule,
          visibilityConfig: {
            ...rule?.visibilityConfig,
          },
        };
      });

      await handleUpdateWebAcl({
        ...webAcl,
        rules: newRules,
      });

      setIsEditSampleRequestOpened(false);
    },
    [webAcl, handleUpdateWebAcl],
  );

  return (
    <Fragment>
      <div className="resize-container vertical">
        <div className="details">
          <div className="row-3">
            <div className="title border-bottom flex j-between a-center">
              <div>
                <p>Logging</p>
                <p>Enable, edit, and disable web ACL traffic logging.</p>
              </div>
              <div className="flex action a-center">
                <button
                  className="action-btn"
                  disabled={enableBtnDisabled}
                  onClick={() => setIsEnableLoggingOpened(true)}
                >
                  Enable
                </button>
                <button className="edit-btn" onClick={() => {}} disabled={!enableBtnDisabled}>
                  Edit
                </button>
                <button
                  className="action-btn"
                  onClick={() => setVisibleLoggingMetricModal(true)}
                  disabled={!enableBtnDisabled}
                >
                  Disable
                </button>
              </div>
            </div>
            <div className="content flex">
              <div className="logging-config">
                <p>Logging (Configure your logging destination and settings)</p>
                <p>{loggingState}</p>
              </div>
              <div className="logging-config">
                <p>
                  {loggingConfig[0]?.service === 's3'
                    ? 'Amazon S3 bucket'
                    : loggingConfig[0]?.service === 'logs'
                    ? 'Amazon CloudWatch Logs log group'
                    : 'Amazon Data Firehose stream'}
                </p>
                {loggingConfig?.map((log, index) => {
                  return <p key={`web-acl-detail-logging-and-metric-${index}`}>{log.resourceName}</p>;
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="details">
          <div className="row-3">
            <div className="title border-bottom flex j-between a-center">
              <div>
                <p>Sampled request</p>
                <p>
                  If you enable sampled requests, AWS WAF stores samples from the last 3 hours of requests that match
                  the web ACL rules. Sampled requests are free of charge
                </p>
              </div>
              <div className="flex action a-center">
                <button className="edit-btn" onClick={() => setIsEditSampleRequestOpened(true)} disabled={false}>
                  Edit
                </button>
              </div>
            </div>
            <div className="content flex">
              <div className="logging-config">
                <p>Sample requests for web ACL default actions</p>
                <p>{webAcl.visibilityConfig.sampledRequestsEnabled ? 'Enabled' : 'Disabled'}</p>
              </div>
              <div className="logging-config">
                <p>Sampled requests</p>
                {webAcl.rules.map((rule, index) => {
                  return (
                    <div className="sample-data flex a-center" key={`sample-config-${index}`}>
                      <span>{rule.name}</span>
                      <span>{rule.visibilityConfig.sampledRequestsEnabled ? 'Enabled' : 'Disabled'}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="details">
          <div className="row-3">
            <div className="title flex j-between a-center">
              <div>
                <p>CloudWatch metrics ({webAcl.rules?.length || 0})</p>
              </div>
              <div className="flex action a-center">
                <button className="edit-btn" onClick={() => setIsEditCloudworkMetricsOpened(true)} disabled={false}>
                  Edit
                </button>
              </div>
            </div>
          </div>
          <div className="content">
            {!webAcl.rules ? (
              <div className="data-grid-wrap">
                <p className="empty-row">Empty</p>
              </div>
            ) : (
              <div className="data-grid-wrap">
                <div className="data-table">
                  <table>
                    <thead>
                      <tr className="header">
                        <th style={{ paddingLeft: '32px' }}>Rule name</th>
                        <th>Cloudwatch metric name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {webAcl.rules.map(rule => (
                        <>
                          <tr>
                            <td style={{ paddingLeft: '32px' }}>
                              <div>{rule.name}</div>
                            </td>
                            <td style={{ paddingLeft: '32px' }}>
                              <div>{rule.visibilityConfig?.metricName}</div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isEnableLoggingOpened && (
        <EnableLoggingModal
          header={'Enable logging'}
          cloudId={cloudId}
          region={region}
          open={isEnableLoggingOpened}
          onClose={() => setIsEnableLoggingOpened(false)}
          loggingConfig={loggingConfig}
          webAcl={webAcl}
        />
      )}

      <EditSampledRequestsModal
        header={'Edit sampled requests'}
        columns={[
          {
            label: 'Name',
            field: 'name',
            sort: true,
          },
        ]}
        rows={webAcl.rules.map(rule => ({
          id: rule?.name,
          name: rule?.name,
        }))}
        open={isEditSampleRequestOpened}
        onClose={() => setIsEditSampleRequestOpened(false)}
        onSaveButtonClicked={handleEditSampledRequests}
      />

      <EditCloudworkMetricsModal
        header={'Edit cloudwork metrics'}
        currentRows={webAcl.rules.map(rule => ({
          id: _.uniqueId(),
          rules: rule?.name,
          cloudmetricName: rule?.visibilityConfig?.metricName,
        }))}
        onSaveButtonClicked={handleEditCloudworkMetrics}
        currentColumns={[
          {
            label: 'Rules',
            field: 'rules',
            sort: true,
          },
          {
            label: 'Cloudwatch metric name',
            field: 'cloudmetricName',
            sort: true,
          },
        ]}
        reportCheckedList={(list: string[]) => {
          //setCheckedList(list);
        }}
        reportSelected={(id: string) => {
          //setSelected(id);
        }}
        sortOption={{
          target: '',
          direction: OrderDirection.DES,
          onChangeSort: (target: string, dir: OrderDirection) => {},
        }}
        open={isEditCloudworkMetricsOpened}
        onClose={() => setIsEditCloudworkMetricsOpened(false)}
      />
      <DisableLoggingModal
        open={loggingMetricModalVisible}
        onClose={() => setVisibleLoggingMetricModal(false)}
        onDiableLogging={disableLoggingMetric}
      />
    </Fragment>
  );
};
export default Logging;
