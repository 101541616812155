import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export type DeleteAwsAllowListVariablesType = {
  cloudId: number;
  region: string;
  request: {
    id: string;
  };
};

export type DeleteAwsAllowListResponseDataType = {
  deleteAwsAllowList: IGqlResponseData<{}>;
};

const deleteAwsAllowListMutation = () =>
  useMutation<DeleteAwsAllowListResponseDataType, DeleteAwsAllowListVariablesType>(query);
export default deleteAwsAllowListMutation;
