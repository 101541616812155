import React from 'react';
import { SectionTitlePropsType } from '../types';
import ButtonGroup from '../ButtonGroup';
import './index.scss';

const SectionTitle = (props: SectionTitlePropsType) => {
  const { title, description, hasBottomBorder, caption, rightButtons, customStyle, noBottomSpace } = props;

  return (
    <div className={`section-title-container ${hasBottomBorder && ' bottom-border'} ${customStyle} ${noBottomSpace && ' no-bottom-space'}`} >
      <div className="title-container">
        <p className="title">
          {title}
          <span className="caption">{caption}</span>
        </p>
        <p className="section-description">{description}</p>
      </div>
      {rightButtons && (
        <div className="button-group-container">
          <ButtonGroup buttons={rightButtons} />
        </div>
      )}
    </div>
  );
};

export default SectionTitle;
