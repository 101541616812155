import InputAtom from 'components/v2/atoms/InputAtom';
import { ICreationDataPropsType } from './type';

interface IAddTagsProps {
  title: string;
  subTitle: string;
  creationData: ICreationDataPropsType;
  updateCreationData: (data: ICreationDataPropsType) => void;
}

const AddTags = ({ title, subTitle, creationData, updateCreationData }: IAddTagsProps) => {
  const { tags } = creationData;
  const handleChangeTag = (field: 'key' | 'value', value: string, index: number) => {
    const newTags = [...tags];
    newTags[index][field] = value;
    updateCreationData({ ...creationData, tags: newTags });
  };
  const handleRemoveTag = (index: number) => {
    const newTags = tags.slice();
    newTags.splice(index, 1);
    updateCreationData({ ...creationData, tags: newTags });
  };
  const handleAddTag = () => {
    const newTags = tags.concat({ key: '', value: '' });
    updateCreationData({ ...creationData, tags: newTags });
  };

  return (
    <div className="rule-group-container">
      <div className="title-group">
        <p className="title">{title}</p>
        <p className="sub-title">{subTitle}</p>
      </div>

      <div className="rule-group-details-container">
        <div className="rule-group-type-content">
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8, width: '100%' }}>
            <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: 0 }}>
              Add tags-optional
            </div>
            <div className="rule-group-detail-content" style={{ gap: 4, padding: 0 }}>
              <div className="config-rule-row">
                <div className="config-rule-column">
                  <div className="firewall-detail-sub-title">Key</div>
                </div>
                <div className="config-rule-column">
                  <div className="firewall-detail-sub-title">Value - optional</div>
                </div>
                <div style={{ marginLeft: 6, opacity: 0, pointerEvents: 'none' }}>
                  <button className="button" style={{ margin: 0 }} onClick={() => {}}>
                    Remove tag
                  </button>
                </div>
              </div>
              {tags.length ? (
                tags.map((item, index) => {
                  return (
                    <div key={index} className="config-rule-row">
                      <div className="config-rule-column">
                        <InputAtom
                          value={item.key}
                          placeholder="Enter key"
                          onChangeValue={value => {
                            handleChangeTag('key', value, index);
                          }}
                        />
                      </div>
                      <div className="config-rule-column">
                        <InputAtom
                          value={item.value}
                          placeholder="Enter value"
                          onChangeValue={value => {
                            handleChangeTag('value', value, index);
                          }}
                        />
                      </div>
                      <button
                        className="button"
                        style={{ margin: 0 }}
                        onClick={() => {
                          handleRemoveTag(index);
                        }}
                      >
                        Remove tag
                      </button>
                    </div>
                  );
                })
              ) : (
                <p>No tags associated with the resource.</p>
              )}
            </div>
            <div>
              <button
                className="button next-button"
                style={{ margin: 0 }}
                onClick={() => {
                  handleAddTag();
                }}
              >
                Add tags
              </button>
            </div>
            <div className="rule-group-detail-title" style={{ borderBottom: 'none', padding: 0 }}>
              <div className="rule-group-detail-sub-title">You can add up to {50 - tags.length} more tags.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTags;
