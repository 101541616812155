import { ColumnType, RowType } from '@Types/v2/Table';
import { TextTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdTabLayout/types';
import StepContainer from 'pages/v2/Organ/Management/WAF/WebACL/CreateWebACL/components/StepContainer';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import TagTab from 'pages/v2/Organ/Management/components/TagTab';
import { useCallback, useMemo } from 'react';
import { ICreationDataPropsType } from '../steps/type';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import { listProtocols, statefulRuleColumn, statelessRuleColumn } from '../configs';
import createAwsRuleGroupFirewallMutation, {
  IAwsRuleGroupFirewall,
  IAwsRulesSourceInput,
  IAwsStatefulRuleInput,
  IAwsStatelessRuleInput,
  IAwsTaglog,
  ICreateAwsRuleGroupFirewallVariables,
} from 'graphql/mutations/createAwsRuleGroupFirewall';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';

interface IReviewProps {
  creationData: ICreationDataPropsType;
  cancelFunction: () => void;
  prevStepFunction: () => void;
}

const Review = ({ creationData, cancelFunction, prevStepFunction }: IReviewProps) => {
  const {
    cloudId,
    region,
    ruleGroupType,
    ruleGroupFormat,
    ruleEvaluationOrder,
    ruleGroupDetails: { name, capacity, description },
    ipSetVariables,
    portVariables,
    ipSetReferenceVariables,
    ruleRowsDomainList,
    awsKey,
    tags,
    suricataRule,
    domainName,
    domainListProtocol,
    domainListAction,
  } = creationData;
  const [createRuleGroup, { loading }] = createAwsRuleGroupFirewallMutation();
  const ruleGroupTypeColumns = useMemo((): IMgdDetailKeyValueProps[] => {
    if (ruleGroupType === 'stateless') {
      return [
        { id: 'rule-group-type', type: TextTypeEnum.NORMAL, title: 'Rule group type', description: ruleGroupType },
      ];
    }

    return [
      { id: 'rule-group-type', type: TextTypeEnum.NORMAL, title: 'Rule group type', description: ruleGroupType },
      {
        id: 'stateful-rule-group-option',
        type: TextTypeEnum.NORMAL,
        title: 'Stateful rule group option',
        description: ruleGroupFormat.name,
      },
      { id: 'rule-order', type: TextTypeEnum.NORMAL, title: 'Rule order', description: ruleEvaluationOrder },
    ];
  }, [ruleEvaluationOrder, ruleGroupFormat.name, ruleGroupType]);
  const ruleGroupDetailsColumns = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      { id: 'name', type: TextTypeEnum.NORMAL, title: 'Name', description: name },
      { id: 'description', type: TextTypeEnum.NORMAL, title: 'Description', description },
      { id: 'capacity', type: TextTypeEnum.NORMAL, title: 'Capacity', description: capacity },
    ];
  }, [capacity, description, name]);
  const ruleVariablesColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Name', field: 'name', sort: true },
      { label: 'Type', field: 'type', sort: true },
    ];
  }, []);
  const ruleVariablesRows = useMemo((): RowType[] => {
    const result1: RowType[] = [];
    ipSetVariables.forEach(item => {
      if (item.name && item.value) {
        result1.push({
          name: item.name,
          value: item.value,
          type: 'IP Sets',
        });
      }
    });
    const result2: RowType[] = [];
    portVariables.forEach(item => {
      if (item.name && item.value) {
        result2.push({
          name: item.name,
          value: item.value,
          type: 'Port',
        });
      }
    });

    return [...result1, ...result2];
  }, [ipSetVariables, portVariables]);
  const ipSetReferencesColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Name', field: 'name', sort: true },
      { label: 'ARN', field: 'arn', sort: true },
    ];
  }, []);
  const ipSetReferencesRows = useMemo((): RowType[] => {
    const result: RowType[] = [];
    ipSetReferenceVariables.forEach(item => {
      if (item.resource.name) {
        result.push({
          name: item.name,
          arn: item.resource.value || 0,
        });
      }
    });

    return result;
  }, [ipSetReferenceVariables]);
  const configureRuleReviewNode = useMemo(() => {
    if (ruleGroupType === 'stateless') {
      return <TagTab title="Rules" columns={statelessRuleColumn} rows={ruleRowsDomainList} />;
    } else if (ruleGroupFormat.id === 1) {
      return <TagTab title="Rules" columns={statefulRuleColumn} rows={ruleRowsDomainList} />;
    }
  }, [ruleGroupFormat.id, ruleGroupType, ruleRowsDomainList]);
  const customerManagedKeyColumns = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      {
        id: 'key-type',
        type: TextTypeEnum.NORMAL,
        title: 'Key type',
        description: awsKey.id ? 'AWS KMS key' : 'AWS owned key',
      },
      { id: 'key-id', type: TextTypeEnum.NORMAL, title: 'Key ID', description: '' },
    ];
  }, [awsKey.id]);
  const rulesGroupTagsColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);
  const getTags = useCallback((): IAwsTaglog[] | undefined => {
    const result: IAwsTaglog[] = [];
    tags.forEach(item => {
      if (item.key && item.value) {
        result.push({
          key: item.key,
          value: item.value,
        });
      }
    });

    return result.length ? result : undefined;
  }, [tags]);
  const getStatefulRules = (): IAwsStatefulRuleInput[] => {
    const result: IAwsStatefulRuleInput[] = [];
    ruleRowsDomainList.forEach((item, id) => {
      result.push({
        action: item.action,
        header: {
          protocol: item.protocol,
          source: item.source,
          sourcePort: item.sourcePort,
          destination: item.destination,
          destinationPort: item.destinationPort,
          direction: item.trafficDirection,
        },
        ruleOptions: [
          {
            keyword: 'sid',
            settings: [`${id + 1}`],
          },
        ],
      });
    });

    return result;
  };
  const strPortFromTo = (strPort: string) => {
    if (strPort === '-') return;
    const result: { fromPort: number; toPort: number }[] = [];
    strPort.split('\n').forEach(line => {
      const [fromPort, toPort] = line.split(':').map((item: string) => Number(item));
      result.push({
        fromPort,
        toPort,
      });
    });

    return result;
  };
  const convertProtocol = (str: string): number[] | undefined => {
    if (str === 'All') return;
    const result: number[] = [];
    str?.split('/').map(item => {
      if (item === 'All') {
        result.push(256);

        return;
      }
      const proto = listProtocols.find(p => p.value === item);
      if (proto) {
        result.push(Number(proto.description?.split(' ')[1]));
      }
    });

    return result;
  };
  const getStatelessRules = (): IAwsStatelessRuleInput[] => {
    const result: IAwsStatelessRuleInput[] = [];
    ruleRowsDomainList.forEach(item => {
      let tcpFlags: { flags?: string[]; masks?: string[] }[] | undefined;
      if (item.flags?.length || item.masks?.length) {
        tcpFlags = [{}];
        if (item.flags?.length) {
          tcpFlags[0].flags = Array.from<string>(item.flags);
        }
        if (item.flags?.length) {
          tcpFlags[0].masks = Array.from<string>(item.masks);
        }
      }
      result.push({
        priority: Number(item.priority),
        ruleDefinition: {
          matchAttributes: {
            protocols: convertProtocol(item.protocol),
            sources: item.source.split('\n').map((item: string) => ({ addressDefinition: item })),
            sourcePorts: strPortFromTo(item.sourcePort),
            destinations: item.destination.split('\n').map((item: string) => ({ addressDefinition: item })),
            destinationPorts: strPortFromTo(item.destinationPort),
            tcpFlags,
          },
          actions: [item.action === 'pass' ? 'aws:pass' : item.action === 'drop' ? 'aws:drop' : 'aws:forward_to_sfe'],
        },
      });
    });

    return result;
  };
  const getMapIpset = () => {
    const result: Record<string, any> = {};
    ipSetVariables.forEach(item => {
      if (item.name && item.value) {
        result[item.name] = {
          definition: item.value.split('\n'),
        };
      }
    });

    return result;
  };
  const getMapPortset = () => {
    const result: Record<string, any> = {};
    portVariables.forEach(item => {
      if (item.name && item.value) {
        result[item.name] = {
          Definition: item.value.split('\n'),
        };
      }
    });

    return result;
  };
  const getMapIpSetReferences = () => {
    const result: Record<string, any> = {};
    ipSetReferenceVariables.forEach(item => {
      if (item.name && item.resource.name) {
        result[item.name] = {
          referenceArn: item.prefixListArn,
        };
      }
    });

    return result;
  };
  const create = async () => {
    const rulesSource: IAwsRulesSourceInput = {};
    if (ruleGroupType === 'stateless') {
      rulesSource.statelessRulesAndCustomActions = {
        statelessRules: getStatelessRules(),
        customActions: [],
      };
    }
    const ruleGroup: IAwsRuleGroupFirewall = {};
    ruleGroup.rulesSource = rulesSource;
    if (ruleGroupType === 'stateful') {
      ruleGroup.statefulRuleOptions = {
        ruleOrder: ruleEvaluationOrder === 'actionOrder' ? 'DEFAULT_ACTION_ORDER' : 'STRICT_ORDER',
      };
      if (ruleGroupFormat.id === 1) {
        rulesSource.statefulRules = getStatefulRules();
      } else if (ruleGroupFormat.id === 2) {
        rulesSource.rulesSourceList = {
          targets: domainName.split('\n'),
          targetTypes: domainListProtocol,
          generatedRulesType: domainListAction,
        };
      } else if (ruleGroupFormat.id === 3) {
        rulesSource.rulesString = ruleGroupFormat.id === 3 ? suricataRule : '';
      }
    }
    if (ruleGroupFormat.id !== 2 && ruleGroupType === 'stateful') {
      ruleGroup.ruleVariables = {
        ipSets: getMapIpset(),
        portSets: getMapPortset(),
      };
      ruleGroup.referenceSets = {
        ipSetReferences: getMapIpSetReferences(),
      };
    }
    const variables: ICreateAwsRuleGroupFirewallVariables = {
      cloudId,
      region,
      request: {
        ruleGroupName: name,
        description,
        capacity: Number(capacity),
        type: ruleGroupType === 'stateless' ? 'STATELESS' : 'STATEFUL',
        tags: getTags(),
        encryptionConfiguration: {
          type: awsKey.id ? 'CUSTOMER_KMS' : 'AWS_OWNED_KMS_KEY',
          keyId: awsKey.id ? awsKey.id : undefined,
        },
        ruleGroup,
      },
    };
    const { errors } = await createRuleGroup({ variables });
    if (!errors) {
      useToast(ErrorCode.SUCCESS, 'Create rule group successful.');
      cancelFunction();
    } else {
      useToast(ErrorCode.UNKNOWN, 'Create rule group failed.');
    }
  };

  return (
    <StepContainer title="Step 6: Review">
      <DetailTab title="Rule group type" data={ruleGroupTypeColumns} />
      <DetailTab title="Rule group details" data={ruleGroupDetailsColumns} />
      <TagTab title="Rule variables" columns={ruleVariablesColumns} rows={ruleVariablesRows} />
      <TagTab title="IP set references" columns={ipSetReferencesColumns} rows={ipSetReferencesRows} />
      {configureRuleReviewNode}
      <DetailTab title="Customer managed key" data={customerManagedKeyColumns} />
      <TagTab title="Rule group tags" columns={rulesGroupTagsColumns} rows={getTags() || []} />
      <div className="firewall-rule-group-bottom">
        <Button label="Cancel" onClick={cancelFunction} />
        <Button label="Previous" onClick={prevStepFunction} />
        <Button label="Create rule group" type={ButtonTypeEnum.PRIMARY} onClick={create} loading={loading} />
      </div>
    </StepContainer>
  );
};

export default Review;
