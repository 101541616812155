import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListFindingsResponse } from 'graphql/types/AwsListFindings';

export interface IGetAwsListFindingsVariables {
  cloudId: number;
  region: string;
  reqData: {
    maxResults: number;
    findingCriteria?: {
      criterion: string
    }
  };
}

export interface IGetAwsListFindingsResponseData {
  getAwsListFindings: IGqlResponseNewData<AwsListFindingsResponse>;
}

const lazyGetAwsListFindings = () =>
  useLazyQuery<IGetAwsListFindingsResponseData, IGetAwsListFindingsVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsListFindings;
