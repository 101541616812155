import { gql } from '@apollo/client';

const query = gql`
  mutation updateAwsGroup($request: AwsUpdateGroupRequest, $cloudId: Long, $region: String) {
    updateAwsGroup(awsUpdateGroupRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsUpdateGroupResponse {
          group {
            groupArn
            name
            description
          }
        }
      }
    }
  }
`;
export default query;
