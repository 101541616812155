import { useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import { handleFormatText, randomString } from 'utils/Common';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import lazyGetAwsRuleGroupMetadata from 'graphql/queries/getAwsRuleGroupMetadata';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { DropdownListDataType } from 'components/Dropdown/types';
import UnmanagedStateModal from '../../UnmanagedStateModal';
import EditPriority from '../../EditPriority';
import AddManagedStatefullRuleGroupModal from '../../AddManagedStatefulRuleGroupModal';
import DisassociateRuleGroups from '../../DisassociateRuleGroups';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { LIST_UP_TAB_LIST } from '../../../../configs';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import { PRIORITY_COLUMN, UNMANAGE_STATE_COLUMN } from '../../FirewallPolicyCreation/configs';

const StateRuleGroups = ({
  cloudId,
  region,
  firewallPolicy,
  firewallPolicyResponse,
  type,
  isEdit,
  creationApiLoading,
  handleUpdateStatefulRule,
  handleUpdateStatelessRule,
  handleUpdateManagedStatefuRule,
  handleDisassociateStatefulRule,
  handleDisassociateStatelessRule,
}: any) => {
  const navigate = useNavigate();
  const { statelessRuleGroupReferences, statefulRuleGroupReferences } = firewallPolicy || {};
  const isStateLess = type === 'STATELESS';
  const [getAwsRuleGroupMetadata, { loading }] = lazyGetAwsRuleGroupMetadata();
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [stateLessCheckedList, setStateLessCheckedList] = useState<string[]>([]);
  const [stateFulcheckedList, setStateFulcheckedList] = useState<string[]>([]);
  const [isOpenEditPriority, setIsOpenEditPriority] = useState<boolean>(false);
  const [isOpenAddUnmanagedState, setIsOpenAddUnmanagedState] = useState<boolean>(false);
  const [isOpenAddManagedState, setIsOpenAddManagedState] = useState<boolean>(false);
  const [isOpenDisassociateStateLess, setIsOpenDisassociateStateLess] = useState<boolean>(false);
  const [isOpenDisassociateStateFul, setIsOpenDisassociateStateFul] = useState<boolean>(false);

  const actionsDropdown = useMemo(() => {
    if (isStateLess) {
      return [
        { id: 1, name: 'Add unmanaged stateless rule groups', value: 'Add unmanaged stateless rule groups' },
        {
          id: 2,
          name: 'Disassociate from policy',
          value: 'Disassociate from policy',
          disable: !stateLessCheckedList?.length,
        },
      ];
    }

    return [
      { id: 1, name: 'Add unmanaged stateful rule groups', value: 'Add unmanaged stateful rule groups' },
      { id: 2, name: 'Add managed stateful rule groups', value: 'Add managed stateful rule groups' },
      {
        id: 3,
        name: 'Disassociate from policy',
        value: 'Disassociate from policy',
        disable: !stateFulcheckedList?.length,
      },
    ];
  }, [type, stateLessCheckedList, stateFulcheckedList]);

  const handleGetAwsRuleGroupMetadata = async () => {
    const statelessRows: RowType[] = [];
    if (!firewallPolicy) return;
    if (isStateLess) {
      if (statelessRuleGroupReferences?.length > 0) {
        for (const item of statelessRuleGroupReferences) {
          const awsRuleGroupVariable = {
            cloudId,
            region,
            request: {
              ruleGroupArn: item.resourceArn,
              type,
            },
          };
          const ruleGroupMetaData = await getAwsRuleGroupMetadata({
            variables: awsRuleGroupVariable,
          }).then(({ data: ruleGroupData }) => {
            return ruleGroupData?.getAwsRuleGroupMetadata;
          });
          statelessRows.push({
            id: ruleGroupMetaData?.data?.[0]?.ruleGroupName,
            arn: item?.resourceArn,
            priority: item.priority,
            name: ruleGroupMetaData?.data?.[0]?.ruleGroupName,
            capacity: ruleGroupMetaData?.data?.[0]?.capacity,
          });
        }
        statelessRows.sort((a, b) => {
          return a.priority - b.priority;
        });
      }
    } else {
      if (statefulRuleGroupReferences?.length > 0) {
        for (const item of statefulRuleGroupReferences) {
          const awsRuleGroupVariable = {
            cloudId,
            region,
            request: {
              ruleGroupArn: item.resourceArn,
              type,
            },
          };
          const ruleGroupMetaData = await getAwsRuleGroupMetadata({
            variables: awsRuleGroupVariable,
          }).then(({ data: ruleGroupData }) => {
            return ruleGroupData?.getAwsRuleGroupMetadata;
          });
          statelessRows.push({
            id: ruleGroupMetaData?.data?.[0]?.ruleGroupName,
            override: item?.override,
            arn: item?.resourceArn,
            priority: item.priority,
            name: ruleGroupMetaData?.data?.[0]?.ruleGroupName,
            capacity: ruleGroupMetaData?.data?.[0]?.capacity,
            isManaged: ruleGroupMetaData?.data?.[0]?.ruleGroupArn?.includes('aws-managed') ? 'Yes' : 'No',
          });
        }
      }
    }
    statelessRows.sort((a, b) => {
      return a.priority - b.priority;
    });
    setMainTblRows(statelessRows);
    setMainTblTotal({
      totalPage: Math.ceil(statelessRows.length / 10),
      totalElement: statelessRows.length,
    });
  };

  useEffect(() => {
    handleGetAwsRuleGroupMetadata();
    setStateFulcheckedList([]);
    setStateLessCheckedList([]);
  }, [firewallPolicy, type]);

  const columns = useMemo((): ColumnType[] => {
    if (isStateLess)
      return [
        {
          field: 'priority',
          label: 'Priority',
          width: 100,
          sort: true,
        },
        {
          field: 'name',
          label: 'Name',
          sort: true,
          renderCell: row => {
            return (
              <p
                className="link"
                onClick={() =>
                  navigate('/organ/1/manage/firewall', {
                    replace: true,
                    state: {
                      managementType: ManagementTypeEnum.FIREWALL,
                      tabId: LIST_UP_TAB_LIST[2].id,
                      value: row.name,
                    },
                  })
                }
              >
                {handleFormatText(row.name)}
              </p>
            );
          },
        },
        {
          field: 'capacity',
          label: 'Capacity',
          sort: true,
        },
      ];

    return [
      {
        field: 'priority',
        label: 'Priority',
        width: 100,
        sort: true,
      },
      {
        field: 'name',
        label: 'Name',
        sort: true,
        renderCell: row => {
          return row?.isManaged === 'No' ? (
            <p
              className="link"
              onClick={() =>
                navigate('/organ/1/manage/firewall', {
                  replace: true,
                  state: {
                    managementType: ManagementTypeEnum.FIREWALL,
                    tabId: LIST_UP_TAB_LIST[2].id,
                    value: row.name,
                  },
                })
              }
            >
              {handleFormatText(row.name)}
            </p>
          ) : (
            <p>{handleFormatText(row.name)}</p>
          );
        },
      },
      {
        field: 'capacity',
        label: 'Capacity',
        width: 200,
        sort: true,
      },
      {
        field: 'isManaged',
        label: 'Is managed',
        width: 200,
        sort: true,
      },
      {
        field: 'override',
        label: 'Run in alert mode?',
        width: 200,
        sort: true,
        renderCell: row => {
          return (
            <p>
              {row?.isManaged === 'No'
                ? 'Not available'
                : row?.override?.action === 'DROP_TO_ALERT'
                  ? 'Enabled'
                  : 'Disabled'}
            </p>
          );
        },
      },
    ];
  }, [type]);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotal, tablePagination]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleClickActions = (values: DropdownListDataType) => {
    if (isStateLess) {
      switch (values?.value) {
        case actionsDropdown[0].value:
          setIsOpenAddUnmanagedState(true);

          return;

        case actionsDropdown[1].value:
          if (!stateLessCheckedList?.length) return;
          setIsOpenDisassociateStateLess(true);

          return;

        default:
          return;
      }
    }

    switch (values?.value) {
      case actionsDropdown[0].value:
        setIsOpenAddUnmanagedState(true);

        return;

      case actionsDropdown[1].value:
        setIsOpenAddManagedState(true);

        return;

      case actionsDropdown[2].value:
        if (!stateFulcheckedList?.length) return;

        setIsOpenDisassociateStateFul(true);

        return;

      default:
        return;
    }
  };

  return (
    <>
      <div className="detail-info">
        <div className="detail-info-title">
          <p>{isStateLess ? 'Stateless rule groups' : 'Stateful rule groups'}</p>

          {isEdit ? (
            <div style={{ display: 'flex', gap: '8px' }}>
              <Button
                label="Edit priority"
                type={ButtonTypeEnum.GENERAL}
                disabled={mainRowsCurrentPage.length === 0}
                onClick={() => setIsOpenEditPriority(true)}
              />

              <DropdownAtom
                id={randomString()}
                className=""
                data={actionsDropdown}
                value={{ name: 'Actions', value: 'Actions' }}
                handleClick={handleClickActions}
              />
            </div>
          ) : null}
        </div>

        {loading ? (
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        ) : (
          <div className="detail-info-content">
            {!mainRowsCurrentPage.length && !loading ? (
              <div className="data-grid-wrap">
                <p className="empty-row">Empty</p>
              </div>
            ) : (
              <div className="data-grid-wrap">
                <Table
                  rows={mainRowsCurrentPage}
                  columns={columns}
                  reportCheckedList={isStateLess ? setStateLessCheckedList : setStateFulcheckedList}
                  sortOption={{
                    target: tablePagination.target,
                    direction: tablePagination.direction,
                    onChangeSort: (target: string, dir: OrderDirection) => {
                      updateTablePagination('target', target);
                      updateTablePagination('direction', dir);
                    },
                  }}
                  isLoading={loading}
                  horizontalScrollable={true}
                />

                {mainRowsCurrentPage?.length && !loading ? (
                  <div className="fleet-instance pagination-wrapper flex a-center">
                    <p className="flex a-center">
                      Total <span>{mainTblTotal.totalElement}</span>
                    </p>

                    <TableManagePagination
                      ableFetchMore={false}
                      currentPage={tablePagination.currentPage}
                      updateCurrentPage={page => updateTablePagination('currentPage', page)}
                      totalPage={mainTblTotal.totalPage}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>

      {isOpenEditPriority ? (
        isStateLess ? (
          <EditPriority
            header={'Edit priority'}
            subHeader={'Available subnets'}
            columns={PRIORITY_COLUMN}
            isStateless={true}
            rows={mainRowsCurrentPage}
            open={isOpenEditPriority}
            creationApiLoading={creationApiLoading}
            handleUpdateRule={handleUpdateStatelessRule}
            onClose={() => setIsOpenEditPriority(false)}
          />
        ) : (
          <EditPriority
            header={'Edit priority'}
            subHeader={'Available subnets'}
            columns={PRIORITY_COLUMN}
            rows={mainRowsCurrentPage}
            open={isOpenEditPriority}
            creationApiLoading={creationApiLoading}
            handleUpdateRule={handleUpdateStatefulRule}
            onClose={() => setIsOpenEditPriority(false)}
          />
        )
      ) : null}

      {isOpenAddUnmanagedState ? (
        isStateLess ? (
          <UnmanagedStateModal
            cloudId={cloudId}
            region={region}
            header={'Add unmanaged stateless rule groups'}
            note={'Select and add the stateless rule groups that you want in your firewall policy.'}
            subHeader={'Stateless rule group'}
            columns={UNMANAGE_STATE_COLUMN}
            btnTitle={'stateless'}
            dataRule={mainRowsCurrentPage}
            open={isOpenAddUnmanagedState}
            creationApiLoading={creationApiLoading}
            handleUpdateRule={handleUpdateStatelessRule}
            onClose={() => setIsOpenAddUnmanagedState(false)}
          />
        ) : (
          <UnmanagedStateModal
            cloudId={cloudId}
            region={region}
            header={'Add unmanaged stateful rule groups'}
            note={'Select and add the stateful rule groups that you want in your firewall policy.'}
            subHeader={'Stateful rule group'}
            columns={UNMANAGE_STATE_COLUMN}
            icon={'stateful'}
            btnTitle={'stateful'}
            dataRule={mainRowsCurrentPage}
            open={isOpenAddUnmanagedState}
            creationApiLoading={creationApiLoading}
            handleUpdateRule={handleUpdateStatefulRule}
            onClose={() => setIsOpenAddUnmanagedState(false)}
          />
        )
      ) : null}

      {isOpenAddManagedState ? (
        <AddManagedStatefullRuleGroupModal
          cloudId={cloudId}
          region={region}
          open={isOpenAddManagedState}
          dataRule={statefulRuleGroupReferences}
          creationApiLoading={creationApiLoading}
          handleUpdateManagedStatefuRule={handleUpdateManagedStatefuRule}
          onClose={() => setIsOpenAddManagedState(false)}
        />
      ) : null}
      {isOpenDisassociateStateLess ? (
        <DisassociateRuleGroups
          header={'Disassociate rule groups'}
          name={`Are you sure you want to Disassociate the rule groups: ${stateLessCheckedList.join(
            ',',
          )} from firewall policy  ${firewallPolicyResponse?.firewallPolicyName}?`}
          stateCheckedList={stateLessCheckedList}
          open={isOpenDisassociateStateLess}
          onClose={() => setIsOpenDisassociateStateLess(false)}
          handleDisassociateRule={handleDisassociateStatelessRule}
        />
      ) : null}
      {isOpenDisassociateStateFul ? (
        <DisassociateRuleGroups
          header={'Disassociate rule groups'}
          name={`Are you sure you want to Disassociate the rule groups: ${stateFulcheckedList.join(
            ',',
          )} from firewall policy ${firewallPolicyResponse?.firewallPolicyName}?`}
          stateCheckedList={stateFulcheckedList}
          open={isOpenDisassociateStateFul}
          onClose={() => setIsOpenDisassociateStateFul(false)}
          handleDisassociateRule={handleDisassociateStatefulRule}
        />
      ) : null}
    </>
  );
};

export default StateRuleGroups;
