import { useCallback, useEffect, useState, useMemo } from 'react';
import BaseModal from 'components/v2/modals/BaseModal';
import {
  RadioButtonGroup,
  SectionContainer,
  SectionTitle,
  StepContainer,
} from '../../../WebACL/CreateWebACL/components';
import './styles.scss';
import DescriptionTable from '../DescriptionTable';
import { UIFreeRuleGroupType } from '../..';
import lazyGetAwsListAvailableManagedRuleGroupVersions from 'graphql/queries/getAwsListAvailableManagedRuleGroupVersions';
import { DropdownListDataType } from 'components/molecules/Dropdown/types';
import { AwsManagedRuleGroupVersionType } from 'graphql/types/AwsListAvailableManagedRuleGroupVersions';
import {
  OVERRIDE_RULE_ACTION_DROPDOWN_LIST,
  SCOPE_OF_INSPECTION_RADIO_LIST,
  STATEMENT_CONDITION_DROPDOWN_LIST,
} from './configs';
import StatementNode from '../../../CustomRuleModal/RuleBuilder/VisualEditor/Regular/StatementNode';
import Dropdown from 'components/molecules/Dropdown';
import {
  BODY_CONTENT_TO_INSPECT,
  DEFAULT_REQUEST_AGGREGATION_KEY_ITEM,
  DEFAULT_STATEMENT_ITEM,
  IP_ADDRESS_SELECTOR,
} from '../../../CustomRuleModal/constant';
import ScopeDownStatement from '../ScopeDownStatement';
import {
  EVALUATION_WINDOW,
  INSPECTION_AND_RATE_LIMIT,
  REQUEST_AGGREGATION,
} from '../../../CustomRuleModal/RuleBuilder/VisualEditor/RateBased/constant';
import { pascalCaseKeys } from 'utils/Json';
import TitleGroup from 'components/organism/TitleGroup';
import Box, { FlexDirection } from 'components/atoms/Box';
import _ from 'lodash';
import Button, { ButtonTypeEnum } from '../../../WebACL/CreateWebACL/components/Button';
import { AwsRuleActionType } from 'graphql/types/AwsDescribeManagedRuleGroup';
import SectionItem from '../../../WebACL/CreateWebACL/components/SectionItem';
import ButtonGroup from '../../../WebACL/CreateWebACL/components/ButtonGroup';
import { AwsRuleActionOverridelogType, AwsRulelogType, AwsStatementlogType } from 'graphql/types/AwsCheckCapacity';
import lazyGetAwsCheckCapacity from 'graphql/queries/getAwsCheckCapacity';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import InputValidIcon from 'assets/svgs/ic_green_check.svg';
import { WafScopeEnum } from '../../../Commons/Constant';
import lazyGetAwsValidateTemplate from 'graphql/queries/getAwsValidateTemplate';
import { getStatementObjectFromRule } from 'utils/Rule';

type EditRuleGroupModalPropsType = {
  isVisible: boolean;
  onClose: () => void;
  ruleGroup?: UIFreeRuleGroupType;
  cloudId: number;
  selectedRegion: string;
  customRules?: Array<AwsRulelogType>;
  setCustomRules: (rule: Array<AwsRulelogType>) => void;
  webAcl?: any;
  customRule?: AwsRulelogType;
};

const EditRuleGroupModal = (props: EditRuleGroupModalPropsType) => {
  const { isVisible, onClose, ruleGroup, cloudId, selectedRegion, customRules, setCustomRules, webAcl, customRule } =
    props;

  const [getAwsListAvailableManagedRuleGroupVersions] = lazyGetAwsListAvailableManagedRuleGroupVersions();
  const [checkCapacity, { loading: checkCapacityLoading }] = lazyGetAwsCheckCapacity();
  const [validateTemplate, { loading: validateTemplateLoading }] = lazyGetAwsValidateTemplate();

  const [versions, setVersions] = useState<Array<AwsManagedRuleGroupVersionType>>([]);
  const [currentVersion, setCurrentVersion] = useState<DropdownListDataType>();
  const [defaultVersion, setDefaultVersion] = useState('');
  const [scopeOfInpection, setScopeInspection] = useState('all');
  const [statements, setStatements] = useState([{ ...DEFAULT_STATEMENT_ITEM }]);
  const [statementData, setStatementData] = useState(statements);
  const [statementCondition, setStatementCondition] = useState(STATEMENT_CONDITION_DROPDOWN_LIST[0]);
  const [jsonStatement, setJsonStatement] = useState({});
  const [statement, setStatement] = useState<AwsStatementlogType>({});
  const [overrideRuleActions, setOverrideRuleActions] = useState<{ [key: string]: string }>({});
  const [overrideAllRuleActionDropdownItem, setOverrideAllRuleActionDropdownItem] = useState<DropdownListDataType>();
  const [isOverrideRuleGroupActionToCount, setOverrideRuleGroupActionToCount] = useState(false);
  const [ruleError, setRuleError] = useState('');
  const [ruleValid, setRuleValid] = useState('');

  const getRuleActionString = useCallback((action?: AwsRuleActionType) => {
    if (!action) {
      return '';
    }

    const key = _.findKey(action, object => object !== null);

    return _.startCase(key);
  }, []);

  const handleOverrideAllRuleAction = useCallback((action: DropdownListDataType) => {
    let newOverrideRuleActions = { ...overrideRuleActions };
    ruleGroup?.rules?.forEach(rule => (newOverrideRuleActions[rule?.name ?? ''] = action.value as string));

    setOverrideAllRuleActionDropdownItem(action);
    setOverrideRuleActions(newOverrideRuleActions);
  }, []);

  const checkOverrideAllRuleAction = useCallback((actions: { [key: string]: string }) => {
    const values = Object.values(actions);
    return _.every(values, value => value === values[0]);
  }, []);

  const handleOverrideRuleActionChanged = useCallback(
    (name: string, value: string) => {
      let newOverrideRuleActions = { ...overrideRuleActions };
      newOverrideRuleActions[name] = value;

      if (checkOverrideAllRuleAction(newOverrideRuleActions)) {
        handleOverrideAllRuleAction(OVERRIDE_RULE_ACTION_DROPDOWN_LIST.find(item => item.value === value)!);
      } else {
        setOverrideRuleActions(newOverrideRuleActions);

        if (overrideAllRuleActionDropdownItem) {
          setOverrideAllRuleActionDropdownItem(undefined);
        }
      }
    },
    [overrideRuleActions],
  );

  const removeAllOverrides = useCallback(() => {
    let newOverrideRuleActions = { ...overrideRuleActions };
    ruleGroup?.rules?.forEach(rule => (newOverrideRuleActions[rule?.name ?? ''] = ''));

    setOverrideRuleActions(newOverrideRuleActions);
    setOverrideAllRuleActionDropdownItem(undefined);
  }, [overrideRuleActions]);

  const getInitialData = useCallback(async () => {
    if (ruleGroup?.isVersioningSupported) {
      await getListVersions();
    }

    let allOverrideRuleActions: { [key: string]: string } = {};

    if (ruleGroup?.rules) {
      ruleGroup?.rules?.forEach(rule => {
        allOverrideRuleActions[rule?.name ?? ''] = '';
      });
      setOverrideRuleActions(allOverrideRuleActions);
    }

    // Version
    if (customRule?.statement?.managedRuleGroupStatement?.version) {
      if (customRule?.statement?.managedRuleGroupStatement?.version === defaultVersion) {
        setCurrentVersion({
          id: 0,
          name: `Default (using ${defaultVersion})`,
          value: defaultVersion ?? '',
        });
      } else {
        setCurrentVersion({
          id: versions.findIndex(version => version.name === customRule?.statement?.managedRuleGroupStatement?.version),
          name: customRule?.statement?.managedRuleGroupStatement?.version,
          value: customRule?.statement?.managedRuleGroupStatement?.version,
        });
      }
    }

    // Scope of inspection
    if (customRule?.statement?.managedRuleGroupStatement?.scopeDownStatement) {
      setScopeInspection('scopeDown');
    } else {
      setScopeInspection('all');
    }

    // Statement
    if (customRule?.statement?.managedRuleGroupStatement?.scopeDownStatement?.andStatement) {
      // AND Statements
      setStatementCondition(STATEMENT_CONDITION_DROPDOWN_LIST[1]);
      const statements = customRule?.statement?.managedRuleGroupStatement?.scopeDownStatement?.andStatement?.statements;
      setStatementData(statements?.map(statement => getStatementObjectFromRule(statement)) ?? []);
    } else if (customRule?.statement?.managedRuleGroupStatement?.scopeDownStatement?.orStatement) {
      // OR Statements
      setStatementCondition(STATEMENT_CONDITION_DROPDOWN_LIST[2]);
      const statements = customRule?.statement?.managedRuleGroupStatement?.scopeDownStatement?.orStatement?.statements;
      setStatementData(statements?.map(statement => getStatementObjectFromRule(statement)) ?? []);
    } else if (customRule?.statement?.managedRuleGroupStatement?.scopeDownStatement?.notStatement) {
      // NOT Statement
      setStatementCondition(STATEMENT_CONDITION_DROPDOWN_LIST[3]);
      const statement = customRule?.statement?.managedRuleGroupStatement?.scopeDownStatement?.notStatement?.statement;
      setStatementData([statement]);
    } else if (customRule?.statement?.managedRuleGroupStatement?.scopeDownStatement) {
      setStatementData([
        getStatementObjectFromRule(customRule?.statement?.managedRuleGroupStatement?.scopeDownStatement),
      ]);
    }

    // Override all rule actions
    if (customRule?.statement?.managedRuleGroupStatement?.ruleActionOverrides) {
      let newOverrideRuleActions = { ...allOverrideRuleActions };
      let tempAction = '';
      customRule?.statement?.managedRuleGroupStatement?.ruleActionOverrides?.forEach(item => {
        newOverrideRuleActions[item?.name ?? ''] = Object.keys(item?.actionToUse ?? {})?.[0] ?? '';
        tempAction = Object.keys(item?.actionToUse ?? {})?.[0] ?? '';
      });

      if (checkOverrideAllRuleAction(newOverrideRuleActions)) {
        handleOverrideAllRuleAction(OVERRIDE_RULE_ACTION_DROPDOWN_LIST.find(item => item.value === tempAction)!);
      } else {
        setOverrideRuleActions(newOverrideRuleActions);
      }
    }

    // Override rule group action
    if (Object.keys(customRule?.overrideAction ?? {})?.[0] === 'count') {
      setOverrideRuleGroupActionToCount(true);
    }
  }, [customRule, defaultVersion, versions]);

  useEffect(() => {
    getInitialData();
  }, []);

  const getListVersions = useCallback(async () => {
    const variables = {
      cloudId,
      region: selectedRegion !== 'CloudFront' ? String(selectedRegion) : 'us-east-1',
      request: {
        vendorName: ruleGroup?.vendorName,
        scope: selectedRegion !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
        name: ruleGroup?.managedRuleSetName,
      },
    };
    await getAwsListAvailableManagedRuleGroupVersions({ variables }).then(res => {
      setVersions(res?.data?.getAwsListAvailableManagedRuleGroupVersions?.data?.[0]?.versions ?? []);
      setDefaultVersion(res?.data?.getAwsListAvailableManagedRuleGroupVersions?.data?.[0]?.currentDefaultVersion ?? '');
    });
  }, [cloudId, selectedRegion, ruleGroup]);

  const handleCheckCapacity = useCallback(
    async (rules: Array<AwsRulelogType>) => {
      const variables = {
        cloudId: cloudId,
        region: selectedRegion !== 'CloudFront' ? String(selectedRegion) : 'us-east-1',
        request: {
          scope: selectedRegion !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
          rules: rules as Array<AwsRulelogType>,
        },
      };
      return await checkCapacity({ variables }).then(res => {
        if (res.error) {
          return 0;
        }

        return res?.data?.getAwsCheckCapacity?.data?.[0]?.capacity ?? 0;
      });
    },
    [cloudId, selectedRegion],
  );

  const getRuleActionOverrides = useCallback(() => {
    if (overrideAllRuleActionDropdownItem) {
      return (
        ruleGroup?.rules?.map(rule => ({
          name: rule.name,
          actionToUse: {
            [overrideAllRuleActionDropdownItem.value]: {},
          },
        })) ?? []
      );
    }

    let overridedRuleActions: Array<AwsRuleActionOverridelogType> = [];
    ruleGroup?.rules?.forEach(rule => {
      if (overrideRuleActions?.[rule?.name ?? '']) {
        overridedRuleActions.push({
          name: rule?.name,
          actionToUse: {
            [overrideRuleActions?.[rule?.name ?? '']]: {},
          },
        });
      }
    });

    return overridedRuleActions;
  }, [overrideAllRuleActionDropdownItem, ruleGroup, overrideRuleActions]);

  const getStatementData = useCallback((statementItem: any) => {
    let newStatement: AwsStatementlogType = {};

    if (statementItem?.inspect?.value === 'geoMatchStatement') {
      newStatement = {};
      newStatement.geoMatchStatement = {};
      newStatement.geoMatchStatement.countryCodes = statementItem?.countryCode?.map((code: any) => code.value);
      if (statementItem?.ipAddress === IP_ADDRESS_SELECTOR[1].value) {
        newStatement.geoMatchStatement.forwardedIPConfig = {
          headerName: statementItem?.headerFieldName,
          fallbackBehavior: statementItem?.missingIpAddress,
        };
      }
    }

    if (statementItem?.inspect?.value === 'ipSetReferenceStatement') {
      newStatement = {};
      newStatement.ipSetReferenceStatement = {};
      newStatement.ipSetReferenceStatement.arn = statementItem?.ipSet?.value;

      if (statementItem?.ipAddress === IP_ADDRESS_SELECTOR[1].value) {
        newStatement.ipSetReferenceStatement.ipSetForwardedIPConfig = {
          headerName: statementItem?.headerFieldName,
          fallbackBehavior: statementItem?.missingIpAddress,
          position: statementItem?.positionInsideHeader,
        };
      } else {
        delete newStatement.ipSetReferenceStatement.ipSetForwardedIPConfig;
      }
    }

    if (statementItem?.inspect?.value === 'labelMatchStatement') {
      newStatement = {};
      newStatement.labelMatchStatement = {
        scope: statementItem?.matchScope,
        key: statementItem?.matchKey,
      };
    }

    if (statementItem?.inspect?.value === 'SINGLE_HEADER') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        singleHeader: {
          name: statementItem?.headerFieldName,
        },
      };
      newStatement.byteMatchStatement.searchString = statementItem?.stringToMatch;
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'ALL_HEADER') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {};
      newStatement.byteMatchStatement.searchString = statementItem?.stringToMatch;
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );

      if (statementItem?.contentToInspect === 'all') {
        newStatement.byteMatchStatement.fieldToMatch = {
          headers: {
            matchScope: statementItem?.headerMatchScope,
            matchPattern: { all: {} },
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }

      if (statementItem?.contentToInspect === 'includedHeaders') {
        newStatement.byteMatchStatement.fieldToMatch = {
          headers: {
            matchScope: statementItem?.headerMatchScope,
            matchPattern: { includedHeaders: statementItem?.keys.split('\n') },
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }

      if (statementItem?.contentToInspect === 'excludedHeaders') {
        newStatement.byteMatchStatement.fieldToMatch = {
          headers: {
            matchScope: statementItem?.headerMatchScope,
            matchPattern: { excludedHeaders: statementItem?.keys.split('\n') },
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }
    }

    if (statementItem?.inspect?.value === 'COOKIES') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {};
      newStatement.byteMatchStatement.searchString = statementItem?.stringToMatch;
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );

      if (statementItem?.contentToInspect === 'all') {
        newStatement.byteMatchStatement.fieldToMatch = {
          cookies: {
            matchScope: statementItem?.headerMatchScope,
            matchPattern: { all: {} },
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }

      if (statementItem?.contentToInspect === 'includedHeaders') {
        newStatement.byteMatchStatement.fieldToMatch = {
          cookies: {
            matchScope: statementItem?.headerMatchScope,
            matchPattern: { includedCookies: statementItem?.keys.split('\n') },
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }

      if (statementItem?.contentToInspect === 'excludedHeaders') {
        newStatement.byteMatchStatement.fieldToMatch = {
          cookies: {
            matchScope: statementItem?.headerMatchScope,
            matchPattern: { excludedCookies: statementItem?.keys.split('\n') },
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }
    }

    if (statementItem?.inspect?.value === 'SINGLE_QUERY') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        singleQueryArgument: {
          name: statementItem?.queryArgument,
        },
      };
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'ALL_QUERY') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        allQueryArguments: {},
      };
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'URI_PATH') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        uriPath: {},
      };
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'QUERY_STRING') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        queryString: {},
      };
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'BODY') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {};

      if (statementItem?.contentType === 'plainText') {
        newStatement.byteMatchStatement.fieldToMatch = {
          body: {
            oversizeHandling: statementItem?.oversizeHandling?.value,
          },
        };
      }

      if (statementItem?.contentType === 'json') {
        newStatement.byteMatchStatement.fieldToMatch.jsonBody = {};
        newStatement.byteMatchStatement.fieldToMatch.jsonBody = {
          matchScope: statementItem?.jsonMatchScope,
          matchPattern: {},
          invalidFallbackBehavior: statementItem?.jsonRequest,
          oversizeHandling: statementItem?.oversizeHandling?.value,
        };
        newStatement.byteMatchStatement.fieldToMatch.jsonBody.matchPattern = {};

        if (statementItem?.bodyContentToInspect === BODY_CONTENT_TO_INSPECT[0].value) {
          newStatement.byteMatchStatement.fieldToMatch.jsonBody.matchPattern = {
            all: {},
          };
        }

        if (statementItem?.bodyContentToInspect === BODY_CONTENT_TO_INSPECT[1].value) {
          newStatement.byteMatchStatement.fieldToMatch.jsonBody.matchPattern = {
            includedPaths: statementItem?.includedElements?.map((item: string) => item.split('\n')),
          };
        }
      }

      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'HTTP_METHOD') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        method: {},
      };
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.searchString = statementItem?.stringToMatch;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'JA3_FIGERPRINT') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        ja3Fingerprint: {
          fallbackBehavior: statementItem?.ja3Fingerprint,
        },
      };
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.searchString = statementItem?.stringToMatch;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    if (statementItem?.inspect?.value === 'HEADER_ORDER') {
      newStatement = {};
      newStatement.byteMatchStatement = {};
      newStatement.byteMatchStatement.fieldToMatch = {
        headerOrder: {
          oversizeHandling: statementItem?.oversizeHandling?.value,
        },
      };
      newStatement.byteMatchStatement.positionalConstraint = statementItem?.matchType?.value;
      newStatement.byteMatchStatement.searchString = statementItem?.stringToMatch;
      newStatement.byteMatchStatement.textTransformations = statementItem?.transformation.map(
        (item: any, index: number) => ({
          type: item?.value?.value,
          priority: index,
        }),
      );
    }

    return newStatement;
  }, []);

  const customStatement = useMemo(() => {
    let newStatement = {} as AwsStatementlogType;

    newStatement.managedRuleGroupStatement = {
      vendorName: ruleGroup?.vendorName,
      name: ruleGroup?.managedRuleSetName,
    };

    let scopeDownStatement: AwsStatementlogType = {};

    if (statementCondition === STATEMENT_CONDITION_DROPDOWN_LIST[0]) {
      scopeDownStatement = getStatementData(statementData?.[0]);
    }

    if (statementCondition === STATEMENT_CONDITION_DROPDOWN_LIST[1]) {
      scopeDownStatement.andStatement = {};
      scopeDownStatement.andStatement.statements = statementData.map((item: any) => {
        if (item.statementResults) {
          return { notStatement: { statement: getStatementData(item) } };
        }

        return getStatementData(item);
      });
    }

    if (statementCondition === STATEMENT_CONDITION_DROPDOWN_LIST[2]) {
      scopeDownStatement.orStatement = {};
      scopeDownStatement.orStatement.statements = statementData.map((item: any) => {
        if (item.statementResults) {
          return { notStatement: { statement: getStatementData(item) } };
        }

        return getStatementData(item);
      });
    }

    if (statementCondition === STATEMENT_CONDITION_DROPDOWN_LIST[3]) {
      scopeDownStatement.notStatement = {};
      scopeDownStatement.notStatement.statement = getStatementData(statementData?.[0]);
    }

    if (!_.isEmpty(scopeDownStatement)) {
      newStatement.managedRuleGroupStatement.scopeDownStatement = scopeDownStatement;
    }

    const ruleActionOverrides = getRuleActionOverrides();

    if (ruleActionOverrides?.length > 0) {
      newStatement.managedRuleGroupStatement.ruleActionOverrides = ruleActionOverrides;
    }

    if (currentVersion) {
      newStatement.managedRuleGroupStatement.version = currentVersion.value as string;
    }

    return newStatement;
  }, [statementCondition, statementData, getRuleActionOverrides, currentVersion]);

  const handleChangeStatementValue = useCallback((_statement: AwsStatementlogType) => {
    setJsonStatement(pascalCaseKeys(_statement));
    setStatement(_statement);
  }, []);

  useEffect(() => {
    handleChangeStatementValue(customStatement);
  }, [customStatement]);

  const overrideRuleActionNode = useMemo(() => {
    if (ruleGroup?.rules?.length === 0) {
      return null;
    }

    return (
      <Box className="override-rule-action-container">
        <Box direction={FlexDirection.ROW} gap={16} className="override-all-rule-action-container">
          <Box className="override-all-rule-action-dropdown-container">
            <TitleGroup title="Override all rule actions" />
            <Dropdown
              data={OVERRIDE_RULE_ACTION_DROPDOWN_LIST}
              id={_.uniqueId('dropdown-')}
              handleClick={handleOverrideAllRuleAction}
              placeholder="Choose rule action override"
              className="override-rule-action-dropdown"
              value={overrideAllRuleActionDropdownItem}
            />
          </Box>
          <Button type={ButtonTypeEnum.GENERAL} label="Remove all overrides" onClick={removeAllOverrides} />
        </Box>

        <Box direction={FlexDirection.ROW} className="override-rule-action-list-container">
          {ruleGroup?.rules?.map(rule => {
            return (
              <Box className="override-rule-action-dropdown-container">
                <TitleGroup title={rule?.name} description={`Rule action: ${getRuleActionString(rule?.action)}`} />
                <Box center direction={FlexDirection.ROW} gap={8}>
                  <Dropdown
                    data={OVERRIDE_RULE_ACTION_DROPDOWN_LIST}
                    id={_.uniqueId('dropdown-')}
                    value={OVERRIDE_RULE_ACTION_DROPDOWN_LIST.find(
                      item => item.value === overrideRuleActions[rule?.name ?? ''],
                    )}
                    handleClick={item => handleOverrideRuleActionChanged(rule?.name ?? '', item.value as string)}
                    placeholder="Choose rule action override"
                    className="override-rule-action-dropdown"
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }, [ruleGroup, overrideRuleActions]);

  const handleValidateTemplate = useCallback(
    async (rule: AwsRulelogType) => {
      const request = {
        AWSTemplateFormatVersion: '2010-09-09',
        Resources: {
          MyWebACL: {
            Type: 'AWS::WAFv2::WebACL',
            Properties: {
              Name: webAcl?.name,
              Scope: selectedRegion === 'CloudFront' ? WafScopeEnum.CLOUDFRONT : WafScopeEnum.REGIONAL,
              DefaultAction: webAcl?.defaultAction,
              Rules: [pascalCaseKeys(rule)],
              VisibilityConfig: {
                SampledRequestsEnabled: true,
                CloudWatchMetricsEnabled: true,
                MetricName: webAcl?.cloudWatchMetricName,
              },
            },
          },
        },
      };

      const variables = {
        cloudId: cloudId,
        region: selectedRegion !== 'CloudFront' ? String(selectedRegion) : 'us-east-1',
        request: {
          templateBody: JSON.stringify(request),
        },
      };

      return await validateTemplate({ variables }).then(res => {
        if (res?.data?.getAwsValidateTemplate?.data?.[0]?.parameters?.length === 0) {
          return true;
        }

        return false;
      });
    },
    [webAcl, selectedRegion, cloudId],
  );

  const handleValidateRule = useCallback(
    async (rule: AwsRulelogType) => {
      const isValidCapacity = (await handleCheckCapacity([rule])) > 0;
      const isValidTemplate = await handleValidateTemplate(rule);
      const isValid = isValidCapacity && isValidTemplate;

      return isValid;
    },
    [handleCheckCapacity, handleValidateTemplate],
  );

  const handleAddRuleClicked = useCallback(async () => {
    let newCustomRules = [...(customRules ?? [])];
    let currentCustomRuleIndex = customRules?.findIndex(rule => rule.name === ruleGroup?.managedRuleSetName) ?? -1;
    if (currentCustomRuleIndex > -1) {
      if (customStatement) {
        newCustomRules[currentCustomRuleIndex].statement = customStatement;
      }

      if (isOverrideRuleGroupActionToCount) {
        newCustomRules[currentCustomRuleIndex].overrideAction = {
          count: {},
        };
      } else {
        newCustomRules[currentCustomRuleIndex].overrideAction = {
          none: {},
        };
      }

      const isValidRule = await handleValidateRule(newCustomRules[currentCustomRuleIndex]);

      if (isValidRule) {
        setRuleError('');
        setRuleValid('Rule is valid');
        setCustomRules(newCustomRules);
        onClose();
      } else {
        setRuleValid('');
        setRuleError('Rule is invalid');
      }
    }
  }, [customRules, ruleGroup, customStatement, handleValidateRule, isOverrideRuleGroupActionToCount]);

  return (
    <BaseModal open={isVisible} onClose={onClose} className="edit-rule-modal-container">
      <StepContainer>
        <SectionContainer title={ruleGroup?.productTitle}>
          <DescriptionTable
            ruleGroup={ruleGroup}
            versions={versions}
            defaultVersion={defaultVersion}
            currentVersion={currentVersion}
            setCurrentVersion={setCurrentVersion}
          />
        </SectionContainer>

        <SectionContainer title="Scope of inspection">
          <SectionTitle
            title="Choose the scope of inspection"
            description="You can inspect all web requests or only the requests that match the criteria in a scope-down rule statement."
          />
          <RadioButtonGroup
            data={SCOPE_OF_INSPECTION_RADIO_LIST}
            value={scopeOfInpection}
            onChangeValue={setScopeInspection}
          />
          {scopeOfInpection === SCOPE_OF_INSPECTION_RADIO_LIST[1].value && (
            <ScopeDownStatement
              cloudId={cloudId}
              selectedRegion={selectedRegion}
              statementData={statementData}
              setStatementData={setStatementData}
              statementCondition={statementCondition}
              setStatementCondition={setStatementCondition}
              json={jsonStatement ?? {}}
            />
          )}
        </SectionContainer>
        {!!ruleError && (
          <div className="rule-error-container">
            <img src={InputErrorIcon} width={18} height={18} />
            <p className="rule-error-message">{ruleError}</p>
          </div>
        )}

        <SectionContainer
          title={`${ruleGroup?.productTitle} rules`}
          description={
            'The rules apply actions and labels to requests that match their criteria.\nBy default, the rule gorup uses its configured rule actions. You can override that actions for all rules and for individual rules. For a single rule, use the rule dropdown to specify an override action ou to remove an override.\nAllow and Block actions terminate Web ACL evaluation for matching requests. Count action counts matching requests and continues the web ACL evaluation.'
          }
        >
          {overrideRuleActionNode}
        </SectionContainer>
        <SectionContainer>
          <SectionItem.Collapsible title={'Override rule group action'} caption="- optional">
            <Box gap={8} className="override-rule-group-action-container">
              <TitleGroup
                title={'Override rule group action'}
                description={
                  "This only overrides the resulting rule group action to count. It doesn't alter the actions for the rules in the rule group. To override the rule actions, make your changes in the Rules pane, above."
                }
              />
              <SectionItem.Checkbox
                checked={isOverrideRuleGroupActionToCount}
                onchange={() => setOverrideRuleGroupActionToCount(!isOverrideRuleGroupActionToCount)}
                label="Override rule group action to count"
              />
            </Box>
          </SectionItem.Collapsible>
        </SectionContainer>

        <Box direction={FlexDirection.ROW} gap={16} className="edit-rule-button-group">
          <Button label="Cancel" onClick={onClose} />
          <Button
            label="Save rule"
            type={ButtonTypeEnum.PRIMARY}
            onClick={handleAddRuleClicked}
            loading={checkCapacityLoading}
          />
        </Box>
      </StepContainer>
    </BaseModal>
  );
};

export default EditRuleGroupModal;
