import { UpdateTagPropsType } from '../types';
import BaseModal from 'components/v2/modals/BaseModal';
import IconTag from 'assets/svgs/v3/ico_tag.svg';
import Icon from 'components/v2/atoms/Icon';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import { useCallback, useMemo, useState } from 'react';
import tagAwsResourcesMutation, { ITagAwsResourceVariables } from 'graphql/mutations/tagAwsResource';
import untagAwsResourcesMutation, { IAwsUntagResourceRequestVariables } from 'graphql/mutations/untagAwsResource';
import InputAtom from 'components/v2/atoms/InputAtom';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import { AwsTagType } from 'graphql/types/AwsFirewall';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import './index.scss';

export interface TagRowData {
  key: string;
  value: string;
  isNewTag?: boolean;
}

const UpdateTag = (props: UpdateTagPropsType) => {
  const { cloudId, region, tlsInspectionDetail, onCancel, onUpdated, ...baseModalProps } = props;

  const { tags } = tlsInspectionDetail?.tlsInspectionConfigurationResponse;
  const [allTagRow, setAllTagRow] = useState<TagRowData[]>(tags ?? []);
  const [errorRequired, setErrorRequired] = useState<boolean[]>([]);

  const [tagAwsResources, { loading: addTagLoading }] = tagAwsResourcesMutation();
  const [untagAwsResources, { loading: removeTagLoading }] = untagAwsResourcesMutation();

  const rowRemaining = useMemo(() => {
    return 50 - allTagRow.length;
  }, [allTagRow]);

  const validateDuplicateKey = useCallback(
    (row: TagRowData) => {
      const rowFiltered = allTagRow.filter(item => row.key !== '' && item.key === row.key);
      return rowFiltered.length > 1;
    },
    [allTagRow],
  );

  const handleCreate = () => {
    setAllTagRow(prevState => {
      const newState = prevState.concat({ key: '', value: '', isNewTag: true });
      return newState;
    });

    setErrorRequired([...errorRequired, false]);
  };

  const handleDelete = (indexToRemove: number) => {
    const newData = allTagRow.filter((_, index) => index != indexToRemove);
    setAllTagRow(newData);
  };

  const handleChange = (index: number, value: string, propName: keyof TagRowData) => {
    // reset error required when change key value
    if (propName === 'key') {
      const updErrorRequired = [...errorRequired];
      updErrorRequired[index] = false;
      setErrorRequired(updErrorRequired);
    }

    const newRows = allTagRow.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setAllTagRow(newRows);
  };

  const handleCreateTags = useCallback(
    async (tags: AwsTagType[]) => {
      const variables: ITagAwsResourceVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          resourceArn: tlsInspectionDetail?.tlsInspectionConfigurationResponse?.tlsInspectionConfigurationArn ?? '',
          tags,
        },
      };

      const { data: responseData } = await tagAwsResources({ variables });

      return responseData?.tagAwsResource;
    },
    [cloudId, region, tlsInspectionDetail],
  );

  const handleDeleteTags = useCallback(
    async (deleTags: AwsTagType[]) => {
      const tagKeys = deleTags.map(t => t.key);
      const variables: IAwsUntagResourceRequestVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          resourceArn: tlsInspectionDetail?.tlsInspectionConfigurationResponse?.tlsInspectionConfigurationArn ?? '',
          tagKeys,
        },
      };
      const { data: responseData } = await untagAwsResources({ variables });

      return responseData?.untagAwsResource;
    },
    [cloudId, region, tlsInspectionDetail],
  );

  const updateCertificate = useCallback(async () => {
    try {
      // if tag key empty -> set error required = true
      const errorRequires = allTagRow.map(tag => !tag.key);
      const hasRequiredError = errorRequires.some(error => error);
      if (hasRequiredError) {
        setErrorRequired(errorRequires);
        return;
      }

      // check duplicate key
      const hasDuplicateKey = allTagRow.some(tag => validateDuplicateKey(tag));
      if (hasDuplicateKey) {
        return;
      }

      const newsTags: AwsTagType[] = allTagRow.map(t => ({ key: t.key, value: t.value }));

      // init tag empty & no change
      if (!tags.length && !newsTags.length) {
        baseModalProps?.onClose?.();
        return;
      }

      // if has init tags -> call api remove init tags & add new tags
      // otherwise call api add new tags
      if (tags.length) {
        //remove init tags
        await handleDeleteTags(tags);
        // add new tags
        if (newsTags.length) {
          await handleCreateTags(newsTags);
        }
      } else {
        // add new tags
        await handleCreateTags(newsTags);
      }

      useToast(ErrorCode.SUCCESS, 'Update firewall tags successfully.');
      onUpdated();
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Update firewall tags failed.');
    }
  }, [allTagRow, tags, handleDeleteTags, handleCreateTags]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconTag} />
          {'Edit Tags'}
        </>
      )}
      {...baseModalProps}
    >
      <div className="update-tag-model">
        <div className="sub-title">{'Edit policy tags'}</div>
        {allTagRow.length ? (
          <>
            <div className="tag-row-container">
              <div className="tag-row-input">
                <div className="column">
                  <p>Key</p>
                </div>
                <div className="column">
                  <p>Value - optional</p>
                </div>
              </div>
              <div className="tag-row-btn"></div>
            </div>
            {allTagRow.map((row, index) => (
              <div key={`update-tags-table-new-row-${index}`}>
                <div className="tag-row-container">
                  <div className="tag-row-input">
                    <div className="column">
                      <InputAtom
                        value={row.key}
                        onChangeValue={(value: string) => handleChange(index, value, 'key')}
                        noClear={!row?.isNewTag}
                        disabled={!row?.isNewTag}
                        error={validateDuplicateKey(row) || (errorRequired.length > 0 && errorRequired[index])}
                      />
                    </div>
                    <div className="column">
                      <InputAtom
                        value={row.value}
                        onChangeValue={(value: string) => handleChange(index, value, 'value')}
                        noClear={false}
                      />
                    </div>
                  </div>
                  <div className="tag-row-btn">
                    <button className="button" onClick={() => handleDelete(index)}>
                      Remove
                    </button>
                  </div>
                </div>
                {validateDuplicateKey(row) && (
                  <div className="error-container">
                    <img src={InputErrorIcon} width={16} height={16} />
                    <p className="error-message">You must specify a unique tag key.</p>
                  </div>
                )}
                {errorRequired.length > 0 && errorRequired[index] && (
                  <div className="error-container">
                    <img src={InputErrorIcon} width={16} height={16} />
                    <p className="error-message">You must specify a tag key</p>
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <p className="text">No tags associated with the resource.</p>
        )}
        <div className="add-new-tag-container">
          <Button
            label="Add new tag"
            disabled={rowRemaining === 0}
            type={ButtonTypeEnum.PRIMARY}
            onClick={handleCreate}
          />
          <p className="note">You can add {rowRemaining} tags.</p>
        </div>

        <div className="cancel-save-container">
          <Button label="Cancel" onClick={onCancel} />
          <Button
            label="Save changes"
            type={ButtonTypeEnum.PRIMARY}
            onClick={() => updateCertificate()}
            loading={addTagLoading || removeTagLoading}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default UpdateTag;
