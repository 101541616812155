import { gql } from '@apollo/client';

const query = gql`
  mutation untagAwsResource($request: AwsUntagResourceRequest, $cloudId: Long, $region: String) {
    untagAwsResource(untagResourceRequest: $request, cloudId: $cloudId, region: $region) {
      result
    }
  }
`;
export default query;
