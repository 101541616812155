import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData, IGqlResponseNewData } from '../../types';
import { AwsUpdateRuleGroupFirewallRequestType } from 'graphql/types/AwsUpdateRuleGroupFirewall';

export type AwsUpdateRuleGroupFirewallVariablesType = {
  cloudId: number;
  region: string;
  request: AwsUpdateRuleGroupFirewallRequestType;
};

export type AwsUpdateRuleGroupFirewallResponseDataType = {
  updateAwsRuleGroupFirewall: IGqlResponseNewData<AwsUpdateRuleGroupFirewallResponseDataType>;
};

const updateAwsRuleGroupFirewallMutation = () =>
  useMutation<AwsUpdateRuleGroupFirewallResponseDataType, AwsUpdateRuleGroupFirewallVariablesType>(query);
export default updateAwsRuleGroupFirewallMutation;
