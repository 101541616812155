import { gql } from '@apollo/client';

const query = gql`
  query getAwsListFirewallPolicies($request: AwsListFirewallPoliciesNetworkFirewallRequest, $cloudId: Long, $region: String) {
    getAwsListFirewallPolicies(
      listFirewallPoliciesNetworkFirewallRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsListFirewallPoliciesNetworkFirewallResponse {
          nextToken
          firewallPolicies {
            name
            arn
          }
        }
      }
    }
  }
`;
export default query;
