import { gql } from '@apollo/client';

const query = gql`
  mutation createAwsSecret($reqData: AwsCreateSecretRequest, $cloudId: Long, $region: String) {
    createAwsSecret(createSecretRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsCreateSecretResponse {
          arn
          name
          replicationStatus {
            region
            kmsKeyId
            status
            statusMessage
            lastAccessedDate
          }
          versionId
        }
      }
    }
  }
`;

export default query;
