import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import InputAtom from 'components/v2/atoms/InputAtom';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import lazyGetAwsAllListFirewallPolicies from 'graphql/queries/getAwsAllListFirewallPolicies';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { handleFormatText } from 'utils/Common';
import { IAwsFirewall, IFirewallListProps } from '../../../types';

interface IFirewallPolicyProps extends IFirewallListProps {}

const FirewallPolicy = (props: IFirewallPolicyProps) => {
  const {
    cloudId,
    region,
    currentRegion,
    currentRouteState,
    setCurrentRouteState,
    relatedCloudSelected,
    setDetailItem,
    setIsDetailPage,
    onNavigateCreation,
  } = props;

  const [getAwsAllListFirewallPolicies] = lazyGetAwsAllListFirewallPolicies();

  const [isLoading, setIsLoading] = useState(false);
  const [nextToken, setNextToken] = useState<string>('');
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [searchValue, setSearchValue] = useState('');
  const [scopeNFilterVariable, setScopeNFilterVariable] = useState('');

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <span
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  setDetailItem(row as IAwsFirewall);
                  setIsDetailPage();
                }}
              >
                {handleFormatText(row?.name)}
              </span>
            }
          </>
        ),
      },
    ];
  }, [mainTblRows]);

  const getVariableData = useCallback(() => {
    return {
      cloudId: relatedCloudSelected.value,
      region: currentRegion.value as string,
      request: {
        maxResults: mainTablePagination.limit,
      },
    };
  }, [currentRegion, currentRouteState, relatedCloudSelected, searchValue]);

  const handleGetMainDataRows = useCallback(
    async (nextToken?: string) => {
      if (!region) return;

      try {
        setIsLoading(true);
        const { data: listFireWalls } = await getAwsAllListFirewallPolicies({
          variables: variableCombineNextToken(getVariableData(), nextToken),
        });

        if (!listFireWalls?.getAwsAlllistFirewallPolicies?.data?.length) {
          setIsLoading(false);
          return;
        }

        const firewallPolicyData: RowType[] = [];
        listFireWalls?.getAwsAlllistFirewallPolicies?.data?.forEach(item => {
          firewallPolicyData.push({
            ...item,
            id: item.arn,
          });
        });

        const allFirewallPolicyData = [...mainTblRows, ...firewallPolicyData];
        setNextToken(listFireWalls?.getAwsAlllistFirewallPolicies?.nextToken as string);
        setMainTblRows(allFirewallPolicyData);
        setMainTblTotal({
          totalPage: Math.ceil(allFirewallPolicyData.length / mainTablePagination.limit),
          totalElement: allFirewallPolicyData.length,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [region, mainTblRows, mainTablePagination, getVariableData],
  );

  const mainRowsCurrentPage = useMemo((): RowType[] => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!nextToken) {
      handleGetMainDataRows(nextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    let totalRows = mainTblRows;
    totalRows = mainTblRows?.filter(row => {
      return row.name?.includes(scopeNFilterVariable);
    });

    setMainTblTotal({
      totalPage: Math.ceil(totalRows.length / mainTablePagination.itemPerPage),
      totalElement: totalRows.length,
    });

    return orderAlphabetical(totalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, scopeNFilterVariable, mainTablePagination, nextToken]);

  useEffect(() => {
    handleGetMainDataRows();
  }, [cloudId]);

  const handleSearch = useCallback(() => {
    if (searchValue === scopeNFilterVariable) return;

    setScopeNFilterVariable(searchValue);
    updateTablePagination('currentPage', 1);
  }, [searchValue, scopeNFilterVariable]);

  const handleSelectItem = useCallback(
    (id: string) => {
      const rowSelected = mainTblRows.find(row => row.id === id);

      if (!rowSelected) return;

      setDetailItem(rowSelected as IAwsFirewall);
    },
    [mainTblRows],
  );

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Firewall policies</p>
        </div>

        <div className="flex action a-center">
          <InputAtom
            placeholder=""
            type={'text'}
            value={searchValue}
            defaultValue={''}
            onChangeValue={setSearchValue}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={handleSearch}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearch();
              }
            }}
          />
          <button className="action-btn" onClick={onNavigateCreation}>
            Create firewall policy
          </button>
        </div>
      </div>

      {!mainRowsCurrentPage?.length && !isLoading ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="data-grid-wrap">
          <Table
            rows={mainRowsCurrentPage}
            columns={columns}
            reportCheckedList={() => {}}
            reportSelected={id => handleSelectItem(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={isLoading}
            horizontalScrollable
          />

          {!!mainRowsCurrentPage?.length && !isLoading && (
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{mainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={!!nextToken}
                currentPage={mainTablePagination.currentPage}
                updateCurrentPage={page =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default FirewallPolicy;
