import lazyGetAwsAllListGroupResources, {
  IAwsAllListGroupResourcesPageVariables,
} from 'graphql/queries/getAwsAllListGroupResources';
import { MatchingResourcePropsType } from './types';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdTabLayout/types';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { extractResourceArnData } from 'utils/Common';
import lazyGetAwsAllResources, { IAwsAllResourcesPageVariables } from 'graphql/queries/getAwsAllResources';
import { FIREWALL_COMMOM_VALUE_MAPPING } from '../../configs';
import './MatchingResource.scss';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';

const MatchingResource = (props: MatchingResourcePropsType) => {
  const { cloudId, region, resourceGroup } = props;
  const navigate = useNavigate();
  // API
  const [getAwsAllListGroupResources, { loading: getAwsAllListGroupResourcesLoading }] =
    lazyGetAwsAllListGroupResources();
  const [getAwsAllResources, { loading: getAllResourceLoading }] = lazyGetAwsAllResources();

  // State
  const [mainTblTotalRows, setMainTblTotalRows] = useState<RowType[]>([]);
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [tablePagination, setTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const apiLoading = useMemo((): boolean => {
    return getAwsAllListGroupResourcesLoading || getAllResourceLoading;
  }, [getAwsAllListGroupResourcesLoading, getAllResourceLoading]);

  const COLMUN_MATCHING_RESOURCE = useMemo((): ColumnType[] => {
    return [
      { label: 'Identifier', field: 'identifier', renderCell: row => {
        return (
          <p
            className="link"
            onClick={() =>
              navigate('/organ/1/manage/instance', {
                replace: true,
                state: {
                  managementType: ManagementTypeEnum.INSTANCE,
                  tabId: row.identifier,
                  value: row.identifier,
                },
              })
            }
          >
            {row.identifier}
          </p>
        );
      } },
      { label: 'Name', field: 'name' },
      { label: 'Service', field: 'service' },
      { label: 'Type', field: 'type' },
      { label: 'Region', field: 'region' },
    ];
  }, []);

  const mainRowsCurrentPage = useMemo(() => {
    if (tablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchListGroupResourceData(mainNextToken);
    }
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblTotalRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotal, tablePagination, mainNextToken, mainTblTotalRows]);

  const updateTablePagination = useCallback((key: string, value: number | string | OrderDirection) => {
    setTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const reqVariable = useMemo((): IAwsAllListGroupResourcesPageVariables => {
    return { cloudId, region, request: { maxResults: tablePagination.limit, group: resourceGroup.groupArn } };
  }, [cloudId, region, tablePagination, resourceGroup]);

  const fetchListGroupResourceData = useCallback(
    async (nextToken?: string, isInitial?: boolean) => {
      const combinedVariable = variableCombineNextToken(reqVariable, nextToken);
      const matchingResourcesSectionContentRows: RowType[] = [];
      const data = await getAwsAllListGroupResources({ variables: combinedVariable }).then(
        ({ data: listResourceGroups }) => {
          return listResourceGroups;
        },
      );

      const awsGroupResourcesData = data?.getAwsAllListGroupResources?.data ?? [];

      if (awsGroupResourcesData.length > 0) {
        const resourceARNList: string[] = awsGroupResourcesData.map(gs => {
          return gs.resourceArn;
        });

        const variablesResources: IAwsAllResourcesPageVariables = {
          cloudId: cloudId,
          region: region,
          request: {
            resourceARNList: resourceARNList,
          },
        };

        const awsResourcesData = await getAwsAllResources({
          variables: variablesResources,
        }).then(({ data: groupResources }) => {
          return groupResources?.getAwsAllResources?.data;
        });
        if (awsResourcesData) {
          awsResourcesData.map(item => {
            matchingResourcesSectionContentRows.push({
              identifier: extractResourceArnData(item.resourceARN).id,
              name: item.tags.find(i => i.key === 'Name')?.value,
              service: FIREWALL_COMMOM_VALUE_MAPPING[extractResourceArnData(item.resourceARN).service || ''],
              type: FIREWALL_COMMOM_VALUE_MAPPING[extractResourceArnData(item.resourceARN).resourceType || ''],
              region: extractResourceArnData(item.resourceARN).region,
            });
          });
        }

        let allResourceGroups: RowType[] = [];
        if (isInitial) {
          allResourceGroups = matchingResourcesSectionContentRows;
        } else {
          allResourceGroups = [...mainTblTotalRows, ...matchingResourcesSectionContentRows];
        }
        
        setMainTblTotalRows(allResourceGroups);
        setMainNextToken(data?.getAwsAllListGroupResources?.nextToken as string);
        setMainTblTotal({
          totalPage: Math.ceil(allResourceGroups.length / tablePagination.itemPerPage),
          totalElement: allResourceGroups.length,
        });
      }
    },
    [reqVariable, mainTblTotalRows],
  );

  useEffect(() => {
    fetchListGroupResourceData('', true);
  }, []);

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>Matching resources</p>
      </div>
      <div className="detail-info-content">
        <div className="data-grid-wrap">
          <Table
            rows={mainRowsCurrentPage}
            columns={COLMUN_MATCHING_RESOURCE}
            sortOption={{
              target: tablePagination.target,
              direction: tablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={apiLoading}
            horizontalScrollable={true}
            isEmpty
            titleEmpty="No resources"
          />

          {mainRowsCurrentPage?.length && !apiLoading ? (
            <div className="fleet-instance matching-detail-info flex a-center">
              <p className="flex a-center">
                  Total <span>{mainTblTotal.totalElement}</span>
              </p>

              <TableManagePagination
                ableFetchMore={false}
                currentPage={tablePagination.currentPage}
                updateCurrentPage={page => updateTablePagination('currentPage', page)}
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MatchingResource;
