import { useLazyQuery } from '@apollo/client';
import query from './query';
import {
  AwsDescribeDbInstancesRequestType,
  AwsDescribeDbInstancesResponseType,
} from 'graphql/types/AwsDescribeDbIntaces';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsDescribeDbInstancesVariable {
  cloudId: number;
  region: string;
  reqData: AwsDescribeDbInstancesRequestType;
}

export interface IAwsDescribeDbInstancesResponseData {
  getAwsDescribeDbInstances: IGqlResponseNewData<AwsDescribeDbInstancesResponseType>;
}

const lazyGetAwsDescribeDbInstances = () =>
  useLazyQuery<IAwsDescribeDbInstancesResponseData, IAwsDescribeDbInstancesVariable>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeDbInstances;
