import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsListWorkgroupsRequestType, AwsListWorkgroupsResponseType } from 'graphql/types/AwsListWorkgroups';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsListWorkgroupsVariable {
  cloudId: number;
  region: string;
  reqData: AwsListWorkgroupsRequestType;
}

export interface IAwsListWorkgroupsResponseData {
  getAwsListWorkgroups: IGqlResponseNewData<AwsListWorkgroupsResponseType>;
}

const lazyGetAwsListWorkgroups = () =>
  useLazyQuery<IAwsListWorkgroupsResponseData, IAwsListWorkgroupsVariable>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListWorkgroups;
