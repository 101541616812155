import { gql } from '@apollo/client';

const query = gql`
query getAwsTagKeys($request: AwsGetTagKeysRequest, $cloudId: Long, $region: String) {
  getAwsTagKeys(
    awsGetTagKeysRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsGetTagKeysResponse {
        paginationToken
        tagKeys
      }
    }
  }
}`;
export default query;