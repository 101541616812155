import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import ToggleSwitchAtom from 'components/v4/atoms/ToggleSwitchAtom';
import { useAuth } from 'contexts/AuthProvider';
import { dateFormatter } from 'utils/Formatter';
import MFARemoveDeviceModal from '../../MFARemoveDeviceModal';
import MFASetupDeviceModal from '../../MFASetupDeviceModal';
import { ProfileAlertType } from '@Types/v4/Member';
import Icon from 'components/v2/atoms/Icon';
import RedCircleDevice from 'assets/svgs/v4/ico_red_circle_mfa_device.svg';
import _ from 'lodash';
import { useMutation } from 'react-query';
import { ISetMfaReq } from 'apis/v2/User/schema';
import apis from 'apis/v2';
import { ErrorCode, ErrorMessage } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';

interface IEnvironmentProps {
  mfaData: string | null;
  alertList: ProfileAlertType[];
  editAlertList: (list: ProfileAlertType[]) => void;
}

const Environment = ({
  mfaData,
  alertList,
  editAlertList
}:IEnvironmentProps) => {

  const { token, userInfo } = useAuth();
  const [device, setDevice] = useState('');
  const [openModal, setOpenModal] = useState<'remove'|'assign'|''>();
  const [passcodeTryCount, setPasscodeTryCount] = useState(0);

  const checkAllAlertEnabled = useMemo(() => {
    return alertList.every(pj => pj.sms === true && pj.slack === true && pj.kakaotalk === true);
  }, [alertList]);
  
  const { mutateAsync: _setMfa } = useMutation((payload:ISetMfaReq) => apis.User.setMfa(payload));
  const { mutateAsync: _unsetMfa } = useMutation((payload:string) => apis.User.unsetMfa(payload));
  
  const changeProjectAlert = (index: number, key: 'sms' | 'slack' | 'kakaotalk') => {
    editAlertList(alertList.map((d, idx) => idx === index ? {...d, [key]: !d[key]} : d));
  };
  const changeAllProjectAlert = (type: boolean) => {
    editAlertList(alertList.map((d, idx) => ({...d, sms: type, slack: type, kakaotalk: type})));
  };
  const removeDevice = () => {
    _unsetMfa(token).then(({data}) => {
      if (data.result === ErrorCode.SUCCESS) {
        useToast(data.result, "MFA setup had been disabled");
        setDevice('');
        modalClose();
      } else {
        useToast(ErrorCode.UNKNOWN);
      }
    }).catch(() => {
      useToast(ErrorCode.UNKNOWN);
    })
  }
  const addDevice = (code1:string) => {
    _setMfa({token: token, passcode: code1}).then(({ data }) => {
      if (data.result === ErrorCode.SUCCESS) {
        useToast(data.result, "MFA code verified. completed the MFA setup.");
        setDevice(`Created at ${dateFormatter(new Date().toISOString(), 'date').replace(/-/g, '.')}`);
        modalClose();
      } else if(data.result === ErrorCode.MFA_PASSCODE_ERROR) {
        useToast(data.result);
        if(passcodeTryCount + 1 >= 3) {
          modalClose();
        } else {
          setPasscodeTryCount(prev => prev + 1);
        }
      } else {
        useToast(ErrorCode.UNKNOWN);
      }
    }).catch(() => {
      useToast(ErrorCode.UNKNOWN);
    });
  }
  const modalClose = () => {
    setPasscodeTryCount(0);
    setOpenModal('');
  }

  useEffect(() => {
    if(mfaData) {
      setDevice(`Created at ${dateFormatter(mfaData, 'date').replace(/-/g, '.')}`);
    } else {
      setDevice('');
    }
  }, [mfaData])

  return (
    <>
      <div className="env-setting-box">
        <h4>Environment setting</h4>
        <div className="row-with-right-group flex j-between a-center">
          MFA (Optional)
          <div className="flex gap8">
            <button className="big-sub-btn" type="button" onClick={() => setOpenModal('remove')} disabled={device === ''}>Remove</button>
            <button className="big-sub-btn" type="button" onClick={() => setOpenModal('assign')} disabled={device !== ''}>Assign</button>
          </div>
        </div>
        <div className="device-info-box">
          {device === '' ? 'n/a' : device}
        </div>
        <div className="row-with-right-group mb-16 flex j-between a-center">
          Alert setting
          <div className='flex a-center gap8'>
            <ToggleSwitchAtom 
              value={checkAllAlertEnabled}
              onClick={() => {
                changeAllProjectAlert(!checkAllAlertEnabled)
              }}
              width={34}
              height={22}
              space={3}
            />
            All
          </div>
        </div>
        <div className="row-with-right-group pl-8 mb-8 flex j-between a-center">
          Project name
          <div className='flex a-center gap16'>
            <span className="label">SMS</span>
            <span className="label">Slack</span>
            <span className="label">Kakaotalk</span>
          </div>
        </div>
        {alertList.map((pj, pjIdx) => 
        <div key={pjIdx} className={`row-with-right-group pl-8 pr-19 ${pjIdx < alertList.length - 1 ? 'mb-14' : ''} flex j-between a-center`}>
          {pj.name}
          <div className='flex a-center gap16'>
            <ToggleSwitchAtom 
              value={pj.sms}
              onClick={() => {
                changeProjectAlert(pjIdx, 'sms');
              }}
              width={34}
              height={22}
              space={3}
            />
            <ToggleSwitchAtom 
              value={pj.slack}
              onClick={() => {
                changeProjectAlert(pjIdx, 'slack');
              }}
              width={34}
              height={22}
              space={3}
            />
            <ToggleSwitchAtom 
              value={pj.kakaotalk}
              onClick={() => {
                changeProjectAlert(pjIdx, 'kakaotalk');
              }}
              width={34}
              height={22}
              space={3}
            />
          </div>
        </div>)}
      </div>
      
      <MFARemoveDeviceModal 
        open={openModal === 'remove'} 
        onClose={modalClose}
        title={() => <><Icon width={32} height={32} src={RedCircleDevice} />Remove MFA device</>}
        buttonTitle="Remove"
        onConfirm={removeDevice}
        content={() => <>
          This MFA device can no longer be used when signing in.
        </>}
      />
      <MFASetupDeviceModal 
        open={openModal === 'assign'} 
        onClose={modalClose} 
        title={() => <><Icon width={32} height={32} src={RedCircleDevice} />Setup device</>}
        buttonTitle="Add device"
        onConfirm={addDevice}
        content={() => <>
        A virtual MFA device is an application running on your device  that you can configure by scanning a QR code.
        </>}
      />
    </>
  );
};

export default Environment;
