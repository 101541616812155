import { useMutation } from '@apollo/client';
import {
  AwsUpdateFirewallDeleteProtectionRequestType,
  AwsUpdateFirewallDeleteProtectionResponseType,
} from 'graphql/types/AwsUpdateFirewall';
import { IGqlResponseNewData } from '../../types';
import query from './query';

export type AwsUpdateFirewallDeleteProtectionVariablesType = {
  cloudId: number;
  region: string;
  request: AwsUpdateFirewallDeleteProtectionRequestType;
};

export type AwsUpdateFirewallDeleteProtectionResponseDataType = {
  updateAwsFirewallDeleteProtection: IGqlResponseNewData<AwsUpdateFirewallDeleteProtectionResponseType>;
};

const updateFirewallDeleteProtectionMutation = () =>
  useMutation<AwsUpdateFirewallDeleteProtectionResponseDataType, AwsUpdateFirewallDeleteProtectionVariablesType>(query);
export default updateFirewallDeleteProtectionMutation;
