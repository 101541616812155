import { gql } from '@apollo/client';

const query = gql`
  query getAwsManagedPrefixLists($request: AwsDescribeManagedPrefixListsRequest, $cloudId: Long, $region: String) {
    getAwsManagedPrefixLists(describeManagedPrefixListsRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsDescribeManagedPrefixListsResponse {
          nextToken
          prefixLists {
            prefixListId
            addressFamily
            state
            stateMessage
            prefixListArn
            prefixListName
            maxEntries
            version
            tags {
              key
              value
            }
            ownerId
          }
        }
      }
    }
  }
`;
export default query;
