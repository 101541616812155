import { useCallback, useEffect, useMemo, useState } from 'react';
import { ScopeConfigPropsType } from './types';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdTabLayout/types';
import { FIREWALL_RULES_PROTOCOL_MAPPING } from '../../configs';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { AwsAddressLog, AwsPortRangeLog } from 'graphql/types/AwsTlsInspectionConfiguration';

const ScopeConfig = (props: ScopeConfigPropsType) => {
  const { scopeConfig, actionNode } = props;

  const [mainTblTotalRows, setMainTblTotalRows] = useState<RowType[]>([]);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [tablePagination, setTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Protocol',
        field: 'protocols',
        sort: true,
        renderCell: (row: RowType) =>
          row?.protocols?.map((protocol: number) => <p>{FIREWALL_RULES_PROTOCOL_MAPPING[protocol]?.text ?? ''}</p>),
      },
      {
        label: 'Source',
        field: 'source',
        sort: true,
        renderCell: (row: RowType) => row?.sources?.map((source: AwsAddressLog) => <p>{source.addressDefinition}</p>),
      },
      {
        label: 'Destination',
        field: 'destination',
        sort: true,
        renderCell: (row: RowType) =>
          row?.destinations?.map((destination: AwsAddressLog) => <p>{destination.addressDefinition}</p>),
      },
      {
        label: 'Source port',
        field: 'sourcePort',
        sort: true,
        renderCell: (row: RowType) =>
          row?.sourcePorts?.map((sourcePort: AwsPortRangeLog) => {
            if (sourcePort.fromPort === sourcePort.toPort) {
              return <p>{`${sourcePort.fromPort}`}</p>
            } else {
              return <p>{`${sourcePort.fromPort}:${sourcePort.toPort}`}</p>
            }
          }),
      },
      {
        label: 'Destination port',
        field: 'desPort',
        sort: true,
        renderCell: (row: RowType) =>
          row?.destinationPorts?.map((destinationPort: AwsPortRangeLog) => {
            if (destinationPort.fromPort === destinationPort.toPort) {
              return <p>{`${destinationPort.fromPort}`}</p>
            } else {
              return <p>{`${destinationPort.fromPort}:${destinationPort.toPort}`}</p>
            }
          }),
      },
    ];
  }, []);

  const geneateData = useCallback(async () => {
    if (scopeConfig.length > 0) {
      const scopeSectionContentRows: RowType[] = [];
      scopeConfig.map(item => {
        scopeSectionContentRows.push(item);
      });
      setMainTblTotalRows(scopeSectionContentRows);
      setMainTblTotal({
        totalPage: Math.ceil(scopeSectionContentRows.length / tablePagination.itemPerPage),
        totalElement: scopeSectionContentRows.length,
      });
    }
  }, [tablePagination, scopeConfig]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  useEffect(() => {
    geneateData();
  }, [geneateData]);

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>{'Scope configuration'}</p>
        {actionNode && actionNode}
      </div>

      {!mainTblTotalRows.length ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="detail-info-content">
          <div className="data-grid-wrap">
            <Table
              rows={mainTblTotalRows}
              columns={columns}
              sortOption={{
                target: tablePagination.target,
                direction: tablePagination.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  updateTablePagination('target', target);
                  updateTablePagination('direction', dir);
                },
              }}
              isLoading={false}
              horizontalScrollable={true}
            />

            {mainTblTotalRows?.length ? (
              <div className="fleet-instance pagination-wrapper flex a-center">
                <p className="flex a-center">
                  Total <span>{mainTblTotal.totalElement}</span>
                </p>

                <TableManagePagination
                  ableFetchMore={false}
                  currentPage={tablePagination.currentPage}
                  updateCurrentPage={page => updateTablePagination('currentPage', page)}
                  totalPage={mainTblTotal.totalPage}
                />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default ScopeConfig;
