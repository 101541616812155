import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import { useCallback } from 'react';
import CustomRadio from '../../components/CustomRadio';
import { ICreationDataPropsType } from '../type';

interface IDomainListRuleProps {
  creationData: ICreationDataPropsType;
  updateCreationData: (data: ICreationDataPropsType) => void;
}

const DomainListRule = ({ creationData, updateCreationData }: IDomainListRuleProps) => {
  const {
    cidrRanges,
    domainName,
    domainListAction,
    domainListProtocol,
    domainListRuleCIDRRanges,
    configureRulesError: { domainNameError, domainCIDRError, domainProtocolsError },
  } = creationData;
  const onChangeProtocol = useCallback(
    (type: 'HTTP_HOST' | 'TLS_SNI') => {
      const newProtocolList = domainListProtocol.filter(item => item !== type);
      if (!domainListProtocol.includes(type)) {
        newProtocolList.push(type);
      }
      updateCreationData({
        ...creationData,
        domainListProtocol: newProtocolList,
      });
    },
    [creationData, domainListProtocol, updateCreationData],
  );
  const onChangeValue = useCallback(
    (name: string, value: string) => {
      updateCreationData({ ...creationData, [name]: value });
    },
    [creationData, updateCreationData],
  );

  return (
    <div className="rule-group-details-container">
      <div className="rule-group-detail-title">
        Domain list rule
        <div className="rule-group-detail-sub-title">Allow or deny traffic based on the domain name list.</div>
      </div>
      <div
        className="rule-group-detail-title"
        style={{
          borderBottom: 'none',
          padding: '8px 8px 0px 16px',
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        Domain names
        <div className="rule-group-detail-sub-title">
          Use the domain names you want to inspect and either allow or deny.
        </div>
      </div>
      <div className="rule-group-detail-content" style={{ gap: 16, padding: '8px 16px' }}>
        <textarea
          placeholder="Enter domain name spruce (ie. Example.com)"
          value={domainName}
          onChange={event => onChangeValue('domainName', event.target.value)}
        />
      </div>
      <div
        className="rule-group-detail-title"
        style={{
          borderBottom: 'none',
          padding: '0px 8px 8px 16px',
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <div className="rule-group-detail-sub-title">Enter one domain name per line</div>
      </div>
      <div
        className="rule-group-detail-title"
        style={{
          borderBottom: 'none',
          padding: '8px 8px 0px 16px',
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        CIDR ranges
        <div className="rule-group-detail-sub-title">The source traffic CIDE names to inspect.</div>
      </div>
      <div className="rule-group-type-content">
        <div className="type-content-row">
          <CustomRadio
            checked={cidrRanges === 'default'}
            label="Default"
            description="Use the CIDR range of the VPC where Network Firewall is deployed."
            onClick={() => onChangeValue('cidrRanges', 'default')}
          />
        </div>
        <div className="type-content-row">
          <CustomRadio
            checked={cidrRanges === 'custom'}
            label="Custom"
            description="Set your own list of CIDR ranges."
            onClick={() => onChangeValue('cidrRanges', 'custom')}
          />
        </div>
      </div>
      {cidrRanges === 'custom' && (
        <>
          <div
            className="rule-group-detail-title"
            style={{
              borderBottom: 'none',
              padding: '8px 8px 0px 16px',
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            }}
          >
            CIDR ranges
            <div className="rule-group-detail-sub-title">
              List the CIDR ranges of the source IPs you want to take action on.
            </div>
          </div>
          <div className="rule-group-detail-content" style={{ gap: 16, padding: '8px 16px' }}>
            <textarea
              value={domainListRuleCIDRRanges}
              placeholder="10.0.0.1/16"
              onChange={event => onChangeValue('domainListRuleCIDRRanges', event.target.value)}
            />
          </div>
          <div
            className="rule-group-detail-title"
            style={{
              borderBottom: 'none',
              padding: '0px 8px 8px 16px',
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            }}
          >
            <div className="rule-group-detail-sub-title">Enter one CIDR range per line.</div>
          </div>
        </>
      )}
      <>
        <div
          className="rule-group-detail-title"
          style={{
            borderBottom: 'none',
            padding: '8px 8px 0px 16px',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          Protocols
          <div className="rule-group-detail-sub-title">The protocols to inspect.</div>
        </div>
        <div className="rule-group-detail-content" style={{ gap: 16 }}>
          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <CheckboxAtom checked={domainListProtocol.includes('HTTP_HOST')} onchange={() => onChangeProtocol('HTTP_HOST')} />
            <p className="text-content">HTTP</p>
          </div>
          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <CheckboxAtom checked={domainListProtocol.includes('TLS_SNI')} onchange={() => onChangeProtocol('TLS_SNI')} />
            <p className="text-content">HTTPS</p>
          </div>
        </div>
        <div
          className="rule-group-detail-title"
          style={{
            borderBottom: 'none',
            padding: '8px 8px 0px 16px',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          Action
          <div className="rule-group-detail-sub-title">
            Action to take when request matches the domain names in the group.
          </div>
        </div>
        <div className="rule-group-detail-content" style={{ gap: 16 }}>
          <div className="rule-group-type-content" style={{ padding: 0 }}>
            <div className="type-content-row">
              <CustomRadio
                checked={domainListAction === 'ALLOWLIST'}
                label="Allow"
                onClick={() => onChangeValue('domainListAction', 'ALLOWLIST')}
              />
            </div>
          </div>
          <div className="rule-group-type-content" style={{ padding: 0 }}>
            <div className="type-content-row">
              <CustomRadio
                checked={domainListAction === 'DENYLIST'}
                label="Deny"
                onClick={() => onChangeValue('domainListAction', 'DENYLIST')}
              />
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default DomainListRule;
