import './index.scss';
import { useEffect, useState } from 'react';
import DetailItem, { IMgdDetailKeyValueProps } from '../../DetailItem';
import Icon from 'components/v2/atoms/Icon';
import CloseIcon from 'assets/svgs/v2/ico_close.svg';
import lazyGetAwsFindingStatistics, {
  IGetAwsFindingStatisticsVariables,
} from 'graphql/queries/getAwsFindingStatistics';
import { TextTypeEnum } from 'layouts/v3/MgdLayout';
import { FilterCriteria, FilterValueType } from '../../SearchBar/types';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { FINDINGS_TAB, MACIE_TAB } from '../../../configs';
import { handleLinkClicked } from '../../../Function';
type SeverityType = {
  high: number;
  medium: number;
  low: number;
};
const FindingsByTypeSummary = ({ cloudId, region, data: dataItem, setData: setDataItem }: any) => {
  const [getAwsFindingStatistics] = lazyGetAwsFindingStatistics();

  const [findingsBySeverity, setFindingsBySeverity] = useState<SeverityType>({
    high: 0,
    medium: 0,
    low: 0,
  });
  const [findingsByBucket, setFindingsByBucket] = useState<any[]>([]);
  const [findingsByJob, setFindingsByJob] = useState<any[]>([]);
  const navigate = useNavigate();

  const getParamForSearch = (items: FilterCriteria) => {
    const data = {
      navigate,
      link: '/organ/1/manage/macie',
      type: ManagementTypeEnum.MACIE,
      tabId: MACIE_TAB[0].id,
      key: FINDINGS_TAB[0].id.toString(),
      value: [
        {
          name: 'type',
          display: 'Finding type',
          type: FilterValueType.VALUE_TYPE,
          value: dataItem?.groupKey,
        },
        { ...items },
      ],
    };

    return data;
  };

  const fetchFindingStatistic = async (groupBy: string, s3BucketEq: string) => {
    if (!region) return;
    try {
      const variables: IGetAwsFindingStatisticsVariables = {
        cloudId,
        region,
        reqData: {
          groupBy,
          findingCriteria: {
            criterion: JSON.stringify({
              archived: { eq: [false] },
              type: { eq: [s3BucketEq] },
            }),
          },
        },
      };
      const { data: findingStatistics } = await getAwsFindingStatistics({ variables });

      return findingStatistics;
    } catch (error) {}
  };

  const findingStatisticSeverity = async () => {
    const result = await fetchFindingStatistic('severity.description', dataItem?.groupKey);
    const countsByGroup = result?.getAwsFindingStatistics?.data[0].countsByGroup;
    const severity: any = {};
    countsByGroup?.forEach(item => {
      const keyName = item?.groupKey?.toLowerCase();
      severity[keyName] = item.count;
    });
    setFindingsBySeverity({ ...findingsBySeverity, ...severity });
  };

  const findingStatisticBucket = async () => {
    const result = await fetchFindingStatistic('resourcesAffected.s3Bucket.name', dataItem?.groupKey);
    const countsByGroup = result?.getAwsFindingStatistics?.data[0].countsByGroup;
    const dataFormat =
      countsByGroup?.map(item => ({
        title: item.groupKey,
        description: item.count,
        type: TextTypeEnum.LINK,
        handleItemClick: (value: string) => {
          handleLinkClicked(
            getParamForSearch({
              name: 'resourcesAffected.s3Bucket.name',
              display: 'S3 bucket name',
              type: FilterValueType.VALUE_TYPE,
              value: item.groupKey,
            }),
          );
        },
      })) || [];
    setFindingsByBucket([...dataFormat]);
  };

  const findingStatisticJob = async () => {
    const result = await fetchFindingStatistic('classificationDetails.jobId', dataItem?.groupKey);
    const countsByGroup = result?.getAwsFindingStatistics?.data[0].countsByGroup;
    const dataFormat =
      countsByGroup?.map(item => ({
        title: item.groupKey,
        description: item.count,
        type: TextTypeEnum.LINK,
        handleItemClick: (value: string) => {
          handleLinkClicked(
            getParamForSearch({
              name: 'classificationDetails.jobId',
              display: 'Job ID',
              type: FilterValueType.VALUE_TYPE,
              value: item.groupKey,
            }),
          );
        },
      })) || [];
    setFindingsByJob([...dataFormat]);
  };

  useEffect(() => {
    if (!dataItem?.id) return;

    findingStatisticSeverity();
    findingStatisticBucket();
    findingStatisticJob();
  }, [dataItem?.id]);

  const findingsBySeverityDetail: IMgdDetailKeyValueProps[] = [
    {
      title: 'High',
      color: 'red',
      type: TextTypeEnum.LINK,
      description: findingsBySeverity.high,
      isChipTitle: true,
      handleItemClick: (value: string) => {
        handleLinkClicked(
          getParamForSearch({
            name: 'severity.description',
            display: 'severity',
            type: FilterValueType.VALUE_TYPE,
            value: 'High',
          }),
        );
      },
    },
    {
      title: 'Medium',
      color: 'yellow',
      isChipTitle: true,
      type: TextTypeEnum.LINK,
      description: findingsBySeverity.medium,
      handleItemClick: (value: string) => {
        handleLinkClicked(
          getParamForSearch({
            name: 'severity.description',
            display: 'severity',
            type: FilterValueType.VALUE_TYPE,
            value: 'Medium',
          }),
        );
      },
    },
    {
      title: 'Low',
      color: 'green',
      isChipTitle: true,
      type: TextTypeEnum.LINK,
      description: findingsBySeverity.low,
      handleItemClick: (value: string) => {
        handleLinkClicked(
          getParamForSearch({
            name: 'severity.description',
            display: 'severity',
            type: FilterValueType.VALUE_TYPE,
            value: 'Low',
          }),
        );
      },
    },
  ];

  return dataItem?.id ? (
    <div className="box-summary-horizontal">
      <div className="box-summary-info">
        <div className="box-summary-info-title">
          <span>type: {dataItem?.groupKey}</span>
          <Icon className="suffix-btn" onClick={() => setDataItem(undefined)} width={10} height={10} src={CloseIcon} />
        </div>
      </div>

      <DetailItem title="Findings by severity" data={findingsBySeverityDetail} findingStyle="justify-between" />

      <DetailItem title="Findings by bucket" data={findingsByBucket} findingStyle="justify-between" />

      <DetailItem title="Findings by job" data={findingsByJob} findingStyle="justify-between" />
    </div>
  ) : null;
};

export default FindingsByTypeSummary;
