import Icon from 'components/v2/atoms/Icon';
import BackIcon from 'assets/svgs/v3/ico_back.svg';
import FavoriteIcon from 'assets/svgs/v3/ico_favorite.svg';
import UnfavoriteIcon from 'assets/svgs/v3/ico_un_favorite.svg';
import { PageTitlePropsType } from '../types';
import './index.scss';

const PageTitle = (props: PageTitlePropsType) => {
  const {
    title,
    hasBackButton,
    hasFavoriteIcon,
    hasPrefixIcon,
    onBackButtonClicked,
    prefixIcon,
    onFavoriteButtonClicked,
    isFavorite,
  } = props;

  return (
    <div className='page-title-container'>
      {hasBackButton && (
        <Icon className="back-button-container" width={12} height={12} src={BackIcon} onClick={onBackButtonClicked} />
      )}
      {hasPrefixIcon && prefixIcon && <Icon width={16} height={16} src={prefixIcon} />}
      <p className='page-title-text'>{title}</p>
      {hasFavoriteIcon && (
        <Icon
          onClick={onFavoriteButtonClicked}
          width={16}
          height={16}
          src={!isFavorite ? UnfavoriteIcon : FavoriteIcon}
        />
      )}
    </div>
  );
};

export default PageTitle;
