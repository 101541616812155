import classNames from 'classnames';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import _ from 'lodash';
import moment from 'moment';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DateType,
  FilterCriteria,
  FilterValueType,
  FromToType,
  MultiSelectType,
  SearchValueGeneralType,
  ValueType,
} from '../../types';
import MacieSearchBarCalendarPopupInput from './CalendarPopup';
import './index.scss';

interface IMacieSearchDropdownPropType {
  id: string;
  data: FilterCriteria[];
  onFilterApply: (filterData: FilterCriteria) => any;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  resetDataSignal: boolean;
}

const MacieSearchBarDropdown = ({
  id,
  data,
  onFilterApply,
  className,
  placeholder,
  disabled = false,
  resetDataSignal,
}: IMacieSearchDropdownPropType) => {
  const listRef = useRef<null | HTMLDivElement>(null);

  const [bg, setBg] = useState({
    width: 0,
    top: 0,
    left: 0,
  });

  const [selectedFilterCriteria, setSelectedFilterCriteria] = useState<FilterCriteria | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState<SearchValueGeneralType | null>(null);

  useEffect(() => {
    const closeDropdownOnClickOutside = (e: MouseEvent) => {
      if (e.target instanceof HTMLElement) {
        if (!e.target.closest(`#${id}-wrap`)) {
          hideDropdown();
        }
      }
    };
    document.addEventListener('click', closeDropdownOnClickOutside, { capture: true });
    return () => {
      document.removeEventListener('click', closeDropdownOnClickOutside);
    };
  }, []);

  useEffect(() => {
    resetData();
  }, [resetDataSignal]);

  const hideDropdown = useCallback(() => {
    !listRef.current?.classList.contains('none') && listRef.current?.classList.add('none');
  }, []);

  const onApplyButtonClick = useCallback(() => {
    if (!selectedFilterCriteria || !filterValue) {
      return;
    }
    let filterValueToCheck = filterValue;
    switch (selectedFilterCriteria.type) {
      case FilterValueType.VALUE_TYPE:
        filterValueToCheck = filterValueToCheck as ValueType;
        if (filterValueToCheck.trim().length === 0) {
          return;
        }
        break;
      case FilterValueType.MULTI_SELECT_TYPE:
        filterValueToCheck = filterValue as MultiSelectType;
        if (filterValueToCheck.length === 0) {
          return;
        }
        break;
      case FilterValueType.FROM_TO_TYPE:
        filterValueToCheck = filterValue as FromToType;
        if (!filterValueToCheck.lte && !filterValueToCheck.gte) {
          return;
        }
        break;
      case FilterValueType.DATE_TYPE:
        filterValueToCheck = filterValue as DateType;
        if (!filterValueToCheck.lte?.date && !filterValueToCheck.gte?.date) {
          return;
        }
        break;
    }
    onFilterApply({
      ...selectedFilterCriteria,
      value: filterValueToCheck,
    });
    resetData();
    hideDropdown();
  }, [selectedFilterCriteria, searchValue, filterValue]);

  const setSearchAndFilterValueBaseOnFilterCriteria = useCallback((filterCriteria: FilterCriteria | null) => {
    if (!filterCriteria) {
      setSearchValue('');
      setFilterValue(null);
      return;
    }
    setSearchValue(`${filterCriteria.display}:`);
    switch (filterCriteria.type) {
      case FilterValueType.VALUE_TYPE:
        setFilterValue('');
        break;
      case FilterValueType.MULTI_SELECT_TYPE:
        setFilterValue([]);
        break;
      case FilterValueType.FROM_TO_TYPE:
      case FilterValueType.DATE_TYPE:
        setFilterValue({
          gte: undefined,
          lte: undefined,
        });
    }
  }, []);

  const resetData = useCallback(() => {
    setSelectedFilterCriteria(null);
    setSearchAndFilterValueBaseOnFilterCriteria(null);
  }, []);

  const openMenu = useCallback((parentId: string) => {
    Array.from(document.getElementsByClassName('macie-search-bar-dropdown-list')).forEach(el => {
      const parentEl = el.parentElement;
      if (parentEl?.id === parentId) {
        if (el.classList.contains('none')) {
          el.classList.remove('none');
        }
      }
    });
  }, []);

  const dropdownContent = useMemo(() => {
    const lowerCaseSearchValue = searchValue.toLowerCase();
    if (!selectedFilterCriteria) {
      const matchingFilterCriteria = data.find(filterCriteria => {
        const filterCriteriaDisplayRegexp = new RegExp(`\\s*${filterCriteria.display.trim().toLowerCase()}\\s*:.*`);
        return lowerCaseSearchValue.match(filterCriteriaDisplayRegexp);
      });

      if (matchingFilterCriteria) {
        setSelectedFilterCriteria(matchingFilterCriteria);
        setSearchAndFilterValueBaseOnFilterCriteria(matchingFilterCriteria);
        return <></>;
      }

      const filteredFilterCriteria = data.filter(filterCriteria =>
        filterCriteria.display.toLowerCase().includes(lowerCaseSearchValue.trim()),
      );
      return (
        <div className="list-container">
          <ul>
            {filteredFilterCriteria.length ? (
              filteredFilterCriteria.map((d, idx) => (
                <li
                  key={`${idx}-${d.value}`}
                  className="list-item"
                  onClick={e => {
                    e.stopPropagation();
                    setSearchAndFilterValueBaseOnFilterCriteria(d);
                    setSelectedFilterCriteria(d);
                  }}
                >
                  {d.display}
                </li>
              ))
            ) : (
              <li className="list-item">No filter options found</li>
            )}
          </ul>
        </div>
      );
    }

    const selectedFilterRegexp = new RegExp(`\\s*${selectedFilterCriteria.display.trim().toLowerCase()}\\s*:.*`);
    if (!lowerCaseSearchValue.match(selectedFilterRegexp)) {
      const currentSearchValue = searchValue;
      setSelectedFilterCriteria(null);
      setSearchAndFilterValueBaseOnFilterCriteria(null);
      setSearchValue(currentSearchValue);
      return <></>;
    }

    switch (selectedFilterCriteria.type) {
      case FilterValueType.VALUE_TYPE:
        return (
          <>
            <div className="value-type-container">
              <InputAtom
                placeholder="Input item value"
                value={(filterValue as ValueType) || ''}
                onChangeValue={value => {
                  setFilterValue(value);
                }}
                noClear={true}
              />
            </div>
          </>
        );
      case FilterValueType.MULTI_SELECT_TYPE:
        return (
          <>
            <div className="multi-select-container">
              {selectedFilterCriteria.availableOption?.map(each => (
                <CheckboxAtom
                  id={each.value}
                  key={each.value}
                  label={each.display}
                  checked={!!(filterValue as MultiSelectType)?.find(value => value.display === each.display)}
                  onchange={() => {
                    if (!filterValue || !(filterValue instanceof Array)) {
                      return;
                    }
                    const filterValueAsMultiSelect = filterValue;
                    const index = filterValueAsMultiSelect.findIndex(value => value.display === each.display);
                    if (index >= 0) {
                      setFilterValue(filterValueAsMultiSelect.filter(value => value.display !== each.display));
                    } else {
                      setFilterValue([...filterValueAsMultiSelect, each]);
                    }
                  }}
                />
              ))}
            </div>
          </>
        );
      case FilterValueType.FROM_TO_TYPE:
        return (
          <>
            <div className="from-to-value-container">
              <div className="number-range">
                <div className="control-label">From</div>
                <InputAtom
                  type="number"
                  value={String((filterValue as FromToType)?.gte || '')}
                  onChangeValue={value => {
                    const filterValueAsFromToType = filterValue as FromToType;
                    setFilterValue({
                      ...filterValueAsFromToType,
                      gte: +value,
                    });
                  }}
                  noClear={true}
                />
              </div>
              <div className="number-range">
                <div className="control-label">To</div>
                <InputAtom
                  type="number"
                  value={String((filterValue as FromToType)?.lte || '')}
                  onChangeValue={value => {
                    const filterValueAsFromToType = filterValue as FromToType;
                    setFilterValue({
                      ...filterValueAsFromToType,
                      lte: +value,
                    });
                  }}
                  noClear={true}
                />
              </div>
            </div>
          </>
        );
      case FilterValueType.DATE_TYPE:
        return (
          <>
            <div className="date-value-container">
              <div className="date-range">
                <div className="date-group">
                  <div className="control-label">From</div>
                  <MacieSearchBarCalendarPopupInput
                    id={'filter-date-from'}
                    dateValue={(filterValue as DateType)?.gte?.date}
                    placeholder="YYYY-MM-DD"
                    onChangeDate={value => {
                      const filterValueAsDateType = _.cloneDeep(filterValue as DateType);
                      filterValueAsDateType.gte = {
                        ...filterValueAsDateType.gte,
                        date: value,
                      };
                      setFilterValue(filterValueAsDateType);
                    }}
                    maxDate={(filterValue as DateType)?.lte?.date}
                  />
                </div>

                <div className="time-group">
                  <div className="control-label hidden">Time</div>
                  <input
                    type="text"
                    placeholder="HH:MM:SS"
                    value={(filterValue as DateType)?.gte?.time || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      const inputValue = e?.target?.value;
                      if (!inputValue) {
                        return;
                      }
                      const time = moment(inputValue, 'HH:mm:ss');
                      if (time.isValid()) {
                        const filterValueAsDateType = _.cloneDeep(filterValue as DateType);
                        filterValueAsDateType.gte = {
                          ...filterValueAsDateType.gte,
                          time: inputValue,
                        };
                        setFilterValue(filterValueAsDateType);
                      }
                    }}
                    onBlur={() => {
                      const value = (filterValue as DateType)?.gte?.time;
                      const time = moment(value, 'HH:mm:ss');
                      if (!time.isValid()) {
                        return;
                      }

                      const filterValueAsDateType = _.cloneDeep(filterValue as DateType);
                      filterValueAsDateType.gte = {
                        ...filterValueAsDateType.gte,
                        time: time.format('HH:mm:ss'),
                      };
                      setFilterValue(filterValueAsDateType);
                    }}
                  />
                </div>
              </div>
              <div className="date-range">
                <div className="date-group">
                  <div className="control-label">To</div>
                  <MacieSearchBarCalendarPopupInput
                    id={'filter-date-to'}
                    dateValue={(filterValue as DateType)?.lte?.date}
                    placeholder="YYYY-MM-DD"
                    onChangeDate={value => {
                      const filterValueAsDateType = _.cloneDeep(filterValue as DateType);
                      filterValueAsDateType.lte = {
                        ...filterValueAsDateType.lte,
                        date: value,
                      };
                      setFilterValue(filterValueAsDateType);
                    }}
                    minDate={(filterValue as DateType)?.gte?.date}
                  />
                </div>

                <div className="time-group">
                  <div className="control-label hidden">Time</div>
                  <input
                    type="text"
                    placeholder="HH:MM:SS"
                    value={(filterValue as DateType)?.lte?.time || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      const inputValue = e?.target?.value;
                      if (!inputValue) {
                        return;
                      }
                      const time = moment(inputValue, 'HH:mm:ss');
                      if (time.isValid()) {
                        const filterValueAsDateType = _.cloneDeep(filterValue as DateType);
                        filterValueAsDateType.lte = {
                          ...filterValueAsDateType.lte,
                          time: inputValue,
                        };
                        setFilterValue(filterValueAsDateType);
                      }
                    }}
                    onBlur={() => {
                      const value = (filterValue as DateType)?.lte?.time;
                      const time = moment(value, 'HH:mm:ss');
                      if (!time.isValid()) {
                        return;
                      }

                      const filterValueAsDateType = _.cloneDeep(filterValue as DateType);
                      filterValueAsDateType.lte = {
                        ...filterValueAsDateType.lte,
                        time: time.format('HH:mm:ss'),
                      };
                      setFilterValue(filterValueAsDateType);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        );
      default:
        return <></>;
    }
  }, [data, selectedFilterCriteria, searchValue, filterValue]);

  const dropdownFooter = useMemo(() => {
    return (
      <div className={classNames('dropdown-footer-container', selectedFilterCriteria ? 'visible' : 'hidden')}>
        <Button label={'Back'} type={ButtonTypeEnum.GENERAL} onClick={resetData} />
        <Button label={'Apply'} type={ButtonTypeEnum.PRIMARY} onClick={onApplyButtonClick} />
      </div>
    );
  }, [selectedFilterCriteria, searchValue, filterValue, resetData, onFilterApply]);

  return (
    <div className={classNames('macie-search-dropdown-atom', className)} id={`${id}-wrap`}>
      <input
        id={id}
        tabIndex={-1}
        value={searchValue}
        autoComplete="off"
        onChange={e => {
          setSearchValue(e.target.value);
        }}
        disabled={disabled}
        placeholder={placeholder}
        onClick={e => {
          e.stopPropagation();
          openMenu(`${id}-wrap`);
          const dropdownAtomEl = document.getElementById(`${id}-wrap`);
          const listWrapEl = document.getElementById(`list-wrap ${id}`);

          if (!dropdownAtomEl || !listWrapEl) return;
          if (
            window.innerHeight - e.currentTarget.getBoundingClientRect().bottom >
            dropdownAtomEl.offsetHeight + listWrapEl.offsetHeight + 5
          ) {
            setBg({
              width: e.currentTarget.offsetWidth,
              top: e.currentTarget.getBoundingClientRect().bottom + 5,
              left: e.currentTarget.getBoundingClientRect().left,
            });
          } else {
            setBg({
              width: e.currentTarget.offsetWidth,
              top:
                e.currentTarget.getBoundingClientRect().bottom -
                (dropdownAtomEl.offsetHeight + listWrapEl.offsetHeight + 5),
              left: e.currentTarget.getBoundingClientRect().left,
            });
          }
        }}
      />
      {!disabled && (
        <div className="macie-search-bar-dropdown-list none" ref={listRef}>
          <div
            id={`list-wrap ${id}`}
            className="list-wrapper"
            style={{
              top: bg.top,
              left: bg.left,
              width: bg.width,
            }}
          >
            <div className="dropdown-content-container">{dropdownContent}</div>
            {dropdownFooter}
          </div>
        </div>
      )}
    </div>
  );
};
export default MacieSearchBarDropdown;
