import './index.scss';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import MgdTabLayout from 'layouts/v3/MgdTabLayout';
import { MgdTabLayoutTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import FindingsBucket from './components/Findings/ListUp/Bucket';
import FindingsItems from './components/Findings/ListUp/Items';
import FindingsJob from './components/Findings/ListUp/Job';
import FindingsType from './components/Findings/ListUp/Type';
import FindingsBucketSummary from './components/Findings/Summary/Bucket';
import FindingsItemSummary from './components/Findings/Summary/Item';
import ResourceCoverageAccessDenied from './components/ResourceCoverage/ListUp/AccessDenied';
import ResourceCoverageAll from './components/ResourceCoverage/ListUp/All';
import ResourceCoverageClassificationError from './components/ResourceCoverage/ListUp/ClassificationError';
import ResourceCoverageUnclassifiable from './components/ResourceCoverage/ListUp/Unclassifiable';
import ResourceCoverageSummary from './components/ResourceCoverage/Summary/ResourceCoverageSummary';
import SettingAllowList from './components/Setting/AllowList';
import CreateMacieSettingAllowList from './components/Setting/AllowList/components/CreateMacieSettingAllowList';
import UpdateAllowList from './components/Setting/AllowList/components/UpdateAllowList';
import { FINDINGS_TAB, MACIE_TAB, PageNodeEnum, RESOURCE_COVERAGE_TAB, SETTING_TAB } from './configs';
import { IAwsFinding, IAwsResourceCoverage, MaciePropsType } from './types';
import lazyGetAwsListAutomatedDiscoveryAccounts, {
  StatusAutoMatedDiscovery,
} from 'graphql/queries/getAwsListAutomatedDiscoveryAccounts';
import FindingsByTypeSummary from './components/Findings/Summary/ByType';
import FindingsByJobSummary from './components/Findings/Summary/ByJob';
import CustomIdentifier from './components/Setting/CustomIdentifier';
import CreateCustomDataIdentifier from './components/Setting/CustomIdentifier/CreateCustomDataIdentifier';
import S3BucketListUp from './components/S3Bucket/ListUp';

const Macie = (props: MaciePropsType) => {
  const {
    onBackButtonClicked,
    projectId,
    cloudName,
    regionCode,
    regions,
    recentRelatedClouds,
    relatedCloudSelected,
    recentRelatedCloudOnChange,
    currentRouteState,
    setCurrentRouteState,
  } = props;

  // Context
  const { defaultRegionList, updateDefaultRegionList } = useOutletContext<{
    defaultRegionList: { projectId: number; cloudId: number; name: string; value: string }[];
    updateDefaultRegionList: (projectId: number, cloudId: number, name: string, value: string) => void;
  }>();

  // API
  const [getEnumTypeCode] = lazyGetEnumTypeCode();
  const [getAutomatedDiscoveryConfig] = lazyGetAwsListAutomatedDiscoveryAccounts();

  // State
  const [pageType, setPageType] = useState<PageNodeEnum>(PageNodeEnum.MGD_LIST);
  const [currentRegion, setCurrentRegion] = useState<DropdownListDataType>({ name: '', value: '' });
  const [regionList, setRegionList] = useState<DropdownListDataType[]>([]);
  const [mainTabSelected, setMainTabSelected] = useState<IMgdTabProps>(MACIE_TAB[0]);
  const [tabSelected, setTabSelected] = useState<IMgdTabProps | null>(null);

  const [selectedFinding, setSelectedFinding] = useState<IAwsFinding | null>(null);
  const [selectedResourceCoverage, setSelectedResourceCoverage] = useState<IAwsResourceCoverage | null>(null);
  const [automatedDis, setAutomatedDis] = useState<StatusAutoMatedDiscovery | null>(null);
  console.log('🚀 ~ Macie ~ selectedResourceCoverage:', selectedResourceCoverage);
  const [allowListId, setAllowListId] = useState<string | null>(null);

  useEffect(() => {
    if (!currentRouteState) return;

    const currentTab = MACIE_TAB.find(e => e.id === currentRouteState.tabId);
    const currentTabChild = FINDINGS_TAB.find(e => e.id === currentRouteState.key);
    if (currentTab) setMainTabSelected(currentTab);
    if (currentTabChild) setTabSelected(currentTabChild);
  }, [currentRouteState]);

  const handleClearState = () => {
    setSelectedFinding(null);
    setSelectedResourceCoverage(null);
    setPageType(PageNodeEnum.MGD_LIST);
  };

  const handleSetTabItem = () => {
    setTabSelected(null);
    switch (mainTabSelected) {
      case MACIE_TAB[0]:
        setTabSelected(FINDINGS_TAB[0]);
        break;

      case MACIE_TAB[1]:
        setTabSelected(RESOURCE_COVERAGE_TAB[0]);
        break;

      case MACIE_TAB[2]:
        setTabSelected(null);
        break;

      case MACIE_TAB[4]:
        setTabSelected(SETTING_TAB[0]);
        break;

      default:
        break;
    }
  };

  const getAutomatedDiscoveryConfiguration = async () => {
    const { data } = await getAutomatedDiscoveryConfig({ variables: { cloudId, region: regionValue, request: {} } });
    if (!data?.getAwsAutomatedDiscoveryConfiguration?.data?.length) return;
    setAutomatedDis(data.getAwsAutomatedDiscoveryConfiguration.data[0].status);
  };

  useEffect(() => {
    handleClearState();
    handleSetTabItem();
    switch (mainTabSelected) {
      case MACIE_TAB[1]:
        switch (tabSelected) {
          case FINDINGS_TAB[0]:
            getAutomatedDiscoveryConfiguration();
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }, [mainTabSelected]);

  useEffect(() => {
    handleClearState();
  }, [tabSelected]);

  const cloudId = useMemo((): number => {
    return Number(relatedCloudSelected.value);
  }, [relatedCloudSelected]);

  const regionValue = useMemo((): string => {
    return currentRegion?.value?.toString();
  }, [currentRegion]);

  const getInitialRegion = useCallback(() => {
    const hasDefaultRegion = defaultRegionList.find(r => {
      return r.projectId === projectId && r.cloudId === cloudId;
    });

    if (!!hasDefaultRegion) {
      setCurrentRegion({
        name: hasDefaultRegion.name,
        value: hasDefaultRegion.value,
      });
    } else {
      getEnumTypeCode({
        variables: { text: regionCode || 'AwsRegionCode' },
      }).then(res => {
        if (res.data) {
          const regionsRes: DropdownListDataType[] = res.data.getEnumTypeCode.data
            .filter(val => regions.includes(val.value))
            .map(val => ({
              ...val,
              name: val.value,
            }));
          setRegionList(regionsRes);
          const region = regionsRes.find(r => r.name === currentRegion.name && r.value === currentRegion.value);
          setCurrentRegion(region || regionsRes[0]);
        }
      });
    }
  }, [cloudId, defaultRegionList]);

  useEffect(() => {
    getInitialRegion();
  }, [getInitialRegion]);

  const resetMainStates = useCallback(() => {
    if (!currentRouteState) return;

    setCurrentRouteState(null);
  }, [currentRouteState]);

  const mainTabClickHandler = useCallback(
    (tabSelected: IMgdTabProps) => {
      setMainTabSelected(MACIE_TAB.find(tabItem => tabItem.id === tabSelected.id) || MACIE_TAB[0]);
      resetMainStates();
    },
    [setMainTabSelected, resetMainStates],
  );

  const pinButtonClickHandle = useCallback(() => {
    updateDefaultRegionList(
      projectId,
      Number(relatedCloudSelected.value),
      currentRegion.name || '',
      currentRegion.value.toString(),
    );
  }, [relatedCloudSelected, currentRegion, projectId]);

  const onPageBackClick = useCallback(() => {
    handleClearState();
  }, []);

  const tabContentNode = useMemo(() => {
    if (!currentRegion?.value || !cloudId) return;

    switch (mainTabSelected) {
      case MACIE_TAB[0]:
        switch (tabSelected) {
          case FINDINGS_TAB[0]:
            return (
              <FindingsItems
                cloudId={cloudId}
                region={regionValue}
                currentRegion={currentRegion}
                currentRouteState={currentRouteState}
                setCurrentRouteState={setCurrentRouteState}
                relatedCloudSelected={relatedCloudSelected}
                setDetailItem={setSelectedFinding}
                setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
                onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
              />
            );

          case FINDINGS_TAB[1]:
            return (
              <FindingsBucket
                cloudId={cloudId}
                region={regionValue}
                currentRegion={currentRegion}
                currentRouteState={currentRouteState}
                setCurrentRouteState={setCurrentRouteState}
                relatedCloudSelected={relatedCloudSelected}
                setDetailItem={setSelectedFinding}
                setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
                onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
              />
            );

          case FINDINGS_TAB[2]:
            return (
              <FindingsType
                cloudId={cloudId}
                region={regionValue}
                currentRegion={currentRegion}
                currentRouteState={currentRouteState}
                setCurrentRouteState={setCurrentRouteState}
                relatedCloudSelected={relatedCloudSelected}
                setDetailItem={setSelectedFinding}
                setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
                onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
              />
            );

          case FINDINGS_TAB[3]:
            return (
              <FindingsJob
                cloudId={cloudId}
                region={regionValue}
                currentRegion={currentRegion}
                currentRouteState={currentRouteState}
                setCurrentRouteState={setCurrentRouteState}
                relatedCloudSelected={relatedCloudSelected}
                setDetailItem={setSelectedFinding}
                setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
                onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
              />
            );

          default:
            return null;
        }
      case MACIE_TAB[1]: {
        if (!automatedDis) return null;
        if (automatedDis === StatusAutoMatedDiscovery.DISABLED) {
          return (
            <div className="row-3 flex j-between a-center">
              <div className="title">
                <p>Resource coverage</p>
                <p>You have to enable Automated discovery.</p>
              </div>
            </div>
          );
        }
        switch (tabSelected) {
          case RESOURCE_COVERAGE_TAB[0]:
            return (
              <ResourceCoverageAll
                cloudId={cloudId}
                region={regionValue}
                currentRegion={currentRegion}
                currentRouteState={currentRouteState}
                setCurrentRouteState={setCurrentRouteState}
                relatedCloudSelected={relatedCloudSelected}
                setDetailItem={setSelectedResourceCoverage}
                setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
                onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
              />
            );

          case RESOURCE_COVERAGE_TAB[1]:
            return (
              <ResourceCoverageAccessDenied
                cloudId={cloudId}
                region={regionValue}
                currentRegion={currentRegion}
                currentRouteState={currentRouteState}
                setCurrentRouteState={setCurrentRouteState}
                relatedCloudSelected={relatedCloudSelected}
                setDetailItem={setSelectedResourceCoverage}
                setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
                onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
              />
            );

          case RESOURCE_COVERAGE_TAB[2]:
            return (
              <ResourceCoverageClassificationError
                cloudId={cloudId}
                region={regionValue}
                currentRegion={currentRegion}
                currentRouteState={currentRouteState}
                setCurrentRouteState={setCurrentRouteState}
                relatedCloudSelected={relatedCloudSelected}
                setDetailItem={setSelectedResourceCoverage}
                setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
                onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
              />
            );

          case RESOURCE_COVERAGE_TAB[3]:
            return (
              <ResourceCoverageUnclassifiable
                cloudId={cloudId}
                region={regionValue}
                currentRegion={currentRegion}
                currentRouteState={currentRouteState}
                setCurrentRouteState={setCurrentRouteState}
                relatedCloudSelected={relatedCloudSelected}
                setDetailItem={setSelectedResourceCoverage}
                setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
                onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
              />
            );

          default:
            return null;
        }
      }
      case MACIE_TAB[2]:
        return (
          <S3BucketListUp
            cloudId={cloudId}
            region={regionValue}
            currentRegion={currentRegion}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            relatedCloudSelected={relatedCloudSelected}
            setDetailItem={() => {}}
            setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
            onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
          />
        );
      case MACIE_TAB[4]: {
        switch (tabSelected) {
          case SETTING_TAB[0]:
          case SETTING_TAB[1]:
            return (
              <SettingAllowList
                cloudId={cloudId}
                region={regionValue}
                allowListId={allowListId}
                onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
                onNavigateUpdation={() => setPageType(PageNodeEnum.MGD_DETAIL)}
              />
            );
          case SETTING_TAB[2]:
            return <CustomIdentifier onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)} />;
          default:
            return null;
        }
      }

      default:
        return null;
    }
  }, [cloudId, regionValue, currentRouteState, mainTabSelected, tabSelected, automatedDis]);

  const tabSummaryNode = useMemo(() => {
    switch (mainTabSelected) {
      case MACIE_TAB[0]:
        if (!selectedFinding) return null;

        switch (tabSelected) {
          case FINDINGS_TAB[0]:
            return (
              <FindingsItemSummary
                cloudId={cloudId}
                region={regionValue}
                data={selectedFinding}
                setData={setSelectedFinding}
              />
            );

          case FINDINGS_TAB[1]:
            return (
              <FindingsBucketSummary
                cloudId={cloudId}
                region={regionValue}
                data={selectedFinding}
                setData={setSelectedFinding}
              />
            );

          case FINDINGS_TAB[2]:
            return (
              <FindingsByTypeSummary
                cloudId={cloudId}
                region={regionValue}
                data={selectedFinding}
                setData={setSelectedFinding}
              />
            );

          case FINDINGS_TAB[3]:
            return (
              <FindingsByJobSummary
                cloudId={cloudId}
                region={regionValue}
                data={selectedFinding}
                setData={setSelectedFinding}
              />
            );
          default:
            return null;
        }
      case MACIE_TAB[1]:
        if (!selectedResourceCoverage) return null;

        switch (tabSelected) {
          case RESOURCE_COVERAGE_TAB[0]:
          case RESOURCE_COVERAGE_TAB[1]:
          case RESOURCE_COVERAGE_TAB[2]:
          case RESOURCE_COVERAGE_TAB[3]:
            return (
              <ResourceCoverageSummary
                cloudId={cloudId}
                region={regionValue}
                selectedResourceCoverage={selectedResourceCoverage}
                onClose={() => setSelectedResourceCoverage(null)}
              />
            );
        }
        break;
      default:
        return null;
    }
  }, [mainTabSelected, cloudId, currentRegion, projectId, tabSelected, selectedFinding, selectedResourceCoverage]);

  const renderTabNode = useMemo(() => {
    let tab: IMgdTabProps[] = [];
    switch (mainTabSelected) {
      case MACIE_TAB[0]:
        tab = FINDINGS_TAB;
        break;
      case MACIE_TAB[1]:
        if (!automatedDis) return null;
        if (automatedDis === StatusAutoMatedDiscovery.DISABLED) {
          tab = [];

          return;
        }
        tab = RESOURCE_COVERAGE_TAB;
        break;
      case MACIE_TAB[2]:
        tab = [];
        break;
      case MACIE_TAB[3]:
      case MACIE_TAB[4]:
        tab = SETTING_TAB;
        break;
      default:
        break;
    }

    if (!tab?.length) return null;

    return (
      <div className="detail-tab flex a-center">
        {tab.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>
    );
  }, [mainTabSelected, cloudId, currentRegion, projectId, tabSelected, automatedDis]);

  const listNode = useMemo((): ReactNode => {
    return (
      <MgdTabLayout
        layoutType={MgdTabLayoutTypeEnum.MGD_LIST_MORE_SUMMARY}
        pageTitle={cloudName}
        hasFavorite={true}
        onBackButtonClicked={onBackButtonClicked}
        headerDropList={regionList}
        headerDropValue={currentRegion}
        headerDropOnchange={val => {
          setCurrentRegion(() => ({
            name: val.name ? val.name : '',
            value: String(val.value),
          }));
        }}
        isPined={
          !!defaultRegionList.find(r => {
            return r.projectId === projectId && r.cloudId === relatedCloudSelected.value;
          })
        }
        pinBtnActHandle={pinButtonClickHandle}
        pinBtnDisabled={false}
        mainTabList={MACIE_TAB}
        mainTabItemClick={mainTabClickHandler}
        mainTitle={mainTabSelected?.title || ''}
        mainTabSelected={mainTabSelected}
        tabContentNode={tabContentNode}
        tabSummaryNode={tabSummaryNode}
        renderTabNode={renderTabNode}
        recentRelatedClouds={recentRelatedClouds}
        relatedCloudSelected={relatedCloudSelected}
        recentRelatedCloudOnChange={relatedCloud => recentRelatedCloudOnChange(relatedCloud)}
      />
    );
  }, [
    cloudName,
    onBackButtonClicked,
    regionList,
    currentRegion,
    defaultRegionList,
    projectId,
    relatedCloudSelected,
    pinButtonClickHandle,
    mainTabClickHandler,
    mainTabSelected,
    tabContentNode,
    tabSummaryNode,
    recentRelatedClouds,
    recentRelatedCloudOnChange,
    renderTabNode,
  ]);

  const detailNode = useMemo((): ReactNode => {
    switch (mainTabSelected) {
      case MACIE_TAB[4]: {
        return (
          <UpdateAllowList
            cloudId={cloudId}
            region={regionValue}
            pageBackClick={onPageBackClick}
            allowListId={allowListId}
          />
        );
      }
      default:
        return null;
    }
  }, [mainTabSelected, cloudId, currentRegion, onPageBackClick, selectedFinding]);

  const creationNode = useMemo((): ReactNode => {
    switch (mainTabSelected) {
      case MACIE_TAB[0]:
      case MACIE_TAB[1]:
      case MACIE_TAB[2]:
      case MACIE_TAB[3]:
      case MACIE_TAB[4]: {
        switch (tabSelected) {
          case SETTING_TAB[1]:
            return (
              <CreateMacieSettingAllowList cloudId={cloudId} region={regionValue} pageBackClick={onPageBackClick} />
            );
          case SETTING_TAB[2]:
            return (
              <CreateCustomDataIdentifier cloudId={cloudId} region={regionValue} pageBackClick={onPageBackClick} />
            );
          default:
            return null;
        }
      }
      default:
        return null;
    }
  }, [mainTabSelected, cloudId, regionValue, tabSelected]);

  const pageNode = useMemo((): ReactNode => {
    switch (pageType) {
      default:
      case PageNodeEnum.MGD_LIST:
        return listNode;

      case PageNodeEnum.MGD_DETAIL:
        return detailNode;

      case PageNodeEnum.MGD_CREATE:
        return creationNode;
    }
  }, [pageType, listNode, detailNode, creationNode]);

  return <div className="network-container">{pageNode}</div>;
};

export default Macie;
