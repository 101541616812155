import React, {useEffect, useState} from 'react';
import {variableCombineNextToken} from "pages/v2/Organ/Management/Utils";
import lazyGetAwsNetworkInterfaces from "graphql/queries/getAwsNetworkInterfaces";
import {networkInterfaceTab} from "pages/v2/Organ/Management/EC2/components/NetworkInterface/Common/Constant";
import Details from "pages/v2/Organ/Management/EC2/components/NetworkInterface/Common/Components/Details";
import Tags from "pages/v2/Organ/Management/EC2/components/NetworkInterface/Common/Components/Tags";

const NetworkInterfaceSummary = ({cloudId, region, rootAccountId, detailItem}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [tabSelected, setTabSelected] = useState(networkInterfaceTab[0]);

  const [getAwsNetworkInterfaces] = lazyGetAwsNetworkInterfaces();

  const handleGetAwsNetworkInterfaces = async (networkInterfaceIds : string) => {
    if (!networkInterfaceIds) return {};

    const payload = {
      cloudId,
      region,
      request: {
        networkInterfaceIds
      },
    };

    return getAwsNetworkInterfaces({
      variables: variableCombineNextToken(payload),
    })
  }

  const handleGetDetail = async () => {
    try {
      setIsLoading(true)
      const res: any = await handleGetAwsNetworkInterfaces(detailItem?.networkInterfaceId)

      const data = {
        ...res?.data?.getAwsNetworkInterfaces?.data?.[0]?.networkInterfaces?.[0],
      }

      setData({...data, rootAccountId})
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setTabSelected(networkInterfaceTab[0]);
    setData(null);

    if (!detailItem?.id) return;

    handleGetDetail()
  }, [detailItem?.id]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case networkInterfaceTab[0].id:
        return <Details data={data} />

      case networkInterfaceTab[1].id:
        return <Tags data={data} />

      default:
        return null
    }
  }

  return detailItem?.networkInterfaceId ? (
    <div className="box-summary">
      <div className="box-summary-id">{data?.networkInterfaceId}</div>

      <div className="detail-tab flex a-center">
        {networkInterfaceTab.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>

      <div className="content-tab">
        {
          !data && isLoading ? (
            <div className="detail-info">
              <div className="progress-container">
                <div className="progress-gif" />
                Loading ...
              </div>
            </div>
          ) : renderTab()
        }
      </div>
    </div>
  ) : (
    <div className="detail-data">
      <p className="empty-page">Select a item</p>
    </div>
  );
};

export default NetworkInterfaceSummary;
