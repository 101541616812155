import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { AwsDisableKeyRotationRequestType } from 'graphql/types/AwsDisableKeyRotation';

export type AwsDisableKeyRotationVariablesType = {
  cloudId: number;
  region: string;
  reqData: AwsDisableKeyRotationRequestType;
};

export type AwsDisableKeyRotationResponseDataType = {
  disableAwsKeyRotation: IGqlResponseData<{}>;
};

const disableAwsKeyRotationMutation = () =>
  useMutation<AwsDisableKeyRotationResponseDataType, AwsDisableKeyRotationVariablesType>(query);
export default disableAwsKeyRotationMutation;
