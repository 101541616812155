import StepContainer from "pages/v2/Organ/Management/components/StepContainer";
import { CreationDataPropsType, DescribeFirewallPropsType } from "./types";
import SectionContainer from "pages/v2/Organ/Management/components/SectionContainer";
import InputGroup from "pages/v2/Organ/Management/components/InputGroup";
import Input from "pages/v2/Organ/Management/components/Input";
import { useCallback, useState } from "react";
import InputArea from "pages/v2/Organ/Management/components/InputArea";

const DescribeFirewall = (props: DescribeFirewallPropsType) => {
  const { title, creationData, updateCreationData } = props;

  const [firewallName, setFirewallName] = useState<string>(creationData.firewallName);
  const [description, setDescription] = useState<string>(creationData?.description ?? '');

  const onFirewallNameChange = useCallback((value: string) => {
    setFirewallName(value);
    let firewallNameError;
    if (value === '') {
      firewallNameError = 'Name is a required field.';
    }
    const updatedCreationData: CreationDataPropsType = { ...creationData, firewallName: value, firewallNameError: firewallNameError };
    updateCreationData(updatedCreationData);
  }, [creationData]);

  const onDescriptionChange = useCallback((value: string) => {
    setDescription(value);
    let descriptionError;
    if (value.length > 256) {
      descriptionError = 'The description must be smaller than 256 characters.';
    }
    const updatedCreationData: CreationDataPropsType = { ...creationData, description: value, descriptionError: descriptionError };
    updateCreationData(updatedCreationData);
  }, [creationData]);

  return (
    <StepContainer title={title} description={'Name and describe your firewall so you can easily identify it and distinguish it from other resources.'}>
      <SectionContainer title="Firewall details">
        <InputGroup>
          <Input
            title={'Firewall name'}
            placeholder={'Enter firewall name'}
            bottomDescription={'Enter a unique anme for the firewall. You can’t change the name of the firewall after creation.'}
            value={firewallName}
            isRequired={true}
            onValueChanged={onFirewallNameChange}
            error={creationData?.firewallNameError}
          />
        </InputGroup>
        <InputGroup>
          <InputArea
            title={'Description'}
            placeholder={'Enter firewall description'}
            bottomDescription={'The description can have 0-256 characters.'}
            value={description}
            onValueChanged={onDescriptionChange}
            error={creationData?.descriptionError}
          />
        </InputGroup>
      </SectionContainer>
    </StepContainer>
  );
}

export default DescribeFirewall;