import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import lazyGetAwsAllListRuleGroupsFirewall from 'graphql/queries/getAwsAllListRuleGroupsFirewall';
import lazyGetAwsRuleGroupMetadata from 'graphql/queries/getAwsRuleGroupMetadata';

const DomainAndIp = (props: any) => {
  const {
    cloudId,
    region,
    currentRegion,
    currentRouteState,
    setCurrentRouteState,
    relatedCloudSelected,
  } = props;

  const [getAwsAllListRuleGroupsFirewall] = lazyGetAwsAllListRuleGroupsFirewall();
  const [getAwsRuleGroupMetadata] = lazyGetAwsRuleGroupMetadata();

  const [isLoading, setIsLoading] = useState(false);
  const [nextToken, setNextToken] = useState<string>('');
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        field: 'name',
        label: 'Name',
        sort: true,
      },
      {
        field: 'description',
        label: 'Description',
        sort: true,
      },
      {
        field: 'capacity',
        label: 'Capacity',
        sort: true,
      },
      {
        field: 'ruleOrder',
        label: 'Rule order',
        sort: true,
      },
    ];
  }, [mainTblRows]);

  const getVariableData = useCallback(() => {
    return {
      cloudId: relatedCloudSelected.value,
      region: currentRegion.value as string,
      request: {
        maxResults: mainTablePagination.limit,
        scope: 'MANAGED',
        managedType: 'AWS_MANAGED_DOMAIN_LISTS',
      },
    };
  }, [currentRegion, currentRouteState, relatedCloudSelected]);

  const handleGetMainDataRows = useCallback(
    async (nextToken?: string) => {
      if (!region) return;

      try {
        setIsLoading(true);
        const { data: listRuleGroupsFirewall } = await getAwsAllListRuleGroupsFirewall({
          variables: variableCombineNextToken(getVariableData(), nextToken),
        });

        if (!listRuleGroupsFirewall?.getAwsAllListRuleGroupsFirewall?.data?.length) {
          setIsLoading(false);
          return;
        }

        const fetchListAllRuleGroupsFirewall = listRuleGroupsFirewall.getAwsAllListRuleGroupsFirewall.data.map(
          async ruleGroup => {
            const ruleGroupFirewallVariable = {
              cloudId,
              region,
              request: {
                ruleGroupArn: ruleGroup.arn,
              },
            };

            return await getAwsRuleGroupMetadata({
              variables: ruleGroupFirewallVariable,
            }).then(({ data: ruleGroupMetaData }) => {
              const ruleGroupMetadataResponse = ruleGroupMetaData?.getAwsRuleGroupMetadata?.data?.[0];
              if (ruleGroupMetadataResponse) {
                const { ruleGroupArn, ruleGroupName, description, capacity, statefulRuleOptions } =
                  ruleGroupMetadataResponse;
                return {
                  id: ruleGroupArn,
                  name: ruleGroupName,
                  description: description || '-',
                  capacity: capacity,
                  ruleOrder: statefulRuleOptions?.ruleOrder || '-',
                };
              }
            });
          },
        );

        const listAllRuleGroupFirewallData = await Promise.all(fetchListAllRuleGroupsFirewall);
        const allRuleGroupPageData: any = [...mainTblRows, ...listAllRuleGroupFirewallData];
        setNextToken(listRuleGroupsFirewall?.getAwsAllListRuleGroupsFirewall?.nextToken as string);
        setMainTblRows(allRuleGroupPageData);
        setMainTblTotal({
          totalPage: Math.ceil(allRuleGroupPageData.length / mainTablePagination.limit),
          totalElement: allRuleGroupPageData.length,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [region, mainTblRows, mainTablePagination, getVariableData],
  );

  const mainRowsCurrentPage = useMemo((): RowType[] => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!nextToken) {
      handleGetMainDataRows(nextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    setMainTblTotal({
      totalPage: Math.ceil(mainTblRows.length / mainTablePagination.itemPerPage),
      totalElement: mainTblRows.length,
    });

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, mainTablePagination, nextToken]);

  useEffect(() => {
    handleGetMainDataRows();
  }, [cloudId]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Domain and IP rule groups</p>

          <p>
            Domain and IP rule groups block HTTP/HTTPS traffic to domains identified as low-reputation or that are known
            or suspected to be associated with malware or bones.
          </p>
        </div>
      </div>

      {!mainRowsCurrentPage?.length && !isLoading ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="data-grid-wrap">
          <Table
            rows={mainRowsCurrentPage}
            columns={columns}
            reportCheckedList={() => {}}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={isLoading}
            horizontalScrollable
          />

          {!!mainRowsCurrentPage?.length && !isLoading && (
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{mainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={!!nextToken}
                currentPage={mainTablePagination.currentPage}
                updateCurrentPage={page =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default DomainAndIp;
