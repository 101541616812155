import lazyGetAwsFirewallPolicy from 'graphql/queries/getAwsFirewallPolicy';
import PageDetailTitle from 'pages/v2/Organ/Management/components/PageDetailTitle';
import { useEffect, useMemo, useState } from 'react';
import { IBaseFirewallProps } from '../../../types';
import Details from '../Common/Components/Details';
import InspectionConfiguration from '../Common/Components/InspectionConfiguration';
import NetworkFirewallRuleGroups from '../Common/Components/NetworkFirewallRuleGroups';
import DeleteResource from '../DeleteResource';
import { firewallPolicyTab } from '../configs';
import './index.scss';
import deleteAwsFirewallPolicyMutation from 'graphql/mutations/deleteAwsFirewallPolicy';
import untagAwsResourcesMutation from 'graphql/mutations/untagAwsResource';
import tagAwsResourcesMutation from 'graphql/mutations/tagAwsResource';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import { AwsTagType } from 'graphql/types/AwsVpc';
import { TagRowDataType } from '../../types';
import updateAwsFirewallPolicyMutation, {
  UpdateAwsFirewallPolicyVariablesType,
} from 'graphql/mutations/updateAwsFirewallPolicy';
import ManageTLSConfigurationModal from '../ManageTLSInspectionConfiguration';

interface IFirewallPolicyDetail extends IBaseFirewallProps {}

const FirewallPolicyDetail = (props: IFirewallPolicyDetail) => {
  const { data, pageBackClick = () => {}, cloudId, region } = props;

  const [tabSelected, setTabSelected] = useState(firewallPolicyTab[0]);

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenManageTLS, setIsOpenManageTLS] = useState(false);
  const [firewallPolicy, setFirewallPolicy] = useState<any>(null);
  const [firewallPolicyResponse, setFirewallPolicyResponse] = useState<any>(null);
  const [updateToken, setUpdateToken] = useState<string>();

  const [getAwsFirewallPolicy, { loading }] = lazyGetAwsFirewallPolicy();
  const [deleteAwsFirewallPolicy, { loading: deleteAwsFirewallPolicyLoading }] = deleteAwsFirewallPolicyMutation();
  const [untagAwsResource, { loading: untagAwsResourceLoading }] = untagAwsResourcesMutation();
  const [tagAwsResource, { loading: tagAwsResourceLoading }] = tagAwsResourcesMutation();
  const [updateAwsFirewallPolicy, { loading: updateAwsFirewallPolicyLoading }] = updateAwsFirewallPolicyMutation();

  const creationApiLoading = useMemo((): boolean => {
    return untagAwsResourceLoading || tagAwsResourceLoading || updateAwsFirewallPolicyLoading;
  }, [untagAwsResourceLoading, tagAwsResourceLoading, updateAwsFirewallPolicyLoading]);

  const handleUntagAwsResources = async (datasDel: string[]) => {
    if (datasDel?.length === 0) return;
    try {
      const variables = {
        cloudId,
        region,
        request: {
          resourceArn: data?.arn,
          tagKeys: datasDel,
        },
      };
      await untagAwsResource({
        variables,
      });
    } catch (error) {
      /* empty */
    }
  };

  const handleTagAwsResources = async (datasUpdate: AwsTagType[]) => {
    if (datasUpdate?.length === 0) return;
    try {
      const variables = {
        cloudId,
        region,
        request: {
          resourceArn: data?.arn,
          tags: datasUpdate,
        },
      };
      await tagAwsResource({
        variables,
      });
    } catch (error) {
      /* empty */
    }
  };

  const handleSaveTagsDetail = async (oldTags: TagRowDataType[], newTags: TagRowDataType[]) => {
    try {
      const dataDelete: string[] = [];
      const dataUpdate: AwsTagType[] = [];
      oldTags.forEach(tag => {
        if (tag.flagDel) {
          dataDelete.push(tag.key);
        } else {
          dataUpdate.push({ key: tag.key, value: tag.value });
        }
      });
      dataUpdate.push(...newTags.map(tag => ({ key: tag.key, value: tag.value })));
      await handleUntagAwsResources(dataDelete);
      await handleTagAwsResources(dataUpdate);
      useToast(ErrorCode.SUCCESS, 'Update tags policy success.');
      setTabSelected(firewallPolicyTab[0]);
      handleGetAwsFirewallPolicy();

      return true;
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Update tags policy failed.');

      return false;
    }
  };

  const formatKeyId = (value: string = '') => {
    return value?.split('key/')[1];
  };

  const handleUpdateAwsFirewallPolicy = async (datasUpdate: string[]) => {
    try {
      const rule = { HOME_NET: { definition: datasUpdate } };
      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken: updateToken || '',
          firewallPolicyArn: data?.arn,
          encryptionConfiguration: firewallPolicyResponse.encryptionConfiguration,
          description: firewallPolicyResponse.description,
          firewallPolicyName: firewallPolicyResponse.firewallPolicyName,
          dryRun: false,
          firewallPolicy: {
            ...firewallPolicy,
            policyVariables: {
              ruleVariables: !datasUpdate.length ? {} : rule ,
            },
          },
        },
      };
      await updateAwsFirewallPolicy({ variables });
      useToast(ErrorCode.SUCCESS, 'Update policies variable success.');
      setTabSelected(firewallPolicyTab[0]);
      await handleGetAwsFirewallPolicy();

      return true;
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Update policies variable failed.');

      return false;
    }
  };

  const handleUpdateFirewallPolicyDetail = async (desc: string, streamExceptionPolicy: string) => {
    try {
      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken: updateToken || '',
          firewallPolicyArn: data?.arn,
          encryptionConfiguration: firewallPolicyResponse.encryptionConfiguration,
          firewallPolicyName: firewallPolicyResponse.firewallPolicyName,
          dryRun: false,
          firewallPolicy: {
            ...firewallPolicy,
            statefulEngineOptions: {
              ...firewallPolicy?.statefulEngineOptions,
              streamExceptionPolicy: streamExceptionPolicy,
            },
          },
          description: desc,
        },
      };
      await updateAwsFirewallPolicy({ variables });
      useToast(ErrorCode.SUCCESS, 'Update details policy success.');
      setTabSelected(firewallPolicyTab[0]);
      await handleGetAwsFirewallPolicy();

      return true;
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Update details policy failed.');

      return false;
    }
  };

  const handleUpdateTLSInspection = async (TLSValue: string) => {
    try {
      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken: updateToken || '',
          firewallPolicyArn: data?.arn,
          encryptionConfiguration: firewallPolicyResponse.encryptionConfiguration,
          firewallPolicyName: firewallPolicyResponse.firewallPolicyName,
          description: firewallPolicyResponse.description,
          dryRun: false,
          firewallPolicy: {
            ...firewallPolicy,
            tlsInspectionConfigurationArn: TLSValue,
          },
        },
      };
      await updateAwsFirewallPolicy({ variables });
      useToast(ErrorCode.SUCCESS, 'Update TLS Inspection success.');
      setTabSelected(firewallPolicyTab[0]);
      await handleGetAwsFirewallPolicy();

      return true;
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Update TLS Inspection failed.');

      return false;
    }
  };

  const handleUpdateKMSencryption = async (keyId: string, checkCustomize: boolean) => {
    try {
      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken: updateToken || '',
          firewallPolicyArn: data?.arn,
          firewallPolicyName: firewallPolicyResponse?.firewallPolicyName,
          description: firewallPolicyResponse.description,
          encryptionConfiguration: checkCustomize
            ? {
              type: 'CUSTOMER_KMS',
              keyId: keyId,
            }
            : {
              type: 'AWS_OWNED_KMS_KEY',
            },
          firewallPolicy: {
            ...firewallPolicy,
          },
          dryRun: false,
        },
      };
      await updateAwsFirewallPolicy({ variables });
      useToast(ErrorCode.SUCCESS, 'Update KMS encryption success.');
      setTabSelected(firewallPolicyTab[0]);
      await handleGetAwsFirewallPolicy();

      return true;
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Update KMS encryption failed.');

      return false;
    }
  };

  const handleUpdateStatefulRule = async (rules: any, isEdit: boolean = false) => {
    try {
      if (!isEdit) {
        const dataReq = firewallPolicy.statefulRuleGroupReferences || [];
        rules?.forEach((value: string, index: number) => {
          const maxPriority =
            !dataReq?.length
              ? 0
              : Math.max(
                ...dataReq.map((obj: { priority: number }) => obj.priority),
              );
          dataReq.push({ priority: maxPriority + 1, resourceArn: value });
        });
        firewallPolicy.statefulRuleGroupReferences = dataReq;
      } else {
        firewallPolicy.statefulRuleGroupReferences = rules;
      }
      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken: updateToken || '',
          firewallPolicyArn: data?.arn,
          firewallPolicyName: firewallPolicyResponse?.firewallPolicyName,
          description: firewallPolicyResponse.description,
          encryptionConfiguration: firewallPolicyResponse.encryptionConfiguration,
          firewallPolicy: {
            ...firewallPolicy,
          },
          dryRun: false,
        },
      };
      await updateAwsFirewallPolicy({ variables });
      useToast(ErrorCode.SUCCESS, 'Update stateful rule groups success.');
      setTabSelected(firewallPolicyTab[0]);
      await handleGetAwsFirewallPolicy();

      return true;
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Update stateful rule groups failed.');

      return false;
    }
  };

  const handleDisassociateStatefulRule = async (rules: any) => {
    try {
      const rulesRemove = new Set(rules);
      const rulesNew = firewallPolicy?.statefulRuleGroupReferences.filter(
        (item: any) => !rulesRemove.has(item.resourceArn?.split('stateful-rulegroup/')[1]),
      );

      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken: updateToken || '',
          firewallPolicyArn: data?.arn,
          firewallPolicyName: firewallPolicyResponse?.firewallPolicyName,
          description: firewallPolicyResponse.description,
          encryptionConfiguration: firewallPolicyResponse.encryptionConfiguration,
          firewallPolicy: {
            ...firewallPolicy,
            statefulRuleGroupReferences: rulesNew,
          },
          dryRun: false,
        },
      };
      await updateAwsFirewallPolicy({ variables });
      useToast(ErrorCode.SUCCESS, 'Disassociate stateful rule groups success.');
      setTabSelected(firewallPolicyTab[0]);
      await handleGetAwsFirewallPolicy();

      return true;
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Disassociate stateful rule groups failed.');

      return false;
    }
  };

  const handleUpdateStatelessRule = async (rules: any, isEdit: boolean = false) => {
    try {
      if (!isEdit) {
        const dataReq = firewallPolicy.statelessRuleGroupReferences || [];
        rules?.forEach((value: string, index: number) => {
          const maxPriority =
            !dataReq?.length
              ? 0
              : Math.max(
                ...dataReq.map((obj: { priority: number }) => obj.priority),
              );
          dataReq.push({ priority: maxPriority + 1, resourceArn: value });
        });
        firewallPolicy.statelessRuleGroupReferences = dataReq;
      } else {
        firewallPolicy.statelessRuleGroupReferences = rules;
      }
      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken: updateToken || '',
          firewallPolicyArn: data?.arn,
          firewallPolicyName: firewallPolicyResponse?.firewallPolicyName,
          description: firewallPolicyResponse.description,
          encryptionConfiguration: firewallPolicyResponse.encryptionConfiguration,
          firewallPolicy: {
            ...firewallPolicy,
          },
          dryRun: false,
        },
      };
      await updateAwsFirewallPolicy({ variables });
      useToast(ErrorCode.SUCCESS, 'Update stateless rule groups success.');
      setTabSelected(firewallPolicyTab[0]);
      await handleGetAwsFirewallPolicy();

      return true;
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Update stateless rule groups failed.');

      return false;
    }
  };

  const handleUpdateManagedStatefuRule = async (rules: any) => {
    try {
      const dataReq = firewallPolicy.statefulRuleGroupReferences || [];
      rules?.forEach((value: any, index: number) => {
        const maxPriority =
            !dataReq?.length
              ? 0
              : Math.max(
                ...dataReq.map((obj: { priority: number }) => obj.priority),
              );
        dataReq.push({
          priority: maxPriority + 1,
          resourceArn: value?.id,
          override: value?.alertMode === true ? { action: 'DROP_TO_ALERT' } : null,
        });
      });
      firewallPolicy.statefulRuleGroupReferences = dataReq;
      
      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken: updateToken || '',
          firewallPolicyArn: data?.arn,
          firewallPolicyName: firewallPolicyResponse?.firewallPolicyName,
          description: firewallPolicyResponse.description,
          encryptionConfiguration: firewallPolicyResponse.encryptionConfiguration,
          firewallPolicy: {
            ...firewallPolicy,
          },
          dryRun: false,
        },
      };
      await updateAwsFirewallPolicy({ variables });
      useToast(ErrorCode.SUCCESS, 'Update stateful rule groups success.');
      setTabSelected(firewallPolicyTab[0]);
      await handleGetAwsFirewallPolicy();

      return true;
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Update stateful rule groups failed.');

      return false;
    }
  };

  const handleDisassociateStatelessRule = async (rules: any) => {
    try {
      const rulesRemove = new Set(rules);
      const rulesNew = firewallPolicy?.statelessRuleGroupReferences.filter(
        (item: any) => !rulesRemove.has(item.resourceArn?.split('stateless-rulegroup/')[1]),
      );

      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken: updateToken || '',
          firewallPolicyArn: data?.arn,
          firewallPolicyName: firewallPolicyResponse?.firewallPolicyName,
          description: firewallPolicyResponse.description,
          encryptionConfiguration: firewallPolicyResponse.encryptionConfiguration,
          firewallPolicy: {
            ...firewallPolicy,
            statelessRuleGroupReferences: rulesNew,
          },
          dryRun: false,
        },
      };
      await updateAwsFirewallPolicy({ variables });
      useToast(ErrorCode.SUCCESS, 'Disassociate stateless rule groups success.');
      setTabSelected(firewallPolicyTab[0]);
      await handleGetAwsFirewallPolicy();

      return true;
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Disassociate stateless rule groups failed.');

      return false;
    }
  };

  const handleUpdateStatelessAction = async (
    statelessDefaultActions: string,
    statelessFragmentDefaultActions: string,
  ) => {
    try {
      const variables: UpdateAwsFirewallPolicyVariablesType = {
        cloudId,
        region,
        request: {
          updateToken: updateToken || '',
          firewallPolicyArn: data?.arn,
          firewallPolicyName: firewallPolicyResponse?.firewallPolicyName,
          description: firewallPolicyResponse.description,
          encryptionConfiguration: firewallPolicyResponse.encryptionConfiguration,
          firewallPolicy: {
            ...firewallPolicy,
            statelessDefaultActions: [statelessDefaultActions],
            statelessFragmentDefaultActions: [statelessFragmentDefaultActions],
          },
          dryRun: false,
        },
      };
      await updateAwsFirewallPolicy({ variables });
      useToast(ErrorCode.SUCCESS, 'Update stateless action success.');
      setTabSelected(firewallPolicyTab[0]);
      await handleGetAwsFirewallPolicy();

      return true;
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Update stateless action failed.');

      return false;
    }
  };

  const handleGetAwsFirewallPolicy = async () => {
    if (!data?.id) return;

    try {
      const variables = {
        cloudId,
        region,
        request: {
          firewallPolicyArn: data?.arn,
        },
      };

      const { data: firewallPolicy } = await getAwsFirewallPolicy({
        variables,
      });
      const result = firewallPolicy?.getAwsFirewallPolicy?.data?.[0];
      setFirewallPolicy(result?.firewallPolicy);
      setFirewallPolicyResponse({
        ...result?.firewallPolicyResponse,
        encryptionConfiguration: {
          ...result?.firewallPolicyResponse.encryptionConfiguration,
          keyId: formatKeyId(result?.firewallPolicyResponse?.encryptionConfiguration?.keyId),
        },
      });
      setUpdateToken(result?.updateToken);
    } catch (error) {}
  };

  const hanldeDeleteAwsFirewallPolicy = async () => {
    if (!data?.id) return;
    try {
      const variables = {
        cloudId,
        region,
        request: {
          firewallPolicyArn: data?.arn,
        },
      };
      await deleteAwsFirewallPolicy({
        variables,
      });
      useToast(ErrorCode.SUCCESS, 'Delete firewall policy success.');
      pageBackClick();
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Delete firewall policy failed.');
    }
  };

  useEffect(() => {
    setTabSelected(firewallPolicyTab[0]);
    handleGetAwsFirewallPolicy();
  }, [data?.id]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case firewallPolicyTab[0].id:
        return (
          <NetworkFirewallRuleGroups
            cloudId={cloudId}
            region={region}
            data={data}
            firewallPolicy={firewallPolicy}
            firewallPolicyResponse={firewallPolicyResponse}
            handleUpdateStatefulRule={handleUpdateStatefulRule}
            handleUpdateManagedStatefuRule={handleUpdateManagedStatefuRule}
            handleUpdateStatelessRule={handleUpdateStatelessRule}
            handleDisassociateStatefulRule={handleDisassociateStatefulRule}
            handleDisassociateStatelessRule={handleDisassociateStatelessRule}
            handleUpdateStatelessAction={handleUpdateStatelessAction}
            isLoading={loading}
            creationApiLoading={creationApiLoading}
            isEdit
          />
        );

      case firewallPolicyTab[1].id:
        return (
          <InspectionConfiguration
            cloudId={cloudId}
            region={region}
            data={data}
            firewallPolicy={firewallPolicy}
            isLoading={loading}
            setOpenEdit={setIsOpenManageTLS}
            isEdit
          />
        );

      case firewallPolicyTab[2].id:
        return (
          <Details
            cloudId={cloudId}
            region={region}
            data={data}
            firewallPolicy={firewallPolicy}
            firewallPolicyResponse={firewallPolicyResponse}
            handleSaveTagsDetail={handleSaveTagsDetail}
            loadingEdit={creationApiLoading}
            handleUpdateAwsFirewallPolicy={handleUpdateAwsFirewallPolicy}
            handleUpdateFirewallPolicyDetail={handleUpdateFirewallPolicyDetail}
            handleUpdateKMSencryption={handleUpdateKMSencryption}
            isLoading={loading}
            isEdit
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className="page-detail">
        <PageDetailTitle
          title={data?.name}
          pageBackClick={pageBackClick}
          isDelete
          onDelete={() => setIsOpenDelete(true)}
        />

        <div className="page-detail-tab">
          <div className="detail-tab flex a-center">
            {firewallPolicyTab.map(tab => {
              return (
                <button
                  className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
                  key={tab.id}
                  data-tab={tab.id}
                  onClick={e => setTabSelected(tab)}
                >
                  <p>{tab.name}</p>
                </button>
              );
            })}
          </div>

          <div className="content-tab">{renderTab()}</div>
        </div>
      </div>

      {isOpenDelete ? (
        <DeleteResource
          header={'Delete resource'}
          titleWarning={`Are you sure you want to delete the girewall policy: ${firewallPolicyResponse?.firewallPolicyName}?`}
          subWarning={'This will delete the selected firewall policy. Deleting the firewall policy cannot be undone.'}
          onDelete={hanldeDeleteAwsFirewallPolicy}
          loading={deleteAwsFirewallPolicyLoading}
          open={isOpenDelete}
          onClose={() => setIsOpenDelete(false)}
        />
      ) : null}
      {isOpenManageTLS ? (
        <ManageTLSConfigurationModal
          cloudId={cloudId}
          region={region}
          header={'Manage TLS inspection configuration'}
          open={isOpenManageTLS}
          dataInspection={firewallPolicy?.tlsInspectionConfigurationArn}
          handleUpdateTLSInspection={handleUpdateTLSInspection}
          onClose={() => setIsOpenManageTLS(false)}
          loadingSave={false}
        />
      ) : null}
    </>
  );
};

export default FirewallPolicyDetail;
