import { useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import { handleFormatText, randomString } from 'utils/Common';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import DeleteIcon from 'assets/svgs/v3/icon_delete_button.svg';
import Icon from 'components/v2/atoms/Icon';

const RulesVariables = ({ data, isLoading, isEdit }: any) => {
  const { ruleGroup } = data || {};

  const { ruleVariables } = ruleGroup || {};

  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const handleGetData = () => {
    const mainTblRows: RowType[] = [];

    try {
      if (ruleVariables?.ipSets) {
        Object.keys(ruleVariables?.ipSets).forEach(key => {
          mainTblRows.push({
            id: randomString(),
            name: key,
            type: 'IP Set',
          });
        });
      }

      if (ruleVariables?.portSets) {
        Object.keys(ruleVariables?.portSets).forEach(key => {
          mainTblRows.push({
            id: randomString(),
            name: key,
            type: 'Ports',
          });
        });
      }

      setMainTblRows(mainTblRows);
      setMainTblTotal({
        totalPage: Math.ceil(mainTblRows.length / 10),
        totalElement: mainTblRows.length,
      });
    } catch (error) {}
  };

  useEffect(() => {
    handleGetData();
  }, [ruleVariables]);

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: row => {
          return <p className="link">{handleFormatText(row.name)}</p>;
        },
      },
      {
        label: 'Type',
        field: 'type',
        sort: true,
      },
    ];
  }, []);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotal, tablePagination]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <>
      <div className="detail-info">
        <div className="detail-info-title">
          <div>
            <p>RulesVariables</p>
          </div>

          {isEdit ? (
            <div style={{ display: 'flex', gap: '8px' }}>
              <Icon width={30} height={30} src={DeleteIcon} onClick={() => setIsOpenDelete(true)} />

              <button style={{ whiteSpace: 'nowrap' }} className="button" onClick={() => setIsOpenEdit(true)}>
                Edit
              </button>
            </div>
          ) : null}
        </div>

        {isLoading ? (
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        ) : (
          <div className="detail-info-content">
            {!mainRowsCurrentPage.length && !isLoading ? (
              <div className="data-grid-wrap">
                <p className="empty-row">Empty</p>
              </div>
            ) : (
              <div className="data-grid-wrap">
                <Table
                  rows={mainRowsCurrentPage}
                  columns={columns}
                  sortOption={{
                    target: tablePagination.target,
                    direction: tablePagination.direction,
                    onChangeSort: (target: string, dir: OrderDirection) => {
                      updateTablePagination('target', target);
                      updateTablePagination('direction', dir);
                    },
                  }}
                  isLoading={isLoading}
                  horizontalScrollable={true}
                  reportCheckedList={isEdit ? e => setCheckedList(e) : undefined}
                />

                {mainRowsCurrentPage?.length && !isLoading ? (
                  <div className="fleet-instance pagination-wrapper flex a-center">
                    <p className="flex a-center">
                      Total <span>{mainTblTotal.totalElement}</span>
                    </p>

                    <TableManagePagination
                      ableFetchMore={false}
                      currentPage={tablePagination.currentPage}
                      updateCurrentPage={page => updateTablePagination('currentPage', page)}
                      totalPage={mainTblTotal.totalPage}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default RulesVariables;
