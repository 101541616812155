export interface ITag {
  key: string;
  value?: string;
}

export enum ListType {
  REGULAR_EXPRESSION = 'regular-expression',
  PREDEFINED_TEXT = 'predefined-text',
}

export interface ICreateAllowListData {
  name: string;
  description?: string;
  listType: ListType;
  regularExpression: string;
  predefinedS3Name: string;
  predefinedS3Object: string;
  tags: ITag[];
}

export interface ICreateAllowListErrors {
  name?: string;
  regular?: string;
  s3Name?: string;
  s3Object?: string;
}
