import { useCallback, useEffect, useMemo, useState } from 'react';
import BaseModal from 'components/v2/modals/BaseModal';
import './styles.scss';
import { SectionContainer, StepContainer } from '../WebACL/CreateWebACL/components';
import SectionItem from '../WebACL/CreateWebACL/components/SectionItem';
import lazyAwsDescribeManagedProductsByVendor from 'graphql/queries/getAwsDescribeManagedProductsByVendor';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import DetailTable from '../../EC2/components/DetailTable';
import { PAID_RULE_GROUP_COLUMN_LIST } from './configs';
import { AwsManagedProductDescriptorType } from 'graphql/types/AwsDescribeManagedProductsByVendor';
import lazyGetAwsDescribeManagedRuleGroup from 'graphql/queries/getAwsDescribeManagedRuleGroup';
import { ColumnType, RowType } from '@Types/v2/Table';
import ToggleSwitchAtom from 'components/v4/atoms/ToggleSwitchAtom';
import ButtonGroup from '../WebACL/CreateWebACL/components/ButtonGroup';
import { ButtonTypeEnum } from '../WebACL/CreateWebACL/components/Button';
import EditRuleGroupModal from './components/EditRuleGroup';
import { AwsRuleSummaryType } from 'graphql/types/AwsDescribeManagedRuleGroup';
import { AwsRulelogType } from 'graphql/types/AwsCheckCapacity';
import _ from 'lodash';

type ManagedRuleModalPropsType = {
  isVisible: boolean;
  onClose: () => void;
  cloudId: number;
  selectedRegion: string;
  webAcl?: any;
  onAddRule: (rule: Array<AwsRulelogType>) => void;
  editingRule?: AwsRulelogType;
  selectedRuleGroups?: Array<AwsRulelogType>;
};

export type UIFreeRuleGroupType = AwsManagedProductDescriptorType & {
  id?: string;
  capacity?: number;
  rules?: Array<AwsRuleSummaryType>;
};

const ManagedRuleModal = (props: ManagedRuleModalPropsType) => {
  const {
    isVisible,
    onClose,
    cloudId,
    selectedRegion,
    webAcl,
    onAddRule,
    editingRule,
    selectedRuleGroups: selectedRuleGroupsProp,
  } = props;

  const [freeRuleGroups, setFreeRuleGroups] = useState<Array<UIFreeRuleGroupType>>([]);
  const [selectedRuleGroups, setSelectedRuleGroups] = useState<Array<UIFreeRuleGroupType>>([]);
  const [selectedRuleGroup, setSelectedRuleGroup] = useState<UIFreeRuleGroupType>();
  const [isEditRuleGroupModalVisible, setEditRuleGroupModalVisible] = useState(false);
  const [customRules, setCustomRules] = useState<Array<AwsRulelogType>>([]);

  const [getAwsDescribeManagedProductsByVendor, { loading: getManagedProductsByVendorLoading }] =
    lazyAwsDescribeManagedProductsByVendor();
  const [getAwsDescribeManagedRuleGroup, { loading: getAwsManagedRuleGroupLoading }] =
    lazyGetAwsDescribeManagedRuleGroup();

  const getFreeRuleGroup = useCallback(async () => {
    const managedProductsByVendorRequest = {
      vendorName: 'AWS',
      scope: selectedRegion !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
    };

    const variables = {
      cloudId: cloudId,
      region: selectedRegion !== 'CloudFront' ? selectedRegion : 'us-east-1',
      request: managedProductsByVendorRequest,
    };

    let managedProducts: Array<AwsManagedProductDescriptorType> = [];

    await getAwsDescribeManagedProductsByVendor({ variables }).then(res => {
      managedProducts = res?.data?.getAwsDescribeManagedProductsByVendor?.data?.[0]?.managedProducts ?? [];
    });

    const filteredManagedProducts = managedProducts.filter(product => !product?.isAdvancedManagedRuleSet);

    const _freeRuleGroups = await Promise.all(
      filteredManagedProducts.map(product => {
        return getAwsDescribeManagedRuleGroup({
          variables: {
            ...variables,
            request: {
              ...variables.request,
              name: product?.managedRuleSetName,
            },
          },
        }).then(res => {
          const managedRuleGroup = res?.data?.getAwsDescribeManagedRuleGroup?.data?.[0];
          return {
            ...product,
            id: product?.managedRuleSetName,
            capacity: managedRuleGroup?.capacity,
            rules: managedRuleGroup?.rules,
          };
        });
      }),
    );

    const orderedFreeRuleGroups = _.orderBy(_freeRuleGroups, ['managedRuleSetName'], ['asc']);

    setFreeRuleGroups(orderedFreeRuleGroups);

    return orderedFreeRuleGroups;
  }, [cloudId, selectedRegion]);

  const getInitialData = useCallback(async () => {
    const _freeRuleGroups = await getFreeRuleGroup();

    const selectedFreeRuleGroups = selectedRuleGroupsProp
      ?.map(ruleGroup => _freeRuleGroups.find(freeRuleGroup => freeRuleGroup.managedRuleSetName === ruleGroup?.name))
      ?.filter(item => !!item);

    setCustomRules(
      (selectedRuleGroupsProp?.map(ruleGroup => ({
        ..._.omit(ruleGroup, 'capacity', 'id'),
      })) as Array<AwsRulelogType>) ?? [],
    );
    setSelectedRuleGroups((selectedFreeRuleGroups as UIFreeRuleGroupType[]) ?? []);

    if (editingRule) {
      const editingFreeRuleGroup = selectedFreeRuleGroups?.find(
        ruleGroup => ruleGroup?.managedRuleSetName === editingRule?.name,
      );
      setSelectedRuleGroup(editingFreeRuleGroup);
      setEditRuleGroupModalVisible(true);
    }
  }, [getFreeRuleGroup, editingRule, selectedRuleGroupsProp]);

  useEffect(() => {
    getInitialData();
  }, []);

  const renderNameCell = useCallback((row: RowType) => {
    const { productTitle, productDescription } = row;
    return (
      <div className="name-cell-container">
        <p className="name-cell-title">{productTitle}</p>
        <p className="name-cell-description">{productDescription}</p>
      </div>
    );
  }, []);

  const handleAddRuleGroup = useCallback((ruleGroup: UIFreeRuleGroupType) => {
    setSelectedRuleGroups(prevState => [...prevState, ruleGroup]);
    setCustomRules(prevState => [
      ...prevState,
      {
        name: ruleGroup?.managedRuleSetName,
        statement: {
          managedRuleGroupStatement: {
            vendorName: ruleGroup?.vendorName,
            name: ruleGroup?.managedRuleSetName,
          },
        },
        overrideAction: { none: {} },
        visibilityConfig: {
          sampledRequestsEnabled: true,
          cloudWatchMetricsEnabled: true,
          metricName: ruleGroup?.managedRuleSetName,
        },
      },
    ]);
  }, []);

  const handleRemoveRuleGroup = useCallback(
    (ruleGroup: UIFreeRuleGroupType) => {
      const newRuleGroups = selectedRuleGroups.filter(group => group.id !== ruleGroup.id);
      const newCustomRules = customRules.filter(rule => rule?.name === ruleGroup?.managedRuleSetName);
      setSelectedRuleGroups(newRuleGroups);
      setCustomRules(newCustomRules);
    },
    [selectedRuleGroups, customRules],
  );

  const onEditButtonClicked = useCallback((ruleGroup: UIFreeRuleGroupType) => {
    setSelectedRuleGroup(ruleGroup);
    setEditRuleGroupModalVisible(true);
  }, []);

  const onToggleSwitched = useCallback(
    (id: string) => {
      const ruleGroup = freeRuleGroups.find(group => group.id === id);
      const selectedRuleGroup = selectedRuleGroups.find(group => group.id === id);

      if (!selectedRuleGroup && ruleGroup) {
        handleAddRuleGroup(ruleGroup);
        return;
      }

      if (ruleGroup) {
        handleRemoveRuleGroup(ruleGroup);
      }
    },
    [freeRuleGroups, selectedRuleGroups, handleAddRuleGroup, handleRemoveRuleGroup],
  );

  const renderActionCell = useCallback(
    (row: RowType) => {
      const { id } = row;

      const _selectedRuleGroup = selectedRuleGroups.find(group => group.id === id);

      return (
        <div className="action-cell-container">
          <div className="label-container">
            <p className="label">Add to web ACL</p>
            {_selectedRuleGroup && (
              <a onClick={() => onEditButtonClicked(_selectedRuleGroup)} className="description">
                Edit
              </a>
            )}
          </div>
          <ToggleSwitchAtom onClick={() => onToggleSwitched(id as string)} value={!!_selectedRuleGroup} />
        </div>
      );
    },
    [selectedRuleGroups, onToggleSwitched],
  );

  const FREE_RULE_GROUP_COLUMN_LIST: Array<ColumnType> = [
    { field: 'productTitle', label: 'Name', sort: true, renderCell: renderNameCell },
    { field: 'capacity', label: 'Capacity', sort: true },
    { field: 'action', label: 'Action', sort: true, renderCell: renderActionCell },
  ];

  const handleAddRuleButtonClicked = useCallback(() => {
    onAddRule(customRules);
    onClose();
  }, [customRules]);

  const buttons = useMemo(() => {
    return [
      {
        id: 'cancel-button',
        label: 'Cancel',
        onClick: onClose,
      },
      {
        id: 'save-button',
        label: 'Save rule',
        onClick: handleAddRuleButtonClicked,
        type: ButtonTypeEnum.PRIMARY,
      },
    ];
  }, [handleAddRuleButtonClicked]);

  return (
    <BaseModal open={isVisible} onClose={onClose} className="managed-rule-modal">
      <StepContainer
        title="Add managed rule groups"
        description="Managed rule groups are created and maintained for you by AWS and AWS Marketplace series. Any fees that a managed rule group provider charges for using a managed rule group are in addition to the standard service charges for AWS WAF. AWS WAF Pricing"
      >
        <SectionContainer>
          <SectionItem.Collapsible title="AWS managed rule groups">
            <DetailTable
              title="Paid rule groups"
              description="AWS WAF charges subscription and usage fees for paid managed rule groups. These are in addition to the standard service charges for AWS WAF."
              columns={PAID_RULE_GROUP_COLUMN_LIST}
              data={[]}
              isEmptyTableWithHeader
              emptyMessage={'Not supported'}
            />
            <DetailTable
              title="Free rule groups"
              description="You can use the free rule groups without any added charges beyond the standard service charges for AWS WAF."
              columns={FREE_RULE_GROUP_COLUMN_LIST}
              data={freeRuleGroups}
              hasPagination={false}
              isLoading={getManagedProductsByVendorLoading || getAwsManagedRuleGroupLoading}
            />
          </SectionItem.Collapsible>
        </SectionContainer>
        <ButtonGroup buttons={buttons} />
      </StepContainer>
      {isEditRuleGroupModalVisible && (
        <EditRuleGroupModal
          isVisible={isEditRuleGroupModalVisible}
          onClose={() => setEditRuleGroupModalVisible(false)}
          ruleGroup={selectedRuleGroup}
          customRule={customRules.find(rule => rule.name === selectedRuleGroup?.managedRuleSetName)}
          cloudId={cloudId}
          selectedRegion={selectedRegion}
          customRules={customRules}
          setCustomRules={setCustomRules}
          webAcl={webAcl}
        />
      )}
    </BaseModal>
  );
};

export default ManagedRuleModal;
