import { OrderDirection } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import { useMemo, useState } from 'react';
import { ICreationDataPropsType } from '../steps/type';

interface IRulesProps {
  creationData: ICreationDataPropsType;
  updateCreationData: (data: ICreationDataPropsType) => void;
  columns: { label: string; field: string; sort?: true }[];
}

const Rules = ({ creationData, updateCreationData, columns }: IRulesProps) => {
  const { ruleRowsDomainList } = creationData;
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const handleDelete = () => {
    const list = ruleRowsDomainList
      .filter(row => checkedList.includes(row.id as string))
      .map((item, id) => ({
        ...item,
        id: id + 1,
      }));
    updateCreationData({
      ...creationData,
      ruleRowsDomainList: list,
    });
    setCheckedList([]);
  };
  const tableNode = useMemo(() => {
    return (
      <>
        <Table
          columns={columns}
          rows={ruleRowsDomainList}
          reportCheckedList={(list: string[]) => {
            setCheckedList(list);
          }}
          currentCheckList={checkedList}
          sortOption={{
            target: tablePagination.target,
            direction: tablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              setTablePagination(prev => ({
                ...prev,
                target: target,
                direction: dir,
              }));
            },
          }}
        />
        <div className="pagination-wrapper flex a-center">
          <p className="flex a-center text-paging">
            Total <span>{ruleRowsDomainList.length}</span>
          </p>
          <TablePagination
            currentPage={tablePagination.currentPage}
            updateCurrentPage={pageNumber => pageNumber}
            totalPage={ruleRowsDomainList.length / tablePagination.limit + 1}
          />
        </div>
      </>
    );
  }, [
    checkedList,
    columns,
    ruleRowsDomainList,
    tablePagination.currentPage,
    tablePagination.direction,
    tablePagination.limit,
    tablePagination.target,
  ]);

  return (
    <div className="rule-group-container">
      <div className="title-group">
        <p className="title">Rules</p>
      </div>
      <div className="rule-group-details-container">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className="rule-group-detail-title" style={{ borderBottom: 0 }}>
            Rules
          </div>
          <div className="button-group">
            <button
              className={`button ${checkedList.length > 0 ? '' : 'disable-btn'}`}
              style={{ margin: 0 }}
              onClick={handleDelete}
            >
              Delete
            </button>
          </div>
        </div>
        <div
          className="detail-table"
          style={{
            margin: 0,
            border: 'none',
            borderTop: '1px solid #E0E0E0',
          }}
        >
          {tableNode}
        </div>
      </div>
    </div>
  );
};

export default Rules;
