import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';

export interface IGetAwsAllowListVariables {
  cloudId: number;
  region: string;
  request: {
    id: string;
  };
}

export interface IGetAwsAllowListResponse {
  name: string;
  description?: string;
  criteria: {
    regex?: string;
    s3WordsList?: {
      bucketName: string;
      objectKey: string;
    };
  };
  tags?: Record<string, string | null>;
  id: string;
  arn: string;
  status: {
    code: string;
    description: string;
  };
  createdAt: Date;
  updatedAt: Date;
}

export interface IGetAwsAllowListResponseData {
  getAwsAllowList: IGqlResponseData<IGetAwsAllowListResponse[]>;
}

const lazyGetAwsAllowList = () =>
  useLazyQuery<IGetAwsAllowListResponseData, IGetAwsAllowListVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsAllowList;
