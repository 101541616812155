import MariaDBImg from 'assets/images/v2/db-icon-mariadb.png';
import MySqlImg from 'assets/images/v2/db-icon-mysql.png';
import OracleImg from 'assets/images/v2/db-icon-oracle.png';
import SqlServerImg from 'assets/images/v2/db-icon-sqlserver.png';
import PostGreImg from 'assets/images/v2/db-icon-postgre.png';

export const COLUMNS_KEY = ['Key', 'Value'];

export const CREATE_SECRET_STEPS = [
  { id: 'secreteType', title: 'Choose secret type' },
  { id: 'configureSecret', title: 'Configure secret' },
  { id: 'configureRotation', title: 'Configure rotation - optional' },
  { id: 'review', title: 'Create customer manager key' },
];

export enum DefaultActionEnum {
  ALLOW = 'allow',
  BLOCK = 'block',
}

export const DATABASE_IMGS = [
  {
    title: 'MariaDB',
    img: MariaDBImg,
    value: 'mariadb',
  },
  {
    title: 'MySQL',
    img: MySqlImg,
    value: 'mysql',
  },
  {
    title: 'PostgreSQL',
    img: PostGreImg,
    value: 'postgresql',
  },
  {
    title: 'Oracle Database',
    img: OracleImg,
    value: 'oracle',
  },
  {
    title: 'SQL Server',
    img: SqlServerImg,
    value: 'sqlserver',
  },
];

export const SCOPE_SECRET_TYPE_RADIO_LIST = [
  {
    id: 'rds',
    name: 'Credentials for Amazon RDS database',
    desc: '',
    value: 'rds',
  },
  {
    id: 'ddb',
    name: 'Credentials for Amazon DocumentDB database',
    desc: '',
    value: 'ddb',
  },
  {
    id: 'wh',
    name: 'Credentials for Amazon Redshift data warehouse',
    desc: '',
    value: 'wh',
  },
  {
    id: 'otherDB',
    name: 'Credentials for other database',
    desc: '',
    value: 'otherDB',
  },
  {
    id: 'otherTS',
    name: 'Other type of secret',
    desc: 'API key, OAuth token, other.',
    value: 'otherTS',
  },
];
