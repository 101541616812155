/** 영어, 숫자만,점, 밑줄, 수평선 사용하여 ~글자 이상, 최대 ~글자 */
export const idRegex = /^[a-zA-Z0-9._-]*$/;
/** 숫자, 영문자, 특수문자 조합의 8글자 이상, 최대 12글자 */
export const pwRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
export const emailRegex = /^[a-zA-Z0-9+-\\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
/** 연락처 */
export const onlyNumRegex = /^[0-9]*$/;
/** 사용자 이름 : 영어 대소문자, 숫자, 한글, 띄어쓰기 가능. 2글자 이상, 최대 30자 */
export const nameRegex = /^[a-zA-Z0-9가-힣\s]{2,}$/;
/* 숫자 외 불가 */
export const numberRegex = /[^0-9]/g;
/* 다른 숫자와 알파벳은 허용되지 않습니다 */
export const alphabetNumberRegex = /[^a-zA-Z0-9]/g;
/* 특수문자 replace처리를 위해 */
export const koEnNumSpRegex = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;

export const nameRegexUtils = /^[A-Za-z0-9_-]{1,128}$/;

export const twelveNumberRegex = /^\d{12}$/;

export const portRegex = /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;

export const secretNameRegex = /^[a-zA-Z0-9/_+=.@-]+$/;

export const scheduleExpressionRegex =
  /^(cron\(\s*((([0-5]?[0-9]|\*)\s+([01]?[0-9]|2[0-3]|\*)\s+([01]?\d|2[0-9]|3[01]|\*)\s+(1[0-2]|0?[1-9]|\*)\s+([1-7]|\*|\?|L|\*)\s+(\d{4}|\*)?)|([0-5]?[0-9]|\*)\s+([01]?[0-9]|2[0-3]|\*)\s+([01]?\d|2[0-9]|3[01]|\*)\s+(1[0-2]|0?[1-9]|\*)\s+([1-7]|\*|\?|L|\*)\s+(\d{4}|\*)?)\s*)\)$|rate\(\s*(\d+)\s*(minute|hour|day|week)s?\s*\)$/;

export const durationRegex = /^(1?[0-9]|2[0-4])h$/;

export const descriptionRegex = /^[a-zA-Z0-9 ._-]+$/;
