import { gql } from '@apollo/client';

const query = gql`
  query getAwsFindingStatistics($reqData: AwsGetFindingStatisticsRequest, $cloudId: Long, $region: String) {
    getAwsFindingStatistics(getFindingStatisticsRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetFindingStatisticsResponse {
          countsByGroup {
            count
            groupKey
          }
        }
      }
    }
  }
`;
export default query;
