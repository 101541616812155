import StepContainer from 'pages/v2/Organ/Management/components/StepContainer';
import { AssociateFirewallPolicyPropsType, CreationDataPropsType } from './types';
import SectionContainer from 'pages/v2/Organ/Management/components/SectionContainer';
import InputGroup from 'pages/v2/Organ/Management/components/InputGroup';
import Dropdown from 'components/molecules/Dropdown';
import { DropdownListDataType } from 'components/molecules/Dropdown/types';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import lazyGetAwsListFirewallPolicies, { IAwsListFirewallPoliciesVariables } from 'graphql/queries/getAwsListFirewallPolicies';

const AssociateFirewallPolicy = (props: AssociateFirewallPolicyPropsType) => {
  const { title, cloudId, region, creationData, updateCreationData } = props;

  // API
  const [getAwsListFirewallPolicies, {loading: getListPolicyLoading}] = lazyGetAwsListFirewallPolicies();

  const [allListFirewallPolicies, setAllListFirewallPolices] = useState<DropdownListDataType[]>([]);
  const [policySelcted, setPolicySelcted] = useState<DropdownListDataType>();

  const onPolicyClicked = useCallback((policy: DropdownListDataType) => {
    setPolicySelcted(policy);
    const updatedCreationData: CreationDataPropsType = {
      ...creationData,
      firewallPolicyArn: policy.value.toString(),
      firewallPolicyArnError: '',
    };
    updateCreationData(updatedCreationData);
  }, [creationData]);

  const returnInitData = useCallback((listPolicy: DropdownListDataType[]) => {
    if (creationData.firewallPolicyArn !== '') {
      const policy = listPolicy.find(item => item.value === creationData.firewallPolicyArn)
      if (policy) {
        setPolicySelcted(policy)
      }
    }
  }, [creationData]);

  const fetchFirewallPolices = useCallback(() => {
    const variables: IAwsListFirewallPoliciesVariables = {
      cloudId,
      region,
      request: {},
    };
    getAwsListFirewallPolicies({ variables }).then(({data: response}) => {
      const firewallPolicies = response?.getAwsListFirewallPolicies.data[0].firewallPolicies;
      const dropdownFirewallPolicies = firewallPolicies?.map(firewallPolicy => {
        return {
          name: firewallPolicy.name,
          value: firewallPolicy.arn,
        };
      });

      setAllListFirewallPolices(dropdownFirewallPolicies || []);
      returnInitData(dropdownFirewallPolicies ?? []);
    });
  }, [cloudId, region]);

  useEffect(() => {
    fetchFirewallPolices();
  }, []);

  return (
    <StepContainer
      title={title}
      description={'The firewall policy contains a list of rule groups that define how the firewall inspects and manages web traffic. You can configure the associated firewall policy after you create the firewall.'}
    >
      <SectionContainer
        title="Firewall policy"
        description={'Associate an existing firewall policy'}
      >
        <InputGroup>
          <Dropdown
            id={_.uniqueId('dropdown-')}
            className={'mw-60'}
            data={allListFirewallPolicies}
            value={policySelcted}
            handleClick={val => onPolicyClicked(val)}
            placeholder={'Choose firewall policy'}
            isVertical={true}
            disabled={getListPolicyLoading}
            error={creationData?.firewallPolicyArnError}
            description={'Choose the firewall policy to associate with this firewall.'}
            title={'Choose firewall policy'}
          />
        </InputGroup>
        
      </SectionContainer>
    </StepContainer>
  );
};

export default AssociateFirewallPolicy;
