import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsPutKeyPolicyRequestType } from 'graphql/types/AwsPutKeyPolicy';

export type AwsUpdateFirewallRuleGroupVariablesType = {
  cloudId: number;
  region: string;
  request: {
    group?: string;
    description?: string;
    groupName?: string;
  };
};

export type AwsUpdateFirewallRuleGroupResponseDataType = {
  updateAwsGroup: IGqlResponseNewData<{}>;
};

const updateFirewallRuleGroupMutation = () =>
  useMutation<AwsUpdateFirewallRuleGroupResponseDataType, AwsUpdateFirewallRuleGroupVariablesType>(query);
export default updateFirewallRuleGroupMutation;
