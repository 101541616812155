import { useMutation } from '@apollo/client';
import { IGqlResponseNewData } from 'graphql/types';
import query from './query';

export interface ITestAwsCustomDataIdentifierVariables {
  cloudId: number;
  region: string;
  request: {
    regex: string;
    maximumMatchDistance?: number;
    sampleText: string;
  };
}

export type TestAwsCustomDataIdentifierResponse = {
  matchCount: number;
};

export interface ITestAwsCustomDataIdentifierResponseData {
  testAwsCustomDataIdentifier: IGqlResponseNewData<TestAwsCustomDataIdentifierResponse>;
}

const lazyTestAwsCustomDataIdentifier = () =>
  useMutation<ITestAwsCustomDataIdentifierResponseData, ITestAwsCustomDataIdentifierVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyTestAwsCustomDataIdentifier;
