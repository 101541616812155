import lazyGetAwsListTlsInspectionConfigurationsPage, { IAwsListTlsInspectionConfigurationsVariables } from "graphql/queries/getAwsAllListTLSInspectionConfigurations";
import { AwsTLSInspectionConfigPropsType } from "../types";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ColumnType, OrderDirection, RowType } from "@Types/v2/Table";
import { AwsTLSInspectionConfigurationMetadata } from "graphql/types/AwsTLSInspectionConfigurationMetadata";
import { IMgdTablePaginationProps, IMgdTotalPageProps } from "layouts/v3/MgdTabLayout/types";
import { handleFormatText } from "utils/Common";
import { orderAlphabetical } from "../../../Utils/Sorting";
import { variableCombineNextToken } from "../../../Utils";
import InputAtom from "components/v2/atoms/InputAtom";
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import Table from "components/v2/dataDisplay/Table";
import TableManagePagination from "components/v2/dataDisplay/TableManagePagination";
import { FilterPropsType } from "../../../type";

const TlsInspectionConfigList = (props: AwsTLSInspectionConfigPropsType) => {
  const { cloudId, region, tabInformation, onTlsInpsectionSelected, onTlsInpsectionIdClicked, currentRouteState, setCurrentRouteState } = props;

  // API
  const [getAwsAllListTLSInspectionConfigurations, {loading: getTlsInspectionLoading}] = lazyGetAwsListTlsInspectionConfigurationsPage();

  // State
  const [mainTblTotalRows, setMainTblTotalRows] = useState<RowType[]>([]);
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [tlsInpsectionConfigsSelected, setTlsInpsectionConfingsSelected] = useState<AwsTLSInspectionConfigurationMetadata[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [resourceGroupFilterInputValue, setResourceGroupFilterInputValue] = useState<FilterPropsType>({
    name: 'name',
    value: currentRouteState?.value || '',
  });
  const [resourceGroupFilter, setResourceGroupFilter] = useState<FilterPropsType | undefined>(currentRouteState?.value ? resourceGroupFilterInputValue : undefined);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const mainTblColumns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <span
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  onTlsInpsectionIdClicked();
                  onTlsInpsectionSelected(row as AwsTLSInspectionConfigurationMetadata)
                }}
              >
                {handleFormatText(row?.name)}
              </span>
            }
          </>
        ),
      }
    ];
  }, []);


  const handleSearchFunction = useCallback(() => {
    if (resourceGroupFilterInputValue.value !== '') {
      setResourceGroupFilter({ name: resourceGroupFilterInputValue.name, value: resourceGroupFilterInputValue.value });
      const searchedRows = mainTblTotalRows?.filter(row => row[resourceGroupFilterInputValue.name] === resourceGroupFilterInputValue.value);
      setMainTblTotal({
        totalPage: Math.ceil(searchedRows.length / mainTablePagination.itemPerPage),
        totalElement: searchedRows.length,
      });
    } else {
      setResourceGroupFilter(undefined);
      setMainTblTotal({
        totalPage: Math.ceil(mainTblTotalRows.length / mainTablePagination.itemPerPage),
        totalElement: mainTblTotalRows.length,
      });
    }
  }, [resourceGroupFilterInputValue, mainTblTotalRows]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setMainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchAwsAllTlsInspections(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    let totalRows = [...mainTblTotalRows];
    if (resourceGroupFilter && resourceGroupFilter?.value !== '' && resourceGroupFilter?.name !== '') {
      totalRows = totalRows?.filter(row => {
        return row?.[resourceGroupFilter?.name ?? '']?.includes(resourceGroupFilter?.value || '');
      });
    }

    return orderAlphabetical(totalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotalRows, mainTblTotal, mainTablePagination, mainNextToken, resourceGroupFilter]);

  const awsTlsInpsectionRequestVariables = useMemo((): IAwsListTlsInspectionConfigurationsVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        maxResults: mainTablePagination.limit
      },
    };
  }, [cloudId, region, mainTablePagination]);

  const fetchAwsAllTlsInspections = useCallback(async (nextToken?: string, isInitial?: boolean) => {
    const combinedVariable = variableCombineNextToken(awsTlsInpsectionRequestVariables, nextToken);

    const listTLS = await getAwsAllListTLSInspectionConfigurations({ variables: combinedVariable }).then(({ data: listTLS }) => {
      return listTLS;
    });

    const awsAllListTLSInspectionConfigurations = listTLS?.getAwsAllListTLSInspectionConfigurations;

    if (awsAllListTLSInspectionConfigurations?.data && awsAllListTLSInspectionConfigurations.data.length > 0) {
      const tlsData: RowType[] = [];
      awsAllListTLSInspectionConfigurations.data.forEach(item => {
        tlsData.push({
          ...item,
          id: item.arn,
        });
      });
      
      let allTlsInspectionConfigs: RowType[] = [];
      if (isInitial) {
        allTlsInspectionConfigs = tlsData;
      } else {
        allTlsInspectionConfigs = [...mainTblTotalRows, ...tlsData];
      }

      setMainTblTotalRows(allTlsInspectionConfigs);
      setMainNextToken(awsAllListTLSInspectionConfigurations?.nextToken as string);
      setMainTblTotal({
        totalPage: Math.ceil(allTlsInspectionConfigs.length / mainTablePagination.itemPerPage),
        totalElement: allTlsInspectionConfigs.length,
      });
    }

  }, [awsTlsInpsectionRequestVariables, cloudId, region, mainTblTotalRows, mainTablePagination]);

  const handleTlsInspectionConfigSelected = useCallback((listId: string[]) => {
    const tlsInspections: AwsTLSInspectionConfigurationMetadata[] = [];
    listId.map(rowId => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);
      if (rowSelected) {
        tlsInspections.push(rowSelected as AwsTLSInspectionConfigurationMetadata);
      }
    });
    setTlsInpsectionConfingsSelected(tlsInspections);
  }, [mainTblTotalRows]);

  const handleMainRowSelected = useCallback(
    (rowId: string) => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);

      if (!rowSelected) return;
      handleTlsInspectionConfigSelected([rowId]);
      onTlsInpsectionSelected(rowSelected as AwsTLSInspectionConfigurationMetadata);
    },
    [mainTblTotalRows],
  );

  // For initial data
  useEffect(() => {
    fetchAwsAllTlsInspections('', true);
  }, []);

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>{tabInformation.title}</p>
        </div>
        <div className="flex action a-center">
          {/* <DropdownAtom
            id="types-dropdown"
            className=""
            data={AWS_KEY_FILTER_DROPDOWN}
            value={resourceGroupFilterDropdownValue}
            handleClick={val => {
              setResourceGroupFilterDropdownValue(val);
              setResourceGroupFilterInputValue(prev => ({ ...prev, name: val.value as string }));
            }}
          /> */}
          <InputAtom
            type={'text'}
            value={resourceGroupFilterInputValue.value}
            defaultValue={''}
            onChangeValue={str => setResourceGroupFilterInputValue(prev => ({ name: prev?.name, value: str }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearchFunction();
              }
            }}
          />

          <button className="action-btn" onClick={handleSearchFunction}>
            Search
          </button>
          {/* <button className="action-btn" onClick={() => onNavigateCreation()}>
            Create key
          </button>
          <button className="action-btn" onClick={() => setDeleteModalVisible(true)} disabled={deleteButtonDisabled}>
            Delete
          </button> */}
        </div>
      </div>
      <div className="data-grid-wrap">
        <Table
          rows={mainRowsCurrentPage}
          columns={mainTblColumns}
          reportCheckedList={listId => handleTlsInspectionConfigSelected(listId)}
          reportSelected={id => handleMainRowSelected(id)}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              updateTablePagination('target', target);
              updateTablePagination('direction', dir);
            },
          }}
          isLoading={getTlsInspectionLoading}
          horizontalScrollable={true}
        />
        {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && getTlsInspectionLoading === false && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center">
              Total <span>{mainTblTotal.totalElement}</span>
            </p>
            <TableManagePagination
              ableFetchMore={!!mainNextToken}
              currentPage={mainTablePagination.currentPage}
              updateCurrentPage={page =>
                setMainTablePagination(prev => ({
                  ...prev,
                  ['currentPage']: page,
                }))
              }
              totalPage={mainTblTotal.totalPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default TlsInspectionConfigList;