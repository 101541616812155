import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeDbInstances($reqData: AwsDescribeDbInstancesRequest, $cloudId: Long, $region: String) {
  getAwsDescribeDbInstances(describeDbInstancesRequest: $reqData, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeDbInstancesResponse {
        dBInstances {
          dbInstanceIdentifier
          engine
          dbInstanceStatus
          instanceCreateTime
          dbName
          endpoint {
            address
            hostedZoneId
            port
          }
        }
        marker
      }
    }
  }
}`;
export default query;