import { useCallback, useMemo, useRef, useState } from 'react';
import './index.scss';
import { PageTitle } from 'pages/v2/Organ/Management/WAF/WebACL/CreateWebACL/components';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import SecretTypeChooseStep from './SecretTypeChooseStep';
import _ from 'lodash';
import ConfigureSecretStep from './ConfigureSecretStep';
import ConfigureRotationStep from './ConfigureRotationStep';
import { CREATE_SECRET_STEPS } from './configs';
import ReviewStep from './ReviewStep';
import createAwsSecretMutation, { ICreateAwsSecretVariables } from 'graphql/mutations/createAwsSecret';
import rotateAwsSecretMutation, { IRotateAwsSecretVariables } from 'graphql/mutations/rotateAwsSecret';
import putAwsResourcePolicyMutation, { IPutAwsResourcePolicyVariables } from 'graphql/mutations/putAwsResourcePolicy';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import lazyGetAwsValidateResourcePolicy, {
  IAwsValidateResourcePolicyVariables,
} from 'graphql/queries/getAwsValidateResourcePolicy';
import { CreationDataType, RotateSecretReqType, TagType } from './type';
import { PageNodeEnum } from '../../../configs';
type CreateSecretType = {
  cloudId: number;
  region: string;
  onBackButtonClicked: () => void;
  setPageType: Function
}
const CreateSecret = (props: CreateSecretType) => {
  const { cloudId, region, onBackButtonClicked, setPageType } = props;

  const [createAwsSecret, { loading: createAwsSecretLoading }] = createAwsSecretMutation();
  const [rotateAwsSecret, { loading: rotateAwsSecretLoading }] = rotateAwsSecretMutation();
  const [putAwsResourcePolicy, { loading: putAwsResourcePolicyLoading }] = putAwsResourcePolicyMutation();
  const [getAwsValidateResourcePolicy] = lazyGetAwsValidateResourcePolicy();

  const [creationData, setCreationData] = useState<CreationDataType>({
    userName: '',
    secretType: 'rds',
    rds: {},
    ddb: {},
    wh: {},
    otherDB: {},
    otherTS: [
      {
        key: '',
        value: '',
      },
    ],
    password: '',
    keyEncryption: '',
    nameEncryption: '',
    secretName: '',
    description: '',
    isAutoRotation: false,
    scheduleExpression: 'rate(24hours)',
    lambdaRotationFuncValue: '',
    lambdaRotationFuncName: '',
    resourcePolicy: '"{\n    \\"Version\\":\\"2012-10-17\\",\n    \\"Statement\\": []\n\n}\n "',
    hideLambda: false,
    duration: '',
    rotateImmediately: false,
    editPermission: false,
    tags: [],
  });
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const nextRef = useRef<any>();

  const creationApiLoading = useMemo((): boolean => {
    return createAwsSecretLoading || rotateAwsSecretLoading || putAwsResourcePolicyLoading;
  }, [createAwsSecretLoading, rotateAwsSecretLoading, putAwsResourcePolicyLoading]);

  const handleChangeCreationData = (key: string, value: any) => {
    setCreationData((prev: CreationDataType) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const onPreviousButtonClicked = useCallback(() => {
    setCurrentStepIndex(currentStepIndex - 1);
  }, [currentStepIndex]);

  const onNextButtonClicked = useCallback(async () => {
    if (currentStepIndex < CREATE_SECRET_STEPS.length - 1 && (await validateInputs())) {
      setCurrentStepIndex(currentStepIndex + 1);

      return;
    }
  }, [currentStepIndex, creationData]);

  const handleGetAwsValidateResourcePolicy = useCallback( async() => {
    const requestVariable: IAwsValidateResourcePolicyVariables = {
      cloudId: cloudId,
      region: region,
      reqData: {
        resourcePolicy: creationData.resourcePolicy,
      },
    };
    try {
      const result = await getAwsValidateResourcePolicy({ variables: requestVariable });
      const checkValid = result.data?.getAwsValidateResourcePolicy?.data[0].policyValidationPassed;
      if (checkValid) {
        useToast(ErrorCode.SUCCESS, 'Validation policy passed.');
        
        return checkValid;
      }
      else {
        useToast(ErrorCode.UNKNOWN, 'Validation policy failed.');

        return false;
      }
    }
    catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Validation policy failed.');

      return false;
    }
  }, [cloudId, region, creationData,getAwsValidateResourcePolicy]);

  const handleEditPermission = useCallback(async (arn: string) => {
    if (putAwsResourcePolicyLoading) return;
    try {
      const variables: IPutAwsResourcePolicyVariables = {
        cloudId,
        region,
        reqData: {
          secretId: arn,
          resourcePolicy: creationData.resourcePolicy,
        },
      };
      await putAwsResourcePolicy({ variables });
    } catch (error) { /* empty */ }
  }, [cloudId, creationData, putAwsResourcePolicy, putAwsResourcePolicyLoading, region]);

  const handleRotateAwsSecret = useCallback(
    async (values: RotateSecretReqType) => {
      const variables: IRotateAwsSecretVariables = {
        cloudId,
        region,
        reqData: {
          ...values,
        },
      };
      await rotateAwsSecret({ variables });
    },
    [cloudId, region, rotateAwsSecret, creationData],
  );

  const secretReqData = useMemo((): ICreateAwsSecretVariables => {
    const { secretType, keyEncryption, secretName, description, tags } = creationData;
    const { idTable, ...newCreationData } = creationData[secretType];
    const secretString =
      creationData.secretType !== 'otherTS'
        ? JSON.stringify({
          username: creationData.userName,
          password: creationData.password,
          ...newCreationData,
        })
        : JSON.stringify(creationData[secretType].map((data: TagType) => ({ key: data.key, value: data.value })));
    const tagsModify = tags.map((tag: TagType) => ({ key: tag.key, value: tag.value }));

    return {
      cloudId: cloudId,
      region: region,
      reqData: {
        secretString,
        tags: tagsModify,
        description,
        forceOverwriteReplicaSecret: false,
        kmsKeyId: keyEncryption,
        name: secretName,
      },
    };
  }, [creationData, cloudId, region]);

  const onCreateButtonClicked = useCallback(async () => {
    try {
      const dataResult = await createAwsSecret({ variables: secretReqData });
      const secertResult = dataResult?.data?.createAwsSecret?.data[0];
      if (secertResult?.arn) {
        if (creationData.editPermission) {
          await handleEditPermission(secertResult?.arn);
        }
        if (creationData.isAutoRotation) {
          const rotationReq = {
            secretId: secertResult.arn,
            rotationRules: {
              scheduleExpression: creationData.scheduleExpression,
              duration: creationData.duration,
            },
            rotateImmediately: creationData.rotateImmediately,
            rotationLambdaArn: creationData.lambdaRotationFuncValue,
          };
          await handleRotateAwsSecret(rotationReq);
        }
        useToast(ErrorCode.SUCCESS, 'Secret created successfully.');
        setPageType(PageNodeEnum.MGD_LIST);
      } else {
        useToast(ErrorCode.UNKNOWN, 'Create secret failed.');
      }
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Create secret failed.');
    }
  }, [
    createAwsSecret,
    creationData,
    handleEditPermission,
    handleRotateAwsSecret,
    onBackButtonClicked,
    secretReqData,
  ]);

  const onCancelButtonClicked = useCallback(() => {
    onBackButtonClicked();
  }, [onBackButtonClicked]);

  const validateInputs = useCallback(async (): Promise<boolean> => {
    switch (currentStepIndex) {
      default:
      case 0: {
        return nextRef.current?.onSecretTypeVadidate();
      }
      case 1: {
        let checkValidResourcePolicy: boolean = true;
        if (creationData.editPermission) {
          checkValidResourcePolicy = await handleGetAwsValidateResourcePolicy();
        }

        return nextRef.current?.onConfigureSecretVadidate() && checkValidResourcePolicy;
      }
      case 2: {
        return nextRef.current?.onConfigureRotationVadidate();
      }
    }
  }, [creationData.editPermission, currentStepIndex, handleGetAwsValidateResourcePolicy]);

  const stepNode = useMemo(() => {
    switch (currentStepIndex) {
      default:
      case 0: {
        return (
          <SecretTypeChooseStep
            stepTitle={CREATE_SECRET_STEPS[0].title}
            cloudId={cloudId}
            region={region}
            creationData={creationData}
            setCreationData={handleChangeCreationData}
            ref={nextRef}
          />
        );
      }
      case 1: {
        return (
          <ConfigureSecretStep
            stepTitle={CREATE_SECRET_STEPS[1].title}
            creationData={creationData}
            setCreateData={setCreationData}
            handleChangeCreationData={handleChangeCreationData}
            validationResourcePolicy={handleGetAwsValidateResourcePolicy}
            loadingValid={creationApiLoading}
            ref={nextRef}
            cloudId={cloudId}
            region={region}
          />
        );
      }
      case 2: {
        return (
          <ConfigureRotationStep
            stepTitle={CREATE_SECRET_STEPS[2].title}
            creationData={creationData}
            setCreateData={setCreationData}
            handleChangeCreationData={handleChangeCreationData}
            ref={nextRef}
            cloudId={cloudId}
            region={region}
          />
        );
      }
      case 3: {
        return (
          <ReviewStep
            creationData={creationData}
          />
        );
      }
    }
  }, [currentStepIndex, cloudId, region, creationData]);

  const buttonGroupNode = useMemo(() => {
    const isFirstItem = currentStepIndex === 0;

    return (
      <div className="button-group">
        <Button label="Cancel" onClick={onCancelButtonClicked} />
        {!isFirstItem && <Button label="Previous" onClick={onPreviousButtonClicked} />}
        {currentStepIndex === CREATE_SECRET_STEPS.length - 1 ? (
          <Button
            label="Store"
            type={ButtonTypeEnum.PRIMARY}
            onClick={onCreateButtonClicked}
            loading={creationApiLoading}
          />
        ) : (
          <Button label="Next" type={ButtonTypeEnum.PRIMARY} onClick={onNextButtonClicked} />
        )}
      </div>
    );
  }, [
    currentStepIndex,
    onCancelButtonClicked,
    onPreviousButtonClicked,
    onCreateButtonClicked,
    creationApiLoading,
    onNextButtonClicked,
  ]);

  return (
    <div id="create-webacl-page-container">
      <PageTitle
        title={
          currentStepIndex === CREATE_SECRET_STEPS.length - 1 ? 'Create customer manager key: Review' : 'Create secret'
        }
        hasBackButton
        onBackButtonClicked={onBackButtonClicked}
      />
      {stepNode}
      {buttonGroupNode}
    </div>
  );
};

export default CreateSecret;
