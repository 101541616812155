import './index.scss';
import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconEdit from 'assets/svgs/v3/ico_kms_encryption.svg';
import IconUpsideDown from 'assets/svgs/v3/ico_upside_down.svg';
import Icon from 'components/v2/atoms/Icon';
import Divider from 'pages/v2/Organ/Management/components/Divider';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { randomString } from 'utils/Common';
import Space from 'pages/v2/Organ/Management/components/Space';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import lazyGetAwsAllListAliasesPage from 'graphql/queries/getAwsAllListAliases';
import { RowType } from '@Types/v2/Table';
import lazyGetAwsDescribeKey, { IAwsDescribeKeyVariables } from 'graphql/queries/getAwsDescribeKey';

interface IEditEncryptionModalProps extends IBaseModalProps {
  cloudId: number;
  region: string;
  data: string;
  onSubmit: (value: string) => void;
}

const EditEncryptionModal = ({ cloudId, region, data, onSubmit, ...baseModalProps }: IEditEncryptionModalProps) => {
  const [listAliases, setListAliases] = useState<any>([])
  const [alias, setAlias] = useState<any>({name: '', value: ''})
  const [isCreateNew, setIsCreateNew] = useState(false)
  
  const [getAwsAllListAliases] = lazyGetAwsAllListAliasesPage();
  const [getAwsDescribeKey] = lazyGetAwsDescribeKey();

  const fetchAwsAllAwsKeys = async () => {
    const awsAwsKeyRequestVariables = {
      cloudId,
      region,
      request: {
        limit: 1000,
      },
    };
    const combinedVariable = variableCombineNextToken(awsAwsKeyRequestVariables);

    const { data: allListAliases } = await getAwsAllListAliases({ variables: combinedVariable });

    const listAliases = allListAliases?.getAwsAllListAliases?.data ?? [];

    if (listAliases.length > 0) {
      const awsKeyRows: RowType[] = [];
      for (const aliases of listAliases) {
        if (aliases.aliasName.startsWith('alias/aws/') && aliases.targetKeyId) {
          const variable: IAwsDescribeKeyVariables = {
            cloudId,
            region,
            request: {
              keyId: aliases.targetKeyId,
            },
          };
          const { data: keyData } = await getAwsDescribeKey({ variables: variable });

          awsKeyRows.push({
            name: aliases.aliasName,
            value: keyData?.getAwsDescribeKey.data[0]?.keyMetadata?.keyId ?? '-',
          });
        }
      }

      setListAliases(awsKeyRows);
    }
  };

  useEffect(() => {
    fetchAwsAllAwsKeys();
  }, []);

  const handleClose = () => {
    if (baseModalProps?.onClose) baseModalProps.onClose();
  };

  const handleSave = () => {
    onSubmit(alias?.value);
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEdit} />

          <span>Edit encryption key</span>
        </>
      )}
      onClose={handleClose}
      {...baseModalProps}
    >
      <div className="modal-body edit-encryption-modal">
        <Divider />

        <div className="input-container">
          <p className="title-container">Encryption key</p>

          <p>
            You can encrypt using the KMS key that Secrets Manager creates or a customer managed KMS key that you
            create.
          </p>

          <Space size={16} />

          <DropdownAtom
            id={randomString()}
            className=""
            data={listAliases}
            value={alias}
            handleClick={(value) => setAlias(value)}
          />

          <Space size={16} />

          <div className="group-note">
            <Icon width={32} height={32} src={IconUpsideDown} />

            <p className="input-note">
              When you change the encryption key for your secret, Secret manager creates a new version of the secret and
              encrypts it with the new key. Make sure that applications using this secret have permission to decrypt the
              secret with the new key.
            </p>
          </div>
        </div>

        <label className="check-box-group">
          <CheckboxAtom checked={isCreateNew} onchange={() => setIsCreateNew(!isCreateNew)} />

          <p>Create new version of secret with new encryption key.</p>
        </label>

        <Divider />

        <div className="button-group">
          <button onClick={handleClose}>Cancel</button>

          <button className="save-btn" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditEncryptionModal;
