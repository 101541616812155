import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import AccordionNavItem from 'components/v2/AccordionNavItem';
import Header from 'components/v2/Header';
import Icon from 'components/v2/atoms/Icon';
import CreateProjectModal from 'components/v2/modals/CreateProjectModal';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import Favorite from 'assets/svgs/v2/ico_favorite.svg';
import OpenFolder from 'assets/svgs/v2/ico_open_folder.svg';
import RedCircleFolder from 'assets/svgs/v2/ico_redcircle_folder.svg';
import { useAuth } from 'contexts/AuthProvider';
import { OrderDirection } from '@Types/v2/Table';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import addProject, { IAddProjectResponseData, IAddProjectVariables } from 'graphql/mutations/addProject';
import lazyGetProjectPage, { IGetProjectPageVariables } from 'graphql/queries/getProjectPage';
import { Favorites } from '@Types/v2/Lnb';
import AsideLnb from 'components/v2/Aside';
import WeekReportIcon from 'assets/svgs/v2/ico_week_report.svg';
import MonthlyReportIcon from 'assets/images/v4/ico_monthly_report.png';
import EventHistoryIcon from 'assets/svgs/v2/ico_event_history.svg';
import EventSettingIcon from 'assets/svgs/v4/ico_event_admin.svg';
import SearchIcon from 'assets/images/v4/ico_rules.png';
import MembersIcon from 'assets/svgs/v2/ico_members.svg';
import DocsIcon from 'assets/svgs/v4/ico_resources.svg';
import NetworkIcon from 'assets/svgs/v3/ico_network.svg';
import WAFIcon from 'assets/svgs/v3/ico_waf.svg';
import FirewallIcon from 'assets/svgs/v3/ico_firewall.svg';
import KeySecretIcon from 'assets/images/v4/ico_keysnsecret.png';
import InstanceIcon from 'assets/images/v4/ico_instance.png';
import IAMIcon from 'assets/svgs/v3/ico_iam.svg';
import ShieldAdvIcon from 'assets/svgs/v4/ico_shield_advanced.svg';
import GuardDutyIcon from 'assets/svgs/v4/ico_gaurd_duty.svg';
import MacieIcon from 'assets/svgs/v4/ico_macie.svg';
import Chatbot from '../ChatBot';
import ChatBotAsideLnb from '../ChatBotAsideLnb';
import DashboardIcon from 'assets/svgs/v4/ico_dashboard.svg';
import OrganizationIcon from 'assets/svgs/v4/ico_organization.svg';
import CreateUserDashboardModal from 'components/v4/modals/CreateUserDashboard';
import RedCircleGraph from 'assets/svgs/v4/ico_red_circle_graph.svg';
import lazyGetDashboardsByOragnId from 'graphql/queries/getDashboardsByOrganId';
import { DashboardListType } from 'graphql/types/DashboardListType';
import ChatbotAside from 'components/v4/ChatbotAside';

/** organ 페이지 전체 레이아웃 - header, lnb, 프로젝트 생성 모달 */
const OrganLayoutv3 = () => {
  const navigate = useNavigate();
  const { organId } = useParams();
  const { pathname } = useLocation();
  const { userInfo, role, updateToken } = useAuth();

  const [organizationId, setOrganizationId] = useState<number | null>(null);
  const [projectList, setProjectList] = useState<ProjectInfo[]>([]);
  const [favoriteList, setFavoriteList] = useState<{ id: number; name: string }[]>([]);
  const [offset, setOffset] = useState({
    favoriteOffset: 0,
    projectOffset: 0,
    monitoringOffset: 0,
    lgOffset: 0,
  });
  const [modalIsOpen, setModalIsOpen] = useState({
    favorite: false,
    project: false,
    dashboard: false,
  });
  const [defaultRegionList, setDefaultRegionList] = useState<
    { projectId: number; cloudId: number; name: string; value: string }[]
  >([]);
  const [dashboardList, setDashboarList] = useState<DashboardListType[]>([]);
  /* 현조직 내 몇 개의 프로젝트가 있는지 검색 */
  const [lazyGetProjectList] = lazyGetProjectPage();
  const [getDashboardList] = lazyGetDashboardsByOragnId();

  const monitorList = useMemo(() => {
    if (!organizationId) {
      return [];
    } else {
      return [
        {
          text: 'Weekly report',
          link: `/organ/${organId}/weekly-report`,
          icon: WeekReportIcon,
        },
        {
          text: 'Monthly report',
          link: `/organ/${organId}/monthly-report`,
          icon: MonthlyReportIcon,
        },
        {
          text: 'Event history',
          link: `/organ/${organId}/event-history`,
          icon: EventHistoryIcon,
        },
      ];
    }
  }, [organizationId]);

  const managementList = useMemo(() => {
    if (!organizationId) {
      return [];
    } else {
      return [
        {
          text: 'Network',
          link: `/organ/${organId}/manage/network`,
          icon: NetworkIcon,
        },
        {
          text: 'Firewall',
          link: `/organ/${organId}/manage/firewall`,
          icon: FirewallIcon,
        },
        {
          text: 'Key & Secret',
          link: `/organ/${organId}/manage/key-secret`,
          icon: KeySecretIcon,
        },
        {
          text: 'Instance',
          link: `/organ/${organId}/manage/instance`,
          icon: InstanceIcon,
        },
        {
          text: 'IAM',
          link: `/organ/${organId}/manage/iam`,
          icon: IAMIcon,
        },
        {
          text: 'WAF',
          link: `/organ/${organId}/manage/waf`,
          icon: WAFIcon,
        },
        // {
        //   text: 'Shield Advanced',
        //   link: `/organ/${organId}/manage/shield-adv`,
        //   icon: ShieldAdvIcon
        // },
        {
          text: 'Guard Duty',
          link: `/organ/${organId}/manage/guard-duty`,
          icon: GuardDutyIcon,
        },
        {
          text: 'Macie',
          link: `/organ/${organId}/manage/macie`,
          icon: MacieIcon,
        },
      ];
    }
  }, [organizationId]);

  const lgList = useMemo(() => {
    if (!organizationId) {
      return [];
    } else {
      return [
        {
          text: 'Organization',
          link: `/organ/${organizationId}/select-organ`,
          icon: OrganizationIcon,
        },
        {
          text: 'Dashboard',
          link: `/organ/${organizationId}/system/dashboard`,
          icon: DashboardIcon,
        },
        {
          text: 'Events',
          link: `/organ/${organizationId}/system/event`,
          icon: EventSettingIcon,
        },
        {
          text: 'Rules',
          link: `/organ/${organizationId}/system/analysis`,
          icon: SearchIcon,
        },
        {
          text: 'Users',
          link: `/organ/${organizationId}/system/user`,
          icon: MembersIcon,
        },
        {
          text: 'Resources',
          link: `/organ/${organizationId}/system/common-information`,
          icon: DocsIcon,
        },
      ];
    }
  }, [organizationId]);

  const updateProject = (data: ProjectInfo[]) => {
    setProjectList(prev => [...prev, ...data]);
  };

  const openCreatePJModal = () => {
    setModalIsOpen(prev => ({
      ...prev,
      project: true,
    }));
  };

  const editProject = (data: ProjectInfo[]) => {
    const copyList = projectList.map(val => val);
    const findIdx = copyList.findIndex(val => val.projectId === data[0].projectId);
    copyList[findIdx] = { ...data[0] };

    setProjectList(copyList);
  };

  const updateFavoriteList = (arr: { id: number; name: string }[]) => {
    setFavoriteList(arr);
  };

  const deleteProject = (projectId: number) => {
    const getFavorites = localStorage.getItem('favorites');
    const left = projectList.filter(li => li.projectId !== projectId);
    setProjectList(left);

    if (left.length === 0) {
      navigate(`/organ/empty-project`);
    } else {
      if (!getFavorites) {
        navigate(`/organ/${organId}`);
      } else {
        const favorites: Favorites = JSON.parse(getFavorites);
        const findMyOrgan = favorites.find(val => val.organId === organizationId);

        if (findMyOrgan === undefined) return navigate(`/organ/${organId}`);

        const findSub = findMyOrgan.sub.find(val => val.memberId === userInfo?.nemo.memberId);
        if (findSub !== undefined) {
          const leftList = findSub.list.filter(val => val.id !== projectId);
          updateFavoriteList(leftList);

          const find = findSub.list.find(val => val.id === projectId);
          if (find !== undefined) {
            const copyFindMyOrgan = findMyOrgan.sub.findIndex(val => val.memberId === userInfo?.nemo.memberId);
            findMyOrgan.sub[copyFindMyOrgan].list = leftList;
            localStorage.setItem('favorites', JSON.stringify(favorites));
          }
        } else {
          const left = favoriteList.filter(val => val.id !== projectId);
          updateFavoriteList(left);
        }

        navigate(`/organ/${organId}`);
      }
    }
  };

  const toggleFavorite = (id: number, name: string) => {
    const getFavorites = localStorage.getItem('favorites');
    if (!getFavorites || !organizationId) return;

    const favorites: Favorites = JSON.parse(getFavorites);
    const findMyOrgan = favorites.find(val => val.organId === organizationId);

    if (findMyOrgan === undefined) {
      const copyFavorites = favorites.push({
        organId: organizationId,
        sub: [
          {
            memberId: String(userInfo?.nemo.memberId),
            list: [{ id: id, name: name }],
          },
        ],
      });
      localStorage.setItem('favorites', JSON.stringify(copyFavorites));
      updateFavoriteList([...favoriteList, { id: id, name: name }]);
    } else {
      const findSub = findMyOrgan.sub.find(val => val.memberId === userInfo?.nemo.memberId);

      if (findSub === undefined) {
        updateFavoriteList([...favoriteList, { id: id, name: name }]);

        const findIdx = favorites.findIndex(val => val.organId === organizationId);
        favorites[findIdx].sub.push({
          memberId: String(userInfo?.nemo.memberId),
          list: [
            {
              id: id,
              name: name,
            },
          ],
        });
      } else {
        const find = findSub.list.find(val => val.id === id);

        if (find === undefined) {
          updateFavoriteList([...favoriteList, { id: id, name: name }]);

          findSub.list.push({
            id: id,
            name: name,
          });
          localStorage.setItem('favorites', JSON.stringify(favorites));
        } else {
          const leftList = findSub.list.filter(val => val.id !== id);
          updateFavoriteList(leftList);

          const copyFindMyOrgan = findMyOrgan.sub.findIndex(val => val.memberId === userInfo?.nemo.memberId);
          findMyOrgan.sub[copyFindMyOrgan].list = leftList;
          localStorage.setItem('favorites', JSON.stringify(favorites));
        }
      }
    }
  };

  const getMyProjectList = (initGetProjectListData: IGetProjectPageVariables) => {
    lazyGetProjectList({ variables: initGetProjectListData }).then(({ data }) => {
      if (data) {
        if (data.getProjectInfoPage.totalElements === 0) {
          setProjectList([]);
        } else {
          const payloadData = {
            reqData: {
              organIds: [data.getProjectInfoPage.content[0].organId],
              pageInfo: {
                page: 0,
                size: data.getProjectInfoPage.totalElements,
                orders: [
                  {
                    target: 'createdAt',
                    direction: OrderDirection.ASC,
                  },
                ],
              },
            },
          };

          lazyGetProjectList({ variables: payloadData }).then(({ data }) => {
            if (data) {
              if (data.getProjectInfoPage) {
                const result = data.getProjectInfoPage.content.map(d => {
                  const createDate = new Date(d.createdAt);
                  const modifiedDate = new Date(d.modifiedAt);

                  if (userInfo?.nemo.timezone) {
                    createDate.setHours(createDate.getHours() + userInfo?.nemo.timezone);
                    modifiedDate.setHours(modifiedDate.getHours() + userInfo?.nemo.timezone);
                  }

                  return {
                    ...d,
                    createdAt: createDate.toISOString(),
                    modifiedAt: modifiedDate.toISOString(),
                  };
                });

                if (role !== 'sy_admin') {
                  const userInfoProjectsIds = userInfo?.nemo.projects.map(val => val.projectId);
                  const filteredProjects = result.filter(val => userInfoProjectsIds?.includes(val.projectId));
                  setProjectList(filteredProjects);
                } else {
                  setProjectList(result);
                }
              }
            }
          });
        }
      }
    });
  };
  const getOrganDashboardList = () => {
    if (organizationId) {
      getDashboardList({ variables: { organId: organizationId } }).then(res => {
        if (res.data) {
          setDashboarList(res.data.getDashboardsByOrganId.data);
        } else {
          setDashboarList([]);
        }
      });
    }
  };

  const updateDefaultRegionList = (projectId: number, cloudId: number, name: string, value: string) => {
    const hasCloudId = defaultRegionList.some(r => r.projectId === projectId && r.cloudId === cloudId);
    if (hasCloudId) {
      const updatingDefaultRegionList = defaultRegionList.filter(
        region => region.projectId != projectId || region.cloudId != cloudId,
      );
      setDefaultRegionList(updatingDefaultRegionList);
      localStorage.setItem('defaultRegionByCloudId', JSON.stringify(updatingDefaultRegionList));
    } else {
      const defaultRegions = [...defaultRegionList, { projectId, cloudId, name, value }];
      setDefaultRegionList(defaultRegions);
      localStorage.setItem('defaultRegionByCloudId', JSON.stringify(defaultRegions));
    }
  };

  useEffect(() => {
    /* 조직id가 null 이 아닐때만 프로젝트리스트 요청 */
    if (organizationId !== null) {
      const initGetProjectListData = {
        reqData: {
          organIds: [organizationId],
          pageInfo: {
            page: 0,
            size: 1,
            orders: [
              {
                target: 'createdAt',
                direction: OrderDirection.ASC,
              },
            ],
          },
        },
      };
      getMyProjectList(initGetProjectListData);
      getOrganDashboardList();
    }
  }, [organizationId, userInfo, role]);

  useEffect(() => {
    /* 일반사용자일 경우 */
    if (role !== 'sy_admin') {
      if (organId === 'null' || userInfo?.nemo.organId === null) {
        navigate('/join-organ');
      } else {
        if (organId !== String(userInfo?.nemo.organId)) {
          navigate(`/organ/${userInfo?.nemo.organId}/dashboard/overview`);
          setOrganizationId(Number(userInfo?.nemo.organId));
        } else {
          setOrganizationId(Number(userInfo?.nemo.organId));
        }
      }
    } else {
      if (organId === undefined) {
        setOrganizationId(null);
        useToast(ErrorCode.UNKNOWN, '해당 조직이 존재하지 않습니다.');
        navigate(`/organ/${userInfo?.nemo.organId}/dashboard/overview`);
      } else {
        setOrganizationId(Number(organId));
      }
    }
  }, [organId, userInfo, role]);

  useEffect(() => {
    if (!userInfo || !organizationId) return;

    const getFavorites = localStorage.getItem('favorites');
    if (!getFavorites) {
      localStorage.setItem(
        'favorites',
        JSON.stringify([
          {
            organId: organizationId,
            sub: [
              {
                memberId: userInfo.nemo.memberId,
                list: [],
              },
            ],
          },
        ]),
      );
    } else {
      const favorites: Favorites = JSON.parse(getFavorites);
      const findMyOrgan = favorites.find(val => val.organId === organizationId);

      if (findMyOrgan === undefined) {
        favorites.push({
          organId: organizationId,
          sub: [
            {
              memberId: userInfo.nemo.memberId,
              list: [],
            },
          ],
        });

        localStorage.setItem('favorites', JSON.stringify(favorites));
      } else {
        const findSubList = findMyOrgan.sub.find(val => val.memberId === userInfo.nemo.memberId);

        if (findSubList === undefined) {
          findMyOrgan.sub.push({
            memberId: userInfo.nemo.memberId,
            list: [],
          });

          const findIdx = favorites.findIndex(val => val.organId === findMyOrgan.organId);
          favorites[findIdx].sub.push({
            memberId: userInfo.nemo.memberId,
            list: [],
          });

          localStorage.setItem('favorites', JSON.stringify(favorites));
        } else {
          setFavoriteList(findSubList.list);
        }
      }
    }
  }, [projectList, organizationId, userInfo]);

  useEffect(() => {
    const chatBotIsOpen = localStorage.getItem('chatBotOpened');
    if (chatBotIsOpen == null) {
      localStorage.setItem('chatBotOpened', 'false');
    }

    const getDefaultRegions = localStorage.getItem('defaultRegionByCloudId');
    if (getDefaultRegions) {
      const defaultRegions: { projectId: number; cloudId: number; name: string; value: string }[] =
        JSON.parse(getDefaultRegions);
      setDefaultRegionList(defaultRegions);
    }
  }, []);

  return (
    <main id="mgd-layout">
      <Header
        rightType="PROFILE"
        leftType="ORGAN"
        projectList={projectList}
        openCreatePJModal={openCreatePJModal}
        organizationId={organizationId}
      />
      <section>
        <ChatbotAside/>
        <article>
          <Outlet
            context={{ 
              projectList,
              favoriteList,
              toggleFavorite,
              updateFavoriteList,
              updateProject,
              deleteProject,
              editProject,
              openCreatePJModal,
              organizationId,
              defaultRegionList,
              updateDefaultRegionList,
              getOrganDashboardList,
              dashboardList
            }}
          />
        </article>
        <AsideLnb>
          <div>
            <AccordionNavItem
              titleComponent={() => <>Favorites</>}
              offset={offset.favoriteOffset}
              originListLength={favoriteList.length}
              listComponent={() => (
                <>
                  <ul>
                    {favoriteList
                      .slice(0, 10 * (offset.favoriteOffset === 0 ? 1 : offset.favoriteOffset + 1))
                      .map((li, idx) => (
                        <li key={`favorite-index-${idx}`} className={`flex a-center`}>
                          <Link to={`/organ/${organId}/project/${li.id}`}>
                            <Icon width={24} height={24} src={Favorite} />
                            <p>{li.name}</p>
                          </Link>
                        </li>
                      ))}
                  </ul>
                  {favoriteList.length > 10 && (
                    <div className="toggle-btn-wrapper flex j-center a-center">
                      <button
                        className="toggle-btn flex j-center"
                        onClick={() => {
                          if (
                            favoriteList.slice(0, 10 * (offset.favoriteOffset === 0 ? 1 : offset.favoriteOffset + 1))
                              .length === favoriteList.length
                          ) {
                            setOffset(prev => ({
                              ...prev,
                              favoriteOffset: 0,
                            }));
                          } else {
                            setOffset(prev => ({
                              ...prev,
                              favoriteOffset: offset.favoriteOffset + 1,
                            }));
                          }
                        }}
                      >
                        {favoriteList.slice(0, 10 * (offset.favoriteOffset === 0 ? 1 : offset.favoriteOffset + 1))
                          .length === favoriteList.length
                          ? 'Collapse'
                          : 'See more'}
                      </button>
                    </div>
                  )}
                </>
              )}
            />
            {favoriteList.length === 0 && (
              <button
                className="add-indicator favorite-btn flex a-center"
                onClick={() =>
                  setModalIsOpen(prev => ({
                    ...prev,
                    favorite: true,
                  }))
                }
              >
                Add new favorites
              </button>
            )}
          </div>
          <div>
            <AccordionNavItem
              titleComponent={() => (
                <>
                  Dashboard
                  {role === 'sy_admin' && (
                    <button
                      className="add-project-btn"
                      onClick={e => {
                        e.stopPropagation();
                        setModalIsOpen(prev => ({
                          ...prev,
                          dashboard: true,
                        }));
                      }}
                    />
                  )}
                </>
              )}
              originListLength={dashboardList.length + 1}
              offset={offset.lgOffset}
              listComponent={() => (
                <ul>
                  <li className={`flex a-center ${pathname === `/organ/${organId}/dashboard/overview` && 'select'}`}>
                    <Link to={`/organ/${organId}/dashboard/overview`}>
                      <Icon width={24} height={24} src={DashboardIcon} />
                      <p>Overview</p>
                    </Link>
                  </li>
                  {dashboardList.map(dashboard => (
                    <li
                      key={dashboard.id}
                      className={`flex a-center ${
                        pathname === `/organ/${organId}/dashboard/${dashboard.id}` && 'select'
                      }`}
                    >
                      <Link to={`/organ/${organId}/dashboard/${dashboard.id}`}>
                        <Icon width={24} height={24} src={DashboardIcon} />
                        <p>{dashboard.name}</p>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            />
          </div>
          <div>
            <AccordionNavItem
              titleComponent={() => (
                <>
                  Projects {projectList.length !== 0 && projectList.length}
                  {projectList.length > 0 && userInfo?.nemo.ownership && (
                    <button
                      className="add-project-btn"
                      onClick={e => {
                        e.stopPropagation();
                        setModalIsOpen(prev => ({
                          ...prev,
                          project: true,
                        }));
                      }}
                    />
                  )}
                </>
              )}
              offset={offset.projectOffset}
              originListLength={projectList.length}
              listComponent={() => (
                <>
                  <ul>
                    {projectList
                      .slice(0, 10 * (offset.projectOffset === 0 ? 1 : offset.projectOffset + 1))
                      .map((li, idx) => (
                        <li
                          key={`project-index-${idx}`}
                          className={`flex a-center ${
                            pathname === `/organ/${organId}/project/${li.projectId}` && 'select'
                          }`}
                        >
                          <Link to={`/organ/${organId}/project/${li.projectId}`}>
                            <Icon width={24} height={24} src={OpenFolder} />
                            <p>{li.name}</p>
                          </Link>
                        </li>
                      ))}
                  </ul>
                  {projectList.length > 10 && (
                    <div className="toggle-btn-wrapper flex j-center a-center">
                      <button
                        className="toggle-btn flex j-center"
                        onClick={() => {
                          if (
                            projectList.slice(0, 10 * (offset.projectOffset === 0 ? 1 : offset.projectOffset + 1))
                              .length === projectList.length
                          ) {
                            setOffset(prev => ({
                              ...prev,
                              projectOffset: 0,
                            }));
                            if (organizationId) {
                              const data = {
                                reqData: {
                                  organIds: [organizationId],
                                  pageInfo: {
                                    page: 0,
                                    size: 99,
                                    orders: [
                                      {
                                        target: 'createdAt',
                                        direction: OrderDirection.ASC,
                                      },
                                    ],
                                  },
                                },
                              };
                              getMyProjectList(data);
                            }
                          } else {
                            setOffset(prev => ({
                              ...prev,
                              projectOffset: offset.projectOffset + 1,
                            }));
                          }
                        }}
                      >
                        {projectList.slice(0, 10 * (offset.projectOffset === 0 ? 1 : offset.projectOffset + 1))
                          .length === projectList.length
                          ? '접기'
                          : '더보기'}
                      </button>
                    </div>
                  )}
                </>
              )}
            />
            {projectList.length === 0 && (
              <button
                className="add-indicator flex a-center j-between"
                onClick={() => {
                  navigate(`/organ/${organizationId}/empty-project`);
                }}
              >
                Add new project
                <span
                  className="add-project-btn"
                  onClick={e => {
                    e.stopPropagation();
                    setModalIsOpen(prev => ({
                      ...prev,
                      project: true,
                    }));
                  }}
                />
              </button>
            )}
          </div>
          <div>
            <AccordionNavItem
              titleComponent={() => <>Monitoring</>}
              originListLength={monitorList.length}
              offset={offset.monitoringOffset}
              listComponent={() => (
                <ul>
                  {monitorList.map((li, idx) => (
                    <li key={`monitoring-index-${idx}`} className={`flex a-center ${pathname === li.link && 'select'}`}>
                      <Link to={li.link}>
                        <Icon width={24} height={24} src={li.icon as string} />
                        <p>{li.text}</p>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            />
          </div>
          <div>
            <AccordionNavItem
              titleComponent={() => <>Management</>}
              originListLength={managementList.length}
              offset={offset.monitoringOffset}
              listComponent={() => (
                <ul>
                  {managementList.map((li, idx) => (
                    <li key={`monitoring-index-${idx}`} className={`flex a-center ${pathname === li.link && 'select'}`}>
                      <Link to={li.link}>
                        <Icon width={24} height={24} src={li.icon as string} />
                        <p>{li.text}</p>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            />
          </div>
          {role === 'sy_admin' && (
            <div>
              <AccordionNavItem
                titleComponent={() => <>System</>}
                originListLength={lgList.length}
                offset={offset.lgOffset}
                listComponent={() => (
                  <ul>
                    {lgList.map((li, idx) => (
                      <li
                        key={`lg-operating-index-${idx}`}
                        className={`flex a-center ${pathname === li.link && 'select'}`}
                      >
                        <Link to={li.link}>
                          <Icon width={24} height={24} src={li.icon as string} />
                          <p>{li.text}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              />
            </div>
          )}
        </AsideLnb>
      </section>

      {/* 프로젝트 생성 모달 */}
      <CreateProjectModal
        className="create-project-modal-wrap"
        open={modalIsOpen.project}
        title={() => (
          <>
            <Icon width={32} height={32} src={RedCircleFolder} />
            Create project
          </>
        )}
        onClose={() =>
          setModalIsOpen(prev => ({
            ...prev,
            project: false,
          }))
        }
        onCreate={(data: IAddProjectResponseData) => {
          updateProject(data.addProject.data);
          useToast(ErrorCode.SUCCESS, 'Project is created successfully.');
          setModalIsOpen(prev => ({
            ...prev,
            project: false,
          }));
          updateToken(data.addProject.data[0].userToken);

          const splitPathname = pathname.split('/');
          if (splitPathname[splitPathname.length - 1] === 'empty-project') {
            navigate(`/organ/${data.addProject.data[0].organId}/project/${data.addProject.data[0].projectId}`);
          }
        }}
      />
      {/* dashboard 생성 모달 */}
      <CreateUserDashboardModal
        className="create-user-modal-wrap"
        open={modalIsOpen.dashboard}
        organId={organId}
        title={() => (
          <>
            <Icon width={32} height={32} src={RedCircleGraph} />
            Add dashboard
          </>
        )}
        onClose={() =>
          setModalIsOpen(prev => ({
            ...prev,
            dashboard: false,
          }))
        }
        onConfirm={() => {
          // gql success 일 경우 들어옴
          // aside 에 있는 dashboard list 에 추가
          setModalIsOpen(prev => ({
            ...prev,
            dashboard: false,
          }));
          getOrganDashboardList();
        }}
      />
    </main>
  );
};
export default OrganLayoutv3;
