import StepContainer from 'pages/v2/Organ/Management/WAF/WebACL/CreateWebACL/components/StepContainer';
import { AddLablePropsType, CreationDataPropsType } from './types';
import SectionContainer from 'pages/v2/Organ/Management/components/SectionContainer';
import Input from 'pages/v2/Organ/Management/components/Input';
import InputGroup from 'pages/v2/Organ/Management/components/InputGroup';
import { useCallback, useState } from 'react';
import './index.scss';
import TagInput from 'pages/v2/Organ/Management/components/TagInput';
import { TagRowData } from 'pages/v2/Organ/Management/components/types';

const AddLabel = (props: AddLablePropsType) => {
  const { title, creationData, updateCreationData } = props;

  const [aliasName, setAliasName] = useState<string>(creationData.aliasName);
  const [description, setDescription] = useState<string>(creationData?.description ?? '');
  const [tags, setTags] = useState<TagRowData[]>(creationData?.tags ?? []);

  const onAliasChange = useCallback((value: string) => {
    setAliasName(value);
    let aliasNameError;
    if (value === '') {
      aliasNameError = 'A key alias is required.';
    }
    const updatedCreationData: CreationDataPropsType = { ...creationData, aliasName: value, aliasNameError: aliasNameError };
    updateCreationData(updatedCreationData);
  }, [creationData]);

  const onDescriptionChange = useCallback((value: string) => {
    setDescription(value);
    const updatedCreationData: CreationDataPropsType = { ...creationData, description: value };
    updateCreationData(updatedCreationData);
  }, [creationData]);

  const onTagsChange = useCallback((curentTags: TagRowData[]) => {
    setTags(curentTags);
    const updatedCreationData: CreationDataPropsType = { ...creationData, tags: curentTags };
    updateCreationData(updatedCreationData);
  }, [creationData]);

  return (
    <StepContainer title={title}>
      <SectionContainer title="Alias" description="You can change the alias at any time">
        <InputGroup>
          <Input
            title={'Alias'}
            placeholder="Display name for the key"
            value={aliasName}
            onValueChanged={onAliasChange}
            error={creationData?.aliasNameError}
          />
        </InputGroup>
      </SectionContainer>

      <SectionContainer title="Description" caption="optional" description="You can change the description at any time.">
        <InputGroup>
          <Input
            title={'Description'}
            placeholder="Description of the key"
            value={description}
            onValueChanged={onDescriptionChange}
          />
        </InputGroup>
      </SectionContainer>

      <SectionContainer title="Tags" caption="optional">
        <InputGroup>
          <p className="tags-description">You can use tags to categorize and identify your KMS keys and help you track your AWS costs. When you add tags to AWS resources, AWS generates a cost allocation report for each tag.</p>
          <TagInput currentTags={tags} onChange={(currentTags) => onTagsChange(currentTags)} />
        </InputGroup>
      </SectionContainer>
    </StepContainer>
  );
};

export default AddLabel;
