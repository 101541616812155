import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsUpdateRuleGroupResponse } from 'graphql/types/AwsUpdateRuleGroupResponse';
import { AwsRulelogType } from 'graphql/types/AwsUpdateRuleGroup';

export interface AwsRegexLog {
  regexString: string;
}

export interface AwsVisibilityConfiglog {
  sampledRequestsEnabled: Boolean;
  cloudWatchMetricsEnabled: Boolean;
  metricName: String;
}

export interface IUpdateRuleGroupVariables {
  cloudId: number;
  region: string;
  reqData: {
    name: string;
    scope: string;
    id: string;
    lockToken?: string;
    description?: string;
    rules?: AwsRulelogType[];
    visibilityConfig?: AwsVisibilityConfiglog;
    customResponseBodies?: any;
    apiAction?: string;
  };
}

export interface IUpdateRuleGroupResponseData {
  updateAwsRuleGroup: IGqlResponseNewData<AwsUpdateRuleGroupResponse>;
}

const updateAwsRuleGroupMutation = () => useMutation<IUpdateRuleGroupResponseData, IUpdateRuleGroupVariables>(query);
export default updateAwsRuleGroupMutation;
