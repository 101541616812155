import { RowType } from '@Types/v2/Table';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';

export const CREATE_RULE_GROUP_STEPS = [
  {
    id: 'describe-rule-group',
    title: 'Step 1. Describe rule group',
    subTitle:
      'Name and describe your firewall policy so you can easily identify it and distinguish it from other resources.',
  },
  {
    id: 'choose-rule-group-type',
    title: 'Step 2. Choose rule group type',
    subTitle:
      'Network Firewall rule groups are either stateless or stateful. Stateless rule groups evaluate packets in isolation, while stateful rule groups evaluate them in the context of their traffic flow.',
  },
  {
    id: 'configure-rules',
    title: 'Step 3. Configure rules',
    subTitle:
      'An AWS Network Firewall rule group is a reusable set of criteria for inspecting and handling network traffic.',
  },
  {
    id: 'configure-advanced-settings',
    title: 'Step 4. Configure advanced settings - optional',
    subTitle:
      'Configure a customer managed AWS Key Management Service (KMS) key to encrypt and decrypt your resources.',
  },
  {
    id: 'add-tags',
    title: 'Step 5. Add tags - optional',
    subTitle:
      'A tag is a label that you assign to an AWS resource. You can use tags to search and filter your resources or track your AWS costs.',
  },
  {
    id: 'review-and-create',
    title: 'Review and create',
    subTitle: '',
  },
];

export const ruleGroupFormatData: DropdownListDataType[] = [
  {
    id: 1,
    name: 'Standard stateful rule',
    description: 'Specify the source and destination IP addresses and ports, protocol, and other rule options.',
    value: 'standard-stateful-rule',
  },
  {
    id: 2,
    name: 'Domain list',
    description:
      'Specify a list of domain names and the action to take for traffic that tries to access one of the domains.',
    value: 'domain-list',
  },
  {
    id: 3,
    name: 'Suricata compatible rule string',
    description:
      'Provide advanced firewall rules using Suricata rule syntax. Suricata is an open source threat detection engine that includes a standard rule-based language.',
    value: 'suricata-compatible-rule-string',
  },
];

export const ruleRowsData: RowType[] = [
  {
    id: 1,
    selection: '',
    isCheck: true,
    protocol: 'TCP',
    source: 'ANY',
    destination: 'ANY',
    sourcePort: 'ANY',
    destinationPort: 'ANY',
    direction: 'Forward',
    action: 'Pass',
  },
  {
    id: 2,
    selection: '',
    isCheck: false,
    protocol: 'TCP',
    source: 'ANY',
    destination: 'ANY',
    sourcePort: 'ANY',
    destinationPort: 'ANY',
    direction: 'Forward',
    action: 'Pass',
  },
];

// export const ruleRowsDataDomainList: RowType[] = [
//   {
//     id: 1,
//     priority: 1,
//     protocol: 'TCP/CPT',
//     source: 'ANY',
//     destination: 'ANY',
//     sourcePort: 'ANY',
//     destinationPort: 'ANY',
//   },
//   {
//     id: 2,
//     priority: 2,
//     protocol: 'ALL/CPT',
//     source: 'ANY',
//     destination: 'ANY',
//     sourcePort: 'ANY',
//     destinationPort: 'ANY',
//   },
// ];

export const listProtocols: DropdownListDataType[] = [
  {
    id: 1,
    name: 'All',
    value: 'All',
    description: 'All protocols',
  },
  {
    id: 2,
    name: 'HOPOPT',
    value: 'HOPOPT',
    description: 'Protocol: 0',
  },
  {
    id: 3,
    name: 'ICMP',
    value: 'ICMP',
    description: 'Protocol: 1',
  },
  {
    id: 4,
    name: 'IGMP',
    value: 'IGMP',
    description: 'Protocol: 2',
  },
  {
    id: 5,
    name: 'GGP',
    value: 'GGP',
    description: 'Protocol: 3',
  },
  {
    id: 6,
    name: 'IP-in-IP',
    value: 'IP-in-IP',
    description: 'Protocol: 4',
  },
  {
    id: 7,
    name: 'ST',
    value: 'ST',
    description: 'Protocol: 5',
  },
  {
    id: 8,
    name: 'TCP',
    value: 'TCP',
    description: 'Protocol: 6',
  },
  {
    id: 9,
    name: 'CBT',
    value: 'CBT',
    description: 'Protocol: 7',
  },
  {
    id: 10,
    name: 'EGP',
    value: 'EGP',
    description: 'Protocol: 8',
  },
  {
    id: 11,
    name: 'IGP',
    value: 'IGP',
    description: 'Protocol: 9',
  },
  {
    id: 12,
    name: 'BBN-RCC-MON',
    value: 'BBN-RCC-MON',
    description: 'Protocol: 10',
  },
  {
    id: 13,
    name: 'NVP-II',
    value: 'NVP-II',
    description: 'Protocol: 11',
  },
  {
    id: 14,
    name: 'PUP',
    value: 'PUP',
    description: 'Protocol: 12',
  },
  {
    id: 15,
    name: 'ARGUS',
    value: 'ARGUS',
    description: 'Protocol: 13',
  },
  {
    id: 16,
    name: 'EMCON',
    value: 'EMCON',
    description: 'Protocol: 14',
  },
  {
    id: 17,
    name: 'XNET',
    value: 'XNET',
    description: 'Protocol: 15',
  },
  {
    id: 18,
    name: 'CHAOS',
    value: 'CHAOS',
    description: 'Protocol: 16',
  },
  {
    id: 19,
    name: 'UDP',
    value: 'UDP',
    description: 'Protocol: 17',
  },
  {
    id: 20,
    name: 'MUX',
    value: 'MUX',
    description: 'Protocol: 18',
  },
  {
    id: 21,
    name: 'DCN-MEAS',
    value: 'DCN-MEAS',
    description: 'Protocol: 19',
  },
  {
    id: 22,
    name: 'HMP',
    value: 'HMP',
    description: 'Protocol: 20',
  },
  {
    id: 23,
    name: 'PRM',
    value: 'PRM',
    description: 'Protocol: 21',
  },
  {
    id: 24,
    name: 'XNS',
    value: 'XNS',
    description: 'Protocol: 22',
  },
  {
    id: 25,
    name: 'TRUNK-1',
    value: 'TRUNK-1',
    description: 'Protocol: 23',
  },
  {
    id: 26,
    name: 'TRUNK-2',
    value: 'TRUNK-2',
    description: 'Protocol: 24',
  },
  {
    id: 27,
    name: 'LEAF-1',
    value: 'LEAF-1',
    description: 'Protocol: 25',
  },
  {
    id: 28,
    name: 'LEAF-2',
    value: 'LEAF-2',
    description: 'Protocol: 26',
  },
  {
    id: 29,
    name: 'RDP',
    value: 'RDP',
    description: 'Protocol: 27',
  },
  {
    id: 30,
    name: 'IRTP',
    value: 'IRTP',
    description: 'Protocol: 28',
  },
  {
    id: 31,
    name: 'ISO-TP4',
    value: 'ISO-TP4',
    description: 'Protocol: 29',
  },
  {
    id: 32,
    name: 'NETBLT',
    value: 'NETBLT',
    description: 'Protocol: 30',
  },
  {
    id: 33,
    name: 'MFE-NSP',
    value: 'MFE-NSP',
    description: 'Protocol: 31',
  },
  {
    id: 34,
    name: 'MERIT-INP',
    value: 'MERIT-INP',
    description: 'Protocol: 32',
  },
  {
    id: 35,
    name: 'DCCP',
    value: 'DCCP',
    description: 'Protocol: 33',
  },
  {
    id: 36,
    name: '3PC',
    value: '3PC',
    description: 'Protocol: 34',
  },
  {
    id: 37,
    name: 'IDPR',
    value: 'IDPR',
    description: 'Protocol: 35',
  },
  {
    id: 38,
    name: 'XTP',
    value: 'XTP',
    description: 'Protocol: 36',
  },
  {
    id: 39,
    name: 'DDP',
    value: 'DDP',
    description: 'Protocol: 37',
  },
  {
    id: 40,
    name: 'IDPR-CMTP',
    value: 'IDPR-CMTP',
    description: 'Protocol: 38',
  },
  {
    id: 41,
    name: 'TP++',
    value: 'TP++',
    description: 'Protocol: 39',
  },
  {
    id: 42,
    name: 'IL',
    value: 'IL',
    description: 'Protocol: 40',
  },
  {
    id: 43,
    name: 'IPv6',
    value: 'IPv6',
    description: 'Protocol: 41',
  },
  {
    id: 44,
    name: 'SDRP',
    value: 'SDRP',
    description: 'Protocol: 42',
  },
  {
    id: 45,
    name: 'IPv6-Route',
    value: 'IPv6-Route',
    description: 'Protocol: 43',
  },
  {
    id: 46,
    name: 'IPv6-Frag',
    value: 'IPv6-Frag',
    description: 'Protocol: 44',
  },
  {
    id: 47,
    name: 'IDRP',
    value: 'IDRP',
    description: 'Protocol: 45',
  },
  {
    id: 48,
    name: 'RSVP',
    value: 'RSVP',
    description: 'Protocol: 46',
  },
  {
    id: 49,
    name: 'GREs',
    value: 'GREs',
    description: 'Protocol: 47',
  },
  {
    id: 50,
    name: 'DSR',
    value: 'DSR',
    description: 'Protocol: 48',
  },
  {
    id: 51,
    name: 'BNA',
    value: 'BNA',
    description: 'Protocol: 49',
  },
  {
    id: 52,
    name: 'DSP',
    value: 'DSP',
    description: 'Protocol: 50',
  },
  {
    id: 53,
    name: 'AH',
    value: 'AH',
    description: 'Protocol: 51',
  },
  {
    id: 54,
    name: 'I-NLSP',
    value: 'I-NLSP',
    description: 'Protocol: 52',
  },
  {
    id: 55,
    name: 'SwIPe',
    value: 'SwIPe',
    description: 'Protocol: 53',
  },
  {
    id: 56,
    name: 'NARP',
    value: 'NARP',
    description: 'Protocol: 54',
  },
  {
    id: 57,
    name: 'MOBILE',
    value: 'MOBILE',
    description: 'Protocol: 55',
  },
  {
    id: 58,
    name: 'TLSP',
    value: 'TLSP',
    description: 'Protocol: 56',
  },
  {
    id: 59,
    name: 'SKIP',
    value: 'SKIP',
    description: 'Protocol: 57',
  },
  {
    id: 60,
    name: 'IPv6-ICMP',
    value: 'IPv6-ICMP',
    description: 'Protocol: 58',
  },
  {
    id: 61,
    name: 'IPv6-NoNxt',
    value: 'IPv6-NoNxt',
    description: 'Protocol: 59',
  },
  {
    id: 62,
    name: 'IPv6-Opts',
    value: 'IPv6-Opts',
    description: 'Protocol: 60',
  },
  {
    id: 63,
    name: 'Any host internal protocol',
    value: 'Any host internal protocol',
    description: 'Protocol: 61',
  },
  {
    id: 64,
    name: 'CFTP',
    value: 'CFTP',
    description: 'Protocol: 62',
  },
  {
    id: 65,
    name: 'Any local network',
    value: 'Any local network',
    description: 'Protocol: 63',
  },
  {
    id: 66,
    name: 'SAT-EXPAK',
    value: 'SAT-EXPAK',
    description: 'Protocol: 64',
  },
  {
    id: 67,
    name: 'KRYPTOLAN',
    value: 'KRYPTOLAN',
    description: 'Protocol: 65',
  },
  {
    id: 68,
    name: 'RVD',
    value: 'RVD',
    description: 'Protocol: 66',
  },
  {
    id: 69,
    name: 'IPPC',
    value: 'IPPC',
    description: 'Protocol: 67',
  },
  {
    id: 70,
    name: 'Any distributed file system',
    value: 'Any distributed file system',
    description: 'Protocol: 68',
  },
  {
    id: 71,
    name: 'SAT-MON',
    value: 'SAT-MON',
    description: 'Protocol: 69',
  },
  {
    id: 72,
    name: 'VISA',
    value: 'VISA',
    description: 'Protocol: 70',
  },
  {
    id: 73,
    name: 'IPCU',
    value: 'IPCU',
    description: 'Protocol: 71',
  },
  {
    id: 74,
    name: 'CPNX',
    value: 'CPNX',
    description: 'Protocol: 72',
  },
  {
    id: 75,
    name: 'CPHB',
    value: 'CPHB',
    description: 'Protocol: 73',
  },
  {
    id: 76,
    name: 'WSN',
    value: 'WSN',
    description: 'Protocol: 74',
  },
  {
    id: 77,
    name: 'PVP',
    value: 'PVP',
    description: 'Protocol: 75',
  },
  {
    id: 78,
    name: 'BR-SAT-MON',
    value: 'BR-SAT-MON',
    description: 'Protocol: 76',
  },
  {
    id: 79,
    name: 'SUN-ND',
    value: 'SUN-ND',
    description: 'Protocol: 77',
  },
  {
    id: 80,
    name: 'WB-MON',
    value: 'WB-MON',
    description: 'Protocol: 78',
  },
  {
    id: 81,
    name: 'WB-EXPAK',
    value: 'WB-EXPAK',
    description: 'Protocol: 79',
  },
  {
    id: 82,
    name: 'ISO-IP',
    value: 'ISO-IP',
    description: 'Protocol: 80',
  },
  {
    id: 83,
    name: 'VMTP',
    value: 'VMTP',
    description: 'Protocol: 81',
  },
  {
    id: 84,
    name: 'SECURE-VMTP',
    value: 'SECURE-VMTP',
    description: 'Protocol: 82',
  },
  {
    id: 85,
    name: 'VINES',
    value: 'VINES',
    description: 'Protocol: 83',
  },
  {
    id: 86,
    name: 'IPTM',
    value: 'IPTM',
    description: 'Protocol: 84',
  },
  {
    id: 87,
    name: 'NSFNET-IGP',
    value: 'NSFNET-IGP',
    description: 'Protocol: 85',
  },
  {
    id: 88,
    name: 'DGP',
    value: 'DGP',
    description: 'Protocol: 86',
  },
  {
    id: 89,
    name: 'TCF',
    value: 'TCF',
    description: 'Protocol: 87',
  },
  {
    id: 90,
    name: 'EIGRP',
    value: 'EIGRP',
    description: 'Protocol: 88',
  },
  {
    id: 91,
    name: 'OSPF',
    value: 'OSPF',
    description: 'Protocol: 89',
  },
  {
    id: 92,
    name: 'Sprite-RPC',
    value: 'Sprite-RPC',
    description: 'Protocol: 90',
  },
  {
    id: 93,
    name: 'LARP',
    value: 'LARP',
    description: 'Protocol: 91',
  },
  {
    id: 94,
    name: 'MTP',
    value: 'MTP',
    description: 'Protocol: 92',
  },
  {
    id: 95,
    name: 'AX.25',
    value: 'AX.25',
    description: 'Protocol: 93',
  },
  {
    id: 96,
    name: 'OS',
    value: 'OS',
    description: 'Protocol: 94',
  },
  {
    id: 97,
    name: 'MICP',
    value: 'MICP',
    description: 'Protocol: 95',
  },
  {
    id: 98,
    name: 'SCC-SP',
    value: 'SCC-SP',
    description: 'Protocol: 96',
  },
  {
    id: 99,
    name: 'ETHERIP',
    value: 'ETHERIP',
    description: 'Protocol: 97',
  },
  {
    id: 100,
    name: 'ENCAP',
    value: 'ENCAP',
    description: 'Protocol: 98',
  },
  {
    id: 101,
    name: 'Any private encryption scheme',
    value: 'Any private encryption scheme',
    description: 'Protocol: 99',
  },
  {
    id: 102,
    name: 'GMTP',
    value: 'GMTP',
    description: 'Protocol: 100',
  },
  {
    id: 103,
    name: 'IFMP',
    value: 'IFMP',
    description: 'Protocol: 101',
  },
  {
    id: 104,
    name: 'PNNI',
    value: 'PNNI',
    description: 'Protocol: 102',
  },
  {
    id: 105,
    name: 'PIM',
    value: 'PIM',
    description: 'Protocol: 103',
  },
  {
    id: 106,
    name: 'ARIS',
    value: 'ARIS',
    description: 'Protocol: 104',
  },
  {
    id: 107,
    name: 'SCPS',
    value: 'SCPS',
    description: 'Protocol: 105',
  },
  {
    id: 108,
    name: 'QNX',
    value: 'QNX',
    description: 'Protocol: 106',
  },
  {
    id: 109,
    name: 'A/N',
    value: 'A/N',
    description: 'Protocol: 107',
  },
  {
    id: 110,
    name: 'IPComp',
    value: 'IPComp',
    description: 'Protocol: 108',
  },
  {
    id: 111,
    name: 'SNP',
    value: 'SNP',
    description: 'Protocol: 109',
  },
  {
    id: 112,
    name: 'Compaq-Peer',
    value: 'Compaq-Peer',
    description: 'Protocol: 110',
  },
  {
    id: 113,
    name: 'IPX-in-IP',
    value: 'IPX-in-IP',
    description: 'Protocol: 111',
  },
  {
    id: 114,
    name: 'VRRP',
    value: 'VRRP',
    description: 'Protocol: 112',
  },
  {
    id: 115,
    name: 'PGM',
    value: 'PGM',
    description: 'Protocol: 113',
  },
  {
    id: 116,
    name: 'Any 0-hop protocol',
    value: 'Any 0-hop protocol',
    description: 'Protocol: 114',
  },
  {
    id: 117,
    name: 'L2TP',
    value: 'L2TP',
    description: 'Protocol: 115',
  },
  {
    id: 118,
    name: 'DDX',
    value: 'DDX',
    description: 'Protocol: 116',
  },
  {
    id: 119,
    name: 'IATP',
    value: 'IATP',
    description: 'Protocol: 117',
  },
  {
    id: 120,
    name: 'STP',
    value: 'STP',
    description: 'Protocol: 118',
  },
  {
    id: 121,
    name: 'SRP',
    value: 'SRP',
    description: 'Protocol: 119',
  },
  {
    id: 122,
    name: 'UTI',
    value: 'UTI',
    description: 'Protocol: 120',
  },
  {
    id: 123,
    name: 'SMP',
    value: 'SMP',
    description: 'Protocol: 121',
  },
  {
    id: 124,
    name: 'SM',
    value: 'SM',
    description: 'Protocol: 122',
  },
  {
    id: 125,
    name: 'PTP',
    value: 'PTP',
    description: 'Protocol: 123',
  },
  {
    id: 126,
    name: 'IS-IS over IPv4',
    value: 'IS-IS over IPv4',
    description: 'Protocol: 124',
  },
  {
    id: 127,
    name: 'FIRE',
    value: 'FIRE',
    description: 'Protocol: 125',
  },
  {
    id: 128,
    name: 'CRTP',
    value: 'CRTP',
    description: 'Protocol: 126',
  },
  {
    id: 129,
    name: 'CRUDP',
    value: 'CRUDP',
    description: 'Protocol: 127',
  },
  {
    id: 130,
    name: 'SSCOPMCE',
    value: 'SSCOPMCE',
    description: 'Protocol: 128',
  },
  {
    id: 131,
    name: 'IPLT',
    value: 'IPLT',
    description: 'Protocol: 129',
  },
  {
    id: 132,
    name: 'SPS',
    value: 'SPS',
    description: 'Protocol: 130',
  },
  {
    id: 133,
    name: 'PIPE',
    value: 'PIPE',
    description: 'Protocol: 131',
  },
  {
    id: 134,
    name: 'SCTP',
    value: 'SCTP',
    description: 'Protocol: 132',
  },
  {
    id: 135,
    name: 'FC',
    value: 'FC',
    description: 'Protocol: 133',
  },
  {
    id: 136,
    name: 'RSVP-E2e-IGNORE',
    value: 'RSVP-E2e-IGNORE',
    description: 'Protocol: 134',
  },
  {
    id: 137,
    name: 'Mobility Header',
    value: 'Mobility Header',
    description: 'Protocol: 135',
  },
  {
    id: 138,
    name: 'UDPLite',
    value: 'UDPLite',
    description: 'Protocol: 136',
  },
  {
    id: 139,
    name: 'MPLS-in-IP',
    value: 'MPLS-in-IP',
    description: 'Protocol: 137',
  },
  {
    id: 140,
    name: 'manet',
    value: 'manet',
    description: 'Protocol: 138',
  },
  {
    id: 141,
    name: 'HIP',
    value: 'HIP',
    description: 'Protocol: 139',
  },
  {
    id: 142,
    name: 'Shim6',
    value: 'Shim6',
    description: 'Protocol: 140',
  },
  {
    id: 143,
    name: 'WESP',
    value: 'WESP',
    description: 'Protocol: 141',
  },
  {
    id: 144,
    name: 'ROHC',
    value: 'WESP',
    description: 'Protocol: 142',
  },
];

export const listSourceOptions: DropdownListDataType[] = [
  {
    id: 1,
    name: 'Custom',
    value: '',
  },
  {
    id: 2,
    name: 'Any IPv4 address',
    value: '0.0.0.0/0',
  },
  {
    id: 3,
    name: 'Any IPv6 address',
    value: '::/0',
  },
];

export const listPortOptions: DropdownListDataType[] = [
  {
    id: 1,
    name: 'Custom',
    value: '',
  },
  {
    id: 2,
    name: 'Any Port',
    value: '0:65535',
  },
];

export const listMasksFlags: DropdownListDataType[] = [
  {
    id: 1,
    name: 'ACK',
    value: 'ACK',
  },
  {
    id: 2,
    name: 'SYN',
    value: 'SYN',
  },
  {
    id: 3,
    name: 'FIN',
    value: 'FIN',
  },
  {
    id: 4,
    name: 'URG',
    value: 'URG',
  },
  {
    id: 5,
    name: 'PSH',
    value: 'PSH',
  },
  {
    id: 6,
    name: 'ECE',
    value: 'ECE',
  },
  {
    id: 7,
    name: 'CWR',
    value: 'CWR',
  },
  {
    id: 8,
    name: 'RST',
    value: 'RST',
  },
];

export const listProtocolData: DropdownListDataType[] = [
  { id: 1, name: 'IP', value: 'IP' },
  { id: 2, name: 'TCP', value: 'TCP' },
  { id: 3, name: 'UDP', value: 'UDP' },
  { id: 4, name: 'ICMP', value: 'ICMP' },
  { id: 5, name: 'HTTP', value: 'HTTP' },
  { id: 6, name: 'FTP', value: 'FTP' },
  { id: 7, name: 'TLS', value: 'TLS' },
  { id: 8, name: 'SMB', value: 'SMB' },
  { id: 9, name: 'DNS', value: 'DNS' },
  { id: 10, name: 'DCERPC', value: 'DCERPC' },
  { id: 11, name: 'SSH', value: 'SSH' },
  { id: 12, name: 'SMTP', value: 'SMTP' },
  { id: 13, name: 'IMAP', value: 'IMAP' },
  { id: 14, name: 'MSN', value: 'MSN' },
  { id: 15, name: 'KRB5', value: 'KRB5' },
  { id: 16, name: 'IKEV2', value: 'IKEV2' },
  { id: 17, name: 'TFTP', value: 'TFTP' },
  { id: 18, name: 'NTP', value: 'NTP' },
  { id: 19, name: 'DHCP', value: 'DHCP' },
];

export const statelessRuleColumn: { label: string; field: string; sort?: true }[] = [
  { label: 'Priority', field: 'priority', sort: true },
  { label: 'Protocol', field: 'protocol', sort: true },
  { label: 'Source', field: 'source', sort: true },
  { label: 'Destination', field: 'destination', sort: true },
  { label: 'Source port range', field: 'sourcePort', sort: true },
  { label: 'Destination port range', field: 'destinationPort', sort: true },
  { label: 'Action', field: 'action', sort: true },
  { label: 'Custom action', field: 'customAction', sort: true },
  { label: 'Masks', field: 'masks', sort: true },
  { label: 'Flags', field: 'flags', sort: true },
];

export const statefulRuleColumn: { label: string; field: string; sort?: true }[] = [
  { label: 'Protocol', field: 'protocol', sort: true },
  { label: 'Source', field: 'source', sort: true },
  { label: 'Destination', field: 'destination', sort: true },
  { label: 'Source port', field: 'sourcePort', sort: true },
  { label: 'Destination port', field: 'destinationPort', sort: true },
  { label: 'Direction', field: 'trafficDirection', sort: true },
  { label: 'Action', field: 'action', sort: true },
];
