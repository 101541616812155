import { useMemo, useState } from 'react';
import { INSPECTION_AND_RATE_LIMIT, REQUEST_AGGREGATION } from './constant';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import Footer from '../../../components/Footer';
import {
  ACTION,
  DEFAULT_REQUEST_AGGREGATION_KEY_ITEM,
  MISSING_IP_ADDRESS,
  RULE_BUILDER_REQUEST_CONDITION_DATA,
} from '../../../constant';
import RateLimiting from './RateLimiting';
import ThenGroup from './ThenGroup';
import RadioGroupContainer from '../../../components/RadioGroupContainer';
import StatementNode from '../Regular/StatementNode';
import IpAddressInHeader from './IpAddressInHeader';
import RequestAggregationKey from './RequestAggregationKey';
import { AwsRuleGroup } from 'graphql/types/AwsRuleGroup';

type RateBasedPropsType = {
  cloudId: number;
  selectedRegion: DropdownListDataType;
  dropdownConditionRequestValue: DropdownListDataType;
  seconds: string;
  isCustomImmunityTime: boolean;
  isEnable: boolean;
  customHeaderList: any;
  anotherLabelList: any;
  setDropdownConditionRequestValue: (value: any) => void;
  setSeconds: (value: any) => void;
  setIsCustomImmunityTime: (value: any) => void;
  setIsEnable: (value: any) => void;
  setCustomHeaderList: (value: any) => void;
  setAnotherLabelList: (value: any) => void;
  setAction: (value: any) => void;
  handleSelectAction: (value: any) => void;
  rateLimit: string;
  evaluationWindow: DropdownListDataType;
  requestAggregation: string;
  inspectionAndRateLimit: string;
  headerFieldName: string;
  positionInsideHeader: DropdownListDataType;
  missingIpAddress: string;
  requestAggregationKeyData: any;
  setRateLimit: (value: any) => void;
  setEvaluationWindow: (value: any) => void;
  setRequestAggregation: (value: any) => void;
  setInspectionAndRateLimit: (value: any) => void;
  setHeaderFieldName: (value: any) => void;
  setPositionInsideHeader: (value: any) => void;
  setMissingIpAddress: (value: any) => void;
  setRequestAggregationKeyData: (value: any) => void;
  setStatementData: (statement: any) => void;
  action: string;
  statementData: any;
  errors: {
    [key: string]: string;
  };
  ipSets: any;
};

const RateBased = (props: RateBasedPropsType) => {
  const {
    cloudId,
    selectedRegion,
    dropdownConditionRequestValue,
    seconds,
    isCustomImmunityTime,
    isEnable,
    customHeaderList,
    anotherLabelList,
    setDropdownConditionRequestValue,
    setSeconds,
    setIsCustomImmunityTime,
    setIsEnable,
    setCustomHeaderList,
    setAnotherLabelList,
    setAction,
    handleSelectAction,
    rateLimit,
    evaluationWindow,
    requestAggregation,
    inspectionAndRateLimit,
    headerFieldName,
    positionInsideHeader,
    missingIpAddress,
    requestAggregationKeyData,
    setRateLimit,
    setEvaluationWindow,
    setRequestAggregation,
    setInspectionAndRateLimit,
    setHeaderFieldName,
    setPositionInsideHeader,
    setMissingIpAddress,
    setRequestAggregationKeyData,
    statementData,
    action,
    setStatementData,
    errors,
    ipSets,
  } = props;

  return (
    <>
      <RateLimiting
        rateLimit={rateLimit}
        setRateLimit={setRateLimit}
        evaluationWindow={evaluationWindow}
        setEvaluationWindow={setEvaluationWindow}
      />

      <div className="rule-container-group-content">
        <RadioGroupContainer
          title="Request aggregation"
          description="Select the web request components to use for request aggregation. AWS WAF groups, counts, and rate limits requests based on this criteria."
          options={REQUEST_AGGREGATION}
          checked={requestAggregation}
          onChange={setRequestAggregation}
        />

        {requestAggregation === REQUEST_AGGREGATION[1].value ? (
          <IpAddressInHeader
            headerFieldName={headerFieldName}
            setHeaderFieldName={setHeaderFieldName}
            positionInsideHeader={positionInsideHeader}
            setPositionInsideHeader={setPositionInsideHeader}
            missingIpAddress={missingIpAddress}
            setMissingIpAddress={setMissingIpAddress}
          />
        ) : null}
      </div>

      {requestAggregation === REQUEST_AGGREGATION[2].value ? (
        <RequestAggregationKey
          item={DEFAULT_REQUEST_AGGREGATION_KEY_ITEM}
          data={requestAggregationKeyData}
          setData={setRequestAggregationKeyData}
        />
      ) : null}

      {requestAggregation !== REQUEST_AGGREGATION[3].value ? (
        <div className="rule-container-group-content">
          <RadioGroupContainer
            title="Scope of inspection and rate limiting"
            description="You can count and manage all requests or only the requests that match the criteria in a scope-down statement."
            options={INSPECTION_AND_RATE_LIMIT}
            checked={inspectionAndRateLimit}
            onChange={setInspectionAndRateLimit}
          />
        </div>
      ) : null}

      {requestAggregation === REQUEST_AGGREGATION[3].value ||
      inspectionAndRateLimit === INSPECTION_AND_RATE_LIMIT[1].value ? (
        <div className="regular-rule">
          <div className="rule-builder-condition-request">
            <p className="rule-text-condition">If a request</p>
            <div className="dropdown-container">
              <DropdownAtom
                id="dropdown-rule-condition-request"
                data={RULE_BUILDER_REQUEST_CONDITION_DATA}
                value={dropdownConditionRequestValue}
                handleClick={val => {
                  setDropdownConditionRequestValue(val);
                }}
              />
            </div>
          </div>

          <StatementNode
            value={dropdownConditionRequestValue.value.toString()}
            cloudId={cloudId}
            selectedRegion={selectedRegion.value.toString()}
            statementData={statementData}
            setStatementData={setStatementData}
            errors={errors}
            ipSets={ipSets}
          />
        </div>
      ) : null}

      <ThenGroup
        action={action}
        handleSelectAction={handleSelectAction}
        seconds={seconds}
        setSeconds={setSeconds}
        isCustomImmunityTime={isCustomImmunityTime}
        setIsCustomImmunityTime={() => setIsCustomImmunityTime(!isCustomImmunityTime)}
        isEnable={isEnable}
        setIsEnable={() => setIsEnable(!isEnable)}
        customHeaderList={customHeaderList}
        setCustomHeaderList={setCustomHeaderList}
        anotherLabelList={anotherLabelList}
        setAnotherLabelList={setAnotherLabelList}
        isRateBasedStatement
      />
    </>
  );
};

export default RateBased;
