import './index.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import NetworkTitle from 'components/v3/NetworkTitle';
import ClearIcon from 'assets/svgs/v2/ico_input_clear.svg';
import lazyGetAllAwsVpc, { IAllAwsVpcVariables } from 'graphql/queries/getAwsAllVPC';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import classNames from 'classnames';
import createNetworkACLMutation, { ICreateNetworkACLVariables } from 'graphql/mutations/createAwsNetworkAcl';
import Button, { ButtonTypeEnum } from '../../../WAF/WebACL/CreateWebACL/components/Button';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';

type ValueDataType = {
  value?: string;
};

type InputFormPropsType = {
  type?: 'INPUT' | 'DROPDOWN' | 'VALUE';
  field?: string;
  index?: number;
  clearable?: boolean;
  value?: ValueDataType;
  error?: boolean;
  setValue?: (key: string, value: string, index: number) => void;
};
export const InputForm = (props: InputFormPropsType) => {
  const { type, value, setValue, field, index, error } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnchange = (value: string) => {
    if (setValue) {
      setValue(field ?? '', value, index ?? 0);
    }
  };

  const handleClear = () => {
    handleOnchange('');
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="input-form-container">
      {type === 'INPUT' && (
        <input
          className="input-form-border"
          value={value?.value as string}
          onChange={event => {
            setValue && setValue('', event.target.value, 0);
          }}
        />
      )}
      {type === 'VALUE' && (
        <div className="value-wrapper input-form-border">
          <input
            className={classNames('value', error && 'error')}
            value={value?.value as string}
            onChange={event => {
              handleOnchange(event.target.value);
            }}
            ref={inputRef}
          />
          {value?.value && (
            <img
              src={ClearIcon}
              width={26}
              height={26}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleClear();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

type NoItemPropsType = {
  handleShowTagsList: () => void;
};
export const NoItem = (props: NoItemPropsType) => {
  const { handleShowTagsList } = props;

  return (
    <div className="no-item-wrapper">
      <div className="title" style={{ textAlign: 'center', marginBottom: '15px' }}>
        No item
      </div>
      <Button label="Add tag" onClick={handleShowTagsList} />
    </div>
  );
};

export type TagPropsType = {
  key: string;
  value: string;
};

type TagsListPropsType = {
  tags?: TagPropsType[];
  handleRemoveTag?: (index: number) => void;
  handleUpdateTag?: (key: string, value: string, index: number) => void;
};
export const TagsList = (props: TagsListPropsType) => {
  const { tags, handleRemoveTag, handleUpdateTag } = props;

  const handleUpdateTags = (key: string, value: string, index: number) => {
    if (handleUpdateTag) {
      handleUpdateTag(key, value, index);
    }
  };

  return (
    <div className="tags-list-container">
      <div className="tags-header">
        <div className="tags-header-item title">Key</div>
        <div className="tags-header-item title">Value-optional</div>
        <div style={{ width: 110 }}></div>
      </div>

      <div className="tags-body">
        {tags?.map((tag, index) => {
          return (
            <div key={index} className="tags-row">
              <div className="tags-column">
                <InputForm
                  type="VALUE"
                  value={{ value: tag.key }}
                  index={index}
                  field="key"
                  setValue={(key: string, value: string, index: number) => {
                    handleUpdateTags(key, value, index);
                  }}
                  error={tag.key === ''}
                />
              </div>
              <div className="tags-column">
                <InputForm
                  type="VALUE"
                  value={{ value: tag.value }}
                  index={index}
                  field="value"
                  setValue={(key: string, value: string, index: number) => {
                    handleUpdateTags(key, value, index);
                  }}
                />
              </div>
              <Button
                label="Remove tag"
                onClick={() => {
                  handleRemoveTag && handleRemoveTag(index);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

type NetworkACLCreationPropsType = {
  cloudId: number;
  region: string;
  pageBackClick: () => void;
};
const NetWorkACLCreation = (props: NetworkACLCreationPropsType) => {
  const { cloudId, region, pageBackClick } = props;

  // API
  const [getAllAwsVpc, { loading: getAllVpcLoading }] = lazyGetAllAwsVpc();
  const [createAwsNetwoklAcl, { loading: createNetworkAclLoading }] = createNetworkACLMutation();

  // State
  const [listTags, setListTags] = useState<TagPropsType[]>([]);
  const [listVpc, setListVpc] = useState<DropdownListDataType[]>([]);
  const [vpc, setVpc] = useState<DropdownListDataType>({ value: '' });
  const [tagSectionVisible, setTagSectionVisible] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [isVpcDropdownError, setVpcDropdownError] = useState<boolean>(false);

  const handleAddTag = useCallback(() => {
    const newTags = listTags.slice();
    newTags.push({ key: '', value: '' });

    setListTags(newTags);
  }, [listTags]);

  const handleRemoveTag = useCallback(
    (index: number) => {
      const isNameTag = listTags[index].key === 'Name';
      if (isNameTag) {
        setName('');
      }
      if (index > -1) {
        const newTags = listTags.slice();
        newTags.splice(index, 1);
        setListTags(newTags);
      }
    },
    [listTags],
  );

  const handleUpdateTag = useCallback(
    (key: string, value: string, index: number) => {
      // handle update name input (Name-optional)
      const isNameTag = listTags[index].key === 'Name';
      if (isNameTag) {
        if (key === 'value') {
          setName(value);
        }

        if (key === 'key') {
          if (value !== 'Name') {
            setName('');
          }
        }
      }
      if (key === 'key' && value === 'Name') {
        setName(listTags[index].value);
      }

      // handle tag list
      const newTags = listTags.map((tag, i) => {
        if (index === i) {
          return {
            ...tag,
            [key]: value,
          };
        }
        return tag;
      });

      setListTags(newTags);
    },
    [listTags],
  );

  const handleShowTagsList = useCallback(() => {
    handleAddTag();
    setTagSectionVisible(true);
  }, [handleAddTag, setTagSectionVisible]);

  const hanleNameChange = useCallback(
    (value: string) => {
      if (value === '') {
        const newListTag = listTags.filter(tag => tag.key !== 'Name');
        setListTags(newListTag);
      } else {
        const nameTag = listTags.find(tag => tag.key === 'Name');
        if (nameTag) {
          handleUpdateTag('value', value, listTags.indexOf(nameTag));
        } else {
          const updatedListTag = [...listTags];
          updatedListTag.push({ key: 'Name', value: value });
          setListTags(updatedListTag);
        }
      }
      setTagSectionVisible(true);
      setName(value);
    },
    [listTags, handleUpdateTag],
  );

  const handleCreate = useCallback(() => {
    // validation
    if (vpc.value === '') {
      setVpcDropdownError(true);
      return;
    }
    if (listTags.length > 0) {
      if (listTags.find(tag => tag.key === '')) {
        return;
      }
    }

    const reqVariable: ICreateNetworkACLVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        vpcId: vpc.value.toString(),
        tagSpecifications: [
          {
            resourceType: 'network-acl',
            tags: listTags,
          },
        ],
      },
    };
    createAwsNetwoklAcl({ variables: reqVariable }).then(({ data: responseData }) => {
      if (responseData?.createAwsNetwoklAcl?.result === ErrorCode.SUCCESS) {
        useToast(ErrorCode.SUCCESS, 'Create network ACLs successful.');
        pageBackClick();
      } else {
        useToast(ErrorCode.UNKNOWN, 'Create network ACLs failed.');
      }
    });
  }, [name, vpc, listTags, cloudId, region]);

  const fetchAwsVpc = useCallback(() => {
    const reqVariable: IAllAwsVpcVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        maxResults: 1000,
      },
    };
    getAllAwsVpc({ variables: reqVariable }).then(({ data: responseData }) => {
      const vpcData = responseData?.getAwsAllVPC?.data;
      if (vpcData) {
        const dropdownData: DropdownListDataType[] = [];
        vpcData.map((vpc, idx) => {
          dropdownData.push({
            id: idx,
            name: `${vpc?.vpcId} (${vpc?.tags?.find(t => t?.key == 'Name')?.value})`,
            value: vpc?.vpcId,
            isBetween: true,
            description: vpc?.cidrBlock,
          });
        });
        setListVpc(dropdownData);
      }
    });
  }, [cloudId, region]);

  useEffect(() => {
    fetchAwsVpc();
  }, []);

  return (
    <div id="mgd-list-layout">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center" id="title">
          <NetworkTitle
            pageTitle={'Create network ACL'}
            id={321}
            name={'name'}
            hasPrefixIcon={false}
            hasFavorite={false}
            pageBackClick={pageBackClick}
          />
        </div>
        <div className="flex a-center" id="action">
          <Button label="Delete" type={ButtonTypeEnum.PRIMARY} onClick={pageBackClick} />
        </div>
      </div>

      <div className="one-page" style={{ height: 'calc(100% - 70px)', overflowY: 'auto' }}>
        <div className="row-3 flex j-between a-center">
          <div className="title">
            <p>Network ACL settings</p>
          </div>
        </div>

        <div className="overview-container">
          <div className="nw-acl-screen">
            <div className="nw-acl-container">
              <div className="nw-acl-setting-container">
                <div className="nw-acl-flex">
                  <div className="title">Name-optional</div>
                  <InputForm
                    type="INPUT"
                    value={{ value: name }}
                    setValue={(key: string, value: string, number: number) => {
                      setName(value);
                      hanleNameChange(value);
                    }}
                  />
                </div>
                <div className="nw-acl-flex">
                  <div className="title">VPC</div>
                  <div>
                    <DropdownAtom
                      id="vpc-list"
                      disabled={getAllVpcLoading}
                      data={listVpc}
                      value={vpc}
                      error={isVpcDropdownError}
                      handleClick={value => {
                        setVpc(value);
                        if (isVpcDropdownError) {
                          setVpcDropdownError(false);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="nw-acl-tags-container">
                <div className="tags-title">Tags</div>
                <div className="tags-body-container">
                  {tagSectionVisible ? (
                    <TagsList
                      tags={listTags}
                      handleRemoveTag={handleRemoveTag}
                      handleUpdateTag={(key: string, value: string, index: number) => {
                        handleUpdateTag(key, value, index);
                      }}
                    />
                  ) : (
                    <NoItem
                      handleShowTagsList={() => {
                        handleShowTagsList();
                      }}
                    />
                  )}
                  {tagSectionVisible && <Button label="Add tag CC" type={ButtonTypeEnum.PRIMARY} onClick={handleAddTag} />}
                </div>
              </div>
            </div>

            <div className="nw-acl-container-bottom">
              <Button label="Cancel" onClick={pageBackClick} />
              <Button
                label="Create network ACL"
                loading={createNetworkAclLoading}
                disabled={createNetworkAclLoading}
                type={ButtonTypeEnum.PRIMARY}
                onClick={handleCreate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetWorkACLCreation;
