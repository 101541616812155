import ReactDOM from 'react-dom';
import React, { useState, ChangeEvent, useCallback } from 'react';
import './index.scss';
import ArrowIcon from 'assets/svgs/v2/ico_dropdown_arrow.svg';
import CloseIcon from 'assets/svgs/v3/ico_close.svg';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import { dropdownType } from '../../Modals/EditGroupingCriteria';

interface IMultiSelectAutocompleteProps {
  data: dropdownType[];
  selectedValues: dropdownType[];
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  handleClick: Function;
}

const MultiSelectAutocomplete: React.FC<IMultiSelectAutocompleteProps> = ({
  className,
  data,
  placeholder,
  selectedValues,
  handleClick,
  error,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [filteredData, setFilteredData] = useState<dropdownType[]>(data);
  const [showData, setShowData] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    if (value) {
      const filtered = data.filter(
        suggestion =>
          String(suggestion.value).toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const handleOnClick = useCallback(
    (data?: dropdownType) => {
      if (!selectedValues?.find(item => item.name === data?.name) || selectedValues.length === 0) {
        handleClick([...selectedValues, data]);
      } else {
        handleClick(selectedValues.filter(item => item.name !== data?.name));
      }
    },
    [selectedValues, handleClick],
  );

  return (
    <div className="regions" >
      <div
        className={'input-region ' + className}
        onMouseDown={e => {
          setShowData(true);
        }}
      >
        <input type="text" value={inputValue} onChange={handleChange} placeholder={placeholder} />
        <img src={ArrowIcon} width={24} height={24} />
      </div>
      { selectedValues.length === 0 && (
        <div className="error-container right-column-content">
          <img src={InputErrorIcon} width={16} height={16} />
          <p className="error-message">{error}</p>
        </div>
      ) }
      <div className="dropdown-list">
        {(
          selectedValues.map((value, index) => (
            <div key={index}>
              <span>{value.name}</span>
              <button
                onClick={() => {
                  const updatedValues = selectedValues.filter(item => item !== value);
                  handleClick(updatedValues);
                }}
              >
                <img src={CloseIcon} width={24} height={24} />
              </button>
            </div>
          ))
        )}

        {showData && (
          <ul className="no-scrollbar" tabIndex={-1} onBlur={() => setShowData(false)}>
            {filteredData.map((data: dropdownType, index: number) => (
              <li
                key={index}
                className="drop-item"
                onClick={e => {
                  e.stopPropagation();
                  handleOnClick(data);
                }}
              >
                <CheckboxAtom
                  id={index.toString()}
                  label={data?.name || ''}
                  checked={!!selectedValues.find(item => item?.name === data?.name)}
                  onchange={() => handleOnClick(data)}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
export default MultiSelectAutocomplete;
