import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface ICanceRotateAwsSecretVariables {
  cloudId: number;
  region: string;
  reqData: {
    secretId: string;
  };
}

export interface ICanceRotateAwsSecretResponseData {
  canceRotateAwsSecret: IGqlResponseData<undefined>;
}

const canceRotateAwsSecretMutation = () =>
  useMutation<ICanceRotateAwsSecretResponseData, ICanceRotateAwsSecretVariables>(query);
export default canceRotateAwsSecretMutation;
