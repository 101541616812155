export const CREATE_AWS_KEY_STEPS = [
  { id: 'configKey', title: 'Step1. Configure key' },
  {
    id: 'addLables',
    title: 'Step2. Add labels',
  },
  {
    id: 'defineKeyAdministrative',
    title: 'Step 3. Define key administrative permissions',
  },
  {
    id: 'defineUsage',
    title: 'Step 4. Define useage permissions',
  },
  { id: 'review', title: 'Review' },
];