import { gql } from '@apollo/client';

const query = gql`
  mutation createAwsAllowList($request: AwsCreateAllowListRequest, $cloudId: Long, $region: String) {
    createAwsAllowList(awsCreateAllowListRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsCreateAllowListResponse {
          arn
          id
        }
      }
    }
  }
`;

export default query;
