import { useMutation } from '@apollo/client';
import { AwsFirewallEncryptionConfigurationRequest, AwsFirewallEncryptionConfigurationResponse } from 'graphql/types/AwsFirewallEncryptionConfiguration';
import { IGqlResponseNewData } from '../../types';
import query from './query';

export type UpdateAwsFirewallEncryptionConfigurationVariables =  {
    cloudId: number;
    region: string;
    request : AwsFirewallEncryptionConfigurationRequest
}

export type UpdateAwsFirewallEncryptionConfigurationResponse = {
  updateAwsFirewallEncryptionConfiguration: IGqlResponseNewData<AwsFirewallEncryptionConfigurationResponse>;
}

const updateAwsFirewallEncryptionConfiguration = () =>
  useMutation<UpdateAwsFirewallEncryptionConfigurationResponse, UpdateAwsFirewallEncryptionConfigurationVariables>(query);
export default updateAwsFirewallEncryptionConfiguration;
