import { gql } from '@apollo/client';

const query = gql`
mutation updateAwsFirewallPolicy($request: AwsUpdateFirewallPolicyRequest, $cloudId: Long, $region: String) {
  updateAwsFirewallPolicy(updateFirewallPolicyRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsUpdateFirewallPolicyResponse {
        		updateToken
        firewallPolicyResponse
        }
      }
    }
  }
`;
export default query;