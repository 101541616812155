import ChatBot from "layouts/v3/ChatBot";
import ChatBotAsideLnb from "layouts/v3/ChatBotAsideLnb";
import { useEffect, useState } from "react";
import './index.scss';

const ChatbotAside = () => {
  const [chatBotOpened, setChatBotOpened] = useState<boolean>(process.env.REACT_APP_NEMO_CHATBOT_ENABLE === 'false' ? false : (localStorage.getItem('chatBotOpened') === 'true' ? true : false));

  const updateChatBotPanel = (value: boolean) => {
    console.log(process.env.REACT_APP_NEMO_CHATBOT_ENABLE);
    if(process.env.REACT_APP_NEMO_CHATBOT_ENABLE !== 'false') {
      setChatBotOpened(value)
      localStorage.setItem('chatBotOpened', value.toString());
    }
  }

  useEffect(() => {
    if(!chatBotOpened || process.env.REACT_APP_NEMO_CHATBOT_ENABLE === 'false') {
      setTimeout(() => {
        document.getElementById('chatbot-collapse-panel')?.classList.remove('hide');
      }, 300);
    } else {
      document.getElementById('chatbot-collapse-panel')?.classList.add('hide');
    }
  },[chatBotOpened])

  return (
    <>
      <ChatBotAsideLnb open={chatBotOpened}>
        <ChatBot open={chatBotOpened} closeChatBotPanel={() => updateChatBotPanel(false)}/>
      </ChatBotAsideLnb>
      <div id="chatbot-collapse-panel" onClick={() => updateChatBotPanel(true)}>
        <button className="chatbot-btn"  />
      </div>
    </>
  );
}
export default ChatbotAside;