import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { useCallback } from 'react';
import CustomRadio from '../components/CustomRadio';
import { ruleGroupFormatData } from '../configs';
import { ICreationDataPropsType } from './type';
import CustomDropdown from '../../Common/Components/CustomDropdown';

interface IRuleGroupType {
  title: string;
  subTitle: string;
  creationData: ICreationDataPropsType;
  updateCreationData: (data: ICreationDataPropsType) => void;
}

const RuleGroupType = ({ title, subTitle, creationData, updateCreationData }: IRuleGroupType) => {
  const { ruleGroupType, ruleGroupFormat, ruleEvaluationOrder } = creationData;
  const updateValue = useCallback(
    (
      name: 'ruleGroupType' | 'ruleGroupFormat' | 'ruleEvaluationOrder' | 'ruleRowsDomainList',
      value: DropdownListDataType | string | [],
    ) => {
      updateCreationData({ ...creationData, [name]: value });
    },
    [creationData, updateCreationData],
  );

  return (
    <div className="rule-group-container">
      <div className="title-group">
        <p className="title">{title}</p>
        <p className="sub-title">{subTitle}</p>
      </div>
      <div className="rule-group-details-container">
        <div className="rule-group-detail-title">Rule group type</div>
        <div className="rule-group-type-content">
          <div className="type-content-row">
            <CustomRadio
              checked={ruleGroupType === 'stateful'}
              label="Stateful rule group"
              description="Use stateful rule groups to inspect packets within the context of the traffic flow."
              onClick={() => {
                updateValue('ruleRowsDomainList', []);
                updateValue('ruleGroupType', 'stateful');
              }}
            />
          </div>
          <div className="type-content-row">
            <CustomRadio
              checked={ruleGroupType === 'stateless'}
              label="Stateless rule group"
              description="Use stateless rule groups to inspect packets on their own, without the context of the traffic flow."
              onClick={() => {
                updateValue('ruleRowsDomainList', []);
                updateValue('ruleGroupType', 'stateless');
              }}
            />
          </div>
          {ruleGroupType === 'stateful' && (
            <>
              <div className="rule-group-format-container">
                <div className="title-font-weight-500">Rule group format</div>
                <div style={{ width: '50%' }}>
                  <CustomDropdown
                    id="rule-group-format-dropdown"
                    style={{ height: 40 }}
                    data={ruleGroupFormatData}
                    value={ruleGroupFormat}
                    placeholder="Choose rule group format"
                    handleClick={data => updateValue('ruleGroupFormat', data)}
                  />
                </div>
              </div>
              <div className="rule-group-format-container">
                <div className="title-font-weight-500">Rule evaluation order</div>
                <div className="rule-group-type-content">
                  <div className="type-content-row">
                    <CustomRadio
                      checked={ruleEvaluationOrder === 'strictOrder'}
                      label="Strict order - "
                      subLabel="recommended"
                      description="Rules are processed in the order that you define, starting with the first rule."
                      onClick={() => updateValue('ruleEvaluationOrder', 'strictOrder')}
                    />
                  </div>
                  <div className="type-content-row">
                    <CustomRadio
                      checked={ruleEvaluationOrder === 'actionOrder'}
                      label="Action order"
                      description="Rules with a pass action are processed first, followed by drop, reject, and alert actions. This option was previously names Default order."
                      onClick={() => updateValue('ruleEvaluationOrder', 'actionOrder')}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RuleGroupType;
