import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsCreateAwsSecretResponseType, AwsCreateSecretRequestType } from 'graphql/types/CreateAwsSecret';

export interface ICreateAwsSecretVariables {
  reqData: AwsCreateSecretRequestType;
  cloudId: number;
  region: string;
}

export interface ICreateAwsSecretResponseData {
  createAwsSecret: IGqlResponseNewData<AwsCreateAwsSecretResponseType>;
}

const createAwsSecretMutation = () => useMutation<ICreateAwsSecretResponseData, ICreateAwsSecretVariables>(query);
export default createAwsSecretMutation;
