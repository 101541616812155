import './index.scss';
import { useEffect, useState } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Icon from 'components/v2/atoms/Icon';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import IconDelete from 'assets/svgs/v3/ico_delete_network.svg';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';

interface IDisassociateRuleGroupsModalProps extends IBaseModalProps {
  name: string;
  header: string;
  stateCheckedList: string[];
  onClose: () => void;
  handleDisassociateRule: Function;
}

const DisassociateRuleGroups = ({
  name,
  header,
  stateCheckedList,
  handleDisassociateRule,
  onClose,
  ...baseModalProps
}: IDisassociateRuleGroupsModalProps) => {
  const [payload, setPayload] = useState<string>('');

  const onSavePolicy = async () => {
    const result = await handleDisassociateRule(stateCheckedList);
    result ? onClose() : null;
  };

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconDelete} />
          {header}
        </>
      )}
      onClose={onClose}
      {...baseModalProps}
    >
      <div className="delete-webacl-modal">
        <div className={'row-1 flex col'}>
          <div className="header">
            <div className="delete-warning">
              <Icon width={32} height={32} src={IconWarning} />
              <p className="detail-warning">{name}</p>
            </div>
          </div>
        </div>
        <div className="row-2">
          <div className="button-group">
            <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={onClose} />
            <Button label="Disassociate from policy" type={ButtonTypeEnum.PRIMARY} onClick={onSavePolicy} />
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default DisassociateRuleGroups;
