import { SecretTypePanel } from '../components/SecretTypePanel';
import KeyValuePairPanel from '../components/KeyValuePairPanel';
import EncryptionPanel from '../components/EncryptionPanel';
import CredentialPanel from '../components/CredentialPanel';
import DatabasePanel from '../components/DatabasePanel';
import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { CreationDataType, KeyPairtType } from '../type';
import _ from 'lodash';
import './index.scss';
import SectionTitle from 'pages/v2/Organ/Management/components/SectionTitle';
import { portRegex } from 'utils/Regex';
import { COLUMNS_KEY } from '../configs';
type SecretTypeChooseStepType = {
  cloudId: number;
  region: string;
  setCreationData: Function;
  creationData: CreationDataType;
  stepTitle: string;
};
const SecretTypeChooseStep = forwardRef((props: SecretTypeChooseStepType, ref: Ref<any>) => {
  const { cloudId, region, setCreationData, creationData, stepTitle } = props;

  const initPair: KeyPairtType = {
    key: '',
    value: '',
    error: '',
  };
  const [createPairs, setCreatePairs] = useState<KeyPairtType[]>(creationData?.otherTS || [initPair]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [startValid, setStartValid] = useState<boolean>(false);

  const handleSetError = (key: string, errorMessage: string) => {
    setErrors(prevErrors => {
      const newErrors = {
        ...prevErrors,
        [key]: errorMessage,
      };
      if (errorMessage === '') {
        _.omit(newErrors, key);
      }

      return newErrors;
    });
  };

  const clearError = useCallback((key: string) => {
    setErrors(prev => {
      const newState: { [key: string]: string } = { ...prev };
      delete newState?.[key];

      return newState;
    });
  }, []);

  const addPair = () => {
    setCreatePairs([...createPairs, initPair]);
  };

  const handleSetPairs = useCallback(
    (rowIndex: number, keyName: string, value: string): KeyPairtType[] => {
      const dataUpdate = createPairs.map((pair, index) => {
        let updatePair = pair;
        if (index === rowIndex) {
          if (keyName === 'key') {
            updatePair = { ...updatePair, [keyName]: value, error: handleSetErrorPairs(createPairs, value) };
          } else {
            updatePair = { ...updatePair, [keyName]: value };
          }
        }

        return updatePair;
      });
      setCreatePairs(dataUpdate);
      setCreationData('otherTS', dataUpdate);

      return dataUpdate;
    },
    [createPairs, creationData, setCreationData],
  );

  const handleRemovePair = useCallback(
    (index: number) => {
      const newPairs = createPairs.filter((_pair: KeyPairtType, idx: number) => idx !== index);
      setCreatePairs(newPairs);
      setCreationData('otherTS', newPairs);
    },
    [createPairs, creationData, setCreationData],
  );

  const checkDuplicates = (arr: KeyPairtType[], keyAValue: string) => {
    const filtered = arr.filter(obj => obj.key === keyAValue && obj.key !== '');

    return filtered.length > 1;
  };

  const handleSetErrorPairs = (pairs: KeyPairtType[], value: string) => {
    if (value !== '') {
      if (checkDuplicates(pairs, value)) {
        return 'You can\'t create two keys with the same name. Key names are case sensitive.';
      } else {
        return '';
      }
    } else {
      return 'You must enter a key.';
    }
  };

  const checkValidationPairs = () => {
    let validation = false;
    if (createPairs.length !== 0) {
      const checkValid = createPairs.map(data => ({ ...data, error: handleSetErrorPairs(createPairs, data.key) }));
      validation = checkValid.some(data => data.error !== '');
      setCreatePairs(checkValid);
    }

    return validation;
  };

  const inputValidation = useCallback(() => {
    let isInputsValid = true;
    if (creationData.secretType !== 'otherTS') {
      if (!creationData?.userName) {
        handleSetError('userName', 'You must enter a user name.');
        isInputsValid = false;
      } else {
        clearError('userName');
      }
      if (!creationData?.password) {
        handleSetError('password', 'You must enter a password.');
        isInputsValid = false;
      } else {
        clearError('password');
      }
    } else {
      isInputsValid = !checkValidationPairs();
    }
    if (creationData.secretType === 'otherDB') {
      if (!creationData.otherDB?.host) {
        handleSetError('host', 'You must enter a server address.');
        isInputsValid = false;
      } else {
        clearError('host');
      }
      if (!creationData.otherDB?.port || !portRegex.test(creationData.otherDB?.port)) {
        handleSetError('port', 'You must enter a port.');
        isInputsValid = false;
      } else {
        clearError('port');
      }

      if (!creationData.otherDB?.dbName) {
        handleSetError('dbName', 'You must enter a database name.');
        isInputsValid = false;
      } else {
        clearError('dbName');
      }
    }
    const checkSecretType = ['otherDB', 'otherTS'].includes(creationData.secretType);
    if (!checkSecretType && !creationData[creationData.secretType]?.idTable) {
      handleSetError('idTable', 'You must select a database.');
      isInputsValid = false;
    } else {
      clearError('idTable');
    }

    return isInputsValid;
  }, [creationData, errors, clearError, checkValidationPairs]);

  const onSecretTypeVadidate = useCallback(() => {
    setStartValid(true);

    return inputValidation();
  }, [startValid, creationData, errors]);

  useImperativeHandle(ref, () => ({
    onSecretTypeVadidate,
  }));

  useEffect(() => {
    if (startValid) {
      inputValidation();
    }
  }, [startValid, creationData]);

  return (
    <div>
      <SectionTitle title={stepTitle} customStyle="section-step" />
      <div className="type-step">
        <SecretTypePanel
          creationData={creationData}
          onChangeValue={setCreationData}
        />
        {creationData.secretType === 'otherTS' ? (
          <div>
            <KeyValuePairPanel
              columns={COLUMNS_KEY}
              createPairs={createPairs}
              addPair={addPair}
              handleValueChanged={handleSetPairs}
              handleRemovePair={handleRemovePair}
            />
            <EncryptionPanel
              cloudId={cloudId}
              region={region}
              creationData={creationData}
              handleValueChanged={setCreationData}
            />
          </div>
        ) : (
          <div>
            <CredentialPanel creationData={creationData} handleValueChanged={setCreationData} errors={errors} />
            <EncryptionPanel
              cloudId={cloudId}
              region={region}
              creationData={creationData}
              handleValueChanged={setCreationData}
            />
            <DatabasePanel
              creationData={creationData}
              handleValueChanged={setCreationData}
              secretType={creationData.secretType}
              cloudId={cloudId}
              region={region}
              errors={errors}
            />
          </div>
        )}
      </div>
    </div>
  );
});
SecretTypeChooseStep.displayName = 'SecretTypeChooseStep';

export default SecretTypeChooseStep;
