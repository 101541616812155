import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import IconAddManaged from 'assets/svgs/v3/ico_add_managed_statefull_rule_group.svg';
import Icon from 'components/v2/atoms/Icon';
import ToggleSwitchAtom from 'components/v2/atoms/ToggleSwitchAtom';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import BaseModal from 'components/v2/modals/BaseModal';
import lazyGetAwsAllListRuleGroupsFirewall from 'graphql/queries/getAwsAllListRuleGroupsFirewall';
import lazyGetAwsRuleGroupMetadata from 'graphql/queries/getAwsRuleGroupMetadata';
import { AwsStatefulRuleGroupReferenceLogType } from 'graphql/types/AwsFirewallPolicy';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import { useEffect, useMemo, useState } from 'react';
import { IBaseFirewallModalProps } from '../../../types';
import './index.scss';
interface IAddManagedStatefulRuleGroupProps extends IBaseFirewallModalProps {
  rules: AwsStatefulRuleGroupReferenceLogType[];
  onAddRuleGroups: (rows: RowType[]) => void
}

const AddManagedStatefulRuleGroupModal = ({
  region,
  cloudId,
  rules,
  onAddRuleGroups,
  ...baseModalProps
}: IAddManagedStatefulRuleGroupProps) => {
  const [checkedDomainIpList, setCheckedDomainIpList] = useState<string[]>([]);
  const [checkedThreatSignatureList, setCheckedThreatSignatureList] = useState<string[]>([]);

  const [getAwsAllListRuleGroupsFirewall] = lazyGetAwsAllListRuleGroupsFirewall();
  const [getAwsRuleGroupMetadata, { loading }] = lazyGetAwsRuleGroupMetadata();

  const [domainTblRows, setDomainTblRows] = useState<RowType[]>([]);
  const [domainTblTotal, setDomainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });

  const [domainTablePagination, setDomainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [threatTblRows, setThreatTblRows] = useState<RowType[]>([]);
  const [threatTblTotal, setThreatTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [threatTablePagination, setThreatTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const domainIpColumns: ColumnType[] = [
    {
      label: 'Name',
      field: 'name',
      width: 650,
      renderCell: (row: RowType) => {
        return (
          <>
            <p className="content">{row.name}</p>
            <p className="description">{row.description}</p>
          </>
        );
      },
    },
    {
      label: 'Capacity',
      field: 'capacity',
    },
    {
      label: 'Alert mode',
      field: 'alertMode',
      renderCell: (row: RowType) => (
        <>
          <div className="alert-mode">
            <ToggleSwitchAtom
              disabled={!checkedDomainIpList.find(item => item === row.id)}
              value={row.alertMode}
              onChange={checked => {
                const newRows = domainTblRows?.map(r => (r.id === row.id ? { ...r, alertMode: checked } : r));
                setDomainTblRows(newRows);
              }}
            />
            {row.alertMode ? <span className="enabled">Enabled</span> : <span className="disabled">Disabled</span>}
          </div>
        </>
      ),
    },
  ];

  const threatSignatureColumns: ColumnType[] = [
    {
      label: 'Name',
      field: 'name',
      width: 650,
      renderCell: (row: RowType) => {
        return (
          <>
            <p className="content">{row.name}</p>
            <p className="description">{row.description}</p>
          </>
        );
      },
    },
    {
      label: 'Capacity',
      field: 'capacity',
    },
    {
      label: 'Alert mode',
      field: 'alertMode',
      renderCell: (row: RowType) => (
        <>
          <div className="alert-mode">
            <ToggleSwitchAtom
              value={row.alertMode}
              disabled={!checkedThreatSignatureList.find(item => item === row.id)}
              onChange={checked => {
                const newRows = threatTblRows?.map(r => (r.id === row.id ? { ...r, alertMode: checked } : r));
                setThreatTblRows(newRows);
              }}
            />
            {row.alertMode ? <span className="enabled">Enabled</span> : <span className="disabled">Disabled</span>}
          </div>
        </>
      ),
    },
  ];

  const getRequestVariables = (type: 'domain' | 'threat') => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        scope: 'MANAGED',
        managedType: type === 'domain' ? 'AWS_MANAGED_DOMAIN_LISTS' : 'AWS_MANAGED_THREAT_SIGNATURES',
        type: 'STATEFUL',
      },
    };
  };

  const handleGetDomainDataRows = async () => {
    try {
      const { data: listRuleGroupsFirewall } = await getAwsAllListRuleGroupsFirewall({
        variables: variableCombineNextToken(getRequestVariables('domain')),
      });

      if (!listRuleGroupsFirewall?.getAwsAllListRuleGroupsFirewall?.data?.length) {
        return;
      }

      const fetchListAllRuleGroupsFirewall = listRuleGroupsFirewall.getAwsAllListRuleGroupsFirewall.data
        .filter(item => item.name.endsWith('StrictOrder'))
        .map(async ruleGroup => {
          const ruleGroupFirewallVariable = {
            cloudId,
            region,
            request: {
              ruleGroupArn: ruleGroup.arn,
            },
          };

          return await getAwsRuleGroupMetadata({
            variables: ruleGroupFirewallVariable,
          }).then(({ data: ruleGroupMetaData }) => {
            const ruleGroupMetadataResponse = ruleGroupMetaData?.getAwsRuleGroupMetadata?.data?.[0];
            if (ruleGroupMetadataResponse) {
              const { ruleGroupArn, ruleGroupName, description, capacity, statefulRuleOptions } =
                ruleGroupMetadataResponse;

              return {
                id: ruleGroupArn,
                name: ruleGroupName,
                description: description || '-',
                capacity: capacity,
                ruleOrder: statefulRuleOptions?.ruleOrder || '-',
                alertMode: false,
              };
            }
          });
        });

      const listAllRuleGroupFirewallData = await Promise.all(fetchListAllRuleGroupsFirewall);
      const listAllRuleGroupFirewallDataFilter = listAllRuleGroupFirewallData.filter(
        item => !rules.some(r => r.resourceArn === item?.id),
      );
      setDomainTblRows([...listAllRuleGroupFirewallDataFilter as RowType[]]);
      setDomainTblTotal({
        totalPage: Math.ceil(listAllRuleGroupFirewallDataFilter.length / domainTablePagination.itemPerPage),
        totalElement: listAllRuleGroupFirewallDataFilter.length,
      });
    } catch (error) {}
  };

  const handleGetThreatDataRows = async () => {
    try {
      const { data: listRuleGroupsFirewall } = await getAwsAllListRuleGroupsFirewall({
        variables: variableCombineNextToken(getRequestVariables('threat')),
      });

      if (!listRuleGroupsFirewall?.getAwsAllListRuleGroupsFirewall?.data?.length) {
        return;
      }

      const fetchListAllRuleGroupsFirewall = listRuleGroupsFirewall.getAwsAllListRuleGroupsFirewall.data
        .filter(item => item.name.endsWith('StrictOrder'))
        .map(async ruleGroup => {
          const ruleGroupFirewallVariable = {
            cloudId,
            region,
            request: {
              ruleGroupArn: ruleGroup.arn,
            },
          };

          return await getAwsRuleGroupMetadata({
            variables: ruleGroupFirewallVariable,
          }).then(({ data: ruleGroupMetaData }) => {
            const ruleGroupMetadataResponse = ruleGroupMetaData?.getAwsRuleGroupMetadata?.data?.[0];
            if (ruleGroupMetadataResponse) {
              const { ruleGroupArn, ruleGroupName, description, capacity, statefulRuleOptions } =
                ruleGroupMetadataResponse;

              return {
                id: ruleGroupArn,
                name: ruleGroupName,
                description: description || '-',
                capacity: capacity,
                ruleOrder: statefulRuleOptions?.ruleOrder || '-',
                alertMode: false,
              };
            }
          });
        });

      const listAllRuleGroupFirewallData = await Promise.all(fetchListAllRuleGroupsFirewall);
      const listAllRuleGroupFirewallDataFilter = listAllRuleGroupFirewallData.filter(
        item => !rules.some(r => item?.id === r.resourceArn),
      );
      setThreatTblRows([...listAllRuleGroupFirewallDataFilter as RowType[]]);
      setThreatTblTotal({
        totalPage: Math.ceil(listAllRuleGroupFirewallDataFilter.length / threatTablePagination.itemPerPage),
        totalElement: listAllRuleGroupFirewallDataFilter.length,
      });
    } catch (error) {}
  };

  const updateDomainTablePagination = (key: string, value: any) => {
    setDomainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const domainRowsCurrentPage = useMemo(() => {
    const startIndex = (domainTablePagination.currentPage - 1) * domainTablePagination.itemPerPage;
    const endIndex = startIndex + domainTablePagination.itemPerPage;

    return domainTblRows.slice(startIndex, endIndex);
  }, [domainTablePagination, domainTblRows]);

  const updateThreatTablePagination = (key: string, value: any) => {
    setThreatTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const threatRowsCurrentPage = useMemo(() => {
    const startIndex = (threatTablePagination.currentPage - 1) * threatTablePagination.itemPerPage;
    const endIndex = startIndex + threatTablePagination.itemPerPage;
    return threatTblRows.slice(startIndex, endIndex);
  }, [threatTablePagination, threatTblRows]);

  const handleAddManagedRuleGroups = async () => {
    const listDomainFiltered = domainTblRows.filter(r => checkedDomainIpList.some(ip => r.id === ip));
    const listThreatFiltered = threatTblRows.filter(r => checkedThreatSignatureList.some(s => r.id === s));
    const allRuleGroups = [...listDomainFiltered, ...listThreatFiltered];
    onAddRuleGroups(allRuleGroups)
  };

  useEffect(() => {
    handleGetDomainDataRows();
    handleGetThreatDataRows();
  }, []);

  return (
    <BaseModal
      title={() => (
        <>
          <div>
            <div className="title flex">
              <Icon width={32} height={32} src={IconAddManaged} /> <label>Add managed stateful rule groups</label>
            </div>
            <p>Select the managed stateful rule groups that you want to add to your policy.</p>
          </div>
        </>
      )}
      {...baseModalProps}
    >
      <div className="add-managed-modal-policy">
        <div className="domain-ip">
          <p className="title">Domain and IP rule groups</p>
          <Table
            columns={domainIpColumns}
            rows={domainRowsCurrentPage}
            useSelected={false}
            reportCheckedList={(list: string[]) => {
              setCheckedDomainIpList(list);
            }}
            isLoading={loading}
            horizontalScrollable={true}
          />
          {domainRowsCurrentPage?.length && !loading ? (
            <div className="fleet-instance pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{domainTblTotal.totalElement}</span>
              </p>

              <TableManagePagination
                ableFetchMore={false}
                currentPage={domainTablePagination.currentPage}
                updateCurrentPage={page => updateDomainTablePagination('currentPage', page)}
                totalPage={domainTblTotal.totalPage}
              />
            </div>
          ) : null}
        </div>
        <div className="thread-signature">
          <p className="title">Threat signature rule groups</p>
          <Table
            columns={threatSignatureColumns}
            rows={threatRowsCurrentPage}
            reportCheckedList={(list: string[]) => {
              setCheckedThreatSignatureList(list);
            }}
            useSelected={false}
            isLoading={loading}
            horizontalScrollable={true}
          />
          {threatRowsCurrentPage?.length && !loading ? (
            <div className="fleet-instance pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{threatTblTotal.totalElement}</span>
              </p>

              <TableManagePagination
                ableFetchMore={false}
                currentPage={threatTablePagination.currentPage}
                updateCurrentPage={page => updateThreatTablePagination('currentPage', page)}
                totalPage={threatTblTotal.totalPage}
              />
            </div>
          ) : null}
        </div>
        <div className="button-group">
          <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={() => baseModalProps?.onClose?.()} />
          <Button label="Add to policy" type={ButtonTypeEnum.PRIMARY} onClick={handleAddManagedRuleGroups} />
        </div>
      </div>
    </BaseModal>
  );
};

export default AddManagedStatefulRuleGroupModal;
