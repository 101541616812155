import RequiredIcon from 'assets/svgs/v2/ico_require.svg';
import InputAtom from 'components/v2/atoms/InputAtom';
import { ICreateAllowListData, ICreateAllowListErrors } from '../types';

interface IPredefinedTextProps {
  creationData: ICreateAllowListData;
  updateCreationData: (data: ICreateAllowListData) => void;
  error: ICreateAllowListErrors;
}

const PredefinedText = ({ creationData, updateCreationData, error }: IPredefinedTextProps) => {
  const { name, description, predefinedS3Name, predefinedS3Object } = creationData;

  return (
    <div className="allow-lists-container">
      <div className="allow-list-title">
        <p>List settings</p>
        <p className="allow-list-sub-title">Enter settings for the allow list.</p>
      </div>
      <div className="allow-list-content">
        <div className="detail-content-row">
          <div className="detail-content-column-left">
            Name
            <img style={{ marginLeft: 5 }} src={RequiredIcon} width={4} height={4} />
          </div>
          <div className="detail-content-column-right">
            <div className="full-width">
              <InputAtom
                value={name}
                noClear={true}
                onChangeValue={(value: string) => updateCreationData({ ...creationData, name: value })}
                error={error.name ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="detail-content-row">
          <div className="detail-content-column-left">Description - optional</div>
          <div className="detail-content-column-right">
            <div className="full-width">
              <InputAtom
                value={description}
                noClear={true}
                onChangeValue={(value: string) => updateCreationData({ ...creationData, description: value })}
              />
            </div>
          </div>
        </div>
        <div className="detail-content-row">
          <div className="detail-content-column-left">
            S3 bucket name
            <img style={{ marginLeft: 5 }} src={RequiredIcon} width={4} height={4} />
          </div>
          <div className="detail-content-column-right">
            <p>Enter the full name of the S3 bucket that contains the list.</p>
            <div className="full-width">
              <InputAtom
                value={predefinedS3Name}
                noClear={true}
                onChangeValue={(value: string) => updateCreationData({ ...creationData, predefinedS3Name: value })}
                error={error.s3Name ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="detail-content-row">
          <div className="detail-content-column-left">
            S3 object name
            <img style={{ marginLeft: 5 }} src={RequiredIcon} width={4} height={4} />
          </div>
          <div className="detail-content-column-right">
            <p>Enter the full name (key) of the S3 object that contains the list.</p>
            <div className="full-width">
              <InputAtom
                value={predefinedS3Object}
                noClear={true}
                onChangeValue={(value: string) => updateCreationData({ ...creationData, predefinedS3Object: value })}
                error={error.s3Object ? true : false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PredefinedText;
