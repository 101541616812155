import { DropdownListDataType } from 'components/Dropdown/types';
import lazyGetAwsListDetectors from 'graphql/queries/getAwsListDetectors';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import MgdTabLayout from 'layouts/v3/MgdTabLayout';
import { MgdTabLayoutTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import MgdListUpGuardDutyFinding from './MgdListUpGuardDutyFinding';
import MgdWelCome from './MgdWelCome';

const GUARD_DUTY_TABS: IMgdTabProps[] = [
  { id: 'Finding', name: 'Finding', title: 'Finding' },
  { id: 'Runtime', name: 'Runtime', title: 'Runtime' },
  { id: 'Malware', name: 'Malware', title: 'Malware' },
  { id: 'Plan', name: 'Plan', title: 'Plan' },
  { id: 'Account', name: 'Account', title: 'Account' },
  { id: 'Setting', name: 'Setting', title: 'Setting' },
];
interface IGuarDutyProps {
  onBackButtonClicked: () => void;
  projectId: number;
  cloudName: string;
  regionCode: string;
  regions: string[];
  relatedCloudSelected: DropdownListDataType;
  pageBackClick: () => void;
}

const initialRegion: DropdownListDataType = { name: '', value: '' };

const GuardDuty = (props: IGuarDutyProps) => {
  const { projectId, cloudName, regionCode, regions, relatedCloudSelected, pageBackClick } = props;

  const [detectorIds, setDetectorIds] = useState<string[]>([]);
  const [currentRegion, setCurrentRegion] = useState<DropdownListDataType>(initialRegion);
  const [regionList, setRegionList] = useState<DropdownListDataType[]>([]);
  const [tabSelected, setTabSelected] = useState<IMgdTabProps>(GUARD_DUTY_TABS[0]);

  const { defaultRegionList, updateDefaultRegionList } = useOutletContext<{
    defaultRegionList: { projectId: number; cloudId: number; name: string; value: string }[];
    updateDefaultRegionList: (projectId: number, cloudId: number, name: string, value: string) => void;
  }>();

  // API
  const [getEnumTypeCode] = lazyGetEnumTypeCode();
  const [getAwsListDetectors] = lazyGetAwsListDetectors();
  
  // handle change region
  const handleChangeRegion = (region: DropdownListDataType) => {
    setCurrentRegion({
      name: region.name || '',
      value: region.value,
    });
  };
  // get initial region
  const getInitialRegion = async () => {
    const region = defaultRegionList.find(o => {
      return o.projectId === projectId && o.cloudId === Number(relatedCloudSelected.value);
    });

    if (!region) {
      const variables = { text: regionCode || 'AwsRegionCode' };
      const response = await getEnumTypeCode({ variables });
      const typeCodeList = response?.data?.getEnumTypeCode?.data || [];

      const regionList = typeCodeList
        .filter(c => regions.includes(c.value))
        .map(o => ({
          ...o,
          name: o.value,
        }));

      setRegionList(regionList);
      const initRegion = regionList.find(o => o.name === currentRegion.name && o.value === currentRegion.value);
      setCurrentRegion(initRegion || regionList[0]);
    } else {
      setCurrentRegion({
        name: region.name,
        value: region.value,
      });
    }
  };

  // layout type
  const layoutType = useMemo(() => {
    return detectorIds.length ? MgdTabLayoutTypeEnum.MGD_LIST_MORE_SUMMARY : MgdTabLayoutTypeEnum.MGD_DETAIL;
  }, [detectorIds]);
  // status button pin
  const isPined = useMemo(() => {
    return defaultRegionList.some(o => o.projectId === projectId && o.cloudId === relatedCloudSelected.value);
  }, [defaultRegionList, projectId, relatedCloudSelected]);

  // handle button pin click
  const pinButtonClickHandle = useCallback(() => {
    updateDefaultRegionList(
      projectId,
      Number(relatedCloudSelected.value),
      currentRegion.name || '',
      currentRegion.value as string,
    );
    // TODO: fake api
    setDetectorIds([]);
  }, [relatedCloudSelected, currentRegion, projectId]);

  // render content node
  const contentNode = useMemo(() => {
    if (!detectorIds.length) {
      return <MgdWelCome />;
    }

    switch (tabSelected.id) {
      case GUARD_DUTY_TABS[0].id:
        return <MgdListUpGuardDutyFinding />;
      case GUARD_DUTY_TABS[1].id:
      case GUARD_DUTY_TABS[2].id:
      case GUARD_DUTY_TABS[3].id:
      case GUARD_DUTY_TABS[4].id:
      case GUARD_DUTY_TABS[5].id:
      default:
        return null;
    }
  }, [detectorIds, tabSelected]);

  useEffect(() => {
    setDetectorIds(['1']);
  }, []);

  useEffect(() => {
    getInitialRegion();
  }, [defaultRegionList]);

  return (
    <div className="mgd-wrapper">
      <MgdTabLayout
        isPined={isPined}
        hasFavorite={true}
        pageTitle={cloudName}
        pinBtnDisabled={false}
        layoutType={layoutType}
        headerDropList={regionList}
        mainTabSelected={tabSelected}
        mainTitle={tabSelected.title}
        headerDropValue={currentRegion}
        mainTabItemClick={setTabSelected}
        onBackButtonClicked={pageBackClick}
        headerDropOnchange={handleChangeRegion}
        pinBtnActHandle={pinButtonClickHandle}
        mainTabList={detectorIds.length ? GUARD_DUTY_TABS : []}
        tabContentNode={contentNode}
        tabSummaryNode={<p>sammary</p>}
      />
    </div>
  );
};

export default GuardDuty;
