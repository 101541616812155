import { gql } from '@apollo/client';

const query = gql`
  query getAwsAllowList($request: AwsGetAllowListRequest, $cloudId: Long, $region: String) {
    getAwsAllowList(awsGetAllowListRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetAllowListResponse {
          arn
          createdAt
          criteria {
            regex
            s3WordsList {
              bucketName
              objectKey
            }
          }
          description
          id
          name
          status {
            code
            description
          }
          tags
          updatedAt
        }
      }
    }
  }
`;

export default query;
