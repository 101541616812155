import { gql } from '@apollo/client';

const query = gql`
  mutation updateAwsSecret($reqData: AwsUpdateSecretRequest, $cloudId: Long, $region: String) {
    updateAwsSecret(updateSecretRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsUpdateSecretResponse {
          arn
          name
          versionId
        }
      }
    }
  }
`;
export default query;
