import { useMutation } from '@apollo/client';
import { AwsAssociateSubnetsRequest, AwsAssociateSubnetsResponse } from 'graphql/types/AwsAssociateSubnet';
import { IGqlResponseNewData } from '../../types';
import query from './query';

export type AwsAssociateSubnetsVariablesType = {
  cloudId: number;
  region: string;
  request: AwsAssociateSubnetsRequest;
};

export type AwsAssociateSubnetsResponseDataType = {
  associateAwsSubnets: IGqlResponseNewData<AwsAssociateSubnetsResponse>;
};

const associateAwsSubnetsMutation = () =>
  useMutation<AwsAssociateSubnetsResponseDataType, AwsAssociateSubnetsVariablesType>(query);

export default associateAwsSubnetsMutation;
