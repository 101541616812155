import { useLazyQuery } from '@apollo/client';
import { IGqlResponseNewData } from 'graphql/types';
import {
  IGetAwsListResourceProfileArtifactsRequest,
  IGetAwsListResourceProfileArtifactsResponse
} from 'graphql/types/AwsResourceProfileArtifacts';
import query from './query';

export interface IGetAwsListResourceProfileArtifactsVariables {
  cloudId: number;
  region: string;
  request: IGetAwsListResourceProfileArtifactsRequest;
}

export interface IGetAwsListResourceProfileArtifactsResponseData {
  getAwsListResourceProfileArtifacts: IGqlResponseNewData<IGetAwsListResourceProfileArtifactsResponse>;
}

const lazyGetAwsListResourceProfileArtifacts = () =>
  useLazyQuery<IGetAwsListResourceProfileArtifactsResponseData, IGetAwsListResourceProfileArtifactsVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsListResourceProfileArtifacts;
