export enum ErrorCode {
  /** 성공 */
  SUCCESS = 'sc0000',
  /** 사용자 아이디 중복 */
  USER_ID_DUPLICATED = 'er0001',
  /** 아이디 규칙 오류 */
  INVALID_USERNAME_RULE = 'er0002',
  /** 비밀번호 규칙 오류 */
  INVALID_PASSWORD_RULE = 'er0003',
  /** 필수 약관 미동의 */
  ESSENTIALS_NOT_AGREED = 'er0004',
  /** 로그인 정보 오류 */
  INVALID_USER = 'er0005',
  /** 이메일 인증 미진행 상태 */
  EMAIL_UNAUTHORIZED = 'er0006',
  /** 필수 약관 미진행 상태 */
  ESSENTIALS_AGREEMENT_NEEDED = 'er0007',
  /** 등록되지 않은 아이디 또는 비밀번호입니다. */
  USER_INFO_NOT_FOUND = 'er0011',
  /** 성공. 단, 사용자 확인 정보 존재 */
  PARTIAL_SUCCESS = 'sc0001',
  /** 알 수 없는 오류 */
  UNKNOWN = 'erfff',
  UNKNOWN2 = 'er9999',
  /** 토큰 오류 */
  INVLAID_AUTH_TOKEN = 'er9000',
  /** 토큰 오류-유효기간 */
  TOKEN_ERROR = 'er9001',
  NO_TOKEN = 'er9005',
  EMAIL_TOKEN_ERROR = 'er9002',
  PROJECT_DUPLICATED = 'er0023',
  /* 이벤트세팅에서 사용중 */
  USING_IN_EVENT_SETTING = 'er0025',
  /* 이미지 미지원확장자 */
  INVALID_EXTENSION= 'er8005',
  /* 이메일 변경 시 해당 이메일이 이미 이미 사용중 */
  ALREADY_EXISTED_EMAIL = 'er0024',
  /* 이미 타조직에 가입된 구성원 */
  ALREADY_ASSIGNED_ORGAN = 'er0026',
  /* 로그인 시도 시 mfa 인증 추가로 필요 */
  MFA_REQUIRED = 'sc0002',
  /** mfa passcode 안맞음 */
  MFA_PASSCODE_ERROR = 'er9009',
}

export const ErrorMessage = {
  [ErrorCode.SUCCESS]: 'success',
  [ErrorCode.USER_ID_DUPLICATED]: 'id duplicated',
  [ErrorCode.INVALID_USERNAME_RULE]: 'invalid username rules',
  [ErrorCode.INVALID_PASSWORD_RULE]: 'invalid password rules',
  [ErrorCode.ESSENTIALS_NOT_AGREED]: 'required terms not agreed',
  [ErrorCode.INVALID_USER]: 'Login error',
  [ErrorCode.EMAIL_UNAUTHORIZED]: 'email unauthorized',
  [ErrorCode.ESSENTIALS_AGREEMENT_NEEDED]: 'required terms agree needed',
  [ErrorCode.USER_INFO_NOT_FOUND]: '"The ID (email) or password you entered is incorrect. (er0005)',
  [ErrorCode.PARTIAL_SUCCESS]: '성공. 단, 사용자 확인 정보 존재',
  [ErrorCode.UNKNOWN]: 'An unknown error has occurred. (er9999)',
  [ErrorCode.INVLAID_AUTH_TOKEN]: '토큰 오류',
  [ErrorCode.TOKEN_ERROR]: '토큰 오류',
  [ErrorCode.UNKNOWN2]: 'An unknown error has occurred. (er9999)',
  [ErrorCode.NO_TOKEN]: '토큰 없음',
  [ErrorCode.EMAIL_TOKEN_ERROR]: '이메일 토큰 오류',
  [ErrorCode.PROJECT_DUPLICATED]: '프로젝트 이름 중복',
  [ErrorCode.USING_IN_EVENT_SETTING]: '이벤트설정 내 사용중인 이벤트 규칙',
  [ErrorCode.INVALID_EXTENSION]: '미지원 이미지 확장자',
  [ErrorCode.ALREADY_EXISTED_EMAIL]: 'This email has already been verified.',
  [ErrorCode.ALREADY_ASSIGNED_ORGAN]: 'Already belong to an organization.',
  [ErrorCode.MFA_REQUIRED]: 'MFA code required',
  [ErrorCode.MFA_PASSCODE_ERROR]: 'MFA code is wrong. Check and retry.'
};