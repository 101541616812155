import { gql } from '@apollo/client';

const query = gql`
  mutation untagAwsResourceSecrets($reqData: AwsUntagResourceSecretsRequest, $cloudId: Long, $region: String) {
    untagAwsResourceSecrets(untagResourceSecretsRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
    }
  }
`;
export default query;
