import { ErrorCode } from '@Types/error';
import IconEditVPCAndSubnets from 'assets/svgs/v3/ico_edit_vpc_subnets.svg';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import Icon from 'components/v2/atoms/Icon';
import InputAtom from 'components/v2/atoms/InputAtom';
import BaseModal from 'components/v2/modals/BaseModal';
import associateAwsSubnetsMutation, { AwsAssociateSubnetsVariablesType } from 'graphql/mutations/associateAwsSubnets';
import disassociateAwsSubnetsMutation, {
  AwsDisAssociateSubnetsVariablesType,
} from 'graphql/mutations/disassociateAwsSubnets';
import lazyGetAwsSubnetsPageByParam, { IAwsSubnetsVariables } from 'graphql/queries/getAwsSubnets';
import { AwsSubnetMapping } from 'graphql/types/AwsAssociateSubnet';
import { AwsSubnetType } from 'graphql/types/AwsSubnet';
import { useToast } from 'hooks/v2/useToast';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IAwsFirewall, IBaseFirewallModalProps } from '../../../types';
import './index.scss';
import { AwsSubnetMappingResponseType } from 'graphql/types/AwsFirewall';

interface IEditVPCAndSubnetsProps extends IBaseFirewallModalProps {
  vpcId: string;
}

export interface RowSubnetData {
  availabilityZoneId: string;
  availabilityZone: string;
  subnetId: string;
  ipAddressType: string;
}

const IpAddressTypeList: DropdownListDataType[] = [
  { id: 0, name: 'IPv4', value: 'IPV4' },
  { id: 1, name: 'Dualstack', value: 'Dualstack' },
  { id: 2, name: 'IPv6', value: 'IPV6' },
];

const initialRow: RowSubnetData = {
  availabilityZone: '',
  availabilityZoneId: '',
  subnetId: '',
  ipAddressType: '',
};

const EditVPCAndSubnetsModal = ({
  header,
  cloudId,
  region,
  data,
  setData = () => { },
  vpcId,
  ...baseModalProps
}: IEditVPCAndSubnetsProps) => {
  const { updateToken = '', firewall, firewallStatus } = data || {};
  const { firewallName = '', firewallArn = '', subnetMappings = [] } = firewall || {};
  const syncStates = firewallStatus?.syncStates || {};

  const [initialRows, setInitialRows] = useState<RowSubnetData[]>([]);
  const [allRows, setAllRows] = useState<RowSubnetData[]>([]);
  const [awsSubnets, setAwsSubnets] = useState<AwsSubnetType[]>([]);
  const [error, setError] = useState<string>('');

  const [getAwsSubnet] = lazyGetAwsSubnetsPageByParam();
  const [associateAwsSubnets] = associateAwsSubnetsMutation();
  const [disassociateAwsSubnets] = disassociateAwsSubnetsMutation();

  const allZones: DropdownListDataType[] = useMemo(() => {
    return awsSubnets.map(awsSubnet => {
      return {
        name: awsSubnet.availabilityZone,
        value: awsSubnet.availabilityZoneId,
      };
    });
  }, [awsSubnets]);

  // get availableZone for each row
  const getAvailableZones = useCallback(
    (row: RowSubnetData) => {
      const selectedZoneIds = allRows.map(row => row.availabilityZoneId);
      const rowIdx = allRows.findIndex(item => item.availabilityZoneId === row.availabilityZoneId);

      if (rowIdx === 0) {
        return allZones;
      }

      const excludedZoneIds = selectedZoneIds.slice(0, rowIdx);

      const excludedZones = allZones.filter(zone => !excludedZoneIds.includes(zone.value.toString()));

      return excludedZones;
    },
    [allRows],
  );

  // get condition disable add button
  const isDisableAddBtn = useMemo(() => {
    return allRows.length === awsSubnets.length;
  }, [allRows, awsSubnets]);

  // get available subnet
  const getAvailableSubnet = useCallback(
    (availabilityId: string): DropdownListDataType[] => {
      const awsSubnetFiltered = awsSubnets.filter(awsSubnet => awsSubnet.availabilityZoneId === availabilityId);
      return awsSubnetFiltered.map(awsSubnet => {
        return {
          name: awsSubnet?.subnetId,
          value: awsSubnet?.subnetId,
        };
      });
    },
    [awsSubnets],
  );

  // handle add new row
  const handleAddNewRow = () => {
    setAllRows(prevState => prevState.concat(initialRow));
  };

  // handle remove row
  const handleRemove = (indexRemove: number) => {
    const updateRows = allRows.filter((_, index) => index !== indexRemove);
    setAllRows(updateRows);
  };

  // handle change dropdown availabilityZone
  const handleChangeZone = (index: number, value: string) => {
    const updateRows = [...allRows];
    const availabilityZone = allZones.find(zone => zone.value === value)?.name ?? '';
    updateRows[index] = { ...updateRows[index], availabilityZone, availabilityZoneId: value, subnetId: '' };
    setAllRows(updateRows);
  };

  const handleChangeSubnet = (index: number, value: string) => {
    if (allRows[index].subnetId === value) {
      return;
    }
    const updateRows = [...allRows];
    updateRows[index] = { ...updateRows[index], subnetId: value, ipAddressType: '' };
    setAllRows(updateRows);
  };

  // handle dropdown ip address
  const handleChangeIp = (index: number, value: string) => {
    const updateRows = [...allRows];
    updateRows[index] = { ...updateRows[index], ipAddressType: value };
    setAllRows(updateRows);
  };

  // call API delete subnets
  const handleDeleteSubnets = async (rows: RowSubnetData[], updateToken: string) => {
    try {
      const subnetIds = rows.map(r => r.subnetId);

      const variables: AwsDisAssociateSubnetsVariablesType = {
        cloudId,
        region,
        request: {
          firewallArn,
          firewallName,
          updateToken,
          subnetIds,
        },
      };

      const res = await disassociateAwsSubnets({ variables });
      return res.data?.disassociateAwsSubnets.data?.[0];
    } catch (error) { }
  };

  // call API add subnets
  const handleAddSubnets = async (rows: RowSubnetData[], updateToken: string) => {
    try {
      let subnetMappings: AwsSubnetMapping[] = rows.map(r => ({
        subnetId: r.subnetId,
        ipAddressType: r.ipAddressType,
      }));

      const uniqueSubnetMappings: Map<string, AwsSubnetMapping> = new Map(
        subnetMappings.map(subnet => [subnet.subnetId, subnet]),
      );
      subnetMappings = Array.from(uniqueSubnetMappings.values());

      const variables: AwsAssociateSubnetsVariablesType = {
        cloudId,
        region,
        request: {
          firewallArn,
          firewallName,
          updateToken,
          subnetMappings,
        },
      };

      const res = await associateAwsSubnets({ variables });
      return res.data?.associateAwsSubnets?.data?.[0];
    } catch (error) { }
  };

  // call api update subnet
  const handleUpdateSubnets = async (subnetsInsert: RowSubnetData[], subnetsDelete: RowSubnetData[]) => {
    try {
      let updToken = updateToken;

      let updateSubnetMappings = subnetMappings;

      if (subnetsInsert.length > 0) {
        const addResponse = await handleAddSubnets(subnetsInsert, updToken);

        if (!addResponse) {
          useToast(ErrorCode.UNKNOWN, 'Update VPC and subnets failed.');
          baseModalProps?.onClose?.();
          return;
        }
        updToken = addResponse.updateToken;
        updateSubnetMappings = addResponse.subnetMappings
      }

      if (subnetsDelete.length > 0) {
        const deleteResponse = await handleDeleteSubnets(subnetsDelete, updToken);
        updToken = deleteResponse?.updateToken || '';

        if (!deleteResponse) {
          useToast(ErrorCode.UNKNOWN, 'Update VPC and subnets failed.');
          baseModalProps?.onClose?.();
          return;
        }
        updateSubnetMappings = deleteResponse.subnetMappings

      }

      setData((prevState: IAwsFirewall) => ({
        ...prevState,
        updateToken: updToken,
        firewall: {
          ...prevState.firewall,
          subnetMappings: updateSubnetMappings,
        },
      }));
      useToast(ErrorCode.SUCCESS, 'Update VPC and subnets successfully.');
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Update VPC and subnets failed.');
    } finally {
      baseModalProps?.onClose?.();
    }
  };

  // validate 
  const validateSubnets = (subnets: RowSubnetData[]) => {
    let error = '';

    subnets.forEach(subnet => {
      if (!subnet.ipAddressType) {
        error = 'Member must satisfy enum value set: [IPV4, IPV6, DUALSTACK]';
        return;
      }

      if (!subnet.subnetId) {
        error = 'Given subnet(s) is/are inaccessible from account';
        return;
      }
    });

    return error;
  };

  // handle click button save
  const handleSave = () => {
    let subnetsInsert = allRows.filter(row => !initialRows.some(item => JSON.stringify(row) === JSON.stringify(item)));
    subnetsInsert = subnetsInsert.filter(subnet => !!subnet.availabilityZoneId);
    const subnetDelete = initialRows.filter(row => !allRows.some(item => JSON.stringify(item) === JSON.stringify(row)));
    const error = validateSubnets(subnetsInsert);

    if (error) {
      setError(error);
      return;
    }

    handleUpdateSubnets(subnetsInsert, subnetDelete);
  };

  useEffect(() => {
    const variables: IAwsSubnetsVariables = {
      cloudId,
      region,
      request: {
        maxResults: 1000,
        filters: {
          name: 'vpc-id',
          values: [vpcId],
        },
      },
    };

    getAwsSubnet({ variables }).then(res => {
      const awsSubnets = res.data?.getAwsSubnets.data[0].subnets || [];

      const rowSubnets: RowSubnetData[] = subnetMappings.map(subnet => {
        const availabilityZone = Object.keys(syncStates).find(
          key => syncStates[key].attachment.subnetId === subnet.subnetId,
        );
        const availabilityZoneId =
          awsSubnets.find(item => item.availabilityZone === availabilityZone)?.availabilityZoneId ?? '';

        return {
          availabilityZone: availabilityZone || '',
          availabilityZoneId,
          ...subnet,
        };
      });

      setInitialRows(rowSubnets);
      setAllRows(rowSubnets);
      setAwsSubnets(awsSubnets);
    });
  }, []);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditVPCAndSubnets} /> {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-vpc-subnets-modal">
        <div className="vpc-id">
          <p>Vpc</p>
          <InputAtom value={vpcId} disabled={true} />
        </div>
        <div className="firewall-subnet">
          <p className="title">Firewall subnets</p>
          <p className="description">
            Each subnet must have one available IP address.
            <br />
            You can't change the subnet's IP address type after creation.
          </p>
        </div>
        <table className="table">
          <thead className="table-header">
            <tr>
              <th>Availability zone</th>
              <th>Subnet</th>
              <th>IP address type</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {allRows.map((row, index) => (
              <tr key={index}>
                <td>
                  <DropdownAtom
                    id={'new-availability-zone' + `${index}`}
                    data={getAvailableZones(row)}
                    placeholder={'Choose an available zone'}
                    value={{
                      name: row.availabilityZone,
                      value: row.availabilityZoneId,
                    }}
                    handleClick={val => handleChangeZone(index, val.value as string)}
                  />
                </td>
                <td>
                  <DropdownAtom
                    id={'new-subnet' + `${index}`}
                    data={getAvailableSubnet(row.availabilityZoneId)}
                    placeholder={'Choose a subnet'}
                    disabled={row.availabilityZoneId === ''}
                    value={{
                      name: row.subnetId,
                      value: row.subnetId,
                    }}
                    handleClick={val => handleChangeSubnet(index, val.value as string)}
                  />
                </td>
                <td>
                  <DropdownAtom
                    id={'new-ip-address-type' + `${index}`}
                    data={IpAddressTypeList}
                    placeholder={'Choose an IP address'}
                    disabled={row.subnetId === ''}
                    value={{
                      name: row.ipAddressType,
                      value: row.ipAddressType,
                    }}
                    handleClick={val => handleChangeIp(index, val.value as string)}
                  />
                </td>
                <td>
                  <button
                    onClick={() => {
                      handleRemove(index);
                    }}
                  >
                    Remove subnet
                  </button>
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <button
                  disabled={isDisableAddBtn}
                  className={`add-button ${isDisableAddBtn ? 'disabled' : ''}`}
                  onClick={handleAddNewRow}
                >
                  Add new subnet
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button-group flex a-center">
          <span className="error">{error}</span>
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="save-btn" onClick={handleSave}>
            Save changes
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditVPCAndSubnetsModal;
