import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import { EBSSnapshotPropsType } from '../types';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { EBS_SNAPSHOT_FILTER_DROPDOWN, SCOPE_DROPDOWN, SNAPSHOT_STATE_ENUM_MAPPING } from './Common/Constants';
import lazyGetAwsAllDescribeSnapshots, {
  IAwsDescribeSnapshotsVariables,
} from 'graphql/queries/getAwsAllDescribeSnapshots';
import { FilterPropsType } from '../Image/types';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { AwsSnapshotType } from 'graphql/types/AwsDescribeSnapshots';
import { variableCombineNextToken } from '../../../Utils';
import { orderAlphabetical } from '../../../Utils/Sorting';
import InputAtom from 'components/v2/atoms/InputAtom';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { capitalize } from '../Image/Common/Functions';
import lazyGetAwsListAliases, { IAwsListAliasesVariables } from 'graphql/queries/getAwsListAliases';
import { getFormatedDateWithTimezone, handleFormatText } from 'utils/Common';

const EBSSnapshot = (props: EBSSnapshotPropsType) => {
  const { cloudId, region, tabInformation, onSnapshotSelected, onSnapshotIdClicked } = props;

  // API
  const [getAwsAllDescribeSnapshots, {loading: awsDescribeSnapshots}] = lazyGetAwsAllDescribeSnapshots();
  const [getAwsListAliases, {loading: awsListAliases}] = lazyGetAwsListAliases();

  // State
  const [scopeDropdownValue, setScopeDropdownValue] = useState<DropdownListDataType>(SCOPE_DROPDOWN[0]);
  const [snapshotFilterDropdownValue, setSnapshotFilterDropdownValue] = useState<DropdownListDataType>(
    EBS_SNAPSHOT_FILTER_DROPDOWN[0],
  );
  const [snapshotFilterInputValue, setSnapshotFilterInputValue] = useState<FilterPropsType>({
    name: snapshotFilterDropdownValue.value as string,
    value: '',
  });

  const [mainTblTotalRows, setMainTblTotalRows] = useState<RowType[]>([]);
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [scopeNFilterVariable, setScopeNFilterVariable] = useState<FilterPropsType>();

  const apiIsLoading = useMemo(() => {
    return awsDescribeSnapshots || awsListAliases;
  }, [awsDescribeSnapshots, awsListAliases]);

  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const mainTblColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Name', field: 'nameTag', sort: true },
      {
        label: 'Snapshot ID',
        field: 'snapshotId',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  onSnapshotSelected(row as AwsSnapshotType);
                  onSnapshotIdClicked();
                }}
              >
                {handleFormatText(row?.snapshotId)}
              </div>
            }
          </>
        ),
      },
      { label: 'Volume size', field: 'volumeSize', sort: true },
      { label: 'Description', field: 'description', sort: true },
      { label: 'Storage tier', field: 'storageTier', sort: true },
      {
        label: 'Snapshot status',
        field: 'state',
        sort: true,
        renderCell: (row: RowType) => (
          <div className={SNAPSHOT_STATE_ENUM_MAPPING[row?.state ?? '-'].style}>
            {SNAPSHOT_STATE_ENUM_MAPPING[row?.state ?? '-'].text}
          </div>
        ),
      },
      { label: 'Started', field: 'started', sort: true },
      { label: 'Progress', field: 'progress', sort: true },
      { label: 'Encryption', field: 'encrypted', sort: true },
      { label: 'KMS key ID', field: 'kmsKeyId', sort: true },
      { label: 'KMS key alias', field: 'kmsKeyAliasName', sort: true },
      { label: 'Outposts ARN', field: 'outpostArn', sort: true },
    ];
  }, []);

  const handleScropeDropdownOnChange = useCallback((val: DropdownListDataType) => {
    setScopeDropdownValue(val);
  }, []);

  const awsDescribeImageVariable = useMemo((): IAwsDescribeSnapshotsVariables => {
    let scropeFilter = {};

    switch (scopeDropdownValue) {
      case SCOPE_DROPDOWN[0]:
        scropeFilter = { ownerIds: 'self' };
        break;
      case SCOPE_DROPDOWN[1]:
        scropeFilter = { restorableByUserIds: 'self' };
        break;
      case SCOPE_DROPDOWN[2]:
        scropeFilter = { restorableByUserIds: 'all' };
        break;
      default:
        break;
    }

    if (scopeNFilterVariable) {
      const searchFilterItem = { name: scopeNFilterVariable.name, values: [scopeNFilterVariable.value] };
      switch (scopeDropdownValue) {
        case SCOPE_DROPDOWN[0]:
          scropeFilter = { ownerIds: 'self', filters: [{ ...searchFilterItem }] };
          break;
        case SCOPE_DROPDOWN[1]:
          scropeFilter = { restorableByUserIds: 'self', filters: [{ ...searchFilterItem }] };
          break;
        case SCOPE_DROPDOWN[2]:
          scropeFilter = { restorableByUserIds: 'all', filters: [{ ...searchFilterItem }] };
          break;
        default:
          break;
      }
    }

    return {
      cloudId: cloudId,
      region: region,
      request: { maxResults: mainTablePagination.limit, ...scropeFilter },
    };
  }, [scopeDropdownValue, cloudId, region, scopeNFilterVariable]);

  const handleSearchFunction = useCallback(() => {
    if (snapshotFilterInputValue.value !== '') {
      setScopeNFilterVariable({ name: snapshotFilterInputValue.name, value: snapshotFilterInputValue.value });
    } else {
      setScopeNFilterVariable(undefined);
    }
  }, [snapshotFilterInputValue]);

  const handleMainRowCheckedList = useCallback((listRowId: string[]) => {
    if (listRowId.length > 0) {
      const lastRowId = listRowId.at(-1);
      const rowSelected = mainTblTotalRows.find(row => row.id === lastRowId);
      if (!rowSelected) return;
      onSnapshotSelected(rowSelected as AwsSnapshotType);
    } else {
      onSnapshotSelected();
    }
  }, []);

  const handleMainRowSelected = useCallback(
    (rowId: string) => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);

      if (!rowSelected) return;

      onSnapshotSelected(rowSelected as AwsSnapshotType);
    },
    [mainTblTotalRows],
  );

  const fetchDescribeImages = useCallback(
    (nextToken?: string, isInitial?: boolean) => {
      const combinedVariable = variableCombineNextToken(awsDescribeImageVariable, nextToken);
      getAwsAllDescribeSnapshots({ variables: combinedVariable }).then(({ data: awsDescribeSnapshotData }) => {
        const awsDescribeSnapshots = awsDescribeSnapshotData?.getAwsAllDescribeSnapshots;
        const snapshotRows: RowType[] = [];
        // TODO: Waiting BE handle filter function.
        if (awsDescribeSnapshots?.data) {
          awsDescribeSnapshots.data.map(async snapshot => {
            let snapshotResult: RowType = {
              ...snapshot,
              id: snapshot?.snapshotId,
              nameTag: snapshot?.tags?.find(t => t?.key === 'Name')?.value,
              description: snapshot?.description ?? '-',
              volumeSize: snapshot?.volumeSize ? snapshot?.volumeSize + ' GiB' : '-',
              storageTier: capitalize(snapshot?.storageTier),
              started: getFormatedDateWithTimezone(snapshot?.startTime),
              progress: snapshot?.progress === '100%' ? 'Available' : 'Unavailable',
              encrypted: snapshot?.encrypted === true ? 'Encrypted' : 'Not encrypted',
              kmsKeyId: snapshot?.kmsKeyId ?? '-',
              kmsKeyAliasName: '-',
              kmsKeyAliasArn: '-',
              outpostArn: snapshot?.outpostArn ?? '-',
            };
            if (snapshot?.kmsKeyId) {
              const listAliasVariable: IAwsListAliasesVariables = {
                cloudId: cloudId,
                region: region,
                request: {
                  keyId: snapshot.kmsKeyId,
                },
              };
              const alias = await getAwsListAliases({ variables: listAliasVariable }).then(
                ({ data: awsListAlises }) => {
                  return awsListAlises?.getAwsListAliases?.data?.[0]?.aliases?.[0];
                },
              );

              snapshotResult = {
                ...snapshotResult,
                kmsKeyId: snapshot.kmsKeyId,
                kmsKeyAliasName: alias?.aliasName,
                kmsKeyAliasArn: alias?.aliasArn,
              };
            }
            snapshotRows.push(snapshotResult);
          });
        }

        let allImages: RowType[] = [];
        if (isInitial) {
          allImages = snapshotRows;
        } else {
          allImages = [...mainTblTotalRows, ...snapshotRows];
        }
        setMainTblTotalRows(allImages);
        setMainNextToken(awsDescribeSnapshots?.nextToken as string);
        setMainTblTotal({
          totalPage: Math.ceil(allImages.length / mainTablePagination.itemPerPage),
          totalElement: allImages.length,
        });
      });
    },
    [awsDescribeImageVariable, mainTblTotalRows, mainNextToken, mainTblTotal, mainTablePagination, cloudId, region],
  );

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchDescribeImages(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(mainTblTotalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotalRows, mainTblTotal, mainTablePagination, mainNextToken, snapshotFilterInputValue]);

  // For initial data
  useEffect(() => {
    fetchDescribeImages('', true);
  }, [awsDescribeImageVariable]);

  return (
    <Fragment>
      <div id="image-listup" className="row-3 flex j-between a-center">
        <div className="title-container">
          <DropdownAtom
            id="scope-dropdown"
            className=""
            data={SCOPE_DROPDOWN}
            value={scopeDropdownValue}
            handleClick={val => handleScropeDropdownOnChange(val)}
          />

          <div className="title">
            <p>{tabInformation.title}</p>
          </div>
        </div>

        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={EBS_SNAPSHOT_FILTER_DROPDOWN}
            value={snapshotFilterDropdownValue}
            handleClick={val => {
              setSnapshotFilterDropdownValue(val);
              setSnapshotFilterInputValue(prev => ({ ...prev, name: val.value as string }));
            }}
          />

          <InputAtom
            type={'text'}
            value={snapshotFilterInputValue.value}
            defaultValue={''}
            onChangeValue={str => setSnapshotFilterInputValue(prev => ({ name: prev?.name, value: str }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
            onKeyDown={event => {}}
          />

          <button className="action-btn" onClick={handleSearchFunction}>
            Search
          </button>
        </div>
      </div>

      <div className="data-grid-wrap">
        {mainRowsCurrentPage.length === 0 && apiIsLoading === false ? (
          <p className="empty-row">Empty</p>
        ) : (
          <Table
            rows={mainRowsCurrentPage}
            columns={mainTblColumns}
            reportCheckedList={(list: string[]) => handleMainRowCheckedList(list)}
            reportSelected={id => handleMainRowSelected(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                setMainTablePagination(prev => ({
                  ...prev,
                  target: target,
                  direction: dir,
                }));
              },
            }}
            isLoading={apiIsLoading}
            horizontalScrollable={true}
          />
        )}

        {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && apiIsLoading === false && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center">
              Total <span>{mainTblTotal.totalElement}</span>
            </p>
            <TableManagePagination
              ableFetchMore={!!mainNextToken}
              currentPage={mainTablePagination.currentPage}
              updateCurrentPage={page =>
                setMainTablePagination(prev => ({
                  ...prev,
                  ['currentPage']: page,
                }))
              }
              totalPage={mainTblTotal.totalPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default EBSSnapshot;
