import { gql } from "@apollo/client";

const query = gql`
  mutation deleteAwsFirewall($request: AwsDeleteFirewallRequest, $cloudId: Long, $region: String) {
    deleteAwsFirewall(
      deleteFirewallRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsDeleteFirewallResponse {
          firewall
          firewallStatus
        }
      }
    }
  }
`

export default query;