import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { handleLinkClicked } from 'pages/v2/Organ/Management/Utils';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import { EC2_TAB_LIST, INSTANCE_SEARCH_TYPES, LINK } from 'pages/v2/Organ/Management/configs';
import { useNavigate } from 'react-router-dom';

function Details({ data, isLoading, handleLinkDetail }: any) {
  const {
    volumeId,
    size,
    volumeType,
    state,
    iops,
    throughput,
    encryption,
    kmsKeyId,
    fastRestored,
    snapshotId,
    availabilityZone,
    createTime,
    multiAttachEnabled,
    attachments,
    volumeStatus,
    alias,
  } = data || {};

  const navigate = useNavigate();

  const detailsData: IMgdDetailKeyValueProps[] = [
    {
      title: 'Volume ID',
      description: volumeId,
      type: TextTypeEnum.COPY_LINK,
      handleItemClick: handleLinkDetail,
    },
    {
      title: 'Size',
      description: size ? `${size} GIB` : '',
    },
    {
      title: 'Type',
      description: volumeType,
    },
    {
      title: 'Volume status',
      description: volumeStatus?.volumeStatus?.status ? 'Okay' : '',
    },
    {
      title: 'Volume state',
      description: state || '',
    },
    {
      title: 'IOPS',
      description: iops,
    },
    {
      title: 'Throughput',
      description: throughput,
    },
    {
      title: 'Encryption',
      description: encryption ? '' : 'Not encrypted',
    },
    {
      title: 'KMS key ID',
      description: kmsKeyId,
    },
    {
      title: 'KMS key alias',
      description: alias?.targetKeyId,
    },
    {
      title: 'KMS key ARN',
      description: alias?.aliasName?.replace('alias/', ''),
    },
    {
      title: 'Fast snapshot restored',
      description: fastRestored,
    },
    {
      title: 'SnapShot',
      description: snapshotId,
      type: TextTypeEnum.COPY,
    },
    {
      title: 'Availability zone',
      description: availabilityZone,
    },
    {
      title: 'Created',
      description: createTime,
    },
    {
      title: 'Multi-Attach enabled',
      description: multiAttachEnabled,
    },
    {
      title: 'Attached resources',
      description: attachments?.length ? attachments.map((e: any) => `${e.instanceId}:${e.device}(${e.state})`) : '',
      type: TextTypeEnum.LINK,
      handleItemClick: attachments?.length
        ? (value: string) =>
            handleLinkClicked({
              navigate,
              link: LINK.INSTANCE,
              type: ManagementTypeEnum.NETWORK,
              tabId: EC2_TAB_LIST[0].id,
              key: INSTANCE_SEARCH_TYPES[0].value.toString(),
              value: value?.split(':')?.[0],
            })
        : null,
    },
    {
      title: 'Outposts',
      description: '',
    },
  ];

  return <DetailTab title="Details" data={detailsData} isApiLoading={isLoading} />;
}

export default Details;
