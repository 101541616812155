import { ErrorCode } from '@Types/error';
import { ColumnType, RowType } from '@Types/v2/Table';
import createAwsTagsMutation from 'graphql/mutations/createAwsTags';
import deleteAwsSecurityGroupMutation, {
  IDeleteAwsSecurityGroupVariables,
} from 'graphql/mutations/deleteAwsSecurityGroup';
import deleteAwsTagsMutation, { IDeleteTagsVariables } from 'graphql/mutations/deleteAwsTags';
import lazyGetAllAwsSecurityGroups, { IAwsSecurityGroupsPageVariables } from 'graphql/queries/getAwsAllSecurityGroups';
import lazyGetAwsManagedPrefixLists from 'graphql/queries/getAwsManagedPrefixLists';
import { IGqlResponseData } from 'graphql/types';
import { AwsSecurityGroupType } from 'graphql/types/AwsSecurityGroup';
import { AwsTagType } from 'graphql/types/AwsVpc';
import { useToast } from 'hooks/v2/useToast';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import { TextTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import { IMgdTabProps } from 'layouts/v3/MgdTabLayout/types';
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { randomString } from 'utils/Common';
import { ManagementTypeEnum } from '../../..';
import TagTab from '../../../EC2/components/TagTab';
import { handleLinkClicked } from '../../../Utils';
import { vpcFilterDropdown } from '../../Constants';
import { NETWORK_TAB_LIST } from '../../configs';
import DeleteSecurityGroupModal from '../DeleteSecurityGroup';
import DetailTab from '../DetailTab';
import UpdateTagsModal, { TagRowData } from '../NetworlAcl/Modals/UpdateTagsModal';
import PageDetailTitle from '../PageDetailTitle';
import EditSecurityGroupRuleModal from './EditSecurityGroupRuleModal';
import InboundOutbound from './Tabs/InboundOutbound';
import { DETAIL_TAB_LIST } from './configs';
import { SecurityGroupDetailPropsType } from './types';

const sourceDestinationOptionDefault = {
  id: randomString(),
  name: 'CIDR blocks',
  value: 'cidr-blocks',
  children: [
    {
      value: '0.0.0.0/0',
      name: '0.0.0.0/0',
    },
    {
      value: '0.0.0.0/8',
      name: '0.0.0.0/8',
    },
    {
      value: '0.0.0.0/16',
      name: '0.0.0.0/16',
    },
    {
      value: '0.0.0.0/24',
      name: '0.0.0.0/24',
    },
    {
      value: '0.0.0.0/32',
      name: '0.0.0.0/32',
    },
    {
      value: '::/0',
      name: '::/0',
    },
    {
      value: '::/16',
      name: '::/16',
    },
    {
      value: '::/32',
      name: '::/32',
    },
    {
      value: '::/48',
      name: '::/48',
    },
    {
      value: '::/64',
      name: '::/64',
    },
  ],
};

const SecurityGroupDetail = (props: SecurityGroupDetailPropsType) => {
  const navigate = useNavigate();

  const { securityGroup, cloudId, region, pageBackClick } = props;

  const [detailSecurityGroup, setDetailSecurityGroup] = useState<AwsSecurityGroupType>(securityGroup);
  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>(DETAIL_TAB_LIST[0]);
  const [sourceDestinationOption, setSourceDestinationOption] = useState<any>([{ ...sourceDestinationOptionDefault }]);
  const [securityGroups, setSecurityGroups] = useState<any>([]);
  const [prefixLists, setPrefixLists] = useState<any>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [isOpenEditRule, setIsOpenEditRule] = useState<boolean>(false);
  const [isEditInbound, setIsEditInbound] = useState(true);
  const [editRuleData, setEditRuleData] = useState<any>([]);
  const [isUpdateTagModalVisible, setUpdateTagModalVisible] = useState<boolean>(false);

  const [getAwsAllSecurityGroups, { loading: awsSecurityGroupsLoading }] = lazyGetAllAwsSecurityGroups();
  const [getAwsManagedPrefixLists] = lazyGetAwsManagedPrefixLists();
  const [deleteAwsSecurityGroup, { loading: isLoadingDeleteAwsSecurityGroup }] = deleteAwsSecurityGroupMutation();
  const [createAwsTags, { loading: createTagLoading }] = createAwsTagsMutation();
  const [deleteAwsTags, { loading: deleteTagLoading }] = deleteAwsTagsMutation();

  const handleGetAwsAllSecurityGroups = async () => {
    const variables = {
      cloudId: cloudId,
      region: region as string,
      request: {
        maxResults: 1000,
        filters: { name: 'vpc-id', values: [detailSecurityGroup?.vpcId] },
      },
    };

    const { data: awsSecurityGroupData } = await getAwsAllSecurityGroups({ variables });

    const getAwsAllSecurityGroup = awsSecurityGroupData?.getAwsAllSecurityGroups;

    if (!getAwsAllSecurityGroup?.data?.length) {
      setSecurityGroups([]);
      return;
    }

    const children: any = getAwsAllSecurityGroup?.data?.map(sg => ({
      id: randomString(),
      name: `${sg.groupName} | ${sg.groupId}`,
      value: sg.groupId,
    }));

    setSecurityGroups(children);
  };

  const handleGetAwsManagedPrefixLists = async () => {
    const variables = {
      cloudId: cloudId,
      region: region as string,
      request: {
        maxResults: 100,
      },
    };

    const { data: managedPrefixLists } = await getAwsManagedPrefixLists({ variables });

    const list = managedPrefixLists?.getAwsManagedPrefixLists;

    if (!list?.data?.[0]?.prefixLists?.length) {
      setPrefixLists([]);
      return;
    }

    const children: any = list.data[0].prefixLists.map(e => ({
      id: randomString(),
      name: `${e.prefixListName} | ${e.prefixListId}`,
      value: e.prefixListId,
    }));

    setPrefixLists(children);
  };

  useEffect(() => {
    handleGetAwsManagedPrefixLists();
  }, []);

  useEffect(() => {
    if (!detailSecurityGroup?.vpcId) return;

    handleGetAwsAllSecurityGroups();
  }, [detailSecurityGroup?.vpcId]);

  useEffect(() => {
    const arr = [{ ...sourceDestinationOptionDefault }];

    if (securityGroups?.length) {
      arr.push({
        id: randomString(),
        value: 'Security group',
        name: 'Security group',
        children: securityGroups,
      });
    }

    if (prefixLists?.length) {
      arr.push({
        id: randomString(),
        value: 'Prefix list',
        name: 'Prefix list',
        children: prefixLists,
      });
    }

    setSourceDestinationOption(arr);
  }, [securityGroups, prefixLists]);

  const securityGroupId = useMemo((): string => {
    return detailSecurityGroup?.groupId ?? '';
  }, [detailSecurityGroup]);

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    if (detailSecurityGroup) {
      return [
        {
          id: 'name',
          type: TextTypeEnum.NORMAL,
          description: detailSecurityGroup?.tags?.find((r: { key: string }) => r.key === 'Name')?.value || '-',
          title: 'Security group name',
        },
        {
          id: 'groupId',
          type: TextTypeEnum.NORMAL,
          description: detailSecurityGroup?.groupId,
          title: 'Security group ID',
        },
        {
          id: 'description',
          type: TextTypeEnum.NORMAL,
          description: detailSecurityGroup?.description,
          title: 'Description',
        },
        {
          id: 'vpcId',
          type: TextTypeEnum.LINK,
          description: detailSecurityGroup?.vpcId,
          title: 'VPC ID',
          handleItemClick: () =>
            handleLinkClicked({
              navigate,
              link: '/organ/1/manage/network',
              type: ManagementTypeEnum.NETWORK,
              tabId: NETWORK_TAB_LIST[0].id,
              key: vpcFilterDropdown[0].value.toString(),
              value: detailSecurityGroup?.vpcId,
            }),
        },
        {
          id: 'ownerId',
          type: TextTypeEnum.COPY,
          description: detailSecurityGroup?.ownerId,
          title: 'Owner',
        },
        {
          id: 'inboundRuleCount',
          type: TextTypeEnum.NORMAL,
          description:
            detailSecurityGroup?.ipPermissions?.length > 1
              ? `${detailSecurityGroup?.ipPermissions?.length} permission entries`
              : `${detailSecurityGroup?.ipPermissionsEgress?.length} permission entry`,
          title: 'inbound rules count',
        },
        {
          id: 'outboundRuleCount',
          type: TextTypeEnum.NORMAL,
          description:
            detailSecurityGroup?.ipPermissionsEgress?.length > 1
              ? `${detailSecurityGroup?.ipPermissionsEgress?.length} permission entries`
              : `${detailSecurityGroup?.ipPermissionsEgress?.length} permission entry`,
          title: 'outbound rules count',
        },
      ];
    }
    return [];
  }, [detailSecurityGroup]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return detailSecurityGroup?.tags ?? [];
  }, [detailSecurityGroup]);

  const handleIsOpenEdit = (type: string, data: any) => {
    const isInbound = type === 'inbound';

    setIsEditInbound(isInbound);
    setEditRuleData(data);
    setIsOpenEditRule(true);
  };

  const rightButtonsNode = useMemo((): any => {
    switch (detailTabSelected) {
      default:
      case DETAIL_TAB_LIST[0]:
        return [
          {
            id: 'eidt-inbound-rules',
            label: 'Edit inbound rules',
            onClick: (data: any) => handleIsOpenEdit('inbound', data),
          },
        ];
      case DETAIL_TAB_LIST[1]:
        return [
          {
            id: 'eidt-outbound-rules',
            label: 'Edit outbound rules',
            onClick: (data: any) => handleIsOpenEdit('outbound', data),
          },
        ];
      case DETAIL_TAB_LIST[2]:
        return [{ id: 'message-tags', label: 'Message tags', onClick: () => setUpdateTagModalVisible(true) }];
    }
  }, [detailTabSelected]);

  const detailContentNode = useMemo((): ReactNode => {
    if (awsSecurityGroupsLoading) {
      return (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      );
    }

    switch (detailTabSelected) {
      default:
      case DETAIL_TAB_LIST[0]:
        return (
          <InboundOutbound
            securityGroup={detailSecurityGroup}
            cloudId={cloudId}
            region={region}
            isInbound={true}
            rightButtons={rightButtonsNode}
          />
        );
      case DETAIL_TAB_LIST[1]:
        return (
          <InboundOutbound
            securityGroup={detailSecurityGroup}
            cloudId={cloudId}
            region={region}
            isInbound={false}
            rightButtons={rightButtonsNode}
          />
        );
      case DETAIL_TAB_LIST[2]:
        return <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} rightButtons={rightButtonsNode} />;
    }
  }, [
    detailSecurityGroup,
    cloudId,
    region,
    detailTabSelected,
    tagRows,
    tagColumns,
    rightButtonsNode,
    awsSecurityGroupsLoading,
  ]);

  const handleIsOpenDelete = () => {
    setDeleteModalVisible(true);
  };

  const handleDeleteSecurityGroup = async (groupId: string[]) => {
    if (isLoadingDeleteAwsSecurityGroup) return;

    try {
      const reqVariable: IDeleteAwsSecurityGroupVariables = {
        cloudId,
        region,
        request: {
          groupId,
        },
      };
      const { data: reponseData } = await deleteAwsSecurityGroup({ variables: reqVariable });

      if (reponseData?.deleteAwsSecurityGroup?.result === ErrorCode.SUCCESS) {
        setDeleteModalVisible(false);
        useToast(ErrorCode.SUCCESS, 'Delete security group successful.');
        pageBackClick();
      } else {
        useToast(ErrorCode.UNKNOWN, 'Delete security group failed.');
      }
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Delete security group failed.');
    }
  };

  // management tag
  const currentTagData = useMemo((): TagRowData[] => {
    const tagRowData: TagRowData[] = [];
    if (detailSecurityGroup?.tags?.length) {
      detailSecurityGroup.tags.map((tag, index) => {
        tagRowData.push({
          index: index,
          keyStr: tag.key,
          valueStr: tag.value,
        });
      });
    }
    return tagRowData;
  }, [detailSecurityGroup?.tags]);

  const updateSecurityGroup = useCallback(() => {
    const reqVariable: IAwsSecurityGroupsPageVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        maxResults: 50,
        filters: {
          name: 'group-id',
          values: [detailSecurityGroup?.groupId],
        },
      },
    };
    getAwsAllSecurityGroups({ variables: reqVariable }).then(({ data: responseData }) => {
      const detailResponse = responseData?.getAwsAllSecurityGroups?.data?.[0];
      if (detailResponse) {
        setDetailSecurityGroup(detailResponse);
      }
    });

    setIsOpenEditRule(false);
  }, [detailSecurityGroup, cloudId, region]);

  const handleDeleteTags = useCallback(
    async (tags: TagRowData[]): Promise<IGqlResponseData<undefined> | undefined> => {
      const awsTags: AwsTagType[] = tags.map(currentTag => {
        return { key: currentTag.keyStr, value: currentTag.valueStr };
      });
      const deleteReqVar: IDeleteTagsVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          resources: [securityGroupId],
          tags: awsTags,
        },
      };
      const { data: responseData } = await deleteAwsTags({ variables: deleteReqVar });

      return responseData?.deleteAwsTags;
    },
    [cloudId, region],
  );

  const handleCreateTags = useCallback(
    async (tags: TagRowData[]): Promise<IGqlResponseData<undefined> | undefined> => {
      const awsTags: AwsTagType[] = tags.map(currentTag => {
        return { key: currentTag.keyStr, value: currentTag.valueStr };
      });

      const createReqVar: IDeleteTagsVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          resources: [securityGroupId],
          tags: awsTags,
        },
      };
      const { data: responseData } = await createAwsTags({ variables: createReqVar });

      return responseData?.createAwsTags;
    },
    [cloudId, region],
  );

  const handleUpdateTags = useCallback(
    async (oldTags: TagRowData[], newTags: TagRowData[]) => {
      const isCurrentTagChanged = JSON.stringify(currentTagData) !== JSON.stringify(oldTags);
      if (isCurrentTagChanged) {
        const [deleteResponse, createResponse] = await Promise.all([
          handleDeleteTags(currentTagData),
          handleCreateTags([...oldTags, ...newTags]),
        ]);
        if (deleteResponse?.result === ErrorCode.SUCCESS && createResponse?.result === ErrorCode.SUCCESS) {
          setUpdateTagModalVisible(false);
          useToast(ErrorCode.SUCCESS, 'Update tags successful.');
          updateSecurityGroup();
        } else {
          useToast(ErrorCode.UNKNOWN, 'Update tags failed.');
        }
      } else {
        if (newTags.length > 0) {
          const createResponse = await handleCreateTags(newTags);
          if (createResponse?.result === ErrorCode.SUCCESS) {
            setUpdateTagModalVisible(false);
            useToast(ErrorCode.SUCCESS, 'Update tags successful.');
            updateSecurityGroup();
          } else {
            useToast(ErrorCode.UNKNOWN, 'Update tags failed.');
          }
        } else {
          setUpdateTagModalVisible(false);
        }
      }
    },
    [currentTagData, handleDeleteTags, handleCreateTags],
  );

  return (
    <div className="ec2-detail">
      <PageDetailTitle
        title={`Security Group / ${securityGroupId}`}
        pageBackClick={pageBackClick}
        isDelete
        onDelete={handleIsOpenDelete}
      />

      {securityGroupId && (
        <Fragment>
          <DetailTab title={'Details'} data={detailTabContentData} isApiLoading={false} />

          <div className="tab-container">
            <div className="detail-tab flex a-center">
              {DETAIL_TAB_LIST.map(tab => {
                return (
                  <button
                    className={`detail-tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                    key={tab.id}
                    data-tab={tab.id}
                    onClick={e => setDetailTabSelected(tab)}
                  >
                    <p>{tab.name}</p>
                  </button>
                );
              })}
            </div>

            <div className="content-tab">{detailContentNode}</div>
          </div>
        </Fragment>
      )}

      {deleteModalVisible ? (
        <DeleteSecurityGroupModal
          rows={[detailSecurityGroup]}
          header={'Delete security groups'}
          onDelete={handleDeleteSecurityGroup}
          open={deleteModalVisible}
          onClose={() => setDeleteModalVisible(!deleteModalVisible)}
        />
      ) : null}

      {isOpenEditRule ? (
        <EditSecurityGroupRuleModal
          cloudId={cloudId}
          region={region}
          groupId={detailSecurityGroup?.groupId}
          header={isEditInbound ? 'Edit Inbound rules' : 'Edit Outbound rules'}
          currentData={editRuleData}
          subTitle={
            isEditInbound
              ? 'Inbound rules control the incoming traffic that’s allowed to reach the instance.'
              : 'Outbound rules control the outgoing traffic that’s allowed to reach the instance.'
          }
          isInbound={isEditInbound}
          sourceDestinationOption={sourceDestinationOption}
          open={isOpenEditRule}
          onClose={() => setIsOpenEditRule(false)}
          onSave={updateSecurityGroup}
        />
      ) : null}

      {isUpdateTagModalVisible ? (
        <UpdateTagsModal
          header={'Tags'}
          subHeader={'Available subnets'}
          currentDatas={currentTagData}
          open={isUpdateTagModalVisible}
          onSave={(oldTags: TagRowData[], newTags: TagRowData[]) => handleUpdateTags(oldTags, newTags)}
          onClose={() => setUpdateTagModalVisible(false)}
        />
      ) : null}
    </div>
  );
};

export default SecurityGroupDetail;
