import { ColumnType, RowType } from '@Types/v2/Table';
import lazyGetAwsDescribeSnapshotAttribute, {
  IAwsDescribeSnapshotAttributeVariables,
} from 'graphql/queries/getAwsDescribeSnapshotAttribute';
import lazyGetAwsDescribeSnapshotTierStatus, {
  IGetAwsDescribeSnapshotTierStatusVariables,
} from 'graphql/queries/getAwsDescribeSnapshotTierStatus';
import { IMgdDetailKeyValueProps, IMgdTabProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBetweenTwoDate } from 'utils/Common';
import { ManagementTypeEnum } from '../../..';
import { handleLinkClicked } from '../../../Utils';
import DetailTab from '../../../components/DetailTab';
import { EBS_VOLUME_FILTER, EC2_TAB_LIST, LINK } from '../../../configs';
import TagTab from '../TagTab';
import { EBSSnapshotSummaryPropsType } from '../types';
import { SNAPSHOT_STATE_ENUM_MAPPING, SUMMARY_TAB_LIST, detailTabKeys, detailTabTextDes } from './Common/Constants';
import './EBSSnapshotSummary.scss';
import SnapshotSetting from './SnapshotSetting';

const EBSSnapshotSummary = (props: EBSSnapshotSummaryPropsType) => {
  const { detailData, cloudId, region } = props;

  const navigate = useNavigate();

  // API
  const [getAwsDescribeSnapshotAttribute, { loading: awsDesSnapshotAttributeLoading }] =
    lazyGetAwsDescribeSnapshotAttribute();
  const [getAwsDescribeSnapshotTierStatus] =
    lazyGetAwsDescribeSnapshotTierStatus();
  // TODO: DescribeFastSnapshotRestoresRequest

  // State
  const [productCode, setProductCode] = useState<string>('-');
  const [summaryTabSelected, setSummaryTabSelected] = useState<IMgdTabProps>(SUMMARY_TAB_LIST[0]);
  const [describeSnapshotTierStatusData, setDescribeSnapshotTierStatusData] = useState<any>(null);

  const snapshotId = useMemo((): string => {
    return detailData?.snapshotId ?? '';
  }, [detailData]);

  const awsDesSnapshotAttribute = useMemo((): IAwsDescribeSnapshotAttributeVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        attribute: 'productCodes',
        snapshotId: detailData?.snapshotId ?? '',
      },
    };
  }, [detailData, cloudId, region]);

  const fetchSnapshotAttribute = useCallback(async () => {
    return getAwsDescribeSnapshotAttribute({ variables: awsDesSnapshotAttribute }).then(
      ({ data: awsDescribeSnapshotAttributeRes }) => {
        const snapshotAttribute = awsDescribeSnapshotAttributeRes?.getAwsDescribeSnapshotAttribute?.data?.[0];
        const productCode = snapshotAttribute?.productCodes?.[0];
        let productCodeValue = '-';
        if (productCode) {
          productCodeValue = `${productCode.productCodeId} (${productCode.productCodeId})`;
        }
        return productCodeValue;
      },
    );
  }, [awsDesSnapshotAttribute]);

  const fetchDetailData = useCallback(async () => {
    const [productCodevalue] = await Promise.all([fetchSnapshotAttribute()]);
    setProductCode(productCodevalue);
  }, [fetchSnapshotAttribute]);

  const handleGetAwsDescribeSnapshotTierStatus = async () => {
    try {
      const variables: IGetAwsDescribeSnapshotTierStatusVariables = {
        cloudId,
        region,
        request: {
          filters: [
            {
              name: 'snapshot-id',
              values: [snapshotId],
            },
          ],
        },
      };

      const { data: describeSnapshotTierStatus }: any = await getAwsDescribeSnapshotTierStatus({ variables });

      setDescribeSnapshotTierStatusData(
        describeSnapshotTierStatus?.getAwsDescribeSnapshotTierStatus?.data?.[0]?.snapshotTierStatuses?.[0],
      );
    } catch (error) {}
  };

  const handleGetLink = (key: string, value: string | string[]) => {
    switch (key) {
      case detailTabKeys[5]:
        return handleLinkClicked({
          navigate,
          link: LINK.INSTANCE,
          type: ManagementTypeEnum.INSTANCE,
          tabId: EC2_TAB_LIST[3].id,
          key: EBS_VOLUME_FILTER[0].value.toString(),
          value: value?.toString(),
        });

      default:
        return null;
    }
  };

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    const detailTabData: IMgdDetailKeyValueProps[] = [];
    const detailTabDataOrdered: IMgdDetailKeyValueProps[] = [];
    if (detailData) {
      Object.entries(detailData).map(([key, value]) => {
        if (detailTabKeys.indexOf(key) > -1) {
          const textDes = detailTabTextDes.find(text => text.id == key);
          let desValue: string | string[] = '-';

          if (typeof value === 'string') {
            desValue = value.toString();
          }

          if (key === detailTabKeys[3]) {
            desValue = SNAPSHOT_STATE_ENUM_MAPPING[desValue].text;
          }

          if (textDes) {
            detailTabData.push({
              id: key,
              type: textDes.type as TextTypeEnum,
              title: textDes.value,
              description: desValue,
              handleItemClick:
                textDes.type === TextTypeEnum.LINK || textDes.type === TextTypeEnum.COPY_LINK
                  ? (value: string) => handleGetLink(key, desValue)
                  : null,
            });
          }
        }
      });

      detailTabData.push({
        id: detailTabKeys[7],
        type: detailTabTextDes[7].type as TextTypeEnum,
        title: detailTabTextDes[7].value,
        description: productCode,
      });

      // TODO: Miss fastSnapshotRestore

      // order data
      detailTabKeys.map(key => {
        const dataByKey = detailTabData.find(data => data.id == key);
        if (dataByKey) detailTabDataOrdered.push(dataByKey);
      });
    }
    return detailTabDataOrdered;
  }, [detailData]);

  const storageTierTabContentData: IMgdDetailKeyValueProps[] = [
    {
      title: 'Snapshot ID',
      description: detailData?.snapshotId,
    },
    {
      title: 'Storage tier',
      description: detailData?.storageTier,
    },
    {
      title: 'Archive completed on',
      description: getBetweenTwoDate(describeSnapshotTierStatusData?.archivalCompleteTime),
    },
    {
      title: 'Temporary restore expires on',
      description: getBetweenTwoDate(describeSnapshotTierStatusData?.restoreExpiryTime),
    },
    {
      title: 'Volume ID',
      description: detailData?.volumeId,
    },
    {
      title: 'Tiering status',
      description: describeSnapshotTierStatusData?.lastTieringOperationStatus,
    },
    {
      title: 'Last tier change started on',
      description: getBetweenTwoDate(describeSnapshotTierStatusData?.lastTieringStartTime),
    },
    {
      title: 'Tier change progress',
      description: describeSnapshotTierStatusData?.lastTieringProgress,
    },
  ];

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return detailData?.tags ?? [];
  }, [detailData]);

  const summaryContentNode = useMemo((): ReactNode => {
    switch (summaryTabSelected) {
      case SUMMARY_TAB_LIST[0]:
        return <DetailTab title={'Detail'} data={detailTabContentData} isApiLoading={awsDesSnapshotAttributeLoading} />;
      case SUMMARY_TAB_LIST[1]:
        return <SnapshotSetting snapshotId={snapshotId} cloudId={cloudId} region={region} />;
      case SUMMARY_TAB_LIST[2]:
        return (
          <DetailTab
            title={'Storage tier'}
            data={storageTierTabContentData}
            isApiLoading={awsDesSnapshotAttributeLoading}
          />
        );
      case SUMMARY_TAB_LIST[3]:
        return <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} />;
      default:
        break;
    }
  }, [
    summaryTabSelected,
    detailTabContentData,

    snapshotId,
    cloudId,
    region,
    storageTierTabContentData,
    tagRows,
    tagColumns,
    awsDesSnapshotAttributeLoading,
  ]);

  useEffect(() => {
    fetchDetailData();
    handleGetAwsDescribeSnapshotTierStatus();
    setSummaryTabSelected(SUMMARY_TAB_LIST[0]);
  }, [detailData]);

  return (
    <Fragment>
      {!detailData ? (
        <div className="detail-data">
          <p className="empty-page">Select a item</p>
        </div>
      ) : (
        <div className="box-summary">
          <div className="box-summary-id">{`Snapshot ID: ${snapshotId}`}</div>

          <div className="detail-tab flex a-center">
            {SUMMARY_TAB_LIST.map(tab => {
              return (
                <button
                  className={`detail-tab-items ${summaryTabSelected?.id === tab.id && 'active'}`}
                  key={tab.id}
                  data-tab={tab.id}
                  onClick={e => setSummaryTabSelected(tab)}
                >
                  <p>{tab.name}</p>
                </button>
              );
            })}
          </div>

          <div className="content-tab">{summaryContentNode}</div>
        </div>
      )}
    </Fragment>
  );
};

export default EBSSnapshotSummary;
