import { Key } from 'react';
import { KeyPairtType } from '../../type';
import InputCustomDesc from '../InputCustomDesc';
import InputAtom from 'components/v2/atoms/InputAtom';
import './index.scss';
import { COLUMNS_KEY } from '../../configs';

const TagTableCustom = (props: any) => {
  const { optionHide, tags, addPair, handleValueChanged, handleCurrentKeyDelete } = props;

  return (
    <div className="update-model">
      {tags.length !== 0 ? (
        <div className="tag-row-container">
          <div className="tag-row-input">
            {COLUMNS_KEY.map((column: string, index: Key) => {
              return (
                <div key={`udpate-tags-table-column-${index}`} className="column">
                  <p>{column}</p>
                </div>
              );
            })}
          </div>
          <div className="tag-row-btn" />
        </div>
      ) : (
        <></>
      )}
      {tags.map((row: KeyPairtType, index: number) => (
        <div className="tag-row-container" key={index}>
          <div className="tag-row-input">
            <div className="column">
              <InputCustomDesc error={tags[index]?.error || ''}>
                <InputAtom
                  value={row.key}
                  onChangeValue={(val: string) => {
                    handleValueChanged(index, 'key', val);
                  }}
                  noClear={true}
                  error={!!tags[index]?.error || false}
                />
              </InputCustomDesc>
            </div>
            <div className="column">
              <InputCustomDesc error={''}>
                <InputAtom
                  value={row.value}
                  onChangeValue={(val: string) => {
                    handleValueChanged(index, 'value', val);
                  }}
                  defaultValue={''}
                  noClear={true}
                />
              </InputCustomDesc>
            </div>
          </div>

          <div className={`tag-row-btn ${tags[index]?.error !== '' ? 'mg-bottom-30' : ''}`}>
            {optionHide || tags.length > 1 ? (
              <button className="button" onClick={() => handleCurrentKeyDelete(index)}>
                Remove
              </button>
            ) : null}
          </div>
        </div>
      ))}
      <div className="add-new-container">
        <button className="normal-btn" onClick={addPair}>
          + Add row
        </button>
      </div>
    </div>
  );
};
export default TagTableCustom;
