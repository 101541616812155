import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsListRolesResponseType } from 'graphql/types/AwsListRolesResponse';
import { IGqlResponseNewData } from 'graphql/types';

export interface IGetListRolesVariables {
  cloudId: number;
  region: string;
  request: {
    maxItems: number;
    pathPrefix?: string;
    nextToken?: string;
  }
}

export interface IListRolesResponseData {
  getAwsListRoles: IGqlResponseNewData<AwsListRolesResponseType>;
}

const lazyGetAwsListRoles = () => useLazyQuery<IListRolesResponseData, IGetListRolesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListRoles;