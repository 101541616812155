import SectionContainer from 'pages/v2/Organ/Management/components/SectionContainer';
import SectionTitle from 'pages/v2/Organ/Management/components/SectionTitle';
import Switch from 'pages/v2/Organ/Management/components/Switch';
import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import InputCustomDesc from '../components/InputCustomDesc';
import InputGroup from 'pages/v2/Organ/Management/components/InputGroup';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { randomString } from 'utils/Common';
import lazyGetAwsListFunctions, { IAwsListFunctionsVariables } from 'graphql/queries/getAwsListFunctions';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import Input from 'pages/v2/Organ/Management/components/Input';
import { durationRegex, scheduleExpressionRegex } from 'utils/Regex';
import './index.scss';

type LambdaRotationFuncType = {
  name: string;
  value: string;
};
type ErrorRotationType = {
  scheduleExpressionError: string;
  durationError: string;
  lambdaRotationFuncValueError: string;
};
const configureRotationStep = forwardRef((props: any, ref: Ref<any>) => {
  const { stepTitle, creationData, handleChangeCreationData, cloudId, region } = props;
  const [lambdaRotationFuncOptions, setLambdaRotationFuncOptions] = useState<LambdaRotationFuncType[]>([]);
  const [startValid, setStartValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorRotationType>({
    scheduleExpressionError: '',
    durationError: '',
    lambdaRotationFuncValueError: '',
  });

  const inputValidation = useCallback((): boolean => {
    const { scheduleExpression, duration, lambdaRotationFuncValue } = creationData;
    let validated = true;
    let scheduleExpressionError = '';
    if (creationData?.isAutoRotation) {
      if (!scheduleExpression || scheduleExpression === undefined) {
        scheduleExpressionError = 'A scheduled expression is required';
        validated = false;
      } else if (!scheduleExpressionRegex.test(scheduleExpression)) {
        scheduleExpressionError = 'The schedule expression isn\'t valid. Enter a different expression.';
        validated = false;
      }
      let durationError = '';
      if (duration !== '' && !durationRegex.test(duration)) {
        durationError = 'Enter a number of hours between 1 and 24 followed by the letter h, for example 2h.';
        validated = false;
      }
      let lambdaRotationFuncValueError = '';
      if (!lambdaRotationFuncValue) {
        lambdaRotationFuncValueError = 'You must specify a Lambda rotation function.';
        validated = false;
      }
      const creationDataValidated = {
        scheduleExpressionError: scheduleExpressionError,
        durationError: durationError,
        lambdaRotationFuncValueError: lambdaRotationFuncValueError,
      };
      setErrors(creationDataValidated);
    }
    
    return validated;
  }, [creationData, setErrors]);

  const [getAwsListFunctions] = lazyGetAwsListFunctions();

  const fetchAwsAllAwsKeys = async () => {
    const awsAwsKeyRequestVariables: IAwsListFunctionsVariables = {
      cloudId,
      region,
      reqData: {
      },
    };
    const combinedVariable = variableCombineNextToken(awsAwsKeyRequestVariables);
    const { data: listFunctions } = await getAwsListFunctions({ variables: combinedVariable });
    if (!listFunctions?.getAwsListFunctions?.data?.[0]?.functions?.length) return;
    const arr = listFunctions.getAwsListFunctions.data[0].functions.map(
      ({ functionName, functionArn }: { functionName: string; functionArn: string }) => ({
        name: functionName,
        value: functionArn,
      }),
    );
    setLambdaRotationFuncOptions(arr);
    if (!(creationData?.lambdaRotationFuncName && creationData?.lambdaRotationFuncValue)) {
      handleChangeCreationData('lambdaRotationFuncName', arr.at(-1)?.name);
      handleChangeCreationData('lambdaRotationFuncValue', arr.at(-1)?.value);
    }
  };

  const handleChangeAutoRotation = (value: boolean) => {
    handleChangeCreationData('hideLambda', value);
    if (value) {
      setLambdaRotationFuncOptions([]);
    } else {
      fetchAwsAllAwsKeys();
    }
  };

  const onConfigureRotationVadidate = useCallback(() => {
    setStartValid(true);
    
    return inputValidation();
  }, [startValid, creationData, errors]);

  useImperativeHandle(ref, () => ({
    onConfigureRotationVadidate,
  }));

  useEffect(() => {
    if (startValid) {
      inputValidation();
    }
  }, [startValid, creationData]);

  useEffect(() => {
    fetchAwsAllAwsKeys();
  }, []);

  return (
    <div>
      <SectionTitle title={stepTitle} customStyle="section-step" />
      <div className="type-step">
        <SectionContainer
          title="Configure automatic rotation"
          description="Configure AWS Secrets Manager to rotate this secret automatically"
          customStyle="auto-stype"
        >
          <InputGroup>
            <Switch
              label="Automatic rotation"
              checked={creationData?.isAutoRotation || false}
              onChange={value => handleChangeCreationData('isAutoRotation', value)}
            />
          </InputGroup>
        </SectionContainer>
        <SectionContainer title="Rotation schedule">
          <InputGroup>
            <Input
              title="Schedule expression"
              disabled={!creationData?.isAutoRotation}
              isRequired
              value={creationData.scheduleExpression}
              onValueChanged={value => handleChangeCreationData('scheduleExpression', value)}
              error={errors?.scheduleExpressionError}
              placeholder="cron(0 2 1 * ? *) or rate (10days)"
              bottomDescription="Enter a cron expression such as cron ( a b c d e f) or a rate expression such as rate (10days)."
            />
            <Input
              title="Window duration"
              disabled={!creationData?.isAutoRotation}
              value={creationData.duration}
              onValueChanged={value => handleChangeCreationData('duration', value)}
              error={errors?.durationError}
              placeholder="4h"
              bottomDescription="Enter the time in hours."
            />

            <div id="input-container">
              <div className="row-content">
                <div className="left-column-content" />
                <div className="right-column-content">
                  <div className="description">
                    <div className="checkbox-content">
                      <CheckboxAtom
                        id="showPassword-checkbox"
                        checked={creationData?.rotateImmediately}
                        disabled={!creationData?.isAutoRotation}
                        onchange={() => handleChangeCreationData('rotateImmediately', !creationData?.rotateImmediately)}
                      />
                      <p
                        className="text-content"
                        onClick={() => handleChangeCreationData('rotateImmediately', !creationData?.rotateImmediately)}
                      >
                        Rotate immediately when the secret is stored. The next rotation will begin on your schedule.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </InputGroup>
        </SectionContainer>
        <SectionContainer title="Rotation function" description="It use a rotation function from your account.">
          <InputGroup>
            <InputCustomDesc
              label="Lambda rotation function"
              topDescription="Choose a function from your account to rotate your secret. We recommend that you use a Lambda function that is in the same VPC as your database."
              error=""
            >
              <DropdownAtom
                id={randomString()}
                disabled={!creationData?.isAutoRotation}
                placeholder="Lambda rotation function"
                className=""
                data={lambdaRotationFuncOptions}
                value={{
                  name: creationData.lambdaRotationFuncName,
                  value: creationData.lambdaRotationFuncValue,
                }}
                error={errors.lambdaRotationFuncValueError}
                handleClick={(e: DropdownListDataType) => {
                  handleChangeCreationData('lambdaRotationFuncName', e.name);
                  handleChangeCreationData('lambdaRotationFuncValue', e.value);
                }}
              />
            </InputCustomDesc>
            <div id="input-container">
              <div className="row-content">
                <div className="left-column-content" />
                <div className="right-column-content">
                  <div className="description">
                    <div className="checkbox-content">
                      <Switch
                        label="Hide Lambda functions Secrets Manager didn’t create."
                        checked={creationData?.hideLambda}
                        disabled={!creationData?.isAutoRotation}
                        onChange={value => handleChangeAutoRotation(value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-content">
                <div className="left-column-content" />
                <div className="right-column-content">
                  <p className="description">
                    Rotation strategy is single user. The user much have permission to update their password.
                  </p>
                </div>
              </div>
            </div>
          </InputGroup>
        </SectionContainer>
      </div>
    </div>
  );
});

configureRotationStep.displayName = 'configureRotationStep';
export default configureRotationStep;
