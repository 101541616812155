import { gql } from '@apollo/client';

const query = gql`
  query getAwsDescribeLockedSnapshots($request: AwsDescribeLockedSnapshotsRequest, $cloudId: Long, $region: String) {
    getAwsDescribeLockedSnapshots(describeLockedSnapshotsRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsDescribeLockedSnapshotsResponse {
          snapshots {
            ownerId
            snapshotId
            lockState
            lockDuration
            coolOffPeriod
            coolOffPeriodExpiresOn
            lockExpiresOn
          }
          nextToken
        }
      }
    }
  }
`;
export default query;
