import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IUpdateAwsSecretVariables {
  cloudId: number;
  region: string;
  reqData: {
    secretId: string;
    description?: string;
    kmsKeyId?: string;
  };
}

export interface IUpdateAwsSecretResponseData {
  updateAwsSecret: IGqlResponseData<undefined>;
}

const updateAwsSecretMutation = () => useMutation<IUpdateAwsSecretResponseData, IUpdateAwsSecretVariables>(query);
export default updateAwsSecretMutation;
