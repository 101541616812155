import { useState } from 'react';
import TagTab from 'pages/v2/Organ/Management/components/TagTab';
import UpdateTagsModal from '../../UpdateTagsModal';

const Tags = ({ data, isLoading, isEdit }: any) => {
  const { ruleGroupResponse } = data || {};

  const { tags } = ruleGroupResponse || {};

  const [isOpenEditTag, setIsOpenEditTag] = useState(false);

  const tagColumns = [
    {
      label: 'Key',
      field: 'key',
      sort: true,
    },
    {
      label: 'Value',
      field: 'value',
      sort: true,
    },
  ];

  return (
    <>
      <TagTab
        columns={tagColumns}
        rows={tags || []}
        title="Firewall tags"
        rightButtons={isEdit ? [{ id: 'edit-tags', label: 'Edit', onClick: () => setIsOpenEditTag(true) }] : []}
        isLoading={isLoading}
      />

      {isOpenEditTag ? (
        <UpdateTagsModal
          header={'Edit Tags'}
          note={'You can add up to 48 more tags.'}
          currentDatas={[]}
          columns={['Key', 'Value-option']}
          onSave={() => {}}
          open={isOpenEditTag}
          onClose={() => setIsOpenEditTag(false)}
        />
      ) : null}
    </>
  );
};

export default Tags;
