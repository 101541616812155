import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { IMgdTabProps, TextTypeEnum } from 'layouts/v3/MgdLayout';

export const SCOPE_DROPDOWN: DropdownListDataType[] = [
  { id: 1, name: 'Owned by me', value: 'owners' },
  { id: 2, name: 'Private snapshots', value: 'is-private' },
  { id: 3, name: 'Public snapshots', value: 'is-public' },
];

export const EBS_SNAPSHOT_FILTER_DROPDOWN: DropdownListDataType[] = [
  { id: 1, name: 'Snapshot ID', value: 'snapshot-id' },
  { id: 2, name: 'Snapshot status', value: 'status' },
  { id: 3, name: 'Description', value: 'description' },
  { id: 4, name: 'Storage tier', value: 'storage-tier' },
];

export const SNAPSHOT_STATE_ENUM_MAPPING: { [key: string]: { text: string; style: string } } = {
  completed: { text: 'Completed', style: 'text-green-01' },
  error: { text: 'Error', style: 'text-red' },
  pending: { text: 'Pending', style: 'text-yellow' },
  recorverable: { text: 'Recorverable', style: 'text-yellow' },
  recorevering: { text: 'Recorevering', style: 'text-yellow' },
  '-': { text: '-', style: 'text-normal' },
};

export const SUMMARY_TAB_LIST: Array<IMgdTabProps> = [
  { id: 'detail', name: 'Detail', title: 'Detail' },
  { id: 'snapshotsetting', name: 'Snapshot settings', title: 'Snapshot settings' },
  { id: 'storagetier', name: 'Storage tier', title: 'Storage tier' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const DETAIL_TAB_LIST: Array<IMgdTabProps> = [
  { id: 'snapshotsetting', name: 'Snapshot settings', title: 'Snapshot settings' },
  { id: 'storagetier', name: 'Storage tier', title: 'Storage tier' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const detailTabKeys = [
  'snapshotId',
  'volumeSize',
  'progress',
  'state',
  'ownerId',
  'volumeId',
  'started',
  'productCodes',
  'encrypted',
  'kmsKeyId',
  'kmsKeyAliasName',
  'kmsKeyAliasArn',
  'fastSnapshotRestore',
  'description',
];

export const detailTabTextDes: { id: string; value: string; type: string }[] = [
  { id: 'snapshotId', value: 'Snapshot ID', type: TextTypeEnum.COPY },
  { id: 'volumeSize', value: 'Volume Size', type: TextTypeEnum.NORMAL },
  { id: 'progress', value: 'Progress', type: TextTypeEnum.STATUS },
  { id: 'state', value: 'Snaphot status', type: TextTypeEnum.STATUS },
  { id: 'ownerId', value: 'Owner', type: TextTypeEnum.NORMAL },
  { id: 'volumeId', value: 'Volume ID', type: TextTypeEnum.COPY_LINK },
  { id: 'started', value: 'Started', type: TextTypeEnum.NORMAL },
  { id: 'productCodes', value: 'Product codes', type: TextTypeEnum.NORMAL },
  { id: 'encrypted', value: 'Encryption', type: TextTypeEnum.NORMAL },
  { id: 'kmsKeyId', value: 'KMS key ID', type: TextTypeEnum.NORMAL },
  { id: 'kmsKeyAliasName', value: 'KMS key alias', type: TextTypeEnum.NORMAL },
  { id: 'kmsKeyAliasArn', value: 'KMS key ARN', type: TextTypeEnum.NORMAL },
  { id: 'fastSnapshotRestore', value: 'Fast snapshot restore', type: TextTypeEnum.NORMAL },
  { id: 'description', value: 'Description', type: TextTypeEnum.NORMAL },
];

export const storageTierTabKeys = [
  'snapshotId',
  'storageTier',
  'archivecompleted',
  'expire',
  'volumeId',
  'status',
  'lastChanged',
  'progress',
];

export const storageTierTabTextDes: { id: string; value: string; type: string }[] = [
  { id: 'snapshotId', value: 'Snapshot ID', type: TextTypeEnum.COPY_LINK },
  { id: 'volumeSize', value: 'Volume Size', type: TextTypeEnum.NORMAL },
  { id: 'archivecompleted', value: 'Archive completed', type: TextTypeEnum.NORMAL },
  { id: 'expire', value: 'Temporary restore expires on', type: TextTypeEnum.NORMAL },
  { id: 'volumeId', value: 'Volume ID', type: TextTypeEnum.NORMAL },
  { id: 'status', value: 'Tiering status', type: TextTypeEnum.NORMAL },
  { id: 'lastChanged', value: 'LAst tier change started on', type: TextTypeEnum.NORMAL },
  { id: 'progress', value: 'Tier change progress', type: TextTypeEnum.NORMAL },
];
