import { useState, useEffect, useMemo } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconDelete from 'assets/svgs/v3/ico_delete_network.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import DetailTable from 'pages/v2/Organ/Management/components/DetailTable';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';

type DeleteAliasesModalPropsType = {
  onSave?: () => void;
  aliases?: Array<string>;
  keyId?: string;
} & IBaseModalProps;

const DeleteAliasesModal = (props: DeleteAliasesModalPropsType) => {
  const { aliases, keyId, onSave, ...baseModalProps } = props;

  const [confirmText, setConfirmText] = useState('');

  const aliasesRows = useMemo(() => {
    return aliases?.map(alias => {
      return {
        id: alias,
        aliasName: alias,
        keyId: keyId,
      };
    });
  }, [keyId]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconDelete} />
          {'Delete aliases'}
        </>
      )}
      {...baseModalProps}
    >
      <div className="modal-content-container">
        <div className="warning-container">
          <Icon width={32} height={32} src={IconWarning} />
          <p>Deleting an alias prevents you from using that alias to identify the target key.</p>
        </div>
        <div className="table-wrapper">
          <DetailTable
            data={aliasesRows ?? []}
            columns={[
              { field: 'aliasName', label: 'Alias name', sort: true },
              { field: 'keyId', label: 'Key ID', sort: true },
            ]}
            className="table-container"
            hasPagination={false}
          />
        </div>

        <div className="input">
          <p>To confirm, enter "delete"</p>
          <InputAtom
            placeholder="Enter delete"
            type={'text'}
            value={confirmText}
            noClear={true}
            onChangeValue={setConfirmText}
          />
        </div>

        <div className="button-group">
          <Button type={ButtonTypeEnum.GENERAL} onClick={() => baseModalProps?.onClose?.()} label="Cancel" />
          <Button
            disabled={confirmText !== 'delete'}
            type={ButtonTypeEnum.PRIMARY}
            onClick={() => onSave?.()}
            label="Delete aliases"
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteAliasesModal;
