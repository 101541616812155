import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import { AwsRuleGroup } from 'graphql/types/AwsRuleGroup';
import { getRegionFromArnsData } from '../../../Commons/Constant';
import DeleteRuleModal from '../../UpdateRuleGroup/DeleteRule';

interface IInfoProps {
  ruleGroup: AwsRuleGroup;
  onEditRuleGroupInfo: () => void;
  onDeleteRule: (ruleId: string) => void;
  onEditRule: (ruleId: string) => void;
  onAddRule: () => void;
  isDeleteRuleModalVisible?: boolean;
  setDeleteRuleModalVisisble: (visible: boolean) => void;
}

const InfoLayout = ({
  ruleGroup,
  onEditRuleGroupInfo,
  onDeleteRule,
  onEditRule,
  onAddRule,
  isDeleteRuleModalVisible,
  setDeleteRuleModalVisisble,
}: IInfoProps) => {
  const [rows, setRows] = useState<RowType[]>([]);
  const [totalRules, setTotalRules] = useState({
    totalPage: 0,
    totalElement: 0,
  });
  const [ruleTablePagination, setRuleTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');

  const updateBtnDisabled = useMemo((): boolean => {
    return selected === '' || checkedList.length !== 1;
  }, [selected, checkedList]);

  const columns: ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true,
      },
      {
        label: 'Action',
        field: 'action',
        sort: true,
        renderCell: (row: RowType) => <>{row?.action?.allow ? 'Allow' : 'Block'}</>,
      },
      {
        label: 'Priority',
        field: 'priority',
        sort: true,
      },
    ];
  }, [ruleGroup.rules]);

  const getRuleRowsContent = useCallback(() => {
    const ruleRows: RowType[] = [];
    ruleGroup.rules.map(rule => {
      ruleRows.push({
        ...rule,
        id: rule.name,
        priority: rule?.priority?.toString(),
      });
    });
    setRows(ruleRows);
    setTotalRules({
      totalElement: ruleGroup.rules.length,
      totalPage: Math.ceil(ruleGroup.rules.length / ruleTablePagination.limit),
    });
  }, [ruleGroup]);

  useEffect(() => {
    getRuleRowsContent();
  }, [getRuleRowsContent]);

  return (
    <div className="resize-container vertical">
      <div className="details">
        <div className="row-3">
          <div className="title flex j-between a-center border-bottom">
            <p>Info</p>
            <div className="flex action a-center">
              <button className="edit-btn" onClick={onEditRuleGroupInfo}>
                Edit
              </button>
            </div>
          </div>

          <div className="content flex">
            <div className="info border-right">
              <p>Name</p>
              <p>{ruleGroup.name}</p>
            </div>
            <div className="info border-right padding-left">
              <p>Capacity</p>
              <p>{ruleGroup.capacity}</p>
            </div>
            <div className="info border-right padding-left">
              <p>Region</p>
              <p>{getRegionFromArnsData(ruleGroup.arn)}</p>
            </div>
            <div className="info padding-left">
              <p>Description</p>
              <p>{ruleGroup.description}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="details">
        <div className="row-3">
          <div className="title flex j-between a-center">
            <div>
              <p>Rules</p>
            </div>
            <div className="flex action a-center">
              <button className="edit-btn" onClick={() => onEditRule(selected)} disabled={updateBtnDisabled}>
                Edit
              </button>
              <button className="action-btn" onClick={() => onAddRule()}>
                Add rule
              </button>
              <button
                className="action-btn"
                onClick={() => setDeleteRuleModalVisisble(true)}
                disabled={updateBtnDisabled}
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        {rows.length == 0 ? (
          <div className="data-grid-wrap">
            <p className="empty-row">Empty</p>
          </div>
        ) : (
          <div className="data-grid-wrap">
            <Table
              rows={rows}
              columns={columns}
              reportCheckedList={(list: string[]) => {
                setCheckedList(list);
                if (list.length > 0) {
                  setSelected(list[list.length - 1]);
                }
              }}
              reportSelected={(id: string) => {
                setSelected(id);
                setCheckedList([id]);
              }}
              horizontalScrollable={true}
            />
          </div>
        )}
      </div>

      {isDeleteRuleModalVisible ? (
        <DeleteRuleModal
          resourceName={selected}
          open={isDeleteRuleModalVisible}
          onClose={() => setDeleteRuleModalVisisble(false)}
          onDelete={() => onDeleteRule(selected)}
        />
      ) : null}
    </div>
  );
};
export default InfoLayout;
