import { CSSProperties } from 'react';

type CustomRadioProps = {
  checked?: boolean;
  subLabel?: string;
  styleCheckmark?: CSSProperties;
  label?: string;
  description?: string;
  onClick: () => void;
};
const CustomRadio = (props: CustomRadioProps) => {
  const { checked, styleCheckmark, label, subLabel, description, onClick } = props;

  return (
    <div className="radio-custom-container">
      <div
        className="radio-atom"
        onClick={() => {
          onClick();
        }}
      >
        <input
          type="radio"
          checked={checked}
          onChange={() => {
            onClick();
          }}
        />
        <span className="check-mark" style={styleCheckmark} />
      </div>
      <div className="label-wrapper">
        <div className="radio-custom-label">
          <span>{label}</span>
          {subLabel && <span style={{ fontStyle: 'italic' }}>{subLabel}</span>}
        </div>
        <div className="radio-custom-description">{description}</div>
      </div>
    </div>
  );
};

export default CustomRadio;
