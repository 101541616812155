import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeClusters($reqData: AwsDescribeClustersRequest, $cloudId: Long, $region: String) {
  getAwsDescribeClusters(describeClustersRequest: $reqData, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeClustersResponse {
        clusters {
          clusterIdentifier
          clusterStatus
          clusterCreateTime
        }
        marker
      }
    }
  }
}`;
export default query;