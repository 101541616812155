import { useLazyQuery } from '@apollo/client';
import query from './query';

export interface IAwsLoginProfileVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults?: number;
  };
}

export interface IGetAwsManagedPrefixListsResponseData {
  getAwsManagedPrefixLists: {
    data: [
      {
        nextToken: string;
        prefixLists: [
          {
            prefixListId: string;
            addressFamily: string;
            state: string;
            stateMessage: string;
            prefixListArn: string;
            prefixListName: string;
            maxEntries: string;
            version: string;
            tags: [
              {
                key: string;
                value: string;
              },
            ];
            ownerId: string;
          },
        ];
      },
    ];
  };
}

const lazyGetAwsManagedPrefixLists = () =>
  useLazyQuery<IGetAwsManagedPrefixListsResponseData, IAwsLoginProfileVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsManagedPrefixLists;
