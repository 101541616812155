import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsSnapshotType } from 'graphql/types/AwsDescribeSnapshots';

export interface IGetAwsDescribeSnapshotTierStatusVariables {
  cloudId: number;
  region: string;
  request: {
    filters?: {
      name: string;
      values: string[];
    }[];
    maxResults?: number;
  };
}

export interface IGetAwsDescribeSnapshotTierStatusResponseData {
  getAwsDescribeSnapshotTierStatus: IGqlResponseNewData<AwsSnapshotType>;
}

const lazyGetAwsDescribeSnapshotTierStatus = () =>
  useLazyQuery<IGetAwsDescribeSnapshotTierStatusResponseData, IGetAwsDescribeSnapshotTierStatusVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeSnapshotTierStatus;
