import React from 'react';
import { InputGroupPropsType } from '../types';
import './index.scss';

const InputGroup = (props: InputGroupPropsType) => {
  const { children } = props;

  return <div id="input-group-container">{children}</div>;
};

export default InputGroup;
