import { useLazyQuery } from '@apollo/client';
import { IGqlResponseData } from 'graphql/types';
import query from './query';

export interface IGetAutomatedDiscoveryConfigurationResponseVariables {
  cloudId: number;
  region: string;
  request: {};
}

export enum StatusAutoMatedDiscovery {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
interface IGetAutomatedDiscoveryConfigurationResponse {
  autoEnableOrganizationMembers: 'ALL' | 'NEW' | 'NONE';
  classificationScopeId: string;
  disabledAt: string;
  firstEnabledAt: string;
  lastUpdatedAt: string;
  sensitivityInspectionTemplateId: string;
  status: StatusAutoMatedDiscovery;
}

export interface IGetAutomatedDiscoveryConfigurationResponseResponseData {
  getAwsAutomatedDiscoveryConfiguration: IGqlResponseData<IGetAutomatedDiscoveryConfigurationResponse[]>;
}

const lazyGetAwsListAutomatedDiscoveryAccounts = () =>
  useLazyQuery<
    IGetAutomatedDiscoveryConfigurationResponseResponseData,
    IGetAutomatedDiscoveryConfigurationResponseVariables
  >(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListAutomatedDiscoveryAccounts;
