import { gql } from '@apollo/client';

const query = gql`
  mutation deleteAwsAliasKms($reqData: AwsDeleteAliasKmsRequest, $cloudId: Long, $region: String) {
    deleteAwsAliasKms(deleteAliasKmsRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
    }
  }
`;
export default query;
