import './index.scss';
import { useEffect, useState, useCallback, useMemo } from 'react';
import PageDetailTitle from 'pages/v2/Organ/Management/components/PageDetailTitle';
import lazyGetAwsDescribeRuleGroup from 'graphql/queries/getAwsDescribeRuleGroup';
import Details from '../Common/Components/Details';
import RulesVariables from '../Common/Components/RulesVariables';
import IpStateReferences from '../Common/Components/IpStateReferences';
import Rules from '../Common/Components/Rules';
import CustomerManagedKeys from '../Common/Components/CustomerManagedKeys';
import Tags from '../Common/Components/Tags';
import EditFirewallDetailModal from '../EditFirewallDetailModal';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import DeleteRuleModal from '../DeleteRuleModal';
import updateAwsRuleGroupFirewallMutation from 'graphql/mutations/updateRuleGroupFirewall';
import deleteAwsRuleGroupFirewallMutation from 'graphql/mutations/deleteAwsRuleGroupFirewall';
import { AwsUpdateRuleGroupFirewallRequestType } from 'graphql/types/AwsUpdateRuleGroupFirewall';
import { IpRowData, PortRowData } from '../EditRuleVariablesModal';
import lazyGetAwsManagedPrefixLists from 'graphql/queries/getAwsManagedPrefixLists';

const RuleGroupDetail = (props: any) => {
  const { data, pageBackClick, cloudId, region } = props;

  const [describeRuleGroup, setDescribeRuleGroup] = useState<any>(null);
  const [isEditDescriptionModalVisible, setEditDescriptionModalVisible] = useState(false);
  const [isDeleteRuleGroupModalVisible, setDeleteRuleGroupModalVisible] = useState(false);
  const [managedPrefixLists, setManagedPrefixLists] = useState<any>([]);

  const [getAwsDescribeRuleGroup, { loading }] = lazyGetAwsDescribeRuleGroup();
  const [updateFirewallRuleGroup, { loading: updateFirewallRuleGroupLoading }] = updateAwsRuleGroupFirewallMutation();
  const [deleteFirewallRuleGroup, { loading: deleteFirewallRuleGroupLoading }] = deleteAwsRuleGroupFirewallMutation();
  const [getAwsManagedPrefixLists] = lazyGetAwsManagedPrefixLists();

  const getManagedPrefixLists = useCallback(async () => {
    const variables = {
      cloudId,
      region,
      request: {},
    };

    await getAwsManagedPrefixLists({ variables }).then(res => {
      if (res?.data?.getAwsManagedPrefixLists?.data?.[0]?.prefixLists) {
        setManagedPrefixLists(res?.data?.getAwsManagedPrefixLists?.data?.[0]?.prefixLists ?? []);
      }
    });
  }, [cloudId, region]);

  const fetchDescribeRuleGroup = async () => {
    if (!data?.id) return;

    try {
      const variables = {
        cloudId,
        region,
        request: {
          ruleGroupName: data?.name || '',
          ruleGroupArn: data?.id || '',
        },
      };

      const { data: describeRuleGroup } = await getAwsDescribeRuleGroup({
        variables,
      });

      setDescribeRuleGroup(describeRuleGroup?.getAwsDescribeRuleGroup?.data?.[0]);
    } catch (error) {}
  };

  useEffect(() => {
    fetchDescribeRuleGroup();
    getManagedPrefixLists();
  }, [data?.id]);

  const defaultUpdateRuleGroupRequest = useMemo((): AwsUpdateRuleGroupFirewallRequestType => {
    return {
      description: describeRuleGroup?.description ?? '',
      ruleGroup: describeRuleGroup?.ruleGroup,
      ruleGroupArn: describeRuleGroup?.ruleGroupResponse?.ruleGroupArn,
      ruleGroupName: describeRuleGroup?.ruleGroupResponse?.ruleGroupName,
      updateToken: describeRuleGroup?.updateToken,
    };
  }, [describeRuleGroup]);

  const handleUpdateRuleGroup = useCallback(
    async (
      request: AwsUpdateRuleGroupFirewallRequestType,
      successMessage: string,
      errorMessage: string,
      callback: () => void,
    ) => {
      await updateFirewallRuleGroup({
        variables: {
          cloudId,
          region,
          request,
        },
      })
        .then(() => {
          useToast(ErrorCode.SUCCESS, successMessage);
          callback();
        })
        .catch(() => {
          useToast(ErrorCode.UNKNOWN, errorMessage);
        });
    },
    [cloudId, region],
  );

  const handleEditDescription = useCallback(
    async (description: string) => {
      const newRequest = {
        ...defaultUpdateRuleGroupRequest,
        description,
      };

      await handleUpdateRuleGroup(newRequest, 'Update description successfully.', 'Update description failed.', () => {
        fetchDescribeRuleGroup();
        setEditDescriptionModalVisible(false);
      });
    },
    [defaultUpdateRuleGroupRequest],
  );

  const handleDeleteRuleVariable = useCallback(
    async (selected: string) => {
      let newRequest = {
        ...defaultUpdateRuleGroupRequest,
      };

      const newIpSets: any = newRequest?.ruleGroup?.ruleVariables?.ipSets;
      const newPortSets: any = newRequest?.ruleGroup?.ruleVariables?.portSets;

      if (newIpSets?.[selected]) {
        delete newIpSets[selected];
      }
      if (newPortSets?.[selected]) {
        delete newPortSets[selected];
      }

      await handleUpdateRuleGroup(
        newRequest,
        'Delete rule variable successfully.',
        'Delete rule variable failed.',
        () => {
          fetchDescribeRuleGroup();
        },
      );
    },
    [defaultUpdateRuleGroupRequest],
  );

  const handleUpdateIpsetReference = useCallback(
    async (references: {
      [key: string]: {
        referenceArn: string;
      };
    }) => {
      let newRequest = {
        ...defaultUpdateRuleGroupRequest,
      };

      if (newRequest?.ruleGroup?.referenceSets?.ipSetReferences) {
        newRequest.ruleGroup.referenceSets.ipSetReferences = references as any;
      }

      await handleUpdateRuleGroup(
        newRequest,
        'Edit IP set references successfully.',
        'Edit IP set references failed.',
        () => {
          fetchDescribeRuleGroup();
        },
      );
    },
    [defaultUpdateRuleGroupRequest],
  );

  const handleDeleteIpSetReferences = useCallback(
    async (checkedReferences: Array<string>) => {
      let newRequest = {
        ...defaultUpdateRuleGroupRequest,
      };

      let ipSetReferences = newRequest?.ruleGroup?.referenceSets?.ipSetReferences as any;

      if (checkedReferences.length > 0) {
        checkedReferences.forEach(key => {
          if (ipSetReferences?.[key] as any) {
            delete ipSetReferences[key];
          }
        });
      }

      await handleUpdateRuleGroup(
        newRequest,
        'Delete IP set references successfully.',
        'Delete IP set references failed.',
        () => {
          fetchDescribeRuleGroup();
        },
      );
    },
    [defaultUpdateRuleGroupRequest],
  );

  const handleEditRuleVariables = useCallback(
    async (oldIps: IpRowData[], newIps: IpRowData[], oldPorts: PortRowData[], newPorts: PortRowData[]) => {
      let newRequest = {
        ...defaultUpdateRuleGroupRequest,
      };

      const newPortArray = [...oldPorts, ...newPorts];
      let newPortSets: any = {};

      newPortArray.forEach(item => {
        if (item?.portName) {
          newPortSets[item.portName] = {
            definition: item?.portValue?.split('\n'),
          };
        }
      });

      if (newRequest?.ruleGroup?.ruleVariables?.portSets) {
        newRequest.ruleGroup.ruleVariables.portSets = newPortSets;
      }

      const newIpArray = [...oldIps, ...newIps];
      let newIpSets: any = {};

      newIpArray.forEach(item => {
        if (item?.ipName) {
          newIpSets[item.ipName] = {
            definition: item?.ipValue?.split('\n'),
          };
        }
      });

      if (newRequest?.ruleGroup?.ruleVariables?.ipSets) {
        newRequest.ruleGroup.ruleVariables.ipSets = newIpSets;
      }

      await handleUpdateRuleGroup(
        newRequest,
        'Edit rule variables successfully.',
        'Edit rule variables failed.',
        () => {
          fetchDescribeRuleGroup();
        },
      );
    },
    [defaultUpdateRuleGroupRequest],
  );

  const handleDeleteRuleGroup = useCallback(async () => {
    await deleteFirewallRuleGroup({
      variables: {
        cloudId,
        region,
        request: {
          ruleGroupArn: describeRuleGroup?.ruleGroupResponse?.ruleGroupArn,
          ruleGroupName: describeRuleGroup?.ruleGroupResponse?.ruleGroupName,
        },
      },
    })
      .then(() => {
        setEditDescriptionModalVisible(false);
        pageBackClick();
        useToast(ErrorCode.SUCCESS, 'Delete rule group successfully.');
      })
      .catch(() => {
        useToast(ErrorCode.UNKNOWN, 'Delete rule group failed.');
      });
  }, [describeRuleGroup, cloudId, region]);

  const handleAddRule = useCallback(
    async (rule: any) => {
      let newRequest = {
        ...defaultUpdateRuleGroupRequest,
      };

      if (newRequest?.ruleGroup?.rulesSource?.statefulRules) {
        let newRules = [...(newRequest?.ruleGroup?.rulesSource?.statefulRules ?? []), rule];
        newRequest.ruleGroup.rulesSource.statefulRules = newRules;
      }

      await handleUpdateRuleGroup(newRequest, 'Add rule successfully.', 'Add rule failed.', () => {
        fetchDescribeRuleGroup();
      });
    },
    [defaultUpdateRuleGroupRequest],
  );

  const handleEditRule = useCallback(
    async (rule: any) => {
      let newRequest = {
        ...defaultUpdateRuleGroupRequest,
      };

      if (newRequest?.ruleGroup?.rulesSource?.statefulRules) {
        let newRules = [rule];
        newRequest.ruleGroup.rulesSource.statefulRules = newRules;
      }

      await handleUpdateRuleGroup(newRequest, 'Edit rule successfully.', 'Edit rule failed.', () => {
        fetchDescribeRuleGroup();
      });
    },
    [defaultUpdateRuleGroupRequest],
  );

  const handleDeleteRule = useCallback(
    async (sid: string) => {
      let newRequest = {
        ...defaultUpdateRuleGroupRequest,
      };

      if (newRequest?.ruleGroup?.rulesSource?.statefulRules) {
        const newRules = newRequest?.ruleGroup?.rulesSource?.statefulRules?.filter(
          rule => !rule?.ruleOptions?.[0]?.settings?.includes(sid),
        );

        newRequest.ruleGroup.rulesSource.statefulRules = newRules;
      }

      await handleUpdateRuleGroup(newRequest, 'Delete rule successfully.', 'Delete rule failed.', () => {
        fetchDescribeRuleGroup();
      });
    },
    [defaultUpdateRuleGroupRequest],
  );

  return (
    <>
      <div className="page-detail">
        <PageDetailTitle
          title={data?.name}
          pageBackClick={pageBackClick}
          isDelete
          onDelete={() => setDeleteRuleGroupModalVisible(true)}
        />

        <div className="page-detail-tab">
          <div className="content-tab">
            <Details
              data={describeRuleGroup}
              isLoading={loading}
              isEdit
              handleEditDescription={handleEditDescription}
              onEditRuleGroupClicked={() => setEditDescriptionModalVisible(true)}
            />

            <RulesVariables
              data={describeRuleGroup}
              isLoading={loading}
              isEdit
              handleDeleteRuleVariable={handleDeleteRuleVariable}
              handleEditRuleVariables={handleEditRuleVariables}
            />

            <IpStateReferences
              data={describeRuleGroup}
              isLoading={loading}
              isEdit
              managedPrefixLists={managedPrefixLists}
              handleUpdateIpsetReference={handleUpdateIpsetReference}
              handleDeleteIpSetReferences={handleDeleteIpSetReferences}
            />

            <Rules
              data={describeRuleGroup}
              isLoading={loading}
              isEdit
              handleEditRule={handleEditRule}
              handleDeleteRule={handleDeleteRule}
              handleAddRule={handleAddRule}
            />

            <CustomerManagedKeys data={describeRuleGroup} isLoading={loading} isEdit />

            <Tags data={describeRuleGroup} isLoading={loading} isEdit />
          </div>
        </div>
      </div>

      {isEditDescriptionModalVisible ? (
        <EditFirewallDetailModal
          enumType={'EditRuleGroup'}
          header={'Edit rule group details'}
          valueName={'test-firewall-rule-group'}
          description={describeRuleGroup?.ruleGroupResponse?.description ?? ''}
          placeholderDescription={'The description can have 0-256 characters.'}
          open={isEditDescriptionModalVisible}
          onClose={() => setEditDescriptionModalVisible(false)}
          onSave={handleEditDescription}
        />
      ) : null}

      {isDeleteRuleGroupModalVisible && (
        <DeleteRuleModal
          header="Delete resource"
          note="To confirm deletions, write “delete” in this field."
          onDelete={handleDeleteRuleGroup}
          titleWarning="Are you sure you want to delete the rule groups: test-stateless-rule-group?"
          open={isDeleteRuleGroupModalVisible}
          confirmText={'delete'}
          descriptionWarning="This will delete the selected rule group. Deleting the rule group cannot be undone."
          onClose={() => setDeleteRuleGroupModalVisible(false)}
          loading={deleteFirewallRuleGroupLoading}
        />
      )}
    </>
  );
};

export default RuleGroupDetail;
