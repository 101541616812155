import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export type AwsCancelKeyDeletionVariables = {
  cloudId: number;
  region: string;
  reqData: {
    keyId?: string;
  };
};

export type AwsCancelKeyDeletionResponseData = {
  cancelAwsKeyDeletion: IGqlResponseData<undefined>;
};

const cancelAwsKeyDeletionMutation = () =>
  useMutation<AwsCancelKeyDeletionResponseData, AwsCancelKeyDeletionVariables>(query);
export default cancelAwsKeyDeletionMutation;
