import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { useCallback, useEffect, useState } from 'react';
import './index.scss';
import lazyGetAwsDescribeKey, { IAwsDescribeKeyVariables } from 'graphql/queries/getAwsDescribeKey';
import lazyGetAwsAllListAliasesPage from 'graphql/queries/getAwsAllListAliases';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { RowType } from '@Types/v2/Table';
import Icon from 'components/v2/atoms/Icon';
import IconReloadButton from 'assets/svgs/v3/ico_button_reload.svg';
import { DropdownListDataType } from 'components/Dropdown/types';
import SectionContainer from 'pages/v2/Organ/Management/components/SectionContainer';

const EncryptionPanel = (props: any) => {
  const { cloudId, region, creationData, handleValueChanged } = props;
  const [listAliases, setListAliases] = useState<any>([]);
  const [getAwsAllListAliases] = lazyGetAwsAllListAliasesPage();
  const [getAwsDescribeKey] = lazyGetAwsDescribeKey();

  const fetchAwsAllAwsKeys = useCallback(async () => {
    const awsAwsKeyRequestVariables = {
      cloudId,
      region,
      request: {
      },
    };
    const combinedVariable = variableCombineNextToken(awsAwsKeyRequestVariables);
    const { data: allListAliases } = await getAwsAllListAliases({ variables: combinedVariable });
    const listAliases = allListAliases?.getAwsAllListAliases?.data ?? [];
    if (!listAliases?.length) return;
    const awsKeyRows: RowType[] = [];
    for (const aliases of listAliases) {
      if (aliases.aliasName.startsWith('alias/aws/') && aliases.targetKeyId) {
        const variable: IAwsDescribeKeyVariables = {
          cloudId,
          region,
          request: {
            keyId: aliases.targetKeyId,
          },
        };
        const { data: keyData } = await getAwsDescribeKey({ variables: variable });

        awsKeyRows.push({
          name: aliases.aliasName.replace('alias/', ''),
          value: keyData?.getAwsDescribeKey.data[0]?.keyMetadata?.keyId ?? '-',
        });
      }
    }
    setListAliases(awsKeyRows);
    if (!(creationData?.keyEncryption && creationData?.nameEncryption)) {
      handleValueChanged('keyEncryption', awsKeyRows.at(-1)?.value);
      handleValueChanged('nameEncryption', awsKeyRows.at(-1)?.name);
    }
  }, [creationData.secretType]);

  useEffect(() => {
    fetchAwsAllAwsKeys();
  }, []);

  return (
    <SectionContainer
      title="Encryption key"
      description="You can encrypt using the KMS key that Secrets Manager creates or a customer managed KMS key that you create."
      customStyle="encryption-panel"
    >
      <div className="encryption-content">
        <div className="dropdown-list">
          <DropdownAtom
            id="select-encryption-key"
            className=""
            data={listAliases}
            value={{
              name: creationData.nameEncryption,
              value: creationData.keyEncryption,
            }}
            handleClick={(e: DropdownListDataType) => {
              handleValueChanged('nameEncryption', e.name);
              handleValueChanged('keyEncryption', e.value);
            }}
          />
        </div>
        <div className="btn-group">
          <Icon
            width={40}
            height={40}
            src={IconReloadButton}
            className="reload-btn"
            onClick={() => fetchAwsAllAwsKeys()}
          />
        </div>
      </div>
    </SectionContainer>
  );
};
export default EncryptionPanel;
