import './index.scss';
import { Fragment } from 'react';
import DomainAndIp from './DomainAndIp';
import ThreatSignature from './ThreatSignature';
import Space from 'pages/v2/Organ/Management/components/Space';

const AwsRuleGroup = (props: any) => {
  const {
    cloudId,
    region,
    currentRegion,
    currentRouteState,
    setCurrentRouteState,
    relatedCloudSelected,
    setDetailItem,
    setIsDetailPage,
  } = props;

  return (
    <Fragment>
      <div className="row-3 aws-rule-group-page">
        <div className="title aws-rule-group-title">
          <p>AWS Managed rule groups</p>

          <p>The following table lists the AWS Managed groups available to you.</p>
        </div>

        <div className="aws-rule-group-content">
          <DomainAndIp
            cloudId={cloudId}
            region={region}
            currentRegion={currentRegion}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            relatedCloudSelected={relatedCloudSelected}
          />

          <Space size={8} />

          <ThreatSignature
            cloudId={cloudId}
            region={region}
            currentRegion={currentRegion}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            relatedCloudSelected={relatedCloudSelected}
            setDetailItem={setDetailItem}
            setIsDetailPage={setIsDetailPage}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default AwsRuleGroup;
