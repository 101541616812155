import './styles.scss';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { AddRulesAndRuleGroupsStepPropsType } from '../type';
import { Input, RadioButtonGroup, SectionContainer, SectionTitle, StepContainer } from '../components';
import DetailTable from 'pages/v2/Organ/Management/EC2/components/DetailTable';
import { DEFAULT_ACTION_RADIO_LIST, DefaultActionEnum, RULES_COLUMN_LIST } from '../configs';
import Button, { ButtonTypeEnum } from '../components/Button';
import SectionItem from '../components/SectionItem';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import JSONViewer from 'components/JSONViewer';
import CustomRuleModal from '../../../CustomRuleModal';
import { ButtonType } from '../components/types';
import ManagedRuleModal, { UIFreeRuleGroupType } from '../../../ManagedRuleModal';
import CustomResponseModal from './CustomResponseModal';
import { randomString } from 'utils/Common';
import lazyGetAwsCheckCapacity from 'graphql/queries/getAwsCheckCapacity';
import { AwsRulelogType as AwsRulelogFromCheckCapacityType } from 'graphql/types/AwsCheckCapacity';
import _ from 'lodash';
import { RowType } from '@Types/v2/Table';
import { AwsRulelogType } from 'graphql/types/AwsUpdateRuleGroup';
import EditRuleGroupModal from '../../../ManagedRuleModal/components/EditRuleGroup';

const DEFAULT_DOMAIN = {
  value: '',
  isValid: false,
};

const DEFAULT_HEADER = {
  key: '',
  value: '',
  isKeyValid: false,
  isValueValid: false,
};

type DomainType = {
  value: string;
  isValid: boolean;
};

type HeaderType = {
  key: string;
  value: string;
  isKeyValid: boolean;
  isValueValid: boolean;
};

type SpecifyResponseBodyType = {
  value: string;
  name: string;
  contentType?: string;
  responseBody?: string;
};

const defaultSpecifyResponseBody = { value: randomString(), name: '-' };

const SPECIFY_RESPONSE_BODY_DROPDOWN_LIST: Array<SpecifyResponseBodyType> = [
  defaultSpecifyResponseBody,
  { value: randomString(), name: 'Create a custom response body' },
];

const MAXIMUM_DOMAIN_ALLOWED = 10;

const AddRulesAndRuleGroupsStep = (props: AddRulesAndRuleGroupsStepPropsType) => {
  const { title, description, creationData, setCreationData, cloudId, region, isSubmit } = props;

  const [checkCapacity] = lazyGetAwsCheckCapacity();

  const initDomains = creationData?.domains?.length
    ? creationData.domains.map((value: string) => ({
        value,
        isValid: false,
      }))
    : [];

  const initHeaders = creationData?.headers?.length
    ? creationData.headers.map(({ key, value }: { key: string; value: string }) => ({
        key,
        value,
        isKeyValid: false,
        isValueValid: false,
      }))
    : [];

  const [defaultAction, setDefaultAction] = useState<DefaultActionEnum>(
    creationData?.defaultAction || DefaultActionEnum.ALLOW,
  );
  const [domains, setDomains] = useState<Array<DomainType>>(initDomains);
  const [customRequest, setCustomRequest] = useState<Array<HeaderType>>(
    defaultAction === DefaultActionEnum.ALLOW ? initHeaders : [],
  );
  const [customResponse, setCustomResponse] = useState(creationData.customResponse);
  const [headers, setHeaders] = useState<Array<HeaderType>>(
    defaultAction === DefaultActionEnum.BLOCK && customResponse ? initHeaders : [],
  );
  const [responseCode, setResponseCode] = useState(creationData.responseCode);
  const [isCustomRuleModalVisible, setCustomRuleModalVisible] = useState(false);
  const [isManagedRuleModalVisible, setManagedRuleModalVisible] = useState(false);
  const [addRuleDropdownValue, setAddRuleDropdownValue] = useState({ name: 'Add rule', value: '' });
  const [rules, setRules] = useState<any>(creationData?.rules || []);
  const [selectedRule, setSeletedRule] = useState<string>('');

  const [specifyResponseBodyData, setSpecifyResponseBodyData] = useState<Array<SpecifyResponseBodyType>>(
    creationData?.specifyResponseBodyData || SPECIFY_RESPONSE_BODY_DROPDOWN_LIST,
  );
  const [specifyResponseBodyItem, setSpecifyResponseBodyItem] = useState<SpecifyResponseBodyType>(
    creationData?.specifyResponseBody || defaultSpecifyResponseBody,
  );
  const [isOpenCreateResponseBody, setIsOpenCreateResponseBody] = useState(false);
  const [responseCodeValid, setResponseCodeValid] = useState(false);
  const [totalWCUs, setTotalWCUs] = useState(0);
  const [editingRule, setEditingRule] = useState<AwsRulelogType | null>(null);
  const [capacityList, setCapacityList] = useState<{ [key: string]: number }>({});
  const [isEditManagedRuleModalVisible, setEditManagedRuleModalVisible] = useState(false);

  const handleCheckCapacity = useCallback(
    async (_rules: Array<AwsRulelogType>) => {
      const requestRules = _rules.map(rule => _.omit(rule, 'capacity'));
      const variables = {
        cloudId: cloudId,
        region: region.value !== 'CloudFront' ? String(region.value) : 'us-east-1',
        request: {
          scope: region.value !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
          rules: requestRules as Array<AwsRulelogFromCheckCapacityType>,
        },
      };
      return await checkCapacity({ variables }).then(res => {
        return res?.data?.getAwsCheckCapacity?.data?.[0]?.capacity ?? 0;
      });
    },
    [cloudId, region],
  );

  const getCapacityList = useCallback(async () => {
    const rulesCapacity = await handleCheckCapacity(rules);
    setTotalWCUs(rulesCapacity);

    const capacityListResult = await Promise.all(rules.map((rule: AwsRulelogType) => handleCheckCapacity([rule])));
    let _capacityList: { [key: string]: number } = {};
    rules.forEach((rule: AwsRulelogType, index: number) => {
      _capacityList[rule?.name ?? ''] = capacityListResult[index];
    });

    setCapacityList(_capacityList);
  }, [rules, handleCheckCapacity]);

  useEffect(() => {
    getCapacityList();
  }, [getCapacityList]);

  const handleDomainsValid = () => {
    const domainsValid = domains?.map((e: DomainType) => ({
      ...e,
      isValid: !e.value,
    }));
    setDomains(domainsValid);
  };

  const handleCustomRequestValid = () => {
    const customRequestValid = customRequest?.map((e: HeaderType) => ({
      ...e,
      isKeyValid: !e.key,
      isValueValid: !e.value,
    }));
    setCustomRequest(customRequestValid);
  };

  const handleHeadersValid = () => {
    const headerValid = headers?.map((e: HeaderType) => ({
      ...e,
      isKeyValid: !e.key,
      isValueValid: !e.value,
    }));
    setHeaders(headerValid);
  };

  const handleValidate = () => {
    handleDomainsValid();

    if (defaultAction === DefaultActionEnum.ALLOW) {
      handleCustomRequestValid();
    } else if (customResponse) {
      setResponseCodeValid(!responseCode);
      handleHeadersValid();
    }
  };

  useEffect(() => {
    if (!isSubmit) return;

    handleValidate();
  }, [isSubmit]);

  useEffect(() => {
    const formatCustomRequest = customRequest.map(({ key, value }) => ({
      key,
      value,
    }));

    const formatHeaders = headers.map(({ key, value }) => ({
      key,
      value,
    }));

    setCreationData({
      defaultAction,
      domains: domains.map(e => e.value),
      headers: defaultAction === DefaultActionEnum.ALLOW ? formatCustomRequest : customResponse ? formatHeaders : [],
      customResponse,
      responseCode,
      specifyResponseBodyData,
      specifyResponseBody: specifyResponseBodyItem,
      rules: rules.map((rule: any, index: number) => ({
        ...rule,
        id: randomString(),
        priority: index,
      })),
    });
  }, [
    defaultAction,
    domains,
    headers,
    customResponse,
    responseCode,
    specifyResponseBodyData,
    specifyResponseBodyItem,
    rules,
  ]);

  const onRadioButtonGroupValueChanged = useCallback((value: string) => {
    setDefaultAction(value as DefaultActionEnum);
  }, []);

  const onDropdownValueChanged = useCallback((dropdown: DropdownListDataType) => {
    if (dropdown.value === 'managedRule') {
      setManagedRuleModalVisible(true);
    }

    if (dropdown.value === 'ownRule') {
      setCustomRuleModalVisible(true);
    }
  }, []);

  const handleAddNewCustomRequest = useCallback(() => {
    setCustomRequest([...customRequest, { ...DEFAULT_HEADER }]);
  }, [customRequest]);

  const handleRemoveCustomRequest = useCallback(
    (index: number) => {
      let newCustomRequest = [...customRequest];
      newCustomRequest.splice(index, 1);
      setCustomRequest(newCustomRequest);
    },
    [customRequest],
  );

  const handleCustomRequestValueChanged = useCallback(
    (value: string, key: 'key' | 'value', index: number) => {
      let newCustomRequest = [...customRequest];
      newCustomRequest[index][key] = value;
      setCustomRequest(newCustomRequest);
    },
    [customRequest],
  );

  const handleAddNewCustomHeader = useCallback(() => {
    setHeaders([...headers, { ...DEFAULT_HEADER }]);
  }, [headers]);

  const handleRemoveCustomHeader = useCallback(
    (index: number) => {
      let newHeaders = [...headers];
      newHeaders.splice(index, 1);
      setHeaders(newHeaders);
    },
    [headers],
  );

  const handleCustomHeaderValueChanged = useCallback(
    (value: string, key: 'key' | 'value', index: number) => {
      let newHeaders = [...headers];
      newHeaders[index][key] = value;
      setHeaders(newHeaders);
    },
    [headers],
  );

  const specifyResponseBodyOption = useMemo(() => {
    if (!specifyResponseBodyData?.length) return [];

    return specifyResponseBodyData.map(({ value, name }: { value: string; name: string }) => ({
      value,
      name,
    }));
  }, [specifyResponseBodyData]);

  const handleChangeSpecifyResponseBody = (e: DropdownListDataType) => {
    if (!specifyResponseBodyOption?.length) return;

    const item = specifyResponseBodyData.find(({ value }: { value: string }) => value === e.value);

    if (!item) return;

    setSpecifyResponseBodyItem(item);

    if (e?.value === specifyResponseBodyOption[1]?.value) setIsOpenCreateResponseBody(true);
  };

  const handleCloseCreateResponseBody = () => {
    setSpecifyResponseBodyItem(defaultSpecifyResponseBody);
    setIsOpenCreateResponseBody(false);
  };

  const handleCreateResponseBody = (data: any) => {
    const value = randomString();
    const arr = [...specifyResponseBodyData, { ...data, value }];
    setSpecifyResponseBodyData(arr);
    setIsOpenCreateResponseBody(false);
    setSpecifyResponseBodyItem({
      ...data,
      value,
    });
  };

  const handleAddTokenDomain = useCallback(() => {
    setDomains([...domains, { ...DEFAULT_DOMAIN }]);
  }, [domains]);

  const handleRemoveTokenDomain = useCallback(
    (index: number) => {
      let newDomains = [...domains];
      newDomains.splice(index, 1);
      setDomains(newDomains);
    },
    [domains],
  );

  const handleDomainValueChanged = useCallback(
    (value: string, index: number) => {
      const newDomains = [...domains];
      newDomains[index].value = value;
      setDomains(newDomains);
    },
    [domains],
  );

  const handleAddRule = useCallback(
    async (rule: AwsRulelogType & Array<AwsRulelogType>) => {
      setCustomRuleModalVisible(false);
      if (rule?.length > 0) {
        let newRules: Array<AwsRulelogType & { capacity: number }> = [];

        for (const _rule of rule) {
          const capacity = await handleCheckCapacity([_rule]);

          newRules.push({
            ..._rule,
            capacity: capacity,
          });
        }

        setRules((prev: any) => [...prev, ...newRules]);
      } else {
        const capacity = await handleCheckCapacity([rule]);

        setRules((prev: any) => [
          ...prev,
          {
            ...rule,
            capacity,
          },
        ]);
      }
    },
    [handleCheckCapacity],
  );

  const handleEditManagedRule = useCallback(
    async (editedRules: Array<AwsRulelogType>) => {
      let rulesWithCapacity: Array<AwsRulelogType & { capacity: number }> = [];

      for (const _rule of editedRules) {
        const capacity = await handleCheckCapacity([_rule]);
        rulesWithCapacity.push({
          ..._rule,
          capacity: capacity,
        });
      }

      const ownRules = rules.filter((rule: AwsRulelogType) => !rule?.statement?.managedRuleGroupStatement);

      setRules([...ownRules, ...rulesWithCapacity]);
    },
    [handleCheckCapacity, rules],
  );

  const customRequestNode = useMemo(() => {
    return (
      <>
        {customRequest.map((e, index) => (
          <div className="header-row-container">
            <Input
              title={'Key'}
              placeholder="Header name"
              value={e.key}
              onValueChanged={value => handleCustomRequestValueChanged(value, 'key', index)}
              isVertical
              isRequired
              error={e.isKeyValid}
            />
            <Input
              title={'Value'}
              placeholder="Header value"
              value={e.value}
              onValueChanged={value => handleCustomRequestValueChanged(value, 'value', index)}
              isVertical
              error={e.isValueValid}
            />
            <Button label="Remove" onClick={() => handleRemoveCustomRequest(index)} />
          </div>
        ))}
        <Button label={'Add new custom header'} onClick={handleAddNewCustomRequest} type={ButtonTypeEnum.PRIMARY} />
      </>
    );
  }, [customRequest]);

  const domainNode = useMemo(() => {
    return (
      <>
        {domains.map(({ value, isValid }, index) => (
          <div className="header-row-container">
            <Input
              title={'Key'}
              placeholder="Enter domain"
              value={value}
              onValueChanged={value => handleDomainValueChanged(value, index)}
              isVertical
              isRequired
              error={isValid}
            />
            <Button label="Remove" onClick={() => handleRemoveTokenDomain(index)} />
          </div>
        ))}
        {domains.length < MAXIMUM_DOMAIN_ALLOWED && (
          <Button label={'Add domain'} onClick={handleAddTokenDomain} type={ButtonTypeEnum.PRIMARY} />
        )}
      </>
    );
  }, [domains]);

  const responseNode = useMemo(() => {
    return (
      <>
        <Input
          title="Response code"
          onValueChanged={setResponseCode}
          value={responseCode}
          isVertical
          placeholder="Enter response code"
          error={responseCodeValid}
        />
        <SectionTitle
          title="Response headers"
          description="Specify the custom headers to be included in the custom response. The header key cannot be “content-type”."
          caption="- optional"
          customStyle="title-section-without-padding"
        />
        {headers.map((header, index) => (
          <div className="header-row-container">
            <Input
              title={'Key'}
              placeholder="Header name"
              value={header.key}
              onValueChanged={value => handleCustomHeaderValueChanged(value, 'key', index)}
              isVertical
              isRequired
              error={header.isKeyValid}
            />
            <Input
              title={'Value'}
              placeholder="Header value"
              value={header.value}
              onValueChanged={value => handleCustomHeaderValueChanged(value, 'value', index)}
              isVertical
              error={header.isValueValid}
            />
            <Button label="Remove" onClick={() => handleRemoveCustomHeader(index)} />
          </div>
        ))}
        <Button label={'Add new custom header'} onClick={handleAddNewCustomHeader} type={ButtonTypeEnum.PRIMARY} />
        <SectionTitle
          title="Choose how you would like to specify the response body"
          description="Select an existing réponse body or create a new one. You can use a response body anywhere in the web ACL or rule group where you create it."
          caption="- optional"
          customStyle="title-section-without-padding"
        />
        <DropdownAtom
          id="specify-response-body-dropdown"
          placeholder="-"
          data={specifyResponseBodyOption}
          value={{
            value: specifyResponseBodyItem?.value || '',
            name: specifyResponseBodyItem?.name || '',
          }}
          handleClick={handleChangeSpecifyResponseBody}
        />

        {specifyResponseBodyItem?.responseBody ? (
          <>
            <SectionTitle
              title="Response body"
              description="The response body can be plain text, HTML, or JSON."
              customStyle="title-section-without-padding"
            />

            <JSONViewer
              data={specifyResponseBodyItem?.responseBody}
              bottomDescription="Response body cannot exceed 4 KB in size."
            />
          </>
        ) : null}
      </>
    );
  }, [responseCode, headers, specifyResponseBodyOption, specifyResponseBodyItem]);

  const customRequestContainer = useMemo(() => {
    if (defaultAction === 'allow') {
      return (
        <SectionItem.Collapsible
          title={'Custom request'}
          caption="- optional"
          description="With the Allow action, you can add custom headers to the web request. AWS WAF prefixes your custom header names with x-amzn-waf- when it inserts them."
        >
          <SectionItem.Container>{customRequestNode}</SectionItem.Container>
        </SectionItem.Collapsible>
      );
    }

    return (
      <SectionItem.Collapsible
        title={'Custom response'}
        caption="- optional"
        description="With the Block action, you can send a custom response to the web request."
      >
        <SectionItem.Container>
          <SectionItem.Checkbox
            label="Enable"
            checked={customResponse}
            onchange={() => setCustomResponse(!customResponse)}
          />
          {customResponse && responseNode}
        </SectionItem.Container>
      </SectionItem.Collapsible>
    );
  }, [defaultAction, customRequestNode, customResponse, responseNode]);

  const ruleRows = useMemo(() => {
    return rules.map((rule: AwsRulelogType & { capacity?: number }) => {
      const { name, action, capacity } = rule ?? {};

      let actionString = '';

      if (
        !_.isEmpty(rule?.statement?.managedRuleGroupStatement) ||
        !_.isEmpty(rule?.statement?.ruleGroupReferenceStatement)
      ) {
        if (rule?.overrideAction?.none) {
          actionString = 'Use rule actions';
        }
        if (rule?.overrideAction?.count) {
          actionString = 'Override rule group action to count';
        }
      } else {
        actionString = _.startCase(Object.keys(rule?.action ?? {})?.[0] ?? '-');
      }

      const _capacity = capacity ?? capacityList[name ?? ''] ?? '-';

      return {
        id: _.uniqueId('rule'),
        name: name,
        capacity: _capacity,
        action: actionString,
      };
    });
  }, [rules, capacityList]);

  const handleEditRule = useCallback(() => {
    const editingRuleRow = ruleRows.find((row: RowType) => row.id === selectedRule);
    const editingRule = rules?.find((rule: AwsRulelogType) => rule?.name === editingRuleRow?.name);

    if (!editingRule) {
      return;
    }

    setEditingRule(editingRule);

    if (editingRule?.statement?.managedRuleGroupStatement) {
      setEditManagedRuleModalVisible(true);
      return;
    }

    if (editingRule?.statement?.ruleGroupReferenceStatement) {
      // Handle rule group
      return;
    }

    if (editingRule?.statement?.ipSetReferenceStatement) {
      // Handle rule group
      return;
    }

    setCustomRuleModalVisible(true);
  }, [ruleRows, rules, selectedRule]);

  const handleRemoveRule = useCallback(
    (rowId: string) => {
      const removingRow = ruleRows.find((row: RowType) => row.id === rowId);
      const newRules = rules.filter((rule: AwsRulelogType) => rule.name !== removingRow.name);
      setRules(newRules);
    },
    [rules, ruleRows],
  );

  const rulesActionButtons: Array<ButtonType> = useMemo(() => {
    return [
      {
        id: 'edit',
        label: 'Edit',
        type: ButtonTypeEnum.GENERAL,
        onClick: handleEditRule,
        disabled: !selectedRule || rules.length <= 0,
      },
      {
        id: 'delete',
        label: 'Delete',
        type: ButtonTypeEnum.PRIMARY,
        onClick: () => handleRemoveRule(selectedRule),
        disabled: !selectedRule || rules.length <= 0,
      },
      {
        id: 'add',
        label: 'Add rule',
        type: ButtonTypeEnum.DROPDOWN,
        onClick: () => {},
        dropdownList: [
          { name: 'Add managed rule groups', value: 'managedRule' },
          { name: 'Add my own rules and rule groups', value: 'ownRule' },
        ],
        dropdownValue: addRuleDropdownValue,
        onDropdownValueChanged,
      },
    ];
  }, [handleEditRule, handleRemoveRule, selectedRule, rules]);

  return (
    <StepContainer title={title} description={description}>
      <DetailTable
        title={'Rules'}
        description={
          'If a request matches a rule, take the corresponding action. The rules are prioritized in order they appear.'
        }
        columns={RULES_COLUMN_LIST}
        data={ruleRows}
        rightButtons={rulesActionButtons}
        isRadioTable={true}
        reportValue={selectedRule}
        reportSelected={setSeletedRule}
      />

      <SectionContainer
        title="Web ACL capacity units (WCUs) used by your web ACL"
        description="The WCUs used by the web ACL will be less than or equal to the sum of the capacities for all of the rules in the web ACL."
      >
        <SectionItem.Container
          title={'The total WCUs for a web ACL can’t exceed 5000. Using over 1500 WCUs affects your costs.'}
        >
          <SectionItem.StyledText text={`${totalWCUs}/5000 WCUs`} />
        </SectionItem.Container>
      </SectionContainer>

      <SectionContainer title="Default web ACL action for requests that don’t match any rules">
        <SectionTitle title={'Default action'} />
        <RadioButtonGroup
          data={DEFAULT_ACTION_RADIO_LIST}
          value={defaultAction}
          onChangeValue={onRadioButtonGroupValueChanged}
        />
        {customRequestContainer}
      </SectionContainer>

      <SectionContainer
        title="Token domain list"
        description="Enable the use of tokens across multiple protected applications by entering the application domains here. Tokens are used by the Challenge and CAPTCHA rule actions, the application integration SDKs, and the ATP and Bot Control managed rule groups."
        caption="- optional"
        bottomDescription={`You can add ${MAXIMUM_DOMAIN_ALLOWED - domains.length} more domains.`}
      >
        <SectionItem.Container>{domainNode}</SectionItem.Container>
      </SectionContainer>

      {isCustomRuleModalVisible && (
        <CustomRuleModal
          isOpen={isCustomRuleModalVisible}
          onClose={() => {
            setCustomRuleModalVisible(false);
            setEditingRule(null);
          }}
          cloudId={cloudId}
          selectedRegion={region}
          onAddRule={handleAddRule}
          webAcl={creationData}
          rule={editingRule || undefined}
        />
      )}

      {isManagedRuleModalVisible && (
        <ManagedRuleModal
          isVisible={isManagedRuleModalVisible}
          onClose={() => setManagedRuleModalVisible(false)}
          cloudId={cloudId}
          selectedRegion={region.value.toString()}
          webAcl={creationData}
          onAddRule={handleEditManagedRule}
          selectedRuleGroups={rules?.filter((rule: AwsRulelogType) => rule?.statement?.managedRuleGroupStatement) ?? []}
        />
      )}

      {isEditManagedRuleModalVisible && (
        <ManagedRuleModal
          isVisible={isEditManagedRuleModalVisible}
          onClose={() => setEditManagedRuleModalVisible(false)}
          cloudId={cloudId}
          selectedRegion={region.value.toString()}
          webAcl={creationData}
          onAddRule={handleEditManagedRule}
          editingRule={editingRule as AwsRulelogType}
          selectedRuleGroups={rules?.filter((rule: AwsRulelogType) => rule?.statement?.managedRuleGroupStatement) ?? []}
        />
      )}

      <CustomResponseModal
        open={isOpenCreateResponseBody}
        onClose={handleCloseCreateResponseBody}
        header="Create a custom response body"
        onSave={handleCreateResponseBody}
      />
    </StepContainer>
  );
};

AddRulesAndRuleGroupsStep.propTypes = {};

export default AddRulesAndRuleGroupsStep;
