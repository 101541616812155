import { gql } from '@apollo/client';

const query = gql`
  query getAwsListKeys($request: AwsListKeysRequest, $cloudId: Long, $region: String) {
    getAwsListKeys(listKeysRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListKeysResponse {
          keys {
            keyId
            keyArn
          }
          nextMarker
          truncated
        }
      }
    }
  }
`;
export default query;