import { useState, useEffect, useMemo } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconEditRuleGroup from 'assets/svgs/v3/ico_policy_variables.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import DetailTable from 'pages/v2/Organ/Management/components/DetailTable';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import IconDelete from 'assets/svgs/v3/ico_delete_network.svg';
import { AwsKeyMetadata } from 'graphql/types/AwsKey';

type ScheduleKeyDeletionModalPropsType = {
  onSave?: (period: string) => void;
  aliases?: Array<string>;
  keyId?: string;
  describeKey?: AwsKeyMetadata;
} & IBaseModalProps;

const ScheduleKeyDeletionModal = (props: ScheduleKeyDeletionModalPropsType) => {
  const { aliases, keyId, onSave, describeKey, ...baseModalProps } = props;

  const [confirm, setConfirm] = useState(false);
  const [period, setPeriod] = useState<string>('30');

  const aliasesRows = useMemo(() => {
    let aliasNames = `${aliases?.[0]}`;
    if (aliases?.length && aliases?.length > 1) {
      aliasNames += `(+${aliases?.length - 1} more)`;
    }

    return [
      {
        id: keyId,
        aliasName: aliasNames,
        keyId: describeKey?.keyId,
        keyUsage: describeKey?.keyUsage,
        regionality: describeKey?.multiRegion
          ? `Multi Region ${describeKey?.multiRegionConfiguration.multiRegionKeyType}`
          : 'Single Region',
      },
    ];
  }, [keyId, aliases, describeKey]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconDelete} />
          {'Schedule key deletion'}
        </>
      )}
      {...baseModalProps}
    >
      <div className="modal-content-container">
        <div className="warning-container">
          <Icon width={32} height={32} src={IconWarning} />
          <p>
            Deleting a key makes all data encrypted under that key unrecoverable.
            <br />
            <br />
            You can create an Amazon CloudWatch alarm that alerts you about any attempts to use the key(s) during the
            waiting period.
          </p>
        </div>
        <p className="title">Waiting period</p>
        <p className="description">
          You can cancel deletion any time before the waiting period ends. After the waiting period ends, AWS KMS
          deletes the key(s).
        </p>
        <div className="input">
          <p>Waiting period (in days)</p>
          <InputAtom placeholder={''} value={period} noClear={true} onChangeValue={val => setPeriod(val)} />
        </div>
        <p className="table-title">Enter a waiting period between 7 and 30 days.</p>
        <div className="table-wrapper">
          <DetailTable
            data={aliasesRows ?? []}
            columns={[
              { field: 'aliasName', label: 'Alias name', sort: true },
              { field: 'keyId', label: 'Key ID', sort: true },
              { field: 'keyUsage', label: 'Key usage', sort: true },
              { field: 'regionality', label: 'Regionality', sort: true },
            ]}
            className="table-container"
            hasPagination={false}
          />
        </div>

        <div className="input">
          <CheckboxAtom
            label="Confirm that you want to disable this key."
            checked={confirm}
            onchange={() => setConfirm(!confirm)}
          />
        </div>

        <div className="button-group">
          <Button type={ButtonTypeEnum.GENERAL} onClick={() => baseModalProps?.onClose?.()} label="Cancel" />
          <Button
            disabled={!confirm}
            type={ButtonTypeEnum.PRIMARY}
            onClick={() => onSave?.(period)}
            label="Disable key"
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default ScheduleKeyDeletionModal;
