import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { InboundCertificateInspectionPropsType } from './types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import lazyGetAwsDescribeCertificate from 'graphql/queries/getAwsDescribeCertificate';
import { extractCertificateArnData } from '../../../Function';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdTabLayout/types';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import './CertificateInspection.scss';

const InboundCertificateInspection = (props: InboundCertificateInspectionPropsType) => {
  const { actionNode, certificateInboundArn, cloudId, region } = props;

  const [getAwsDescribeCertificate, { loading: getDescribeLoading }] = lazyGetAwsDescribeCertificate();

  const [mainTblTotalRows, setMainTblTotalRows] = useState<RowType[]>([]);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [tablePagination, setTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Certificate ID',
        field: 'certificateId',
      },
      {
        label: 'Account ID',
        field: 'accountId',
      },
      {
        label: 'Domain name',
        field: 'domainName',
      },
    ];
  }, []);

  const fetCertificateInspectionData = useCallback(async () => {
    if (certificateInboundArn.length > 0) {
      const inboundSectionContentRows: RowType[] = [];
      await Promise.all(
        certificateInboundArn.map(async arn => {
          const certificateInboundVariables = {
            cloudId,
            region: region,
            request: {
              certificateArn: arn ?? '',
            },
          };
          const awsCertificateInbound = await getAwsDescribeCertificate({
            variables: certificateInboundVariables,
          }).then(({ data: awsCertificate }) => {
            return awsCertificate?.getAwsDescribeCertificate?.data?.[0]?.certificate;
          });
          
          if (awsCertificateInbound) {
            inboundSectionContentRows.push({
              certificateId: awsCertificateInbound.certificateArn
                ? extractCertificateArnData(awsCertificateInbound.certificateArn)?.id
                : '-',
              accountId: awsCertificateInbound.certificateArn
                ? extractCertificateArnData(awsCertificateInbound.certificateArn)?.accountId
                : '-',
              domainName: awsCertificateInbound.domainName || '-',
            });
          }
        }),
      );
      setMainTblTotalRows(inboundSectionContentRows);
      setMainTblTotal({
        totalPage: Math.ceil(inboundSectionContentRows.length / tablePagination.itemPerPage),
        totalElement: inboundSectionContentRows.length,
      });
    }
  }, [tablePagination, cloudId, region, certificateInboundArn]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  useEffect(() => {
    fetCertificateInspectionData();
  }, [certificateInboundArn]);

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>{'Server certificates for inbound SSL/TLS inspection'}</p>
        {actionNode && actionNode}
      </div>

      {!mainTblTotalRows.length && !getDescribeLoading ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="detail-info-content">
          <div className="data-grid-wrap">
            <Table
              rows={mainTblTotalRows}
              columns={columns}
              sortOption={{
                target: tablePagination.target,
                direction: tablePagination.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  updateTablePagination('target', target);
                  updateTablePagination('direction', dir);
                },
              }}
              isLoading={getDescribeLoading}
              horizontalScrollable={true}
            />

            {mainTblTotalRows?.length && !getDescribeLoading ? (
              <div className="fleet-instance pagination-wrapper flex a-center">
                <p className="flex a-center">
                  Total <span>{mainTblTotal.totalElement}</span>
                </p>

                <TableManagePagination
                  ableFetchMore={false}
                  currentPage={tablePagination.currentPage}
                  updateCurrentPage={page => updateTablePagination('currentPage', page)}
                  totalPage={mainTblTotal.totalPage}
                />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default InboundCertificateInspection;
