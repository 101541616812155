import { gql } from '@apollo/client';

const query = gql`
  query getAwsAutomatedDiscoveryConfiguration(
    $request: AwsGetAutomatedDiscoveryConfigurationRequest
    $cloudId: Long
    $region: String
  ) {
    getAwsAutomatedDiscoveryConfiguration(
      awsGetAutomatedDiscoveryConfigurationRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsGetAutomatedDiscoveryConfigurationResponse {
          autoEnableOrganizationMembers
          classificationScopeId
          disabledAt
          firstEnabledAt
          lastUpdatedAt
          sensitivityInspectionTemplateId
          status
        }
      }
    }
  }
`;
export default query;
