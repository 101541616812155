import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { DropdownListDataType } from 'components/Dropdown/types';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import lazyGetAwsFindingStatistics from 'graphql/queries/getAwsFindingStatistics';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import _ from 'lodash';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { randomString } from 'utils/Common';
import { IMacieListProps } from '../../../types';
import MacieSearchBar from '../../SearchBar';
import { FINDING_STATUS, generateSearchParam } from '../../SearchBar/config';
import { FilterType } from '../../SearchBar/types';
import lazyGetAwsDescribeBuckets from 'graphql/queries/getAwsDescribeBuckets';

interface IMacieListUpProps extends IMacieListProps<{}> {}

const S3BucketListUp = (props: IMacieListUpProps) => {
  const {
    cloudId,
    region,
    currentRegion,
    currentRouteState,
    setCurrentRouteState,
    relatedCloudSelected,
    setDetailItem,
    setIsDetailPage,
    onNavigateCreation,
  } = props;
  const [getAwsDescribeBuckets] = lazyGetAwsDescribeBuckets();
  const [isLoading, setIsLoading] = useState(false);
  const [nextToken, setNextToken] = useState<string>('');
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'bucketName',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [searchData, setSearchData] = useState<any>(() =>
    generateSearchParam(FilterType.FINDING, currentRouteState?.value || [], FINDING_STATUS[0]),
  );
  const [prevSearchData, setPrevSearchData] = useState<any>({});
  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState, setCurrentRouteState]);
  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Sensitivity',
        field: 'sensitivityScore',
        sort: true,
      },
      {
        label: 'Bucket',
        field: 'bucketName',
        sort: true,
      },
      {
        label: 'Account',
        field: 'accountId',
        sort: true,
      },
      {
        label: 'Classifiable objects',
        field: 'classifiableObjectCount',
        sort: true,
      },
      {
        label: 'Classifiable size',
        field: 'classifiableSizeInBytes',
        sort: true,
        renderCell: row => {
          const data = Math.ceil(Number(row.classifiableSizeInBytes) / 100) / 10;

          return <>{data > 0 ? data + ' KB' : '0'}</>;
        },
      },
      {
        label: 'Monitored by job',
        field: 'isMonitoredByJob',
        sort: true,
        renderCell: row => {
          const data = row.isMonitoredByJob === 'TRUE' ? 'Yes' : 'No';

          return <>{data}</>;
        },
      },
      {
        label: 'Latest job run',
        field: 'lastJobRunTime',
        sort: true,
      },
    ];
  }, []);
  const getVariableData = useCallback(
    (usePrevSearchData?: boolean) => {
      let processedSearchData: any = {};
      let searchDataParam = usePrevSearchData ? prevSearchData : searchData;
      console.log(prevSearchData, searchData);
      if (searchDataParam?.findingCriteria?.criterion) {
        processedSearchData = _.cloneDeep(searchDataParam);
        processedSearchData.findingCriteria.criterion = JSON.stringify(processedSearchData.findingCriteria.criterion);
      }

      return {
        cloudId: relatedCloudSelected.value,
        region: currentRegion.value as string,
        reqData: {
          // groupBy: 'resourcesAffected.s3Bucket.name',
          // size: mainTablePagination.limit,
          ...processedSearchData,
        },
      };
    },
    [prevSearchData, searchData, relatedCloudSelected.value, currentRegion.value, mainTablePagination.limit],
  );
  const handleGetMainDataRows = useCallback(
    async (nextToken?: string, usePrevSearchData?: boolean) => {
      if (!region) return;
      try {
        setIsLoading(true);
        const { data: describeBucket } = await getAwsDescribeBuckets({
          variables: variableCombineNextToken(getVariableData(usePrevSearchData), nextToken),
        });
        if (!describeBucket?.getAwsDescribeBuckets?.data?.length) {
          setIsLoading(false);

          return;
        }
        const data =
          describeBucket?.getAwsDescribeBuckets?.data?.[0]?.buckets?.map(e => ({
            ...e,
            id: randomString(),
          })) || [];
        setNextToken(describeBucket?.getAwsDescribeBuckets?.nextToken as string);
        setMainTblRows(data as RowType[]);
        setMainTblTotal({
          totalPage: Math.ceil(data?.length / mainTablePagination.limit),
          totalElement: data?.length,
        });
        setIsLoading(false);
        if (!usePrevSearchData) {
          setPrevSearchData(searchData);
        }
      } catch (error) {
        setIsLoading(false);
      }
    },
    [region, getAwsDescribeBuckets, getVariableData, mainTablePagination.limit, searchData],
  );
  const mainRowsCurrentPage = useMemo((): RowType[] => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!nextToken) {
      handleGetMainDataRows(nextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;
    setMainTblTotal({
      totalPage: Math.ceil(mainTblRows.length / mainTablePagination.itemPerPage),
      totalElement: mainTblRows.length,
    });

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [
    mainTablePagination.currentPage,
    mainTablePagination.itemPerPage,
    mainTablePagination.target,
    mainTablePagination.direction,
    mainTblTotal.totalPage,
    nextToken,
    mainTblRows,
    handleGetMainDataRows,
  ]);
  useEffect(() => {
    handleGetMainDataRows();
  }, [cloudId, handleGetMainDataRows]);
  const handleSelectItem = useCallback(
    (id: string) => {
      const rowSelected = mainTblRows.find(row => row.id === id);
      if (!rowSelected) return;
      setDetailItem(rowSelected);
    },
    [mainTblRows, setDetailItem],
  );
  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>S3 bucket</p>
          <p>The table lists S3 buckets for your or organization. Choose a bucket to show its details.</p>
        </div>
        <div className="flex action a-center">
          <MacieSearchBar
            onSearch={() => handleGetMainDataRows(undefined, false)}
            onSearchDataChange={setSearchData}
            filterType={FilterType.S3_BUCKET}
            initialAppliedFilters={currentRouteState?.value || []}
          />
        </div>
      </div>
      {!mainRowsCurrentPage?.length && !isLoading ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="data-grid-wrap">
          <Table
            rows={mainRowsCurrentPage}
            columns={columns}
            reportCheckedList={() => {}}
            reportSelected={id => handleSelectItem(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={isLoading}
            horizontalScrollable
          />
          {!!mainRowsCurrentPage?.length && !isLoading && (
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{mainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={!!nextToken}
                currentPage={mainTablePagination.currentPage}
                updateCurrentPage={page =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default S3BucketListUp;
