import InputErrorIcon from 'assets/svgs/v2/ico_input_error_red.svg';
import ArrowIcon from 'assets/svgs/v2/ico_dropdown_arrow.svg';
import CloseIcon from 'assets/svgs/v3/ico_close.svg';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

type MultiSelectAutocompleteProps = {
  data: DropdownListDataType[];
  selectedValues: DropdownListDataType[];
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  selectable?: boolean;
  handleClick: (val: DropdownListDataType) => void;
  handleRemove: (val: DropdownListDataType) => void;
};

const CusMultiSelectAutocomplete: React.FC<MultiSelectAutocompleteProps> = ({
  className,
  data,
  placeholder,
  selectedValues,
  handleClick,
  handleRemove,
  selectable,
  error,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [filteredData, setFilteredData] = useState<DropdownListDataType[]>(data);
  const [showData, setShowData] = useState<boolean>(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    if (value) {
      const filtered = data.filter(
        suggestion =>
          String(suggestion.value).toLowerCase().includes(value.toLowerCase()) && !selectedValues.includes(suggestion),
      );
      setFilteredData(filtered);
    }
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      setShowData(false);
      setFilteredData(data);
      setInputValue('');
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div className="regions">
      <div className={'input-region ' + className} ref={inputRef} onClick={() => setShowData(true)}>
        <input type="text" value={inputValue} onChange={handleChange} placeholder={placeholder} />
        <img src={ArrowIcon} width={24} height={24} />
      </div>
      <div className="dropdown-list">
        {!error &&
          selectedValues.map((value, index) => (
            <div key={index}>
              <span>
                <p
                  style={{
                    color: '#515151',
                    fontFamily: 'Pretendard',
                    fontSize: 13,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '135%',
                  }}
                >
                  {value.value}
                </p>
                <p
                  style={{
                    color: '#8E8E8E',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: 12,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '135%',
                  }}
                >
                  {value.description}
                </p>
              </span>
              <button
                onClick={() => {
                  handleRemove(value);
                  setFilteredData([...filteredData, { value: value.value }]);
                }}
              >
                <img src={CloseIcon} width={24} height={24} />
              </button>
            </div>
          ))}
        {error && (
          <span className="error-container">
            <img src={InputErrorIcon} width={16} height={16} />
            <p className="error-message">{error}</p>
          </span>
        )}
        {showData && (
          <ul className="no-scrollbar">
            {filteredData.map(data => (
              <li
                key={data.id}
                onClick={() => {
                  handleClick(data);
                  if (!selectedValues.includes(data)) {
                    setInputValue('');
                    setFilteredData(filteredData.filter(item => item.value !== data.value));
                  }
                }}
                style={{ display: 'flex', gap: 10 }}
              >
                <span>
                  <CheckboxAtom />
                </span>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span>{data.value}</span>
                  {data.description && <p className="custom-dropdown-item-description">{data.description}</p>}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CusMultiSelectAutocomplete;
