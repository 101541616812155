import { useMutation } from '@apollo/client';
import { IGqlResponseNewData } from '../../types';
import { AwsTLSInspectionConfigurationResponse, AwsTlsInspectionConfigurationRequestType } from 'graphql/types/AwsTlsInspectionConfiguration';
import query from './query';

export type AwsUpdateTLSInspectionConfigurationVariables = {
  cloudId: number;
  region: string;
  reqData: AwsTlsInspectionConfigurationRequestType;
};

export interface IUpdateMemberResponseData {
  updateAwsTLSInspectionConfiguration: IGqlResponseNewData<AwsTLSInspectionConfigurationResponse>;
}

const updateAwsTLSInspectionConfigurationMutation = () => useMutation<IUpdateMemberResponseData, AwsUpdateTLSInspectionConfigurationVariables>(query);
export default updateAwsTLSInspectionConfigurationMutation;
