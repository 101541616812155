import './styles.scss';
import React, { useMemo } from 'react';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { ReactComponent as Loader } from 'assets/svgs/ic_loader.svg';
import { DropdownListDataType } from 'components/Dropdown/types';

type ButtonPropsType = {
  id?: string;
  label: string;
  type?: string;
  onClick: () => void;
  disabled?: boolean;
  dropdownList?: Array<DropdownListDataType>;
  dropdownValue?: DropdownListDataType;
  onDropdownValueChanged?: (value: DropdownListDataType) => void;
  loading?: boolean;
};


export enum ButtonTypeEnum {
  PRIMARY = 'PRIMARY',
  GENERAL = 'GENERAL',
  WARNING = 'WARNING',
  DROPDOWN = 'DROPDOWN',
}

const Button = (props: ButtonPropsType) => {
  const { label, type, onClick, disabled, dropdownList, dropdownValue, onDropdownValueChanged, loading } = props;

  const className = useMemo(() => {
    switch (type) {
      case ButtonTypeEnum.WARNING:
        return 'warning-button';

      case ButtonTypeEnum.PRIMARY:
        return 'primary-button';

      case ButtonTypeEnum.GENERAL:
      default:
        return '';
    }
  }, [type]);

  if (type === ButtonTypeEnum.DROPDOWN && dropdownValue && onDropdownValueChanged && dropdownList) {
    return <DropdownAtom data={dropdownList} id="" value={dropdownValue} handleClick={onDropdownValueChanged} />;
  }

  return (
    <button disabled={disabled} className={`button-container ${className} ${disabled && 'disabled'}`} onClick={onClick}>
      {label}
      {loading &&  <div className="spinner-container">
          <Loader className="spinner" />
        </div>}
    </button>
  );
};

export default Button;
