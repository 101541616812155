import { ColumnType, RowType } from '@Types/v2/Table';
import IconStateful from 'assets/svgs/v3/ico_stateful_actions.svg';
import IconStateless from 'assets/svgs/v3/ico_stateless_actions.svg';
import Icon from 'components/v2/atoms/Icon';
import TableRadio from 'components/v2/dataDisplay/TableRadio';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { useState } from 'react';
import './index.scss';

interface IEditPriorityProps extends IBaseModalProps {
  header: string;
  isStateless?: boolean;
  rows: RowType[];
  columns: ColumnType[];
  note?: string;
  onEdit: (rows: RowType[]) => void;
}

const EditPriority = ({
  header,
  isStateless = false,
  rows,
  columns,
  note,
  onEdit,
  ...baseModalProps
}: IEditPriorityProps) => {

  const defaultSelectRow = (rows?.[0].id || '') as string;
  // deep clone rows
  const initialRows = JSON.parse(JSON.stringify(rows));
  const [handleRows, setHandleRows] = useState<RowType[]>(initialRows);
  const [selectedRow, setSelectedRow] = useState<string>(defaultSelectRow);

  const moveUp = () => {
    const selectRowIndex = handleRows.findIndex((row) => row.id === selectedRow);
    if (selectRowIndex === 0) return;

    const updatedRows = [...handleRows];
    // swap row & update priority
    const currentRow = updatedRows[selectRowIndex];
    const previousRow = updatedRows[selectRowIndex - 1]
    updatedRows[selectRowIndex] = {...previousRow, priority: currentRow.priority};
    updatedRows[selectRowIndex - 1] = {...currentRow, priority: previousRow.priority};
    setHandleRows(updatedRows);

  };

  const moveDown = () => {
    const selectRowIndex = handleRows.findIndex((row) => row.id === selectedRow);
    if (selectRowIndex === rows.length - 1) return;

    const updatedRows = [...handleRows];
    // swap row & update priority
    const currentRow = updatedRows[selectRowIndex];
    const nextRow = updatedRows[selectRowIndex + 1]
    updatedRows[selectRowIndex] = { ...nextRow, priority: currentRow.priority};
    updatedRows[selectRowIndex + 1] = {...currentRow,  priority: nextRow.priority};
    setHandleRows(updatedRows);
  };

  return (
    <BaseModal
      title={() => <><Icon width={32} height={32} src={isStateless ? IconStateless : IconStateful} />{header}</>}
      {...baseModalProps}>
      <div className="unmanaged-state-modal">
        <p className="note">{note}</p>
        <div className="unmanaged-state">

          <div className="row-1">
            <p className="text-title">{`${isStateless ? 'Stateless' : 'Stateful'}`} rule group</p>
            <div className="btn-group-edit">
              <button className="btn-up" onClick={moveUp}> Move Up</button>
              <button className="btn-down" onClick={moveDown}>Move Down</button>
            </div>
          </div>

          <TableRadio
            columns={columns}
            rows={handleRows}
            name="radio-priority"
            radioValue={selectedRow}
            onRowSelected={(id) => setSelectedRow(id)}
          />
        </div>

        <div className={"button-group"}>
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="btn-save" onClick={() => onEdit(handleRows)}>Save</button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditPriority;