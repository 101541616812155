import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { AwsPutKeyPolicyRequestType } from 'graphql/types/AwsPutKeyPolicy';

export type AwsPutKeyPolicyVariablesType = {
  cloudId: number;
  region: string;
  reqData: AwsPutKeyPolicyRequestType;
};

export type AwsPutKeyPolicyResponseDataType = {
  putAwsKeyPolicy: IGqlResponseData<{}>;
};

const putKeyPolicyMutation = () =>
  useMutation<AwsPutKeyPolicyResponseDataType, AwsPutKeyPolicyVariablesType>(query);
export default putKeyPolicyMutation;
