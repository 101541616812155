import 'index.scss';
import { useEffect, useState } from 'react';
import { AddTLSInspectionConfigurationStepPropsType } from '../types';
import { FIREWALL_POLICY_CREATION } from '../configs';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import lazyGetAwsListTlsInspectionConfigurationsPage from 'graphql/queries/getAwsAllListTLSInspectionConfigurations';
import { DropdownListDataType } from 'components/Dropdown/types';
import { randomString } from 'utils/Common';
import Button from 'pages/v2/Organ/Management/components/Button';

const AddTLSInspectionConfigurationStep = (props: AddTLSInspectionConfigurationStepPropsType) => {
  const { cloudId, region, onValueChanged, tlsInspectionConfiguration } = props;

  const [getAwsAllListTLSInspectionConfigurations] = lazyGetAwsListTlsInspectionConfigurationsPage();

  const [listTLSInspectionConfigurations, setListTLSInspectionConfigurations] = useState<DropdownListDataType[]>([]);

  const handleGetAwsAllListTLSInspectionConfigurations = async () => {
    const variables = {
      cloudId,
      region,
      request: {
        maxResults: 100,
      },
    };

    const listTLS = await getAwsAllListTLSInspectionConfigurations({ variables }).then(({ data: listTLS }) => {
      return listTLS;
    });

    const awsAllListTLSInspectionConfigurations = listTLS?.getAwsAllListTLSInspectionConfigurations;

    if (!awsAllListTLSInspectionConfigurations?.data?.length) return;

    const listTLSInspectionConfigurations = awsAllListTLSInspectionConfigurations.data.map(item => ({
      name: item.name,
      value: item.arn,
    }));

    setListTLSInspectionConfigurations(listTLSInspectionConfigurations);
  };

  useEffect(() => {
    handleGetAwsAllListTLSInspectionConfigurations();
  }, []);

  return (
    <div>
      <div className="rule-group-container">
        <div className="title-group">
          <p className="title">{FIREWALL_POLICY_CREATION.ADD_TLS_INSPECTION_CONFIGURATION_TITLE}</p>
          <p className="sub-title">{FIREWALL_POLICY_CREATION.ADD_TLS_INSPECTION_CONFIGURATION_DESCRIPTION}</p>
        </div>

        <div className="rule-group-details-container">
          <div
            className="rule-group-detail-title"
            style={{ gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            {FIREWALL_POLICY_CREATION.TLS_INSPECTION_CONFIGURATIONS_TITLE}
            <span className="rule-group-detail-sub-title">
              {FIREWALL_POLICY_CREATION.TLS_INSPECTION_CONFIGURATIONS_DESCRIPTION}
            </span>
          </div>

          <div
            className="rule-group-detail-title"
            style={{ gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <span className="rule-group-detail-sub-title">
              {FIREWALL_POLICY_CREATION.TLS_INSPECTION_CONFIGURATIONS_DROPDOWN_LABEL}
            </span>

            <div style={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 10 }}>
              <DropdownAtom
                id={randomString()}
                placeholder={FIREWALL_POLICY_CREATION.TLS_INSPECTION_CONFIGURATIONS_DROPDOWN_PLACEHOLDER}
                data={listTLSInspectionConfigurations}
                value={tlsInspectionConfiguration || { name: '', value: '' }}
                handleClick={val => onValueChanged('tlsInspectionConfiguration', val)}
              />

              <Button
                label={FIREWALL_POLICY_CREATION.TLS_INSPECTION_CONFIGURATIONS_REMOVE_BUTTON}
                disabled={!tlsInspectionConfiguration?.value}
                onClick={() => onValueChanged('tlsInspectionConfiguration', null)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTLSInspectionConfigurationStep;
