import { useState, useMemo, useCallback } from 'react';
import './index.scss';
import DescribeRuleGroup from './steps/DescribeRuleGroup';
import AddRulesAndSetCapacity from './steps/AddRulesAndSetCapacity';
import SetRulePriority from './steps/SetRulePriority';
import Review from './steps/Review';
import { CREATE_RULE_GROUP_STEPS } from './configs';
import Button, { ButtonTypeEnum } from '../../WebACL/CreateWebACL/components/Button';
import { PageTitle } from '../../WebACL/CreateWebACL/components';
import _ from 'lodash';
import { nameRegexUtils } from 'utils/Regex';
import createRuleGroupMutation from 'graphql/mutations/createRuleGroup';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';

type CreateRuleGroupPropsType = {
  onBackButtonClicked: () => void;
  cloudId: number;
  region: string;
};

const CreateRuleGroup = (props: CreateRuleGroupPropsType) => {
  const { onBackButtonClicked, cloudId, region } = props;

  const [createRuleGroup, { loading: createRuleGroupLoading }] = createRuleGroupMutation();

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [creationData, setCreationData] = useState<any>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const onPreviousButtonClicked = useCallback(() => {
    setCurrentStepIndex(currentStepIndex - 1);
  }, [currentStepIndex]);

  const handleSetCreationData = useCallback(
    (key: string, value: any) => {
      setCreationData((prev: any) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    },
    [creationData],
  );

  const handleCreateRuleGroup = useCallback(async () => {
    const requestData = {
      name: creationData?.name,
      description: creationData?.description,
      capacity: creationData?.capacity,
      visibilityConfig: {
        sampledRequestsEnabled: false,
        cloudWatchMetricsEnabled: true,
        metricName: creationData?.cloudWatchMetricName,
      },
      rules: creationData?.rules?.map((rule: any) => _.omit(rule, 'capacity', 'id')),
    };
    const variables = {
      cloudId,
      region: region !== 'CloudFront' ? String(region) : 'us-east-1',
      request: {
        scope: region !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
        ..._.omitBy(requestData, _.isEmpty || _.isUndefined),
      },
    };

    await createRuleGroup({ variables })
      .then(res => {
        if (res?.data?.createAwsRuleGroup?.result === ErrorCode.SUCCESS) {
          useToast(ErrorCode.SUCCESS, `Rule Group ${creationData?.name} is created successfully`);
          onBackButtonClicked();
        } else {
          useToast(ErrorCode.UNKNOWN, res?.errors?.[0]?.message ?? `Failed to create rule Group ${creationData?.name}`);
        }
      })
      .catch(e => {
        useToast(ErrorCode.UNKNOWN, e?.message ?? `Failed to create rule Group ${creationData?.name}`);
      });
  }, [creationData, cloudId, region]);

  const stepNode = useMemo(() => {
    switch (currentStepIndex) {
      default:
      case 0: {
        return (
          <DescribeRuleGroup creationData={creationData} handleValueChanged={handleSetCreationData} errors={errors} />
        );
      }

      case 1: {
        return (
          <AddRulesAndSetCapacity
            cloudId={cloudId}
            region={region}
            creationData={creationData}
            handleValueChanged={handleSetCreationData}
            errors={errors}
          />
        );
      }

      case 2: {
        return <SetRulePriority creationData={creationData} handleValueChanged={handleSetCreationData} />;
      }

      case 3: {
        return (
          <Review
            creationData={creationData}
            onCancelButtonClicked={onBackButtonClicked}
            onPreviousButtonClicked={onPreviousButtonClicked}
            onCreateButtonClicked={handleCreateRuleGroup}
            submitLoading={createRuleGroupLoading}
          />
        );
      }
    }
  }, [currentStepIndex, creationData, errors, createRuleGroupLoading]);

  const handleSetError = useCallback(
    (key: string, errorMessage: string) => {
      setErrors(prevErrors => {
        const newErrors = {
          ...prevErrors,
          [key]: errorMessage,
        };

        if (errorMessage === '') {
          _.omit(newErrors, key);
        }

        return newErrors;
      });
    },
    [errors],
  );

  const validateInputs = useCallback(() => {
    let isInputsValid = true;
    if (currentStepIndex === 0) {
      if (!creationData?.name) {
        handleSetError('name', 'Name is a required field');
        isInputsValid = false;
      } else if (!nameRegexUtils.test(creationData?.name)) {
        handleSetError('name', 'Name is invalid');
        isInputsValid = false;
      } else {
        handleSetError('name', '');
      }

      if (!creationData?.cloudWatchMetricName) {
        handleSetError('cloudWatchMetricName', 'CloudWatch metric name is a required field');
        isInputsValid = false;
      } else if (!nameRegexUtils.test(creationData?.cloudWatchMetricName)) {
        handleSetError('cloudWatchMetricName', 'CloudWatch metric name is invalid');
        isInputsValid = false;
      } else {
        handleSetError('cloudWatchMetricName', '');
      }
    }

    if (currentStepIndex === 1) {
      if (!creationData?.capacity) {
        handleSetError('capacity', 'Capacity is a required field');
        isInputsValid = false;
      } else if ((creationData?.capacity ?? 0) < (creationData?.minimumCapacity ?? 1)) {
        handleSetError('capacity', `Capacity must be greater than or equal to ${creationData?.minimumCapacity ?? 1}`);
        isInputsValid = false;
      } else {
        handleSetError('capacity', '');
      }
    }

    return isInputsValid;
  }, [currentStepIndex, creationData, handleSetError]);

  const onNextButtonClicked = useCallback(() => {
    if (currentStepIndex < CREATE_RULE_GROUP_STEPS.length - 1 && validateInputs()) {
      setCurrentStepIndex(currentStepIndex + 1);
      return;
    }
  }, [currentStepIndex, creationData, validateInputs]);

  const onCancelButtonClicked = useCallback(() => {
    onBackButtonClicked();
  }, [currentStepIndex]);

  const buttonGroupNode = useMemo(() => {
    const isFirstItem = currentStepIndex === 0;

    if (currentStepIndex === 3) {
      return null;
    }

    return (
      <div className="button-group">
        <Button label="Cancel" onClick={onCancelButtonClicked} />
        {!isFirstItem && <Button label="Previous" onClick={onPreviousButtonClicked} />}
        <Button label="Next" type={ButtonTypeEnum.PRIMARY} onClick={onNextButtonClicked} />
      </div>
    );
  }, [currentStepIndex, creationData]);

  return (
    <div id="create-webacl-page-container">
      <PageTitle
        title={currentStepIndex === 3 ? 'Create rule group: Preview' : 'Create rule group'}
        hasBackButton
        onBackButtonClicked={onBackButtonClicked}
      />
      {stepNode}
      {buttonGroupNode}
    </div>
  );
};

export default CreateRuleGroup;
