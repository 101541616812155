import { useCallback, useEffect, useMemo, useState } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconAddManaged from 'assets/svgs/v3/ico_add_managed_statefull_rule_group.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import ToggleSwitchAtom from 'components/v2/atoms/ToggleSwitchAtom';
import lazyGetAwsAllListRuleGroupsFirewall from 'graphql/queries/getAwsAllListRuleGroupsFirewall';
import lazyGetAwsRuleGroupMetadata from 'graphql/queries/getAwsRuleGroupMetadata';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
interface IAddManagedStatefullRuleGroupProps extends IBaseModalProps {
  region: string;
  cloudId: number;
  dataRule: any;
  open: boolean;
  creationApiLoading: boolean;
  handleUpdateManagedStatefuRule: Function;
  onChangeValue?: () => void;
  onClose: () => void;
}

const AddManagedStatefullRuleGroupModal = ({
  region,
  cloudId,
  open,
  dataRule,
  creationApiLoading,
  onChangeValue,
  handleUpdateManagedStatefuRule,
  onClose,
  ...baseModalProps
}: IAddManagedStatefullRuleGroupProps) => {
  const [checkedDomainIpList, setCheckedDomainIpList] = useState<any[]>([]);
  const [checkedThreatSignatureList, setCheckedThreatSignatureList] = useState<string[]>([]);
  const [nextToken, setNextToken] = useState<string>('');

  const [getAwsAllListRuleGroupsFirewall, { loading: getAwsAllListRuleGroupsFirewallLoading }] =
    lazyGetAwsAllListRuleGroupsFirewall();
  const [getAwsRuleGroupMetadata] = lazyGetAwsRuleGroupMetadata();
  const [domainTblRows, setDomainTblRows] = useState<any[]>([]);
  const [domainTblTotal, setDomainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [domainTablePagination, setDomainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [threatTblRows, setThreatTblRows] = useState<any[]>([]);
  const [threatTblTotal, setThreatTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [threatTablePagination, setThreatTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const domainIpColumns: ColumnType[] = [
    {
      label: 'Name',
      field: 'name',
      width: 650,
      renderCell: (row: RowType) => {
        return (
          <>
            <p className="content">{row.name}</p>
            <p className="description">{row.description}</p>
          </>
        );
      },
    },
    {
      label: 'Capacity',
      field: 'capacity',
    },
    {
      label: 'Alert mode',
      field: 'alertMode',
      renderCell: (row: RowType) => (
        <>
          <div className="alert-mode">
            <ToggleSwitchAtom
              disabled={!checkedDomainIpList.find(item => item === row.id)}
              value={row.alertMode}
              onChange={checked => {
                const newRows = domainTblRows?.map(r => (r.id === row.id ? { ...r, alertMode: checked } : r));
                setDomainTblRows(newRows);
              }}
            />
            {row.alertMode ? <span className="enabled">Enabled</span> : <span className="disabled">Disabled</span>}
          </div>
        </>
      ),
    },
  ];

  const threatSignatureColumns: ColumnType[] = [
    {
      label: 'Name',
      field: 'name',
      width: 650,
      renderCell: (row: RowType) => {
        return (
          <>
            <p className="content">{row.name}</p>
            <p className="description">{row.description}</p>
          </>
        );
      },
    },
    {
      label: 'Capacity',
      field: 'capacity',
    },
    {
      label: 'Alert mode',
      field: 'alertMode',
      renderCell: (row: RowType) => (
        <>
          <div className="alert-mode">
            <ToggleSwitchAtom
              value={row.alertMode}
              disabled={!checkedThreatSignatureList.find(item => item === row.id)}
              onChange={checked => {
                const newRows = threatTblRows?.map(r => (r.id === row.id ? { ...r, alertMode: checked } : r));
                setThreatTblRows(newRows);
              }}
            />
            {row.alertMode ? <span className="enabled">Enabled</span> : <span className="disabled">Disabled</span>}
          </div>
        </>
      ),
    },
  ];

  const getVariableData = useCallback(
    () => {
      return {
        cloudId: cloudId,
        region: region,
        request: {
          scope: 'MANAGED',
          managedType: 'AWS_MANAGED_DOMAIN_LISTS',
          type: 'STATEFUL',
        },
      };
    },
    [cloudId, domainTablePagination, region],
  );

  const getVariableThreatData = useCallback(
    () => {
      return {
        cloudId: cloudId,
        region: region,
        request: {
          scope: 'MANAGED',
          managedType: 'AWS_MANAGED_THREAT_SIGNATURES',
          type: 'STATEFUL',
        },
      };
    },
    [cloudId, threatTablePagination, region],
  );

  const handleGetDomainDataRows = useCallback(
    async (nextToken?: string) => {
      if (!region) return;

      try {
        const { data: listRuleGroupsFirewall } = await getAwsAllListRuleGroupsFirewall({
          variables: variableCombineNextToken(getVariableData(), nextToken),
        });

        if (!listRuleGroupsFirewall?.getAwsAllListRuleGroupsFirewall?.data?.length) {
          return;
        }

        const fetchListAllRuleGroupsFirewall = listRuleGroupsFirewall.getAwsAllListRuleGroupsFirewall.data
          .filter(item => item.name.endsWith('StrictOrder'))
          .map(async ruleGroup => {
            const ruleGroupFirewallVariable = {
              cloudId,
              region,
              request: {
                ruleGroupArn: ruleGroup.arn,
              },
            };

            return await getAwsRuleGroupMetadata({
              variables: ruleGroupFirewallVariable,
            }).then(({ data: ruleGroupMetaData }) => {
              const ruleGroupMetadataResponse = ruleGroupMetaData?.getAwsRuleGroupMetadata?.data?.[0];
              if (ruleGroupMetadataResponse) {
                const { ruleGroupArn, ruleGroupName, description, capacity, statefulRuleOptions } =
                  ruleGroupMetadataResponse;

                return {
                  id: ruleGroupArn,
                  name: ruleGroupName,
                  description: description || '-',
                  capacity: capacity,
                  ruleOrder: statefulRuleOptions?.ruleOrder || '-',
                  alertMode: false,
                };
              }
            });
          });

        const listAllRuleGroupFirewallData = await Promise.all(fetchListAllRuleGroupsFirewall);
        const mainTblRowsSet = new Set(dataRule?.map((item: { resourceArn: string }) => item.resourceArn));
        const listAllRuleGroupFirewallDataFilter = listAllRuleGroupFirewallData.filter(
          item => !mainTblRowsSet?.has(item?.id),
        );

        setNextToken(listRuleGroupsFirewall?.getAwsAllListRuleGroupsFirewall?.nextToken as string);
        setDomainTblRows([...listAllRuleGroupFirewallDataFilter]);
        setDomainTblTotal({
          totalPage: Math.ceil(listAllRuleGroupFirewallDataFilter.length / domainTablePagination.itemPerPage),
          totalElement: listAllRuleGroupFirewallDataFilter.length,
        });
      } catch (error) {
        /* empty */
      }
    },
    [
      region,
      getAwsAllListRuleGroupsFirewall,
      getVariableData,
      domainTblRows,
      domainTablePagination,
      cloudId,
      getAwsRuleGroupMetadata,
    ],
  );

  const handleGetThreatDataRows = useCallback(
    async (nextToken?: string) => {
      if (!region) return;

      try {
        const { data: listRuleGroupsFirewall } = await getAwsAllListRuleGroupsFirewall({
          variables: variableCombineNextToken(getVariableThreatData(), nextToken),
        });

        if (!listRuleGroupsFirewall?.getAwsAllListRuleGroupsFirewall?.data?.length) {
          return;
        }
        const fetchListAllRuleGroupsFirewall = listRuleGroupsFirewall.getAwsAllListRuleGroupsFirewall?.data
          .filter(item => item.name.endsWith('StrictOrder'))
          .map(async ruleGroup => {
            const ruleGroupFirewallVariable = {
              cloudId,
              region,
              request: {
                ruleGroupArn: ruleGroup.arn,
              },
            };

            return await getAwsRuleGroupMetadata({
              variables: ruleGroupFirewallVariable,
            }).then(({ data: ruleGroupMetaData }) => {
              const ruleGroupMetadataResponse = ruleGroupMetaData?.getAwsRuleGroupMetadata?.data?.[0];
              if (ruleGroupMetadataResponse) {
                const { ruleGroupArn, ruleGroupName, description, capacity, statefulRuleOptions } =
                  ruleGroupMetadataResponse;
                
                return {
                  id: ruleGroupArn,
                  name: ruleGroupName,
                  description: description || '-',
                  capacity: capacity,
                  ruleOrder: statefulRuleOptions?.ruleOrder || '-',
                  alertMode: false,
                };
              }
            });
          });

        const listAllRuleGroupFirewallData = await Promise.all(fetchListAllRuleGroupsFirewall);
        const mainTblRowsSet = new Set(dataRule?.map((item: { resourceArn: string }) => item.resourceArn));
        
        const listAllRuleGroupFirewallDataFilter = listAllRuleGroupFirewallData.filter(
          item => !mainTblRowsSet?.has(item?.id),
        );

        setNextToken(listRuleGroupsFirewall?.getAwsAllListRuleGroupsFirewall?.nextToken as string);
        setThreatTblRows([...listAllRuleGroupFirewallDataFilter]);
        setThreatTblTotal({
          totalPage: Math.ceil(listAllRuleGroupFirewallDataFilter.length / threatTablePagination.itemPerPage),
          totalElement: listAllRuleGroupFirewallDataFilter.length,
        });
      } catch (error) {}
    },
    [
      region,
      getAwsAllListRuleGroupsFirewall,
      getVariableData,
      threatTblRows,
      domainTablePagination,
      cloudId,
      getAwsRuleGroupMetadata,
    ],
  );

  const updateDomainTablePagination = useCallback((key: string, value: any) => {
    setDomainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const domainRowsCurrentPage = useMemo(() => {
    if (domainTablePagination.currentPage > domainTblTotal.totalPage && !!nextToken) {
      handleGetDomainDataRows(nextToken);
    }
    const startIndex = (domainTablePagination.currentPage - 1) * domainTablePagination.itemPerPage;
    const endIndex = startIndex + domainTablePagination.itemPerPage;

    return orderAlphabetical(domainTblRows, domainTablePagination.target, domainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [domainTablePagination, domainTblTotal, nextToken, domainTblRows]);

  const updateThreatTablePagination = useCallback((key: string, value: any) => {
    setThreatTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const threatRowsCurrentPage = useMemo(() => {
    if (threatTablePagination.currentPage > threatTblTotal.totalPage && !!nextToken) {
      handleGetThreatDataRows(nextToken);
    }
    const startIndex = (threatTablePagination.currentPage - 1) * threatTablePagination.itemPerPage;
    const endIndex = startIndex + threatTablePagination.itemPerPage;

    return orderAlphabetical(threatTblRows, threatTablePagination.target, threatTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [threatTablePagination, threatTblTotal, nextToken, threatTblRows]);

  const onSavePolicy = async () => {
    const checkedDomainSignatureListSet = new Set(checkedDomainIpList);
    const listDomainReq = domainTblRows.filter(item => checkedDomainSignatureListSet?.has(item?.id));
    const checkedThreatSignatureListSet = new Set(checkedThreatSignatureList);
    const listThreatReq = threatTblRows.filter(item => checkedThreatSignatureListSet?.has(item?.id));
    const totalReq = [...listDomainReq, ...listThreatReq];
    const result = await handleUpdateManagedStatefuRule(totalReq);
    result ? onClose() : null;
  };

  useEffect(() => {
    handleGetDomainDataRows();
    handleGetThreatDataRows();
  }, [open]);

  return (
    <BaseModal
      open={open}
      title={() => (
        <>
          <div>
            <div className="title flex">
              <Icon width={32} height={32} src={IconAddManaged} /> <label>Add managed stateful rule groups</label>
            </div>
            <p>Select the managed stateful rule groups that you want to add to your policy.</p>
          </div>
        </>
      )}
      onClose={onClose}
      {...baseModalProps}
    >
      <div className="add-managed-modal-policy">
        <div className="domain-ip">
          <p className="title">Domain and IP rule groups</p>
          <Table
            columns={domainIpColumns}
            rows={domainRowsCurrentPage}
            useSelected={false}
            reportCheckedList={(list: string[]) => {
              setCheckedDomainIpList(list);
            }}
            isLoading={getAwsAllListRuleGroupsFirewallLoading}
            sortOption={{
              target: domainTablePagination.target,
              direction: domainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateDomainTablePagination('target', target);
                updateDomainTablePagination('direction', dir);
              },
            }}
            horizontalScrollable={true}
          />
          {domainRowsCurrentPage?.length && !getAwsAllListRuleGroupsFirewallLoading ? (
            <div className="fleet-instance pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{domainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={!!nextToken}
                currentPage={domainTablePagination.currentPage}
                updateCurrentPage={page =>
                  setDomainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={domainTblTotal.totalPage}
              />
            </div>
          ) : null}
        </div>
        <div className="thread-signature">
          <p className="title">Threat signature rule groups</p>
          <Table
            columns={threatSignatureColumns}
            rows={threatRowsCurrentPage}
            reportCheckedList={(list: string[]) => {
              setCheckedThreatSignatureList(list);
            }}
            useSelected={false}
            isLoading={getAwsAllListRuleGroupsFirewallLoading}
            sortOption={{
              target: threatTablePagination.target,
              direction: threatTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateThreatTablePagination('target', target);
                updateThreatTablePagination('direction', dir);
              },
            }}
            horizontalScrollable={true}
          />
          {threatRowsCurrentPage?.length && !getAwsAllListRuleGroupsFirewallLoading ? (
            <div className="fleet-instance pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{threatTblTotal.totalElement}</span>
              </p>

              <TableManagePagination
                ableFetchMore={false}
                currentPage={threatTablePagination.currentPage}
                updateCurrentPage={page => updateThreatTablePagination('currentPage', page)}
                totalPage={threatTblTotal.totalPage}
              />
            </div>
          ) : null}
        </div>
        <div className="button-group">
          <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={onClose} />
          <Button
            label="Add to policy"
            loading={creationApiLoading}
            type={ButtonTypeEnum.PRIMARY}
            onClick={onSavePolicy}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default AddManagedStatefullRuleGroupModal;
