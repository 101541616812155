import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsListKeyResponse } from 'graphql/types/AwsListKey';
import { IGqlResponseNewData } from 'graphql/types';

export type AwsListKeyVariablesType = {
  cloudId: number;
  region: string;
  request: {
    limit?: number;
    maker?: string;
  };
};

export type AwsListKeyResponseDataType = {
  getAwsListKeys: IGqlResponseNewData<AwsListKeyResponse>
};

const lazyGetAwsListKeys = () =>
  useLazyQuery<AwsListKeyResponseDataType, AwsListKeyVariablesType>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListKeys;
