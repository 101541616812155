import { DropdownListDataType } from "components/molecules/Dropdown/types";

export const IP_SET_DROPDOWN_DATA: DropdownListDataType[] = [
  {name: 'Custom', value: 'custom'},
  {name: 'Any IPv4 addresss', value: 'Any IPv4 addresss'},
];

export const PORT_RANGE_DROPDOWN_DATA: DropdownListDataType[] = [
  {name: 'Custom', value: 'custom'},
  {name: 'Any port', value: 'any port'},
];

export enum ENCRYPTION_CONFIGURATION_DATA {
  AWS_OWNED_KMS_KEY = 'AWS_OWNED_KMS_KEY',
  CUSTOMER_KMS = 'CUSTOMER_KMS'
};