import { gql } from '@apollo/client';

const query = gql`
  mutation scheduleAwsKeyDeletion($reqData: AwsScheduleKeyDeletionRequest, $cloudId: Long, $region: String) {
    scheduleAwsKeyDeletion(scheduleKeyDeletionRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsScheduleKeyDeletionResponse {
          deletionDate
          keyId
          keyState
          pendingWindowInDays
        }
      }
    }
  }
`;
export default query;
