import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import {
  AwsPutLoggingConfigurationRequestType,
  AwsPutLoggingConfigurationResponseType,
} from 'graphql/types/PutAwsLoggingConfiguration';

export type PutAwsLoggingConfigurationVariablesType = {
  cloudId: number;
  region: string;
  request: AwsPutLoggingConfigurationRequestType;
};

export type PutAwsLoggingConfigurationResponseDataType = {
  putAwsLoggingConfiguration: IGqlResponseData<AwsPutLoggingConfigurationResponseType>;
};

const putAwsLoggingConfigurationMutation = () =>
  useMutation<PutAwsLoggingConfigurationResponseDataType, PutAwsLoggingConfigurationVariablesType>(query);
export default putAwsLoggingConfigurationMutation;
