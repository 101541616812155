import { AwsStatementlogType } from 'graphql/types/AwsCheckCapacity';
import _ from 'lodash';
import {
  BODY_CONTENT_TO_INSPECT,
  CONTENT_TO_INSPECT,
  CONTENT_TYPE,
  HEADERS_MATCH_SCOPE,
  IP_ADDRESS_SELECTOR,
  JA3_FINGERPRINT,
  JSON_MATCH_SCOPE,
  JSON_REQUEST,
  MATCH_SCOPE,
  MATCH_TYPE_DATA,
  MISSING_IP_ADDRESS,
  OVERSIZE_HANDLING_DATA,
  POSITION_INSIDE_HEADER,
  RULE_BUILDER_STATEMENT_INSPECT_DATA,
  TEXT_TRANSFORMATION_DATA,
} from 'pages/v2/Organ/Management/WAF/CustomRuleModal/constant';

import { COUNTRY_CODES_DATA } from 'pages/v2/Organ/Management/WAF/CustomRuleModal/countryCodes';

export const getStatementObjectFromRule = (statement: AwsStatementlogType) => {
  // Inspect
  let inspect = {
    name: '',
    value: '',
  };
  let countryCode: Array<{ id: number; name: string; value: string }> = [];
  let ipAddress = IP_ADDRESS_SELECTOR[0].value;
  let ipSet = {};
  let headerFieldName = 'X-Forwarded-For';
  let positionInsideHeader = POSITION_INSIDE_HEADER[0].value;
  let missingIpAddress = MISSING_IP_ADDRESS[0].value;
  let matchScope = MATCH_SCOPE[0].value;
  let matchKey = '';
  let matchType: { id: number; value: string; name: string } = MATCH_TYPE_DATA[0];
  let stringToMatch = '';
  let transformation = [
    {
      id: _.uniqueId('transformation'),
      value: {
        id: 0,
        name: '',
        value: '',
      },
    },
  ];
  let oversizeHandling = OVERSIZE_HANDLING_DATA[0];
  let headerMatchScope = HEADERS_MATCH_SCOPE[0].value;
  let contentToInspect = '';
  let keys = '';
  let queryArgument = '';
  let contentType = CONTENT_TYPE[0].value;
  let ja3Fingerprint = JA3_FINGERPRINT[0].value;
  let jsonMatchScope = JSON_MATCH_SCOPE[0].value;
  let jsonRequest = JSON_REQUEST[0].value;
  let bodyContentToInspect = BODY_CONTENT_TO_INSPECT[0].value;
  let includedElements = '';
  const DEFAULT_TRANSFORMATION_VALUE = {
    id: 0,
    name: '',
    value: '',
  };

  if (statement?.geoMatchStatement) {
    inspect = RULE_BUILDER_STATEMENT_INSPECT_DATA[0];
    countryCode =
      statement?.geoMatchStatement?.countryCodes?.map(code => {
        return COUNTRY_CODES_DATA.find(item => item.value === code)!;
      }) ?? [];

    if (statement?.geoMatchStatement?.forwardedIPConfig) {
      ipAddress = IP_ADDRESS_SELECTOR[1].value;
      headerFieldName = statement?.geoMatchStatement?.forwardedIPConfig?.headerName ?? '';
      missingIpAddress =
        MISSING_IP_ADDRESS.find(
          item => item.value === statement?.geoMatchStatement?.forwardedIPConfig?.fallbackBehavior,
        )?.value ?? '';
    }
  }

  if (statement?.ipSetReferenceStatement) {
    inspect = RULE_BUILDER_STATEMENT_INSPECT_DATA[1];
    ipSet = { value: statement?.ipSetReferenceStatement?.arn };
    ipAddress = IP_ADDRESS_SELECTOR[1].value;

    if (statement?.ipSetReferenceStatement?.ipSetForwardedIPConfig) {
      headerFieldName = statement?.ipSetReferenceStatement?.ipSetForwardedIPConfig?.headerName ?? '';
      positionInsideHeader =
        POSITION_INSIDE_HEADER.find(
          position => position.value === statement?.ipSetReferenceStatement?.ipSetForwardedIPConfig?.position,
        )?.value ?? POSITION_INSIDE_HEADER[0].value;
      missingIpAddress =
        MISSING_IP_ADDRESS.find(
          item => item.value === statement?.ipSetReferenceStatement?.ipSetForwardedIPConfig?.fallbackBehavior,
        )?.value ?? '';
    }
  }

  if (statement?.labelMatchStatement) {
    inspect = RULE_BUILDER_STATEMENT_INSPECT_DATA[2];
    matchScope =
      MATCH_SCOPE.find(scope => statement?.labelMatchStatement?.scope === scope.value)?.value ?? MATCH_SCOPE[0].value;
    matchKey = statement?.labelMatchStatement?.key ?? '';
  }

  if (statement?.byteMatchStatement) {
    if (statement?.byteMatchStatement?.fieldToMatch?.singleHeader) {
      inspect = {
        value: 'SINGLE_HEADER',
        name: 'Single header',
      };
      headerFieldName = statement?.byteMatchStatement?.fieldToMatch?.singleHeader?.name ?? '';
      stringToMatch = statement?.byteMatchStatement?.searchString ?? '';
      matchType =
        MATCH_TYPE_DATA.find(type => type.value === statement?.byteMatchStatement?.positionalConstraint) ??
        MATCH_TYPE_DATA[0];
      transformation =
        statement?.byteMatchStatement?.textTransformations?.map(data => ({
          id: _.uniqueId('transformation'),
          value: TEXT_TRANSFORMATION_DATA.find(item => item.value === data.type)!,
        })) ?? [];
    }

    if (statement?.byteMatchStatement?.fieldToMatch?.headers) {
      inspect = {
        value: 'ALL_HEADER',
        name: 'All headers',
      };
      stringToMatch = statement?.byteMatchStatement?.searchString ?? '';
      matchType =
        MATCH_TYPE_DATA.find(type => type.value === statement?.byteMatchStatement?.positionalConstraint) ??
        MATCH_TYPE_DATA[0];
      transformation =
        statement?.byteMatchStatement?.textTransformations?.map(data => ({
          id: _.uniqueId('transformation'),
          value: TEXT_TRANSFORMATION_DATA.find(item => item.value === data.type) ?? DEFAULT_TRANSFORMATION_VALUE,
        })) ?? [];
      oversizeHandling =
        OVERSIZE_HANDLING_DATA.find(
          item => statement?.byteMatchStatement?.fieldToMatch?.headers?.oversizeHandling === item.value,
        ) ?? OVERSIZE_HANDLING_DATA[0];
      headerMatchScope =
        HEADERS_MATCH_SCOPE.find(
          item => statement?.byteMatchStatement?.fieldToMatch?.headers?.matchScope === item.value,
        )?.value ?? HEADERS_MATCH_SCOPE[0]?.value;

      if (statement?.byteMatchStatement?.fieldToMatch?.headers?.matchPattern?.all) {
        contentToInspect = CONTENT_TO_INSPECT[0].value;
      }

      if (statement?.byteMatchStatement?.fieldToMatch?.headers?.matchPattern?.includedHeaders) {
        contentToInspect = CONTENT_TO_INSPECT[1].value;
        keys = statement?.byteMatchStatement?.fieldToMatch?.headers?.matchPattern?.includedHeaders?.join('\n') ?? '';
      }

      if (statement?.byteMatchStatement?.fieldToMatch?.headers?.matchPattern?.excludedHeaders) {
        contentToInspect = CONTENT_TO_INSPECT[2].value;
        keys = statement?.byteMatchStatement?.fieldToMatch?.headers?.matchPattern?.excludedHeaders?.join('\n') ?? '';
      }
    }

    if (statement?.byteMatchStatement?.fieldToMatch?.cookies) {
      inspect = {
        value: 'COOKIES',
        name: 'Cookies',
      };
      stringToMatch = statement?.byteMatchStatement?.searchString ?? '';
      matchType =
        MATCH_TYPE_DATA.find(type => type.value === statement?.byteMatchStatement?.positionalConstraint) ??
        MATCH_TYPE_DATA[0];
      transformation =
        statement?.byteMatchStatement?.textTransformations?.map(data => ({
          id: _.uniqueId('transformation'),
          value: TEXT_TRANSFORMATION_DATA.find(item => item.value === data.type) ?? DEFAULT_TRANSFORMATION_VALUE,
        })) ?? [];
      oversizeHandling =
        OVERSIZE_HANDLING_DATA.find(
          item => statement?.byteMatchStatement?.fieldToMatch?.cookies?.oversizeHandling === item.value,
        ) ?? OVERSIZE_HANDLING_DATA[0];
      headerMatchScope =
        HEADERS_MATCH_SCOPE.find(
          item => statement?.byteMatchStatement?.fieldToMatch?.cookies?.matchScope === item.value,
        )?.value ?? HEADERS_MATCH_SCOPE[0]?.value;

      if (statement?.byteMatchStatement?.fieldToMatch?.cookies?.matchPattern?.all) {
        contentToInspect = CONTENT_TO_INSPECT[0].value;
      }

      if (statement?.byteMatchStatement?.fieldToMatch?.cookies?.matchPattern?.includedCookies) {
        contentToInspect = CONTENT_TO_INSPECT[1].value;
        keys = statement?.byteMatchStatement?.fieldToMatch?.cookies?.matchPattern?.includedCookies?.join('\n') ?? '';
      }

      if (statement?.byteMatchStatement?.fieldToMatch?.cookies?.matchPattern?.excludedCookies) {
        contentToInspect = CONTENT_TO_INSPECT[2].value;
        keys = statement?.byteMatchStatement?.fieldToMatch?.cookies?.matchPattern?.excludedCookies?.join('\n') ?? '';
      }
    }

    if (statement?.byteMatchStatement?.fieldToMatch?.singleQueryArgument) {
      inspect = {
        value: 'SINGLE_QUERY',
        name: 'Single query parameter',
      };
      queryArgument = statement?.byteMatchStatement?.fieldToMatch?.singleQueryArgument?.name ?? '';
      matchType =
        MATCH_TYPE_DATA.find(type => type.value === statement?.byteMatchStatement?.positionalConstraint) ??
        MATCH_TYPE_DATA[0];
      transformation =
        statement?.byteMatchStatement?.textTransformations?.map(data => ({
          id: _.uniqueId('transformation'),
          value: TEXT_TRANSFORMATION_DATA.find(item => item.value === data.type) ?? DEFAULT_TRANSFORMATION_VALUE,
        })) ?? [];
    }

    if (statement?.byteMatchStatement?.fieldToMatch?.allQueryArguments) {
      inspect = {
        value: 'ALL_QUERY',
        name: 'All query parameters',
      };
      queryArgument = '';
      matchType =
        MATCH_TYPE_DATA.find(type => type.value === statement?.byteMatchStatement?.positionalConstraint) ??
        MATCH_TYPE_DATA[0];
      transformation =
        statement?.byteMatchStatement?.textTransformations?.map(data => ({
          id: _.uniqueId('transformation'),
          value: TEXT_TRANSFORMATION_DATA.find(item => item.value === data.type) ?? DEFAULT_TRANSFORMATION_VALUE,
        })) ?? [];
    }

    if (statement?.byteMatchStatement?.fieldToMatch?.uriPath) {
      inspect = {
        value: 'URI_PATH',
        name: 'URI path',
      };
      matchType =
        MATCH_TYPE_DATA.find(type => type.value === statement?.byteMatchStatement?.positionalConstraint) ??
        MATCH_TYPE_DATA[0];
      transformation =
        statement?.byteMatchStatement?.textTransformations?.map(data => ({
          id: _.uniqueId('transformation'),
          value: TEXT_TRANSFORMATION_DATA.find(item => item.value === data.type) ?? DEFAULT_TRANSFORMATION_VALUE,
        })) ?? [];
    }

    if (statement?.byteMatchStatement?.fieldToMatch?.queryString) {
      inspect = {
        value: 'QUERY_STRING',
        name: 'Query string',
      };
      matchType =
        MATCH_TYPE_DATA.find(type => type.value === statement?.byteMatchStatement?.positionalConstraint) ??
        MATCH_TYPE_DATA[0];
      transformation =
        statement?.byteMatchStatement?.textTransformations?.map(data => ({
          id: _.uniqueId('transformation'),
          value: TEXT_TRANSFORMATION_DATA.find(item => item.value === data.type) ?? DEFAULT_TRANSFORMATION_VALUE,
        })) ?? [];
    }

    if (statement?.byteMatchStatement?.fieldToMatch?.body) {
      inspect = {
        value: 'BODY',
        name: 'Body',
      };
      matchType =
        MATCH_TYPE_DATA.find(type => type.value === statement?.byteMatchStatement?.positionalConstraint) ??
        MATCH_TYPE_DATA[0];
      transformation =
        statement?.byteMatchStatement?.textTransformations?.map(data => ({
          id: _.uniqueId('transformation'),
          value: TEXT_TRANSFORMATION_DATA.find(item => item.value === data.type) ?? DEFAULT_TRANSFORMATION_VALUE,
        })) ?? [];

      if (statement?.byteMatchStatement?.fieldToMatch?.body) {
        oversizeHandling =
          OVERSIZE_HANDLING_DATA.find(
            item => statement?.byteMatchStatement?.fieldToMatch?.body?.oversizeHandling === item.value,
          ) ?? OVERSIZE_HANDLING_DATA[0];
        contentType = 'plainText';
      }

      if (statement?.byteMatchStatement?.fieldToMatch?.jsonBody) {
        oversizeHandling =
          OVERSIZE_HANDLING_DATA.find(
            item => statement?.byteMatchStatement?.fieldToMatch?.jsonBody?.oversizeHandling === item.value,
          ) ?? OVERSIZE_HANDLING_DATA[0];
        contentType = 'json';
        jsonMatchScope = statement?.byteMatchStatement?.fieldToMatch?.jsonBody?.matchScope ?? JSON_MATCH_SCOPE[0].value;
        jsonRequest =
          statement?.byteMatchStatement?.fieldToMatch?.jsonBody?.invalidFallbackBehavior ?? JSON_REQUEST[0].value;

        if (statement?.byteMatchStatement?.fieldToMatch?.jsonBody?.matchPattern?.all) {
          bodyContentToInspect = BODY_CONTENT_TO_INSPECT[0].value;
        }

        if (statement?.byteMatchStatement?.fieldToMatch?.jsonBody?.matchPattern?.includedPaths) {
          bodyContentToInspect = BODY_CONTENT_TO_INSPECT[1].value;
          includedElements =
            statement?.byteMatchStatement?.fieldToMatch?.jsonBody?.matchPattern?.includedPaths?.join('\n');
        }
      }
    }

    if (statement?.byteMatchStatement?.fieldToMatch?.method) {
      inspect = {
        value: 'HTTP_METHOD',
        name: 'HTTP method',
      };
      matchType =
        MATCH_TYPE_DATA.find(type => type.value === statement?.byteMatchStatement?.positionalConstraint) ??
        MATCH_TYPE_DATA[0];
      transformation =
        statement?.byteMatchStatement?.textTransformations?.map(data => ({
          id: _.uniqueId('transformation'),
          value: TEXT_TRANSFORMATION_DATA.find(item => item.value === data.type) ?? DEFAULT_TRANSFORMATION_VALUE,
        })) ?? [];
    }

    if (statement?.byteMatchStatement?.fieldToMatch?.ja3Fingerprint) {
      inspect = {
        value: 'JA3_FIGERPRINT',
        name: 'JA3 fingerprint',
      };
      transformation =
        statement?.byteMatchStatement?.textTransformations?.map(data => ({
          id: _.uniqueId('transformation'),
          value: TEXT_TRANSFORMATION_DATA.find(item => item.value === data.type) ?? DEFAULT_TRANSFORMATION_VALUE,
        })) ?? [];
      ja3Fingerprint =
        JA3_FINGERPRINT.find(
          item => item.value === statement?.byteMatchStatement?.fieldToMatch?.ja3Fingerprint?.fallbackBehavior,
        )?.value ?? JA3_FINGERPRINT[0].value;
      stringToMatch = statement?.byteMatchStatement?.searchString ?? '';
    }

    if (statement?.byteMatchStatement?.fieldToMatch?.headerOrder) {
      inspect = {
        value: 'HEADER_ORDER',
        name: 'Header order',
      };
      matchType =
        MATCH_TYPE_DATA.find(type => type.value === statement?.byteMatchStatement?.positionalConstraint) ??
        MATCH_TYPE_DATA[0];
      transformation =
        statement?.byteMatchStatement?.textTransformations?.map(data => ({
          id: _.uniqueId('transformation'),
          value: TEXT_TRANSFORMATION_DATA.find(item => item.value === data.type) ?? DEFAULT_TRANSFORMATION_VALUE,
        })) ?? [];
      stringToMatch = statement?.byteMatchStatement?.searchString ?? '';
      oversizeHandling =
        OVERSIZE_HANDLING_DATA.find(
          item => item.value === statement?.byteMatchStatement?.fieldToMatch?.headerOrder?.oversizeHandling,
        ) ?? OVERSIZE_HANDLING_DATA[0];
    }
  }

  return {
    id: _.uniqueId('statement'),
    statementResults: false,
    inspect,
    countryCode,
    ipAddress,
    ipSet,
    headerFieldName,
    positionInsideHeader,
    missingIpAddress,
    matchScope,
    matchKey,
    matchType,
    size: '',
    transformation,
    headerMatchScope,
    contentToInspect,
    keys,
    oversizeHandling,
    queryArgument,
    contentType,
    jsonMatchScope,
    jsonRequest,
    bodyContentToInspect,
    includedElements,
    stringToMatch,
    ja3Fingerprint,
  };
};
