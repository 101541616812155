import { ErrorCode } from '@Types/error';
import CopyableText from 'components/v3/CopyableText';
import { useToast } from 'hooks/v2/useToast';

const Rules = ({ data, isLoading }: any) => {
  const { ruleGroup } = data || {};
  const { rulesSource } = ruleGroup || {};
  const { rulesString } = rulesSource || {};

  const copyToClipBoard = () => {
    if (!rulesString) return;

    navigator.clipboard
      .writeText(rulesString)
      .then(() => {
        useToast(ErrorCode.SUCCESS, 'Copied.');
      })
      .catch(() => {
        useToast(ErrorCode.SUCCESS, 'Copy to clipboard failed.');
      });
  };

  return (
    <div className="detail-info rules-detail">
      <div className="detail-info-title">
        <div>
          <p>Rules</p>

          <p>
            Suricata campatible APS rules. You can copy these rules and use them as the basis for your own rule group.
          </p>
        </div>
      </div>

      {isLoading ? (
        <div className="detail-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <div className="content-container">
          <div className="content-item-container">{rulesString ? <CopyableText text={rulesString} /> : '-'}</div>

          <button className="button" disabled={!rulesString} onClick={copyToClipBoard}>
            <p>Copy rules</p>
          </button>
        </div>
      )}
    </div>
  );
};

export default Rules;
