import { useState, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconAddAlias from 'assets/svgs/v3/ico_add_new_rule.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import CopyableText from 'components/v3/CopyableText';
import _ from 'lodash';

type CreateAliasesModalPropsType = {
  onSave?: (alias: string) => void;
  arn?: string;
  alias?: string;
} & IBaseModalProps;

const CreateAliasesModal = (props: CreateAliasesModalPropsType) => {
  const { arn, onSave, ...baseModalProps } = props;
  const [alias, setAlias] = useState<string>('');
  const [error, setError] = useState('');

  const handleSubmit = useCallback(() => {
    if (!_.startsWith(alias, "alias/")) {
      setError("Alias must start with the prefix \"alias/\"")
      return;
    }

    onSave?.(alias)
  }, [alias]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconAddAlias} />
          {'Create alias'}
        </>
      )}
      {...baseModalProps}
    >
      <div className="modal-content-container">
        <div className="input">
          <p>Target key ARN</p>
          <p>
            <span>
              <CopyableText text={arn ?? ''} key={`copy-${arn}`} />
            </span>
          </p>
        </div>
        <div className="input">
          <p>Alias</p>
          <InputAtom type={'text'} value={alias} noClear={true} onChangeValue={setAlias} error={error} />
        </div>

        <div className="button-group">
          <Button type={ButtonTypeEnum.GENERAL} onClick={() => baseModalProps?.onClose?.()} label="Cancel" />
          <Button type={ButtonTypeEnum.PRIMARY} onClick={handleSubmit} label="Create alias" disabled={!alias} />
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateAliasesModal;
