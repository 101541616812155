import StepContainer from 'pages/v2/Organ/Management/WAF/WebACL/CreateWebACL/components/StepContainer';
import { ReviewPropsType } from '../types';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import { useCallback, useEffect, useMemo } from 'react';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdTabLayout/types';
import { TextTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import { KEY_REGIONALITY_DATA, KEY_TYPE_DATA } from '../steps/configs';
import TagTab from 'pages/v2/Organ/Management/components/TagTab';
import { ColumnType, RowType } from '@Types/v2/Table';
import JSONViewer from 'components/JSONViewer';
import SectionTitle from 'pages/v2/Organ/Management/components/SectionTitle';
import SectionContainer from 'pages/v2/Organ/Management/components/SectionContainer';
import './index.scss';
import { CreationDataPropsType } from '../steps/types';

const Review = (props: ReviewPropsType) => {
  const {
    adminAccountId,
    creationData,
    onCancelButtonClicked,
    onPreviousButtonClicked,
    onCreateButtonClicked,
    submitLoading,
    updateCreationData,
  } = props;

  const keyConfigurationData = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      {
        id: 'key-type',
        type: TextTypeEnum.NORMAL,
        title: 'Key type',
        description: KEY_TYPE_DATA.find(item => item.id === creationData.keyType)?.label,
      },
      { id: 'key-spec', type: TextTypeEnum.NORMAL, title: 'Key spec', description: creationData.keySpec },
      { id: 'key-usage', type: TextTypeEnum.NORMAL, title: 'Key usage', description: creationData.keyUsage },
      { id: 'origin', type: TextTypeEnum.NORMAL, title: 'Orgin', description: creationData.origin },
      {
        id: 'regionality',
        type: TextTypeEnum.NORMAL,
        title: 'Regionality',
        description: KEY_REGIONALITY_DATA.find(item => item.id === creationData.multiRegion.toString())?.label,
      },
    ];
  }, [creationData]);

  const aliasAndDescroptionData = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      { id: 'alias', type: TextTypeEnum.NORMAL, title: 'Alias', description: creationData.aliasName },
      { id: 'key-spec', type: TextTypeEnum.NORMAL, title: 'Key spec', description: creationData.description },
    ];
  }, [creationData]);

  const tagRows = useMemo((): RowType[] => {
    return creationData?.tags ?? [];
  }, [creationData]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'keyStr', sort: true },
      { label: 'Value', field: 'valueStr', sort: true },
    ];
  }, []);

  const keyAdministratorPermissionPolicy = useMemo((): string => {
    const { administratorDeleteAgreement, keyAdministratorSelected } = creationData;

    if (keyAdministratorSelected && keyAdministratorSelected.length > 0) {
      const rowDataLength = keyAdministratorSelected.length;

      let principalUserContent = '';
      const arrayPrefixCharacter = rowDataLength === 1 ? '' : '[';
      const arraySubfixCharacter = rowDataLength === 1 ? '' : ']';
      keyAdministratorSelected.map((rowData, index) => {
        principalUserContent += `"arn:aws:iam::${adminAccountId}:user/${rowData?.name}"${
          index + 1 === rowDataLength ? '' : ','
        }`;
      });

      let addtionAction = '';
      if (administratorDeleteAgreement) {
        addtionAction = `
        "kms:ScheduleKeyDeletion",
        "kms:CancelKeyDeletion",`;
      }

      return `,{
        "Sid": "Allow access for Key Administrators",
        "Effect": "Allow",
        "Principal": {
          "AWS": ${arrayPrefixCharacter}${principalUserContent}${arraySubfixCharacter}
        },
        "Action": [
            "kms:Create*",
            "kms:Describe*",
            "kms:Enable*",
            "kms:List*",
            "kms:Put*",
            "kms:Update*",
            "kms:Revoke*",
            "kms:Disable*",
            "kms:Get*",
            "kms:Delete*",
            "kms:TagResource",
            "kms:UntagResource",
            ${addtionAction}
            "kms:ReplicateKey",
            "kms:UpdatePrimaryRegion"
        ],
        "Resource": "*"
      }`;
    }

    return '';
  }, [creationData, adminAccountId]);

  const keyUsagePermissionPolicy = useMemo((): string => {
    const { keyUsageSelected, otherAwsAccounts } = creationData;

    if ((keyUsageSelected && keyUsageSelected.length > 0) || (otherAwsAccounts && otherAwsAccounts.length > 0)) {
      let principalUserContent = '';
      let principalOtherAccountContent = '';
      let finalPricipal = '';
      let rowDataLength = 0;

      if (keyUsageSelected && keyUsageSelected.length > 0) {
        const keyUsageLength = keyUsageSelected.length;
        rowDataLength = keyUsageLength;

        keyUsageSelected.map((rowData, index) => {
          principalUserContent += `"arn:aws:iam::${adminAccountId}:user/${rowData?.name}"${
            index + 1 === keyUsageLength ? '' : ','
          }`;
        });
      }

      if (otherAwsAccounts && otherAwsAccounts.length > 0) {
        const otherAccountLength = otherAwsAccounts.length;
        rowDataLength += otherAccountLength;
        otherAwsAccounts.map((accountId, index) => {
          principalOtherAccountContent += `"arn:aws:iam::${accountId.name}:user/root"${
            index + 1 === otherAccountLength ? '' : ','
          }`;
        });
      }

      const arrayPrefixCharacter = rowDataLength === 1 ? '' : '[';
      const arraySubfixCharacter = rowDataLength === 1 ? '' : ']';

      // generate final principal
      finalPricipal = `${principalUserContent} ${
        principalOtherAccountContent === '' ? '' : ','
      } ${principalOtherAccountContent}`;

      return `
        ,
        {
            "Sid": "Allow use of the key",
            "Effect": "Allow",
            "Principal": {
              "AWS": ${arrayPrefixCharacter}${finalPricipal}${arraySubfixCharacter}
            },
            "Action": [
                "kms:DescribeKey",
                "kms:VerifyMac",
                "kms:GenerateMac"
            ],
            "Resource": "*"
        },
        {
            "Sid": "Allow attachment of persistent resources",
            "Effect": "Allow",
            "Principal": {
              "AWS": ${arrayPrefixCharacter}${principalUserContent}${arraySubfixCharacter}
            },
            "Action": [
                "kms:CreateGrant",
                "kms:ListGrants",
                "kms:RevokeGrant"
            ],
            "Resource": "*",
            "Condition": {
                "Bool": {
                    "kms:GrantIsForAWSResource": "true"
                }
            }
        }
      `;
    }
    return '';
  }, [creationData, adminAccountId]);

  const policyJson = useMemo((): JSON => {
    const policy = `
    {
      "Id": "key-consolepolicy-3",
      "Version": "2012-10-17",
      "Statement": [
          {
              "Sid": "Enable IAM User Permissions",
              "Effect": "Allow",
              "Principal": {
                  "AWS": "arn:aws:iam::${adminAccountId}:root"
              },
              "Action": "kms:*",
              "Resource": "*"
          }
          ${keyAdministratorPermissionPolicy}
          ${keyUsagePermissionPolicy}
      ]
    }
    `;
    return JSON.parse(policy);
  }, [keyAdministratorPermissionPolicy, adminAccountId]);

  const onPolicyUpdate = useCallback(() => {
    const updatedCreationData: CreationDataPropsType = { ...creationData, policy: JSON.stringify(policyJson, null, 4).replace(/\n/g, '\r\n') };
    updateCreationData(updatedCreationData);
  }, [policyJson]);

  useEffect(() => {
    onPolicyUpdate();
  }, []);

  return (
    <StepContainer>
      <DetailTab title={'Key configuration'} data={keyConfigurationData} />

      <DetailTab title={'Alias and description'} data={aliasAndDescroptionData} />

      <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} />

      <SectionContainer>
        <SectionTitle
          title="Policy"
          description="To change this policy, return to previous steps or edit the text here."
        />

        <JSONViewer data={policyJson} />
      </SectionContainer>

      <div className="button-group">
        <Button label="Cancel" onClick={onCancelButtonClicked} />
        <Button label="Previous" onClick={onPreviousButtonClicked} />
        <Button
          label="Create item"
          type={ButtonTypeEnum.PRIMARY}
          onClick={onCreateButtonClicked}
          loading={submitLoading}
        />
      </div>
    </StepContainer>
  );
};

export default Review;
