import { useLazyQuery } from '@apollo/client';
import query from './query';
import {
  AwsDescribeDbClustersRequestType,
  AwsDescribeDbClustersResponseType,
} from 'graphql/types/AwsDescribeDbClusters';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsDescribeDbClustersVariable {
  cloudId: number;
  region: string;
  reqData: AwsDescribeDbClustersRequestType;
}

export interface IAwsDescribeDbClustersResponseData {
  getAwsDescribeDbClusters: IGqlResponseNewData<AwsDescribeDbClustersResponseType>;
}

const lazyGetAwsDescribeDbClusters = () =>
  useLazyQuery<IAwsDescribeDbClustersResponseData, IAwsDescribeDbClustersVariable>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsDescribeDbClusters;
