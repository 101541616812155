import './index.scss';
import { KeyPairtType } from '../../type';
import { Key } from 'react';
import SectionContainer from 'pages/v2/Organ/Management/components/SectionContainer';
import TagTableCustom from '../TagTableCustom';

const KeyValuePairPanel = (props: any) => {
  const { createPairs, addPair, handleValueChanged, columns, handleRemovePair, errors } = props;

  return (
    <SectionContainer title="Key/value pairs">
      <TagTableCustom
        optionHide={false}
        tags={createPairs}
        errors={errors}
        addPair={addPair}
        handleValueChanged={handleValueChanged}
        handleCurrentKeyDelete={handleRemovePair}
      />
    </SectionContainer>
  );
};
export default KeyValuePairPanel;
