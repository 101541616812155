import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import lazyGetAwsManagedPrefixLists from 'graphql/queries/getAwsManagedPrefixLists';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ICreationDataPropsType } from '../steps/type';
import { IIpSetReferenceVariable } from '../type';
import CustomDropdown from '../../Common/Components/CustomDropdown';

interface IIpSetReferencesProps {
  creationData: ICreationDataPropsType;
  updateCreationData: (data: ICreationDataPropsType) => void;
}

const IPSetReferences = ({ creationData, updateCreationData }: IIpSetReferencesProps) => {
  const { region, cloudId, ipSetReferenceVariables } = creationData;
  const total = useMemo(() => {
    let sum = 0;
    ipSetReferenceVariables.forEach(item => {
      if (!isNaN(Number(item.resource.value))) {
        sum += Number(item.resource.value);
      }
    });

    return sum;
  }, [ipSetReferenceVariables]);
  const [list, setList] = useState<IIpSetReferenceVariable[]>([]);
  const [ipSetList, setIpSetList] = useState<DropdownListDataType[]>([]);
  const handleChangeIpSetReference = (
    key: 'name' | 'resource',
    value: string | DropdownListDataType,
    index: number,
  ) => {
    const newIpSetReferenceVariables: IIpSetReferenceVariable[] = ipSetReferenceVariables.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [key]: value,
          prefixListArn: list[i].prefixListArn,
        };
      }

      return item;
    });
    updateCreationData({ ...creationData, ipSetReferenceVariables: newIpSetReferenceVariables });
  };
  const handleRemoveIpSetReferenceVariable = (index: number) => {
    const newIpSetReferenceVariables = ipSetReferenceVariables.slice();
    newIpSetReferenceVariables.splice(index, 1);

    updateCreationData({ ...creationData, ipSetReferenceVariables: newIpSetReferenceVariables });
  };
  const handleAddIpSetReferenceVariable = () => {
    const newVariables: IIpSetReferenceVariable[] = ipSetReferenceVariables.concat({
      name: '',
      prefixListId: '',
      prefixListArn: '',
      prefixListName: '',
      maxEntries: '',
      resource: { value: '' },
    });
    updateCreationData({ ...creationData, ipSetReferenceVariables: newVariables });
  };
  const [getAwsManagedPrefixLists] = lazyGetAwsManagedPrefixLists();
  const getAllIpSetReferences = useCallback(async () => {
    if (!region) return;
    const variables = {
      cloudId,
      region,
      request: {},
    };
    try {
      const { data: dataPrefix } = await getAwsManagedPrefixLists({ variables });
      if (!dataPrefix?.getAwsManagedPrefixLists?.data?.length) {
        return;
      }
      const resources: DropdownListDataType[] = dataPrefix.getAwsManagedPrefixLists.data[0].prefixLists.map(item => ({
        name: item.prefixListId,
        description: item.prefixListName,
        value: item.maxEntries || 0,
      }));
      const saveList: IIpSetReferenceVariable[] = dataPrefix.getAwsManagedPrefixLists.data[0].prefixLists.map(item => ({
        name: item.prefixListName,
        prefixListId: item.prefixListId,
        prefixListArn: item.prefixListArn,
        prefixListName: item.prefixListName,
        maxEntries: item.maxEntries,
        resource: { value: 0 },
      }));
      setList(saveList);
      setIpSetList(resources);
    } catch (error) {
      console.error(error);
    }
  }, [cloudId, getAwsManagedPrefixLists, region]);
  useEffect(() => {
    getAllIpSetReferences();
  }, [getAllIpSetReferences]);

  return (
    <div className="rule-group-container">
      <div className="title-group">
        <p className="title">IP set references - optional</p>
        <p className="sub-title">
          An IP reference is a variable used in your rules that refers to a resource associated with a list of IPs or
          CDRs.
        </p>
      </div>

      <div className="rule-group-details-container">
        <div className="config-rule-row">
          <div className="rule-group-detail-content" style={{ gap: 16 }}>
            <div className="config-rule-row">
              <div className="config-rule-column">
                <div className="config-rule-label">Variable name</div>
              </div>
              <div className="config-rule-column">
                <div className="config-rule-label">Resource ID</div>
              </div>
              <div className="config-rule-column">
                <div className="config-rule-label">CIDRs</div>
              </div>
              <div style={{ marginLeft: 6, opacity: 0, pointerEvents: 'none' }}>
                <button className="button" style={{ margin: 0 }}>
                  Remove
                </button>
              </div>
            </div>
            {ipSetReferenceVariables.map((item, index) => {
              return (
                <div key={index} className="config-rule-row">
                  <div className="config-rule-column">
                    <InputAtom
                      placeholder="IP-set reference"
                      noClear={true}
                      value={item.name}
                      onChangeValue={value => handleChangeIpSetReference('name', value, index)}
                    />
                  </div>
                  <div className="config-rule-column">
                    <CustomDropdown
                      id={`resource-id-${index}`}
                      style={{ height: 48 }}
                      placeholder="Choose an IP set"
                      data={[...ipSetList]}
                      value={item.resource || { value: '' }}
                      handleClick={val => handleChangeIpSetReference('resource', val, index)}
                    />
                  </div>
                  <div className="config-rule-column">
                    <InputAtom value={`${item.resource.value || 0}`} disabled />
                  </div>
                  <button
                    className="button"
                    style={{ margin: 0 }}
                    onClick={() => handleRemoveIpSetReferenceVariable(index)}
                  >
                    Remove
                  </button>
                </div>
              );
            })}
            <button className="button next-button" style={{ margin: 0 }} onClick={handleAddIpSetReferenceVariable}>
              Add IP set reference
            </button>
            <div className="ip-set-description">You can add as many as 4 more IP set references.</div>
          </div>
          <div className="rule-group-detail-title" style={{ borderTop: '1px solid #E0E0E0' }}>
            CIDR usage
            <div className="rule-group-detail-sub-title">
              The total number of CIDRs used by the UP set references across the firewall.
            </div>
          </div>
          <div className="rule-group-detail-content">{total}/1,000,000</div>
        </div>
      </div>
    </div>
  );
};

export default IPSetReferences;
