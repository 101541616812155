import RequiredIcon from 'assets/svgs/v2/ico_require.svg';
import { DescribeFirewallPolicyStepPropsType } from '../types';
import InputAtom from 'components/v2/atoms/InputAtom';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import { FIREWALL_POLICY_CREATION, STREAM_EXCEPTION_POLICY } from '../configs';

const DescribeFirewallPolicyStep = (props: DescribeFirewallPolicyStepPropsType) => {
  const { name, description, streamExceptionPolicy, onValueChanged, errorMessage, setErrorMessage } = props;

  return (
    <div className="rule-group-container">
      <div className="title-group">
        <p className="title">{FIREWALL_POLICY_CREATION.DESCRIBE_FIREWALL_POLICY_TITLE}</p>
        <p className="sub-title">{FIREWALL_POLICY_CREATION.DESCRIBE_FIREWALL_POLICY_SUB_TITLE}</p>
      </div>

      <div className="rule-group-details-container">
        <div className="rule-group-detail-title">{FIREWALL_POLICY_CREATION.POLICY_DETAIL_TITLE}</div>
        <div className="rule-group-detail-content">
          <div className="detail-content-row">
            <div className="detail-content-column-left">
              {FIREWALL_POLICY_CREATION.POLICY_DETAIL_NAME_TITLE}
              <img style={{ marginLeft: 5 }} src={RequiredIcon} width={4} height={4} />
            </div>
            <div className="detail-content-column-right">
              <div className="full-width">
                <InputAtom
                  value={name}
                  noClear={true}
                  placeholder={FIREWALL_POLICY_CREATION.POLICY_DETAIL_NAME_PLACEHOLDER}
                  onChangeValue={(value: string) => {
                    const pattern = /^[A-Za-z0-9-]+$/;
                    if (value && (value?.length > 128 || !pattern.test(value))) return;

                    onValueChanged('name', value);
                    setErrorMessage?.();
                  }}
                  error={errorMessage}
                  bottomDescription="Enter a unique anme for the firewall. The name must have 1-128 characters. Valid characters:a-z, A-Z, 0-9 and - (hyphen). The name can’t start or end with a hyphen, and it can’t contain two consecutive hyphens."
                />
              </div>
            </div>
          </div>
          <div className="detail-content-row">
            <div className="detail-content-column-left">{FIREWALL_POLICY_CREATION.POLICY_DETAIL_DESCRIPTION}</div>
            <div className="detail-content-column-right">
              <div className="full-width">
                <InputAtom
                  value={description}
                  noClear={true}
                  placeholder={FIREWALL_POLICY_CREATION.POLICY_DETAIL_DESCRIPTION_PLACEHOLDER}
                  onChangeValue={(value: string) => onValueChanged('description', value)}
                />
              </div>
              <p>{FIREWALL_POLICY_CREATION.POLICY_DETAIL_SUBDESCRIPTION}</p>
            </div>
          </div>
        </div>
        <div className="firewall-detail-title">
          {FIREWALL_POLICY_CREATION.STREAM_EXCEPTION_POLICY_TITLE}
          <div className="firewall-detail-sub-title">
            {FIREWALL_POLICY_CREATION.STREAM_EXCEPTION_POLICY_DESCRIPTION}
          </div>

          {STREAM_EXCEPTION_POLICY.map(e => (
            <RadioAtom
              key={e.value}
              label={e.label}
              description={e.description}
              checked={e.value}
              name=""
              onChange={() => onValueChanged('streamExceptionPolicy', e.value)}
              value={streamExceptionPolicy}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DescribeFirewallPolicyStep;
