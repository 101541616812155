import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { SecurityGroupListPropsType } from '../types';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { SECURITY_GROUP_FILTER_DROPDOWN } from './configs';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import InputAtom from 'components/v2/atoms/InputAtom';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { orderAlphabetical } from '../../../Utils/Sorting';
import lazyGetAllAwsSecurityGroups, { IAwsSecurityGroupsPageVariables } from 'graphql/queries/getAwsAllSecurityGroups';
import { handleLinkClicked, variableCombineNextToken } from '../../../Utils';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdTabLayout/types';
import { FilterPropsType } from '../../../EC2/components/Image/types';
import { handleFormatText } from 'utils/Common';
import { AwsSecurityGroupType } from 'graphql/types/AwsSecurityGroup';
import { ManagementTypeEnum } from '../../..';
import { NETWORK_TAB_LIST } from '../../configs';
import { vpcFilterDropdown } from '../../Constants';
import { useNavigate } from 'react-router-dom';
import DeleteSecurityGroupModal from '../DeleteSecurityGroup';
import deleteAwsSecurityGroupMutation, {
  IDeleteAwsSecurityGroupVariables,
} from 'graphql/mutations/deleteAwsSecurityGroup';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';

const SecurityGroupList = (props: SecurityGroupListPropsType) => {
  const { cloudId, region, tabInformation, onSecurityGroupSelected, onSecurityGroupIdClicked, onNavigateCreation, currentRouteState, setCurrentRouteState } =
    props;

  const navigate = useNavigate();

  // API
  const [getAwsAllSecurityGroups, { loading: awsSGPageLoading }] = lazyGetAllAwsSecurityGroups();
  const [deleteAwsSecurityGroup, { loading: isLoadingDeleteAwsSecurityGroup }] = deleteAwsSecurityGroupMutation();

  // State
  const [mainTblTotalRows, setMainTblTotalRows] = useState<RowType[]>([]);
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const currentFilter = currentRouteState && SECURITY_GROUP_FILTER_DROPDOWN.find(e => e.value === currentRouteState.key);
  const [securityGroupFilterDropdownValue, setSecurityGroupFilterDropdownValue] = useState<DropdownListDataType>(
    currentFilter || SECURITY_GROUP_FILTER_DROPDOWN[0],
  );
  const [securityGroupFilterInputValue, setSecurityGroupFilterInputValue] = useState<FilterPropsType>({
    name: securityGroupFilterDropdownValue.value as string,
    value: currentRouteState?.value || '',
  });
  const [securityGroupFilter, setSecurityGroupFilter] = useState<FilterPropsType | undefined>(securityGroupFilterInputValue);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [securityGroupSelected, setSecurityGroupSelected] = useState<AwsSecurityGroupType[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const mainTblColumns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
      },
      {
        label: 'Security group ID',
        field: 'groupId',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  onSecurityGroupIdClicked();
                  onSecurityGroupSelected(row as AwsSecurityGroupType);
                }}
              >
                {handleFormatText(row?.groupId)}
              </div>
            }
          </>
        ),
      },
      { label: 'Security group name', field: 'groupName', sort: true },
      {
        label: 'VPC',
        field: 'vpcId',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e => {
                  handleLinkClicked({
                    navigate,
                    link: '/organ/1/manage/network',
                    type: ManagementTypeEnum.NETWORK,
                    tabId: NETWORK_TAB_LIST[0].id,
                    key: vpcFilterDropdown[0].value.toString(),
                    value: row?.vpcId,
                  });
                }}
              >
                {handleFormatText(row?.vpcId)}
              </div>
            }
          </>
        ),
      },
      { label: 'Description', field: 'description', sort: true },
      { label: 'Owner', field: 'ownerId', sort: true },
      {
        label: 'Inbound rules count',
        field: 'ipPermissions',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {row?.ipPermissions?.length > 1
              ? `${row?.ipPermissions?.length} permission entries`
              : `${row?.ipPermissionsEgress?.length} permission entry`}
          </>
        ),
      },
      {
        label: 'Outbound rules count',
        field: 'ipPermissionsEgress',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {row?.ipPermissionsEgress?.length > 1
              ? `${row?.ipPermissionsEgress?.length} permission entries`
              : `${row?.ipPermissionsEgress?.length} permission entry`}
          </>
        ),
      },
    ];
  }, []);

  const handleSearchFunction = useCallback(() => {
    if (securityGroupFilterInputValue.value !== '') {
      setSecurityGroupFilter({ name: securityGroupFilterInputValue.name, value: securityGroupFilterInputValue.value });
    } else {
      setSecurityGroupFilter(undefined);
    }
  }, [securityGroupFilterInputValue]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setMainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchAwsAllSecurityGroups(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(mainTblTotalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotalRows, mainTblTotal, mainTablePagination, mainNextToken, securityGroupFilterInputValue]);

  const awsSecurityGroupRequestVariables = useMemo((): IAwsSecurityGroupsPageVariables => {
    if (securityGroupFilter?.value) {
      return {
        cloudId: cloudId,
        region: region.value as string,
        request: {
          maxResults: mainTablePagination.limit,
          filters: {
            name: securityGroupFilter?.name || '',
            values: [securityGroupFilter?.value.toLocaleLowerCase() || ''],
          },
        },
      };
    }
    return {
      cloudId: cloudId,
      region: region.value as string,
      request: {
        maxResults: mainTablePagination.limit,
      },
    };
  }, [cloudId, region, mainTablePagination, securityGroupFilter]);

  const fetchAwsAllSecurityGroups = useCallback(
    (nextToken?: string, isInitial?: boolean) => {
      const combinedVariable = variableCombineNextToken(awsSecurityGroupRequestVariables, nextToken);
      getAwsAllSecurityGroups({ variables: combinedVariable }).then(({ data: awsSecurityGroupData }) => {
        const securityGroupRows: RowType[] = [];
        const getAwsAllSecurityGroup = awsSecurityGroupData?.getAwsAllSecurityGroups;
        if (getAwsAllSecurityGroup) {
          getAwsAllSecurityGroup?.data?.map(sg => {
            securityGroupRows.push({
              ...sg,
              name: sg?.tags?.find(t => t?.key == 'Name')?.value,
              id: sg.groupId,
            });
          });
        }
        let allSecurityGroups: RowType[] = [];
        if (isInitial) {
          allSecurityGroups = securityGroupRows;
        } else {
          allSecurityGroups = [...mainTblTotalRows, ...securityGroupRows];
        }
        setMainTblTotalRows(allSecurityGroups);
        if (allSecurityGroups.length > mainTablePagination.limit) {
          setMainNextToken(getAwsAllSecurityGroup?.nextToken as string);
        } else {
          setMainNextToken('');
        }
        setMainTblTotal({
          totalPage: Math.ceil(allSecurityGroups.length / mainTablePagination.itemPerPage),
          totalElement: allSecurityGroups.length,
        });
      });
    },
    [awsSecurityGroupRequestVariables, mainTblTotalRows, mainTblTotal, mainTablePagination],
  );

  const handleSecurityGroupSelected = useCallback(
    (listId: string[]) => {
      const securityGroups: AwsSecurityGroupType[] = [];
      listId.map(rowId => {
        const rowSelected = mainTblTotalRows.find(row => row.id === rowId);
        if (rowSelected) {
          securityGroups.push(rowSelected as AwsSecurityGroupType);
        }
      });
      setSecurityGroupSelected(securityGroups);
    },
    [mainTblTotalRows],
  );

  const handleMainRowSelected = useCallback(
    (rowId: string) => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);

      if (!rowSelected) return;

      handleSecurityGroupSelected([rowId]);
      onSecurityGroupSelected(rowSelected as AwsSecurityGroupType);
    },
    [mainTblTotalRows],
  );

  // For initial data
  useEffect(() => {
    fetchAwsAllSecurityGroups('', true);
  }, [awsSecurityGroupRequestVariables]);

  const deleteButtonDisabled = useMemo((): boolean => {
    return securityGroupSelected?.length === 0;
  }, [securityGroupSelected]);

  const handleDeleteSecurityGroup = async (groupId: string[]) => {
    if (isLoadingDeleteAwsSecurityGroup) return;

    try {
      const reqVariable: IDeleteAwsSecurityGroupVariables = {
        cloudId: cloudId,
        region: region?.value?.toString(),
        request: {
          groupId,
        },
      };
      const { data: reponseData } = await deleteAwsSecurityGroup({ variables: reqVariable });

      if (reponseData?.deleteAwsSecurityGroup?.result === ErrorCode.SUCCESS) {
        setDeleteModalVisible(false);
        useToast(ErrorCode.SUCCESS, 'Delete security group successful.');
        setSecurityGroupSelected([]);
        fetchAwsAllSecurityGroups('', true);
      } else {
        useToast(ErrorCode.UNKNOWN, 'Delete security group failed.');
      }
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Delete security group failed.');
    }
  };

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>{tabInformation.title}</p>
        </div>
        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={SECURITY_GROUP_FILTER_DROPDOWN}
            value={securityGroupFilterDropdownValue}
            handleClick={val => {
              setSecurityGroupFilterDropdownValue(val);
              setSecurityGroupFilterInputValue(prev => ({ ...prev, name: val.value as string }));
            }}
          />
          <InputAtom
            type={'text'}
            value={securityGroupFilterInputValue.value}
            defaultValue={''}
            onChangeValue={str => setSecurityGroupFilterInputValue(prev => ({ name: prev?.name, value: str }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearchFunction();
              }
            }}
          />

          <button className="action-btn" onClick={handleSearchFunction}>
            Search
          </button>

          <button className="action-btn" onClick={() => onNavigateCreation()}>
            Create
          </button>
          <button className="action-btn" onClick={() => setDeleteModalVisible(true)} disabled={deleteButtonDisabled}>
            Delete
          </button>
        </div>
      </div>
      <div className="data-grid-wrap">
        <Table
          rows={mainRowsCurrentPage}
          columns={mainTblColumns}
          reportCheckedList={listId => handleSecurityGroupSelected(listId)}
          reportSelected={id => handleMainRowSelected(id)}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              updateTablePagination('target', target);
              updateTablePagination('direction', dir);
            },
          }}
          isLoading={awsSGPageLoading}
          horizontalScrollable={true}
        />
        {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && awsSGPageLoading === false && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center">
              Total <span>{mainTblTotal.totalElement}</span>
            </p>
            <TableManagePagination
              ableFetchMore={!!mainNextToken}
              currentPage={mainTablePagination.currentPage}
              updateCurrentPage={page =>
                setMainTablePagination(prev => ({
                  ...prev,
                  ['currentPage']: page,
                }))
              }
              totalPage={mainTblTotal.totalPage}
            />
          </div>
        )}
      </div>

      {deleteModalVisible && (
        <DeleteSecurityGroupModal
          rows={securityGroupSelected}
          header={'Delete security groups'}
          onDelete={handleDeleteSecurityGroup}
          open={deleteModalVisible}
          onClose={() => setDeleteModalVisible(!deleteModalVisible)}
        />
      )}
    </Fragment>
  );
};

export default SecurityGroupList;
