import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IPutAwsSecretValueVariables {
  cloudId: number;
  region: string;
  reqData: {
    secretId: string;
    secretString: string;
  };
}

export interface IPutAwsSecretValueResponseData {
  putAwsSecretValue: IGqlResponseData<undefined>;
}

const putAwsSecretValueMutation = () => useMutation<IPutAwsSecretValueResponseData, IPutAwsSecretValueVariables>(query);
export default putAwsSecretValueMutation;
