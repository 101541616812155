import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import lazyGetAwsListResourceProfileArtifacts, {
  IGetAwsListResourceProfileArtifactsVariables,
} from 'graphql/queries/getAwsListResourceProfileArtifacts';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdLayout';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { useEffect, useMemo, useState } from 'react';
import { IResourceCoverageSummaryProps } from '../../../../../types';
import './index.scss';
import { extractBucketName } from 'pages/v2/Organ/Management/Macie/Function';

interface IObjectSamplesProps extends IResourceCoverageSummaryProps {}

const ObjectSamples = ({ cloudId, region, selectedResourceCoverage }: IObjectSamplesProps) => {
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTblPagination, setMainTblPagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const rows = useMemo((): RowType[] => {
    return orderAlphabetical(mainTblRows, mainTblPagination.target, mainTblPagination.direction);
  }, [mainTblRows, mainTblPagination]);

  const [getAwsListResourceProfileArtifacts, { loading }] = lazyGetAwsListResourceProfileArtifacts();

  const fetchListResourceProfileArtifacts = async () => {
    const variables: IGetAwsListResourceProfileArtifactsVariables = {
      cloudId,
      region,
      request: {
        resourceArn: selectedResourceCoverage.bucketArn,
      },
    };
    const response = await getAwsListResourceProfileArtifacts({ variables });
    const artifacts = response.data?.getAwsListResourceProfileArtifacts.data?.[0]?.artifacts || [];
    const artifactsTransformed: RowType[] = artifacts.map(o => ({
      id: o.arn,
      ...o,
    }));

    setMainTblRows(artifactsTransformed);
  };

  useEffect(() => {
    fetchListResourceProfileArtifacts();
  }, [selectedResourceCoverage.bucketArn]);

  const columns: ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Object name',
        field: 'arn',
        sort: true,
        renderCell: (row: RowType) => <span>{extractBucketName(row.arn)}</span>,
      },
      {
        label: 'Object sensitivity',
        field: 'sensitive',
        sort: true,
        renderCell: (row: RowType) => <span>{row.sensitive ? 'Sensitive' : 'Not sensitive'}</span>,
      },
      {
        label: 'Classification result',
        field: 'classificationResultStatus',
        sort: true,
        renderCell: (row: RowType) => <span>{row.classificationResultStatus}</span>,
      },
    ];
  }, []);

  return (
    <div className="mgd-object-samples-tab">
      <Table
        className="object-samples-table"
        rows={rows}
        columns={columns}
        sortOption={{
          target: mainTblPagination.target,
          direction: mainTblPagination.direction,
          onChangeSort: (target: string, dir: OrderDirection) => {
            setMainTblPagination(prev => ({ ...prev, direction: dir, target }));
          },
        }}
        isLoading={loading}
        horizontalScrollable
      />
      {!loading && !rows.length && (
        <div className="mgd-object-samples-table-empty data-grid-wrap">
          <div className="empty-row">
            <p>No objects</p>
            <span>Macie hasn't analyzed any objects in the bucket.</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ObjectSamples;
