import { gql } from '@apollo/client';

const query = gql`
  query getAwsListBuckets($request: AwsListBucketsRequest, $cloudId: Long, $region: String) {
    getAwsListBuckets(listBucketsRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListBucketsResponse {
          buckets {
            name
            creationDate
          }
          owner {
            displayName
            id
          }
        }
      }
    }
  }
`;
export default query;
