import Button from 'pages/v2/Organ/Management/components/Button';

interface ICustomIdentifierProps {
  onNavigateCreation: () => void;
}

const CustomIdentifier = ({ onNavigateCreation }: ICustomIdentifierProps) => {
  return (
    <div className="row-3 flex j-between a-center">
      <div className="title">
        <p>Custom data identifiers</p>
        <Button label="Create data identifier" onClick={onNavigateCreation} />
      </div>
    </div>
  );
};

export default CustomIdentifier;
