import { gql } from '@apollo/client';

const query = gql`
  mutation createAwsRuleGroupFirewall($request: AwsCreateRuleGroupFirewallRequest, $cloudId: Long, $region: String) {
    createAwsRuleGroupFirewall(createRuleGroupFirewallRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsCreateRuleGroupFirewallResponse {
          updateToken
          ruleGroupResponse {
            ruleGroupArn
            ruleGroupName
            ruleGroupId
            description
            type
            capacity
            ruleGroupStatus
            tags {
              key
              value
            }
            consumedCapacity
            numberOfAssociations
            encryptionConfiguration {
              keyId
              type
            }
            sourceMetadata {
              keyId
              type
            }
            snsTopic
            lastModifiedTime
            analysisResults {
              identifiedRuleIds
              identifiedType
              analysisDetail
            }
          }
        }
      }
    }
  }
`;

export default query;
