import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import LabelInput from 'components/v2/LabelInput';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Icon from 'components/v2/atoms/Icon';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import IconDelete from 'assets/svgs/v3/ico_delete_network.svg';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';

interface IDeleteResourceModalProps extends IBaseModalProps {
  header: string;
  titleWarning?: string;
  subWarning?: string;
  loading?: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteResource = ({
  header,
  titleWarning,
  subWarning,
  onDelete,
  loading,
  onClose,
  ...baseModalProps
}: IDeleteResourceModalProps) => {
  const [payload, setPayload] = useState<string>('');

  const ableDelete = useMemo(() => {
    if (payload !== 'delete') {
      return true;
    }

    return false;
  }, [payload]);

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      {...baseModalProps}
      onClose={onClose}
      title={() => (
        <>
          <Icon width={32} height={32} src={IconDelete} />
          {header}
        </>
      )}
    >
      <div className="delete-resource-modal">
        <div className="delete-resource">
          <div className="header">
            {titleWarning ? (
              <div className="delete-warning">
                <Icon width={32} height={32} src={IconWarning} />
                <div className="content">
                  <p className="detail-warning">{titleWarning}</p>
                  <p className="note">{subWarning}</p>
                </div>
              </div>
            ) : (
              <>
                <p className="delete-info">
                  Delete <span className="text-bold">test-firewall?</span> This action cannot be undone. You must first
                  diasble this delte protection for the firewall.
                </p>
                <button className="btn-protection" onClick={() => {}}>
                  Disable delete protection
                </button>
              </>
            )}
            <LabelInput
              title="To confirm deletions, type “delete” in this field."
              value={payload}
              placeholder={'delete'}
              onChangeValue={val => setPayload(val)}
            />
          </div>
        </div>

        <div className={'button-group'}>
          <Button
            label="Cancel"
            type={ButtonTypeEnum.GENERAL}
            onClick={onClose}
          />
          <Button
            label="Delete"
            type={ButtonTypeEnum.PRIMARY}
            loading={loading}
            disabled={ableDelete}
            onClick={onDelete}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteResource;
