import { ErrorCode } from '@Types/error';
import IconEditKmsEncryption from 'assets/svgs/v3/ico_kms_encryption.svg';
import IconSearch from 'assets/svgs/v3/ico_search.svg';
import AutoCompleteDropdownAtom from 'components/v2/atoms/AutoCompleteDropdownAtom';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import Icon from 'components/v2/atoms/Icon';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import CopyableText from 'components/v3/CopyableText';
import updateAwsFirewallEncryptionConfiguration, {
  UpdateAwsFirewallEncryptionConfigurationVariables,
} from 'graphql/mutations/updateAwsFirewallEncryptionConfiguration';
import lazyGetAwsAllListAliasesPage, { IAwsAllListAliasesVariables } from 'graphql/queries/getAwsAllListAliases';
import lazyGetAwsDescribeKey, { IAwsDescribeKeyVariables } from 'graphql/queries/getAwsDescribeKey';
import lazyGetAwsListKeys, { AwsListKeyVariablesType } from 'graphql/queries/getAwsListKeys';
import { AwsAlias } from 'graphql/types/AwsAliases';
import { AwsEncryptionConfigurationLog } from 'graphql/types/AwsFirewallEncryptionConfiguration';
import { AwsKeyListEntry } from 'graphql/types/AwsListKey';
import { Dispatch, useEffect, useMemo, useState } from 'react';
import './index.scss';
import { useToast } from 'hooks/v2/useToast';
import { IAwsFirewall, IBaseFirewallModalProps } from '../../../types';

interface IEditKMSEncryptionProps extends IBaseFirewallModalProps {
  onChangeValue?: (index: number, value: string) => void;
}
interface AwsKeyDetail {
  keyArn: string;
  keyStatus: boolean;
  keyAlias: string;
}
const EditKMSEncryptionModal = ({
  header,
  cloudId,
  region,
  data,
  setData = () => {},
  onChangeValue,
  ...baseModalProps
}: IEditKMSEncryptionProps) => {
  const { updateToken = '', firewall } = data || {};
  const { firewallArn = '', firewallName = '', encryptionConfiguration } = firewall || {};
  const initialCheckBox = encryptionConfiguration?.type !== "AWS_OWNED_KMS_KEY";

  const [awsAlias, setAwsAlias] = useState<AwsAlias[]>([]);
  const [awsListKey, setAwsListKey] = useState<AwsKeyListEntry[]>([]);
  const [isEncryptionChecked, setIsEncryptionChecked] = useState<boolean>(initialCheckBox);
  const [awsKeySelected, setAwsKeySelected] = useState<string>('');
  const [awsKeyDetail, setAwsKeyDetail] = useState<AwsKeyDetail | null>(null);
  const [error, setError] = useState<string>('');

  const [getAwsAllListAliases] = lazyGetAwsAllListAliasesPage();
  const [getAwsListKeys] = lazyGetAwsListKeys();
  const [getAwsDescribeKey] = lazyGetAwsDescribeKey();
  const [updateAwsFirewallEncryption] = updateAwsFirewallEncryptionConfiguration();

  const fetchAllListAliases = async () => {
    try {
      const variables: IAwsAllListAliasesVariables = {
        cloudId,
        region,
        request: {},
      };
      const response = await getAwsAllListAliases({ variables });
      const aliases = response.data?.getAwsAllListAliases?.data || [];
      setAwsAlias(aliases);
    } catch (error) { }
  };

  const fetchAllListKeys = async () => {
    try {
      const variables: AwsListKeyVariablesType = {
        cloudId,
        region,
        request: {},
      };

      const response = await getAwsListKeys({ variables });
      const listKeys = response.data?.getAwsListKeys?.data?.[0]?.keys || [];
      setAwsListKey(listKeys);
    } catch (error) { }
  };

  const dropdownListKeys = useMemo(() => {
    return awsListKey.reduce((data, awsKey) => {
      const alias = awsAlias.find(item => item.targetKeyId === awsKey.keyId);
      let description = '';

      if (alias) {
        const startIdx = alias.aliasName.lastIndexOf('/') + 1;
        const endIdx = alias.aliasName.length;
        description = alias.aliasName.substring(startIdx, endIdx);
      }

      data.push({
        name: awsKey.keyId,
        value: awsKey.keyId,
        description,
      });

      return data;
    }, [] as DropdownListDataType[]);
  }, [awsAlias, awsListKey]);

  const toggleEncryptionCheckBox = () => {
    setIsEncryptionChecked(!isEncryptionChecked);
    setAwsKeySelected('');
  };

  const handleGetAwsKeyDetail = async () => {
    try {
      const variables: IAwsDescribeKeyVariables = {
        cloudId,
        region,
        request: {
          keyId: awsKeySelected,
        },
      };

      const response = await getAwsDescribeKey({ variables });
      const keyMetadata = response.data?.getAwsDescribeKey.data?.[0].keyMetadata;
      const alias = dropdownListKeys.find(item => item.value === keyMetadata?.keyId)?.description || '';

      const keyDetail: AwsKeyDetail = {
        keyStatus: keyMetadata?.enabled ?? false,
        keyArn: keyMetadata?.arn || '',
        keyAlias: alias,
      };

      setAwsKeyDetail(keyDetail);
    } catch (error) { }
  };

  const handleEditKmsEncryption = async () => {
    if (isEncryptionChecked && !awsKeySelected) {
      setError('An AWS KMS key is required.');
      return;
    }

    try {
      const encryptionConfiguration: Partial<AwsEncryptionConfigurationLog> = {
        type: 'AWS_OWNED_KMS_KEY',
      };

      if (isEncryptionChecked) {
        encryptionConfiguration.type = 'CUSTOMER_KMS';
        encryptionConfiguration.keyId = awsKeySelected;
      }

      const variables: UpdateAwsFirewallEncryptionConfigurationVariables = {
        cloudId,
        region,
        request: {
          updateToken,
          firewallArn,
          firewallName,
          encryptionConfiguration,
        },
      };

      const response = await updateAwsFirewallEncryption({ variables });
      const firewallEncryptionData = response?.data?.updateAwsFirewallEncryptionConfiguration.data?.[0];
      setData((prevState: IAwsFirewall) => ({
        ...prevState,
        updateToken: firewallEncryptionData?.updateToken ?? '',
        firewall: {
          ...firewall,
          encryptionConfiguration: {
            keyId: firewallEncryptionData?.encryptionConfiguration.keyId,
            type: firewallEncryptionData?.encryptionConfiguration.keyId || ''
          },
        },
      }));
      useToast(ErrorCode.SUCCESS, 'Update Change protections successfully.');
    } catch (err) {
      useToast(ErrorCode.UNKNOWN, 'Update Change protections failed.');
    } finally {
      baseModalProps?.onClose?.();
    }
  };

  useEffect(() => {
    fetchAllListKeys();
    fetchAllListAliases();
  }, []);

  useEffect(() => {
    const initialAwsKey = awsListKey.find(item => item.keyArn === encryptionConfiguration?.keyId)?.keyId || '';
    setAwsKeySelected(initialAwsKey);
  }, [awsListKey]);

  useEffect(() => {
    if (awsKeySelected) {
      handleGetAwsKeyDetail();
    }
  }, [awsKeySelected]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditKmsEncryption} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="kms-encryption-modal">
        <div className="kms-encryption">
          <div className="kms-container">
            <p className="text-description">
              Your data is encrypted by default with a key that AWS owns and manages for you. To choose a different key,
              customize your encryption settings.
            </p>
            <label className="labeled flex a-center">
              <CheckboxAtom
                id="checkbox-protection"
                checked={isEncryptionChecked}
                onchange={toggleEncryptionCheckBox}
              />
              Customize encryption settings
            </label>
          </div>

          {isEncryptionChecked && (
            <>
              <div className="kms-container">
                <p className="text-description">To use the default key, clear this option.</p>
                <p className="text-title">Choose an AWS KMS key</p>
                <p className="text-description">This key will be used for encryption instead of the default key.</p>
                <AutoCompleteDropdownAtom
                  icon={IconSearch}
                  value={awsKeySelected}
                  data={dropdownListKeys}
                  error={error}
                  placeholder="Choose an AWS KMS key or enter an ARN"
                  handleClick={val => {
                    setAwsKeySelected(val.value as string);
                  }}
                  handleChange={() => {
                    setAwsKeySelected('');
                    setError('');
                  }}
                  handleAddClass={() => { }}
                />
              </div>
            </>
          )}

          {awsKeySelected && (
            <>
              <div className="kms-container">
                <p className="text-title">AWS KMS key details</p>
                <div className="kms-key-detail">
                  <p className="text-title">Key ARN</p>
                  <CopyableText text={awsKeyDetail?.keyArn || ''} />
                </div>
                <div className="kms-key-detail">
                  <p className="text-title">Key status</p>
                  <p className="text-description">{awsKeyDetail?.keyStatus ? 'Enabled' : 'Disabled'}</p>
                </div>
                {awsKeyDetail?.keyAlias && (
                  <div className="kms-key-detail">
                    <p className="text-title">Key aliases</p>
                    <p className="text-description">{awsKeyDetail?.keyAlias}</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={handleEditKmsEncryption}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditKMSEncryptionModal;
