import { ErrorCode } from '@Types/error';
import IconEditKmsEncryption from 'assets/svgs/v3/ico_kms_encryption.svg';
import IconSearch from 'assets/svgs/v3/ico_search.svg';
import AutoCompleteDropdownAtom from 'components/v2/atoms/AutoCompleteDropdownAtom';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import Icon from 'components/v2/atoms/Icon';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import CopyableText from 'components/v3/CopyableText';
import lazyGetAwsAllListAliasesPage, { IAwsAllListAliasesVariables } from 'graphql/queries/getAwsAllListAliases';
import lazyGetAwsDescribeKey, { IAwsDescribeKeyVariables } from 'graphql/queries/getAwsDescribeKey';
import lazyGetAwsListKeys, { AwsListKeyVariablesType } from 'graphql/queries/getAwsListKeys';
import { AwsAlias } from 'graphql/types/AwsAliases';
import { AwsKeyListEntry } from 'graphql/types/AwsListKey';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import { useEffect, useMemo, useState } from 'react';
import './index.scss';

interface IEditKMSEncryptionProps extends IBaseModalProps {
  header: string;
  cloudId: number;
  region: string;
  encryptionConfiguration: any;
  loadingSave: boolean;
  onClose: () => void;
  handleUpdateKMSencryption: (keyId: string, checkCustomize: boolean) => boolean;
}
interface IAwsKeyDetail {
  keyArn: string;
  keyStatus: boolean;
  keyAlias: string;
}
const EditKMSEncryptionModal = ({
  header,
  cloudId,
  region,
  encryptionConfiguration,
  handleUpdateKMSencryption,
  loadingSave,
  onClose,
  ...baseModalProps
}: IEditKMSEncryptionProps) => {

  const [awsAlias, setAwsAlias] = useState<AwsAlias[]>([]);
  const [awsListKey, setAwsListKey] = useState<AwsKeyListEntry[]>([]);
  const [isEncryptionChecked, setIsEncryptionChecked] = useState<boolean>(encryptionConfiguration?.type !== 'AWS_OWNED_KMS_KEY' ? true : false);
  const [awsKeySelected, setAwsKeySelected] = useState<string>(encryptionConfiguration?.keyId || '');
  const [awsKeyDetail, setAwsKeyDetail] = useState<IAwsKeyDetail | null>(null);
  const [error, setError] = useState<string>('');

  const [getAwsAllListAliases] = lazyGetAwsAllListAliasesPage();
  const [getAwsListKeys] = lazyGetAwsListKeys();
  const [getAwsDescribeKey] = lazyGetAwsDescribeKey();

  const onSavePolicy = async() => {
    if (isEncryptionChecked && awsKeySelected === '') {
      return setError('An AWS KMS key is required.');
    }
    const result = await handleUpdateKMSencryption(awsKeySelected, isEncryptionChecked);
    onClose();
  };

  const fetchAllListAliases = async () => {
    try {
      const variables: IAwsAllListAliasesVariables = {
        cloudId,
        region,
        request: {},
      };
      const response = await getAwsAllListAliases({ variables });
      const aliases = response.data?.getAwsAllListAliases?.data || [];
      setAwsAlias(aliases);
    } catch (error) {}
  };

  const fetchAllListKeys = async () => {
    try {
      const variables: AwsListKeyVariablesType = {
        cloudId,
        region,
        request: {},
      };

      const response = await getAwsListKeys({ variables });
      const listKeys = response.data?.getAwsListKeys?.data?.[0]?.keys || [];
      setAwsListKey(listKeys);
    } catch (error) {}
  };

  const dropdownListKeys = useMemo(() => {
    return awsListKey.reduce((data, awsKey) => {
      const alias = awsAlias.find(item => item.targetKeyId === awsKey.keyId);
      let description = '';

      if (alias) {
        const startIdx = alias.aliasName.lastIndexOf('/') + 1;
        const endIdx = alias.aliasName.length;
        description = alias.aliasName.substring(startIdx, endIdx);
      }

      data.push({
        name: awsKey.keyId,
        value: awsKey.keyId,
        description,
      });

      return data;
    }, [] as DropdownListDataType[]);
  }, [awsAlias, awsListKey]);

  const toggleEncryptionCheckBox = () => {
    setIsEncryptionChecked(!isEncryptionChecked);
    setAwsKeySelected('');
  };

  const handleGetAwsKeyDetail = async () => {
    try {
      const variables: IAwsDescribeKeyVariables = {
        cloudId,
        region,
        request: {
          keyId: awsKeySelected,
        },
      };

      const response = await getAwsDescribeKey({ variables });
      const keyMetadata = response.data?.getAwsDescribeKey.data?.[0].keyMetadata;
      const alias = dropdownListKeys.find(item => item.value === keyMetadata?.keyId)?.description || '';

      const keyDetail: IAwsKeyDetail = {
        keyStatus: keyMetadata?.enabled ?? false,
        keyArn: keyMetadata?.arn || '',
        keyAlias: alias,
      };

      setAwsKeyDetail(keyDetail);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllListKeys();
    fetchAllListAliases();
  }, []);

  useEffect(() => {
    if (awsKeySelected) {
      handleGetAwsKeyDetail();
    }
  }, [awsKeySelected, dropdownListKeys]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditKmsEncryption} />
          {header}
        </>
      )}
      onClose={onClose}
      {...baseModalProps}
    >
      <div className="kms-encryption-modal-policy">
        <div className="kms-encryption">
          <div className="kms-container">
            <p className="text-description">
              Your data is encrypted by default with a key that AWS owns and manages for you. To choose a different key,
              customize your encryption settings.
            </p>
            <label className="labeled flex a-center">
              <CheckboxAtom
                id="checkbox-protection"
                checked={isEncryptionChecked}
                onchange={toggleEncryptionCheckBox}
              />
              <p className="mx-5">Customize encryption settings</p>
            </label>
          </div>

          {isEncryptionChecked && (
            <>
              <div className="kms-container">
                <p className="text-description">To use the default key, clear this option.</p>
                <p className="text-title">Choose an AWS KMS key</p>
                <p className="text-description">This key will be used for encryption instead of the default key.</p>
                <AutoCompleteDropdownAtom
                  icon={IconSearch}
                  data={dropdownListKeys}
                  error={error}
                  value={awsKeySelected}
                  placeholder="Choose an AWS KMS key or enter an ARN"
                  handleClick={val => {
                    setAwsKeySelected(val.value as string);
                  }}
                  handleChange={() => {
                    setAwsKeySelected('');
                    setError('');
                  }}
                  handleAddClass={() => {}}
                />
              </div>
            </>
          )}

          {awsKeySelected && (
            <>
              <div className="kms-container">
                <p className="text-title">AWS KMS key details</p>
                <div className="kms-key-detail">
                  <p className="text-title">Key ARN</p>
                  <CopyableText text={awsKeyDetail?.keyArn || ''} />
                </div>
                <div className="kms-key-detail">
                  <p className="text-title">Key status</p>
                  <p className="text-description">{awsKeyDetail?.keyStatus ? 'Enabled' : 'Disabled'}</p>
                </div>
                {awsKeyDetail?.keyAlias && (
                  <div className="kms-key-detail">
                    <p className="text-title">Key aliases</p>
                    <p className="text-description">{awsKeyDetail?.keyAlias}</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="button-group">
          <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={onClose} />
          <Button
            label="Save"
            type={ButtonTypeEnum.PRIMARY}
            loading={loadingSave}
            onClick={onSavePolicy}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default EditKMSEncryptionModal;
