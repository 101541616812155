import ClearIcon from 'assets/svgs/v2/ico_input_clear.svg';
import classNames from 'classnames';
import InputAtom from 'components/v2/atoms/InputAtom';
import NetworkTitle from 'components/v3/NetworkTitle';
import { CSSProperties, useRef, useState } from 'react';
import RegularExpression from './RegularExpression';
import PredefinedText from './PredefinedText';
import { ICreateAllowListData, ICreateAllowListErrors, ITag, ListType } from '../types';
import lazyCreateAwsAllowList, { ICriteria } from 'graphql/mutations/createAwsAllowList';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';

type CustomRadioProps = {
  checked?: boolean;
  subLabel?: string;
  styleCheckmark?: CSSProperties;
  label?: string;
  description?: string;
  onClick: () => void;
  disabled?: boolean;
  style?: CSSProperties;
};
export const CustomRadio = (props: CustomRadioProps) => {
  const { checked, styleCheckmark, label, subLabel, description, onClick, disabled, style } = props;

  return (
    <div
      className="radio-custom-container"
      style={{ ...(disabled ? { pointerEvents: 'none', cursor: 'none', backgroundColor: '#f7f7f7' } : {}), ...style }}
    >
      <div
        className="radio-atom"
        onClick={() => {
          onClick();
        }}
      >
        <input
          type="radio"
          checked={checked}
          onChange={() => {
            onClick();
          }}
        />
        <span className="check-mark" style={styleCheckmark} />
      </div>
      <div className="label-wrapper">
        <div className="radio-custom-label">
          <span>{label}</span>
          {subLabel && <span style={{ fontStyle: 'italic' }}>{subLabel}</span>}
        </div>
        <div className="radio-custom-description">{description}</div>
      </div>
    </div>
  );
};
type ValueDataType = {
  value?: string;
};
type InputFormPropsType = {
  type?: 'INPUT' | 'DROPDOWN' | 'VALUE';
  field?: string;
  index?: number;
  clearable?: boolean;
  value?: ValueDataType;
  error?: boolean;
  setValue?: (key: string, value: string, index: number) => void;
};
export const InputForm = (props: InputFormPropsType) => {
  const { type, value, setValue, field, index, error } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnchange = (value: string) => {
    if (setValue) {
      setValue(field ?? '', value, index ?? 0);
    }
  };

  const handleClear = () => {
    handleOnchange('');
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="input-form-container">
      {type === 'INPUT' && (
        <input
          className={classNames('input-form-border', error && 'error')}
          value={value?.value as string}
          onChange={event => {
            setValue && setValue('', event.target.value, 0);
          }}
        />
      )}
      {type === 'VALUE' && (
        <div className="value-wrapper input-form-border">
          <input
            className={classNames('value', error && 'error')}
            value={value?.value as string}
            onChange={event => {
              handleOnchange(event.target.value);
            }}
            ref={inputRef}
          />
          {value?.value && (
            <img
              src={ClearIcon}
              width={26}
              height={26}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleClear();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

interface ICreateMacieSettingAllowListProps {
  cloudId: number;
  region: string;
  pageBackClick: () => void;
}

const CreateMacieSettingAllowList = ({ cloudId, region, pageBackClick }: ICreateMacieSettingAllowListProps) => {
  const initData: ICreateAllowListData = {
    listType: ListType.REGULAR_EXPRESSION,
    name: '',
    regularExpression: '',
    predefinedS3Name: '',
    predefinedS3Object: '',
    tags: [],
  };
  const [creationData, updateCreationData] = useState(initData);
  const [createAwsAllowList] = lazyCreateAwsAllowList();
  const [error, setError] = useState<ICreateAllowListErrors>({});
  const validate = (): boolean => {
    let isValid = true;
    const { name, listType, regularExpression, predefinedS3Name, predefinedS3Object, tags } = creationData;
    let nameError, regular, s3Name, s3Object;
    if (!name || !name.trim()) {
      nameError = 'Name is required';
      isValid = false;
    }
    if (listType === ListType.REGULAR_EXPRESSION && (!regularExpression || !regularExpression.trim())) {
      regular = 'A regular expression is required';
      isValid = false;
    } else if (listType === ListType.PREDEFINED_TEXT) {
      if (!predefinedS3Name || !predefinedS3Name.trim()) {
        s3Name = 'A bucket name is required';
        isValid = false;
      }
      if (!predefinedS3Object || !predefinedS3Object.trim()) {
        s3Object = 'A object name is required';
        isValid = false;
      }
    }
    let tagsValid = true;
    tags.forEach((item, id) => {
      if (tagsValid) {
        tagsValid = !tags.some((tag, i) => tag.key !== '' && i !== id && tag.key === item.key);
      }
    });
    setError({
      name: nameError,
      regular: regular,
      s3Name,
      s3Object,
    });

    return isValid && tagsValid;
  };
  const handleCreateAwsAllowList = async () => {
    const { name, description, listType, regularExpression, predefinedS3Name, predefinedS3Object, tags } = creationData;
    if (!validate()) return;
    let newTags: Record<string, string | undefined> | undefined;
    if (tags?.length) {
      newTags = {};
      for (const { key, value } of tags) {
        newTags[key] = value;
      }
    }
    const criteria: ICriteria = {};
    if (listType === ListType.REGULAR_EXPRESSION) {
      criteria.regex = regularExpression;
    } else if (listType === ListType.PREDEFINED_TEXT) {
      criteria.s3WordsList = {
        bucketName: predefinedS3Name,
        objectKey: predefinedS3Object,
      };
    }
    const { errors } = await createAwsAllowList({
      variables: {
        cloudId,
        region,
        request: {
          name,
          description: description || undefined,
          criteria,
          tags: newTags,
        },
      },
    });
    if (!errors) {
      useToast(ErrorCode.SUCCESS, 'Create allow list successful.');
      pageBackClick();
    } else {
      useToast(ErrorCode.UNKNOWN, 'Create allow list failed.');
    }
  };

  return (
    <div id="mgd-list-layout">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center" id="title">
          <NetworkTitle
            pageTitle="Create allow list"
            id={321}
            name="name"
            hasPrefixIcon={false}
            hasFavorite={false}
            pageBackClick={pageBackClick}
          />
        </div>
      </div>
      <div className="one-page" style={{ height: 'calc(100% - 70px)', overflowY: 'auto' }}>
        <div className="row-3 flex j-between a-center">
          <div className="title">
            <p>Select a list type</p>
            <p className="sub">
              An allow list can be a file that lists predefined text to ignore or a regular expression that defines a
              text parttern to ignore. Choose the type to create
            </p>
          </div>
        </div>
        <div className="allow-list-screen">
          <div className="allow-list-container">
            <div className="allow-list-setting-type-container">
              <div
                className={`allow-list-flex ${
                  creationData.listType === ListType.REGULAR_EXPRESSION ? 'radio-selected' : ''
                }`}
              >
                <CustomRadio
                  checked={creationData.listType === ListType.REGULAR_EXPRESSION}
                  label="Regular expression"
                  description="Specify a regular expression (regex) that defines a text pattern to ignore."
                  onClick={() => {
                    updateCreationData({ ...creationData, listType: ListType.REGULAR_EXPRESSION });
                    setError({});
                  }}
                />
              </div>
              <div
                className={`allow-list-flex ${
                  creationData.listType === ListType.PREDEFINED_TEXT ? 'radio-selected' : ''
                }`}
              >
                <CustomRadio
                  checked={creationData.listType === ListType.PREDEFINED_TEXT}
                  label="Predefined text"
                  description="Specify the location of a plaintext file that lists specific text to ignore and is stored in an S3 bucket."
                  onClick={() => {
                    updateCreationData({ ...creationData, listType: ListType.PREDEFINED_TEXT });
                    setError({});
                  }}
                />
              </div>
            </div>
            {creationData.listType === ListType.REGULAR_EXPRESSION ? (
              <RegularExpression
                cloudId={cloudId}
                region={region}
                creationData={creationData}
                updateCreationData={updateCreationData}
                error={error}
              />
            ) : (
              <PredefinedText creationData={creationData} updateCreationData={updateCreationData} error={error} />
            )}
            <div className="allow-lists-container" style={{ display: 'flex' }}>
              <div style={{ width: creationData.listType === ListType.REGULAR_EXPRESSION ? '60%' : '100%' }}>
                <div className="allow-list-title">
                  <p>Tags - optional</p>
                  <p className="allow-list-sub-title">
                    A tag is a label that you assign to an AWS resource. Each tag consists of a key and an optional
                    value.
                  </p>
                </div>
                <div className="allow-list-content">
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 8, width: '100%' }}>
                    <div className="allow-list-content" style={{ gap: 4, padding: 0 }}>
                      {creationData.tags.length > 0 ? (
                        <>
                          <div className="config-rule-row">
                            <div className="config-rule-column">
                              <div className="firewall-detail-sub-title">Key</div>
                            </div>
                            <div className="config-rule-column">
                              <div className="firewall-detail-sub-title">Value - optional</div>
                            </div>
                            <div style={{ marginLeft: 6, opacity: 0, pointerEvents: 'none' }}>
                              <button className="button" style={{ margin: 0 }} onClick={() => {}}>
                                Remove tag
                              </button>
                            </div>
                          </div>
                          {creationData.tags.map((item, id) => {
                            return (
                              <div key={id} className="config-rule-row">
                                <div className="config-rule-column">
                                  <InputAtom
                                    value={item.key}
                                    placeholder="Enter key"
                                    onChangeValue={value => {
                                      const newTags = [...creationData.tags];
                                      newTags[id].key = value;
                                      updateCreationData({ ...creationData, tags: newTags });
                                    }}
                                    error={creationData.tags.some(
                                      (tag, i) => tag.key !== '' && i !== id && tag.key === item.key,
                                    )}
                                  />
                                </div>
                                <div className="config-rule-column">
                                  <InputAtom
                                    value={item.value}
                                    placeholder="Enter value"
                                    onChangeValue={value => {
                                      const newTags = [...creationData.tags];
                                      newTags[id].value = value;
                                      updateCreationData({ ...creationData, tags: newTags });
                                    }}
                                  />
                                </div>
                                <button
                                  className="button"
                                  style={{ margin: 0 }}
                                  onClick={() => {
                                    const newTags = [...creationData.tags];
                                    newTags.splice(id, 1);
                                    updateCreationData({ ...creationData, tags: newTags });
                                  }}
                                >
                                  Remove tag
                                </button>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <p className="allow-list-sub-title" style={{ padding: '10px 0' }}>
                          No tags associated with the resource.
                        </p>
                      )}
                    </div>
                    <div>
                      <button
                        className="button next-button"
                        style={{ margin: 0 }}
                        onClick={() =>
                          updateCreationData({ ...creationData, tags: [...creationData.tags, { key: '' }] })
                        }
                      >
                        Add tags
                      </button>
                    </div>
                    <div className="allow-list-title" style={{ borderBottom: 'none', padding: 0 }}>
                      <div className="allow-list-sub-title">
                        You can add up to {50 - creationData.tags.length} more tags.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="allow-list-bottom">
            {(error.name || error.regular || error.s3Name || error.s3Object) && (
              <p className="error-message">{error.name || error.regular || error.s3Name || error.s3Object}</p>
            )}
            <Button label="Cancel" onClick={pageBackClick} />
            <Button label="Create" type={ButtonTypeEnum.PRIMARY} onClick={handleCreateAwsAllowList} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMacieSettingAllowList;
