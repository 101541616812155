import './index.scss';
import ChatBotLogoIcon from 'assets/svgs/v4/chatbot_logo.svg';

import Icon from 'components/v2/atoms/Icon';
import Message from './Message';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import apis from 'apis/v4';
import { IGetChatbotMessageReq } from 'apis/v4/Chatbot/schema';
import { useAuth } from 'contexts/AuthProvider';
import { ChatbotMessageType } from '@Types/v4/Chatbot';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import { dateFormatter } from 'utils/Formatter';

interface IChatBotProps {
  open: boolean;
  closeChatBotPanel: () => void;
}

const ChatBot = ({open, closeChatBotPanel}: IChatBotProps) => {
  const {token, userInfo, checkTokenValid} = useAuth();
  const messageRef = useRef<HTMLDivElement>(null);
  const [msg, setMsg] = useState('');
  const [messageList, setMessageList] = useState<ChatbotMessageType[]>([{
    id: -4, 
    user: 'chatbot',
    who: 'AI',
    message: `Hello!  \nFeel free to ask me anything. 😃`,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString()
  }]);
  const [localLike, setLocalLike] = useState<{id: number, state: boolean} | undefined>(undefined);
  const [chatbotMessageEnd, setChatbotMessageEnd] = useState(true);
  const [chatbotServerError, setChatbotServerError] = useState(false);

  const memoizeMsgList = useMemo(() => {
    if(localLike) {
      return messageList.map(msg => msg.id === localLike.id ? {...msg, liked: localLike.state} : msg);
    } else {
      return messageList;
    }
  },[messageList, localLike]);
  const onClickSendMsg = (msg: string) => {
    if(msg !== '') {
      setMessageList(prev => [...prev.map(d => d.id === -2 ? {...d, id: -3} : d), {
        id: -1, 
        user: userInfo?.nemo.memberId || '',
        who: 'UR',
        message: msg,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }, {
        id: -2, 
        user: 'chatbot',
        who: 'AI',
        message: '',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }]);
    }
  }
  const getRecentMessages = () => {
    const beginTime = new Date();
    beginTime.setHours(beginTime.getHours() - 1);
    const datas:IGetChatbotMessageReq = {
      begin_time: beginTime.toISOString(),
      end_time: new Date().toISOString()
    };
    apis.Chatbot.getMessages(datas, token).then(res => {
      setChatbotServerError(false);
      if(res.data.result === 'OK') {
        setMessageList([{
          id: -4, 
          user: 'chatbot',
          who: 'AI',
          message: `Hello!  \nFeel free to ask me anything. 😃`,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }, ...res.data.messages]);
      } else {
        console.log('get chatbot false', res.data);
        // useToast(ErrorCode.UNKNOWN, 'Get chatbot message list failed.');
        setMessageList([{
          id: -4, 
          user: 'chatbot',
          who: 'AI',
          message: `Hello!  \nFeel free to ask me anything. 😃`,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }]);
      }
    }).catch((e) => {
      console.log('get chatbot error', e);
      // useToast(ErrorCode.UNKNOWN, 'Get chatbot message list failed.');
      setMessageList([{
        id: -4, 
        user: 'chatbot',
        who: 'AI',
        message: `Hi!   \nWe are currently undergoing maintenance.   \nIn the near future, we will meet you again in a better form.`,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }]);
      setChatbotServerError(true);
      setMsg('');
    });
  };
  const refetchLikeState = () => {
    const like = localStorage.getItem('chatbot');

    if(like) {
      try {
        const parse = JSON.parse(like);
        setLocalLike(parse);
      } catch {
        setLocalLike(undefined);
      }
    } else {
      setLocalLike(undefined);
    }
  }
  const sendMessageAi = async (msg: string) => {
    await fetch(`${process.env.REACT_APP_NEMO_CHATBOT_API}/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({ message: msg }),
    }).then((response) => {
      messageRef.current?.scrollTo(0, messageRef.current.scrollHeight);
      if(response.body) {
        setMsg('');
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        const processResult:any = (result:ReadableStreamReadResult<Uint8Array>) =>  {
          if (result.done || decoder.decode(result.value).startsWith('{"detail":')) {
            if(decoder.decode(result.value)) {
              setMessageList(prev => prev.map(d => d.id === -2 ? {...d, message: decoder.decode(result.value)} : d));
            }
  
            setChatbotMessageEnd(true);
  
            return;
          } else {
            let token = decoder.decode(result.value);
            setMessageList(prev => prev.map(d => d.id === -2 ? {...d, message: d.message + token} : d));
            messageRef.current?.scrollTo(0, messageRef.current.scrollHeight);
  
            return reader.read().then(processResult);
          }
        }
  
        reader.read().then((res) => {
          processResult(res);
        });
      }
    }).catch(() => useToast(ErrorCode.UNKNOWN, 'Send message failed.'));

  }

  useEffect(() => {
    if(checkTokenValid && open) {
      getRecentMessages();
    }
  },[checkTokenValid, open]);
  useEffect(() => {
    const findOne = messageList.find(d => d.id === -2);
    if(findOne && findOne.message === '') {
      sendMessageAi(msg);
      setChatbotMessageEnd(false);
    }
  },[messageList]);
  useEffect(() => {
    const findOne = messageList.find(d => d.id === -2);
    if(chatbotMessageEnd && findOne && findOne.message !== '') {
      //챗봇이 한 말을 서버에 저장
      apis.Chatbot.setMessage({
        message: findOne.message,
        done_time: new Date().toISOString()
      }, token).then(res => {
        if(res.data.result === 'OK') {
          // getRecentMessages();
          setMessageList(prev => prev.map(d => d.id < 0 ? {...d, id: d.id - 2} : d));
        } else {
          console.log('set chatbot false', res.data);
          // useToast(ErrorCode.UNKNOWN, 'Set chatbot message failed.');
        }
      }).catch((e) => {
        console.log('set chatbot error', e);
        // useToast(ErrorCode.UNKNOWN, 'Set chatbot message failed.');
      });
    }
  },[chatbotMessageEnd, messageList])

  return (
    <div id="chatbot-page">
      {/* header chatbot */}
      <div className="header flex j-between a-center">
        <div className="title-box flex a-end">
          <Icon width={14} height={18} src={ChatBotLogoIcon} />
          CuridyQ
          <span>Beta</span>
        </div>
        <button className="close-btn" onClick={() => closeChatBotPanel()} />
      </div>

      {/* message content */}
      <div className="message-wrapper" ref={messageRef}>
        <div className="message-content">
          {/* separate date */}
          {memoizeMsgList.map((msg, idx) => {
            return (
              <Fragment key={msg.id}>
                {(idx === 0 || dateFormatter(memoizeMsgList[idx - 1].created_at, 'date', true) !== dateFormatter(msg.created_at, 'date', true)) &&
                <div className="separate-date">
                  <p className="date-time-text">{dateFormatter(msg.created_at, 'date', true)}</p>
                </div>
                }
                <Message 
                  {...msg}
                  isLeft={msg.who === 'AI'}
                  hasAction={idx === memoizeMsgList.length - 1 && !chatbotServerError}
                  key={msg.id}
                  refetchLikeState={refetchLikeState}
                />
              </Fragment>
            );
          })}
        </div>
      </div>

      {/* footer chatbot */}
      <div className="footer">
        <form className={`input-box ${chatbotServerError ? 'disabled': ''}`} onSubmit={(e) => {
          e.preventDefault();
          onClickSendMsg(msg);
        }}>
          <input type="text" className="message-text" value={msg} disabled={chatbotServerError} placeholder="Ask me everything" onChange={(e) => {
            setMsg(e.target.value);
          }}/>
          <button className="send-btn" disabled={chatbotServerError}/>
        </form>
        <div className="footer-tip">Beta operation. It operates from 9 AM to 7 PM Korea Standard Time.</div>
      </div>
    </div>
  );
};

export default ChatBot;
