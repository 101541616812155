import IconReload from 'assets/svgs/v3/ico_red_circle_reload.svg';
import Icon from 'components/v2/atoms/Icon';
import BaseModal from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import { useCallback, useMemo, useState } from 'react';
import { UpdateDescriptionModalPropsType } from '../types';
import updateAwsTLSInspectionConfigurationMutation, {
  AwsUpdateTLSInspectionConfigurationVariables,
} from 'graphql/mutations/updateAwsTLSInspectionConfiguration';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import './index.scss';

const UpdateDescriptionModal = (props: UpdateDescriptionModalPropsType) => {
  const { cloudId, region, tlsInspectionDetail, onCancel, onUpdated, ...baseModalProps } = props;

  // API
  const [updateAwsTLSInspectionConfiguration, { loading: updateLoading }] =
    updateAwsTLSInspectionConfigurationMutation();

  const [editedDescription, setEditedDescription] = useState<string>(
    tlsInspectionDetail?.tlsInspectionConfigurationResponse?.description,
  );
  const [editedDescriptionError, setEditedDescriptionError] = useState<string>('');

  const requestData = useMemo((): AwsUpdateTLSInspectionConfigurationVariables => {
    const { updateToken, tlsInspectionConfiguration, tlsInspectionConfigurationResponse } = tlsInspectionDetail;
    const { tlsInspectionConfigurationArn, tlsInspectionConfigurationName, encryptionConfiguration } =
      tlsInspectionConfigurationResponse;
    return {
      cloudId,
      region,
      reqData: {
        tlsInspectionConfigurationArn,
        tlsInspectionConfigurationName,
        tlsInspectionConfiguration,
        description: editedDescription,
        encryptionConfiguration,
        updateToken,
      },
    };
  }, [cloudId, region, tlsInspectionDetail, editedDescription]);

  const onDescriptionChange = useCallback((value: string) => {
    setEditedDescription(value);
    if (value.length > 256) {
      setEditedDescriptionError('The description must be less than 256 characters.');
    } else {
      setEditedDescriptionError('');
    }
  }, []);

  const updateDescription = useCallback(() => {
    if (editedDescriptionError === '') {
      updateAwsTLSInspectionConfiguration({ variables: requestData }).then(({ data: response }) => {
        const result = response?.updateAwsTLSInspectionConfiguration?.result ?? '';
        if (result === ErrorCode.SUCCESS) {
          useToast(ErrorCode.SUCCESS, 'Update TLS Inspection Configuration successfully.');
          onUpdated();
        } else {
          useToast(ErrorCode.SUCCESS, 'Update TLS Inspection Configuration failed.');
        }
      });
    }
  }, [editedDescriptionError, requestData, onUpdated]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconReload} />
          {'Update TLS inspection configuration description'}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-tls-inpsection-detail-modal">
        <div className="edit-tls-inpsection-name">
          <p className="label">Name</p>
          <p className="name">
            {tlsInspectionDetail?.tlsInspectionConfigurationResponse?.tlsInspectionConfigurationName}
          </p>
        </div>
        <div className="edit-tls-inpsection-description">
          <p className="label">Description</p>
          <div className={`input-group-container ${editedDescriptionError ? 'error' : ''}`}>
            <InputAtom
              type={'text'}
              placeholder={'Enter TLS inspection configuration description'}
              value={editedDescription}
              noClear={false}
              onChangeValue={val => onDescriptionChange(val)}
              error={editedDescriptionError}
            />
          </div>
          <p className="description">The description can have 0-256 characters.</p>
        </div>

        <div className="button-group">
          <Button label="Cancel" onClick={onCancel} />
          <Button
            label="Save"
            type={ButtonTypeEnum.PRIMARY}
            onClick={() => updateDescription()}
            loading={updateLoading}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default UpdateDescriptionModal;
