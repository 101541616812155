import { useCallback, useEffect, useMemo, useState } from 'react';
import { FirewallPolicyCreationPropsType } from './types';
import NetworkTitle from 'components/v3/NetworkTitle';
import DescribeFirewallPolicyStep from './components/DescribeFirewallPolicyStep';
import AddRuleGroupsStep from './components/AddRuleGroupsStep';
import ConfigureAdvancedSettingsStep from './components/ConfigureAdvancedSettingsStep';
import AddTLSInspectionConfigurationStep from './components/AddTLSInspectionConfigurationStep';
import AddTagsStep from './components/AddTagsStep';
import BottomActionButtonGroup from './components/BottomActionButtonGroup';
import {
  DROP_ACTION_DROPDOWN_LIST,
  FIREWALL_POLICY_CREATION,
  RULE_ORDER,
  STATE_LESS_DEFAULT_ACTION,
  STATE_LESS_FRAGMENT_DEFAULT_ACTIONS,
  STREAM_EXCEPTION_POLICY,
} from './configs';
import { RowType } from '@Types/v2/Table';
import lazyGetAwsAllListRuleGroupsFirewall from 'graphql/queries/getAwsAllListRuleGroupsFirewall';
import lazyGetAwsDescribeRuleGroup from 'graphql/queries/getAwsDescribeRuleGroup';
import FirewallPoliciesPreviewCreation from '../FirewallPoliciesPreviewCreation';
import createAwsFirewallPolicyMutation, {
  ICreateAwsFirewallPolicyVariables,
} from 'graphql/mutations/createAwsFirewallPolicy';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';

const FIREWALL_POLICY_DEFAULT_STATE = {
  name: '',
  description: '',
  streamExceptionPolicy: STREAM_EXCEPTION_POLICY[0].value,
  fragmentedPackets: '',
  ruleAction: 'Same',
  ruleActionFullPackets: STATE_LESS_DEFAULT_ACTION[0].value,
  ruleActionFragmentedPackets: STATE_LESS_FRAGMENT_DEFAULT_ACTIONS[0].value,
  statefulRuleEvaluationOrder: RULE_ORDER[0].value,
  statefulRuleDropAction: DROP_ACTION_DROPDOWN_LIST[0].value,
  alertOptionAll: false,
  alertOptionEstablished: false,
  statelessRuleGroupCapacityUnitConsumed: '0/30000',
  statefulRuleGroupCapacityUnitConsumed: '0/30000',
  customizeEncryptionSettings: false,
  policyVariables: '',
  awsKmsKey: null,
  tlsInspectionConfiguration: null,
  tags: [],
};

const MAXIMUM_STEP_COUNT = 5;

enum DATA_VALIDATION_CONDITION {
  'REQUIRED',
  'MAX-LENGTH',
  'MIN-LENGTH',
}

type DataValidationConditionType = 'REQUIRED' | 'MAX-LENGTH' | 'MIN-LENGTH';

const FirewallPolicyCreation = (props: FirewallPolicyCreationPropsType) => {
  const { cloudId, region, pageBackClick } = props;

  const [getAwsAllListRuleGroupsFirewall] = lazyGetAwsAllListRuleGroupsFirewall();
  const [getAwsDescribeRuleGroup] = lazyGetAwsDescribeRuleGroup();
  const [createAwsFirewallPolicy, { loading: createAwsFirewallPolicyLoading }] = createAwsFirewallPolicyMutation();

  const [screenName, setScreenName] = useState('CREATION');
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [firewallPolicy, setFirewallPolicy] = useState(FIREWALL_POLICY_DEFAULT_STATE);
  const [errorMessage, setErrorMessage] = useState('');

  const [ruleGroups, setRuleGroups] = useState<any>([]);
  const [statelessRuleGroupRows, setStatelessRuleGroupRows] = useState<any>([]);
  const [statefulRuleGroupRows, setStatefulRuleGroupRows] = useState<any>([]);

  const handleValueChanged = useCallback(
    (key: string, value: string) => {
      setFirewallPolicy(prevState => ({
        ...prevState,
        [key]: value,
      }));
      if (errorMessage) {
        setErrorMessage('');
      }
    },
    [setFirewallPolicy, setErrorMessage],
  );

  const handleGetMainDataRows = async () => {
    if (!region) return;

    const variables = {
      cloudId,
      region,
      request: {
        maxResults: 100,
      },
    };

    try {
      const { data: listRuleGroupsFirewall } = await getAwsAllListRuleGroupsFirewall({
        variables,
      });

      if (!listRuleGroupsFirewall?.getAwsAllListRuleGroupsFirewall?.data?.length) {
        return;
      }

      const fetchListAllRuleGroupsFirewall = listRuleGroupsFirewall.getAwsAllListRuleGroupsFirewall.data.map(
        async metaData => {
          const ruleGroupFirewallVariable = {
            cloudId,
            region,
            request: {
              ruleGroupName: metaData?.name || '',
              ruleGroupArn: metaData?.arn || '',
            },
          };

          return await getAwsDescribeRuleGroup({
            variables: ruleGroupFirewallVariable,
          }).then(({ data: ruleGroup }) => {
            const ruleGroupResponse = ruleGroup?.getAwsDescribeRuleGroup?.data?.[0]?.ruleGroupResponse;

            if (ruleGroupResponse) {
              return {
                id: metaData?.arn,
                name: metaData?.name,
                type: ruleGroupResponse?.type,
                capacity: ruleGroupResponse?.capacity,
              };
            }
          });
        },
      );

      const listAllRuleGroupFirewallData = await Promise.all(fetchListAllRuleGroupsFirewall);
      const ruleGroupFirewallDataResult: RowType[] = [];
      listAllRuleGroupFirewallData.map(firewall => {
        ruleGroupFirewallDataResult.push({
          id: firewall?.id,
          name: firewall?.name,
          type: firewall?.type,
          capacity: firewall?.capacity,
        });
      });

      setRuleGroups(ruleGroupFirewallDataResult);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetMainDataRows();
  }, []);

  const stepNode = useMemo(() => {
    switch (currentStepIndex) {
      case 0: {
        const { name = '', description = '', streamExceptionPolicy = '' } = firewallPolicy;
        return (
          <DescribeFirewallPolicyStep
            onValueChanged={handleValueChanged}
            name={name}
            description={description}
            streamExceptionPolicy={streamExceptionPolicy}
            errorMessage={errorMessage}
            setErrorMessage={() => setErrorMessage('')}
          />
        );
      }

      case 1: {
        const {
          ruleAction = '',
          ruleActionFullPackets = '',
          ruleActionFragmentedPackets = '',
          statefulRuleDropAction,
          statefulRuleEvaluationOrder,
          alertOptionAll,
          alertOptionEstablished,
          statelessRuleGroupCapacityUnitConsumed,
          statefulRuleGroupCapacityUnitConsumed,
        } = firewallPolicy;

        return (
          <AddRuleGroupsStep
            ruleAction={ruleAction}
            ruleActionFullPackets={ruleActionFullPackets}
            ruleActionFragmentedPackets={ruleActionFragmentedPackets}
            onValueChanged={handleValueChanged}
            isSameActionForAll={ruleAction === 'Same'}
            ruleGroups={ruleGroups}
            statelessRuleGroupList={statelessRuleGroupRows}
            setStatelessRuleGroupRows={setStatelessRuleGroupRows}
            statefulRuleGroupList={statefulRuleGroupRows}
            setStatefulRuleGroupRows={setStatefulRuleGroupRows}
            statefulRuleDropAction={statefulRuleDropAction}
            statefulRuleEvaluationOrder={statefulRuleEvaluationOrder}
            alertOptionAll={alertOptionAll}
            alertOptionEstablished={alertOptionEstablished}
            statelessRuleGroupCapacityUnitConsumed={statelessRuleGroupCapacityUnitConsumed}
            statefulRuleGroupCapacityUnitConsumed={statefulRuleGroupCapacityUnitConsumed}
          />
        );
      }

      case 2: {
        const { customizeEncryptionSettings, policyVariables, awsKmsKey } = firewallPolicy;
        return (
          <ConfigureAdvancedSettingsStep
            cloudId={cloudId}
            region={region}
            awsKmsKey={awsKmsKey}
            customizeEncryptionSettings={customizeEncryptionSettings}
            onValueChanged={handleValueChanged}
            policyVariables={policyVariables}
            errorMessage={errorMessage}
            setErrorMessage={() => setErrorMessage('')}
          />
        );
      }

      case 3: {
        const { tlsInspectionConfiguration } = firewallPolicy;
        return (
          <AddTLSInspectionConfigurationStep
            cloudId={cloudId}
            region={region}
            onValueChanged={handleValueChanged}
            tlsInspectionConfiguration={tlsInspectionConfiguration}
          />
        );
      }

      case 4: {
        const { tags } = firewallPolicy;
        return <AddTagsStep tags={tags} onValueChanged={handleValueChanged} />;
      }

      case 5: {
        return (
          <FirewallPoliciesPreviewCreation
            firewallPolicy={firewallPolicy}
            statelessRuleGroupRows={statelessRuleGroupRows}
            statefulRuleGroupRows={statefulRuleGroupRows}
          />
        );
      }

      default:
        return <></>;
    }
  }, [
    cloudId,
    region,
    currentStepIndex,
    firewallPolicy,
    handleValueChanged,
    ruleGroups,
    statelessRuleGroupRows,
    statefulRuleGroupRows,
    errorMessage,
  ]);

  const isDataValid = useCallback(
    (label: string, value: any, condition: DataValidationConditionType): boolean => {
      switch (condition) {
        case 'REQUIRED':
          switch (label) {
            case 'Name':
              if (!value) {
                setErrorMessage(`${label} is a required field`);
                return false;
              }
              break;

            case 'tags':
              const { tags }: any = firewallPolicy;

              const arr = tags.map((e: any) => ({
                ...e,
                errorMessage: e.key ? '' : 'Must enter a key.',
              }));

              const isValid = arr.some((e: any) => e.errorMessage);

              if (isValid) handleValueChanged('tags', arr);

              return isValid;

            case 'awsKmsKey':
              const { customizeEncryptionSettings, awsKmsKey }: any = firewallPolicy;

              if (customizeEncryptionSettings && !awsKmsKey?.value) {
                setErrorMessage('An AWS KMS key is required.');
              }

              return customizeEncryptionSettings && !awsKmsKey?.value;

            default:
              break;
          }
          break;

        default:
          break;
      }

      return true;
    },
    [firewallPolicy],
  );

  const handleCreateFirewallPolicy = async () => {
    if (createAwsFirewallPolicyLoading) return;

    try {
      const {
        name,
        description,
        streamExceptionPolicy,
        ruleAction,
        ruleActionFullPackets,
        ruleActionFragmentedPackets,
        statefulRuleEvaluationOrder,
        customizeEncryptionSettings,
        awsKmsKey,
        statefulRuleDropAction,
        alertOptionAll,
        alertOptionEstablished,
        policyVariables,
        tlsInspectionConfiguration,
        tags,
      }: any = firewallPolicy;

      const statefulDefaultActions = [statefulRuleDropAction];

      if (alertOptionAll) {
        statefulDefaultActions.push('aws:alert_strict');
      }

      if (alertOptionEstablished) {
        statefulDefaultActions.push('aws:alert_established');
      }

      const variables: ICreateAwsFirewallPolicyVariables = {
        cloudId,
        region,
        request: {
          firewallPolicyName: name,
          description,
          tags: tags?.length
            ? tags.map(({ key, value }: { key: string; value: string }) => ({
                key,
                value,
              }))
            : [],
          encryptionConfiguration: customizeEncryptionSettings
            ? {
                type: 'CUSTOMER_KMS',
                keyId: awsKmsKey?.value,
              }
            : {
                type: 'AWS_OWNED_KMS_KEY',
              },
          firewallPolicy: {
            statefulEngineOptions: { streamExceptionPolicy, ruleOrder: statefulRuleEvaluationOrder },
            statelessDefaultActions: [ruleActionFullPackets],
            statelessFragmentDefaultActions: ruleAction === 'Same' ? [] : [ruleActionFragmentedPackets],
            statelessRuleGroupReferences: statelessRuleGroupRows?.length
              ? statelessRuleGroupRows.map(({ id, priority }: { id: string; priority: string }) => ({
                  resourceArn: id,
                  priority: priority,
                }))
              : [],
            statefulRuleGroupReferences: statefulRuleGroupRows?.length
              ? statefulRuleGroupRows.map(({ id, priority }: { id: string; priority: string }) => ({
                  resourceArn: id,
                  priority: priority,
                }))
              : [],
            statefulDefaultActions: statefulRuleEvaluationOrder === RULE_ORDER[0].value ? statefulDefaultActions : [],
            tlsInspectionConfigurationArn: tlsInspectionConfiguration?.value,
            policyVariables: policyVariables ? { ruleVariables: { HOME_NET: { definition: [policyVariables] } } } : {},
          },
        },
      };

      const { data: responseData }: any = await createAwsFirewallPolicy({ variables });

      if (responseData?.createAwsFirewallPolicy?.result !== ErrorCode.SUCCESS) {
        useToast(ErrorCode.UNKNOWN, 'Create firewall policy failed.');
        return;
      }

      useToast(ErrorCode.SUCCESS, 'Create firewall policy successful.');
      pageBackClick();
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Create firewall policy failed.');
    }
  };

  const handleCancelButtonClicked = useCallback(() => {
    pageBackClick();
  }, []);

  const handlePreviousButtonClicked = useCallback(() => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
    setErrorMessage('');
  }, [currentStepIndex]);

  const handleNextButtonClicked = useCallback(() => {
    if (
      !isDataValid('Name', firewallPolicy.name, 'REQUIRED') ||
      isDataValid('awsKmsKey', firewallPolicy.awsKmsKey, 'REQUIRED') ||
      isDataValid('tags', firewallPolicy.tags, 'REQUIRED')
    ) {
      return;
    }

    if (currentStepIndex < MAXIMUM_STEP_COUNT) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      handleCreateFirewallPolicy();
    }
    setErrorMessage('');
  }, [currentStepIndex, firewallPolicy]);

  return (
    <div id="mgd-list-layout">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center" id="title">
          <NetworkTitle
            pageTitle={
              screenName === 'CREATION'
                ? FIREWALL_POLICY_CREATION.CREATE_FIREWALL_POLICY_TITLE
                : FIREWALL_POLICY_CREATION.CREATE_FIREWALL_POLICY_REVIEW_TITLE
            }
            id={321}
            name={'name'}
            hasPrefixIcon={false}
            hasFavorite={false}
            pageBackClick={pageBackClick}
          />
        </div>
      </div>

      <div className="one-page">
        <div className="flex j-between a-center">
          <div className="firewall-rule-group">
            {screenName === 'CREATION' && stepNode}
            <BottomActionButtonGroup
              onCancelButtonClicked={handleCancelButtonClicked}
              onNextButtonClicked={handleNextButtonClicked}
              onPreviousButtonClicked={handlePreviousButtonClicked}
              previousButtonVisible={currentStepIndex !== 0}
              isCreate={currentStepIndex === MAXIMUM_STEP_COUNT}
              errorMessage={errorMessage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirewallPolicyCreation;
