import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { AwsGetGroupResponse } from 'graphql/types/AwsGroup';
import lazyGetAwsGroup, { IGetAwsGroupVariables } from 'graphql/queries/getAwsGroup';
import PageDetailTitle from '../../../components/PageDetailTitle';
import { ResourceGroupDetailPropsType } from './types';
import lazyGetAwsGroupQuery, { IGetAwsGroupQueryVariables } from 'graphql/queries/getAwsGroupQuery';
import DetailTab from '../../../components/DetailTab';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdTabLayout/types';
import { TextTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import { AwsGetGroupQueryResponse } from 'graphql/types/AwsGroupQuery';
import MatchingResource from './components/MatchingResource';
import Tag from './components/Tag';
import UpdateTagsModal from './Modals/UpdateTagsModal';
import Button, { ButtonTypeEnum } from '../../../components/Button';
import EditResourceGroupDetails from './Modals/EditResourceGroupDetails';
import updateFirewallRuleGroupMutation, {
  AwsUpdateFirewallRuleGroupVariablesType,
} from 'graphql/mutations/updateFirewallRuleGroup';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import EditGroupingCriteria from './Modals/EditGroupingCriteria';
import editAwsTagMutation from 'graphql/mutations/editAwsTag';
import untagAwsMutation, { IAwsUntagRequestVariables } from 'graphql/mutations/unAwsTag';
import { TagRowDataType } from '../types';
import lazyGetAwsTags, { IAwsGetTagsVariables } from 'graphql/queries/getAwsTags';
import { RowType } from '@Types/v2/Table';
import DeleteResource from './Modals/DeleteResource';
import deleteFirewallRuleGroupMutation from 'graphql/mutations/deleteFirewallRuleGroup';
import updateAwsGroupQueryMutation, { UpdateAwsGroupQueryVariablesType } from 'graphql/mutations/updateAwsGroupQuery';

const ResourceGroupDetail = (props: ResourceGroupDetailPropsType) => {
  const { resourceGroup, cloudId, region, pageBackClick } = props;

  // API
  const [getAwsGroup, { loading: getAwsGroupLoading }] = lazyGetAwsGroup();
  const [getAwsGroupQuery, { loading: getAwsGroupQueryLoading }] = lazyGetAwsGroupQuery();
  const [updateAwsGroup, { loading: updateAwsGroupLoading }] = updateFirewallRuleGroupMutation();
  const [editAwsTag, { loading: editAwsTagLoading }] = editAwsTagMutation();
  const [unAwsTag, { loading: unAwsTagLoading }] = untagAwsMutation();
  const [getAwsTags, { loading: getTagLoading }] = lazyGetAwsTags();
  const [deleteAwsGroup, { loading: deleteAwsGroupLoading }] = deleteFirewallRuleGroupMutation();
  const [updateAwsGroupQuery, { loading: updateAwsGroupQueryLoading }] = updateAwsGroupQueryMutation();

  // State
  const [awsGroupData, setAwsGroupData] = useState<AwsGetGroupResponse>();
  const [awsGroupQueryData, setAwsGroupQueryData] = useState<AwsGetGroupQueryResponse>();
  const [isOpenEditTag, setIsOpenEditTag] = useState<boolean>(false);
  const [isOpenEditResourceDetail, setIsOpenEditResourceDetail] = useState<boolean>(false);
  const [isOpenEditCriteria, setIsOpenEditCriteria] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [tagRows, setTagRows] = useState<RowType[]>([]);

  const groupData = useMemo((): IMgdDetailKeyValueProps[] => {
    const awsGroup = awsGroupData?.group;

    return [
      { title: 'Name', description: awsGroup?.name ?? '-', type: TextTypeEnum.NORMAL },
      { title: 'Description', description: awsGroup?.description ?? '-', type: TextTypeEnum.NORMAL },
      { title: 'Group ARN', description: awsGroup?.groupArn ?? '-', type: TextTypeEnum.COPY },
    ];
  }, [awsGroupData]);

  const groupQueryData = useMemo((): IMgdDetailKeyValueProps[] => {
    if (awsGroupQueryData) {
      const awsGroupQuery = JSON.parse(awsGroupQueryData?.groupQuery?.resourceQuery?.query ?? '');
      const resourceTypeFilters = awsGroupQuery?.ResourceTypeFilters?.map((filter: string) => {
        return filter;
      });
      const tagFilters = awsGroupQuery?.TagFilters?.map((tag: any) => {
        return `${tag?.Key}:${tag?.Values.join(',')}`;
      });

      return [
        { title: 'Resource types', description: resourceTypeFilters ?? '-', type: TextTypeEnum.NORMAL },
        { title: 'Tags', description: tagFilters ?? '-', type: TextTypeEnum.NORMAL },
      ];
    }

    return [];
  }, [awsGroupQueryData]);

  const creationApiLoading = useMemo((): boolean => {
    return updateAwsGroupLoading || editAwsTagLoading || unAwsTagLoading || updateAwsGroupQueryLoading;
  }, [updateAwsGroupLoading, editAwsTagLoading, unAwsTagLoading, updateAwsGroupQueryLoading]);

  const fetchAwsGroupData = useCallback(async () => {
    const reqVariable: IGetAwsGroupVariables = {
      cloudId,
      region,
      request: { groupName: resourceGroup.groupName },
    };
    const response = await getAwsGroup({ variables: reqVariable }).then(({ data: response }) => {
      return response?.getAwsGroup?.data?.[0];
    });

    if (response) {
      setAwsGroupData(response);
    }
  }, [cloudId, region, resourceGroup]);

  const reqVariable = useMemo((): IAwsGetTagsVariables => {
    return { cloudId, region, request: { arn: resourceGroup.groupArn } };
  }, [cloudId, region, resourceGroup]);

  const fetchAwsTags = useCallback(async () => {
    const awsTags = await getAwsTags({
      variables: reqVariable,
    }).then(({ data: tags }) => {
      return tags?.getAwsTags?.data?.[0];
    });
    if (awsTags) {
      const tagsSectionContentRows: RowType[] = [];
      Object.entries(awsTags?.tags).map(([key, value]) => {
        tagsSectionContentRows.push({
          key: key,
          value: value,
        });
      });
      setTagRows(tagsSectionContentRows);
    }
  }, [reqVariable]);

  const fetchAwsGroupQueryData = useCallback(async () => {
    const reqVariable: IGetAwsGroupQueryVariables = {
      cloudId,
      region,
      request: { groupName: resourceGroup.groupName },
    };
    const response = await getAwsGroupQuery({ variables: reqVariable }).then(({ data: response }) => {
      return response?.getAwsGroupQuery?.data?.[0];
    });

    if (response) {
      setAwsGroupQueryData(response);
    }
  }, [cloudId, region, resourceGroup]);

  const renderAction = (setIsOpen: (value: boolean) => void) => {
    return <Button label="Edit" type={ButtonTypeEnum.GENERAL} onClick={() => setIsOpen(true)} />;
  };

  const handleUpdateGroupDetail = async (desc: string) => {
    try {
      const variables: AwsUpdateFirewallRuleGroupVariablesType = {
        cloudId,
        region,
        request: {
          description: desc,
          groupName: awsGroupData?.group.name,
        },
      };
      await updateAwsGroup({ variables });
      useToast(ErrorCode.SUCCESS, 'Update details of resource group success.');
      await fetchAwsGroupData();

      return true;
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Update details of resource group failed.');

      return false;
    }
  };

  const handleUpdateGroupCriteria = async (query: string) => {
    try {
      const variables: UpdateAwsGroupQueryVariablesType = {
        cloudId,
        region,
        request: {
          resourceQuery: {
            type: 'TAG_FILTERS_1_0',
            query: query,
          },
          groupName: awsGroupData?.group.name || '',
        },
      };
      await updateAwsGroupQuery({ variables });
      useToast(ErrorCode.SUCCESS, 'Update details of resource criteria success.');
      await fetchAwsGroupQueryData();

      return true;
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Update details of resource criteria failed.');

      return false;
    }
  };

  const handleUntag = async (datasDel: string[]) => {
    if (datasDel?.length === 0) return;
    try {
      const variables: IAwsUntagRequestVariables = {
        cloudId,
        region,
        request: {
          arn: awsGroupData?.group?.groupArn || '',
          keys: datasDel,
        },
      };
      await unAwsTag({
        variables,
      });
    } catch (error) {
      /* empty */
    }
  };

  const handleEditTag = async (datasUpdate: JSON) => {
    try {
      const variables = {
        cloudId,
        region,
        request: {
          arn: awsGroupData?.group?.groupArn || '',
          tags: datasUpdate,
        },
      };
      await editAwsTag({
        variables,
      });
    } catch (error) {
      /* empty */
    }
  };

  const handleSaveTagsDetail = async (oldTags: TagRowDataType[], newTags: TagRowDataType[]) => {
    try {
      const dataDelete: string[] = [];
      const dataUpdate: any = {};
      oldTags.forEach(tag => {
        if (tag.flagDel) {
          dataDelete.push(tag.key);
        } else {
          dataUpdate[tag.key] = tag.value;
        }
      });
      newTags.forEach(tag => (dataUpdate[tag.key] = tag.value));

      await handleUntag(dataDelete);
      await handleEditTag(dataUpdate);
      useToast(ErrorCode.SUCCESS, 'Update tags success.');
      await fetchAwsTags();

      return true;
    } catch (e) {
      useToast(ErrorCode.UNKNOWN, 'Update tags failed.');

      return false;
    }
  };

  const hanldeDeleteResources = async () => {
    try {
      const variables = {
        cloudId,
        region,
        request: {
          group: awsGroupData?.group?.groupArn,
        },
      };
      await deleteAwsGroup({
        variables,
      });
      useToast(ErrorCode.SUCCESS, 'Delete resource group success.');
      pageBackClick();
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Delete resource group failed.');
    }
  };

  useEffect(() => {
    fetchAwsGroupData();
    fetchAwsGroupQueryData();
    fetchAwsTags();
  }, []);

  return (
    <Fragment>
      <div className="page-detail">
        <PageDetailTitle
          title={resourceGroup?.groupName}
          pageBackClick={pageBackClick}
          isDelete
          onDelete={() => setIsOpenDelete(true)}
        />

        <DetailTab
          title="Details"
          data={groupData}
          isApiLoading={getAwsGroupLoading}
          actions={renderAction(setIsOpenEditResourceDetail)}
        />

        {awsGroupQueryData && (
          <DetailTab
            title="Grouping criteria"
            data={groupQueryData}
            isApiLoading={getAwsGroupQueryLoading}
            actions={renderAction(setIsOpenEditCriteria)}
          />
        )}

        <MatchingResource cloudId={cloudId} region={region} resourceGroup={resourceGroup} />

        <Tag
          cloudId={cloudId}
          region={region}
          resourceGroup={resourceGroup}
          onEditClicked={() => setIsOpenEditTag(!isOpenEditTag)}
          tagRows={tagRows}
          loading={getTagLoading}
        />
      </div>
      {isOpenEditResourceDetail && (
        <EditResourceGroupDetails
          header={'Edit resource group details'}
          description={'Edit resource group.'}
          groupData={awsGroupData?.group}
          handleUpdateGroupDetail={handleUpdateGroupDetail}
          onClose={() => setIsOpenEditResourceDetail(false)}
          loading={creationApiLoading}
          open={isOpenEditResourceDetail}
        />
      )}
      {isOpenEditCriteria && (
        <EditGroupingCriteria
          header={'Edit grouping criteria'}
          cloudId={cloudId}
          region={region}
          resourceGroup={JSON.parse(awsGroupQueryData?.groupQuery?.resourceQuery?.query ?? '')}
          handleUpdateGroupDetail={handleUpdateGroupCriteria}
          onClose={() => setIsOpenEditCriteria(false)}
          loading={creationApiLoading}
          open={isOpenEditCriteria}
        />
      )}
      {isOpenEditTag && (
        <UpdateTagsModal
          header={'Manage resource group tags'}
          currentDatas={tagRows}
          columns={['Key', 'Value-option']}
          note={'You can add up to 48 more tags.'}
          open={isOpenEditTag}
          onClose={() => setIsOpenEditTag(false)}
          onSave={handleSaveTagsDetail}
          loadingTagsCreat={creationApiLoading}
        />
      )}
      {isOpenDelete && (
        <DeleteResource
          header={'Delete resource'}
          titleHeader={awsGroupData?.group.name}
          titleWarning={'Proceeding with this action will delete resource with all content and can impact related resources.'}
          subWarning={'To avoid accidental deletions, we ask you to provide additional written consent.'}
          onDelete={hanldeDeleteResources}
          loading={deleteAwsGroupLoading}
          open={isOpenDelete}
          onClose={() => setIsOpenDelete(false)}
        />
      )}
    </Fragment>
  );
};

export default ResourceGroupDetail;
