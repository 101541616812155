import { gql } from '@apollo/client';

const query = gql`
mutation updateAwsTLSInspectionConfiguration($reqData: AwsUpdateTlsInspectionConfigurationRequest, $cloudId: Long, $region: String) {
  updateAwsTLSInspectionConfiguration(
    awsUpdateTlsInspectionConfigurationRequest: $reqData
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsUpdateTlsInspectionConfigurationResponse {
        updateToken
        tlsInspectionConfigurationResponse {
          tlsInspectionConfigurationArn
          tlsInspectionConfigurationName
          tlsInspectionConfigurationId
          tlsInspectionConfigurationStatus
          description
          tags {
            key
            value
          }
          lastModifiedTime
          numberOfAssociations
          encryptionConfiguration {
            keyId
            type
          }
          certificates {
            certificateArn
            certificateSerial
            status
            statusMessage
          }
          certificateAuthority {
            certificateArn
            certificateSerial
            status
            statusMessage
          }
        }
      }
    }
  }
}
`;
export default query;
