import { gql } from '@apollo/client';

const query = gql`
  mutation updateAwsAllowList($request: AwsUpdateAllowListRequest, $cloudId: Long, $region: String) {
    updateAwsAllowList(awsUpdateAllowListRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsUpdateAllowListResponse {
          arn
          id
        }
      }
    }
  }
`;
export default query;
