import React, { ReactNode, useCallback, useState } from 'react';
import Icon from 'components/v2/atoms/Icon';
import UpIcon from 'assets/svgs/v3/ico_move_up.svg';
import './index.scss';

export type CollapsibleContainerPropsType = {
  title?: string;
  description?: string;
  children?: Array<ReactNode> | ReactNode;
}

const CollapsibleContainer = (props: CollapsibleContainerPropsType) => {
  const { title, description, children } = props;

  const [isCollapse, setIsCollapse] = useState<boolean>(true);

  const onClicked = useCallback(() => {
    setIsCollapse(!isCollapse)
  }, [isCollapse]);

  return (
    <div className="collapsible-container">
      <div className="collapsible-header">
        <Icon className={`collapsible-header-button ${!isCollapse && 'expanded'}`} width={12} height={12} src={UpIcon} onClick={onClicked} />
        <div className="collapsible-header-container">
          <p className="collapsible-header-container-title">{title}</p>
          {description && <p className="collapsible-header-container-description">{description}</p>}
        </div>
        
      </div>
      {!isCollapse &&
        children
      }
    </div>
  );
};

export default CollapsibleContainer;
