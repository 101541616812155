import { useEffect, useMemo, useState } from 'react';
import { OrderDirection, RowType } from '@Types/v2/Table';
import { useOutletContext, useParams } from 'react-router-dom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { enumFormatter } from 'utils/Formatter';
import ResourceGrid from './components/ResourceGrid';
import ResourceDetail from './components/ResourceDetail';
import { IGetResourcePageByParamVariables, lazyGetResourcePageByParam } from 'graphql/queries/getResourcePageByParam';
import { ErrorCode } from '@Types/error';
import { ProjectInfo } from 'graphql/types/ProjectInfo';

const Resource = () => {
  const { organizationId } = useOutletContext<{ projectList: ProjectInfo[], organizationId: number}>();

  const [isLoading, setIsSLoading] = useState(false);
  const [isShowing, setIsShowing] = useState<'DATA_GRID' | 'DETAIL'>('DATA_GRID');
  const [search, setSearch] = useState({
    relObjectType: 'all',
    searchText: '',
  });
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0,
  });
  const [rows, setRows] = useState<RowType[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [selected, setSelected] = useState<string>('');
  /* 리소스 종류 리스트 */
  const [relObjTypeList, setRelObjTypeList] = useState<{ name: string; value: string }[]>([]);
  /* 파일 형식 리스트 */
  const [resFormatCodeTypeList, setResForamtCodeTypeList] = useState<{ name: string; value: string }[]>([]);
  
  /* 리소스 조회 */
  const [getResourcePageByParam] = lazyGetResourcePageByParam(); /* ResourcePageByParam */

  /* 리포트 종류 조회 */
  const [getResRelObjectType] = lazyGetEnumTypeCode(); /* ResRelObjectType */
  /* 파일 형식 조회 */
  const [getResFormatCode] = lazyGetEnumTypeCode(); /* getResFormatCode */

  const getResourceData:IGetResourcePageByParamVariables = useMemo(
    () => ({
      reqData: {
        organId: organizationId ? Number(organizationId) : undefined,
        relObjectType: search.relObjectType === 'all' ? undefined : search.relObjectType,
        text: search?.searchText || undefined,
        pageInfo: {
          page: tablePagination.currentPage - 1,
          size: tablePagination.limit,
          orders: [
            {
              target: tablePagination.target === 'modifiedByName' ? 'modifiedBy' : tablePagination.target,
              direction: tablePagination.direction,
            },
          ],
        },
      },
    }),
    [organizationId, JSON.stringify(tablePagination), JSON.stringify(search), isShowing],
  );

  const renderShowing = useMemo(() => {
    switch (isShowing) {
      default:
        return (
          <ResourceGrid
            relObjTypeList={relObjTypeList}
            onSubmitSearch={(readyToSearch: { relObjectType: string; searchText: string }) => {
              setSearch({ ...readyToSearch });
              setTablePagination(prev => ({
                ...prev,
                currentPage: 1,
              }));
            }}
            tablePagination={tablePagination}
            updateTablePagination={(key: string, value: number | string | OrderDirection) =>
              setTablePagination(prev => ({
                ...prev,
                [key]: value,
              }))
            }
            total={total}
            rows={rows}
            reportSelected={(id: string) => {
              setSelected(id);
              setIsShowing('DETAIL');
            }}
            isLoading={isLoading}
            resFormatCodeTypeList={resFormatCodeTypeList}
          />
        );
      case 'DETAIL':
        return (
          <ResourceDetail
            resFormatCodeTypeList={resFormatCodeTypeList}
            data={rows.find(val => val.id === Number(selected)) as RowType}
            convertGridIsShowing={() => {
              setSelected('');
              setIsShowing('DATA_GRID');
            }}
          />
        );
    }
  }, [tablePagination, isShowing, rows, total, selected, relObjTypeList, resFormatCodeTypeList, isLoading]);

  const getResourceRows = async () => {
    try {
      setIsSLoading(true);
      const { data } = await getResourcePageByParam({
        variables: getResourceData,
      });
      setIsSLoading(false);

      if (data?.getResourcePageByParam?.result !== ErrorCode.SUCCESS) return;

      setRows(data?.getResourcePageByParam?.content || []);
      setTotal({
        totalPage: data.getResourcePageByParam?.totalPages || 0,
        totalElement: data.getResourcePageByParam?.totalElements || 0,
      });
    } catch (error) {
      setIsSLoading(false);
    }
  };

  /* 사용자 정보 rows 받기 */
  useEffect(() => {
    getResourceRows();
  }, [getResourceData]);

  useEffect(() => {
    /* 리소스 종류 리스트 */
    getResRelObjectType({ variables: { text: 'ResRelObjectType' } }).then(({ data }) => {
      if (data) {
        setRelObjTypeList(
          data.getEnumTypeCode.data.map(val => ({
            name: String(enumFormatter(val.value)),
            value: val.value,
          })),
        );
      }
    });

    /* 파일형식 리스트 */
    getResFormatCode({ variables: { text: 'ResFormatCode' } }).then(({ data }) => {
      if (data) {
        setResForamtCodeTypeList(
          data.getEnumTypeCode.data.map(val => ({
            name: String(enumFormatter(val.value)),
            value: val.value,
          })),
        );
      }
    });
  }, []);

  return <div className="resource">{renderShowing}</div>;
};
export default Resource;
