import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IAwsUntagResourceRequestVariables {
  cloudId: number;
  region: string;
  request: {
    resourceArn: string;
    tagKeys: string[];
  };
}

export interface IUntagResourceResponseData {
  untagAwsResource: IGqlResponseData<undefined>;
}

const untagAwsResourcesMutation = () =>
  useMutation<IUntagResourceResponseData, IAwsUntagResourceRequestVariables>(query);
export default untagAwsResourcesMutation;
