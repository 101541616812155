import './index.scss';
import LikeIcon from 'assets/svgs/v3/ico_like.svg';
import DislikeIcon from 'assets/svgs/v3/ico_dislike.svg';
import LikeIconActive from 'assets/svgs/v4/ico_like_active.svg';
import DislikeIconActive from 'assets/svgs/v4/ico_dislike_active.svg';
import CopyIcon from 'assets/svgs/v3/ico_copy.svg';

import Icon from 'components/v2/atoms/Icon';
import apis from 'apis/v4';
import { useAuth } from 'contexts/AuthProvider';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import { getFormatedDateWithTimezone } from 'utils/Common';
import MarkdownPreview from '@uiw/react-markdown-preview';

interface IMessageProps {
  id: number | string;
  isLeft: boolean;
  message: string;
  liked?: boolean;
  created_at: string;
  hasAction: boolean;
  refetchLikeState: () => void;
}

const Message = ({id, isLeft, message, liked, created_at, hasAction, refetchLikeState}: IMessageProps) => {
  const {token} = useAuth();
  const onClickCopy = (msg: string) => {
    navigator.clipboard.writeText(msg).then(() => {
      useToast(ErrorCode.SUCCESS, 'Copied.');
    }).catch(() => {
      useToast(ErrorCode.SUCCESS, 'Copy to clipboard failed.');
    });
  }
  const onClickLike = () => {
    if(typeof id === 'number') {
      apis.Chatbot.updateMessages({
        id: id,
        like: true
      }, token).then(res => {
        if(res.data.result === 'OK') {
          localStorage.setItem('chatbot', JSON.stringify({id: id, state: true}));
          refetchLikeState();
        } else {
          console.log('like chatbot false', res.data);
          // useToast(ErrorCode.UNKNOWN, 'Like chatbot message failed.');
        }
      }).catch((e) => {
        console.log('like chatbot error', e);
        // useToast(ErrorCode.UNKNOWN, 'Like chatbot message failed.');
      });
    }
  }
  const onClickDislike = () => {
    if(typeof id === 'number') {
      apis.Chatbot.updateMessages({
        id: id,
        like: false
      }, token).then(res => {
        if(res.data.result === 'OK') {
          localStorage.setItem('chatbot', JSON.stringify({id: id, state: false}));
          refetchLikeState();
        } else {
          console.log('dislike chatbot false', res.data);
          // useToast(ErrorCode.UNKNOWN, 'Dislike chatbot message failed.');
        }
      }).catch((e) => {
        console.log('dislike chatbot error', e);
        // useToast(ErrorCode.UNKNOWN, 'Dislike chatbot message failed.');
      });
    }
  }
  return (
    <>
      <div id={id.toString()} className={isLeft ? 'chatbot-message left-message' : 'chatbot-message right-message'}>
        <div className="content">
          <div className="message-text">
            {message !== '' && <MarkdownPreview source={message}/>}
          </div>
        </div>
        <div className={`tooltip flex ${hasAction ? 'j-between' : 'j-end'}`}>
          {hasAction && 
            <div className="action">
              <Icon width={14} height={14} src={CopyIcon} onClick={() => onClickCopy(message)}/>
              <Icon width={14} height={14} src={liked ? LikeIconActive : LikeIcon} onClick={onClickLike} />
              <Icon width={14} height={14} src={(liked !== undefined && liked === false) ? DislikeIconActive : DislikeIcon} onClick={onClickDislike} />
            </div>
          }
          <p className="msg-date-time-text">{getFormatedDateWithTimezone(created_at, 'HH:mm').substring(0, 5)}</p>
        </div>
      </div>
    </>
  );
};

export default Message;
