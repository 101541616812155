import { useMutation } from '@apollo/client';
import { IGqlResponseNewData } from 'graphql/types';
import query from './query';

export enum AwsSeverityLevelInputEnum {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export type AwsSeverityLevelInput = {
  occurrencesThreshold: number;
  severity: AwsSeverityLevelInputEnum;
};

export type CreateAwsCustomDataIdentifierVariablesRequest = {
  name: string;
  description?: string;
  regex: string;
  keywords?: string[];
  ignoreWords?: string[];
  maximumMatchDistance?: number;
  severityLevels?: AwsSeverityLevelInput[];
  tags?: Record<string, string>;
};

export interface ICreateAwsCustomDataIdentifierVariables {
  cloudId: number;
  region: string;
  request: CreateAwsCustomDataIdentifierVariablesRequest;
}

export type CreateAwsCustomDataIdentifierResponse = {
  customDataIdentifierId: string;
};

export interface ICreateAwsCustomDataIdentifierResponseData {
  createAwsCustomDataIdentifier: IGqlResponseNewData<CreateAwsCustomDataIdentifierResponse>;
}

const lazyCreateAwsCustomDataIdentifierMutation = () =>
  useMutation<ICreateAwsCustomDataIdentifierResponseData, ICreateAwsCustomDataIdentifierVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyCreateAwsCustomDataIdentifierMutation;
