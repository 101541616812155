import { useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import { getStrValueOrNull, handleFormatText } from 'utils/Common';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { FirewallConfigurationSyncStateMapping } from '../../configs';

const ConfigurationSyncState = ({ data}: any) => {
  const { firewallStatus } = data || {};

  const [isLoading] = useState(false);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  useEffect(() => {
    const availabilityZone: any = [];
    Object.keys(firewallStatus?.syncStates).forEach(e => {
      const configs = firewallStatus?.syncStates[e]?.config;
      if (configs) {
        Object.keys(configs).forEach(key => {
          const config = firewallStatus?.syncStates[e]?.config[key];
          const configKeySlashParts = key.split('/');
          const resourceName = configKeySlashParts[configKeySlashParts.length - 1];
          const configKeyColonParts = configKeySlashParts[configKeySlashParts.length - 2].split(':');
          availabilityZone.push({
            resourceName: resourceName,
            resourceType: configKeyColonParts.pop(),
            alilabilityZone: e,
            syncState: getStrValueOrNull(config?.syncStatus),
          });
        });
      }
    });

    setMainTblRows(availabilityZone);
    setMainTblTotal({
      totalPage: Math.ceil(availabilityZone.length / 10),
      totalElement: availabilityZone.length,
    });
  }, []);

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Resource name',
        field: 'resourceName',
        sort: true,
      },
      {
        label: 'Resource type',
        field: 'resourceType',
        sort: true,
      },
      {
        label: 'Availability Zone',
        field: 'alilabilityZone',
        sort: true,
      },
      {
        label: 'Sync state',
        field: 'syncStateMapping',
        sort: true,
        renderCell: (row: RowType) => (
          <p className={`${FirewallConfigurationSyncStateMapping[row?.syncState]?.style}`}>
            {handleFormatText(FirewallConfigurationSyncStateMapping[row?.syncState]?.text)}
          </p>
        ),
      },
    ];
  }, []);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotal, tablePagination]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>Configuration sync state</p>
      </div>

      {isLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainRowsCurrentPage.length && !isLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <Table
                rows={mainRowsCurrentPage}
                columns={columns}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                isLoading={isLoading}
                horizontalScrollable={true}
              />

              {mainRowsCurrentPage?.length && !isLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>

                  <TableManagePagination
                    ableFetchMore={false}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page => updateTablePagination('currentPage', page)}
                    totalPage={mainTblTotal.totalPage}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ConfigurationSyncState;
