import BaseModal from "components/v2/modals/BaseModal";
import Icon from 'components/v2/atoms/Icon';
import IconDelete from 'assets/svgs/v3/ico_delete_network.svg';
import LabelInput from 'components/v2/LabelInput';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import Button, { ButtonTypeEnum } from "pages/v2/Organ/Management/components/Button";
import deleteAwsTLSInspectionConfigurationMutation, { DeleteTlsInspectionConfigurationVariables } from "graphql/mutations/deleteAwsTLSInspectionConfiguration";
import { DeleteTlsInspectionPropsType } from "../types";
import { useCallback, useState } from "react";
import { ErrorCode } from "@Types/error";
import { useToast } from 'hooks/v2/useToast';
import './index.scss';

const DeleteTlsInspection = (props: DeleteTlsInspectionPropsType) => {
  const { cloudId, region, tlsInspectionDetail, onCancel, onDeleted, ...baseModalProps } = props;

  const [deleteAwsTLSInspectionConfiguration, {loading: deleteResourceLoading}] = deleteAwsTLSInspectionConfigurationMutation();

  const [descriptionDelete, setDescriptionDelete] = useState<string>('');

  const onDeleteHandler = useCallback(() => {
    const {tlsInspectionConfigurationArn, tlsInspectionConfigurationName} = tlsInspectionDetail?.tlsInspectionConfigurationResponse;
    const requestData: DeleteTlsInspectionConfigurationVariables = {
      cloudId, region, reqData: {tlsInspectionConfigurationArn, tlsInspectionConfigurationName}
    };
    deleteAwsTLSInspectionConfiguration({variables: requestData}).then(({data: response}) => {
      const result = response?.deleteAwsTLSInspectionConfiguration?.result ?? '';
      if (result === ErrorCode.SUCCESS) {
        useToast(ErrorCode.SUCCESS, 'Delete TLS Inspection Configuration successfully.');
        onDeleted();
      } else {
        useToast(ErrorCode.SUCCESS, 'Delete TLS Inspection Configuration failed.');
      }
    });
  }, [cloudId, region, tlsInspectionDetail]);
  
  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconDelete} />
          {'Delete resource'}
        </>
      )}
      {...baseModalProps}
    >
      <div className="delete-resource-modal">
        <div className="delete-resource">

          <div className="delete-warning">
            <Icon width={32} height={32} src={IconWarning} />
            <div className="content">
              <p className="title-warning">{`Are you sure you want to delete the TLS inspection configuration ${tlsInspectionDetail?.tlsInspectionConfigurationResponse?.tlsInspectionConfigurationName}?`}</p>
              <p className="note">{'This will delete the selected TLS inspection configuration. Deleting the TLS inspection configuration cannot be undone.'}</p>
            </div>
          </div>
        </div>
        <div className="delete-resource-reason">
          <LabelInput
            title='To confirm deletion, type "delete" in the field.'
            required
            value={descriptionDelete}
            placeholder={'delete'}
            onChangeValue={val => setDescriptionDelete(val)}
          />
        </div>

        <div className="button-group">
          <Button label="Cancel" onClick={onCancel} />
          <Button
            label="Delete"
            disabled={descriptionDelete !== 'delete'}
            type={ButtonTypeEnum.PRIMARY}
            onClick={onDeleteHandler}
            loading={deleteResourceLoading}
          />
        </div>
      </div>
    </BaseModal>
  );
}

export default DeleteTlsInspection;