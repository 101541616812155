import axios from 'axios';
import Chatbot from './Chatbot';

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  baseURL: process.env.REACT_APP_NEMO_CHATBOT_API
});

const apis = { 
  Chatbot: Chatbot(instance)
};

export default apis;