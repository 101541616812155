import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeBucketsResponse } from 'graphql/types/AwsBuckets';

export interface IGetAwsDescribeBucketsVariables {
  cloudId: number;
  region: string;
  reqData: {
    maxResults?: number;
    criteria?: string;
  };
}

export interface IGetAwsDescribeBucketsResponseData {
  getAwsDescribeBuckets: IGqlResponseNewData<AwsDescribeBucketsResponse>;
}

const lazyGetAwsDescribeBuckets = () =>
  useLazyQuery<IGetAwsDescribeBucketsResponseData, IGetAwsDescribeBucketsVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeBuckets;
