import { gql } from '@apollo/client';

const query = gql`
  mutation updateAwsSubnetChangeProtection($request: AwsUpdateSubnetChangeProtectionRequest, $cloudId: Long, $region: String) {
    updateAwsSubnetChangeProtection(
      updateSubnetChangeProtectionRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsUpdateSubnetChangeProtectionResponse {
          firewallArn
          firewallName
          subnetChangeProtection
          updateToken
        }
      }
    }
  }
`;
export default query;
