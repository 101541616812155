import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { AwsTagType } from 'graphql/types/AwsVpc';

export interface ITagAwsResourceVariables {
  cloudId: number;
  region: string;
  request: {
    resourceArn: string;
    tags: AwsTagType[];
  };
}

export interface ITagAwsResourceResponseData {
  tagAwsResource: IGqlResponseData<undefined>;
}

const tagAwsResourcesMutation = () =>
  useMutation<ITagAwsResourceResponseData, ITagAwsResourceVariables>(query);
export default tagAwsResourcesMutation;
