import ButtonGroup from 'pages/v2/Organ/Management/components/ButtonGroup';
import { ButtonPropsType } from 'pages/v2/Organ/Management/components/types';

type JsonViewerProps = {
  data: any;
  title?: string;
  isJsonOnly?: boolean;
  rightButtons?: Array<ButtonPropsType>;
};

const JsonViewer = (props: JsonViewerProps) => {
  const { data, title, isJsonOnly, rightButtons } = props;

  if (isJsonOnly) {
    return (
      <div className="detail-block-container">
        <div className="content-container-json-viewer">
          <pre>{JSON.stringify(data, null, 4)}</pre>
        </div>
      </div>
    );
  }

  return (
    <div className="detail-block-container">
      <div className="header-container">
        <div className="group-title">
          <div className="title">{title}</div>
        </div>
        {rightButtons && rightButtons?.length > 0 && <ButtonGroup buttons={rightButtons} />}
      </div>
      <div className="content-container-json-viewer">
        <pre>{JSON.stringify(data, null, 4)}</pre>
      </div>
    </div>
  );
};

export default JsonViewer;
