import { useMemo } from 'react';
import Rules from '../../components/Rules';
import { ICreationDataPropsType } from '../type';
import DomainListRule from './DomainListRule';
import StandardStatefulRules from './StandardStatefulRules';
import Stateless from './Stateless';
import SuricataCompatibleRule from './SuricataCompatibleRule';
import { statefulRuleColumn, statelessRuleColumn } from '../../configs';

interface IConfigureRulesProps {
  title: string;
  subTitle: string;
  creationData: ICreationDataPropsType;
  updateCreationData: (data: ICreationDataPropsType) => void;
}

const ConfigureRules = ({ title, subTitle, creationData, updateCreationData }: IConfigureRulesProps) => {
  const { ruleGroupType } = creationData;
  const configureRulesNode = useMemo(() => {
    if (ruleGroupType === 'stateless') {
      return <Stateless creationData={creationData} updateCreationData={updateCreationData} />;
    }
    switch (creationData.ruleGroupFormat?.id) {
      default:
      case 1:
        return <StandardStatefulRules creationData={creationData} updateCreationData={updateCreationData} />;
      case 2:
        return <DomainListRule creationData={creationData} updateCreationData={updateCreationData} />;
      case 3:
        return <SuricataCompatibleRule creationData={creationData} updateCreationData={updateCreationData} />;
    }
  }, [creationData, ruleGroupType, updateCreationData]);
  const ruleNode = useMemo(() => {
    if (ruleGroupType === 'stateless') {
      return (
        <Rules creationData={creationData} updateCreationData={updateCreationData} columns={statelessRuleColumn} />
      );
    }
    switch (creationData.ruleGroupFormat?.id) {
      default:
        return null;
      case 1:
        return (
          <Rules creationData={creationData} updateCreationData={updateCreationData} columns={statefulRuleColumn} />
        );
    }
  }, [creationData, ruleGroupType, updateCreationData]);

  return (
    <>
      <div className="rule-group-container">
        <div className="title-group">
          <p className="title">{title}</p>
          <p className="sub-title">{subTitle}</p>
        </div>
        {configureRulesNode}
      </div>
      {ruleNode}
    </>
  );
};

export default ConfigureRules;
