type AwsArnData = {
  id: string;
  service?: string;
  region?: string;
  accountId?: string;
  resourceType?: string;
  resourceName?: string;
}

export const extractCertificateArnData = (arn:string) => {
  // Split the ARN string
  const arnComponents = arn.split(':');

  // Extract specific parts
  const service = arnComponents[2];
  const region = arnComponents[3];
  const accountId = arnComponents[4];
  const resourceData = arnComponents[5].split('/'); // Split resource type and name

  const resourceType = resourceData[0];
  const resourceId = resourceData[1];

  const arnData: AwsArnData = {
    id: resourceId,
    service: service,
    region: region,
    accountId: accountId,
    resourceType: resourceType
  }
  return arnData;
}

export const extractResourceArnData = (arn:string) => {
  // Split the ARN string
  const arnComponents = arn.split(':');

  // Extract specific parts
  const service = arnComponents[2];
  const region = arnComponents[3];
  const accountId = arnComponents[4];
  const resourceData = arnComponents[5].split('/'); // Split resource type and name

  const resourceType = resourceData[0];
  const resourceId = resourceData[1];

  const arnData: AwsArnData = {
    id: resourceId,
    service: service,
    region: region,
    accountId: accountId,
    resourceType: resourceType
  }
  return arnData;
}