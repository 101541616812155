import { IChatbotErrorRes, IGetChatbotMessageReq, IGetChatbotMessageRes, ISendChatbotReq, ISetChatbotMessageReq, ISetChatbotMessageRes, IUpdateChatbotMessageReq, IUpdateChatbotMessageRes } from './schema';
import { AxiosInstance, AxiosResponse } from 'axios';

const send = (instance: AxiosInstance) => 
  (data: ISendChatbotReq, token: string): Promise<AxiosResponse<IChatbotErrorRes | string,ISendChatbotReq>> => {
    return instance.request({
      method: 'POST',
      url: '/send',
      data: data,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  };
  
const setMessage = (instance: AxiosInstance) => 
  (data: ISetChatbotMessageReq, token: string): Promise<AxiosResponse<ISetChatbotMessageRes,ISetChatbotMessageReq>> => {
    return instance.request({
      method: 'POST',
      url: '/set_message',
      data: data,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  };

const getMessages = (instance: AxiosInstance) => 
  (data: IGetChatbotMessageReq, token: string): Promise<AxiosResponse<IGetChatbotMessageRes,IGetChatbotMessageReq>> => {
    return instance.request({
      method: 'POST',
      url: '/get_messages',
      data: data,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  };

const updateMessages = (instance: AxiosInstance) => 
  (data: IUpdateChatbotMessageReq, token: string): Promise<AxiosResponse<IUpdateChatbotMessageRes,IUpdateChatbotMessageReq>> => {
    return instance.request({
      method: 'PUT',
      url: '/update_message',
      data: data,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  };

// export const deleteReport = (data:IDeleteReportReq): BaseApiConfig<IDefaultRes> => ({
//   key: 'deleteReport',
//   config: {
//     method: 'POST',
//     url: '/file/deleteReport',
//     data: data
//   }
// });

const Chatbot = (instance: AxiosInstance) => ({
  send: send(instance),
  setMessage: setMessage(instance),
  getMessages:getMessages(instance),
  updateMessages: updateMessages(instance)
});
export default Chatbot;