import IconEditTlsInspection from 'assets/svgs/v3/ico_edit_subnet.svg';
import Icon from 'components/v2/atoms/Icon';
import BaseModal from 'components/v2/modals/BaseModal';
import { UpdateServerCerInboundPropsType } from '../types';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import updateAwsTLSInspectionConfigurationMutation, {
  AwsUpdateTLSInspectionConfigurationVariables,
} from 'graphql/mutations/updateAwsTLSInspectionConfiguration';
import Dropdown from 'components/molecules/Dropdown';
import lazyGetAwsListCertificate, { IGetAwsListCertificateVariables } from 'graphql/queries/getAwsListCertificates';
import { DropdownListDataType } from 'components/molecules/Dropdown/types';
import { extractResourceArnData } from 'utils/Common';
import { AwsServerCertificate, AwsServerCertificateConfiguration } from 'graphql/types/AwsTlsInspectionConfiguration';
import _ from 'lodash';
import './index.scss';

const UpdateServerCerInbound = (props: UpdateServerCerInboundPropsType) => {
  const { cloudId, region, tlsInspectionDetail, onCancel, onUpdated, ...baseModalProps } = props;
  const { tlsInspectionConfiguration } = tlsInspectionDetail;
  const { serverCertificateConfigurations } = tlsInspectionConfiguration;

  // API
  const [getAwsListCertificate] = lazyGetAwsListCertificate();
  const [updateAwsTLSInspectionConfiguration, { loading: updateLoading }] =
    updateAwsTLSInspectionConfigurationMutation();

  // State
  const [serverCerRows, setServerCerRows] = useState<DropdownListDataType[]>([]);
  const [listDropdownData, setListDropdownData] = useState<DropdownListDataType[]>([]);

  const setCertificateSelected = useCallback(
    (dropdownData: DropdownListDataType[]) => {
      const selectedDropndown: DropdownListDataType[] = [];
      serverCertificateConfigurations.map(serverCertificateConfiguration => {
        const { serverCertificates } = serverCertificateConfiguration;
        serverCertificates.map(serverCertificate => {
          const dropdown = dropdownData.find(item => item.value === serverCertificate.resourceArn);
          if (dropdown) {
            selectedDropndown.push(dropdown);
          }
        });
      });
      setServerCerRows(selectedDropndown);
    },
    [serverCertificateConfigurations],
  );

  const fetchListCertificateData = useCallback(() => {
    const reqData: IGetAwsListCertificateVariables = { cloudId, region, reqData: { includes: {} } };
    getAwsListCertificate({ variables: reqData }).then(({ data: response }) => {
      const certificateSummaryList = response?.getAwsListCertificates?.data?.[0]?.certificateSummaryList;
      if (certificateSummaryList) {
        const listDropdown: DropdownListDataType[] = certificateSummaryList.map(item => {
          const extractedResource = extractResourceArnData(item?.certificateArn ?? '');
          return {
            name: extractedResource.id,
            value: item?.certificateArn ?? '',
            isVertical: true,
            description: `Account ID: ${extractedResource?.accountId ?? '-'} \nDomain name: ${item?.domainName ?? '-'}`,
          };
        });
        setListDropdownData(listDropdown);
        setCertificateSelected(listDropdown);
      }
    });
  }, [cloudId, region, setCertificateSelected]);

  const onDropdownChange = useCallback(
    (index: number, serverCerItem: DropdownListDataType) => {
      if (serverCerItem.value !== 'No certificates found') {
        const updatedServerCerRows = serverCerRows.slice().map((item, idx) => {
          if (idx === index) {
            return { ...serverCerItem, error: '' };
          } else {
            return item;
          }
        });
        setServerCerRows(updatedServerCerRows);
      }
    },
    [serverCerRows],
  );

  const onRemoveCertificateRow = useCallback(
    (serverCerItem: DropdownListDataType) => {
      const updatedServerCerRows = serverCerRows.slice();
      const itemIndex = updatedServerCerRows.indexOf(serverCerItem);
      if (itemIndex !== -1) {
        updatedServerCerRows.splice(itemIndex, 1);
        setServerCerRows(updatedServerCerRows);
      }
    },
    [serverCerRows],
  );

  const onAddCertificateRow = useCallback(() => {
    const updatedServerCerRows = serverCerRows.slice();
    updatedServerCerRows.push({
      name: '',
      value: '',
    });
    setServerCerRows(updatedServerCerRows);
  }, [serverCerRows]);

  const getDropdownDataCurrentRow = useCallback(
    (index: number): DropdownListDataType[] => {
      const totalDropdownData = listDropdownData.slice();
      const totalServerCerRows = serverCerRows.slice().map((item, idx) => {
        if (idx !== index) {
          return item;
        }
      });

      const dropdownData = _.remove(totalDropdownData, dropdownData => {
        return _.indexOf(totalServerCerRows, dropdownData) === -1;
      });

      if (dropdownData.length === 0) {
        dropdownData.push({
          name: 'No certificates found',
          value: 'No certificates found',
          disable: true,
        });
      }
      return dropdownData;
    },
    [serverCerRows, listDropdownData],
  );

  const onValidateSubmission = useCallback((): boolean => {
    let validated = true;
    const validatedRows = serverCerRows.slice().map(item => {
      if (item.value === '') {
        validated = false;
        return { ...item, error: true };
      } else {
        return item;
      }
    });
    setServerCerRows(validatedRows);
    return validated;
  }, [serverCerRows]);

  const requestData = useMemo((): AwsUpdateTLSInspectionConfigurationVariables => {
    const { updateToken, tlsInspectionConfiguration, tlsInspectionConfigurationResponse } = tlsInspectionDetail;
    const { tlsInspectionConfigurationArn, tlsInspectionConfigurationName, encryptionConfiguration, description } =
      tlsInspectionConfigurationResponse;

    const { serverCertificateConfigurations } = tlsInspectionConfiguration;
    const listResourceArn: AwsServerCertificate[] = serverCerRows.slice().map(item => {
      return { resourceArn: item.value.toString() };
    });
    const serverCertificateConfigurationUpdated: AwsServerCertificateConfiguration[] =
      serverCertificateConfigurations.map(serverCertificateConfig => {
        const { scopes, certificateAuthorityArn, checkCertificateRevocationStatus } = serverCertificateConfig;
        return {
          serverCertificates: listResourceArn,
          scopes,
          certificateAuthorityArn,
          checkCertificateRevocationStatus,
        };
      });

    return {
      cloudId,
      region,
      reqData: {
        tlsInspectionConfigurationArn,
        tlsInspectionConfigurationName,
        tlsInspectionConfiguration: {
          serverCertificateConfigurations: serverCertificateConfigurationUpdated,
        },
        description,
        encryptionConfiguration,
        updateToken,
      },
    };
  }, [cloudId, region, tlsInspectionDetail, serverCerRows]);

  const updateCertificate = useCallback(() => {
    if (onValidateSubmission()) {
      updateAwsTLSInspectionConfiguration({ variables: requestData }).then(({ data: response }) => {
        const result = response?.updateAwsTLSInspectionConfiguration?.result ?? '';
        if (result === ErrorCode.SUCCESS) {
          useToast(ErrorCode.SUCCESS, 'Update TLS Inspection Configuration successfully.');
          onUpdated();
        } else {
          useToast(ErrorCode.SUCCESS, 'Update TLS Inspection Configuration failed.');
        }
      });
    }
  }, [onValidateSubmission, requestData]);

  useEffect(() => {
    fetchListCertificateData();
  }, []);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditTlsInspection} />
          {'Edit server certificates for inbound SSL/TLS inspection'}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-tls-inpsection-detail-modal">
        <div className="edit-tls-inpsection-title">
          <p className="header">Server certificates for inbound SSL/TLS inspection</p>
          <p className="description">
            Choose one or more public or imported private certificates within ACM for inbound SSL/TLS inspection.
            Network Firewall uses the certification to decrypt and re-encrypt your firewalls inbound traffic. If you
            don’t already have certificates in ACM for this purpose,
          </p>
          <div className="link-group">
            <p className="link">Request certificate</p> or <p className="link">import certificate.</p>
          </div>
        </div>
        <p className="title">TLS inspection configuration</p>

        <div className="edit-tls-inpsection-container">
          <p className="label">{`${
            serverCerRows.length === 0 ? 'No certificates selected.' : 'Public or private certificate'
          }`}</p>
          {serverCerRows.map((serverCerRow, idx) => {
            return (
              <div className="row-container" key={`server-certificate-row-${idx}`}>
                <Dropdown
                  id={idx.toString()}
                  data={getDropdownDataCurrentRow(idx)}
                  value={serverCerRow}
                  handleClick={value => onDropdownChange(idx, value)}
                  placeholder="Choose a certificate from ACM"
                  error={serverCerRow?.error}
                />

                <Button label="Remove" onClick={() => onRemoveCertificateRow(serverCerRow)} />
              </div>
            );
          })}

          <Button
            label="Add"
            type={ButtonTypeEnum.PRIMARY}
            onClick={() => onAddCertificateRow()}
            disabled={serverCerRows.length === 10}
          />
          <p className="description">{`You can add up to ${10 - serverCerRows.length} more certificates`}</p>
        </div>

        <div className="button-group">
          <Button label="Cancel" onClick={onCancel} />
          <Button
            label="Save changes"
            type={ButtonTypeEnum.PRIMARY}
            onClick={() => updateCertificate()}
            disabled={serverCerRows.length === 0}
            loading={updateLoading}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default UpdateServerCerInbound;
