import { gql } from '@apollo/client';

const query = gql`
  query getAwsLoadBalancers($request: AwsDescribeLoadBalancersRequest, $cloudId: Long, $region: String) {
    getAwsLoadBalancers(decribeLoadBalancersRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsDescribeLoadBalancersResponse {
          loadBalancers {
            loadBalancerArn
            dnsName
            canonicalHostedZoneId
            createdTime
            loadBalancerName
            scheme
            vpcId
            state
            type
            availabilityZones {
              zoneName
              subnetId
              outpostId
              loadBalancerAddresses {
                ipAddress
                allocationId
                privateIPv4Address
                iPv6Address
              }
            }
            securityGroups
            ipAddressType
            customerOwnedIpv4Pool
            enforceSecurityGroupInboundRulesOnPrivateLinkTraffic
          }
          nextMarker
        }
      }
    }
  }
`;
export default query;
