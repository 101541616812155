import { useMutation } from '@apollo/client';
import {
  AssociateAwsFirewallPolicyRequestType,
  AssociateAwsFirewallPolicyResponseType,
} from 'graphql/types/AwsFirewallPolicy';
import { IGqlResponseNewData } from '../../types';
import query from './query';

export type AssociateAwsFirewallPolicyVariablesType = {
  cloudId: number;
  region: string;
  request: AssociateAwsFirewallPolicyRequestType;
};

export type AssociateAwsFirewallPolicyResponseDataType = {
  associateAwsFirewallPolicy: IGqlResponseNewData<AssociateAwsFirewallPolicyResponseType>;
};

const updateAssociateAwsFirewallPolicyMutation = () =>
  useMutation<AssociateAwsFirewallPolicyResponseDataType, AssociateAwsFirewallPolicyVariablesType>(query);
export default updateAssociateAwsFirewallPolicyMutation;
