import { gql } from '@apollo/client';

const query = gql`
mutation updateAwsFirewallDescription($request: AwsUpdateFirewallDescriptionRequest, $cloudId: Long, $region: String) {
  updateAwsFirewallDescription(updateFirewallDescriptionRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsUpdateFirewallDescriptionResponse {
   			firewallArn
        firewallName
        description
        updateToken
        }
    }
  }
}
`;
export default query;