export const KEY_TYPE_DATA = [
  {
    id: 'SYMMETRIC',
    value: 'SYMMETRIC',
    name: 'SYMMETRIC',
    label: 'Symmetric',
    description: 'A single key used for encrypting and decrypting data or generating and verifying HMAC codes.',
  },
  {
    id: 'ASYMMETRIC',
    value: 'ASYMMETRIC',
    name: 'ASYMMETRIC',
    label: 'Asymmetric',
    description: 'A public and private key pair used for encrypting and decrypting data or signing and verifying messages.',
  },
];

export enum KeyTypeEnum {
  SYMMETRIC = 'SYMMETRIC',
  ASYMMETRIC = 'ASYMMETRIC',
}

export const KEY_SYMMETRIC_USAGE_DATA = [
  {
    id: 'ENCRYPT_DECRYPT',
    value: 'ENCRYPT_DECRYPT',
    name: 'ENCRYPT_DECRYPT',
    label: 'Encrypt and decrypt',
    description: 'Use the key only to encrypt and decrypt data.',
  },
  {
    id: 'GENERATE_VERIFY_MAC',
    value: 'GENERATE_VERIFY_MAC',
    name: 'GENERATE_VERIFY_MAC',
    label: 'Generate and verify MAC',
    description: 'Use the key only to generate and verify hash-based message authentication codes (HMAC).',
  },
];

export const KEY_ASYMMETRIC_USAGE_DATA = [
  {
    id: 'ENCRYPT_DECRYPT',
    value: 'ENCRYPT_DECRYPT',
    name: 'ENCRYPT_DECRYPT',
    label: 'Encrypt and decrypt',
    description: 'Use the key only to encrypt and decrypt data.',
  },
  {
    id: 'SIGN_VERIFY',
    value: 'SIGN_VERIFY',
    name: 'SIGN_VERIFY',
    label: 'Sign and verify',
    description: 'Key pairs for digital signing. Uses the private key for signing and the public key for verification.',
  },
];

export enum KeyUsageEnum {
  // SYMMETRIC
  SYMMETRIC_ENCRYPTDECRYPT = 'ENCRYPT_DECRYPT',
  SYMMETRIC_GENERATEVERIFYMAC = 'GENERATE_VERIFY_MAC',
  // ASYMMETRIC
  ASYMMETRIC_ENCRYPTDECRYPT = 'ENCRYPT_DECRYPT',
  ASYMMETRIC_SIGNVERRIFY = 'SIGN_VERIFY',
}

export const KEY_SPEC_SYMMETRIC_GENERATEVERIFYMAC_DATA = [
  { id: 'hmac224', value: 'hmac224', name: 'hmac224', label: 'HMAC_224', description: ''},
  { id: 'hmac256', value: 'hmac256', name: 'hmac256', label: 'HMAC_256', description: ''},
  { id: 'hmac384', value: 'hmac384', name: 'hmac384', label: 'HMAC_384', description: ''},
  { id: 'hmac512', value: 'hmac512', name: 'hmac512', label: 'HMAC_512', description: ''},
];

export const KEY_SPEC_ASYMMETRIC_ENCRYPTDECRYPT_DATA = [
  { id: 'rsa2048', value: 'rsa2048', name: 'rsa2048', label: 'RSA_2048', description: ''},
  { id: 'rsa3072', value: 'rsa3072', name: 'rsa3072', label: 'RSA_3072', description: ''},
  { id: 'rsa4096', value: 'rsa4096', name: 'rsa4096', label: 'RSA_4096', description: ''},
];

export const KEY_SPEC_ASYMMETRIC_SIGNVERIFY_DATA = [
  { id: 'rsa2048', value: 'rsa2048', name: 'rsa2048', label: 'RSA_2048', description: ''},
  { id: 'rsa3072', value: 'rsa3072', name: 'rsa3072', label: 'RSA_3072', description: ''},
  { id: 'rsa4096', value: 'rsa4096', name: 'rsa4096', label: 'RSA_4096', description: ''},
  { id: 'eccnistp256', value: 'eccnistp256', name: 'eccnistp256', label: 'ECC_NIST_P256', description: ''},
  { id: 'eccnistp384', value: 'eccnistp384', name: 'eccnistp384', label: 'ECC_NIST_P384', description: ''},
  { id: 'eccnistp521', value: 'eccnistp521', name: 'eccnistp521', label: 'ECC_NIST_P521', description: ''},
  { id: 'eccnistp256k1', value: 'eccnistp256k1', name: 'eccnistp256k1', label: 'ECC_SECG_P256K1', description: ''},
];

export enum KeySpecEnum {
  // Default
  SYMMETRIC_DEFAULT = 'SYMMETRIC_DEFAULT',
  // Symmetric - Generate and verify MAC
  SYMMETRIC_HMAC224 = 'HMAC_224',
  SYMMETRIC_HMAC256 = 'HMAC_256',
  SYMMETRIC_HMAC384 = 'HMAC_384',
  SYMMETRIC_HMAC512 = 'HMAC_512',
  // Asymmetric - Encrypt and decrypt
  ASYMMETRIC_ENCRYPTDECRYPT_RSA2048 = 'RSA_2048',
  ASYMMETRIC_ENCRYPTDECRYPT_RSA3072 = 'RSA_3072',
  ASYMMETRIC_ENCRYPTDECRYPT_RSA4096 = 'RSA_4096',
  // Asymmetric - Sign and verify
  ASYMMETRIC_SIGNANDVERIFY_RSA2048 = 'RSA_2048',
  ASYMMETRIC_SIGNANDVERIFY_RSA3072 = 'RSA_3072',
  ASYMMETRIC_SIGNANDVERIFY_RSA4096 = 'RSA_4096',
  ASYMMETRIC_SIGNANDVERIFY_ECCNISTP256 = 'ECC_NIST_P256',
  ASYMMETRIC_SIGNANDVERIFY_ECCNISTP384 = 'ECC_NIST_P384',
  ASYMMETRIC_SIGNANDVERIFY_ECCNISTP521 = 'ECC_NIST_P521',
  ASYMMETRIC_SIGNANDVERIFY_ECCNISTP256K1 = 'ECC_SECG_P256K1',
}

export const KEY_SYMMETRIC_MATERIAL_ORIGIN_DATA = [
  {
    id: 'AWS_KMS',
    value: 'AWS_KMS',
    name: 'AWS_KMS',
    label: 'KMS - recommended',
    description: 'AWS KMS creates and manages the key material for the KMS key',
  },
  {
    id: 'EXTERNAL',
    value: 'EXTERNAL',
    name: 'EXTERNAL',
    label: 'External (Import Key material)',
    description: 'You create and import the key material for the KMS key.',
  },
  // {
  //   id: 'awscloudhsm',
  //   value: 'awscloudhsm',
  //   name: 'awscloudhsm',
  //   label: 'AWS CloudHSM key store',
  //   description: 'AWS KMS creates the key material in the AWS cloudHSM cluster of your AWS CloudHSM key store',
  // },
  // {
  //   id: 'externalkeystore',
  //   value: 'externalkeystore',
  //   name: 'externalkeystore',
  //   label: 'External key store',
  //   description: 'The key material for the KMS key is in an external key manager outside of AWS.',
  // },
];

export const KEY_ASYMMETRIC_MATERIAL_ORIGIN_DATA = [
  {
    id: 'AWS_KMS',
    value: 'AWS_KMS',
    name: 'AWS_KMS',
    label: 'KMS - recommended',
    description: 'AWS KMS creates and manages the key material for the KMS key',
  },
  {
    id: 'EXTERNAL',
    value: 'EXTERNAL',
    name: 'EXTERNAL',
    label: 'External (Import Key material)',
    description: 'You create and import the key material for the KMS key.',
  },
];

export enum KeyMaterialOriginEnum {
  KMSRECOMMEND = 'AWS_KMS',
  EXTERNAL = 'EXTERNAL',
  AWSCLOUDHSM = 'AWS_CLOUDHSM',
  EXTERNALKEYSTORE = 'XTERNAL_KEY_STORE'
}

export const KEY_REGIONALITY_DATA = [
  {
    id: 'false',
    value: 'false',
    name: 'false',
    label: 'Single-Region key',
    description: 'Never allow this key to be replicated into other Regions',
  },
  {
    id: 'true',
    value: 'true',
    name: 'true',
    label: 'Multi-Region key',
    description: 'Allow this key to be replicated into other Regions',
  },
];

export enum KeyRegionalityEnum {
  SINGLE = 'false',
  MULTI = 'true',
}