import { gql } from "@apollo/client";

const query = gql`
  query getAwsListResourceProfileArtifacts($request: AwsListResourceProfileArtifactsRequest, $cloudId: Long, $region: String) {
    getAwsListResourceProfileArtifacts(awsListResourceProfileArtifactsRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data{
        ... on AwsListResourceProfileArtifactsResponse {
        artifacts {
          arn
          classificationResultStatus
          sensitive
        }
          nextToken
          }
        }
      }
    }
`

export default query;