import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';

function StatusChecks({ data, volumeAttribute, isLoading }: any) {
  const { availabilityZone, volumeStatus } = data || {};

  const checkIOStatus =
    volumeStatus?.[0]?.volumeStatus?.details?.length &&
    volumeStatus[0].volumeStatus.details.some((e: any) => e.name === 'io-enabled' && e.status === 'passed');

  const ioPerformance = volumeStatus?.[0]?.volumeStatus?.details?.length
    ? volumeStatus[0].volumeStatus.details.find((e: any) => e.name === 'io-performance')
    : null;

  const detailsData: IMgdDetailKeyValueProps[] = [
    {
      title: 'Volume status',
      description: volumeStatus?.[0]?.volumeStatus?.status ? 'Okay' : '',
    },
    {
      title: 'Availability zone',
      description: availabilityZone,
    },
    {
      title: 'I/O status',
      description: checkIOStatus ? 'enabled' : 'disabled',
    },
    {
      title: 'I/O performance',
      description: ioPerformance?.status || '',
    },
    {
      title: 'Auto-enabled',
      description: volumeAttribute?.autoEnableIO?.value ? 'enabled' : '',
    },
  ];

  return <DetailTab title="Status checks" data={detailsData} isApiLoading={isLoading} />;
}

export default StatusChecks;
