import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsListFunctionsVariables {
  cloudId: number;
  region: string;
  reqData: {
    maxItems?: number;
  };
}

export interface IAwsListFunctionsResponseData {
  getAwsListFunctions: IGqlResponseNewData<any>;
}

const lazyGetAwsListFunctions = () =>
  useLazyQuery<IAwsListFunctionsResponseData, IAwsListFunctionsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListFunctions;
