import React, { useMemo } from 'react';
import { LoadBalancerDetailPropsType } from '../../types';
import LoadBalancerSummary from '../Summary';
import PageDetailTitle from '../../PageDetailTitle';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import { detailTabKeys, detailTabTextDes } from '../config';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import { handleLinkClicked } from 'pages/v2/Organ/Management/Utils';
import { useNavigate } from 'react-router-dom';
import { LINK, SUBNET_FILTER } from 'pages/v2/Organ/Management/configs';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { NETWORK_TAB_LIST } from 'pages/v2/Organ/Management/Network/configs';
import { getFormatedDateWithTimezone } from 'utils/Common';

const LoadBalancerDetail = (props: LoadBalancerDetailPropsType) => {
  const { detailData, onBackButtonClicked, cloudId, region } = props;

  const navigate = useNavigate();

  const title = useMemo(() => {
    return `Load balancer: ${detailData?.dnsName}`;
  }, [detailData]);

  const handleGetLink = (key: string, value: string | string[]) => {
    switch (key) {
      case detailTabKeys[6]:
        const arr = value?.toString()?.split(' ');
        if (!arr?.length) return null;

        const isSubnet = arr[0].includes('subnet');
        if (!isSubnet) return null;

        return handleLinkClicked({
          navigate,
          link: LINK.NETWORK,
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[2].id,
          key: SUBNET_FILTER[0].value.toString(),
          value: arr[0],
        });

      default:
        return null;
    }
  };

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    const detailTabData: IMgdDetailKeyValueProps[] = [];
    const detailTabDataOrdered: IMgdDetailKeyValueProps[] = [];
    if (detailData) {
      Object.entries(detailData).map(([key, value]) => {
        if (detailTabKeys.indexOf(key) > -1) {
          const textDes = detailTabTextDes.find(text => text.id == key);
          let desValue: string | string[] = '-';

          if (typeof value === 'string') {
            desValue = value.toString();

            if (key === detailTabKeys[7]) desValue = getFormatedDateWithTimezone(desValue, 'MMM DD, YYYY HH:MM');
          }

          if (key === detailTabKeys[6]) {
            desValue =
              detailData?.availabilityZones?.map(az => `${az.subnetId || ''} ${az.zoneName || ''}` || '-') || '-';
          }

          if (textDes) {
            detailTabData.push({
              id: key,
              type: textDes.type as TextTypeEnum,
              title: textDes.value,
              description: desValue,
              handleItemClick:
                textDes.type === TextTypeEnum.LINK ? (value: string) => handleGetLink(key, value || desValue) : null,
            });
          }
        }
      });

      // order data
      detailTabKeys.map(key => {
        const dataByKey = detailTabData.find(data => data.id == key);
        if (dataByKey) detailTabDataOrdered.push(dataByKey);
      });
    }
    return detailTabDataOrdered;
  }, [detailData]);

  return (
    <div className="ec2-detail scroll">
      <PageDetailTitle title={title} pageBackClick={onBackButtonClicked} />
      <DetailTab title={'Details'} data={detailTabContentData} isApiLoading={false} />
      <LoadBalancerSummary detailData={detailData} cloudId={cloudId} region={region} isSummaryHidden={true} />
    </div>
  );
};

export default LoadBalancerDetail;
