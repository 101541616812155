import { Fragment, useEffect, useMemo, useState } from 'react';
import Button from 'pages/v2/Organ/Management/components/Button';
import Space from 'pages/v2/Organ/Management/components/Space';
import Divider from 'pages/v2/Organ/Management/components/Divider';
import Table from 'components/v2/dataDisplay/Table';
import { OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdLayout';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import lazyGetAwsSecretValue, { IAwsSecretValueVariables } from 'graphql/queries/getAwsSecretValue';
import EditSecretValueModal, { TagRowData } from '../Components/EditSecretValueModal';
import lazyGetAwsResourcePolicy, { IAwsResourcePolicyVariables } from 'graphql/queries/getAwsResourcePolicy';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import putAwsResourcePolicyMutation, { IPutAwsResourcePolicyVariables } from 'graphql/mutations/putAwsResourcePolicy';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import putAwsSecretValueMutation, { IPutAwsSecretValueVariables } from 'graphql/mutations/putAwsSecretValue';

const Overview = ({ cloudId, region, secret, onReloadData }: any) => {
  const [rows, setRows] = useState([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [isShowSecretContent, setIsShowSecretContent] = useState(false);
  const [isOpenEditSecretValue, setIsOpenEditSecretValue] = useState(false);
  const [isShowPermissionContent, setIsShowPermissionContent] = useState(false);
  const permissionDefault = {
    Version: '2012-10-17',
    Statement: [],
  };
  const [permissionJson, setPermissionJson] = useState<any>(null);

  const [getAwsSecretValue] = lazyGetAwsSecretValue();
  const [getAwsResourcePolicy] = lazyGetAwsResourcePolicy();
  const [putAwsSecretValue, { loading: putAwsSecretValueLoading }] = putAwsSecretValueMutation();
  const [putAwsResourcePolicy, { loading: putAwsResourcePolicyLoading }] = putAwsResourcePolicyMutation();

  const columns = [
    { label: '', field: '', width: 10 },
    { label: 'Secret key', field: 'secretKey', sort: true },
    { label: 'Secret value', field: 'secretValue', sort: true },
  ];

  const handleGetAwsSecretValue = async () => {
    if (!secret?.arn) return;

    try {
      const variables: IAwsSecretValueVariables = {
        cloudId,
        region,
        request: {
          secretId: secret.arn,
        },
      };

      const { data: secretValue } = await getAwsSecretValue({ variables });

      const lissSecretValue = secretValue?.getAwsSecretValue?.data || [];

      if (!lissSecretValue?.length) return;

      let secretValueRows: any = [];

      const secretData = JSON.parse(lissSecretValue?.[0]?.secretString || '');

      if (secretData?.length) {
        secretValueRows = secretData.map((e: any) => ({
          secretKey: Object.keys(e)?.[0],
          secretValue: Object.values(e)?.[0],
        }));
        setRows(secretValueRows);
        return;
      }

      secretValueRows = [
        {
          secretKey: Object.keys(secretData)?.[0],
          secretValue: Object.values(secretData)?.[0],
        },
      ];
      setRows(secretValueRows);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAwsResourcePolicy = async () => {
    if (!secret?.arn) return;

    try {
      const variables: IAwsResourcePolicyVariables = {
        cloudId,
        region,
        reqData: {
          secretId: secret.arn,
        },
      };

      const { data: resourcePolicy } = await getAwsResourcePolicy({ variables });

      const json = resourcePolicy?.getAwsResourcePolicy?.data?.[0]?.resourcePolicy || permissionDefault;
      setPermissionJson(JSON.stringify(json, null, 2));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAwsSecretValue();
    handleGetAwsResourcePolicy();
  }, [secret]);

  const rowsCurrentPage = useMemo(() => {
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(rows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [rows, mainTablePagination]);

  const currentTagData = useMemo((): TagRowData[] => {
    const tagRowData: TagRowData[] = [];
    if (rowsCurrentPage?.length) {
      rowsCurrentPage.map((tag: any, index: number) => {
        tagRowData.push({
          index,
          keyStr: tag.secretKey,
          valueStr: tag.secretValue,
        });
      });
    }
    return tagRowData;
  }, [rowsCurrentPage]);

  const handleEditSecret = async (oldTags: TagRowData[], newTags: TagRowData[]) => {
    if (putAwsSecretValueLoading) return;

    try {
      const tags = [...oldTags, ...newTags];

      const formatTags = tags.map(e => ({
        [e.keyStr]: e.valueStr,
      }));

      const tagsString = JSON.stringify(formatTags);

      const variables: IPutAwsSecretValueVariables = {
        cloudId,
        region,
        reqData: {
          secretId: secret.arn,
          secretString: tagsString,
        },
      };
      await putAwsSecretValue({ variables });
      onReloadData();
      useToast(ErrorCode.SUCCESS, 'Edit secret successful.');
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Edit secret failed.');
    }
  };

  const handleEditPermission = async () => {
    if (putAwsResourcePolicyLoading) return;

    try {
      const variables: IPutAwsResourcePolicyVariables = {
        cloudId,
        region,
        reqData: {
          secretId: secret.arn,
          resourcePolicy: permissionJson,
        },
      };
      await putAwsResourcePolicy({ variables });
      onReloadData();
      useToast(ErrorCode.SUCCESS, 'Edit permission successful.');
    } catch (error) {
      useToast(ErrorCode.UNKNOWN, 'Edit permission failed.');
    }
  };

  return (
    <Fragment>
      <div className="overview-item">
        <div className="overview-item-header">
          <div className="header-title">
            <p>Secret value</p>

            <p>Retreive and view the secret value</p>
          </div>

          <div className="header-action">
            {isShowSecretContent ? (
              <div className="button-group">
                <Button label="Close" onClick={() => setIsShowSecretContent(false)} />

                <Button label="Edit" onClick={() => setIsOpenEditSecretValue(true)} />
              </div>
            ) : (
              <Button label="Retrieve secret value" onClick={() => setIsShowSecretContent(true)} />
            )}
          </div>
        </div>

        {isShowSecretContent ? (
          <>
            <Divider />

            <div className="overview-item-content">
              <div className="table-container">
                <Table
                  columns={columns}
                  rows={rowsCurrentPage}
                  sortOption={{
                    target: mainTablePagination.target,
                    direction: mainTablePagination.direction,
                    onChangeSort: (target: string, dir: OrderDirection) => {
                      setMainTablePagination(prev => ({
                        ...prev,
                        target: target,
                        direction: dir,
                      }));
                    },
                  }}
                  horizontalScrollable={true}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>

      <Space size={10} />

      <div className="overview-item">
        <div className="overview-item-header">
          <div className="header-title">
            <p>Resource permissions-optional</p>

            <p>Add or edit a resource policy to access secrets across AWS accounts.</p>
          </div>

          <div className="header-action">
            {isShowPermissionContent ? (
              <div className="button-group">
                <Button label="Cancel" onClick={() => setIsShowPermissionContent(false)} />

                <Button label="Save" onClick={handleEditPermission} />
              </div>
            ) : (
              <Button label="Edit permission" onClick={() => setIsShowPermissionContent(true)} />
            )}
          </div>
        </div>

        {isShowPermissionContent ? (
          <>
            <Divider />

            <div className="overview-item-content">
              <TextareaAtom value={permissionJson} onChangeValue={value => setPermissionJson(value)} />
            </div>
          </>
        ) : null}
      </div>

      {isOpenEditSecretValue ? (
        <EditSecretValueModal
          open={isOpenEditSecretValue}
          onClose={() => setIsOpenEditSecretValue(false)}
          data={currentTagData}
          onSave={handleEditSecret}
        />
      ) : null}
    </Fragment>
  );
};

export default Overview;
