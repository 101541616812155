import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IUpdateProjectCloudVariables {
  reqUpdProjectCloud: {
    id: number;
    cloudId?: number;
    name?: string;
    rootAccount?: string;
    attrKey1?: string;
    attrValue1?: string;
    attrKey2?: string;
    attrValue2?: string;
    attrKey3?: string;
    attrValue3?: string;
    attrKey4?: string;
    attrValue4?: string;
    attributes?: string;
    serviceType?: string;
    features?: string;
    msspAttrKey?: string;
    msspAttrValue?: string;
  }
}

export interface IUpdateProjectCloudResponseData {
  updateProjectCloud: IGqlResponseData<undefined>;
}

const updateProjectCloud = () => useMutation<IUpdateProjectCloudResponseData, IUpdateProjectCloudVariables>(query);
export default updateProjectCloud;