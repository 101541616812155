import './index.scss';
import { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import CopyableText from 'components/v3/CopyableText';
import { TextTypeEnum } from 'layouts/v3/MgdLayout';
import MgdTooltip from 'components/v2/MgdTooltip';
import { randomString } from 'utils/Common';
export interface IMgdDetailKeyValueProps {
  id?: string;
  type?: TextTypeEnum;
  title: string | string[];
  description: any;
  isTooltip?: boolean;
  handleItemClick?: any;
  isChipTitle?: boolean;
  color?: string;
  onlyTitle?: boolean;
}
export type DetailTabPropsType = {
  title: string;
  isApiLoading?: boolean;
  description?: string;
  findingStyle?: string;
  data: IMgdDetailKeyValueProps[];
  actions?: any;
};

const DetailItem = ({ title, description, data, isApiLoading, actions, findingStyle }: DetailTabPropsType) => {
  const renderLinkNode = useCallback((description: string, handleItemClick: any): ReactNode => {
    if (description === '' || description === null || description === undefined) {
      return <p>-</p>;
    }

    return (
      <p
        className="description link"
        onClick={e => {
          e.stopPropagation();
          handleItemClick?.(description);
        }}
      >
        {description}
      </p>
    );
  }, []);

  const renderValue = useCallback((detail: IMgdDetailKeyValueProps) => {
    switch (detail.type) {
      case TextTypeEnum.COPY: {
        if (detail.description instanceof Array) {
          return (
            <Fragment>
              {detail.description.map((item, index) => {
                return <CopyableText text={item} key={`detail-tab-copy-${index}`} />;
              })}
            </Fragment>
          );
        }

        return <CopyableText text={detail.description} />;
      }

      case TextTypeEnum.STATUS: {
        return (
          <div className="status">
            <p>{detail.description}</p>
          </div>
        );
      }

      case TextTypeEnum.LINK: {
        if (detail.description instanceof Array) {
          if (detail?.isTooltip) {
            return (
              <MgdTooltip id={randomString()} title={`${detail.description?.length} subnets`}>
                {detail.description.map((item: any, index: number) => {
                  return (
                    <Fragment key={`detail-description-tooltip-${index}`}>
                      {renderLinkNode(item, detail?.handleItemClick)}
                    </Fragment>
                  );
                })}
              </MgdTooltip>
            );
          }

          return (
            <Fragment>
              {detail.description.map((item, index) => {
                return (
                  <Fragment key={`detail-tab-link-${index}`}>{renderLinkNode(item, detail.handleItemClick)}</Fragment>
                );
              })}
            </Fragment>
          );
        }

        return renderLinkNode(detail.description, detail.handleItemClick);
      }

      case TextTypeEnum.COPY_LINK: {
        if (detail.description instanceof Array) {
          return detail.description.map((des, idx) => (
            <CopyableText key={`detail-tab-copy-${idx}`} text={des} onTextClicked={() => detail?.handleItemClick?.()} />
          ));
        }

        if (!detail.description || detail.description === '-') {
          return <CopyableText text={'-'} />;
        }

        return (
          <CopyableText
            text={detail.description}
            onTextClicked={() => (detail?.handleItemClick ? detail.handleItemClick() : {})}
          />
        );
      }

      case TextTypeEnum.NORMAL:
      default:
        if (detail.description instanceof Array) {
          return (
            <Fragment>
              {detail.description.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </Fragment>
          );
        }

        return (
          <p>
            {detail.description === null || detail.description === undefined || detail.description === ''
              ? '-'
              : detail.description}
          </p>
        );
    }
  }, []);

  const renderContentNode = useCallback((): ReactNode => {
    return (
      <div className="detail-info-content">
        {data?.map((detail: IMgdDetailKeyValueProps, index: number) => (
          <div className={`content ${findingStyle}`} key={randomString()}>
            <span className={`title ${detail.onlyTitle ? 'w-100' : ''}`}>
              <p className={`${detail.isChipTitle ? `chip-title-${detail.color}` : ''}`}>{detail.title}</p>
            </span>

            <div className="value">{renderValue(detail)}</div>
          </div>
        ))}
      </div>
    );
  }, [data, renderValue]);

  return (
    <div className="detail-item">
      <Fragment>
        <div className="detail-info-title">
          <div>
            <p>{title}</p>
            <p>{description}</p>
          </div>

          <div>{actions}</div>
        </div>

        {isApiLoading ? (
          <div className="detail-info">
            <div className="progress-container">
              <div className="progress-gif" />
              Loading ...
            </div>
          </div>
        ) : (
          renderContentNode()
        )}
      </Fragment>
    </div>
  );
};

export default DetailItem;
