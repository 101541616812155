import { ErrorCode } from '@Types/error';
import IconEditKmsEncryption from 'assets/svgs/v3/ico_kms_encryption.svg';
import IconSearch from 'assets/svgs/v3/ico_search.svg';
import AutoCompleteDropdownAtom from 'components/v2/atoms/AutoCompleteDropdownAtom';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import Icon from 'components/v2/atoms/Icon';
import BaseModal from 'components/v2/modals/BaseModal';
import CopyableText from 'components/v3/CopyableText';
import lazyGetAwsAllListAliasesPage, { IAwsAllListAliasesVariables } from 'graphql/queries/getAwsAllListAliases';
import lazyGetAwsDescribeKey, { IAwsDescribeKeyVariables } from 'graphql/queries/getAwsDescribeKey';
import lazyGetAwsListKeys, { AwsListKeyVariablesType } from 'graphql/queries/getAwsListKeys';
import { AwsAlias } from 'graphql/types/AwsAliases';
import { AwsKeyListEntry } from 'graphql/types/AwsListKey';
import { useCallback, useEffect, useMemo, useState } from 'react';
import './index.scss';
import { useToast } from 'hooks/v2/useToast';
import { UpdateKMSEncryptionPropsType } from '../types';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import updateAwsTLSInspectionConfigurationMutation, {
  AwsUpdateTLSInspectionConfigurationVariables,
} from 'graphql/mutations/updateAwsTLSInspectionConfiguration';
import {
  AwsEncryptionConfiguration,
  AwsServerCertificateConfiguration,
} from 'graphql/types/AwsTlsInspectionConfiguration';
import { ENCRYPTION_CONFIGURATION_DATA } from '../../configs';

interface AwsKeyDetail {
  keyArn: string;
  keyStatus: boolean;
  keyAlias: string;
}

const UpdateKMSEncryption = (props: UpdateKMSEncryptionPropsType) => {
  const { cloudId, region, tlsInspectionDetail, onCancel, onUpdated, ...baseModalProps } = props;
  const { encryptionConfiguration } = tlsInspectionDetail?.tlsInspectionConfigurationResponse;
  const customizeEncryptionSelected = encryptionConfiguration?.type === ENCRYPTION_CONFIGURATION_DATA.CUSTOMER_KMS;

  // API
  const [getAwsListKeys] = lazyGetAwsListKeys();
  const [getAwsDescribeKey, { loading: getDesribeKeyLoading }] = lazyGetAwsDescribeKey();
  const [getAwsAllListAliases] = lazyGetAwsAllListAliasesPage();
  const [updateAwsTLSInspectionConfiguration, { loading: updateLoading }] =
    updateAwsTLSInspectionConfigurationMutation();

  const [customizeEncryptionValue, setCustomizeEncryptionValue] = useState<boolean>(customizeEncryptionSelected);

  const [awsListKey, setAwsListKey] = useState<AwsKeyListEntry[]>([]);
  const [awsAlias, setAwsAlias] = useState<AwsAlias[]>([]);
  const [kmsSelected, setKmsSelected] = useState<DropdownListDataType>();
  const [awsKeyDetail, setAwsKeyDetail] = useState<AwsKeyDetail | null>(null);
  const [detailVisible, setVisibleDetailConent] = useState<boolean>(false);
  const [encryptionConfigurationError, setEncryptionConfigurationError] = useState<string>('');

  const dropdownListKeys = useMemo(() => {
    return awsListKey.reduce((data, awsKey) => {
      const alias = awsAlias.find(item => item.targetKeyId === awsKey.keyId);
      let description = '';

      if (alias) {
        const startIdx = alias.aliasName.lastIndexOf('/') + 1;
        const endIdx = alias.aliasName.length;
        description = alias.aliasName.substring(startIdx, endIdx);
      }

      data.push({
        name: awsKey.keyId,
        value: awsKey.keyId,
        description,
      });

      return data;
    }, [] as DropdownListDataType[]);
  }, [awsAlias, awsListKey]);

  const onCustomerManagedKeyChange = useCallback((value: boolean) => {
    setCustomizeEncryptionValue(value);
    if (!value) {
      setVisibleDetailConent(false);
      setKmsSelected(undefined);
    }
  }, []);

  const onOptionSelected = useCallback(
    (optionSelected: DropdownListDataType) => {
      const keyId = optionSelected.value.toString();
      setKmsSelected(optionSelected);
      const variables: IAwsDescribeKeyVariables = {
        cloudId,
        region,
        request: {
          keyId: keyId,
        },
      };
      getAwsDescribeKey({ variables }).then(({ data: response }) => {
        const keyMetadata = response?.getAwsDescribeKey?.data?.[0].keyMetadata;
        const alias = awsAlias.find(item => item.targetKeyId === keyId)?.aliasName?.split('/')?.[1] ?? '';

        const keyDetail: AwsKeyDetail = {
          keyStatus: keyMetadata?.enabled ?? false,
          keyArn: keyMetadata?.arn || '',
          keyAlias: alias,
        };
        setVisibleDetailConent(true);
        setAwsKeyDetail(keyDetail);
      });
      // update creation data
      // encryptionConfiguration
      setEncryptionConfigurationError('');
    },
    [cloudId, region, awsAlias],
  );

  const fetchAllListAliases = useCallback(async () => {
    const variables: IAwsAllListAliasesVariables = {
      cloudId,
      region,
      request: {},
    };
    const response = await getAwsAllListAliases({ variables });
    const aliases = response.data?.getAwsAllListAliases?.data || [];
    setAwsAlias(aliases);
  }, []);

  const fetchAllListKeys = useCallback(async () => {
    const variables: AwsListKeyVariablesType = {
      cloudId,
      region,
      request: {},
    };

    const response = await getAwsListKeys({ variables });
    const listKeys = response.data?.getAwsListKeys?.data?.[0]?.keys || [];
    setAwsListKey(listKeys);
  }, []);

  const onRemoveKmsKey = useCallback(() => {
    setKmsSelected(undefined);
  }, []);

  const requestData = useMemo((): AwsUpdateTLSInspectionConfigurationVariables => {
    const { updateToken, tlsInspectionConfiguration, tlsInspectionConfigurationResponse } = tlsInspectionDetail;
    const { tlsInspectionConfigurationArn, tlsInspectionConfigurationName, description } =
      tlsInspectionConfigurationResponse;

    let encryptionConfiguration: AwsEncryptionConfiguration = { type: ENCRYPTION_CONFIGURATION_DATA.AWS_OWNED_KMS_KEY };
    if (customizeEncryptionValue) {
      encryptionConfiguration = { type: ENCRYPTION_CONFIGURATION_DATA.CUSTOMER_KMS, keyId: awsKeyDetail?.keyArn };
    }

    const { serverCertificateConfigurations } = tlsInspectionConfiguration;
    const serverCertificateConfigurationUpdated: AwsServerCertificateConfiguration[] =
      serverCertificateConfigurations.map(serverCertificateConfig => {
        const { serverCertificates, scopes, checkCertificateRevocationStatus, certificateAuthorityArn } =
          serverCertificateConfig;

        return {
          serverCertificates,
          scopes,
          certificateAuthorityArn,
          checkCertificateRevocationStatus,
        };
      });

    return {
      cloudId,
      region,
      reqData: {
        tlsInspectionConfigurationArn,
        tlsInspectionConfigurationName,
        tlsInspectionConfiguration: {
          serverCertificateConfigurations: serverCertificateConfigurationUpdated,
        },
        description,
        encryptionConfiguration,
        updateToken,
      },
    };
  }, [cloudId, region, tlsInspectionDetail, customizeEncryptionValue, awsKeyDetail]);

  const onValidated = useCallback((): boolean => {
    let validated = true;
    if (customizeEncryptionValue && !kmsSelected) {
      setEncryptionConfigurationError('You must choose a firewall policy.');
      validated = false;
    }
    return validated;
  }, [kmsSelected, customizeEncryptionValue]);

  const updateCertificate = useCallback(() => {
    if (onValidated()) {
      updateAwsTLSInspectionConfiguration({ variables: requestData }).then(({ data: response }) => {
        const result = response?.updateAwsTLSInspectionConfiguration?.result ?? '';
        if (result === ErrorCode.SUCCESS) {
          useToast(ErrorCode.SUCCESS, 'Update TLS Inspection Configuration successfully.');
          onUpdated();
        } else {
          useToast(ErrorCode.SUCCESS, 'Update TLS Inspection Configuration failed.');
        }
      });
    }
  }, [requestData, onValidated]);

  useEffect(() => {
    fetchAllListKeys();
    fetchAllListAliases();
  }, []);

  useEffect(() => {
    if (encryptionConfiguration?.type === ENCRYPTION_CONFIGURATION_DATA.CUSTOMER_KMS) {
      const optionSelected = dropdownListKeys.find(item =>
        encryptionConfiguration?.keyId?.includes(item.value.toString()),
      );
      if (optionSelected) {
        onOptionSelected(optionSelected);
      }
    }
  }, [dropdownListKeys, encryptionConfiguration]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditKmsEncryption} />
          {'Edit KMS encryption settings'}
        </>
      )}
      {...baseModalProps}
    >
      <div className="kms-encryption-modal">
        <div className="kms-encryption">
          <div className="kms-container">
            <p className="text-description">
              Your data is encrypted by default with a key that AWS owns and manages for you. To choose a different key,
              customize your encryption settings.
            </p>
            <label className="labeled flex a-center">
              <CheckboxAtom
                checked={customizeEncryptionValue}
                onchange={() => onCustomerManagedKeyChange(!customizeEncryptionValue)}
                label={'Customize encryption settings (advanced)'}
                description={`${customizeEncryptionValue ? 'To use the default key, clear this option.' : ''}`}
              />
            </label>
          </div>

          {customizeEncryptionValue && (
            <>
              <div className="kms-container">
                <p className="text-title">Choose an AWS KMS key</p>
                <p className="text-description">This key will be used for encryption instead of the default key.</p>
                <div className="input-group-container">
                  <AutoCompleteDropdownAtom
                    icon={IconSearch}
                    data={dropdownListKeys}
                    value={kmsSelected?.value.toString()}
                    placeholder="Choose an AWS KMS key or enter an ARN"
                    handleClick={val => onOptionSelected(val)}
                    handleChange={() => {}}
                    handleAddClass={() => {}}
                    error={encryptionConfigurationError}
                  />
                  <Button
                    label="Remove"
                    onClick={() => {
                      onRemoveKmsKey();
                    }}
                  />
                </div>
              </div>
            </>
          )}

          {detailVisible && kmsSelected && !getDesribeKeyLoading && (
            <>
              <div className="kms-container">
                <p className="text-title">AWS KMS key details</p>
                <div className="kms-key-detail">
                  <p className="text-title">Key ARN</p>
                  <CopyableText text={awsKeyDetail?.keyArn || ''} />
                </div>
                <div className="kms-key-detail">
                  <p className="text-title">Key status</p>
                  <p className="text-description">{awsKeyDetail?.keyStatus ? 'Enabled' : 'Disabled'}</p>
                </div>
                {awsKeyDetail?.keyAlias && (
                  <div className="kms-key-detail">
                    <p className="text-title">Key aliases</p>
                    <p className="text-description">{awsKeyDetail?.keyAlias}</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="button-group">
          <Button label="Cancel" onClick={onCancel} />
          <Button
            label="Save"
            type={ButtonTypeEnum.PRIMARY}
            onClick={() => updateCertificate()}
            loading={updateLoading}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default UpdateKMSEncryption;
