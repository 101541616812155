import { gql } from '@apollo/client';

const query = gql`
  query getAwsListFunctions($reqData: AwsListFunctionsRequest, $cloudId: Long, $region: String) {
    getAwsListFunctions(awsListFunctionsRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListFunctionsResponse {
          functions {
            functionArn
            functionName
          }
        }
      }
    }
  }
`;
export default query;
