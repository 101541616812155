import './index.scss';
import { useState, useMemo, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconEdit from 'assets/svgs/v3/ico_edit.svg';
import Icon from 'components/v2/atoms/Icon';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import Divider from 'pages/v2/Organ/Management/components/Divider';

interface IEditDescriptionModalProps extends IBaseModalProps {
  data: string;
  onSubmit: (value: string) => void;
}

const EditDescriptionModal = ({ data, onSubmit, ...baseModalProps }: IEditDescriptionModalProps) => {
  const [description, setDescription] = useState<string>('');

  const isDisableSaveButton = useMemo(() => {
    return data === description;
  }, [data, description]);

  useEffect(() => {
    setDescription(data);
  }, [data]);

  const handleChangeDescription = (value: string) => {
    if (value?.length > 250) return;

    setDescription(value);
  };

  const handleClose = () => {
    setDescription('');
    if (baseModalProps?.onClose) baseModalProps.onClose();
  };

  const handleSave = () => {
    if (isDisableSaveButton) return;

    onSubmit(description);
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEdit} />

          <span>Edit secret desciption</span>
        </>
      )}
      onClose={handleClose}
      {...baseModalProps}
    >
      <div className="modal-body">
        <Divider />

        <div className="input-container">
          <p>Description</p>

          <TextareaAtom
            required
            value={description}
            onChangeValue={handleChangeDescription}
            placeholder="Description of the key"
          />

          <p className="input-note">Maximum 250 characters</p>
        </div>
        
        <Divider />

        <div className="button-group">
          <button onClick={handleClose}>Cancel</button>

          <button className="save-btn" disabled={isDisableSaveButton} onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditDescriptionModal;
