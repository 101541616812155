import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IPutAwsResourcePolicyVariables {
  cloudId: number;
  region: string;
  reqData: {
    secretId: string;
    resourcePolicy: string;
  };
}

export interface IPutAwsResourcePolicyResponseData {
  putAwsResourcePolicy: IGqlResponseData<undefined>;
}

const putAwsResourcePolicyMutation = () =>
  useMutation<IPutAwsResourcePolicyResponseData, IPutAwsResourcePolicyVariables>(query);
export default putAwsResourcePolicyMutation;
