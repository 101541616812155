import { gql } from '@apollo/client';

const query = gql`
  mutation createAwsKey($reqData: AwsCreateKeyRequest, $cloudId: Long, $region: String) {
    createAwsKey(createKeyRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsCreateKeyResponse {
          keyMetadata {
            awsAccountId
            keyId
            arn
            creationDate
            enabled
            description
            keyUsage
            keyState
            deletionDate
            validTo
            origin
            customKeyStoreId
            cloudHsmClusterId
            expirationModel
            keyManager
            customerMasterKeySpec
            keySpec
            encryptionAlgorithms
            signingAlgorithms
            multiRegion
            multiRegionConfiguration {
              multiRegionKeyType
              primaryKey {
                arn
                region
              }
              replicaKeys {
                arn
                region
              }
            }
            pendingDeletionWindowInDays
            macAlgorithms
            xksKeyConfiguration {
              id
            }
          }
        }
      }
    }
  }
`;

export default query;