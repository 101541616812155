import ClearIcon from 'assets/svgs/v2/ico_input_clear.svg';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { getDateTimeFromDateAndTimeString } from '../../config';
import { DateType, FilterCriteria, FilterValueType, FromToType, MultiSelectType } from '../../types';
import './index.scss';

type IMacieSearchBarFilterTagPropType = {
  filterData: FilterCriteria;
  onRemoveFilter: (filterData: FilterCriteria) => any;
};

const MacieSearchBarFilterTag = ({ filterData, onRemoveFilter }: IMacieSearchBarFilterTagPropType) => {
  const getTagTextForValueType = useCallback((filterData: FilterCriteria) => {
    return `${filterData.display}: ${filterData.value}`;
  }, []);

  const getTagTextForMultiSelectType = useCallback((filterData: FilterCriteria) => {
    return `${filterData.display}: ${(filterData.value as MultiSelectType).map(each => each.display).join(', ')}`;
  }, []);

  const getTagTextForFromToType = useCallback((filterData: FilterCriteria) => {
    const filterDataValueAsFromToType = filterData.value as FromToType;
    if (filterDataValueAsFromToType.lte && filterDataValueAsFromToType.gte) {
      return `${filterData.display}: >= ${filterDataValueAsFromToType.gte} to <= ${filterDataValueAsFromToType.lte}`;
    }
    if (!filterDataValueAsFromToType.lte && !filterDataValueAsFromToType.gte) {
      return '';
    }
    if (filterDataValueAsFromToType.lte) {
      return `${filterData.display}: <= ${filterDataValueAsFromToType.lte}`;
    }
    return `${filterData.display}: >= ${filterDataValueAsFromToType.gte}`;
  }, []);

  const getTagTextForDateType = useCallback((filterData: FilterCriteria) => {
    const filterDataValueAsDateType = filterData.value as DateType;
    const fromValue = getDateTimeFromDateAndTimeString(
      filterDataValueAsDateType.gte?.date,
      filterDataValueAsDateType.gte?.time,
    );
    const toValue = getDateTimeFromDateAndTimeString(
      filterDataValueAsDateType.lte?.date,
      filterDataValueAsDateType.lte?.time,
    );
    if (fromValue && toValue) {
      const fromDateString = moment(fromValue).toISOString().slice(0, 16);
      const toDateString = moment(toValue).toISOString().slice(0, 16);
      return `${filterData.display}: >= ${fromDateString} to <= ${toDateString}`;
    }
    if (!fromValue && !toValue) {
      return '';
    }
    if (fromValue) {
      const fromDateString = moment(fromValue).toISOString().slice(0, 16);
      return `${filterData.display}: >= ${fromDateString}`;
    }
    if (toValue) {
      const toDateString = moment(toValue).toISOString().slice(0, 16);
      return `${filterData.display}: <= ${toDateString}`;
    }
  }, []);

  const tagText = useMemo(() => {
    switch (filterData.type) {
      case FilterValueType.VALUE_TYPE:
        return getTagTextForValueType(filterData);

      case FilterValueType.MULTI_SELECT_TYPE:
        return getTagTextForMultiSelectType(filterData);

      case FilterValueType.FROM_TO_TYPE:
        return getTagTextForFromToType(filterData);

      case FilterValueType.DATE_TYPE:
        return getTagTextForDateType(filterData);
    }
  }, [filterData]);

  return (
    <div className="tag-wrapper">
      <div className="tag-text">{tagText}</div>
      <div className="tag-clear-icon">
        <img
          src={ClearIcon}
          width={18}
          height={18}
          className="clear"
          onClick={() => {
            onRemoveFilter(filterData);
          }}
        />
      </div>
    </div>
  );
};

export default MacieSearchBarFilterTag;
