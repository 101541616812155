import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsGetResourceProfileResponse } from 'graphql/types/AwsResourceProfile';

export interface IGetAwsResourceProfileVariables {
  cloudId: number;
  region: string;
  request: {
    resourceArn: string;
  };
}

export interface IGetAwsResourceProfileResponseData {
  getAwsResourceProfile: IGqlResponseNewData<AwsGetResourceProfileResponse>;
}

const lazyGetAwsResourceProfile = () =>
  useLazyQuery<IGetAwsResourceProfileResponseData, IGetAwsResourceProfileVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsResourceProfile;
