import { gql } from '@apollo/client';

const query = gql`
  query getAwsDescribeBuckets($reqData: AwsDescribeBucketsRequest, $cloudId: Long, $region: String) {
    getAwsDescribeBuckets(describeBucketsRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      nextToken
      data {
        ... on AwsDescribeBucketsResponse {
          buckets {
            accountId
            allowsUnencryptedObjectUploads
            automatedDiscoveryMonitoringStatus
            bucketArn
            bucketCreatedAt
            bucketName
            classifiableObjectCount
            classifiableSizeInBytes
            errorCode
            errorMessage
            jobDetails {
              isDefinedInJob
              isMonitoredByJob
              lastJobId
              lastJobRunTime
            }
            lastAutomatedDiscoveryTime
            lastUpdated
            objectCount
            objectCountByEncryptionType {
              customerManaged
              kmsManaged
              s3Managed
              unencrypted
              unknown
            }
            publicAccess {
              effectivePermission
              permissionConfiguration {
                accountLevelPermissions {
                  blockPublicAccess {
                    blockPublicAcls
                    blockPublicPolicy
                    ignorePublicAcls
                    restrictPublicBuckets
                  }
                }
                bucketLevelPermissions {
                  accessControlList {
                    allowsPublicReadAccess
                    allowsPublicWriteAccess
                  }
                  blockPublicAccess {
                    blockPublicAcls
                    blockPublicPolicy
                    ignorePublicAcls
                    restrictPublicBuckets
                  }
                  bucketPolicy {
                    allowsPublicReadAccess
                    allowsPublicWriteAccess
                  }
                }
              }
            }
            region
            replicationDetails {
              replicated
              replicatedExternally
              replicationAccounts
            }
            sensitivityScore
            serverSideEncryption {
              kmsMasterKeyId
              type
            }
            sharedAccess
            sizeInBytes
            sizeInBytesCompressed
            tags {
              key
              value
            }
            unclassifiableObjectCount {
              fileType
              storageClass
              total
            }
            unclassifiableObjectSizeInBytes {
              fileType
              storageClass
              total
            }
            versioning
          }
          nextToken
        }
      }
    }
  }
`;
export default query;
