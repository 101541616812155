import { useState, useEffect, useMemo } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconEditRuleGroup from 'assets/svgs/v3/ico_policy_variables.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import DetailTable from 'pages/v2/Organ/Management/components/DetailTable';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';

type DisableKeyModalPropsType = {
  onSave?: () => void;
  aliases?: Array<string>;
  keyId?: string;
} & IBaseModalProps;

const DisableKeyModal = (props: DisableKeyModalPropsType) => {
  const { aliases, keyId, onSave, ...baseModalProps } = props;

  const [confirm, setConfirm] = useState(false);

  const aliasesRows = useMemo(() => {
    return aliases?.map(alias => {
      return {
        id: alias,
        aliasName: alias,
        keyId: keyId,
      };
    });
  }, [keyId]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditRuleGroup} />
          {'Disable key'}
        </>
      )}
      {...baseModalProps}
    >
      <div className="modal-content-container">
        <div className="warning-container">
          <Icon width={32} height={32} src={IconWarning} />
          <p>
            Disabling a key prevents you fro using it in any cryptographic operations. Also, disabling might prevent you
            from accessing resources in AWS services that use this key.
            <br />
            <br />
            To monitor attempts to use the key while it is disabled, create an Amazon CloudWatch alarm.
          </p>
        </div>
        <p className='table-title'>To make the key usable again, enable the key.</p>
        <div className="table-wrapper">
          <DetailTable
            data={aliasesRows ?? []}
            columns={[
              { field: 'aliasName', label: 'Alias name', sort: true },
              { field: 'keyId', label: 'Key ID', sort: true },
            ]}
            className="table-container"
            hasPagination={false}
          />
        </div>

        <div className="input">
          <CheckboxAtom
            label="Confirm that you want to disable this key."
            checked={confirm}
            onchange={() => setConfirm(!confirm)}
          />
        </div>

        <div className="button-group">
          <Button type={ButtonTypeEnum.GENERAL} onClick={() => baseModalProps?.onClose?.()} label="Cancel" />
          <Button disabled={!confirm} type={ButtonTypeEnum.PRIMARY} onClick={() => onSave?.()} label="Disable key" />
        </div>
      </div>
    </BaseModal>
  );
};

export default DisableKeyModal;
