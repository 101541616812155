import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';

export interface ICreateAwsAliasKmsVariables {
  reqData: {
    aliasName: string;
    targetKeyId: string;
  }
  cloudId: number;
  region: string;
}

export interface ICreateAwsAliasKmsResponseData {
  createAwsAliasKms: IGqlResponseNewData<undefined>;
}

/** 조직 추가 */
const createAwsAliasKmsMutation = () => useMutation<ICreateAwsAliasKmsResponseData, ICreateAwsAliasKmsVariables>(query);
export default createAwsAliasKmsMutation;