import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsDescribeClustersRequestType, AwsDescribeClustersResponseType } from 'graphql/types/AwsDescribeClusters';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsDescribeClustersVariable {
  cloudId: number;
  region: string;
  reqData: AwsDescribeClustersRequestType;
}

export interface IAwsDescribeClustersResponseData {
  getAwsDescribeClusters: IGqlResponseNewData<AwsDescribeClustersResponseType>;
}

const lazyGetAwsDescribeClusters = () =>
  useLazyQuery<IAwsDescribeClustersResponseData, IAwsDescribeClustersVariable>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsDescribeClusters;
