import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData, IGqlResponseNewData } from '../../types';

export type AwsDeleteFirewallRuleGroupVariables = {
  cloudId: number;
  region: string;
  request: {
    group?: string;
  };
};
 
export type AwsDeleteFirewallRuleGroupResponseData = {
  deleteAwsGroup: IGqlResponseNewData<undefined>;
};

const deleteFirewallRuleGroupMutation = () =>
  useMutation<AwsDeleteFirewallRuleGroupResponseData, AwsDeleteFirewallRuleGroupVariables>(query);
export default deleteFirewallRuleGroupMutation;
