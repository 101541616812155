import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import LabelInput from 'components/v2/LabelInput';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Icon from 'components/v2/atoms/Icon';
import IconSuccess from 'assets/svgs/ic_green_check.svg';
import IconDelete from 'assets/svgs/v3/ico_delete_network.svg';
import updateFirewallDeleteProtectionMutation, { AwsUpdateFirewallDeleteProtectionVariablesType } from 'graphql/mutations/updateFirewallDeleteProtection';
import { IAwsFirewall, IBaseFirewallModalProps } from '../../../types';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import deleteAwsFirewallMutation, { AwsDeleteFirewallVariablesType } from 'graphql/mutations/deleteAwsFirewall';

interface IDeleteResourceModalProps extends IBaseFirewallModalProps {

}

const DeleteResource = ({
  cloudId,
  region,
  data,
  setData = () => { },
  header,
  ...baseModalProps
}: IDeleteResourceModalProps) => {
  const { firewallArn = '', firewallName = '', deleteProtection = false } = data?.firewall || {};
  const updateToken = data?.updateToken || '';
  const [descriptionDelete, setDescriptionDelete] = useState<string>('');
  const [disabledDelProtection, setDisabledDelProtection] = useState<boolean>(false);

  const [updateFirewallDeleteProtection] = updateFirewallDeleteProtectionMutation();
  const [deleteAwsFirewall] = deleteAwsFirewallMutation();

  const handleDisableDelProtection = async () => {
    try {
      const variables: AwsUpdateFirewallDeleteProtectionVariablesType = {
        region,
        cloudId,
        request: {
          updateToken,
          firewallArn,
          firewallName,
          deleteProtection: false
        }
      }

      const response = await updateFirewallDeleteProtection({ variables });
      if (response.data?.updateAwsFirewallDeleteProtection.result === ErrorCode.SUCCESS) {
        setData((prevState: IAwsFirewall) => ({
          ...prevState!,
          updateToken: response.data?.updateAwsFirewallDeleteProtection.data[0].updateToken!,
          firewall: {
            ...data?.firewall!,
            deleteProtection: response.data?.updateAwsFirewallDeleteProtection.data[0].deleteProtection!
          }
        }))
        setDisabledDelProtection(true);
      } else {
        useToast(ErrorCode.UNKNOWN, "Disable delete protection failed.");
      }
    } catch {
      useToast(ErrorCode.UNKNOWN, "Disable delete protection failed.");
    }
  }


  const handleDeleteResource = async () => {
    try {
      const variables: AwsDeleteFirewallVariablesType = {
        cloudId,
        region,
        request: {
          firewallArn,
          firewallName
        }
      }

      const response = await deleteAwsFirewall({variables});
      const newData = response.data?.deleteAwsFirewall.data[0];
      setData((prevState: IAwsFirewall) => ({
        ...prevState!,
        firewall: newData?.firewall,
        firewallStatus: newData?.firewallStatus
      }))

      useToast(ErrorCode.SUCCESS, "Delete resource successfully.");

    } catch {
      useToast(ErrorCode.UNKNOWN, "Delete resource failed.");
    } finally {
      baseModalProps?.onClose?.();
    }
  }

  return (
    <BaseModal
      {...baseModalProps}
      title={() => <><Icon width={32} height={32} src={IconDelete} />{header}</>}
    >
      <div className="delete-resource-modal">
        <div className="delete-resource-desc">
          <p className="text-info">
            Delete <span className="text-bold">{data?.name}</span> ? This
            action cannot be undone. <br />
            {deleteProtection ? 'You must first disable this delete protection for the firewall.' : ''}
          </p>

          {deleteProtection && !disabledDelProtection &&
            <button className="btn-protection" onClick={handleDisableDelProtection}>
              Disable delete protection
            </button>
          }

          {
            disabledDelProtection && (
              <div className="flex a-center">
                <Icon width={15} height={15} src={IconSuccess} />
                <p className="text-success">Delete protection is disabled.</p>
              </div>)
          }
        </div>
        <div className="delete-resource-reason">
          <LabelInput
            title='To confirm deletion, type "delete" in the field.'
            required
            value={descriptionDelete}
            placeholder={'delete'}
            onChangeValue={val => setDescriptionDelete(val)}
          />
        </div>

        <div className="btn-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="btn-del" disabled={descriptionDelete !== 'delete'} onClick={handleDeleteResource}>
            Delete
          </button>
        </div>
      </div>

    </BaseModal>
  );
};

export default DeleteResource;
