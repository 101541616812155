import { ReviewPropsType } from '../types';
import { useCallback, useMemo } from 'react';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdTabLayout/types';
import { TextTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import { IP_ADDRESS_TYPE_DROPDOWN } from '../components/configs';
import { ENCRYPTION_CONFIGURATION_DATA } from '../configs';
import { ColumnType, RowType } from '@Types/v2/Table';
import StepContainer from 'pages/v2/Organ/Management/components/StepContainer';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import TagTab from 'pages/v2/Organ/Management/components/TagTab';

const Review = (props: ReviewPropsType) => {
  const {
    creationData,
    onCancelButtonClicked,
    onPreviousButtonClicked,
    onCreateButtonClicked,
    submitLoading,
    updateCreationData,
  } = props;

  const {
    firewallName,
    description,
    vpcId,
    listFirewallSubnet,
    deleteProtection,
    subnetChangeProtection,
    customizeEncryptionSetting,
    encryptionConfiguration,
    firewallPolicyArn,
    tags,
  } = creationData;

  const firewallDetailData = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      { id: 'firewall-name', type: TextTypeEnum.NORMAL, title: 'Name', description: firewallName },
      { id: 'firewall-description', type: TextTypeEnum.NORMAL, title: 'Description', description: description },
    ];
  }, [firewallName, description]);

  const getIpAddressType = useCallback((typeMapping: string): string => {
    return IP_ADDRESS_TYPE_DROPDOWN.find(item => item.value === typeMapping)?.name ?? '';
  }, []);

  const vpcAndSubnetData = useMemo((): IMgdDetailKeyValueProps[] => {
    const firewallSubnets = listFirewallSubnet.map(item => {
      return `${getIpAddressType(item.ipAddress.value.toString())} ${item.subnet.value}`;
    });
    return [
      { id: 'associate-vpc', type: TextTypeEnum.NORMAL, title: 'Associated VPC', description: vpcId },
      { id: 'firewall-subnet', type: TextTypeEnum.NORMAL, title: 'Firewall subnets', description: firewallSubnets },
    ];
  }, [listFirewallSubnet, vpcId]);

  const protectionAgainstChangeData = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      {
        id: 'delete-protection',
        type: TextTypeEnum.NORMAL,
        title: 'Delete protection',
        description: deleteProtection === true ? 'Enabled' : 'Disabled',
      },
      {
        id: 'subnet-change-protection',
        type: TextTypeEnum.NORMAL,
        title: 'Subnet change protection',
        description: subnetChangeProtection === true ? 'Enabled' : 'Disabled',
      },
    ];
  }, [deleteProtection, subnetChangeProtection]);

  const customerManagedKeyData = useMemo((): IMgdDetailKeyValueProps[] => {
    if (encryptionConfiguration && encryptionConfiguration.type === ENCRYPTION_CONFIGURATION_DATA.AWS_OWNED_KMS_KEY) {
      return [{ id: 'key-type', type: TextTypeEnum.NORMAL, title: 'Key type', description: 'AWS owned key' }];
    }

    return [
      { id: 'key-type', type: TextTypeEnum.NORMAL, title: 'Key type', description: 'AWS KMS key' },
      { id: 'key-id', type: TextTypeEnum.NORMAL, title: 'Key ID', description: encryptionConfiguration?.keyId },
    ];
  }, [customizeEncryptionSetting, encryptionConfiguration]);

  const firewallPolicyData = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      {
        id: 'associated-firewall-policy',
        type: TextTypeEnum.NORMAL,
        title: 'Associated firewall policy',
        description: firewallPolicyArn,
      },
    ];
  }, [firewallPolicyArn]);

  const tagRows = useMemo((): RowType[] => {
    return tags ?? [];
  }, [tags]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'keyStr', sort: true },
      { label: 'Value', field: 'valueStr', sort: true },
    ];
  }, []);

  return (
    <StepContainer>
      <DetailTab title={'Firewall details'} data={firewallDetailData} />

      <DetailTab title={'VPC and subnets'} data={vpcAndSubnetData} />

      <DetailTab title={'Protection against changes'} data={protectionAgainstChangeData} />

      <DetailTab title={'Customer managed key'} data={customerManagedKeyData} />

      <DetailTab title={'Firewall Policy'} data={firewallPolicyData} />

      <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} />

      <div className="button-group">
        <Button label="Cancel" onClick={onCancelButtonClicked} />
        <Button label="Previous" onClick={onPreviousButtonClicked} />
        <Button
          label="Create firewall"
          type={ButtonTypeEnum.PRIMARY}
          onClick={onCreateButtonClicked}
          loading={submitLoading}
        />
      </div>
    </StepContainer>
  );
};

export default Review;
