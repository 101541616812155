import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';

export const SEVERITY_DATA: DropdownListDataType[] = [
  {
    name: 'Low',
    value: 'LOW',
  },
  {
    name: 'Medium',
    value: 'MEDIUM',
  },
  {
    name: 'High',
    value: 'HIGH',
  },
];
