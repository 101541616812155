import { useEffect } from "react";
import { useAuth } from "contexts/AuthProvider";
import { ProjectInfo } from "graphql/types/ProjectInfo";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import './index.scss';
/**
 * <>lg 운영자인지 권한 확인 할 layout - 권한 없으면 주간 리포트로 돌려보내기</>
*/
const ManageLayout = () => {
  
  const navigate = useNavigate();
  const { role } = useAuth();
  const { organizationId, projectList } = useOutletContext<{ 
    organizationId: number | null;
    projectList: ProjectInfo[];
  }>();

  useEffect(() => {
    if (role === 'sy_admin') {
      if (!organizationId) {navigate('/join-organ');}
    } else {
      if (!organizationId) { navigate(`/join-organ`);} 
      else {navigate(`/organ/${organizationId}/weekly-report`);}
    }
  }, [organizationId, role])

  return (
    <div id="manage-layout">
      <Outlet
        context={{ projectList, organizationId }}
      />
    </div>
  )
}

export default ManageLayout;