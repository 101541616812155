import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { ICriteria } from '../createAwsAllowList';

export interface IUpdateAwsAllowListVariables {
  cloudId: number;
  region: string;
  request: {
    name: string;
    description?: string;
    criteria: ICriteria;
    id: string;
  };
}

export interface IUpdateAwsAllowListResponse {}

export interface IUpdateAwsAllowListResponseData {
  updateAwsAllowList: IGqlResponseData<IUpdateAwsAllowListResponse>;
}

const updateAwsAllowListMutation = () =>
  useMutation<IUpdateAwsAllowListResponseData, IUpdateAwsAllowListVariables>(query);
export default updateAwsAllowListMutation;
