import { gql } from '@apollo/client';

const query = gql`
  mutation createAwsFirewallPolicy($request: AwsCreateFirewallPolicyRequest, $cloudId: Long, $region: String) {
    createAwsFirewallPolicy(createFirewallPolicyRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsCreateFirewallPolicyResponse {
          updateToken
          firewallPolicyResponse {
            firewallPolicyName
            firewallPolicyArn
            firewallPolicyId
            description
            firewallPolicyStatus
            tags {
              key
              value
            }
            consumedStatelessRuleCapacity
            consumedStatefulRuleCapacity
            numberOfAssociations
            encryptionConfiguration {
              keyId
              type
            }
            lastModifiedTime
          }
        }
      }
    }
  }
`;

export default query;
