import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import { FirewallStatusMapping } from '../../configs';
import { getStrValueOrNull } from 'utils/Common';

interface IOverviewProps {
  isLoading: boolean;
  firewallStatus?: string;
  firewallPolicyName?: string;
  vpcId?: string;
}

function Overview({ isLoading, firewallStatus = '', firewallPolicyName = '', vpcId = '' }: IOverviewProps) {

  const summary: IMgdDetailKeyValueProps[] = [
    {
      title: 'Firewall status',
      description: FirewallStatusMapping[getStrValueOrNull(firewallStatus)]?.text,
    },
    {
      title: 'Associated firewall policy',
      description: getStrValueOrNull(firewallPolicyName),
    },
    {
      title: 'Associated VPC',
      description: getStrValueOrNull(vpcId),
    },
  ];

  return (
    <div className="user-group-details-tab">
      <DetailTab title="Overview" data={summary} isApiLoading={isLoading} />
    </div>
  );
}

export default Overview;
