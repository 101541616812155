import { useState, useMemo, useEffect, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconStateless from 'assets/svgs/v3/ico_stateless_actions.svg';
import IconStateful from 'assets/svgs/v3/ico_stateful_actions.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import ResetIcon from 'assets/svgs/v3/ico_reset.svg';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';
import lazyGetAwsAllListRuleGroupsFirewall from 'graphql/queries/getAwsAllListRuleGroupsFirewall';
import lazyGetAwsDescribeRuleGroup from 'graphql/queries/getAwsDescribeRuleGroup';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';

interface IUnmanagedStateProps extends IBaseModalProps {
  region: string;
  cloudId: number;
  header: string;
  subHeader?: string;
  icon?: string;
  dataRule: RowType[];
  columns: ColumnType[];
  btnTitle?: string;
  note?: string;
  creationApiLoading: boolean;
  handleUpdateRule: Function;
  onClose: () => void;
}

const UnmanagedStateModal = ({
  region,
  cloudId,
  header,
  subHeader,
  icon,
  dataRule,
  columns,
  btnTitle,
  note,
  creationApiLoading,
  handleUpdateRule,
  onClose,
  ...baseModalProps
}: IUnmanagedStateProps) => {
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 10,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [dataSelectd, setDataSelected] = useState<any>([]);
  const [getAwsAllListRuleGroupsFirewall, { loading: getAwsAllListRuleGroupsFirewallLoading }] =
    lazyGetAwsAllListRuleGroupsFirewall();

  const [getAwsDescribeRuleGroup] = lazyGetAwsDescribeRuleGroup();

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotal, mainTablePagination]);

  const handleGetMainDataRows = async (type: string) => {
    if (!region) return;
    const variables = {
      cloudId,
      region,
      request: {
        type: type,
      },
    };
    try {
      const { data: listRuleGroupsFirewall } = await getAwsAllListRuleGroupsFirewall({
        variables,
      });

      if (!listRuleGroupsFirewall?.getAwsAllListRuleGroupsFirewall?.data?.length) {
        return;
      }
      const fetchListAllRuleGroupsFirewall = listRuleGroupsFirewall.getAwsAllListRuleGroupsFirewall.data.map(
        async metaData => {
          const ruleGroupFirewallVariable = {
            cloudId,
            region,
            request: {
              ruleGroupName: metaData?.name || '',
              ruleGroupArn: metaData?.arn || '',
            },
          };

          return await getAwsDescribeRuleGroup({
            variables: ruleGroupFirewallVariable,
          }).then(({ data: ruleGroup }) => {
            const ruleGroupResponse = ruleGroup?.getAwsDescribeRuleGroup?.data?.[0]?.ruleGroupResponse;

            if (ruleGroupResponse) {
              return {
                id: metaData?.arn,
                name: metaData?.name,
                type: ruleGroupResponse?.type,
                capacity: ruleGroupResponse?.capacity,
              };
            }
          });
        },
      );

      const listAllRuleGroupFirewallData = await Promise.all(fetchListAllRuleGroupsFirewall);
      const ruleGroupFirewallDataResult: RowType[] = [];
      const mainTblRowsSet = new Set(dataRule?.map(item => item.name));
      const listAllRuleGroupFirewallDataFilter = listAllRuleGroupFirewallData.filter(
        item => !mainTblRowsSet.has(item?.name),
      );
      listAllRuleGroupFirewallDataFilter.map(firewall => {
        ruleGroupFirewallDataResult.push({
          id: firewall?.id,
          name: firewall?.name,
          type: firewall?.type,
          capacity: firewall?.capacity,
        });
      });
      setMainTblRows(ruleGroupFirewallDataResult);
      setMainTblTotal({
        totalPage: Math.ceil(ruleGroupFirewallDataResult.length / mainTablePagination.itemPerPage),
        totalElement: ruleGroupFirewallDataResult.length,
      });
    } catch (error) {
      /* empty */
    }
  };

  const onSavePolicy = async () => {
    console.log(dataSelectd);
    
    const result = await handleUpdateRule(dataSelectd);
    if (result) {
      onClose();
    }
  };

  useEffect(() => {
    handleGetMainDataRows(btnTitle?.toUpperCase() || '');
  }, [open, region, cloudId, btnTitle]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={!!icon ? IconStateful : IconStateless} />
          {header}
        </>
      )}
      onClose={onClose}
      {...baseModalProps}
    >
      <div className="unmanaged-state-modal-policy">
        <p className="note">{note}</p>
        <div className="unmanaged-state">
          <div className="row-1">
            <p className="text-title">Stateless rule group</p>
            <button className="reset-btn">
              <img
                src={ResetIcon}
                width={24}
                height={24}
                onClick={() => handleGetMainDataRows(btnTitle?.toUpperCase() || '')}
              />
            </button>
          </div>

          <div className="row-2">
            <Table
              rows={mainRowsCurrentPage}
              columns={columns}
              className={'table-state-rule'}
              useSelected={false}
              isLoading={getAwsAllListRuleGroupsFirewallLoading}
              reportCheckedList={(list: string[]) => {
                setDataSelected(list);
              }}
              reportSelected={(id: string) => {}}
              sortOption={{
                target: mainTablePagination.target,
                direction: mainTablePagination.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  updateTablePagination('target', target);
                  updateTablePagination('direction', dir);
                },
              }}
              horizontalScrollable={true}
            />
            {mainRowsCurrentPage?.length && !getAwsAllListRuleGroupsFirewallLoading ? (
              <div className="fleet-instance pagination-wrapper flex a-center">
                <p className="flex a-center">
                  Total <span>{mainTblTotal.totalElement}</span>
                </p>

                <TableManagePagination
                  ableFetchMore={false}
                  currentPage={mainTablePagination.currentPage}
                  updateCurrentPage={page => updateTablePagination('currentPage', page)}
                  totalPage={mainTblTotal.totalPage}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className={'button-group'}>
          <Button label="Cancel" type={ButtonTypeEnum.GENERAL} onClick={onClose} />
          <div className="save-btn">
            <Button
              label={`Add ${btnTitle} rule group`}
              type={ButtonTypeEnum.PRIMARY}
              loading={creationApiLoading}
              onClick={onSavePolicy}
            />
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
export default UnmanagedStateModal;
