import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import { IMgdDetailKeyValueProps } from 'layouts/v3/MgdLayout';
import { useState } from 'react';
import { FIREWALL_COMMOMVALUE_MAPPING } from '../../configs';
import EditKMSEncryptionModal from '../../EditKMSEncryptionModal';
import { getStrValueOrNull } from 'utils/Common';

const CustomerManagedKeys = ({ data, isLoading, isEdit }: any) => {
  const { ruleGroupResponse } = data || {};

  const { encryptionConfiguration } = ruleGroupResponse || {};

  const [isOpenEditCustomerManagedKey, setIsOpenEditCustomerManagedKey] = useState(false);

  const customerManagedKey: IMgdDetailKeyValueProps[] = [
    {
      title: 'Key type',
      description: getStrValueOrNull(encryptionConfiguration?.type),
    },
  ];

  const renderAction = (setIsOpen: (value: boolean) => void) => {
    return isEdit ? (
      <button className="button" onClick={() => setIsOpen(true)}>
        Edit
      </button>
    ) : null;
  };

  return (
    <>
      <DetailTab
        title="Customer managed key"
        data={customerManagedKey}
        actions={renderAction(setIsOpenEditCustomerManagedKey)}
        isApiLoading={isLoading}
      />

      {isOpenEditCustomerManagedKey ? (
        <EditKMSEncryptionModal
          header={'Edit KMS encryption settings'}
          open={isOpenEditCustomerManagedKey}
          onClose={() => setIsOpenEditCustomerManagedKey(false)}
        />
      ) : null}
    </>
  );
};

export default CustomerManagedKeys;
