import { IDefaultRes } from 'apis/v2/schema';
import { BaseDummyRes } from '..';
import { ICheckMfaReq, ICheckMfaRes, ILoginReq, ILoginRes } from './schema';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ErrorCode } from '@Types/error';

const Login = (instance: AxiosInstance) => (data: ILoginReq):Promise<AxiosResponse<ILoginRes, ILoginReq>> => {
  return instance.request({
    method: 'POST',
    url: '/login/checkIn',
    data: data
  });
};

const reqReset = (instance: AxiosInstance) => (userId: string):Promise<AxiosResponse<IDefaultRes, string>> => {
  return instance.request({
    method: 'POST',
    url: '/login/reqReset',
    data: {
      userId: userId
    }
  });
};

/** 이메일 인증 토큰 */
const getProjectMemberVerify = (instance: AxiosInstance) => (token:string, parameter: string):Promise<AxiosResponse<IDefaultRes, {token:string, param: string}>> => {
  return instance.request({
    method: 'GET',
    url: `${parameter}/auth/projectMemberVerify`,
    headers: { 'authToken': `${token}` }
  });
};

/** 이메일 변경 인증 이메일의 [본인 인증하기] */
// const getReqUpdateEmail = (instance: AxiosInstance) => (token: string):Promise<AxiosResponse<IDefaultRes, string>> => {
//   return instance.request({
//     method: 'GET',
//     url: /* [platformweb] */ '/user/reqUpdateEmail',
//     headers: {
//       'authToken': `${token}`
//     }
//   });
// };

const checkMfa = (instance: AxiosInstance) => (data: ICheckMfaReq):Promise<AxiosResponse<ICheckMfaRes, ICheckMfaReq>> => {
  return instance.request({
    method: 'POST',
    url: '/login/checkMfa',
    data: data
  });
};


const DummyLogin = (data: ILoginReq): Promise<BaseDummyRes<ILoginRes>> => {
  const promise = new Promise((resolve:(value: BaseDummyRes<ILoginRes>) => void) => {
    //일반 로그인 계정
    const normalLogin = {
      email: 'sunghakim@nineright.com',
      id: 'sunghakim',
      pw: 'admin12!'
    };
    //최초 로그인 계정
    const firstLogin = {
      email: 'ha1015@naver.com',
      id: 'ha1015',
      pw: 'admin12!'
    };

    if ((firstLogin.email === data.userId || firstLogin.id === data.userId) && firstLogin.pw === data.userPw) {
      //최초 로그인 시 새 조직만들기 페이지로 이동.
      resolve({
        data: {
          result: ErrorCode.PARTIAL_SUCCESS,
          data: [{
            userToken: 'firstLogin'
          }]
        }
      });
    } else if ((normalLogin.email === data.userId || normalLogin.id === data.userId) && normalLogin.pw === data.userPw) {
    //최초 로그인 아니면 프로젝트 페이지로 이동.
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: [{
            userToken: 'dummyToken'
          }]
        }
      });
    } else {
      resolve({ 
        data: {
          result: ErrorCode.UNKNOWN,
          data: [{
            userToken: ''
          }]
        } 
      });
    }
  });

  return promise;
};
const DummyReqReset = (userId: string): Promise<BaseDummyRes<IDefaultRes>> => {
  const promise = new Promise((resolve:(value: BaseDummyRes<IDefaultRes>) => void) => {
    const UserList = [
      {
        email: 'sunghakim@nineright.com',
        id: 'sunghakim',
        pw: 'admin12!'
      },{
        email: 'ha1015@naver.com',
        id: 'ha1015',
        pw: 'admin12!'
      }
    ];
    const findUser = UserList.find(d => d.email === userId || d.id === userId);
    if (findUser) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS
        }
      });
    } else {
      resolve({ 
        data: {
          result: ErrorCode.UNKNOWN
        } 
      });
    }
  });

  return promise;
};
const DummyCheckMfa = (data: ICheckMfaReq): Promise<BaseDummyRes<ICheckMfaRes>> => {
  const promise = new Promise((resolve:(value: BaseDummyRes<ICheckMfaRes>) => void) => {
    if (data.passcode === '123456') {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: [{
            userToken: '',
            mfaToken: ''
          }]
        }
      });
    } else {
      resolve({ 
        data: {
          result: ErrorCode.UNKNOWN,
          data: [{
            userToken: '',
            mfaToken: ''
          }]
        } 
      });
    }
  });

  return promise;
};

export const DummyAuth = {
  Login: DummyLogin,
  reqReset: DummyReqReset,
  checkMfa: DummyCheckMfa
};
const Auth = (instance: AxiosInstance) => ({
  getProjectMemberVerify: getProjectMemberVerify(instance),
  Login: Login(instance),
  reqReset: reqReset(instance),
  // getReqUpdateEmail: getReqUpdateEmail(instance),
  checkMfa: checkMfa(instance)
});
export default Auth;