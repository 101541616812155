import lazyGetAwsAllListGroups, { IAwsAllListGroupsPageVariables } from "graphql/queries/getAwsAllListGroups";
import { AwsResourceGroupPropsType } from "../types";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ColumnType, OrderDirection, RowType } from "@Types/v2/Table";
import { AwsGroupIdentifier } from "graphql/types/AwsListGroups";
import { IMgdTablePaginationProps, IMgdTotalPageProps } from "layouts/v3/MgdTabLayout/types";
import { handleFormatText } from "utils/Common";
import { orderAlphabetical } from "../../../Utils/Sorting";
import { variableCombineNextToken } from "../../../Utils";
import InputAtom from "components/v2/atoms/InputAtom";
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import Table from "components/v2/dataDisplay/Table";
import TableManagePagination from "components/v2/dataDisplay/TableManagePagination";
import { FilterPropsType } from "../../../type";

const ResourceGroupList = (props: AwsResourceGroupPropsType) => {
  const { cloudId, region, tabInformation, onResourceGroupSelected, onResourceGroupIdClicked, currentRouteState, setCurrentRouteState, onNavigateCreation } = props;

  // API
  const [getAwsAllListGroups, {loading: getListResourceGroupLoading}] = lazyGetAwsAllListGroups();

  // State
  const [mainTblTotalRows, setMainTblTotalRows] = useState<RowType[]>([]);
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [resourceGroupsSelected, setResourceGroupsSelected] = useState<AwsGroupIdentifier[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [resourceGroupFilterInputValue, setResourceGroupFilterInputValue] = useState<FilterPropsType>({
    name: 'name',
    value: currentRouteState?.value || '',
  });
  const [resourceGroupFilter, setResourceGroupFilter] = useState<FilterPropsType | undefined>(currentRouteState?.value ? resourceGroupFilterInputValue : undefined);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const mainTblColumns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <span
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  onResourceGroupIdClicked();
                  onResourceGroupSelected(row as AwsGroupIdentifier)
                }}
              >
                {handleFormatText(row?.groupName)}
              </span>
            }
          </>
        ),
      }
    ];
  }, []);


  const handleSearchFunction = useCallback(() => {
    if (resourceGroupFilterInputValue.value !== '') {
      setResourceGroupFilter({ name: resourceGroupFilterInputValue.name, value: resourceGroupFilterInputValue.value });
      const searchedRows = mainTblTotalRows?.filter(row => row[resourceGroupFilterInputValue.name] === resourceGroupFilterInputValue.value);
      setMainTblTotal({
        totalPage: Math.ceil(searchedRows.length / mainTablePagination.itemPerPage),
        totalElement: searchedRows.length,
      });
    } else {
      setResourceGroupFilter(undefined);
      setMainTblTotal({
        totalPage: Math.ceil(mainTblTotalRows.length / mainTablePagination.itemPerPage),
        totalElement: mainTblTotalRows.length,
      });
    }
  }, [resourceGroupFilterInputValue, mainTblTotalRows]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setMainTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchAwsAllResourceGroups(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    let totalRows = [...mainTblTotalRows];
    if (resourceGroupFilter && resourceGroupFilter?.value !== '' && resourceGroupFilter?.name !== '') {
      totalRows = totalRows?.filter(row => {
        return row?.[resourceGroupFilter?.name ?? '']?.includes(resourceGroupFilter?.value || '');
      });
    }

    return orderAlphabetical(totalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotalRows, mainTblTotal, mainTablePagination, mainNextToken, resourceGroupFilter]);

  const awsResourceGroupRequestVariables = useMemo((): IAwsAllListGroupsPageVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        maxResults: mainTablePagination.limit
      },
    };
  }, [cloudId, region, mainTablePagination]);

  const fetchAwsAllResourceGroups = useCallback(async (nextToken?: string, isInitial?: boolean) => {
    const combinedVariable = variableCombineNextToken(awsResourceGroupRequestVariables, nextToken);

    const data = await getAwsAllListGroups({ variables: combinedVariable }).then(({ data: listResourceGroups }) => {
      return listResourceGroups;
    });

    const listResourceGroups = data?.getAwsAllListGroups?.data ?? [];

    if (listResourceGroups.length > 0) {
      const resourceGroupRows: RowType[] = [];
      listResourceGroups.map(resource => {
        resourceGroupRows.push({
          ...resource,
          name: resource.groupName,
          id: resource.groupArn,
        })
      });
      
      let allResourceGroups: RowType[] = [];
      if (isInitial) {
        allResourceGroups = resourceGroupRows;
      } else {
        allResourceGroups = [...mainTblTotalRows, ...resourceGroupRows];
      }
      setMainTblTotalRows(allResourceGroups);
      setMainNextToken(data?.getAwsAllListGroups?.nextToken as string);
      setMainTblTotal({
        totalPage: Math.ceil(allResourceGroups.length / mainTablePagination.itemPerPage),
        totalElement: allResourceGroups.length,
      });
    }

  }, [awsResourceGroupRequestVariables, cloudId, region, mainTblTotalRows, mainTablePagination]);

  const handleResourceGroupSelected = useCallback((listId: string[]) => {
    const resourceGroups: AwsGroupIdentifier[] = [];
    listId.map(rowId => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);
      if (rowSelected) {
        resourceGroups.push(rowSelected as AwsGroupIdentifier);
      }
    });
    setResourceGroupsSelected(resourceGroups);
  }, [mainTblTotalRows]);

  const handleMainRowSelected = useCallback(
    (rowId: string) => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);

      if (!rowSelected) return;
      handleResourceGroupSelected([rowId]);
      onResourceGroupSelected(rowSelected as AwsGroupIdentifier);
    },
    [mainTblTotalRows],
  );

  // For initial data
  useEffect(() => {
    fetchAwsAllResourceGroups('', true);
  }, []);

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>{tabInformation.title}</p>
        </div>
        <div className="flex action a-center">
          {/* <DropdownAtom
            id="types-dropdown"
            className=""
            data={AWS_KEY_FILTER_DROPDOWN}
            value={resourceGroupFilterDropdownValue}
            handleClick={val => {
              setResourceGroupFilterDropdownValue(val);
              setResourceGroupFilterInputValue(prev => ({ ...prev, name: val.value as string }));
            }}
          /> */}
          <InputAtom
            type={'text'}
            value={resourceGroupFilterInputValue.value}
            defaultValue={''}
            onChangeValue={str => setResourceGroupFilterInputValue(prev => ({ name: prev?.name, value: str }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSearchFunction();
              }
            }}
          />

          <button className="action-btn" onClick={handleSearchFunction}>
            Search
          </button>
          <button className="action-btn" onClick={onNavigateCreation}>
            Create resource group
          </button>
          {/* <button className="action-btn" onClick={() => setDeleteModalVisible(true)} disabled={deleteButtonDisabled}>
            Delete
          </button> */}
        </div>
      </div>
      <div className="data-grid-wrap">
        <Table
          rows={mainRowsCurrentPage}
          columns={mainTblColumns}
          reportCheckedList={listId => handleResourceGroupSelected(listId)}
          reportSelected={id => handleMainRowSelected(id)}
          sortOption={{
            target: mainTablePagination.target,
            direction: mainTablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              updateTablePagination('target', target);
              updateTablePagination('direction', dir);
            },
          }}
          isLoading={getListResourceGroupLoading}
          horizontalScrollable={true}
        />
        {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && getListResourceGroupLoading === false && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center">
              Total <span>{mainTblTotal.totalElement}</span>
            </p>
            <TableManagePagination
              ableFetchMore={!!mainNextToken}
              currentPage={mainTablePagination.currentPage}
              updateCurrentPage={page =>
                setMainTablePagination(prev => ({
                  ...prev,
                  ['currentPage']: page,
                }))
              }
              totalPage={mainTblTotal.totalPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default ResourceGroupList;