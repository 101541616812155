import { useMutation } from '@apollo/client';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDeleteFirewallRequestType, AwsDeleteFirewallResponseType } from 'graphql/types/AwsFirewall';
import query from './query';

export type AwsDeleteFirewallVariablesType = {
  cloudId: number;
  region: string;
  request: AwsDeleteFirewallRequestType;
};

export type AwsDeleteFirewallResponseDataType = {
  deleteAwsFirewall: IGqlResponseNewData< AwsDeleteFirewallResponseType>;
};

const deleteAwsFirewallMutation = () => useMutation<AwsDeleteFirewallResponseDataType, AwsDeleteFirewallVariablesType>(query);
export default deleteAwsFirewallMutation;

