import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import LabelInput from 'components/v2/LabelInput';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Icon from 'components/v2/atoms/Icon';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import IconDelete from 'assets/svgs/v3/ico_delete_network.svg';

interface IDisassociateRuleGroupsModalProps extends IBaseModalProps {
  ruleGroups: string[];
  policyName: string;
  onDelete: () => void;
}

const DisassociateRuleGroups = ({
  ruleGroups,
  policyName,
  onDelete,
  ...baseModalProps
}:IDisassociateRuleGroupsModalProps) => {


  return (
    <BaseModal 
      title={() => <><Icon width={32} height={32} src={IconDelete} />Disassociate Rulegroup</>} 
      {...baseModalProps}
    >
      <div className="delete-web-acl-modal">
        <div className={`row-1 flex col`}>
          <div className="header">
            <div className="delete-warning flex a-center">
              <Icon width={32} height={32} src={IconWarning} />
              <p className="detail-warning">Are you sure you want to Disassociate the rule group(s): {ruleGroups.join(',')} from firewall policy {policyName}?</p>
            </div>
          </div>
        </div>
        <div className="row-2">
          <div className="button-group">
            <button className="big-sub-btn"
              onClick={baseModalProps.onClose}>Cancel</button>
            <button className="big-main-btn"
              onClick={onDelete} >Disassociate from policy</button>
          </div>
          
        </div>
      </div>
    </BaseModal>
  );
};

export default DisassociateRuleGroups;
