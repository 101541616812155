import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsRuleGroupMetadata } from 'graphql/types/AwsRuleGroupMetadata';

export interface IAwsAllListRuleGroupFirewallPageVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults?: number;
    nextToken?: string;
    managedType?: string;
    type?: string;
    scope?: string;
    filters?: {
      name: string;
      values: string[];
    };
    pageInfo?: Paging;
  };
}

export interface IAwsAllListRuleGroupsFirewallResponseData {
  getAwsAllListRuleGroupsFirewall: IGqlResponseNewData<AwsRuleGroupMetadata>;
}

const lazyGetAwsAllListRuleGroupsFirewall = () =>
  useLazyQuery<IAwsAllListRuleGroupsFirewallResponseData, IAwsAllListRuleGroupFirewallPageVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsAllListRuleGroupsFirewall;
