import { useState } from 'react';
import LabelInput from 'components/v2/LabelInput';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Icon from 'components/v2/atoms/Icon';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import IconDelete from 'assets/svgs/v3/ico_delete_network.svg';
import './index.scss';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/components/Button';

interface IDeleteRuleModalProps extends IBaseModalProps {
  header: string;
  titleWarning: string;
  note: string;
  valueInput?: string;
  onDelete: () => void;
  descriptionWarning?: string;
  loading?: boolean;
  confirmText?: string;
}

const DeleteRuleModal = (props: IDeleteRuleModalProps) => {
  const {
    header,
    titleWarning,
    note,
    valueInput,
    onDelete,
    descriptionWarning,
    loading,
    confirmText: confirmTextProp,
    ...baseModalProps
  } = props;
  const [confirmText, setConfirmText] = useState('');

  return (
    <BaseModal
      {...baseModalProps}
      title={() => (
        <>
          <Icon width={32} height={32} src={IconDelete} />
          {header}
        </>
      )}
    >
      <div className="delete-rule-modal">
        <div className="delete-rule">
          <div className="header">
            <div className="delete-warning">
              <Icon width={32} height={32} src={IconWarning} />
              <div className="content">
                <p className="detail-warning">{titleWarning}</p>
                {descriptionWarning && <p className="description-warning">{descriptionWarning}</p>}
              </div>
            </div>
            {!!valueInput || !!confirmTextProp ? (
              <>
                <LabelInput
                  title={note}
                  value={confirmTextProp ? confirmText : valueInput ?? ''}
                  onChangeValue={setConfirmText}
                  placeholder={confirmTextProp ?? ''}
                  required
                  noClear={!!valueInput}
                  disabled={!!valueInput}
                />
              </>
            ) : (
              <>
                <div className="note">
                  {note.split('\n').map((line, index) => (
                    <p key={index}>
                      {line}
                      <br />
                    </p>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>

        <div className={'button-group'}>
          <Button label="Cancel" onClick={() => baseModalProps?.onClose?.()} />
          <Button
            label="Delete"
            type={ButtonTypeEnum.PRIMARY}
            disabled={confirmTextProp ? confirmText !== confirmTextProp : false}
            onClick={onDelete}
            loading={loading}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteRuleModal;
