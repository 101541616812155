import { useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import { handleFormatText } from 'utils/Common';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import lazyGetAwsTLSInspectionConfiguration from 'graphql/queries/getAwsTLSInspectionConfiguration';
import Button from 'pages/v2/Organ/Management/WAF/WebACL/CreateWebACL/components/Button';

const InspectionConfiguration = ({ cloudId, region, firewallPolicy, setOpenEdit }: any) => {
  const { tlsInspectionConfigurationArn } = firewallPolicy || {};

  const [getAwsTLSInspectionConfiguration] = lazyGetAwsTLSInspectionConfiguration();

  const [isLoading, setIsLoading] = useState(false);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const handleGetAwsTLSInspectionConfiguration = async () => {
    if (!tlsInspectionConfigurationArn) return;

    try {
      const tlsInspectionConfigVar = {
        cloudId,
        region,
        request: {
          tlsInspectionConfigurationArn,
        },
      };
  
      const tlsInspectionConf = await getAwsTLSInspectionConfiguration({
        variables: tlsInspectionConfigVar,
      }).then(({ data: tlsInspectionConfData }) => {
        return tlsInspectionConfData?.getAwsTLSInspectionConfiguration;
      });
  
      const item = [
        {
          name: tlsInspectionConf?.data?.[0]?.tlsInspectionConfigurationResponse.tlsInspectionConfigurationName,
          description: tlsInspectionConf?.data?.[0]?.tlsInspectionConfigurationResponse.description,
        },
      ];
      setMainTblRows(item);
      setMainTblTotal({
        totalPage: Math.ceil(item.length / 10),
        totalElement: item.length,
      });
    } catch (error) { /* empty */ }
  };

  useEffect(() => {
    handleGetAwsTLSInspectionConfiguration();
  }, [tlsInspectionConfigurationArn]);

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        field: 'name',
        label: 'Name',
        sort: true,
        renderCell: row => {
          return <p className="link">{handleFormatText(row.name)}</p>;
        },
      },
      {
        field: 'description',
        label: 'Description',
        sort: true,
      },
    ];
  }, []);

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, mainTblTotal, tablePagination]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>TLS inspection configuration</p>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button disabled={mainTblRows.length === 0} label="Manage TLS inspection configuration" onClick={() => {setOpenEdit(true);} }/>
        </div>
      </div>

      {isLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {mainTblRows.length === 0 ? (
            <div className="data-grid-wrap">
              <p className="empty-row">No TLS inspection configurations</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <Table
                rows={mainRowsCurrentPage}
                columns={columns}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                isLoading={isLoading}
                horizontalScrollable={true}
              />

              {mainRowsCurrentPage?.length && !isLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>

                  <TableManagePagination
                    ableFetchMore={false}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page => updateTablePagination('currentPage', page)}
                    totalPage={mainTblTotal.totalPage}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InspectionConfiguration;
