import IconDelete from 'assets/svgs/v3/ico_delete_network.svg';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import LabelInput from 'components/v2/LabelInput';
import Icon from 'components/v2/atoms/Icon';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { useEffect, useMemo, useState } from 'react';
import './index.scss';

interface IDeleteResourceModalProps extends IBaseModalProps {
  header: string;
  titleWarning?: string;
  subWarning?: string;
  onDelete: () => void;
}

const DeleteResource = ({
  header,
  titleWarning,
  subWarning,
  onDelete,
  ...baseModalProps
}: IDeleteResourceModalProps) => {
  const [payload, setPayload] = useState<string>('');

  const ableDelete = useMemo(() => {
    if (payload !== 'delete') {
      return true;
    }
    return false;
  }, [payload]);

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      {...baseModalProps}
      title={() => (
        <>
          <Icon width={32} height={32} src={IconDelete} />
          {header}
        </>
      )}
    >
      <div className="delete-resource-modal">
        <div className="delete-resource">
          <div className="header">
            {titleWarning ? (
              <div className="delete-warning">
                <Icon width={32} height={32} src={IconWarning} />
                <div className="content">
                  <p className="detail-warning">{titleWarning}</p>
                  <p className="note">{subWarning}</p>
                </div>
              </div>
            ) : (
              <>
                <p className="delete-info">
                  Delete <span className="text-bold">test-firewall?</span> This action cannot be undone. You must first
                  diasble this delte protection for the firewall.
                </p>
                <button className="btn-protection" onClick={() => {}}>
                  Disable delete protection
                </button>
              </>
            )}
            <LabelInput
              title="To confirm deletions, type “delete” in this field."
              value={payload}
              placeholder={'delete'}
              onChangeValue={val => setPayload(val)}
            />
          </div>
        </div>

        <div className={'button-group'}>
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" disabled={ableDelete} onClick={() => {}}>
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteResource;
