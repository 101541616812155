import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { DropdownListDataType } from 'components/Dropdown/types';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import lazyGetAwsDescribeBuckets from 'graphql/queries/getAwsDescribeBuckets';
import lazyGetAwsResourceProfile, { IGetAwsResourceProfileVariables } from 'graphql/queries/getAwsResourceProfile';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { randomString } from 'utils/Common';
import { IAwsResourceCoverage, IMacieListProps } from '../../../types';
import MacieSearchBar from '../../SearchBar';
import { FINDING_STATUS, generateSearchParam } from '../../SearchBar/config';
import { FilterType } from '../../SearchBar/types';

const filterDropdown: DropdownListDataType[] = [
  { id: 1, name: 'Current', value: 'Current' },
  { id: 2, name: 'Archived', value: 'Archived' },
  { id: 3, name: 'All', value: 'All' },
];

interface IResourceCoverageAccessDeniedProps extends IMacieListProps<IAwsResourceCoverage> {}

const ResourceCoverageAccessDenied = (props: IResourceCoverageAccessDeniedProps) => {
  const {
    cloudId,
    region,
    currentRegion,
    currentRouteState,
    setCurrentRouteState,
    relatedCloudSelected,
    setDetailItem,
    setIsDetailPage,
    onNavigateCreation,
  } = props;

  const [getAwsDescribeBuckets] = lazyGetAwsDescribeBuckets();
  const [getAwsResourceProfile] = lazyGetAwsResourceProfile();

  const [isLoading, setIsLoading] = useState(false);
  const [nextToken, setNextToken] = useState<string>('');
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [searchData, setSearchData] = useState<any>(() =>
    generateSearchParam(FilterType.FINDING, currentRouteState?.value || [], FINDING_STATUS[0]),
  );
  const [prevSearchData, setPrevSearchData] = useState<any>({});

  useEffect(() => {
    return () => {
      if (!currentRouteState) return;

      setCurrentRouteState(null);
    };
  }, [currentRouteState]);

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Name',
        field: 'bucketName',
        sort: true,
      },
      {
        label: 'Account',
        field: 'accountId',
        sort: true,
      },
      {
        label: 'Issues',
        field: 'issues',
        sort: true,
      },
    ];
  }, [mainTblRows]);

  const getVariableData = useCallback(
    (usePrevSearchData?: boolean) => {
      let processedSearchData: any = {
        errorCode: {
          eq: ['ACCESS_DENIED'],
        },
      };
      let searchDataParam = usePrevSearchData ? prevSearchData : searchData;
      if (searchDataParam?.criteria) {
        processedSearchData = {
          ...processedSearchData,
          ...searchDataParam.criteria,
        };
      }

      return {
        cloudId: relatedCloudSelected.value,
        region: currentRegion.value as string,
        reqData: {
          maxResults: mainTablePagination.limit,
          criteria: JSON.stringify(processedSearchData),
        },
      };
    },
    [currentRegion, currentRouteState, relatedCloudSelected, searchData, prevSearchData],
  );

  const handleGetMainDataRows = useCallback(
    async (nextToken?: string, usePrevSearchData?: boolean) => {
      if (!region) return;

      try {
        setIsLoading(true);
        const { data: describeBuckets } = await getAwsDescribeBuckets({
          variables: variableCombineNextToken(getVariableData(usePrevSearchData), nextToken),
        });

        if (!describeBuckets?.getAwsDescribeBuckets?.data?.[0]?.buckets?.length) {
          setIsLoading(false);
          return;
        }

        const fetchListResourceProfile = describeBuckets?.getAwsDescribeBuckets?.data?.[0]?.buckets.map(
          async (e: any) => {
            const firewallVariable: IGetAwsResourceProfileVariables = {
              cloudId,
              region,
              request: {
                resourceArn: e?.bucketArn,
              },
            };

            const { data: resourceProfile } = await getAwsResourceProfile({
              variables: firewallVariable,
            });

            return resourceProfile?.getAwsResourceProfile?.data?.[0];
          },
        );

        const listResourceProfile = await Promise.all(fetchListResourceProfile);

        const data: any = describeBuckets?.getAwsDescribeBuckets?.data?.[0]?.buckets?.length
          ? describeBuckets?.getAwsDescribeBuckets?.data?.[0]?.buckets?.map((e: any, index: number) => {
              const { statistics } = listResourceProfile[index] || {};
              const {
                totalItemsSkippedInvalidEncryption,
                totalItemsSkippedInvalidKms,
                totalItemsSkippedPermissionDenied,
              } = statistics || {};

              const issues =
                totalItemsSkippedInvalidEncryption ||
                totalItemsSkippedInvalidKms ||
                totalItemsSkippedPermissionDenied ||
                'None';

              return {
                ...e,
                id: randomString(),
                resourceProfile: listResourceProfile[index],
                issues,
              };
            })
          : [];

        setNextToken(describeBuckets?.getAwsDescribeBuckets?.nextToken as string);
        setMainTblRows(data as RowType[]);
        setMainTblTotal({
          totalPage: Math.ceil(data?.length / mainTablePagination.limit),
          totalElement: data?.length,
        });
        setIsLoading(false);
        if (!usePrevSearchData) {
          setPrevSearchData(searchData);
        }
      } catch (error) {
        setIsLoading(false);
      }
    },
    [region, mainTblRows, mainTablePagination, getVariableData, searchData, prevSearchData],
  );

  const mainRowsCurrentPage = useMemo((): RowType[] => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!nextToken) {
      handleGetMainDataRows(nextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    setMainTblTotal({
      totalPage: Math.ceil(mainTblRows.length / mainTablePagination.itemPerPage),
      totalElement: mainTblRows.length,
    });

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, mainTablePagination, nextToken]);

  useEffect(() => {
    handleGetMainDataRows();
  }, [cloudId]);

  const handleSelectItem = useCallback(
    (id: string) => {
      const rowSelected = mainTblRows.find(row => row.id === id);

      if (!rowSelected) return;

      setDetailItem(rowSelected as IAwsResourceCoverage);
    },
    [mainTblRows],
  );

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Resource coverage</p>

          <p>
            These S3 buckets have permissions settings that prevent Macie from accessing the buckets and the buckets’
            objects.
          </p>
        </div>

        <div className="flex action a-center">
          <MacieSearchBar
            onSearch={() => {
              updateTablePagination('currentPage', 1);
              handleGetMainDataRows(undefined, false);
            }}
            onSearchDataChange={setSearchData}
            filterType={FilterType.RESOURCE_COVERAGE}
            initialAppliedFilters={currentRouteState?.value || []}
          />
        </div>
      </div>

      {!mainRowsCurrentPage?.length && !isLoading ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="data-grid-wrap">
          <Table
            rows={mainRowsCurrentPage}
            columns={columns}
            reportCheckedList={() => {}}
            reportSelected={id => handleSelectItem(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={isLoading}
            horizontalScrollable
          />

          {!!mainRowsCurrentPage?.length && !isLoading && (
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{mainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={!!nextToken}
                currentPage={mainTablePagination.currentPage}
                updateCurrentPage={page =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default ResourceCoverageAccessDenied;
