import { useEffect, useMemo, useState } from 'react';
import { LoadBalancerSummaryPropsType } from '../../types';
import {
  LOAD_BALANCER_DETAIL_TAB_LIST,
  LOAD_BALANCER_SUMMARY_TAB_LIST,
  detailTabKeys,
  detailTabTextDes,
} from '../config';
import DetailTab from 'pages/v2/Organ/Management/components/DetailTab';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import Listeners from '../Tabs/Listeners';
import NetworkMapping from '../Tabs/NetworkMapping';
import Security from '../Tabs/Security';
import Tags from '../Tabs/Tags';
import Attribute from '../Tabs/Attribute';
import { handleLinkClicked } from 'pages/v2/Organ/Management/Utils';
import { useNavigate } from 'react-router-dom';
import { LINK, SUBNET_FILTER } from 'pages/v2/Organ/Management/configs';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';
import { NETWORK_TAB_LIST } from 'pages/v2/Organ/Management/Network/configs';
import { getFormatedDateWithTimezone } from 'utils/Common';

const LoadBalancerSummary = (props: LoadBalancerSummaryPropsType) => {
  const { detailData, cloudId, region, isSummaryHidden } = props;
  const [tabSelected, setTabSelected] = useState(LOAD_BALANCER_SUMMARY_TAB_LIST[0]);

  const navigate = useNavigate();

  const title = useMemo(() => {
    return `Load balancer: ${detailData?.dnsName}`;
  }, [detailData]);

  const tabList = useMemo(() => {
    if (isSummaryHidden) {
      return LOAD_BALANCER_DETAIL_TAB_LIST;
    }

    return LOAD_BALANCER_SUMMARY_TAB_LIST;
  }, [isSummaryHidden]);

  useEffect(() => {
    if (isSummaryHidden) {
      setTabSelected(LOAD_BALANCER_DETAIL_TAB_LIST[0]);
    } else {
      setTabSelected(LOAD_BALANCER_SUMMARY_TAB_LIST[0]);
    }
  }, [isSummaryHidden, detailData]);

  const handleGetLink = (key: string, value: string | string[]) => {
    switch (key) {
      case detailTabKeys[6]:
        const arr = value?.toString()?.split(' ');
        if (!arr?.length) return null;

        const isSubnet = arr[0].includes('subnet');
        if (!isSubnet) return null;

        return handleLinkClicked({
          navigate,
          link: LINK.NETWORK,
          type: ManagementTypeEnum.NETWORK,
          tabId: NETWORK_TAB_LIST[2].id,
          key: SUBNET_FILTER[0].value.toString(),
          value: arr[0],
        });

      default:
        return null;
    }
  };

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    const detailTabData: IMgdDetailKeyValueProps[] = [];
    const detailTabDataOrdered: IMgdDetailKeyValueProps[] = [];
    if (detailData) {
      Object.entries(detailData).map(([key, value]) => {
        if (detailTabKeys.indexOf(key) > -1) {
          const textDes = detailTabTextDes.find(text => text.id == key);
          let desValue: string | string[] = '-';

          if (typeof value === 'string') {
            desValue = value.toString();

            if (key === detailTabKeys[7]) desValue = getFormatedDateWithTimezone(desValue, 'MMM DD, YYYY HH:MM');
          }

          if (key === detailTabKeys[6]) {
            desValue =
              detailData?.availabilityZones?.map(az => `${az.subnetId || ''} ${az.zoneName || ''}` || '-') || '-';
          }

          if (textDes) {
            detailTabData.push({
              id: key,
              type: textDes.type as TextTypeEnum,
              title: textDes.value,
              description: desValue,
              handleItemClick:
                textDes.type === TextTypeEnum.LINK ? (value: string) => handleGetLink(key, value || desValue) : null,
            });
          }
        }
      });

      // order data
      detailTabKeys.map(key => {
        const dataByKey = detailTabData.find(data => data.id == key);
        if (dataByKey) detailTabDataOrdered.push(dataByKey);
      });
    }
    return detailTabDataOrdered;
  }, [detailData]);

  const tabNode = useMemo(() => {
    if (isSummaryHidden) {
      switch (tabSelected) {
        case tabList[0]:
          return <Listeners data={detailData} region={region} cloudId={cloudId} />;
        case tabList[1]:
          return <NetworkMapping data={detailData} region={region} cloudId={cloudId} />;
        case tabList[2]:
          return <Security data={detailData} region={region} cloudId={cloudId} />;
        case tabList[3]:
          return <Attribute data={detailData} region={region} cloudId={cloudId} />;
        case tabList[4]:
          return <Tags data={detailData} region={region} cloudId={cloudId} />;
        default:
          return null;
      }
    }

    switch (tabSelected) {
      case tabList[0]:
        return <DetailTab title={'Details'} data={detailTabContentData} isApiLoading={false} />;
      case tabList[1]:
        return <Listeners data={detailData} region={region} cloudId={cloudId} />;
      case tabList[2]:
        return <NetworkMapping data={detailData} region={region} cloudId={cloudId} />;
      case tabList[3]:
        return <Security data={detailData} region={region} cloudId={cloudId} />;
      case tabList[4]:
        return <Attribute data={detailData} region={region} cloudId={cloudId} />;
      case tabList[5]:
        return <Tags data={detailData} region={region} cloudId={cloudId} />;
      default:
        return null;
    }
  }, [isSummaryHidden, tabSelected, detailTabContentData, tabList, region, cloudId]);

  if (!detailData) {
    return (
      <div className="detail-data">
        <p className="empty-page">Select a item</p>
      </div>
    );
  }

  return (
    <div className="box-summary">
      {!isSummaryHidden && <div className="box-summary-id">{title}</div>}

      <div className="detail-tab flex a-center">
        {tabList.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>

      <div className="content-tab">{tabNode}</div>
    </div>
  );
};

export default LoadBalancerSummary;
