import './index.scss';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import NetworkTitle from 'components/v3/NetworkTitle';
import lazyGetAwsRuleGroup, { IGetAwsRuleGroupVariables } from 'graphql/queries/getAwsRuleGroup';
import CustomResponse from './CustomResponseLayout';
import InfoLayout from './InfoLayout';
import {
  CLOUDFRONT_OPTION,
  CLOUDFRONT_REGION,
  CONTENT_TYPE_MAPPING,
  WafScopeEnum,
  ruleGroupDetailTabs,
} from '../../Commons/Constant';
import { AwsRuleGroup } from 'graphql/types/AwsRuleGroup';
import { AwsRuleGroupSummary } from 'graphql/types/AwsListRuleGroup';
import EditRuleGroupInfoModal from '../UpdateRuleGroup/EditRuleGroupInfo';
import { RowType } from '@Types/v2/Table';
import updateAwsRuleGroupMutation, { IUpdateRuleGroupVariables } from 'graphql/mutations/updateRuleGroup';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import _ from 'lodash';
import DeleteResponseBodyModal from '../UpdateRuleGroup/DeleteResponseBody';
import CustomResponseModal from '../../CustomResponseModal';
import CustomRuleModal from '../../CustomRuleModal';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { AwsRulelogType } from 'graphql/types/AwsUpdateRuleGroup';
import lazyGetAwsCheckCapacity from 'graphql/queries/getAwsCheckCapacity';
interface IRuleGroupDetailProps {
  cloudId: number;
  region: string;
  currentRuleGroup: AwsRuleGroupSummary;
  pageBackClick: () => void;
  selectedRegion: DropdownListDataType;
}

export interface ITabProps {
  id: string;
  name: string;
  title: string;
  description?: string;
}

export interface ICustomeResponseBody {
  id: string;
  name: string;
  contentType: string;
  responseBody: string;
  cellLableContentType: string;
}

const RuleGroupDetail = (props: IRuleGroupDetailProps) => {
  const { cloudId, region, currentRuleGroup, pageBackClick, selectedRegion } = props;

  const [getAwsRuleGroup] = lazyGetAwsRuleGroup();
  const [updateAwsRuleGroup] = updateAwsRuleGroupMutation();
  const [checkCapacity] = lazyGetAwsCheckCapacity();

  const [ruleGroupDetail, setRuleGroupDetail] = useState<AwsRuleGroup>();
  const [lockToken, setLockToken] = useState<string>('');
  const [activeTab, setActiveTab] = useState<ITabProps>(ruleGroupDetailTabs[0]);
  const [description, setDescription] = useState<string>(currentRuleGroup?.description ?? '');
  const [isEditRuleGroupInfoModalVisible, setEditRuleGroupInfoModalVisisble] = useState<boolean>(false);
  const [isEditRuleVisible, setEditRuleModalVisisble] = useState<boolean>(false);
  const [isCreateResponseBodyVisible, setCreateResponseBodyModalVisisble] = useState<boolean>(false);
  const [isEditResponseBodyVisible, setEditResponseBodyModalVisisble] = useState<boolean>(false);
  const [isDeleteResponseBodyVisible, setDeleteResponseBodyModalVisisble] = useState<boolean>(false);
  const [customResponseBodyRows, setCustomResponseBodyRows] = useState<ICustomeResponseBody[]>([]);
  const [responseBodyRowSelected, setResponseBodyRowSelected] = useState<string>('');
  const [isDeleteRuleModalVisible, setDeleteRuleModalVisisble] = useState<boolean>(false);
  const [isAddRuleModalVisible, setAddRuleModalVisible] = useState(false);

  const scope = useMemo((): string => {
    return region === CLOUDFRONT_OPTION.value ? WafScopeEnum.CLOUDFRONT : WafScopeEnum.REGIONAL;
  }, [region]);

  const regionValue = useMemo((): string => {
    return region === CLOUDFRONT_OPTION.value ? CLOUDFRONT_REGION : region;
  }, [region]);

  const handleActiveTabClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const tabTarget = e.currentTarget;
    if (!!tabTarget) {
      const tab: ITabProps = ruleGroupDetailTabs.find(tab => tab.id === tabTarget?.getAttribute('data-tab'))!;
      setActiveTab(tab);
    }
  }, []);

  const responseBodyRows = useMemo((): RowType[] => {
    const customResBodyMapped: ICustomeResponseBody[] = [];
    if (ruleGroupDetail?.customResponseBodies) {
      Object.entries(ruleGroupDetail?.customResponseBodies).map(([key, value]) => {
        const customeBody = {
          id: key,
          name: key,
          responseBody: value.content,
          contentType: value.contentType,
          cellLableContentType: CONTENT_TYPE_MAPPING[value.contentType].cellLable,
        };
        customResBodyMapped.push(customeBody);
      });
      setCustomResponseBodyRows(customResBodyMapped);
    }
    return customResBodyMapped;
  }, [ruleGroupDetail]);

  const responseBodySelected = useMemo((): ICustomeResponseBody => {
    return customResponseBodyRows.find(row => row.id === responseBodyRowSelected)!;
  }, [customResponseBodyRows, responseBodyRowSelected]);

  const getRuleGroup = useCallback(() => {
    const reqVariable: IGetAwsRuleGroupVariables = {
      cloudId: cloudId,
      region: regionValue,
      request: {
        id: currentRuleGroup.id,
        name: currentRuleGroup.name,
        scope: scope,
      },
    };
    getAwsRuleGroup({ variables: reqVariable }).then(({ data: awsRuleGroupResponse }) => {
      const ruleGroupData = awsRuleGroupResponse?.getAwsRuleGroup?.data?.[0];
      if (ruleGroupData) {
        const ruleGroup = ruleGroupData?.ruleGroup;
        setLockToken(ruleGroupData?.lockToken);
        if (ruleGroup) {
          setRuleGroupDetail(ruleGroup);
          setDescription(ruleGroup?.description);
        }
      }
    });
  }, [cloudId, regionValue, scope, currentRuleGroup]);

  const executeUpdateAwsRuleGroup = useCallback(
    async (reqVariable: IUpdateRuleGroupVariables, successMessage: string, failMessage: string) => {
      await updateAwsRuleGroup({ variables: reqVariable })
        .then(({ data: awsRuleGroupResponse }) => {
          const nextLockToken = awsRuleGroupResponse?.updateAwsRuleGroup?.data?.[0]?.nextLockToken;
          if (nextLockToken) {
            getRuleGroup();
            useToast(ErrorCode.SUCCESS, successMessage);
            setEditRuleGroupInfoModalVisisble(false);
          } else {
            useToast(ErrorCode.UNKNOWN, failMessage);
          }
        })
        .catch(e => {
          useToast(
            ErrorCode.UNKNOWN,
            e?.graphQLErrors?.[0]?.extensions?.messages?.[0]?.split('\n')?.[0] ?? failMessage,
          );
        });
    },
    [getRuleGroup],
  );

  const handleDeleteRule = useCallback(
    async (name: string) => {
      const newRules = ruleGroupDetail?.rules?.filter(rule => rule?.name !== name);

      if (!newRules) {
        return;
      }

      if (ruleGroupDetail) {
        const reqVariable: IUpdateRuleGroupVariables = {
          cloudId: cloudId,
          region: regionValue,
          reqData: {
            id: ruleGroupDetail?.id,
            name: ruleGroupDetail?.name,
            scope: scope,
            lockToken: lockToken,
            description: ruleGroupDetail?.description,
            rules: omitDeep(newRules, ['__typename']),
            visibilityConfig: _.omit(ruleGroupDetail?.visibilityConfig, '__typename'),
          },
        };

        await executeUpdateAwsRuleGroup(reqVariable, 'Deleted rule group successfully.', 'Delete rule group failed.');
        setDeleteRuleModalVisisble(false);
      }
    },
    [cloudId, regionValue, ruleGroupDetail, scope, lockToken, executeUpdateAwsRuleGroup],
  );

  const omitDeep = (collection: Object, excludeKeys: string[]) => {
    const omitFunc = (value: string) => {
      if (value && typeof value === 'object') {
        excludeKeys.forEach((key: string) => {
          delete value[key];
        });
      }
    };
    return _.cloneDeepWith(collection, omitFunc);
  };

  const handleEditRuleGroupSave = useCallback(
    (descriptionUpdated: string) => {
      if (ruleGroupDetail) {
        const reqVariable: IUpdateRuleGroupVariables = {
          cloudId: cloudId,
          region: regionValue,
          reqData: {
            id: ruleGroupDetail?.id,
            name: ruleGroupDetail?.name,
            scope: scope,
            lockToken: lockToken,
            description: descriptionUpdated,
            rules: omitDeep(ruleGroupDetail?.rules, ['__typename']),
            visibilityConfig: _.omit(ruleGroupDetail?.visibilityConfig, '__typename'),
          },
        };
        executeUpdateAwsRuleGroup(reqVariable, 'Edit rule group info successful.', 'Edit rule group info failed.');
      }
    },
    [ruleGroupDetail, executeUpdateAwsRuleGroup, cloudId, ruleGroupDetail, scope, lockToken, omitDeep],
  );

  useEffect(() => {
    getRuleGroup();
  }, []);

  const [editingRule, setEditingRule] = useState<AwsRulelogType>();

  const onEditRuleButtonClicked = useCallback(
    (name: string) => {
      const selectedRule = ruleGroupDetail?.rules?.find(item => item?.name === name);
      if (selectedRule) {
        setEditingRule(selectedRule);
        setEditRuleModalVisisble(true);
      }
    },
    [ruleGroupDetail],
  );

  const handleEditRule = useCallback(
    async (rule: AwsRulelogType) => {
      setEditRuleModalVisisble(false);

      const editingRuleIndex =
        ruleGroupDetail?.rules?.findIndex((item: AwsRulelogType) => item?.name === editingRule?.name) ?? -1;

      if (editingRuleIndex < 0) {
        return;
      }

      let newRules = [...(ruleGroupDetail?.rules ?? [])];
      newRules[editingRuleIndex] = { ...rule };

      if (ruleGroupDetail) {
        const reqVariable: IUpdateRuleGroupVariables = {
          cloudId: cloudId,
          region: regionValue,
          reqData: {
            id: ruleGroupDetail?.id,
            name: ruleGroupDetail?.name,
            scope: scope,
            lockToken: lockToken,
            description: !!ruleGroupDetail?.description ? ruleGroupDetail?.description : 'description',
            rules: omitDeep(newRules, ['__typename']),
            visibilityConfig: _.omit(ruleGroupDetail?.visibilityConfig, '__typename'),
          },
        };

        await executeUpdateAwsRuleGroup(reqVariable, 'Edited rule successfully.', 'Edit rule failed.');
      }
    },
    [ruleGroupDetail, editingRule, cloudId, regionValue, scope, lockToken],
  );

  const handleAddRule = useCallback(
    async (rule: AwsRulelogType) => {
      setAddRuleModalVisible(false);

      let newRules = [...(ruleGroupDetail?.rules ?? []), rule];
      newRules = newRules.map((rule, index: number) => ({ ...rule, priority: index }));

      if (ruleGroupDetail) {
        const reqVariable: IUpdateRuleGroupVariables = {
          cloudId: cloudId,
          region: regionValue,
          reqData: {
            id: ruleGroupDetail?.id,
            name: ruleGroupDetail?.name,
            scope: scope,
            lockToken: lockToken,
            description: !!ruleGroupDetail?.description ? ruleGroupDetail?.description : 'description',
            rules: omitDeep(newRules, ['__typename']),
            visibilityConfig: _.omit(ruleGroupDetail?.visibilityConfig, '__typename'),
          },
        };

        await executeUpdateAwsRuleGroup(reqVariable, 'Added rule successfully.', 'Add rule failed.');
      }
    },
    [ruleGroupDetail, cloudId, regionValue, scope, lockToken],
  );

  const handleCustomResponseBody = useCallback(
    async (type: string, data?: { name: string; responseBody: string; contentType: string }) => {
      let message = {
        success: '',
        fail: '',
      };

      let customResponseBodies = undefined;

      switch (type) {
        case 'create':
          {
            setCreateResponseBodyModalVisisble(false);
            message = {
              success: 'Created custom response body successfully.',
              fail: 'Create custom response body failed.',
            };
            customResponseBodies = {
              ...ruleGroupDetail?.customResponseBodies,
              [data?.name ?? '']: {
                content: data?.responseBody,
                contentType: data?.contentType,
              },
            };
          }
          break;

        case 'update':
          {
            setEditResponseBodyModalVisisble(false);
            message = {
              success: 'Updated custom response body successfully.',
              fail: 'Update custom response body failed.',
            };
            customResponseBodies = {
              ..._.omit(ruleGroupDetail?.customResponseBodies, responseBodySelected?.name),
              [data?.name ?? '']: {
                content: data?.responseBody,
                contentType: data?.contentType,
              },
            };
          }
          break;

        case 'delete':
          {
            setDeleteResponseBodyModalVisisble(false);
            message = {
              success: 'Deleted custom response body successfully.',
              fail: 'Delete custom response body failed.',
            };
            customResponseBodies = {
              ..._.omit(ruleGroupDetail?.customResponseBodies, responseBodySelected?.name),
            };
          }
          break;

        default:
          break;
      }

      if (ruleGroupDetail) {
        const reqVariable: IUpdateRuleGroupVariables = {
          cloudId: cloudId,
          region: regionValue,
          reqData: {
            id: ruleGroupDetail?.id,
            name: ruleGroupDetail?.name,
            scope: scope,
            apiAction: type,
            customResponseBodies,
          },
        };

        await executeUpdateAwsRuleGroup(
          _.omitBy(reqVariable, _.isUndefined || _.isEmpty) as IUpdateRuleGroupVariables,
          message.success,
          message.fail,
        );

        getRuleGroup();
      }
    },
    [ruleGroupDetail, cloudId, regionValue, scope, getRuleGroup, responseBodySelected],
  );

  const renderShowing = useMemo(() => {
    if (ruleGroupDetail) {
      switch (activeTab) {
        default:
          return (
            <InfoLayout
              ruleGroup={ruleGroupDetail}
              onEditRuleGroupInfo={() => setEditRuleGroupInfoModalVisisble(true)} //TODO: need BE update API
              onEditRule={onEditRuleButtonClicked}
              onAddRule={() => setAddRuleModalVisible(true)}
              onDeleteRule={handleDeleteRule}
              isDeleteRuleModalVisible={isDeleteRuleModalVisible}
              setDeleteRuleModalVisisble={setDeleteRuleModalVisisble}
            />
          );
        case ruleGroupDetailTabs[1]:
          return (
            <CustomResponse
              customResponseBodyRows={responseBodyRows}
              onSelectedCustomReponseBody={setResponseBodyRowSelected}
              onEditResponseBody={() => setEditResponseBodyModalVisisble(true)}
              onDeleteResponseBody={() => setDeleteResponseBodyModalVisisble(true)}
              onCreateResponseBody={() => setCreateResponseBodyModalVisisble(true)}
            />
          );
      }
    }
  }, [activeTab, ruleGroupDetailTabs, ruleGroupDetail, responseBodyRows, isDeleteRuleModalVisible]);

  return (
    <Fragment>
      <div id="rule-group-detail">
        <div className="row-1 flex j-between a-center">
          <div className="flex j-start a-center" id="title">
            <NetworkTitle
              pageTitle={'WAF Rule groups: ' + currentRuleGroup.name}
              id={321}
              name={'WAF Rule groups: ' + currentRuleGroup.name}
              hasPrefixIcon={false}
              hasFavorite={false}
              pageBackClick={pageBackClick}
            />
          </div>
        </div>
        <div className="row-2 flex a-center">
          {ruleGroupDetailTabs.map(tab => {
            return (
              <button
                className={`tab-items ${activeTab?.id === tab.id && 'active'}`}
                key={tab.id}
                data-tab={tab.id}
                onClick={e => handleActiveTabClick(e)}
              >
                <p>{tab.name}</p>
              </button>
            );
          })}
        </div>
        {renderShowing}
      </div>
      {isEditRuleGroupInfoModalVisible ? (
        <EditRuleGroupInfoModal
          open={isEditRuleGroupInfoModalVisible}
          description={description}
          onClose={() => setEditRuleGroupInfoModalVisisble(false)}
          onSave={description => handleEditRuleGroupSave(description)}
        />
      ) : null}

      {isEditResponseBodyVisible ? (
        <CustomResponseModal
          header={`Edit ${responseBodySelected?.name}`}
          name={responseBodySelected?.name}
          contentType={responseBodySelected?.contentType}
          responseBody={responseBodySelected?.responseBody}
          open={isEditResponseBodyVisible}
          onClose={() => setEditResponseBodyModalVisisble(false)}
          onSave={data => handleCustomResponseBody('update', data)}
        />
      ) : null}
      {isCreateResponseBodyVisible && (
        <CustomResponseModal
          header={'Create custom response body'}
          open={isCreateResponseBodyVisible}
          onClose={() => setCreateResponseBodyModalVisisble(false)}
          name={''}
          contentType={CONTENT_TYPE_MAPPING['APPLICATION_JSON'].value}
          responseBody={''}
          onSave={data => handleCustomResponseBody('create', data)}
        />
      )}
      {isDeleteResponseBodyVisible ? (
        <DeleteResponseBodyModal
          resourceName={responseBodySelected?.name}
          open={isDeleteResponseBodyVisible}
          onClose={() => setDeleteResponseBodyModalVisisble(false)}
          onDelete={() => handleCustomResponseBody('delete')}
        />
      ) : null}
      {isEditRuleVisible && (
        <CustomRuleModal
          isOpen={isEditRuleVisible}
          cloudId={cloudId}
          selectedRegion={selectedRegion}
          onEditRule={handleEditRule}
          onClose={() => setEditRuleModalVisisble(false)}
          isRuleBuilderOnly
          isEditing
          rule={editingRule}
        />
      )}
      {isAddRuleModalVisible && (
        <CustomRuleModal
          isOpen={isAddRuleModalVisible}
          cloudId={cloudId}
          selectedRegion={selectedRegion}
          onAddRule={handleAddRule}
          onClose={() => setAddRuleModalVisible(false)}
          isRuleBuilderOnly
        />
      )}
    </Fragment>
  );
};
export default RuleGroupDetail;
