import './index.scss';
import { useState } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Table from 'components/v2/dataDisplay/Table';
import { RowType } from '@Types/v2/Table';

interface IAddRuleGroupsProps extends IBaseModalProps {
  rows: RowType[];
  onAdd: (values: RowType[]) => void;
}

const AddRuleGroups = ({ rows, onAdd, ...baseModalProps }: IAddRuleGroupsProps) => {
  const [checkedList, setCheckedList] = useState<string[]>([]);

  const handleAddRuleGroups = () => {
    if (!checkedList?.length) return;

    const arr = rows.filter(e => checkedList.includes(`${e.id}`));

    onAdd(arr);
    baseModalProps?.onClose?.();
  };

  return (
    <BaseModal title={() => <>Add from existing rule groups</>} {...baseModalProps}>
      <div className="add-rule-groups-modal">
        <Table
          columns={[
            {
              label: 'Name',
              field: 'name',
              sort: true,
            },
          ]}
          rows={rows}
          reportCheckedList={setCheckedList}
        />

        <div className={'button-group'}>
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="btn-save" disabled={!checkedList?.length} onClick={handleAddRuleGroups}>
            Add rule groups
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default AddRuleGroups;
