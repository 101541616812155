import { gql } from '@apollo/client';

const query = gql`
  mutation updateAwsFirewallEncryptionConfiguration($request: AwsUpdateFirewallEncryptionConfigurationRequest, $cloudId: Long, $region: String) {
    updateAwsFirewallEncryptionConfiguration(
      updateFirewallEncryptionConfigurationRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsUpdateFirewallEncryptionConfigurationResponse {
          firewallArn
          firewallName
          encryptionConfiguration
          updateToken
        }
      }
    }
  }
`;
export default query;