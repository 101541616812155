import './index.scss';
import { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import CopyableText from 'components/v3/CopyableText';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import MgdTooltip from 'components/v2/MgdTooltip';
import { getStrValueOrNull, randomString } from 'utils/Common';

export type DetailTabPropsType = {
  title: string;
  isApiLoading?: boolean;
  description?: string;
  data: IMgdDetailKeyValueProps[];
};

const DetailTab = ({ title, description, data, isApiLoading }: DetailTabPropsType) => {
  const detailInfoRef = useRef<HTMLDivElement>(null);

  const [detailInfoWidth, setDetailInfoWidth] = useState(0);

  useEffect((): any => {
    const observer = new ResizeObserver(entries => {
      setDetailInfoWidth(entries[0].contentRect.width);
    });

    if (detailInfoRef.current) {
      observer.observe(detailInfoRef.current);
      return () => detailInfoRef.current && observer.unobserve(detailInfoRef.current);
    }
    return () => detailInfoRef.current && observer.unobserve(detailInfoRef.current);
  }, [isApiLoading]);

  const renderLinkNode = useCallback((description: string, handleItemClick: any): ReactNode => {
    if (!description) {
      return <p>-</p>;
    }
    return (
      <p
        className="description link"
        onClick={e => {
          e.stopPropagation();
          handleItemClick?.(description);
        }}
      >
        {description}
      </p>
    );
  }, []);

  const renderValue = useCallback((detail: IMgdDetailKeyValueProps) => {
    switch (detail.type) {
      case TextTypeEnum.COPY: {
        if (detail.description instanceof Array) {
          return (
            <Fragment>
              {detail.description.map((item, index) => {
                return <CopyableText text={item} key={`detail-tab-copy-${index}`} />;
              })}
            </Fragment>
          );
        }

        return <CopyableText text={detail.description} />;
      }

      case TextTypeEnum.STATUS: {
        return (
          <div className="status">
            <p>{detail.description}</p>
          </div>
        );
      }

      case TextTypeEnum.LINK: {
        if (detail.description instanceof Array) {
          if (detail.isTooltip) {
            return (
              <MgdTooltip id={randomString()} title={`${detail.description?.length} subnets`}>
                {detail.description.map((item: any, index: number) => {
                  return <Fragment key={`detail-description-tooltip-${index}`}>{renderLinkNode(item, detail.handleItemClick)}</Fragment>;
                })}
              </MgdTooltip>
            );
          }

          return (
            <Fragment>
              {detail.description.map((item, index) => {
                return <Fragment key={`detail-tab-link-${index}`}>{renderLinkNode(item, detail.handleItemClick)}</Fragment>;
              })}
            </Fragment>
          );
        }

        return renderLinkNode(detail.description, detail.handleItemClick);
      }

      case TextTypeEnum.COPY_LINK: {
        if (detail.description instanceof Array) {
          return detail.description.map((des, idx) => (
            <CopyableText key={`detail-tab-copy-${idx}`} text={des} onTextClicked={() => detail?.handleItemClick?.()} />
          ));
        }

        if (!detail.description || detail.description === '-') {
          return <CopyableText text={'-'} />;
        }

        return (
          <CopyableText
            text={detail.description}
            onTextClicked={() => (detail?.handleItemClick ? detail.handleItemClick() : {})}
          />
        );
      }

      case TextTypeEnum.NORMAL:
      default:
        if (detail.description instanceof Array) {
          return (
            <Fragment>
              {detail.description.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </Fragment>
          );
        }

        return <p>{getStrValueOrNull(detail.description)}</p>;
    }
  }, []);

  if (isApiLoading) {
    return (
      <div className="detail-info">
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      </div>
    );
  }

  return (
    <div className="detail-info">
      <Fragment>
        <div className="detail-info-title">
          <p>{title}</p>
          <p>{description}</p>
        </div>
        <div className="detail-info-content" ref={detailInfoRef}>
          {data?.map((detail: IMgdDetailKeyValueProps, index: number) => (
            <div
              className={`col ${
                (index + 1) % Math.floor((detailInfoWidth > 1160 ? 1160 : detailInfoWidth) / 290) === 0 ? 'last' : ''
              }`}
              key={`${index}`}
            >
              <div className="content">
                <div className="title">
                  <p>{detail.title}</p>
                </div>
                <div className="value">{renderValue(detail)}</div>
              </div>
            </div>
          ))}
        </div>
      </Fragment>
    </div>
  );
};

export default DetailTab;
