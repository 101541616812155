import { gql } from '@apollo/client';

const query = gql`
  mutation disassociateAwsSubnets($request: AwsDisassociateSubnetsRequest, $cloudId: Long, $region: String) {
    disassociateAwsSubnets(
      disassociateSubnetsRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsDisassociateSubnetsResponse {
          firewallArn
          firewallName
          subnetMappings {
            subnetId
            ipAddressType
          }
          updateToken
        }
      }
    }
  }
`;
export default query;