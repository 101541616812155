import './index.scss';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { useOutletContext } from 'react-router-dom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import MgdTabLayout from 'layouts/v3/MgdTabLayout';
import { MgdTabLayoutTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import { LIST_UP_TAB_LIST, PageNodeEnum } from './configs';
import { FirewallPropsType, IAwsFirewall } from './types';
import FirewallListUp from './components/Firewall/ListUp';
import ResourceGroupList from './components/ResourceGroup/ResourceGroupList';
import { AwsGroupIdentifier } from 'graphql/types/AwsListGroups';
import FirewallSummary from './components/Firewall/Summary';
import FirewallDetail from './components/Firewall/Detail';
import FirewallCreation from './components/Firewall/FirewallCreation';
import FirewallPolicy from './components/FirewallPolicy/ListUp';
import FirewallPolicyCreation from './components/FirewallPolicy/FirewallPolicyCreation';
import FirewallPolicySummary from './components/FirewallPolicy/Summary';
import FirewallPolicyDetail from './components/FirewallPolicy/Detail';
import RuleGroup from './components/RuleGroup/ListUp';
import RuleGroupSummary from './components/RuleGroup/Summary';
import RuleGroupDetail from './components/RuleGroup/Detail';
import ResourceGroupDetail from './components/ResourceGroup/ResourceGroupDetail';
import TlsInspectionConfigList from './components/TlsInspectionConfig/TlsInspectionConfigList';
import { AwsTLSInspectionConfigurationMetadata } from 'graphql/types/AwsTLSInspectionConfigurationMetadata';
import TlsInspectionConfigDetail from './components/TlsInspectionConfig/TlsInspectionConfigDetail';
import AwsRuleGroup from './components/AwsRuleGroup/ListUp';
import RuleGroupCreation from './components/RuleGroup/RuleGroupCreation';
import AwsRuleGroupDetail from './components/AwsRuleGroup/Detail';
import TlsInspectionConfigSummary from './components/TlsInspectionConfig/TlsInspectionConfigSummary';
import updateFirewallRuleGroupMutation from 'graphql/mutations/updateFirewallRuleGroup';

const Firewall = (props: FirewallPropsType) => {
  const {
    onBackButtonClicked,
    projectId,
    cloudName,
    regionCode,
    regions,
    recentRelatedClouds,
    relatedCloudSelected,
    recentRelatedCloudOnChange,
    currentRouteState,
    setCurrentRouteState,
  } = props;

  // Context
  const { defaultRegionList, updateDefaultRegionList } = useOutletContext<{
    defaultRegionList: { projectId: number; cloudId: number; name: string; value: string }[];
    updateDefaultRegionList: (projectId: number, cloudId: number, name: string, value: string) => void;
  }>();

  // API
  const [getEnumTypeCode] = lazyGetEnumTypeCode();

  // State
  const [pageType, setPageType] = useState<PageNodeEnum>(PageNodeEnum.MGD_LIST);
  const [currentRegion, setCurrentRegion] = useState<DropdownListDataType>({ name: '', value: '' });
  const [regionList, setRegionList] = useState<DropdownListDataType[]>([]);
  const [mainTabSelected, setMainTabSelected] = useState<IMgdTabProps>(LIST_UP_TAB_LIST[0]);

  const [detailTlsInspectionConfig, setDetailTlsInspectionConfig] = useState<
    AwsTLSInspectionConfigurationMetadata | undefined
  >();
  const [detailResourceGroup, setDetailResourceGroup] = useState<AwsGroupIdentifier | undefined>();
  const [firewall, setFirewall] = useState<IAwsFirewall | null>(null);
  const [firewallPolicy, setFirewallPolicy] = useState<any>(null);
  const [ruleGroup, setRuleGroup] = useState<any>(null);
  const [awsRuleGroup, setAwsRuleGroup] = useState<any>(null);

  useEffect(() => {
    if (!currentRouteState) return;

    const currentTab = LIST_UP_TAB_LIST.find(e => e.id === currentRouteState.tabId);

    if (currentTab) setMainTabSelected(currentTab);
  }, [currentRouteState]);

  const handleClearState = () => {
    setFirewall(null);
    setFirewallPolicy(undefined);
    setRuleGroup(undefined);
    setAwsRuleGroup(undefined);
    setDetailResourceGroup(undefined);
    setDetailTlsInspectionConfig(undefined);
    setPageType(PageNodeEnum.MGD_LIST);
  };

  useEffect(() => {
    handleClearState();
  }, [mainTabSelected]);

  const cloudId = useMemo((): number => {
    return Number(relatedCloudSelected.value);
  }, [relatedCloudSelected]);

  const regionValue = useMemo((): string => {
    return currentRegion?.value?.toString();
  }, [currentRegion]);

  // Get initial region
  const getInitialRegion = useCallback(() => {
    const hasDefaultRegion = defaultRegionList.find(r => {
      return r.projectId === projectId && r.cloudId === cloudId;
    });

    if (!!hasDefaultRegion) {
      setCurrentRegion({
        name: hasDefaultRegion.name,
        value: hasDefaultRegion.value,
      });
    } else {
      getEnumTypeCode({
        variables: { text: regionCode || 'AwsRegionCode' },
      }).then(res => {
        if (res.data) {
          const regionsRes: DropdownListDataType[] = res.data.getEnumTypeCode.data
            .filter(val => regions.includes(val.value))
            .map(val => ({
              ...val,
              name: val.value,
            }));
          setRegionList(regionsRes);
          const region = regionsRes.find(r => r.name === currentRegion.name && r.value === currentRegion.value);
          setCurrentRegion(region || regionsRes[0]);
        }
      });
    }
  }, [cloudId, defaultRegionList]);

  // For initial data
  useEffect(() => {
    getInitialRegion();
  }, [getInitialRegion]);

  const resetMainStates = useCallback(() => {
    if (!currentRouteState) return;

    setCurrentRouteState(null);
  }, [currentRouteState]);

  const mainTabClickHandler = useCallback(
    (tabSelected: IMgdTabProps) => {
      setMainTabSelected(LIST_UP_TAB_LIST.find(tabItem => tabItem.id === tabSelected.id) || LIST_UP_TAB_LIST[0]);
      resetMainStates();
    },
    [setMainTabSelected, resetMainStates],
  );

  const pinButtonClickHandle = useCallback(() => {
    updateDefaultRegionList(
      projectId,
      Number(relatedCloudSelected.value),
      currentRegion.name || '',
      currentRegion.value.toString(),
    );
  }, [relatedCloudSelected, currentRegion, projectId]);

  const tabContentNode = useMemo(() => {
    if (!currentRegion?.value || !cloudId) return;

    switch (mainTabSelected) {
      case LIST_UP_TAB_LIST[0]:
        return (
          <FirewallListUp
            cloudId={cloudId}
            region={regionValue}
            currentRegion={currentRegion}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            relatedCloudSelected={relatedCloudSelected}
            setDetailItem={setFirewall}
            setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
            onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
          />
        );

      case LIST_UP_TAB_LIST[1]:
        return (
          <FirewallPolicy
            cloudId={cloudId}
            region={regionValue}
            currentRegion={currentRegion}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            relatedCloudSelected={relatedCloudSelected}
            setDetailItem={setFirewallPolicy}
            setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
            onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
          />
        );

      case LIST_UP_TAB_LIST[2]:
        return (
          <RuleGroup
            cloudId={cloudId}
            region={regionValue}
            currentRegion={currentRegion}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            relatedCloudSelected={relatedCloudSelected}
            setDetailItem={setRuleGroup}
            setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
            onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
          />
        );

      case LIST_UP_TAB_LIST[3]:
        return (
          <AwsRuleGroup
            cloudId={cloudId}
            region={regionValue}
            currentRegion={currentRegion}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            relatedCloudSelected={relatedCloudSelected}
            setDetailItem={setAwsRuleGroup}
            setIsDetailPage={() => setPageType(PageNodeEnum.MGD_DETAIL)}
            onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
          />
        );

      case LIST_UP_TAB_LIST[4]:
        return (
          <TlsInspectionConfigList
            cloudId={cloudId}
            region={regionValue}
            tabInformation={mainTabSelected}
            onTlsInpsectionSelected={setDetailTlsInspectionConfig}
            onTlsInpsectionIdClicked={() => setPageType(PageNodeEnum.MGD_DETAIL)}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
          />
        );
      case LIST_UP_TAB_LIST[5]:
        return (
          <ResourceGroupList
            cloudId={cloudId}
            region={regionValue}
            tabInformation={mainTabSelected}
            onResourceGroupSelected={setDetailResourceGroup}
            onResourceGroupIdClicked={() => setPageType(PageNodeEnum.MGD_DETAIL)}
            currentRouteState={currentRouteState}
            setCurrentRouteState={setCurrentRouteState}
            onNavigateCreation={() => setPageType(PageNodeEnum.MGD_CREATE)}
          />
        );
      default:
        return null;
    }
  }, [mainTabSelected, regionValue, cloudId, currentRouteState]);

  const tabSummaryNode = useMemo(() => {
    switch (mainTabSelected) {
      case LIST_UP_TAB_LIST[0]:
        return <FirewallSummary cloudId={cloudId} region={regionValue} data={firewall} />;
      case LIST_UP_TAB_LIST[1]:
        return <FirewallPolicySummary cloudId={cloudId} region={regionValue} data={firewallPolicy} />;

      case LIST_UP_TAB_LIST[2]:
        return <RuleGroupSummary cloudId={cloudId} region={regionValue} data={ruleGroup} setPageDetail={() => {}} />;

      case LIST_UP_TAB_LIST[4]:
        if (detailTlsInspectionConfig) {
          return (
            <TlsInspectionConfigSummary
              cloudId={cloudId}
              region={regionValue}
              tlsInspectionConfig={detailTlsInspectionConfig!}
            />
          );
        }
        break;

      case LIST_UP_TAB_LIST[3]:
      case LIST_UP_TAB_LIST[5]:
      default:
        return null;
    }
  }, [
    mainTabSelected,
    cloudId,
    currentRegion,
    projectId,
    firewall,
    firewallPolicy,
    ruleGroup,
    detailTlsInspectionConfig,
    detailResourceGroup,
  ]);

  const onPageBackClick = useCallback(() => {
    handleClearState();
  }, []);

  const detailNode = useMemo((): ReactNode => {
    switch (mainTabSelected) {
      case LIST_UP_TAB_LIST[0]:
        return (
          <FirewallDetail
            cloudId={cloudId}
            region={regionValue}
            data={firewall}
            setData={setFirewall}
            pageBackClick={onPageBackClick}
          />
        );

      case LIST_UP_TAB_LIST[1]:
        return (
          <FirewallPolicyDetail
            cloudId={cloudId}
            region={regionValue}
            data={firewallPolicy}
            setData={() => {}}
            pageBackClick={onPageBackClick}
          />
        );

      case LIST_UP_TAB_LIST[2]:
        return (
          <RuleGroupDetail cloudId={cloudId} region={regionValue} data={ruleGroup} pageBackClick={onPageBackClick} />
        );

      case LIST_UP_TAB_LIST[3]:
        return (
          <AwsRuleGroupDetail
            cloudId={cloudId}
            region={regionValue}
            data={awsRuleGroup}
            pageBackClick={onPageBackClick}
          />
        );
      case LIST_UP_TAB_LIST[4]:
        if (detailTlsInspectionConfig) {
          return (
            <TlsInspectionConfigDetail
              cloudId={cloudId}
              region={regionValue}
              tlsInspectionConfig={detailTlsInspectionConfig}
              pageBackClick={onPageBackClick}
              editable={true}
            />
          );
        }
        break;
      case LIST_UP_TAB_LIST[5]:
        return (
          <ResourceGroupDetail
            cloudId={cloudId}
            region={regionValue}
            resourceGroup={detailResourceGroup!}
            pageBackClick={onPageBackClick}
          />
        );
      default:
        return null;
    }
  }, [
    mainTabSelected,
    cloudId,
    currentRegion,
    onPageBackClick,
    firewall,
    firewallPolicy,
    ruleGroup,
    awsRuleGroup,
    detailTlsInspectionConfig,
    detailResourceGroup,
  ]);

  const listNode = useMemo((): ReactNode => {
    return (
      <MgdTabLayout
        layoutType={MgdTabLayoutTypeEnum.MGD_LIST_SUMMARY}
        pageTitle={cloudName}
        hasFavorite={true}
        onBackButtonClicked={onBackButtonClicked}
        headerDropList={regionList}
        headerDropValue={currentRegion}
        headerDropOnchange={val => {
          setCurrentRegion(() => ({
            name: val.name ? val.name : '',
            value: String(val.value),
          }));
        }}
        isPined={
          !!defaultRegionList.find(r => {
            return r.projectId === projectId && r.cloudId === relatedCloudSelected.value;
          })
        }
        pinBtnActHandle={pinButtonClickHandle}
        pinBtnDisabled={false}
        mainTabList={LIST_UP_TAB_LIST}
        mainTabItemClick={mainTabClickHandler}
        mainTitle={mainTabSelected?.title || ''}
        mainTabSelected={mainTabSelected}
        tabContentNode={tabContentNode}
        tabSummaryNode={tabSummaryNode}
        recentRelatedClouds={recentRelatedClouds}
        relatedCloudSelected={relatedCloudSelected}
        recentRelatedCloudOnChange={relatedCloud => recentRelatedCloudOnChange(relatedCloud)}
      />
    );
  }, [
    cloudName,
    onBackButtonClicked,
    regionList,
    currentRegion,
    defaultRegionList,
    projectId,
    relatedCloudSelected,
    pinButtonClickHandle,
    mainTabClickHandler,
    mainTabSelected,
    tabContentNode,
    tabSummaryNode,
    recentRelatedClouds,
    recentRelatedCloudOnChange,
  ]);

  const creationNode = useMemo((): ReactNode => {
    switch (mainTabSelected) {
      case LIST_UP_TAB_LIST[0]:
        return (
          <FirewallCreation
            cloudId={cloudId}
            region={regionValue}
            onBackButtonClicked={() => setPageType(PageNodeEnum.MGD_LIST)}
          />
        );

      case LIST_UP_TAB_LIST[1]:
        return (
          <FirewallPolicyCreation
            cloudId={cloudId}
            region={regionValue}
            pageBackClick={() => setPageType(PageNodeEnum.MGD_LIST)}
          />
        );

      case LIST_UP_TAB_LIST[2]:
        return (
          <RuleGroupCreation
            cloudId={cloudId}
            region={regionValue}
            pageBackClick={() => setPageType(PageNodeEnum.MGD_LIST)}
          />
        );

      case LIST_UP_TAB_LIST[3]:
      case LIST_UP_TAB_LIST[4]:
      case LIST_UP_TAB_LIST[5]:
      default:
        return null;
    }
  }, [mainTabSelected, cloudId, regionValue]);

  const pageNode = useMemo((): ReactNode => {
    switch (pageType) {
      default:
      case PageNodeEnum.MGD_LIST:
        return listNode;

      case PageNodeEnum.MGD_DETAIL:
        return detailNode;

      case PageNodeEnum.MGD_CREATE:
        return creationNode;
    }
  }, [pageType, listNode, detailNode, creationNode]);

  return <div className="network-container">{pageNode}</div>;
};

export default Firewall;
