import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ImagePropsType } from '../types';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { IMAGE_FILTER_DROPDOWN, SCOPE_DROPDOWN } from './Common/Constants';
import InputAtom from 'components/v2/atoms/InputAtom';
import { FilterPropsType } from './types';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import './Image.scss';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import lazyGetAwsDescribeImages, { IAwsDescribeImagesVariables } from 'graphql/queries/getAwsDescribeImages';
import { orderAlphabetical } from '../../../Utils/Sorting';
import { variableCombineNextToken } from '../../../Utils';
import { AwsBlockDeviceMappingType, AwsImageType } from 'graphql/types/AwsImage';
import { capitalize } from './Common/Functions';
import { getFormatedDateWithTimezone, handleFormatText } from 'utils/Common';

const Image = (props: ImagePropsType) => {
  const { cloudId, region, tabInformation, onImageeSelected, onImageIdClicked } = props;

  // API
  const [getAwsDescribeImages, {loading: awsDescribeImagesLoading}] = lazyGetAwsDescribeImages();

  // State
  const [scopeDropdownValue, setScopeDropdownValue] = useState<DropdownListDataType>(SCOPE_DROPDOWN[0]);
  const [imageFilterDropdownValue, setImageFilterDropdownValue] = useState<DropdownListDataType>(
    IMAGE_FILTER_DROPDOWN[0],
  );
  const [imageFilterInputValue, setImageFilterInputValue] = useState<FilterPropsType>({
    name: imageFilterDropdownValue.value as string,
    value: '',
  });

  const [mainTblTotalRows, setMainTblTotalRows] = useState<RowType[]>([]);
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [scopeNFilterVariable, setScopeNFilterVariable] = useState<FilterPropsType>();

  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const mainTblColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Name', field: 'nameTag', sort: true },
      { label: 'AMI name', field: 'name', sort: true },
      {
        label: 'AMI ID',
        field: 'imageId',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  onImageeSelected(row as AwsImageType);
                  onImageIdClicked();
                }}
              >
                {handleFormatText(row?.imageId)}
              </div>
            }
          </>
        ),
      },
      { label: 'Source', field: 'imageLocation', sort: true },
      { label: 'Owner', field: 'ownerId', sort: true },
      { label: 'Visibility', field: 'visibility', sort: true },
      { label: 'Status', field: 'status', sort: true },
      { label: 'Creation date', field: 'creationDate', sort: true },
      { label: 'Platform', field: 'platformDetails', sort: true },
      { label: 'Root device type', field: 'rootDeviceType', sort: true },
      {
        label: 'Block devices',
        field: 'blockDevices',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {row?.blockDeviceMappings?.map((blockDevice: AwsBlockDeviceMappingType, index: number) => {
              const ebs = blockDevice?.ebs;
              if (!!ebs) {
                const { snapshotId, volumeSize, deleteOnTermination, volumeType } = ebs;
                return (
                  <p
                    key={`image-listup-block-device-${index}`}
                  >{handleFormatText(`${blockDevice?.deviceName}=${snapshotId}:${volumeSize}:${deleteOnTermination}:${volumeType}`)}</p>
                );
              }
            })}
          </>
        ),
      },
      { label: 'Virtualization', field: 'virtualizationType', sort: true },
    ];
  }, []);

  const handleScropeDropdownOnChange = useCallback((val: DropdownListDataType) => {
    setScopeDropdownValue(val);
  }, []);

  const awsDescribeImageVariable = useMemo((): IAwsDescribeImagesVariables => {
    let scropeFilter = {};

    switch (scopeDropdownValue) {
      case SCOPE_DROPDOWN[0]:
        scropeFilter = { owners: 'self' };
        break;
      case SCOPE_DROPDOWN[1]:
        scropeFilter = { filters: [{ name: 'is-public', values: ['false'] }] };
        break;
      case SCOPE_DROPDOWN[2]:
        scropeFilter = { filters: [{ name: 'is-public', values: ['true'] }] };
        break;
      case SCOPE_DROPDOWN[3]:
        scropeFilter = { includeDisabled: true };
        break;
      default:
        break;
    }

    if (scopeNFilterVariable) {
      const searchFilterItem = { name: scopeNFilterVariable.name, values: [scopeNFilterVariable.value] };
      switch (scopeDropdownValue) {
        case SCOPE_DROPDOWN[0]:
          scropeFilter = { owners: 'self', filters: [{ ...searchFilterItem }] };
          break;
        case SCOPE_DROPDOWN[1]:
          scropeFilter = { filters: [{ name: 'is-public', values: ['false'] }, { ...searchFilterItem }] };
          break;
        case SCOPE_DROPDOWN[2]:
          scropeFilter = { filters: [{ name: 'is-public', values: ['true'] }, { ...searchFilterItem }] };
          break;
        case SCOPE_DROPDOWN[3]:
          scropeFilter = { includeDisabled: true, filters: [{ ...searchFilterItem }] };
          break;
        default:
          break;
      }
    }

    return {
      cloudId: cloudId,
      region: region,
      request: { maxResults: mainTablePagination.limit, ...scropeFilter },
    };
  }, [scopeDropdownValue, cloudId, region, scopeNFilterVariable]);

  const handleSearchFunction = useCallback(() => {
    if (imageFilterInputValue.value !== '') {
      setScopeNFilterVariable({ name: imageFilterInputValue.name, value: imageFilterInputValue.value });
    } else {
      setScopeNFilterVariable(undefined);
    }
  }, [imageFilterInputValue]);

  const handleMainRowCheckedList = useCallback((listRowId: string[]) => {
    if (listRowId.length > 0) {
      const lastRowId = listRowId.at(-1);
      const rowSelected = mainTblTotalRows.find(row => row.id === lastRowId);
      if (!rowSelected) return;
      onImageeSelected(rowSelected as AwsImageType);
    } else {
      onImageeSelected();
    }
  }, []);

  const handleMainRowSelected = useCallback(
    (rowId: string) => {
      const rowSelected = mainTblTotalRows.find(row => row.id === rowId);

      if (!rowSelected) return;

      onImageeSelected(rowSelected as AwsImageType);
    },
    [mainTblTotalRows],
  );

  const fetchDescribeImages = useCallback(
    (nextToken?: string, isInitial?: boolean) => {
      const combinedVariable = variableCombineNextToken(awsDescribeImageVariable, nextToken);
      getAwsDescribeImages({ variables: combinedVariable }).then(({ data: awsDescribeImageData }) => {
        const awsDescribeImages = awsDescribeImageData?.getAwsDescribeImages?.data?.[0];
        const imageRows: RowType[] = [];
        if (awsDescribeImages?.images) {
          awsDescribeImages.images.map(image => {
            imageRows.push({
              ...image,
              nameTag: image?.tags?.find(t => t?.key === 'Name')?.value,
              id: image?.imageId,
              visibility: image?.publicLaunchPermissions === false ? 'Private' : 'Public',
              status: capitalize(image.state),
              creationDate: getFormatedDateWithTimezone(image?.creationDate),
            });
          });
        }

        let allImages: RowType[] = [];
        if (isInitial) {
          allImages = imageRows;
        } else {
          allImages = [...mainTblTotalRows, ...imageRows];
        }
        setMainTblTotalRows(allImages);
        if (allImages.length > mainTablePagination.limit) {
          setMainNextToken(awsDescribeImages?.nextToken as string);
        } else {
          setMainNextToken('');
        }
        setMainTblTotal({
          totalPage: Math.ceil(allImages.length / mainTablePagination.itemPerPage),
          totalElement: allImages.length,
        });
      });
    },
    [awsDescribeImageVariable, mainTblTotalRows, mainNextToken, mainTblTotal, mainTablePagination],
  );

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchDescribeImages(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(mainTblTotalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblTotalRows, mainTblTotal, mainTablePagination, mainNextToken, imageFilterInputValue]);

  // For initial data
  useEffect(() => {
    fetchDescribeImages('', true);
  }, [awsDescribeImageVariable]);

  return (
    <Fragment>
      <div id="image-listup" className="row-3 flex j-between a-center">
        <div className="title-container">
          <DropdownAtom
            id="scope-dropdown"
            className=""
            data={SCOPE_DROPDOWN}
            value={scopeDropdownValue}
            handleClick={val => handleScropeDropdownOnChange(val)}
          />

          <div className="title">
            <p>{tabInformation.title}</p>
          </div>
        </div>

        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={IMAGE_FILTER_DROPDOWN}
            value={imageFilterDropdownValue}
            handleClick={val => {
              setImageFilterDropdownValue(val);
              setImageFilterInputValue(prev => ({ ...prev, name: val.value as string }));
            }}
          />

          <InputAtom
            type={'text'}
            value={imageFilterInputValue.value}
            defaultValue={''}
            onChangeValue={str => setImageFilterInputValue(prev => ({ name: prev?.name, value: str }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
            onKeyDown={event => {}}
          />

          <button className="action-btn" onClick={handleSearchFunction}>
            Search
          </button>
        </div>
      </div>

      <div className="data-grid-wrap">
        {mainRowsCurrentPage.length === 0 && awsDescribeImagesLoading === false ? (
          <p className="empty-row">Empty</p>
        ) : (
          <Table
            rows={mainRowsCurrentPage}
            columns={mainTblColumns}
            reportCheckedList={(list: string[]) => handleMainRowCheckedList(list)}
            reportSelected={id => handleMainRowSelected(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                setMainTablePagination(prev => ({
                  ...prev,
                  target: target,
                  direction: dir,
                }));
              },
            }}
            isLoading={awsDescribeImagesLoading}
            horizontalScrollable={true}
          />
        )}

        {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && awsDescribeImagesLoading === false && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center">
              Total <span>{mainTblTotal.totalElement}</span>
            </p>
            <TableManagePagination
              ableFetchMore={!!mainNextToken}
              currentPage={mainTablePagination.currentPage}
              updateCurrentPage={page =>
                setMainTablePagination(prev => ({
                  ...prev,
                  ['currentPage']: page,
                }))
              }
              totalPage={mainTblTotal.totalPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Image;
