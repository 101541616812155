import { useLazyQuery } from '@apollo/client';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListFirewallPoliciesResponseType } from 'graphql/types/AwsListFirewallPolicy';
import query from './query';

export interface IAwsListFirewallPoliciesVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults?: number;
    nextToken?: string;
  };
}

export interface IAwsListFirewallPoliciesResponseData {
  getAwsListFirewallPolicies: IGqlResponseNewData<AwsListFirewallPoliciesResponseType>;
}

const lazyGetAwsListFirewallPolicies = () =>
  useLazyQuery<IAwsListFirewallPoliciesResponseData, IAwsListFirewallPoliciesVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsListFirewallPolicies;
