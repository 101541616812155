import PageTitle from 'pages/v2/Organ/Management/WAF/WebACL/CreateWebACL/components/PageTitle';
import { CreateAwsKeyPropsType } from './types';
import { useCallback, useMemo, useState } from 'react';
import Button, { ButtonTypeEnum } from 'pages/v2/Organ/Management/WAF/WebACL/CreateWebACL/components/Button';
import { CREATE_AWS_KEY_STEPS } from './configs';
import ConfigKey from './steps/ConfigKey';
import AddLabel from './steps/AddLabel';
import DefineKeyAdministratorPermission from './steps/DefineKeyAdministratorPermission';
import DefineKeyUsagePermission from './steps/DefineKeyUsagePermission';
import Review from './Review';
import { CreationDataPropsType } from './steps/types';
import {
  KEY_SYMMETRIC_USAGE_DATA,
  KEY_TYPE_DATA,
  KeyMaterialOriginEnum,
  KeySpecEnum,
  KeyTypeEnum,
  KeyUsageEnum,
} from './steps/configs';
import './index.scss';
import createAwsKeyMutation, { ICreateAwsKeyVariables } from 'graphql/mutations/createAwsKey';
import createAwsAliasKmsMutation, { ICreateAwsAliasKmsVariables } from 'graphql/mutations/createAwsAliasKms';
import { CreateKeyAwsTagType } from 'graphql/types/AwsCreateKey';
import { twelveNumberRegex } from 'utils/Regex';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';

const CreateAwsKey = (props: CreateAwsKeyPropsType) => {
  const { cloudId, region, onBackButtonClicked, adminAccountId } = props;

  const initCreation: CreationDataPropsType = {
    keyType: KeyTypeEnum.SYMMETRIC,
    keyTypeData: KEY_TYPE_DATA,
    keyUsage: KeyUsageEnum.SYMMETRIC_ENCRYPTDECRYPT,
    keyUsageData: KEY_SYMMETRIC_USAGE_DATA,
    keySpec: KeySpecEnum.SYMMETRIC_DEFAULT,
    keySpecData: [],
    origin: KeyMaterialOriginEnum.KMSRECOMMEND,
    securityAndDurabilityAgreement: false,
    multiRegion: false,
    aliasName: '',
    administratorDeleteAgreement: true,
  };

  // API
  const [createAwsAliasKms, { loading: createAliasLoading }] = createAwsAliasKmsMutation();
  const [createAwsKey, { loading: createKeyLoading }] = createAwsKeyMutation();

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [creationData, setCreationData] = useState<CreationDataPropsType>(initCreation);

  // Step 1 validation
  const onConfigKeyValidate = useCallback((): boolean => {
    const { keyUsage, keyUsageData, keySpec, keySpecData, origin, securityAndDurabilityAgreement } = creationData;
    let validated = true;
    let keyUsageError, keySpecError, securityAndDurabilityError;
    if (keyUsageData.length > 0) {
      const keyUsageSelected = keyUsageData.find(item => item.id === keyUsage);
      if (!keyUsageSelected) {
        keyUsageError = 'Key usage is required';
        validated = false;
      }
    }

    if (keySpecData.length > 0) {
      const keySpecSelected = keySpecData.find(item => item.id === keySpec);
      if (!keySpecSelected) {
        keySpecError = 'Key spec is required';
        validated = false;
      }
    }

    if (origin === KeyMaterialOriginEnum.EXTERNAL && securityAndDurabilityAgreement === false) {
      securityAndDurabilityError =
        'To use external key you must check the box to indicate that you understand the implications.';
      validated = false;
    }

    const creationDateValidated: CreationDataPropsType = {
      ...creationData,
      keyUsageError: keyUsageError,
      keySpecError: keySpecError,
      securityAndDurabilityError: securityAndDurabilityError,
    };
    setCreationData(creationDateValidated);
    return validated;
  }, [creationData]);

  // Step 2 validation
  const onAddLabelVadidate = useCallback((): boolean => {
    const { aliasName, tags } = creationData;
    let validated = true;
    let aliasNameError;
    if (aliasName === '') {
      aliasNameError = 'A key alias is required.';
      validated = false;
    }

    if (tags && tags.length > 0) {
      tags.map(tag => {
        if (tag.keyStr === '' || tag.valueStr === '') {
          validated = false;
        }
      });
    }

    const creationDateValidated: CreationDataPropsType = { ...creationData, aliasNameError: aliasNameError };
    setCreationData(creationDateValidated);
    return validated;
  }, [creationData]);

  // Step 4 validation
  const onKeyUsageValidate = useCallback((): boolean => {
    const { otherAwsAccounts } = creationData;
    let validated = true;

    if (otherAwsAccounts && otherAwsAccounts.length > 0) {
      otherAwsAccounts.map(awsAccount => {
        if (!twelveNumberRegex.test(awsAccount.name)) {
          validated = false;
        }
      });
    }
    return validated;
  }, [creationData]);

  const validateInputs = useCallback((): boolean => {
    switch (currentStepIndex) {
      default:
      case 0:
        return onConfigKeyValidate();
      case 1:
        return onAddLabelVadidate();
      case 3:
        return onKeyUsageValidate();
    }
  }, [currentStepIndex, onConfigKeyValidate, onAddLabelVadidate]);

  const onPreviousButtonClicked = useCallback(() => {
    setCurrentStepIndex(currentStepIndex - 1);
  }, [currentStepIndex]);

  const onNextButtonClicked = useCallback(() => {
    if (currentStepIndex < CREATE_AWS_KEY_STEPS.length - 1 && validateInputs()) {
      setCurrentStepIndex(currentStepIndex + 1);
      return;
    }
  }, [currentStepIndex, creationData, validateInputs]);

  const onCancelButtonClicked = useCallback(() => {
    onBackButtonClicked();
  }, [currentStepIndex]);

  const creationApiLoading = useMemo((): boolean => {
    return createAliasLoading || createKeyLoading;
  }, [createAliasLoading, createKeyLoading]);

  const keyReqData = useMemo((): ICreateAwsKeyVariables => {
    const { keySpec, keyUsage, origin, multiRegion, tags, policy, description } = creationData;

    const keyTags: CreateKeyAwsTagType[] =
      tags?.map(tag => {
        return { tagKey: tag.keyStr, tagValue: tag.valueStr };
      }) ?? [];

    return {
      cloudId: cloudId,
      region: region,
      reqData: {
        keySpec,
        keyUsage,
        origin,
        multiRegion,
        tags: keyTags,
        policy,
        description,
      },
    };
  }, [creationData, cloudId, region]);

  const getAliasReqData = useCallback(
    (targetKeyId: string): ICreateAwsAliasKmsVariables => {
      const { aliasName } = creationData;
      const finalAliasName = `alias/${aliasName}`
      return { cloudId, region, reqData: { aliasName: finalAliasName, targetKeyId } };
    },
    [cloudId, region, creationData],
  );

  const onCreateButtonClicked = useCallback(() => {
    createAwsKey({ variables: keyReqData }).then(({ data: keyCreationResponse }) => {
      const keyMetadata = keyCreationResponse?.createAwsKey?.data?.[0]?.keyMetadata;
      if (keyMetadata) {
        const aliasReqData: ICreateAwsAliasKmsVariables = getAliasReqData(keyMetadata.keyId);
        createAwsAliasKms({ variables: aliasReqData }).then(({ data: aliasCreationResponse }) => {
          if (aliasCreationResponse?.createAwsAliasKms?.result === ErrorCode.SUCCESS) {
            useToast(ErrorCode.SUCCESS, 'Create customer managed key successful.');
            onBackButtonClicked();
          } else {
            useToast(ErrorCode.UNKNOWN, 'Create alias name failed.');
          }
        });
      } else {
        useToast(ErrorCode.UNKNOWN, 'Create customer managed key failed.');
      }
    });
  }, [keyReqData, getAliasReqData]);

  const buttonGroupNode = useMemo(() => {
    const isFirstItem = currentStepIndex === 0;

    if (currentStepIndex === 4) {
      return null;
    }

    return (
      <div className="button-group">
        <Button label="Cancel" onClick={onCancelButtonClicked} />
        {!isFirstItem && <Button label="Previous" onClick={onPreviousButtonClicked} />}
        <Button label="Next" type={ButtonTypeEnum.PRIMARY} onClick={onNextButtonClicked} />
      </div>
    );
  }, [currentStepIndex, creationData]);

  const stepNode = useMemo(() => {
    switch (currentStepIndex) {
      default:
      case 0:
        return (
          <ConfigKey
            title={CREATE_AWS_KEY_STEPS[currentStepIndex].title}
            creationData={creationData}
            updateCreationData={data => setCreationData(data)}
          />
        );
      case 1:
        return (
          <AddLabel
            title={CREATE_AWS_KEY_STEPS[currentStepIndex].title}
            creationData={creationData}
            updateCreationData={data => setCreationData(data)}
          />
        );
      case 2:
        return (
          <DefineKeyAdministratorPermission
            title={CREATE_AWS_KEY_STEPS[currentStepIndex].title}
            creationData={creationData}
            cloudId={cloudId}
            region={region}
            updateCreationData={data => setCreationData(data)}
          />
        );
      case 3:
        return (
          <DefineKeyUsagePermission
            title={CREATE_AWS_KEY_STEPS[currentStepIndex].title}
            creationData={creationData}
            cloudId={cloudId}
            region={region}
            updateCreationData={data => setCreationData(data)}
          />
        );
      case 4:
        return (
          <Review
            adminAccountId={adminAccountId}
            creationData={creationData}
            onCancelButtonClicked={onCancelButtonClicked}
            onPreviousButtonClicked={onPreviousButtonClicked}
            onCreateButtonClicked={onCreateButtonClicked}
            submitLoading={creationApiLoading}
            updateCreationData={data => setCreationData(data)}
          />
        );
    }
  }, [currentStepIndex, creationData, cloudId, region, adminAccountId, creationApiLoading]);

  return (
    <div id="create-awskey-page-container">
      <PageTitle
        title={currentStepIndex === 4 ? 'Create customer managed key: Review' : 'Create customer managed key'}
        hasBackButton
        onBackButtonClicked={onBackButtonClicked}
      />
      {stepNode}
      {buttonGroupNode}
    </div>
  );
};

export default CreateAwsKey;
