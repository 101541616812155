import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { MemoInfo } from 'apis/v2/Manage/schema';

export interface IGetMemoPageByParamVariables {
  reqData: {
    organId?: number;
    text?: string;
    relObjectType?: string;
    pageInfo: Paging;
  }
}

export interface IGetMemoPageByParamResponseData {
  getMemoPageByParam: ResponsePage<MemoInfo>;
}

const lazyGetMemoPageByParam = () => useLazyQuery<IGetMemoPageByParamResponseData, IGetMemoPageByParamVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetMemoPageByParam;