import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import Icon from 'components/v2/atoms/Icon';
import IconTag from 'assets/svgs/v3/ico_tag.svg';
import './index.scss';

interface IUpdateTagsModalProps extends IBaseModalProps {
  header: string;
  subHeader?: string;
  currentDatas: TagRowData[];
  columns: string[];
  note?: string;
  onSave: (editiedTags: TagRowData[]) => void;
}

export interface TagRowData {
  index: number;
  valueKey: string;
  valueOptional: string;
}

const UpdateTagsModal = ({
  header,
  subHeader,
  currentDatas,
  columns,
  note,
  onSave,
  ...baseModalProps
}: IUpdateTagsModalProps) => {
  const [tagRows, setTagRows] = useState<TagRowData[]>([]);

  useEffect(() => {
    if (baseModalProps.open) {
      if (currentDatas.length == 0) {
        setTagRows([{ index: 0, valueKey: '', valueOptional: '' }]);
      } else {
        setTagRows(currentDatas);
      }
    }
  }, [baseModalProps.open, currentDatas]);

  const handleCreate = () => {
    setTagRows(prevState => {
      const newState = prevState.concat({ index: tagRows.length, valueKey: '', valueOptional: '' });
      return newState;
    });
  };

  const handleDelete = (indexToRemove: number) => {
    const newData = tagRows.filter((_, index) => index != indexToRemove);
    setTagRows(newData);
  };

  const handleChange = (index: number, value: string, propName: keyof TagRowData) => {
    const newRows = tagRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setTagRows(newRows);
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconTag} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="update-tag-model">
        {subHeader && <div className="sub-title">{subHeader}</div>}

        {columns && (
          <div className="tag-row-container">
            <div className="tag-row-input">
              {columns.map((column, index) => {
                return (
                  <div key={`udpate-tags-table-column-${index}`} className="column">
                    <p>{column}</p>
                  </div>
                );
              })}
            </div>
            <div className="tag-row-btn"></div>
          </div>
        )}

        {tagRows && (
          <>
            {tagRows.map((row, index) => (
              <div className="tag-row-container" key={`udpate-tags-table-new-row-${index}`}>
                <div className="tag-row-input">
                  <div className="column">
                    <InputAtom
                      type={'text'}
                      value={row.valueKey}
                      onChangeValue={(value: string) => handleChange(index, value, 'valueKey')}
                      defaultValue={''}
                      noClear={true}
                      hasPrefixIcon={true}
                    />
                  </div>
                  <div className="column">
                    <InputAtom
                      type={'text'}
                      value={row.valueOptional}
                      onChangeValue={(value: string) => handleChange(index, value, 'valueOptional')}
                      defaultValue={''}
                      noClear={false}
                      hasPrefixIcon={true}
                    />
                  </div>
                </div>
                <div className="tag-row-btn">
                  <button className="button" onClick={() => handleDelete(index)}>
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </>
        )}

        <div className="add-new-tag-container">
          <button className="normal-btn" onClick={handleCreate}>
            Add new tag
          </button>
          {!!note && <p className="note">{note?.replace('<limit>', (50 - (tagRows?.length ?? 0)).toString())}</p>}
        </div>

        <div className="cancle-save-container">
          <button className="button" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button className="normal-btn" onClick={() => onSave(tagRows)}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default UpdateTagsModal;
