import { gql } from '@apollo/client';

const query = gql`
  mutation updateAwsFirewallDeleteProtection(
    $request: AwsUpdateFirewallDeleteProtectionRequest
    $cloudId: Long
    $region: String
  ) {
    updateAwsFirewallDeleteProtection(
      updateFirewallDeleteProtectionRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsUpdateFirewallDeleteProtectionResponse {
          firewallArn
          firewallName
          deleteProtection
          updateToken
        }
      }
    }
  }
`;
export default query;
