import { useState, useEffect, useCallback, useMemo } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import Icon from 'components/v2/atoms/Icon';
import IconTag from 'assets/svgs/v3/ico_tag.svg';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import './index.scss';

interface IEditIPSetReferenceModalProps extends IBaseModalProps {
  header: string;
  subHeader?: string;
  currentDatas: IpSetRowData[];
  columns: string[];
  note?: string;
  onSave: (oldTags: IpSetRowData[], newTags: IpSetRowData[]) => void;
  managedPrefixLists?: any;
  handleUpdateIpsetReference: (ipSetReference: {
    [key: string]: {
      referenceArn: string;
    };
  }) => Promise<void>;
}

export interface IpSetRowData {
  index: number;
  variableName: string;
  resourceId: DropdownListDataType;
  cidr: string;
}

const EditIPSetReferenceModal = (props: IEditIPSetReferenceModalProps) => {
  const {
    header,
    subHeader,
    currentDatas,
    columns,
    note,
    onSave,
    managedPrefixLists,
    handleUpdateIpsetReference,
    ...baseModalProps
  } = props;
  const [ipSetReferenceRows, setIpSetReferenceRows] = useState<IpSetRowData[]>([]);
  const [isShowRow, setIsShowRow] = useState<boolean>(false);

  const resources = useMemo(() => {
    return managedPrefixLists?.map((item: any, index: number) => {
      return {
        id: item?.prefixListId,
        name: item?.prefixListId ?? '',
        description: item?.prefixListName ?? '',
        value: item?.prefixListArn ?? '',
      };
    });
  }, [managedPrefixLists]);

  useEffect(() => {
    setIsShowRow(true);
    setIpSetReferenceRows(currentDatas);
  }, [currentDatas]);

  const handleCreate = useCallback(() => {
    setIsShowRow(true);
    setIpSetReferenceRows(prevState => {
      const newState = prevState.concat({
        index: ipSetReferenceRows.length,
        variableName: '',
        resourceId: { value: '' },
        cidr: '',
      });
      return newState;
    });
  }, [ipSetReferenceRows]);

  const handleDelete = useCallback(
    (indexToRemove: number) => {
      const newData = ipSetReferenceRows.filter((_, index) => index != indexToRemove);
      setIpSetReferenceRows(newData);
    },
    [ipSetReferenceRows],
  );

  const handleChange = useCallback(
    (index: number, value: string | DropdownListDataType, propName: keyof IpSetRowData) => {
      const newRows = ipSetReferenceRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
      setIpSetReferenceRows(newRows);
    },
    [ipSetReferenceRows],
  );

  const renderIpSetRow = useCallback(
    (
      index: number,
      variableNameInputValue: string,
      resourceIdDropDownValue: DropdownListDataType,
      cidrInputValue: string,
    ) => {
      return (
        <div className="tag-row-container" key={`udpate-tags-table-new-row-${index}`}>
          <div className="tag-row-input">
            <div className="column">
              <InputAtom
                type={'text'}
                value={variableNameInputValue}
                placeholder={'IP-set reference'}
                onChangeValue={(value: string) => handleChange(index, value, 'variableName')}
                defaultValue={''}
                noClear={true}
                hasPrefixIcon={true}
              />
            </div>
            <div className="column">
              <DropdownAtom
                id={`ip-resource-id-${index}`}
                className={'ip-resource'}
                data={resources}
                placeholder={'Choose an IP set'}
                value={{
                  name: resourceIdDropDownValue?.name || '',
                  value: (resourceIdDropDownValue?.value as string) || '',
                }}
                handleClick={val => handleChange(index, val, 'resourceId')}
              />
            </div>
            <div className="column">
              <InputAtom
                type={'text'}
                value={cidrInputValue}
                placeholder={'IP-set reference'}
                onChangeValue={(value: string) => handleChange(index, value, 'cidr')}
                defaultValue={''}
                noClear={true}
                hasPrefixIcon={true}
                disabled
              />
            </div>
            <button className="remove-btn" onClick={() => handleDelete(index)}>
              Remove
            </button>
          </div>
        </div>
      );
    },
    [ipSetReferenceRows, handleChange],
  );

  const onSaveButtonClicked = useCallback(async () => {
    let referenceSet: {
      [key: string]: {
        referenceArn: string;
      };
    } = {};
    ipSetReferenceRows.forEach(row => {
      referenceSet[row.variableName] = {
        referenceArn: row.resourceId.value as string,
      };
    });
    await handleUpdateIpsetReference(referenceSet);
    baseModalProps?.onClose?.();
  }, [ipSetReferenceRows]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconTag} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-ip-references-model">
        {!!note && <p className="note">{note}</p>}
        <div className="sub-title">IP set references</div>
        <p className="detail-sub-title">
          An IP set reference is a variable used in your rules that refers to a resource associated with a list of IPs
          or CIDRs.
        </p>

        {ipSetReferenceRows.length === 0 && (
          <button className="add-btn" onClick={handleCreate}>
            Add IP set references
          </button>
        )}

        {isShowRow && (
          <>
            {columns && (
              <div className="tag-row-container">
                <div className="tag-row-input">
                  {columns.map((column, index) => {
                    return (
                      <div key={`udpate-tags-table-column-${index}`} className="column">
                        <p>{column}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="tag-row-btn"></div>
              </div>
            )}
            {ipSetReferenceRows && (
              <>
                {ipSetReferenceRows.map((row, index) =>
                  renderIpSetRow(index, row.variableName, row.resourceId, row.cidr),
                )}
              </>
            )}
            {ipSetReferenceRows.length > 0 && (
              <button className="add-btn" onClick={handleCreate}>
                Add another IP set variable
              </button>
            )}
          </>
        )}

        <div className="cidr-usage">
          <div className="container-header">
            <p className="title">CIDR usage</p>
            <p className="note">The total number of CIDRs used by the IP set references across the firewall.</p>
          </div>
          <p className="detail">1/1,000,000</p>
        </div>
        <div className="cancle-save-container">
          <button className="btn cancel-btn" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button className="btn save-btn" onClick={onSaveButtonClicked}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditIPSetReferenceModal;
